import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { CommissionManagementService } from 'app/commission-management/services/commission-management.service';
import { CommissionDto } from 'app/models/commission';
import { AppComponentBase } from 'app/app-component-base';
import { SymbolMarketCommissionDialogComponent } from '../symbol-market-commission-dialog/symbol-market-commission-dialog.component';



@Component({
  selector: 'ms-symbol-market-manage-commission-list',
  templateUrl: './symbol-market-commission-list.component.html',
  styleUrls: ['./symbol-market-commission-list.component.scss']
})
export class SymbolMarketCommissionListComponent extends AppComponentBase implements OnInit, OnDestroy {

  public items: CommissionDto[];
  public lookups: StatusLookupDto;
 
   
  public _symbolId: number;
  @Input() set symbolId(value: number) {
    this._symbolId = value;
  if(  this._symbolId>0){

    this.getSymbolMarketCommissions();
  }
    
  }
  title: any;
  public actions: any;
  public action: any;
  selectBy:any; 
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
 
    private route: ActivatedRoute,
    private marketService: MarketService    ,
    private commissionManagementService: CommissionManagementService    ,
    private dialog: MatDialog  , 
    
    
    
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector);
      this.actions = _helper.getNames(StatusEnum);
 
  
    }
    
  ngOnInit(): void { 
    this.title = this.translate('Commissions');
    

  }

  getSymbolMarketCommissions( ) {
    
    this.commissionManagementService.GetSymbolMarketCommissionList(this._symbolId).subscribe(items => {
 
      this.items = items; 
    });
  }



 

  editSymbolMarketCommission(data) {
   
     const dialog = this.dialog.open(SymbolMarketCommissionDialogComponent, {
      data: { symbolId:this._symbolId, symbolMarket: data }
    });
    dialog.afterClosed().subscribe((res) => {
         this.getSymbolMarketCommissions( );
    }); 

  } 
 
 

}

