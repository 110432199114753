import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../core/directive/directives.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ChartsModule } from "@progress/kendo-angular-charts";

import { DashboardRoutes } from "./dashboard.routing";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { DashboardComponent } from "./containers/dashboard/dashboard.component";
import { DashboardApi } from "./api/dashboard.api";

import { GridModule } from "@progress/kendo-angular-grid";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
 
import { SharedModule } from "app/shared/shared.module";
import { TranslocoRootModule } from "app/transloco/transloco-root.module";

import { MarketWatchApi } from "../dashboard/api/market.watch.api";
import { MarketWatchComponent } from "./components/market-watch/market-watch.component";
import { AllMarketWatchComponent } from "./components/all-market-watch/all-market-watch.component";

 
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SlickCarouselModule } from "ngx-slick-carousel";
  
import { MarketMyTradesComponent } from "./components/market-my-trades/market-my-trades.component";
import { MarketTradesComponent } from "./components/market-trades/market-trades.component";

import { DayChartComponent } from "./components/day-chart/day-chart.component";
import { DatePipe } from "@angular/common";
 

 import { WarehouseHomeComponent } from "app/warehouse-managment/warehouse-home/warehouse-home.component";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
 
import { LayoutModule } from "@progress/kendo-angular-layout"; 
import { OrderManagementModule } from "app/order-management/order-management.module";
import { OrderTicketDialogComponent } from "./components/orderTicket-dialog/orderTicket-dialog.component";
import { OrderAskComponent} from "./components/order-ask/order-ask.component";
  import {OrderBidComponent} from "./components/order-bid/order-bid.component";
import { OrderWatchComponent } from "./components/order-watch/order-watch.component";
import {OrderCofirmationDialogComponent} from './components/order-cofirmation-dialog/order-cofirmation-dialog.component'

import { NewsApi } from "app/news/api/news-api"; 
import { OrderAttachmentComponent } from "app/order-management/components/order-attachment/order-attachment.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    PerfectScrollbarModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    ChartsModule,
    MatSortModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    MatExpansionModule,
    NgxDatatableModule,
    FlexLayoutModule,
    MatOptionModule,
    MatSelectModule,
    SlickCarouselModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatInputModule,
    GridModule,
    TranslocoRootModule,
    DragDropModule,
    ResizableModule,
    SharedModule,MatButtonToggleModule,
    LayoutModule ,
    RouterModule.forChild(DashboardRoutes),

    OrderManagementModule  ,
    
  ],
  declarations: [
    DashboardComponent,
    MarketWatchComponent,
    DayChartComponent,
    MarketMyTradesComponent,
    MarketTradesComponent, 
    WarehouseHomeComponent,
    AllMarketWatchComponent,
    OrderTicketDialogComponent, 
    OrderWatchComponent,
    OrderCofirmationDialogComponent,
    OrderBidComponent,
    OrderAskComponent  
  ],
  providers: [
    DatePipe,
    NewsApi ,
    DashboardApi,
    MarketWatchApi,
  ],
})
export class DashboardModule {}
