<div class="user-manage-list report-list-form">
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">

			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
				<div fxLayout="row wrap">
					<div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 350px)"
						fxFlex.lg="calc(100% - 350px)" fxFlex.xl="calc(100% - 350px)">
						<h2> {{ title | translate }}</h2>
					</div>
					<div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="85" fxFlex.gt-md="85">
						  <mat-form-field class="width100 form-field" floatLabel="never">
							 <mat-label> {{ 'Auction Number' | translate }} </mat-label>
							 <input matInput type="number" [(ngModel)]="this.filterOptions.Id"   
							  placeholder="{{ 'Auction Number' | translate }} "   [min]="0">
						  </mat-form-field>
						  <mat-form-field class="width100 form-field"
						  floatLabel="never" *ngIf="!this.isBroker()"  >
						  <mat-label>{{ 'Trading Company' | translate }}</mat-label>
							  <mat-select 
									  [(ngModel)]="AuctionPaymentFilter.CompanyId"
									  name="companyId" 
									  class="filter-select" 
									  [placeholder]="'Company' | translate "
									  (selectionChange)="onChangeCompany($event);getList()">
									  <mat-label>{{ 'Company' | translate }}</mat-label>	
							  <mat-option>								
								  <ngx-mat-select-search name="companySearch" 
									  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
									  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
									  (ngModelChange)="onChangeCompany($event)">
								  </ngx-mat-select-search>
							  </mat-option>
								  <mat-option >	{{ 'All' | translate }} </mat-option>
									  <mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
										 {{isRtl()?company.NameAr:company.Name}}
								  </mat-option>
							  </mat-select> 
						  </mat-form-field> 
					</div>
				 
					<div fxFlex.xs="15" fxFlex.sm="15" fxFlex.md="15" fxFlex.lg="15" fxFlex.xl="15">

						<button mat-raised-button color="primary"
			 
						required
						*ngIf="this.filterOptions.Id>0"
						(click)="getAuctions()">
							{{ 'Search' | translate }} <mat-icon>search</mat-icon></button>

					</div>

				</div>
			</div>
		</div>

		

	</mat-card>

	<div  *ngFor="let auction of  items"  class="div-content"> 
		<mat-accordion class="mrgn-all-md"  >
			<ng-container   > 
				<!--  -->
			  <mat-expansion-panel   class="expansion-panel" 
			  (opened)="getAuctionPayments(auction.Id ,auction.OrderType )"  [expanded]="true">
				  <mat-expansion-panel-header>
					 <mat-panel-title>
						<h3 class="form-title mrgn-b-none  ">
						   <span class="form-title cc primary-text mrgn-r-sm"> 
						   </span>{{ 'Auction' | translate }} # {{auction.Id}}</h3>
					 </mat-panel-title>
				  </mat-expansion-panel-header>
	
				  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
					<div class="gene-card-content">
						<kendo-grid 
					
					#grid  
					[pageSize]="state.take" 
					[skip]="state.skip"
					 [sort]="state.sort" 
					[pageable]="true" 
					(dataStateChange)="dataStateChange($event,null)"
					[data]="view | async"
					(edit)="editHandler($event)"
					(cancel)="cancelHandler($event)"
					 (save)="saveHandler($event)" 
				
					 [resizable]="false"
					[reorderable]="true"  
					
					>
					
					<kendo-grid-column field="Id"   headerClass="grid-list-column-header" [hidden]="true" ></kendo-grid-column>
					<kendo-grid-column field="AuctionID"   headerClass="grid-list-column-header" [hidden]="true" ></kendo-grid-column>
					<kendo-grid-column field="Date" width="120"  [editable]="false" title="{{ 'Date' | translate }}" headerClass="grid-list-column-header">
						<ng-template kendoGridCellTemplate let-dataItem> 
							 <span>{{dataItem.Date | date: 'dd/MM/yyyy'}}</span>	
						</ng-template>
						
					</kendo-grid-column>
					<kendo-grid-column field="ClientCode" title="{{ 'Code' | translate }}"
					 headerClass="grid-list-column-header"    [editable]="false" ></kendo-grid-column>
	
					<kendo-grid-column field="ClientNameAr"    [editable]="false" title="{{ 'NameAr' | translate }}" headerClass="grid-list-column-header">
					</kendo-grid-column>

					<kendo-grid-column field="ClientNameEn"    [editable]="false" title="{{ 'NameEn' | translate }}" headerClass="grid-list-column-header">
					</kendo-grid-column>
	
					<kendo-grid-column field="Payment" title="{{ 'Payment' | translate }}"
					 editor="numeric"  width="120"  headerClass="grid-list-column-header"> 
					</kendo-grid-column>
					
					
					 <kendo-grid-command-column title=" " width="150"  headerClass="grid-list-column-header">
						<ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">

							<button   *ngIf="dataItem.hasAttchment==true" class="attBtn"
							 (click)="downloadAttachment(dataItem,auction.SymbolCode)" ><mat-icon >cloud_download</mat-icon>
							</button>
							
							 <button kendoGridEditCommand [primary]="true"><mat-icon >edit</mat-icon></button>
							
							 <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
								<span *ngIf="!isNew">  <mat-icon> done_all  </mat-icon></span>
								<span *ngIf="isNew">  <mat-icon   >done_all </mat-icon></span>
							</button>
							<button kendoGridCancelCommand> 
								<span *ngIf="isNew">   <mat-icon  class="cancel-btn  ">cancel </mat-icon></span>
								<span *ngIf="!isNew">  <mat-icon  class="cancel-btn  ">cancel </mat-icon></span>								
							</button>
							
						</ng-template>
					</kendo-grid-command-column>
				</kendo-grid>
					
				</div> 
	
		 </div>
	
	
			   </mat-expansion-panel>
	
			</ng-container>
		 </mat-accordion>    
	 
		</div>

</div>