<div class="user-manage-list">
  <mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mrgn-r-sm">
          <h4>{{'Statements' |translate}}</h4>
        </div>
        <div class="mrgn-l-sm mrgn-r-sm">

        </div>
      </div>
      <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="">

    <br/>
      <kendo-grid   #grid
      [data]="view | async"
	  [filterable]="false" 
	  [resizable]="true"
	  [reorderable]="true"
	  filterable="menu"  
	  [sortable]="true" 
	  [kendoGridSelectBy]="selectBy"
	  [columnMenu]="true"
    class="market-kendo-grid"
	[rowClass]="rowCallback"

	[pageSize]="state.take"
	[skip]="state.skip"
	[sort]="state.sort"
 
	[pageable]="true"
 
	(dataStateChange)="dataStateChange($event)" >  

        <kendo-grid-column field="Balance Date" title="{{ 'Balance Date' | translate }} "
          headerClass="grid-list-column-header" width="120">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">


            <span class="client-name" *ngIf="dataItem.Amount<0" style="color:red">{{dataItem.BalanceDate | date:
              'dd/MM/yyyy'}} </span>
            <span class="client-name" *ngIf="dataItem.Amount>0 " style="color:green">{{dataItem.BalanceDate | date:
              'dd/MM/yyyy'}}</span>

          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column> {{ 'Balance Date'| translate }} </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="Amount" title="{{ 'Amount' | translate }} " headerClass="grid-list-column-header"
          width="100">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <span *ngIf="dataItem.Amount<0" style="color:red">{{(dataItem.Amount >= 0 ? dataItem.Amount :
              -dataItem.Amount) | number }} </span>
            <span *ngIf="dataItem.Amount>0 " style="color:green">{{dataItem.Amount | number }} </span>

          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column> {{ 'Amount'| translate }} </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="CurrencyCode" title="{{ 'Currency' | translate }} " headerClass="grid-list-column-header"
          width="100">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <span class="client-name" *ngIf="dataItem.Amount<0" style="color:red">{{dataItem.CurrencyCode }} </span>
            <span class="client-name" *ngIf="dataItem.Amount>0 " style="color:green">{{dataItem.CurrencyCode }} </span>

          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column> {{ 'Currency'| translate }} </ng-template>

        </kendo-grid-column>



        <kendo-grid-column field="" title="{{ 'Source' | translate }}" headerClass="grid-list-column-header"
          width="120">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
            <span *ngIf="dataItem.BalanceSource==SourceEnum.Manual"> {{ 'Manual' | translate }}</span>
            <span *ngIf="dataItem.BalanceSource==SourceEnum.Disk">{{ 'Disk' | translate }} </span>
            <span *ngIf="dataItem.BalanceSource==SourceEnum.SFTP">{{ 'SFTP' | translate }} </span>
            <span *ngIf="dataItem.BalanceSource==SourceEnum. Order && dataItem.Amount<0">{{ 'Sell Order' | translate }}
            </span>
            <span *ngIf="dataItem.BalanceSource==SourceEnum. Order && dataItem.Amount>0">{{ 'Buy Order' | translate }}
            </span>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"
          width="120">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
            <span *ngIf="dataItem.Status==StatusEnum.Approve" class="oval-span" style="background-color:#19a978"> {{
              'Approved' | translate }}</span>
            <span *ngIf="dataItem.Status==StatusEnum.Rejected" class="oval-span" style="background-color:#ce3030">{{
              'Rejected' | translate }} </span>
            <span *ngIf="dataItem.Status==StatusEnum.AwaitApprove" class="oval-span" style="background-color:#9d9b99">{{
              'Pending' | translate }} </span>


          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Remark" title="{{ 'Note' | translate }} " headerClass="grid-list-column-header"
          width="200" [hidden]="true">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="client-name">{{dataItem.Remark}}</span>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column> {{ 'Note '| translate }} </ng-template>

        </kendo-grid-column>

      </kendo-grid>



    </div>

  </mat-card>
</div>