import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'app/client-managment/services/client.service';
import { ClientBankDto, ClientDto, StoreClientDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StatusLookupDto, SymbolUnitDto } from 'app/models/StatusLookup';
import { BankDto } from 'app/models/bank';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { StoreSectionDto, StoreDto, StoreClientViewDto } from 'app/models/store';
import { AppComponentBase } from 'app/app-component-base';
import { AuthService } from 'app/service/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { StatusEnum, EntityTypeEnum, UserTypeEnum } from 'app/core/enums';
import { SymbolDto, SymbolMarketDto } from 'app/models/symbol';
import { CategoryDto, SectorDto } from 'app/models/category';
import { MarketDto } from 'app/models/market';
import { permissions } from 'app/core/permission';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { UploadAttachmentDto } from 'app/models/attachment';


@Component({
  selector: 'ms-symbol-form',
  templateUrl: './symbol-form.component.html',
  styleUrls: ['./symbol-form.component.scss']
})
export class SymbolFormComponent extends AppComponentBase implements OnInit {
  uploadAttachmentTypeId: any;
  attachmentTypes: any;
  currencies: any;
  title: string;
  public symbolUnits: SymbolUnitDto[];
  public sectors: SectorDto[];
  symbol: SymbolDto = new SymbolDto();
  markets: MarketDto[];
  mainMarkets: MarketDto[];
  subMarkets: MarketDto[];
  symbolMarket: SymbolMarketDto=new SymbolMarketDto();
  addNewSymbolForm: FormGroup;
  statusLst: any;
  isUpdate: boolean;
  categoryId: number;
  symbolId: any;
  mainMarketId: any;
  public categories: CategoryDto[];
  errors: any;
  isdisable: boolean;
  click: any = false;
  selectedFiles: File[] = [];
  constructor(
    public formBuilder: FormBuilder,
    private clientService: ClientService,
    private dialog: MatDialog,
    private symbolService: SymbolService,
    private route: ActivatedRoute,
    private pageTitleService: PageTitleService,
    private setupApi: SetupApi, injector: Injector
  ) {
    super(injector);
    if (!this.auth.isGranted(permissions.EditSymbols)) {
      this.isdisable = true;
    }
  this.getStatusLookup();
    this.getMarkets();
    this. getCurrencies();
  }


  ngOnInit(): void {

    this.pageTitleService.setTitle("Symbols");

    this.route.paramMap.subscribe(prams => {
      this.symbolId = +prams.get('id') as number;

      if (this.symbolId > 0) {
        this.geSymbol(this.symbolId);
        this.isUpdate=true;

      } else {
        this.title = this.translate('Add New Symbol');
        this.isUpdate=false;
      }


    })

  this.getAttachmentTypes();

    this.addNewSymbolForm = new FormGroup({
      SymbolCode: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SymbolNameAr: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SymbolNameEn: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Unit: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SymbolMarkat: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SectorID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      ReutersCode: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      CoverPerc: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      categoryId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      OpenPrice: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      UpperLValue: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      UpperLPerc: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      LowerLValue: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      LowerLPerc: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      CurrencyId: new FormControl({ value: '' } ),
      SymbolId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      mainMarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      attachmentType: new FormControl({ value: '' } ),
      NoSettlement: new FormControl({ value: '' } ),
    });


  }

  getAttachmentTypes(){ 
    this.setupApi.getAttachmentTypes().subscribe(res=>{
                this.attachmentTypes=res;
    }); 
  }

  getCurrencies(){ 
    this.setupApi.getCurrencies().subscribe(res=>{
                this.currencies=res;
    }); 
  }


  onFormSubmit() {
    this.click = false;
    this.errors = [];
    if (this.symbol.Id > 0) {

      this.symbolService.updateSymbol(this.symbol, this.symbol.Id).subscribe(() => {
    this.uploadFile(this.symbol.Id );
        this.toastr.success("Successfully!");
      },
        err => {

          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
        });

    } else {
      this.symbol.symbolMarket =this.symbolMarket;
      this.symbolService.insertSymbol(this.symbol).subscribe(res => {
      
 this.symbolId=Number(res);
 this.uploadFile(this.symbolId );
        this.toastr.success("Successfully!");
      },
        err => {
          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);

        }
      );
    }
  }


  geSymbol(id) {
    this.symbolService.getSymbol(id).subscribe(res => {

      this.symbol = res;
  
      if (this.symbol.Sector != null) {
        this.categoryId = this.symbol.Sector.CategoryID;
      } 
      var symbolMarkets=this.symbol.SymbolMarkets.filter(x=>x.Status==StatusEnum.Active);
      if (symbolMarkets != null &&  symbolMarkets.length>0) {
         this.symbol.symbolMarket = symbolMarkets[0];
         this.symbolMarket = symbolMarkets[0];
         this.symbol.MarketId= symbolMarkets[0].MarketId;
         
         this.mainMarketId=this.markets.filter(x=>x.Id== this.symbol.MarketId)[0].ParentMarketId;
         this.getSubMarket(   this.mainMarketId);
        }
if(this.isRtl()){
  this.title = this.translate('Update Symbol') + ' - ' + this.symbol.NameAr;
}else{
  this.title = this.translate('Update Symbol') + ' - ' + this.symbol.NameEn;
}



    });
  }


  getMarkets(){
    this.setupApi.getActiveMarkets( ).subscribe(res => {

      this.markets = res;
      this.mainMarkets=this.markets.filter(c=>c.ParentMarketId==null);

    });
  }

 
   getStatusLookup() {
    this.setupApi.getStatusLookup().subscribe(items => {
      this.symbolUnits = items.SymbolUnits;

    });

    this.setupApi.getCategories().subscribe(items => {
      this.categories = items;

      this.setupApi.getSectors().subscribe(itemsSectors => {
        this.sectors = itemsSectors;

      });

    });



  }
   getSectors(categorId) {

    var cate = this.categories.filter(x => x.CategoryID == categorId.value);
    cate.forEach(element => {
      this.sectors = element.Sectors;
    });
  }

  onChangeMainMarket(id) {
   
    this.getSubMarket( id.value);
  }

  getSubMarket(id){
    this.setupApi.getSubMarkets( id ).subscribe(items => {
      this.subMarkets = items;

    });
  }
  calUpperValue(){
    if(this.symbolMarket.UpperLPerc!=null){
 
     this.symbolMarket.UpperLValue=(this.symbolMarket.OpenPrice*
       (this.symbolMarket.UpperLPerc /100))+this.symbolMarket.OpenPrice;
    }
   }
 
   calLowerValue(){ 
     if(this.symbolMarket.LowerLPerc!=null){
 
       this.symbolMarket.LowerLValue=this.symbolMarket.OpenPrice-(this.symbolMarket.OpenPrice*
         (this.symbolMarket.LowerLPerc /100));
     }
   }
   DeleteAttachment(){
     
        let fileToUpload = new UploadAttachmentDto();
         
        this.setupApi.DeleteAttachment(fileToUpload).subscribe(res=>{

        });
   }

   changeOpenPrice(){
     this.calUpperValue();
     this.calLowerValue();
   }

   public importFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
          let file = event.target.files[index];
          // Don't allow file sizes over 1MB
          if (file.size < 50000000) {
              // Add file to list of files
             this.selectedFiles[index]=file;
          }
          else {
            this.toastr.warning("File: " + file.name + " is too large to upload.");
          }
      }
  }
  }
  public uploadFile(id :number) {
  
 
    let fileToUpload = new UploadAttachmentDto();
    this.selectedFiles.forEach(file => {

        fileToUpload.EntityType=EntityTypeEnum.Symbol;
        fileToUpload.file = file;  
        fileToUpload
        console.log(fileToUpload);
        const formData = new FormData();
        formData.append('file',       fileToUpload.file ,  fileToUpload.file.name);
        formData.append('EntityType',       fileToUpload.EntityType.toString()  );
        formData.append('EntityId',    id .toString() );
        formData.append('TypeId',    this.uploadAttachmentTypeId    );
        this.setupApi.UploadFiles(formData).subscribe((event) => {
          console.log(event);

          },err =>{
            console.log(err);     
          });
    });
  }
  
}
