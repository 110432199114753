import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
 
@Injectable()
export class EmxManagementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}

 
  getEmxUsers(): Observable<any> {
    
    return this.http.get<any>(`${this.API_URL}/getEMXUsers`);
  } 

  getEMXUsersById(id:any): Observable<any> {
    
    return this.http.get<any>(`${this.API_URL}/getEMXUsersById/`+id);
  } 


  addEMXUser(BrokerRequest: any ): Observable<any> {

    return this.http.post<any>(`${this.API_URL}/addEMXUser`, 
    BrokerRequest)
      .pipe(tap(data => {}));
  }

 
 
  updateEmxUser(BrokerRequest: any ,id :any): Observable<boolean> {

   return this.http.put<any>(`${this.API_URL}/updateEMXUser/`+id, 
   BrokerRequest)
     .pipe(tap(data => {}));
 }

 deleteEmxUser(id: any): Observable<boolean> {

   return this.http.delete<any>(`${this.API_URL}/deleteEMXUser/`+id).pipe(tap(data => {}));
 }

  
}