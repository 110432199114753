<form [formGroup]="addNewUserForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
                      

                      <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{ 'Email' | translate }}</mat-label>
                         <input class="form-control" matInput type="input" placeholder="Email" 
                          formControlName="email"  
                          [(ngModel)]="user.email"  >  
                    </mat-form-field>

                    <mat-form-field class="width50 form-field " appearance="outline">
                      <mat-label>{{ 'UserName' | translate }}</mat-label>
                       <input class="form-control" matInput type="input" placeholder="UserName" 
                        formControlName="userName"  
                        [(ngModel)]="user.userName"  >  
                  </mat-form-field>
                    <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{ 'phoneNumber' | translate }}</mat-label>
                         <input class="form-control" matInput type="input" placeholder="phoneNumber" 
                          formControlName="phoneNumber"  
                          [(ngModel)]="user.phoneNumber"  >  
                    </mat-form-field>
 
                    
 
                    <mat-form-field class="width50 form-field " appearance="outline">
                      <mat-label>{{ 'Ip Address' | translate }}</mat-label>
                    <input class="form-control" matInput type="input" placeholder="Ip Address" 
                        formControlName="ipAddress"  
                        [(ngModel)]="user.ipAddress"  >  
                  </mat-form-field>


                  
                    <mat-form-field class="width100 form-field " appearance="outline">
                        <mat-label>{{ 'role' | translate }}</mat-label>
                        <mat-select  [(ngModel)]="rolesName"  name="rolesName"
                             formControlName="rolesName"  multiple> 
                            <mat-option  *ngFor="let role of  roles" [value]="role.name">
                             {{role.name}}</mat-option>
                        </mat-select> 
                      </mat-form-field>
 

			 
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit() " type="submit"  [disabled]="!addNewUserForm.valid"  mat-raised-button color="warn">SUBMIT</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">CLOSE</button>
	
	</mat-dialog-actions>
</form>