import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from 'app/app-component-base';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { ClientService } from 'app/client-managment/services/client.service';
import { ApproveStatusEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { BankDto } from 'app/models/bank';
import { ClientBankDto } from 'app/models/client';
 import { ClientCompanyFormDialogComponent } from '../client-company-form-dialog/client-company-form-dialog.component';

@Component({
  selector: 'ms-client-bank',
  templateUrl: './client-bank.component.html',
  styleUrls: ['./client-bank.component.scss']
})
export class ClientBankComponent extends   AppComponentBase implements OnInit, OnDestroy  {

  ngOnDestroy() {

  }
  
  isUpdate:boolean =true;
  banks:BankDto[];
  private _clientId: number;
  errors: any;
  addNewBankForm: FormGroup;
  bank :ClientBankDto= new ClientBankDto;
  approveStatus=ApproveStatusEnum;
  @Input()
  status: number;
 @Input() set clientId(value: number) {
  this._clientId = value; 

 }

 @Input() set clientBanks(value: any) {
   if(value!=null){
     
     this.bank = value;
   }
 }
 
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _enumHelpers :EnumHelpers   , 
    private bankManagementService: BankManagementService,
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector);  

    }

 
  ngOnInit(): void {
    if(this.auth.isGranted(this._permissions.AssignClientsToBanks) ){
    this.isUpdate=false;
    }
    this.addNewBankForm= new FormGroup({

      BankID: new FormControl({ value: '' } ),
     // Balance: new FormControl({ value: '' } ),
      IBan: new FormControl({ value: '' } ),
     
    });
    this.bankManagementService.getActiveBanks().
    subscribe(res => {
      this.banks = res;
   
    },
      // err => console.log(err)
    );
  }
  
  getClientBank(id){
   this.clientService.getClientBanks(id).subscribe(res=>{
     if(res!=null){

      this.bank=res;
     }
   });
  }

    
  onBankFormSubmit() {
    this.errors=[];
    
    this.bank.ClientID=Number(this._clientId) ;
  
    this.clientService.addClientBank(this.bank ,this._clientId).subscribe(() => {
       
      this.toastr.success(  this.translate("Successfully"));
    },
      err => {

        this.errors.push(  this.translate(err.error[0]));
        this.toastr.error(  this.translate(err.error[0]));
    });

  }

 
}
