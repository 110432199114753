import { Component, OnInit, Input, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { CoreService } from '../../../service/core/core.service';
import { MenuItems } from '../../../core/menu/menu-items/menu-items';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from 'app/account/services/auth.service';
import { AppComponentBase } from 'app/app-component-base';

@Component({
	selector: 'ms-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss']
})

export class SideBarComponent extends AppComponentBase implements OnInit {

	@Input() menuList : any;
  @Input() verticalMenuStatus : boolean;
  public userEmail$: Observable<string>;
  public userRole$: Observable<string>;
	constructor(  
                private router: Router,
                public coreService: CoreService,
                public menuItems: MenuItems,
                private authService: AuthService , injector: Injector
                ) {
                
                  super(injector); 
   
                  this.userEmail$ = this.authService.getUserEmail$();
                 
                  this.userRole$ = this.authService.getUserRoles$();
                }

	ngOnInit() {
   
	}

 
 

}
