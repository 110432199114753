import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; 
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 
import { CommissionManagementService } from './services/commission-management.service';
import {CommissionManagementApi } from './api/commission-management.api';
import { CommissionManagementRoutes } from './commission-management.routing';
import { CommissionListComponent } from './containers/commission-list/commission-list.component';
 
import{CommissionFormDialogComponent} from './components/commission-form-dialog/commission-form-dialog.component'
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { SharedModule } from "app/shared/shared.module";


import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  imports: [  ReactiveFormsModule, 
    CommonModule,
    TranslateModule, 
    FlexLayoutModule, RouterModule.forChild(CommissionManagementRoutes),
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSidenavModule,
    SharedModule ,
    MatSelectModule,
          CommonModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      GridModule, DragDropModule,ResizableModule,
      CommonModule,
      FormsModule,  
      MatButtonModule, 
      MatCardModule, 
      MatMenuModule, 
      MatToolbarModule, 
      MatIconModule, 
      MatInputModule,
      MatButtonToggleModule, 
      MatDatepickerModule, 
      MatNativeDateModule, 
      MatProgressSpinnerModule,
      MatTableModule, 
      MatExpansionModule, 
      MatSelectModule, 
      MatSnackBarModule, 
      MatTooltipModule, 
      MatChipsModule, 
      MatListModule, 
      MatSidenavModule, 
      MatTabsModule, 
      MatProgressBarModule,
      MatCheckboxModule,
      MatSliderModule,
      MatRadioModule,
      MatDialogModule,
      MatGridListModule,
      ReactiveFormsModule,
      FlexLayoutModule,
        TranslateModule
  ],
  declarations: [ 
    CommissionListComponent ,CommissionFormDialogComponent
  ], 
  providers: [  CommissionManagementApi , CommissionManagementService]
})
export class CommissionManagementModule { }