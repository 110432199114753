import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { AnnouncementTypeEnum } from 'app/core/enums';
import { AnnouncementDto, AnnouncementRequestDto } from 'app/models/announcement';
import { NewsApi } from 'app/news/api/news-api';
import { SignalRService } from 'app/service/signalr/signalr.service';
import { NewViewComponent } from 'app/Shared/components/new-view/new-view.component';

@Component({
  selector: 'ms-news-slider',
  templateUrl: './news-slider.component.html',
  styleUrls: ['./news-slider.component.scss']
})
export class NewsSliderComponent extends AppComponentBase implements OnInit {
  filterOptions: AnnouncementRequestDto = new AnnouncementRequestDto();
  news: AnnouncementDto[];

  
  /* #region  declaration */
  sliderContent : any   ;
 marketId: any; 
 //ticker slider config
 sliderConfig  = {
	   "speed": 10000,
	   "autoplay": true,
	   "autoplaySpeed": 0,
	   "cssEase": 'linear',
	   "slidesToShow": 4,
	   "slidesToScroll": 4,
	   "arrows": false,
	   "dots": false,
	   "responsive": [
	   {
		   breakpoint: 1480,
		   settings: {
			   slidesToShow: 4,
			   slidesToScroll: 1
		   }
	   },
	   {
		   breakpoint: 1280,
		   settings: {
			   slidesToShow: 3,
			   slidesToScroll: 1
		   }
	   },
	   {
		   breakpoint: 960,
		   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 1,
			   speed: 7000
		   }
	   },
	   {
		   breakpoint: 599,
		   settings: {
			   slidesToShow: 1,
			   slidesToScroll: 1
		   }
	   }
   ]};
  FromDate: Date;
  ToDate: Date;

  /* #endregion */
  /* #region  constructor & on in it */
  constructor(private newsApi: NewsApi,private dialog: MatDialog,
	private readonly signalrService: SignalRService , injector: Injector,
	) {
	  super(injector);  
		signalrService.announcementChanges.subscribe(item => {
 
		        this.getFilteredNews();
		});
  }

  ngOnInit(): void {
	  // Set Date Filter
    this.filterOptions.FromDate=this.FromDate=new Date();
    this.filterOptions.ToDate=this.ToDate=new Date();
    this.getFilteredNews();
  }
  /* #endregion */
  /* #region  methods */

  // get filterd News by today
  getFilteredNews() {
  
    this.filterOptions.Take = 5;
 
 
   if(this.filterOptions.ToDate !=null){
    this.filterOptions.ToDate = new Date(this.ToDate).toDateString();
  }
  if(this.filterOptions.FromDate !=null){
    this.filterOptions.FromDate = new Date(this.FromDate).toDateString();
  }
 
    this.newsApi.getAnnouncements(this.filterOptions).subscribe(items => {
      this.news = items;  
    });

  
  }

  // open dialog to view the news

  viewNews(news){ 
	const dialog = this.dialog.open(NewViewComponent, {
		data:{news:news}  
	  });
	  dialog.afterClosed().subscribe(() => {
	  } );
  }

  /* #endregion */
}
