import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
 
import { PageTitleService } from 'app/core/page-title/page-title.service';
import{CommissionDto } from 'app/models/commission';
import {   MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { Router } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum } from 'app/core/enums';
import{EnumHelpers } from 'app/helper/EnumHelpers';

 
import { CommissionManagementService } from '../../services/commission-management.service';
import { AppComponentBase } from 'app/app-component-base';
import { CommissionFormDialogComponent } from 'app/commission-management/components/commission-form-dialog/commission-form-dialog.component';
import { MarketCommissionDialogComponent } from 'app/market-managment/components/market-commission-dialog/market-commission-dialog.component';
@Component({
  selector: 'ms-commission-list',
  templateUrl: './commission-list.component.html',
  styleUrls: ['./commission-list.component.scss']
})
export class CommissionListComponent   extends AppComponentBase  implements OnInit , OnDestroy{
 
  public items:  CommissionDto [] ;
  public actions:any;
  public action :any;
  dialogRef: MatDialogRef<DemoDialog>;
   
  selectBy:any;
  ngOnDestroy(){ 

	}

  constructor( private pageTitleService : PageTitleService ,
    private dialog: MatDialog  ,
    private commissionManagementService :CommissionManagementService,

    private _router: Router ,
    private _helper :EnumHelpers    , injector: Injector
    ) {
      super(injector);
      this.actions = _helper.getNames(StatusEnum);
  
    }

  ngOnInit(): void {
    this.getCommissions( ) ;
    setTimeout(() =>{ 
 			this.pageTitleService.setTitle("Commissions");
			},0);
      
  
  }

  getCommissions( ){ 
     this.commissionManagementService.getCommissions().subscribe(items=>{

       this.items=items;
 
    });
  }
  
 
    
  addNewDialog(){ 
    const dialog = this.dialog.open(CommissionFormDialogComponent, {
           data:{count : this.items.length}
      });
      dialog.afterClosed().subscribe((res) => { 
         this.getCommissions( );
         
      } );
  }
  edit(commission){ 
    const dialog = this.dialog.open(CommissionFormDialogComponent, {
        data:{commission:commission}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getCommissions( );
      } );
  }
  
  
  delete(commission) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this commission  ' + commission.CommissionNameEN + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.commissionManagementService.deleteCommission (commission.CommissionID).subscribe((res) => {
          this.getCommissions( );
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }


  marketCommission(data) {
    var name=data.CommissionNameEN;
    if(this.isRtl()){
      name==data.CommissionNameAR;
    }
    const dialog = this.dialog.open(MarketCommissionDialogComponent, {
     data: {  CommissionId:data.CommissionID ,name: 'Commission - ' +name  }
   });
   dialog.afterClosed().subscribe((res) => {
    this.getCommissions( ) ;
   }); 

 } 
 
}

 