import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { AnnouncementTypeEnum } from 'app/core/enums';
import { AnnouncementDto, AnnouncementRequestDto } from 'app/models/announcement';
import { NewsApi } from 'app/news/api/news-api';

@Component({
  selector: 'ms-news-by-symbol',
  templateUrl: './news-by-symbol.component.html',
  styleUrls: ['./news-by-symbol.component.scss']
})
export class NewsBySymbolComponent extends AppComponentBase implements OnInit {
  /* #region declaration*/
  news: AnnouncementDto;
 
  filterOptions: AnnouncementRequestDto = new AnnouncementRequestDto();
  ToDate: any;
  FromDate: any;
 
  SymbolCode: any;
  selectBy:any;
  allSymbol: any;
  SymbolName: any;
  /* #endregion */

  /* #region constructor & on in it */
  constructor( injector: Injector,
                private dialogRef: MatDialogRef<NewsBySymbolComponent>,
                // inject mat dialog if present
                @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
                private newsApi: NewsApi,
              ) {
    super(injector);
    // Check if symbol name and symbol code is null
    if (data.SymbolCode != null  ) {
          this.SymbolCode=data.SymbolCode;
    }
    if (data.SymbolName != null  ) {
      this.SymbolName=data.SymbolName;
}
   }
  public ngOnInit(): void {
    // values to the filter
    this.filterOptions.FromDate=this.FromDate=new Date();
    this.filterOptions.ToDate=this.ToDate=new Date();
    this.filterOptions.SymbolCode = this.SymbolCode;
    this.filterOptions.AnouncementType = AnnouncementTypeEnum.News;
     this.getFilteredNews();
    


  }
  /* #endregion */
  
  /* #region  methods */
  close() {
    this.dialogRef.close(false);
  }
 
  // get news list filtered by date and anouncement News and symbol
   getFilteredNews() {
  
    this.filterOptions.Take = 10000;
 
 
   if(this.filterOptions.ToDate !=null){
    this.filterOptions.ToDate = new Date(this.ToDate).toDateString();
  }
  if(this.filterOptions.FromDate !=null){
    this.filterOptions.FromDate = new Date(this.FromDate).toDateString();
  }
 
    this.newsApi.getAnnouncements(this.filterOptions).subscribe(items => {
      this.news = items;  
    });

  
  }
  /* #endregion */
  
}
