import { Injectable } from '@angular/core';
 
import { Observable } from 'rxjs';

import { EmxManagementApi } from '../api/emx-management.api'

@Injectable()
export class EmxManagementService {


  constructor(private  emxManagementApi: EmxManagementApi) { }

  getEmxUsers(): Observable<[]> {

    return this.emxManagementApi.getEmxUsers( );
  }
  getEMXUsersById(id:any): Observable<any> {
    
    return this.emxManagementApi.getEMXUsersById(id );
  } 

  updateEmxUser(EmxRequest: any ,id :any): Observable<boolean> {

    return this.emxManagementApi.updateEmxUser(EmxRequest,id);
  }

  addEMXUser(EmxRequest: any  ): Observable<any> {

    return this.emxManagementApi.addEMXUser(EmxRequest);
  }

  deleteEmxUser(id: any): Observable<boolean> {

    return this.emxManagementApi.deleteEmxUser(id);
  }
 
 
}