 

<div class="user-manage-list">

    <!-- <mat-card>

  </mat-card> -->

    <mat-card>


        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="mrgn-r-sm">
                </div>
                <div class="mrgn-l-sm mrgn-r-sm">
                  {{storeKeeper.StoreInfo?.StoreNameEn}}
                </div>
            </div>
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="80">
              <div  >
               <div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 200px)" fxFlex.lg="calc(100% - 200px)" fxFlex.xl="calc(100% - 200px)">
                <form [formGroup]="clientForm" class="popup-card-width add-new-user">
              
                  <div class="trade-crypto crypto-input-prefix"> 
                    
                    <div class="padding-input-form"> 
                      <mat-form-field class="width100 form-field " appearance="outline">
                        <mat-label>{{translate('Client')}}*</mat-label>
                        <mat-select  [(ngModel)]="selectedClientID"  name="ClientID"
                           formControlName="ClientID"  (ngModelChange)="getList()"> 
                           <mat-option>
                            <ngx-mat-select-search name="itemNameseaRCHa" formControlName="itemNameseaRCHa"
                              placeholderLabel="{{translate('Enter At Least Three Chars To Search')}}"
                              noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                              (ngModelChange)="changeClientFilter($event)">
                            </ngx-mat-select-search>
                          </mat-option>
                           <mat-option  *ngFor="let entity of  clients" [value]="entity.Id">
                           {{isRtl()?entity.NameAr:entity.NameEn}}</mat-option>
                        </mat-select> 
                       </mat-form-field>
                    
                    </div>
                  
                  </div>
              
  

              </form>
               </div>
 
              </div>
           </div>
       
        </div>
        <mat-divider></mat-divider>
        <div class="">

      <kendo-grid [data]="view"
       [resizable]="true"
        [reorderable]="true" 
        [filterable]="false" class="kendo-grid-list" [sort]="gridState.sort" [sortable]="true"  >

        <kendo-grid-column field="ClientName" title="{{translate('Client')}}" headerClass="grid-list-column-header" width="80">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
            <span>{{isRtl()?dataItem.ClientNameAr:dataItem.ClientNameEn}}</span>

                    </ng-template>
                </kendo-grid-column>

        <kendo-grid-column field="SymbolName" title="{{translate('Commodity')}}" headerClass="grid-list-column-header" width="80">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
            <span >{{isRtl()?dataItem.SymbolNameAr:dataItem.SymbolNameEn}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="OriginalQty" title="{{translate('Original Qty')}}" headerClass="grid-list-column-header" width="50">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.OriginalQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="BlockedQty" title="{{translate('Blocked Qty')}}" headerClass="grid-list-column-header" width="50">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.BlockedQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="SoldQty" title="{{translate('Sold Qty')}}" headerClass="grid-list-column-header" width="50">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.SoldQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="WastedQty" title="{{translate('Wasted Qty')}}" headerClass="grid-list-column-header" width="50">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.WastedQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="UnderSettlementTransferQty" title="{{translate('Under settlement transfer Qty')}}" headerClass="grid-list-column-header" width="60">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.UnderSettlementTransferQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="AvailableQty" title="{{translate('Available Qty')}}" headerClass="grid-list-column-header" width="50">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.AvailableQty}}
          </ng-template>
        </kendo-grid-column>


            </kendo-grid>



        </div>

    </mat-card>
</div>