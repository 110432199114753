import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgetUserPasswordRequest } from 'app/models/loginRequest';
import { AppConsts } from 'app/core/config';

@Component({
	 selector: 'ms-confirm',
	 templateUrl:'./confirm-component.html',
	 styleUrls: ['./confirm-component.scss'],
	 encapsulation: ViewEncapsulation.None,
})

export class ConfirmComponent {
	
 
	code  : string; 
	userId:string;
	constructor( public authService: AuthService,
				    public translate : TranslateService,
					
				    private route: ActivatedRoute ,
					private router: Router ) { 
  

			this.confirm( );
	}

	//register method is used to sign up on the template.
	confirm( ) { 
		this.userId = this.route.snapshot.queryParams.uid;
		this.code = this.route.snapshot.queryParams.code;
		// this.router.navigate(['/account/change-password']);
		this.authService.confirm(this.userId,this.code).subscribe(res=>{
	 
			var forgetUserPasswordRequest=new ForgetUserPasswordRequest();
			forgetUserPasswordRequest.userId=this.userId;
			forgetUserPasswordRequest.clientCallbackUrl=AppConsts.frontEndUrl+"/account/change-password";
			this.authService.forgotPasswordByUserId(forgetUserPasswordRequest).subscribe(res=>{
 
			}) ;
		}) ;
	}
	
}



