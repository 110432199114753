
export class AppConsts {

  static authApiUrl: string;
  static bussinessApiUrl: string;
  static frontEndUrl: string; 
  static signalRUrl: string; 
  static idelTimeout: number;
  static idelPeriod: number;

  static readonly userUrl = '/api/user';
  static readonly authUrl = '/api/auth';
  static readonly manageUrl = '/api/manage';
  static readonly orderUrl = '/api/order';
  static readonly setupUrl = '/api/setup';
  static readonly clientUrl = '/api/client';
  static readonly brokerUrl = '/api/broker';
  static readonly settlementUrl = '/api/settlement';
  static readonly cooperativeUrl = '/api/cooperative';
  static readonly marketWatchUrl = '/api/marketWatch';
  static readonly storeUrl="/api/store"
  static readonly globalMarketUrl = '/api/globalMarket';
  static readonly superAdminRole = 'superadmin';
  static readonly agriculturalCooperativeRole = 'AgriculturalCooperative';
  static readonly agriculturalCooperativeManagerRole = 'AgriculturalCooperativeManager';

  static readonly otherLanguageData = 'fr';

  static readonly monitorUrl = '/api/monitorServices';
  
}
