import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { BidDto } from 'app/models/bid';
import { EntityTypeEnum, MarketStatusEnum } from 'app/core/enums';
import { OrderDto } from 'app/models/order';
import { SetupApi } from 'app/Shared/api/setup.api';
import { take } from 'rxjs/operators';
import { OrderTicketDialogComponent } from '../orderTicket-dialog/orderTicket-dialog.component';
import { FileAttachmentDto } from 'app/models/client';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';

@Component({
  selector: 'ms-order-bid',
  templateUrl: './order-bid.component.html',
  styleUrls: ['./order-bid.component.scss']
})
export class OrderBidComponent extends AppComponentBase  implements OnInit {

  attachments:any;
  selectBy: any;
  @Input()
  orderChanges: OrderDto[];
  @Input() 
  _auctionId: any; 
  isPreOpen: any=false;
 
  companyID:any;
  brokerID:any;
  public clickedRowItem;
  items: any; 
 
  private _symbolCode: string;
  @Input() set symbolCode(value: string) {

  this._symbolCode = value;
  this.getBid( );

}

  marketStatus:any;

  constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService, private marketWatchApi: MarketWatchApi,
    private pageTitleService: PageTitleService,
    private dashboardParamService :DashboardParamService,
    private setupApi: SetupApi, private dialog: MatDialog
    ,  injector: Injector
    ) {
      super(injector);
      this.dashboardParamService.loadIsOpen.subscribe(res => {

        if (res != null) {  
            if (res==true) {
            this.isPreOpen = true;
          } else {
            this.isPreOpen = false;
          }
        }
  
      });

    this.dashboardParamService.loadMarketStatus.subscribe(res=>{
      if(res!=null){
        this.marketStatus=res;
        this.getBid( );
      }

    });
    signalrService.orderChanges.subscribe(item => {
      this.getBid();
    });
    signalrService.monitorServiceChanges.subscribe(item => {
      this.getBid();
    });

    if(this.isBroker()){
 
      this.getUserBrokerInfo().subscribe(res=>{
        
        if(res!=null  ){ 
          this.companyID=res.CompanyId;
          this.brokerID=res.BrokerId;
        } 
      }) 

    }
  }
 
  getBid() {
    if (this._symbolCode!=null && this._symbolCode!="") {
   
      this.marketWatchApi.getBidsBySymbol(this._symbolCode)
        .subscribe(items => {
          this.items = items; 
          if(this.marketStatus == MarketStatusEnum.Preopen||this.isPreOpen){
              this.items=   this.items.filter(x=>x.AuctionID==-1);
          }
          
        });
    }

  }
  downloadAttachment(order ){ 
    const editingDialog = this.dialog.open(OrderAttachmentComponent, {
      data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:true}
    });
    editingDialog.afterClosed().subscribe((res) => { });
  }

  public rowCallback(context: RowClassArgs) {
    if(context.dataItem.CompanyID!=this.companyID){
      return {
        myOrderColorBuy: true 
      };
    } 
    return {};
  }
  ngOnInit(): void {
    // this.  getBid();
  }
  onCellClick(e) { 
    this.clickedRowItem = e.dataItem;  
  }

  onDblClick( ) {
    if(this.auth.isGranted(permissions.AddOrders)){
    var order=this.clickedRowItem;
    order.OrderQty=order.RemainingQty;
    order.OrderType = 2;
    const editingDialog = this.dialog.open(OrderTicketDialogComponent, {
      data: { order: {...order },isBidAsk:true}
    });
    editingDialog.afterClosed().subscribe((res) => { });
  }
  }

  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(2))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }

}
