import { Injectable } from "@angular/core";

@Injectable()
export class AuctionHelper {
  public IsAllowClient(isEMX:any,auction :any ,myCompanyId:any ,companyId:any ){

       if(isEMX || (auction!=null&& auction.IsAllowClient   )
       || (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
     
       || (auction!=null &&myCompanyId ==auction.CompanyID))
           return true;
       else
           return false;
  }
  public IsAllowCompany(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
       if(isEMX || (auction!=null&& auction.IsAllowCompany )||
        (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)    
          || (auction!=null &&myCompanyId ==auction.CompanyID))
           return true;
       else
           return false;
  }
  public IsAllowBroker(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowBroker )||
     (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  }   
  public IsAllowShowPrice(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowAuctionPrice )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 
  public IsAllowShowQty(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowAuctionQty )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 

  public IsAllowMatchingType(isEMX:any,auction:any ,myCompanyId:any, auctionCompanyId:any ){
    if(isEMX || (auction != null && myCompanyId!=null&&auctionCompanyId!=null&&myCompanyId== auctionCompanyId))
        return true;
    else
        return false;
  } 

  public IsAllowOperationOrder(isEMX:any,auction:any ,myBrokerId:any, auctionBrokerId:any ){
    if(isEMX || (auction != null && myBrokerId!=null&&auctionBrokerId!=null&&myBrokerId== auctionBrokerId&&
        (auction.IsAllowEditOrderQty==true ||  auction.IsAllowEditOrderPrice==true) ))
        return true;
    else
        return false;
  }
  public IsAllowCapping(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(auction!=null&& auction.IsAllowCapping )
        return true;
    else
        return false;
  } 


  public IsAllowShowOrderCompany(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowOrderCompany )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 


  public IsAllowShowOrderBroker(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowOrderBroker )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 


  public IsAllowShowOrderClient(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowOrderClient )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 



  public IsAllowShowOrderQty(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowOrderQty )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 

  public IsAllowShowOrderPrice(isEMX:any,auction :any ,myCompanyId:any, companyId:any ){
    if(isEMX || (auction!=null&& auction.IsAllowShowOrderPrice )|| (myCompanyId!=null&&companyId!=null&&myCompanyId== companyId)
    || (auction!=null &&myCompanyId ==auction.CompanyID))
        return true;
    else
        return false;
  } 
}