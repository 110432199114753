  
  <div class="gene-card-title">
    <div fxLayout="row" fxLayoutAlign="start center">
       <div fxLayout="column">
          <h5 class="mrgn-b-none">{{'Market Watch' | translate}}</h5>
       </div>
       <span fxFlex></span>
 

       
    </div>
    <mat-divider></mat-divider>
 </div>
  
    <div class="table-responsive kendo-table">

        <kendo-grid [kendoGridBinding]="items"
        class="watch-kendo-grid"
        [resizable]="true"
        [reorderable]="true"
        
        [sortable]="true"
        filterable="menu"
        [columnMenu]="true"  > 
        <kendo-grid-column       [columnMenu]="false"   width="60">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
       
              <a    	*ngIf="auth.isGranted(_permissions.AddOrders)" (click)="createOrder(dataItem ,1)" class="trasaction-icon buy" title="{{ 'TranactionBuy' | translate }}">{{ 'TranactionBuyIcon' | translate }}</a> |
              <a   	*ngIf="auth.isGranted(_permissions.AddOrders)" (click)="createOrder(dataItem ,2)" class="trasaction-icon sell" title="{{ 'TranactionSell' | translate }}"> {{ 'TranactionSellIcon' | translate }}</a> 
                   
      
         </ng-template>
        
        </kendo-grid-column>
        
   <kendo-grid-column field="SymbolCode"  filterable="menu"
   [sortable]="true"
   [columnMenu]="true" title="{{ 'Symbol' | translate }}" width="150" >
    <ng-template kendoGridCellTemplate let-dataItem  >
     <span class="market-watch-symbol-c">{{dataItem.SymbolCode}} </span> 

     
     <br/>
     <span class="market-watch-symbol-n" *ngIf="isRtl()"> {{dataItem. NameAr}}   </span>
     <span  class="market-watch-symbol-n" *ngIf="!isRtl()"> {{dataItem.NameEn}}   </span>
     
      <!-- <a href="" class=""> <span  matPrefix class="fa fa-btc" aria-hidden="true"></span> {{dataItem.SymbolCode}}  </a> -->

    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="PriceUnit"  title="{{ 'Unit' | translate }}" width="80">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.PriceUnit | translate }}  
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="BidPrice"  title="{{ 'Bids' | translate }} " width="100">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span class="buy"> {{dataItem.BidPrice  | number}}  
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="AskPrice"  title="{{ 'Asks' | translate }} " width="100">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span class="sell"> {{dataItem.AskPrice| number }}  
      </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="Currency"  title="{{ 'Currency' | translate }} " width="100">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.Currency  | translate }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Change"  title="{{ 'Change' | translate }}" width="100">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.Change | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="PercentageChange" title="{{ 'Change %' | translate }}" width="100" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.PercentageChange | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>


         
     
        
        </kendo-grid>
           
        </div> 
       
   

    