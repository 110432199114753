import { Injectable } from '@angular/core';  
import { ClientReuqest } from 'app/models/client';
import { Observable } from 'rxjs';
import { ClientApi } from '../api/client.api';
  

@Injectable()
export class ClientService {

  
  constructor(private clientApi: ClientApi ) { }
 
  getActiveClients(): Observable< []> {
  
    return this.clientApi. getActiveClients( );
  } 

  GetRejectClients(): Observable< []> {

    return this.clientApi.GetRejectClients( );
  } 
  getClientCodeRelations(id:any): Observable< []> {

    return this.clientApi.getClientCodeRelations(id );
  } 
  
  GetPendingClients(): Observable< []> {
  
    return this.clientApi.GetPendingClients( );
  } 
  getClientCommentsById(id:any): Observable< []> {

    return this.clientApi.getClientCommentsById(id);
  } 

  getUnSeenClientCommentCount(id:any ,type:any): Observable<any> {
    return this.clientApi.getUnSeenClientCommentCount(id,type);
  } 

  addClientComment(request: any,id :any): Observable<boolean> {
  
    return this.clientApi.addClientComment( request,id);
    }
  
  
  seenComments(id :any, type:any): Observable<boolean> {
    return this.clientApi.seenComments(  id,type);
  }
  
 
getOnlyStoreClients(id:any): Observable< []> {
  return this.clientApi.getOnlyStoreClients(id);
} 
 
getClient(id:any): Observable< any> {
  return this.clientApi.getClient(id);
} 

 
insertClient(ClientRequest: any): Observable<number> {
 
  return this.clientApi.insertClient(ClientRequest);
}
assginClientCodeRelation(ClientRequest: any): Observable<any> {
 
  return this.clientApi.assginClientCodeRelation(ClientRequest);
}
updateClient(ClientRequest: any,id :any): Observable<boolean> {
  return this.clientApi.updateClient(ClientRequest,id);
}

deleteClient(id: any): Observable<boolean> {
  return this.clientApi.deleteClient( id);
}
addClientBank(ClientRequest: any,id :any): Observable<boolean> {

  return this.clientApi.addClientBank(ClientRequest, id);
}
 addStoreClient(ClientRequest: any,id :any): Observable<boolean> {

  return this.clientApi.addStoreClient(ClientRequest, id);
}
   searchClients(request: ClientReuqest): Observable<any> {

    return this.clientApi.searchClients(request);
  }
  getClientBanks(id: any): Observable<any> {

    return this.clientApi.getClientBanks(id);
  }

  getStoreByClientId(id: any): Observable<any> {

    return this.clientApi.getStoreByClientId(id);
  }

  GetCompanyClientByClientId(id: any): Observable<any> {
    return this.clientApi.GetCompanyClientByClientId(id);
  } 

  GetCompanyClientByClientCompanyId(id: any,companyId:any): Observable<any> {
    return this.clientApi.GetCompanyClientByClientCompanyId(id,companyId);
  } 

  UpdateCompanyClient(ClientRequest: any,id :any): Observable<boolean> {
    return this.clientApi.UpdateCompanyClient(ClientRequest,id);
    }
   insertCompanyClient(ClientRequest: any ): Observable<boolean> {
    return this.clientApi.insertCompanyClient(ClientRequest);
      }
      removeCompanyClient(id: any): Observable<any> {

        return this.clientApi.removeCompanyClient(id);
      }
 
      ChangeStatusClient(id: any): Observable<any> {

        return this.clientApi.ChangeStatusClient(id);
      }

      approveClient(ClientRequest: any,id :any): Observable<boolean> {
 
          return this.clientApi.approveClient(ClientRequest,id);
        }
    
        rejectClient(ClientRequest: any,id :any): Observable<boolean> {
    
         
          return this.clientApi.rejectClient(ClientRequest,id);
          }


            getAttachmentTypes( ): Observable<any> {
              return this.clientApi.getAttachmentTypes();
            } 
            getAttachmentsById( id:any): Observable<any> {
              return this.clientApi.getAttachmentsById(id);
            } 
            getClientByCompanyId( id:any): Observable<any> {
              return this.clientApi.getClientByCompanyId(id);
            } 
            getClientPurchasePower  (id: any   ,currencyId:any ): Observable<any> {
              return this.clientApi.getClientPurchasePower(id,currencyId);
            } 
            getAvilableQty  (filter:any ): Observable<any> {
              return this.clientApi.getAvilableQty(filter);
            } 

    
}