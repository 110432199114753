import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';

import { SignalRService } from '../../../service/signalr/signalr.service';
import { AuctionDto } from 'app/models/auction';
import { AuctionstatusEnum,  CompanyTypeEnum,  MatchTypeEnum,  OrderSatausEnum } from 'app/core/enums';
import { MarketWatchDto } from 'app/models/MarketWatch'; 
import { OrderDto } from 'app/models/order';
import { ToastrService } from 'ngx-toastr';
import { AuctionFormDialogComponent } from '../../components/auction-form-dialog/auction-form-dialog.component';
import { AuctionOrderTicketDialogComponent } from '../auction-orderTicket-dialog/auction-orderTicket-dialog.component';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';
import { AuctionStatisticsDialogComponent } from 'app/auction-management/components/auction-statistics-dialog/auction-statistics-dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { AuctionpendingTradesDialogComponent } from '../auction-pending-trades-dialog/auction-pending-trades-dialog.component';
import { id } from '@swimlane/ngx-charts';
import { AuctionHelper } from 'app/auction-management/auctionHelper';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from 'app/core/config';
import { AuctionNewsComponent } from '../auction-news/auction-news.component';
import { AuctionNewsListComponent } from '../auction-news/auction-news-list/auction-news-list.component';
 
@Component({
  selector: 'ms-auction-list',
  templateUrl: './auction-list.component.html',
  styleUrls: ['./auction-list.component.scss']
})
export class AuctionListComponent extends AppComponentBase  implements OnInit {
 

  
  @ViewChild('fileInput') fileInput: any;
  @Input()
  items: any;
  dialogRef: MatDialogRef<DemoDialog>;
  selectBy:any;

  @Output()  aucthionEmitter = new EventEmitter<number>();
  public clickedRowItem;

  orderStatus=OrderSatausEnum.Private;
  AuctionstatusEnum=AuctionstatusEnum;
  CompanyTypeEnum=CompanyTypeEnum;
  _MatchingType=MatchTypeEnum;
  companyType:any;
  form: FormGroup;
  companyID:any;
  symbolIds:any=[];
  brokerID:any;
  orderID: any;
  selectedOrderRow :OrderDto = new OrderDto();

  constructor(
    private readonly signalrService: SignalRService, 
    private dialog: MatDialog,
    private _helper :EnumHelpers   ,
    private Auctionservice: Auctionservice,
    private httpClient: HttpClient,
    private  _AuctionHelper :AuctionHelper,
    injector: Injector ) {

    super(injector);
    if(this.isBroker()){
   
      this.getUserBrokerInfo().subscribe(res=>{
        this.Auctionservice.getCompanySymbolsByCompanyId(res.CompanyId).subscribe(res=>{
          this.symbolIds=res;
          });
        this.companyID=res.CompanyId;  
         this.brokerID=res.BrokerId;
        }) ;
    }
    signalrService.auctionChanges.subscribe(item => { 
      let Auctions = JSON.parse(item); 

      Auctions.forEach((element, index) => {
    
        if(this.symbolIds!=null&&this.symbolIds.filter(c=>c==element.SymbolID).length > 0 ){
          if ((this.items.filter(x => x.Id === Auctions[index].Id).length > 0)) {
            if(this.clickedRowItem!=null){

              if(this.clickedRowItem.Id==  this.items.filter(x => x.Id === Auctions[index].Id)[0].Id){
                this.aucthionEmitter.emit(this.clickedRowItem.Id); 
              }
            }
            this.items.filter(x => x.Id === Auctions[index].Id)[0].Qty=element.Qty;
            this.items.filter(x => x.Id === Auctions[index].Id)[0].Price=element.Price;
            this.items.filter(x => x.Id === Auctions[index].Id)[0].OpenDate=element.OpenDate;
            this.items.filter(x => x.Id === Auctions[index].Id)[0].CloseDate=element.CloseDate;
            this.items.filter(x => x.Id === Auctions[index].Id)[0].Status=element.Status;
            this.items.filter(x => x.Id === Auctions[index].Id)[0].MarketStatus=element.MarketStatus;

        
          } else {
        
              this.items = [element, ...this.items];
          
      
        
          }
          this.items = this.items.filter(x=>x.Status!=AuctionstatusEnum.Executed  );
      
          if(this.items == null || this.items == undefined || this.items.length == 0)
          {
            this.clickedRowItem = null; 
            
            this.aucthionEmitter.emit( 0 ); 
          }
        }
      });
      
         
    });
  }

  ngOnInit() {


    this.GetAuctions();   
 

}
public GetAuctions(){

  if(this.isBroker()){
 
    this.getUserBrokerInfo().subscribe(res=>{
           

      this.Auctionservice.getAuctions(  res.CompanyId).subscribe(  items => {
        this.items=items; 
        if( this.items.length>0){
         
          this.aucthionEmitter.emit( this.items[0].Id); 
        }
    
      });
    });
  }
  else
   if(this.isEMX()){
    this.Auctionservice.getAuctions( null).subscribe(  items => {
      this.items=items; 
      if( this.items.length>0){
        this.aucthionEmitter.emit( this.items[0].Id); 
      }
  
    }); 
  }
   
  

}
  onCellClick(e) {
 
    this.clickedRowItem = e.dataItem;   
    this.aucthionEmitter.emit(this.clickedRowItem.Id); 
  }
 
  createOrder(auction ){

    var order = new OrderDto;
    order.AuctionID=auction.Id ;
    order.SymbolCode=auction.SymbolCode ;
    order.MarketID=auction.MarketId ;
    const createOrderDialog = this.dialog.open(AuctionOrderTicketDialogComponent, {
      data: { 
        order: order, 
        isAuction: true },
    });
    
      createOrderDialog.afterClosed().subscribe((res) => { 
        console.log(res);
      this.aucthionEmitter.emit( this.clickedRowItem.Id); 
    }); 
   }
 
   editAuctionOrder(auction){
  
    const editingDialog = this.dialog.open(AuctionFormDialogComponent, {
      data:{ auction:auction,
          companyType:auction.CompanyTypeId} ,
          height: '700px'
      }  );
    editingDialog.afterClosed().subscribe((res) => {this.GetAuctions();} );

   }

   pendingTradesAuction(auction){
    if(auction.Status!=this.AuctionstatusEnum.PreExecute){
      this.dialogRef = this.dialog.open(DemoDialog, {
        data: { message: 'Would you like to close the auction and check trades before the execution?#' + auction.Id + '?' },
        disableClose: false
      });
  
      this.dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes!') {
          auction.Status==this.AuctionstatusEnum.PreExecute;
          this.Auctionservice.preMatchAuction(auction,auction.Id).subscribe((res) => {
           
            const editingDialog = this.dialog.open(AuctionpendingTradesDialogComponent, {
              data:{auction:auction }   });
              editingDialog.afterClosed().subscribe((res) => { 
            
              } );
 
          }, err => {
            this.toastr.error("Error");
          //  console.log(err)
          });
        }
        this.dialogRef = null;
      });
    }else{
      const editingDialog = this.dialog.open(AuctionpendingTradesDialogComponent, {
        data:{auction:auction }   });
        editingDialog.afterClosed().subscribe((res) => { 
      
        } );
    }


   }

   getAttachments(auction){

      this.Auctionservice.getAuctionOrderId(auction.Id).subscribe(res => {
      this.orderID = res;
      const attachmentsDialog = this.dialog.open(OrderAttachmentComponent,  {
        data: { 
          OrderID: this.orderID,
          SymbolCode: auction.SymbolCode, 
          OnlyDownload:false }
      });
      attachmentsDialog.afterClosed().subscribe((res) => { });
    }); 
      
  }

  GetAuctionStatistics(auction){
    this.Auctionservice.getAuctionStatistics(auction.Id).subscribe(res=>{

      const statisticsDialog = this.dialog.open(AuctionStatisticsDialogComponent, {
        data: { 
          data: res,
          openDate: auction.OpenDate,
          closeDate: auction.CloseDate
        },
      });
      statisticsDialog.afterClosed().subscribe((res) => { });
      
    }) 
  }

  addNews(auction){
    const statisticsDialog = this.dialog.open(AuctionNewsComponent, {
      data: { 
        data: auction
      },
    });
    statisticsDialog.afterClosed().subscribe((res) => { });
  }

  showNews(auction){
    const statisticsDialog = this.dialog.open(AuctionNewsListComponent, {
      data: { 
        data: auction
      },
    });
    statisticsDialog.afterClosed().subscribe((res) => { });
  }

  IsOpen(auction){
    var isOpen = true;
     if(auction!=null){ 
   
      if (auction.Status != AuctionstatusEnum.Open)  {
        isOpen = false;
        
      }
     }

    return isOpen;
 
  }

   matchAuction(auction){

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to match this auction #' + auction.Id + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.Auctionservice.matchAuction(auction,auction.Id).subscribe((res) => {
            this.GetAuctions();
            this.toastr.success("Successfully!");
         
        }, err => {
          this.toastr.error("Error");
         // console.log(err)
        });
      }
      this.dialogRef = null;
    });
   }

   adjustmentAuction(auction){

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to allow adjustment this auction #' + auction.Id + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.Auctionservice.adjustmentAuction(auction,auction.Id).subscribe((res) => {
            this.GetAuctions();
            this.toastr.success("Successfully!");
         
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.dialogRef = null;
    });
   }
   unadjustmentAuction(auction){

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to disallow adjustment this auction #' + auction.Id + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.Auctionservice.unadjustmentAuction(auction,auction.Id).subscribe((res) => {
            this.GetAuctions();
            this.toastr.success("Successfully!");
         
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.dialogRef = null;
    });
   }
   Delete(auction){
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this auction #' + auction.Id + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.Auctionservice.deleteAuction( auction.Id).subscribe((res) => {
          this.GetAuctions();
           this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
       //   console.log(err)
        });
      }
      this.dialogRef = null;
    });
   }


  createAuctionOrder() {
    var auction = new AuctionDto;
    
    const editingDialog = this.dialog.open(AuctionFormDialogComponent, {
      data: { auction: auction  },
      height: '700px'
    });
    editingDialog.afterClosed().subscribe((res) => {
     
      this.GetAuctions();
    });
  }
 

  setTimer(data){
   
    if(data.Status==this.AuctionstatusEnum.Open){
      var hourTime="";
      var minuteTime="";
      var secondTime="";
       var endDate  = new Date(data.CloseDate);
       var openDate  = new Date(data.OpenDate);
       var startDate = new Date(); 
       if(startDate.getDate() ==endDate.getDate()){
       const [hoursClose, minutesClose, secondsClose] = data.CloseTime.split(':');   
        var TimeClose = new Date(endDate.getFullYear(), endDate.getMonth() ,endDate.getDate(), +hoursClose, +minutesClose, +secondsClose); 
        
        const [hoursOpen, minutesOpen, secondsOpen] = data.OpenTime.split(':');   
        var TimeOpen = new Date(openDate.getFullYear(), openDate.getMonth() ,openDate.getDate(), +hoursOpen, +minutesOpen, +secondsOpen); 
        if(TimeOpen.getTime()-startDate.getTime()>0){
         
        } 
        var diffClose=TimeClose.getTime()-startDate.getTime();
        if(diffClose>0){
          const hours =  Math.floor(diffClose /(1000 * 60 * 60) % 24 );
          const minutes = Math.floor( diffClose / (1000 * 60) % 60) ;
          const seconds = Math.floor(diffClose / (1000) % 60 ); 
          if((this.getDigitsOfNumber(hours)<2)){
            hourTime="0"+hours;
          }else{ hourTime= ""+hours;}
    
          if((this.getDigitsOfNumber(minutes)<2)){
            minuteTime="0"+minutes;
          }else{ minuteTime= ""+minutes;}
    
          if((this.getDigitsOfNumber(seconds)<2)){
            secondTime="0"+seconds;
          }else{ secondTime= ""+seconds;}
          return  hourTime+":"+minuteTime+":"+secondTime;
        }
       }
    }


    return  "00:00:00";
  }
 
  getDigitsOfNumber(number:any){
    return number.toString().length;
  }

  public isAllowPreTreade(auction:any){
    if(auction.Status==this.AuctionstatusEnum.Open
      || auction.Status==this.AuctionstatusEnum.Adjustment
       || auction.Status==this.AuctionstatusEnum.PendingExecution
       || auction.Status==this.AuctionstatusEnum.PreExecute){
             if(auction.MatchingType== MatchTypeEnum.Avg ||   auction.MatchingType== MatchTypeEnum.  Prorata||   auction.MatchingType== MatchTypeEnum.  Price ){
                  return true;
             }
       }
       return false;
  }

  uploadExcel(event: any) {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    this.httpClient.post
    (`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}` + '/UploadAuction', formData).subscribe(res => {
      this.toastr.success("Successfully Uploaded!");
    });
  }

}
