import { Injectable } from '@angular/core';
import { OrderReuqest } from 'app/models/order';
import { Observable } from 'rxjs';

import { PeriodService } from '../../shared/services/period.service';
 import {AuctionApi} from '../api/auction.api'
 

@Injectable()
export class Auctionservice {
 
  constructor(private auctionApi: AuctionApi, private periodService: PeriodService) { }

  GetAuctionsLog(request: OrderReuqest): Observable<any> {
    
    return this.auctionApi.GetAuctionsLog(request);
  } 
  getCompanySymbolsByCompanyId(id:any): Observable<[]> {

    return this.auctionApi.getCompanySymbolsByCompanyId(id);
  }
  getAuctions(id:any): Observable<[]> {

    return this.auctionApi.getAuctions(id);
  }  
  getAuctionView(id:any): Observable<any> {

    return this.auctionApi.getAuctionView(id);
  } 
  insertAuction(createOrderRequest: any): Observable<boolean> {

    return this.auctionApi.insertAuction(createOrderRequest);
  }

  updateAuction(orderRequest: any,id: any): Observable<boolean> {

    return this.auctionApi.updateAuction(orderRequest,id);
  }

  matchAuction(orderRequest: any,id: any): Observable<boolean> {

    return this.auctionApi.matchAuction(orderRequest,id);
  }
  preMatchAuction(orderRequest: any,id: any): Observable<boolean> {

    return this.auctionApi.preMatchAuction(orderRequest,id);
  }
  adjustmentAuction(orderRequest: any,id: any): Observable<boolean> {

    return this.auctionApi.adjustmentAuction(orderRequest,id);
  }
  updatependingTrade(orderRequest: any): Observable<any> {

    return this.auctionApi.updatependingTrade(orderRequest);
  }
  deletependingTrade(id: any): Observable<any> {

    return this.auctionApi.deletependingTrade(id);
  }
  unadjustmentAuction(orderRequest: any,id: any): Observable<boolean> {

    return this.auctionApi.unadjustmentAuction(orderRequest,id);
  }
  savePreexecutedTrade(id: any): Observable<any>{
    return this.auctionApi.savePreexecutedTrade(id);
  }
  getAuctionOrderId(id:any ): Observable<any> {   
     return this.auctionApi.getAuctionOrderId( id);
  }
  getAuctionStatistics(id:any ): Observable<any> {   
    return this.auctionApi.getAuctionStatistics( id);
 }
 getAuctionpendingTrades(acutionId:any,Skip :any ,Take :any ): Observable<any> {

  return this.auctionApi.getAuctionpendingTrades( acutionId,Skip,Take);
}
  deleteAuction( id: any): Observable<boolean> {

    return this.auctionApi.deleteAuction( id);
  }
  getTrades(Skip: any, Take: any  ){
    return this.auctionApi.getTrades( Skip,Take);
  }

  getCompanyTrades(Skip: any, Take: any ,companyId:any  ){
    return this.auctionApi.getCompanyTrades( Skip,Take,companyId);
  }

  getBids (AuctionId: any,companyId:any ,  isEMX:any ): Observable<[]> {

    return this.auctionApi.getBids( AuctionId,companyId,isEMX);
  }
  getAsks (AuctionId: any,companyId:any ,  isEMX:any ): Observable<[]> {
    return this.auctionApi.getAsks( AuctionId,companyId,isEMX);
  }
  getOrdersAuctionByDate(acutionId:any,Skip :any ,Take :any ): Observable<[]> 
  {   
     return this.auctionApi.getOrdersAuctionByDate( acutionId,Skip,Take);
  }
  getMyOrdersActionByDate( companyId :any ,acutionId:any,Skip :any ,Take :any ): Observable<[]> {
 
     return this.auctionApi.getMyOrdersActionByDate( companyId,acutionId,Skip,Take);
    }
    GetAuctionTranactions(id: any): Observable<any> {
        return this.auctionApi.GetAuctionTranactions(id);
    }

    addAuctionNews(auctionDto: any): Observable<any> {
      return this.auctionApi.insertAuctionNews(auctionDto);
  }

  getAuctionNews(auctionDto: any): Observable<any> {
    return this.auctionApi.getAuctionNews(auctionDto);
}
}
  