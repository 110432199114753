import { Injectable } from '@angular/core';
import { BrokerDto, CompanyDto } from 'app/models/broker';
import { Observable } from 'rxjs';

import { BrokerManagementApi } from '../api/broker-management.api'

@Injectable()
export class BrokerManagementService {


  constructor(private manageBrokerApi: BrokerManagementApi) { }
  
  getActiveBrokerNotHaveAccount(): Observable<[]> {

    return this.manageBrokerApi.getActiveBrokerNotHaveAccount( );
  }
  getAllBrokerCompanies(id:any): Observable<any> {
    
    return this.manageBrokerApi.getAllBrokerCompanies(id); 
  } 
  getBrokerCompany (id:any): Observable<any> {

    return this.manageBrokerApi.getBrokerCompany(id);
  }
  getMyBrokerCompany(companyId:any, brokerId: any): Observable<any> {

    return this.manageBrokerApi.getMyBrokerCompany(companyId, brokerId );
  }

  getCompanyBrokerByCompanyId(id:any): Observable<any> { 
    return this.manageBrokerApi.getCompanyBrokerByCompanyId(id);
  }

  getBrokers(): Observable<[]> {

    return this.manageBrokerApi.getBrokers();
  }

  getBroker(id: any): Observable<any> {
    return this.manageBrokerApi.getBroker(id);
  }


  insertBroker(BrokerRequest: any): Observable<any> {
    return this.manageBrokerApi.insertBroker(BrokerRequest);
  }

  updateBroker(BrokerRequest: any ,id :any): Observable<boolean> {

    return this.manageBrokerApi.updateBroker(BrokerRequest,id);
  }

  deleteBroker(id: any): Observable<boolean> {

    return this.manageBrokerApi.deleteBroker(id);
  }
  getBrokerCompanies(BrokerRequestDto :any){
    
    return this.manageBrokerApi.getBrokerCompanies( BrokerRequestDto);
  }
 
  searchBrokers(BrokerRequestDto :any){
    
    return this.manageBrokerApi.searchBrokers( BrokerRequestDto);
  }
 
}