export class PropertiesStatusLogDto {
     Id :any;
     ActionDate  :any;
     ActionType :any;
     ActionOn :any;
     ResponsibleId:any;
     ResponsibleNameAr:any;
     ResponsibleNameEn:any;
     ResponsibleName:any;
     ReasonId :any;
     Remark :any;
     Status:any;
     IgnoreUnsuspend:any;
     NotifiledEmail :any;
     CCEmail :any;
     CreatedUserId: string;
     CreatedDate: Date;
     LastUpdatedUserId: string;
     LastUpdatedDate: Date;
     UserId:any;
}

export class PropertiesStatusLogRequestDto {
    GeneralFilter   :any;
  ActionType   :any;
   ActionOn   :any;
  Skip   :any;
  Take   :any;
    From   :any;
    To   :any;

}
