import { Injectable } from '@angular/core';  
import { Observable } from 'rxjs';
import { WarehouseApi } from '../api/warehouse.api';
  

@Injectable()
export class WarehouseService {

  
  constructor(private warehouseApi: WarehouseApi ) { }
 
  
 

 

getWarehouses(): Observable< []> {
  return this.warehouseApi.getWarehouses();
} 

 getActiveWarehouses(): Observable< []> {
  return this.warehouseApi.getWarehouses();
} 
insertWarehouse(WarehouseRequest: any): Observable<number> {
 
  return this.warehouseApi.insertWarehouse(WarehouseRequest);
}

updateWarehouse(WarehouseRequest: any,id :any): Observable<boolean> {
  return this.warehouseApi.updateWarehouse(WarehouseRequest,id);
}

deleteWarehouse(id: any): Observable<boolean> {
  return this.warehouseApi.deleteWarehouse( id);
}

}