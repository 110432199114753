    export class BrokerDto {
        Id: number;
        UserId: string;
        CompanyId:number;
        CompaniesId: any[];
        Code: string;
        Name: string;
        ClientCallbackUrl: string;
        IpAddress: string;
        IsDeleted: boolean;
        IsActive: boolean;
        CreatedUserId: string;
        CreatedDate: Date;
        IssueDate: Date;
        ExpiryDate: Date;
        LastUpdatedUserId: string;
        LastUpdatedDate: Date;
        Email: string;
        UserName: string;
        Password: string;
        Status:number;
        BrokerType:number;
        ConfirmPassword: string;
        BrokerCompanies:BrokerCompanyDto[];
        RoleName: string;
        DateofBirth: Date;
        Address: string;
        MainAddress: string;
        NationalityID: number;
        IdentityTypeID: number;
        NationalID: string;
        CommercialRegister: string;
        Telephone: string;
        status: number;
        CompanyBroker:BrokerCompanyDto;
        MailAddress: string;
    }
    export class CompanyDto {
        Id: number;
        Code: string;
        Name: string;
        NameAr: string;
        AddressEn: string;
        AdressAr: string;
        CityID: number;
        Telephone: string;
        Email: string;
        Website: string;
        Subnet: string;
        IsDeleted: boolean;
        IsActive: boolean;
        CreatedUserId: string;
        CreatedDate: Date;
        LastUpdatedUserId: string;
        LastUpdatedDate: Date;
        Brokers: string[];
        TypeId:number;
        CompanyType:any;
         IdentityTypeID :any;
          IdentityText :any;
          NationalityID :any;
         IpAddress :any;
         Password: string;
         ConfirmPassword: string;
         ClientCallbackUrl: string;
         SymbolIds:any;
    }

    export class BrokerCompanyDto {
        Id: number;
        BrokerId: number;
        CompanyId: number;
        UserId: string;
        UserName: string;
        Email: string;
        IpAddress: string;
        IsActive: boolean;
        IsDeleted: boolean;
        CreatedUserId: string;
        CreatedDate: Date;
        LastUpdatedUserId: string;
        LastUpdatedDate: Date;
        Broker: string;
        Password: string;
        ConfirmPassword: string;
        ClientCallbackUrl: string;
        
    }

    
    export class BrokerRequestDto
    {
         Skip:any;

         Take:any; 
         BrokerType:any;
         CompanyId:any;
         BrokerId:any;
         IsActive:any;
         GeneralFilter:any;
    }
    export class CompanyTypeDto
    {
        Id:any;
        NameEn:any;
        NameAr:any;
    }