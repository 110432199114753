<div class="user-manage-list">
  <mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="mrgn-r-sm">
          <h4>Trades</h4>
        </div>
        <div class="mrgn-l-sm mrgn-r-sm">

        </div>
      </div>
      <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="">


      <kendo-grid #grid [kendoGridBinding]="items" [resizable]="true" [reorderable]="true" filterable="menu"  class="kendo-grid-list" 
        [columnMenu]="true" [sortable]="true" [kendoGridSelectBy]="selectBy">
        <kendo-grid-column field="TradeDate" title="{{ 'Date' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.TradeDate | date: 'dd/MM/yyyy'}}
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="TradeDate" title="{{ 'Time' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.TradeDate | date: 'h:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="SymbolCode" title="{{ 'Symbol' | translate }}"></kendo-grid-column>
        <!-- <kendo-grid-column field="TradeDate" title="Date"></kendo-grid-column>  -->
        <kendo-grid-column field="TradedQty" title="{{ 'Qty' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{dataItem.TradedQty | number }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="TradePrice" title="{{ 'Price' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{dataItem.TradePrice | number }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="TradePrice" title="{{ 'Rated Price' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{(dataItem.TradePrice  *exChangeRate)| number }}
            </span>
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>



    </div>

  </mat-card>
</div>