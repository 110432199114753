
<div class="popup-card-width add-new-user order-ticket">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title | translate}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
	
		<div class="gene-card-content">
			<div class="bank-details">

				<div class="mrgn-b-xs" mat-card-widget fxLayout="row wrap" fxLayoutAlign="space-between start"
					fxLayoutGap="5x" *ngIf="statisticsData != null && statisticsData != undefined">

					<kendo-grid  [kendoGridBinding]="auctionNews"
					class="market-kendo-grid"
					[resizable]="true"
					[reorderable]="true" 
					[sortable]="true" 
					filterable="menu"
					[columnMenu]="true" 
					(cellClick)="onCellClick($event)"    
					[kendoGridSelectBy]="selectBy"
				   > 
				   
					<kendo-grid-column field="HeaderAr"  filterable="menu"
					[sortable]="true"
					[columnMenu]="true" title="{{ 'HeaderAr' | translate }}" width="160" >
						<ng-template kendoGridCellTemplate let-dataItem  >
						<span class="market-watch-symbol-c">{{dataItem.HeaderAr}} </span> 					
						</ng-template>
					</kendo-grid-column>
					
					<kendo-grid-column field="HeaderEn"  filterable="menu"
					[sortable]="true"
					[columnMenu]="true" title="{{ 'HeaderEn' | translate }}" width="160" >
						<ng-template kendoGridCellTemplate let-dataItem  >
						<span class="market-watch-symbol-c">{{dataItem.HeaderEn}} </span> 					
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column field="DetailsAr"  filterable="menu"
					[sortable]="true"
					[columnMenu]="true" title="{{ 'DetailsAr' | translate }}" width="160" >
						<ng-template kendoGridCellTemplate let-dataItem  >
						<span class="market-watch-symbol-c">{{dataItem.DetailsAr}} </span> 					
						</ng-template>
					</kendo-grid-column>

					<kendo-grid-column field="DetailsEn"  filterable="menu"
					[sortable]="true"
					[columnMenu]="true" title="{{ 'DetailsEn' | translate }}" width="160" >
						<ng-template kendoGridCellTemplate let-dataItem  >
						<span class="market-watch-symbol-c">{{dataItem.DetailsEn}} </span> 					
						</ng-template>
					</kendo-grid-column>
				   </kendo-grid>
				</div>

			</div>
		</div>

	</mat-dialog-content>

</div>