import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { BrokerCompanyDto, BrokerDto, CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { Helpers } from 'app/helper/helpers';
import { AppComponentBase } from 'app/app-component-base';
import { AdminService } from 'app/admin/services/admin.service';
import { RoleDto, UseRoleDto } from 'app/models/role';
import { AppConsts } from 'app/core/config';
import { permissions } from 'app/core/permission';
import { ApproveStatusEnum, StatusEnum } from 'app/core/enums';

@Component({
  selector: 'ms-broker-form-dialog',
  templateUrl: './broker-form-dialog.component.html',
  styleUrls: ['./broker-form-dialog.component.scss']
})
export class BrokerFormDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  broker: BrokerDto;
  brokerId: any;
  companyId: any;
  roles: any;
  isClick:any=false;
  company: CompanyDto;
  companies: CompanyDto[];
  brokers: any ;
  addNewBrokerForm: FormGroup;
  statusLst: any;
  errors: any;
  disableBroker:any;
  disableCompany:any;
  rolesName: any;
  isUpdate:any;
  brokerCompany = new BrokerCompanyDto();
  randamPassword: any;
  filteredCompanies: any;

  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<BrokerFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,
    private adminService: AdminService,
    private brokerManagementService: BrokerManagementService,
    private companyManagementService: CompanyManagementService,

    private _helpers: Helpers, injector: Injector
  ) {
    super(injector);
    this.randamPassword = _helpers.generatePassword(12);
  
    this.getRoles();
    var userId=0;
    if(data.company!=null){
        this.companyId=data.company.Id;
        this.brokerCompany.CompanyId=this.companyId;
        this.disableCompany=true;
      if(data.brokerCompany!=null){
        userId=data.brokerCompany.UserId;
       
      }else{
        if(data.company.BrokerCompanies!=null && data.company.BrokerCompanies.length>0 ){
          userId=data.company.BrokerCompanies[0].UserId;

        }
      }
        this.adminService.GetRoleByUserId(userId).subscribe(
          res=>{
            res.forEach(element => {  this.rolesName=element;    });
         });

  
    }else{
      this.disableCompany=false;
      this.getCompanies();
    }

    this.title = "Manage Account";
    if (data.brokerCompany != null) {

      this.adminService.GetRoleByUserId(data.brokerCompany.UserId).subscribe(
        res=>{
          res.forEach(element => {
            this.rolesName=element;
          });
         
         } );

        this.brokerManagementService.getBroker(data.brokerCompany.BrokerId ).subscribe(items => {
  
        this.brokers=[];
        this.brokers.push(items) ;
  
      })
      this.brokerCompany = data.brokerCompany;
      this.isUpdate=true;

    }else{
      this.isUpdate=false;
      if (data.brokerId != null) {
        this.disableBroker=true;
        this.brokerId = data.brokerId;
        this.brokerCompany.BrokerId = this.brokerId;
      }else{
        this.brokerId = data.brokerId;
        this.brokerManagementService.getActiveBrokerNotHaveAccount().subscribe(res=>{
           this.brokers=res;
        });
        this.disableBroker=false;
      }
    }
    this.statusLst = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];

    if(data.broker!=null){
      
       this.broker=data.broker;
       this.disableBroker=true;
    }
  }

  ngOnInit(): void {

    this.addNewBrokerForm = new FormGroup({
      UserName: new FormControl({ value: '' }, Validators.compose([Validators.required])),

      BrokerId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Email: new FormControl({ value: '' }, Validators.compose([
        Validators.maxLength(50), Validators.email, Validators.required])),
      CompanyId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      companyName:  new FormControl({ value: 'companyName' }),
      IsActive: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IpAddress: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      rolesName: new FormControl({ value: '' }, Validators.compose([Validators.required]))
    });
    
  }
  close() {

    this.dialogRef.close(false);
  }



  getRoles() {

    this.adminService.getRolesByType(permissions.ISBroker).subscribe(
      res=>{  
         this.roles=res; 
         this.roles = this.roles.filter(y => y.name != AppConsts.superAdminRole);
      }
     );
  }

  getCompanies() {
    this.companyManagementService.getActiveCompanies().subscribe(items => {
      this.companies = items;
    });
  }

 
  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
 
  this.errors = [];
  this.isClick=true;
  if(this.isUpdate){ 
    this.companyManagementService.updateCompanyBroker(this.brokerCompany,
      this.brokerCompany.BrokerId).subscribe(res => {
        
        var userRole = new UseRoleDto();
        userRole.rolesName = [];
        userRole.userId = res;
        userRole.rolesName.push(this.rolesName);

        this.dialogRef.close(this.addNewBrokerForm.value);
        
        this.adminService.addUserRoles(userRole).subscribe(ress => {
          this.isClick=false;
        }) 
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
          this.isClick=false;
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        }
      );
  }else{

    this.brokerCompany.IsActive=true;
    this.brokerCompany.Password = this.randamPassword;
    this.brokerCompany.ConfirmPassword = this.brokerCompany.Password;
    this.brokerCompany.ClientCallbackUrl =  AppConsts.frontEndUrl+'/account/confirm';

    if(this.broker!=null){

      this.broker.Status = ApproveStatusEnum.Approve;
      this.broker.IsActive = true; 
      this.broker.CompanyBroker= this.brokerCompany;
      this.brokerManagementService. insertBroker(this.broker).subscribe(res => {
      this.dialogRef.close(this.addNewBrokerForm.value);
      this.broker.Id=res[1];
      var userRole = new UseRoleDto();
      userRole.rolesName = [];
      userRole.userId = res[0];
      userRole.rolesName.push(this.rolesName);

          this.adminService.addUserRoles(userRole).subscribe(ress => {
            this.isClick=false;
          });
        this.dialogRef.close(this.addNewBrokerForm.value);
        this.toastr.success(this.translate("Successfully"));
      } ,
      err => {
      this.isClick=false;
      this.errors.push(this.translate(err.error[0]));
      this.toastr.error(this.translate(err.error[0]));

      } 
      );
      }else{

      if(this.brokerCompany != null && this.brokerCompany.IpAddress != null 
        &&this.brokerCompany.CompanyId != null  && this.brokerCompany.UserName != null
        && this.brokerCompany.Email != null)
        {
          this.companyManagementService.addCompanyBroker(this.brokerCompany,
          this.brokerCompany.BrokerId).subscribe(res => {
        
        var userRole = new UseRoleDto();
        userRole.rolesName = [];
        userRole.userId = res;
        userRole.rolesName.push(this.rolesName);


        
        this.adminService.addUserRoles(userRole).subscribe(ress => {
          this.isClick=false;
        });
        this.dialogRef.close(this.addNewBrokerForm.value);
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
          this.isClick=false;
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));

        }
            );
       }
       else{
        this.toastr.error("Please, fill the required data");
       }
     }
  } 
}


  onChangeCompany(event) {

    if(this.companies != null)
      { 
        if(typeof event === 'string')
        {
        if(event.match(/^ *$/) !== null)
        this.filteredCompanies = this.companies;
        else
        this.filteredCompanies = this.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

}
