import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { SymbolMarketFormDialogComponent } from '../symbol-market-form-dialog/symbol-market-form-dialog.component';
 import { AppComponentBase } from 'app/app-component-base';



@Component({
  selector: 'ms-market-symbol-manage-list',
  templateUrl: './market-symbol-list.component.html',
  styleUrls: ['./market-symbol-list.component.scss']
})
export class MarketSymbolListComponent extends AppComponentBase implements OnInit, OnDestroy {

  public items: SymbolDto[];
  public lookups: StatusLookupDto;
  marketId: any;
  title: any;
  public actions: any;
  public action: any;
  selectBy:any;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }
  
  
 
  constructor(private pageTitleService: PageTitleService,
 
    private route: ActivatedRoute,
    private marketService: MarketService    ,
    private symbolService: SymbolService    ,
    private dialog: MatDialog  , 
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector); 
      this.actions = _helper.getNames(StatusEnum);
  
  
    }
    
  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.marketId = +prams.get('id'); 
 
    })
    this.route.paramMap.subscribe(prams => {
      this.title =   this.translate('Markets') ;
    })

    setTimeout(() => {
      this.pageTitleService.setTitle(  this.translate("Markets"));
    }, 0);

    this.getSymbolMarkets(StatusEnum.Active);

  }

  getSymbolMarkets(action) {
    this.action = action;
    this.symbolService.getSymbols().subscribe(items => {
      this.items = items; 
      this.items=this.items.filter(c=>c.MarketId==this.marketId); 

    });

  }


  getCommission(dataItem) {
 ;
   }

  addNewSymbolMarketDialog() {
   var symbol = new SymbolDto();
   symbol.MarketId=this.marketId;
    const dialog = this.dialog.open(SymbolMarketFormDialogComponent, {
      data: { symbol: symbol }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getSymbolMarkets(this.action);
    });
  }


  editSymbolMarket(symbol) {
    symbol.MarketId=this.marketId;
   const dialog = this.dialog.open(SymbolMarketFormDialogComponent, {
      data: { symbol: symbol }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getSymbolMarkets(this.action);
    }); 

  } 

  deleteSymbolMarket(symbol) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:  this.translate( 'would you like to delete this Symbol')+ ' # ' + symbol.SymbolCode +
       +this.translate( 'from this market')+' ?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.marketService.removeSymbolMarket(symbol.Id ,this.marketId).subscribe((res) => {
         this. getSymbolMarkets(this.action);
                 this.toastr.success(this.translate("Successfully"));
        }, err => {
          this.toastr.error(this.translate("Error"));
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }

 

}

