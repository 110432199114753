<div class="popup-card-dialog">
    <div class="gene-card-title">
       <div fxLayout="row" fxLayoutAlign="start center">
    
          <h2 mat-dialog-title class="mrgn-b-none paragraph">
              <span  *ngIf="isRtl()" [innerHTML]="news.HeadrAr"  style="direction: rtl;"> </span>
            <span  *ngIf="!isRtl()" [innerHTML]="news.HeaderEn"  style="direction: rtl;"> </span>
        </h2>
    </div>
    <mat-divider></mat-divider>
    <br>
 </div>
<mat-dialog-content mat-typography>

                <p class="date">{{news.CreatedDate | date: 'dd/MM/yyyy'}}     {{news.CreatedDate | date: 'h:mm:ss a'}}</p>

                <p *ngIf="news.AnnouncementTypeTxt != null"> {{ 'Type' | translate }}: {{news.AnnouncementTypeTxt}} </p>
                
                <p >{{ 'Commoddity Name' | translate }}: {{news.SymbolCodeName}}<span *ngIf="!news.SymbolCodeName">{{ 'General' | translate }}</span></p>
             
             
                <p> {{ 'Content' | translate }}: </p> 
                <p *ngIf="isRtl()" [innerHTML]="news.DetailsAr" style="direction: rtl;"></p>
                <p *ngIf="!isRtl()" [innerHTML]="news.DetailsEn"  style="direction: rtl;"></p>

                <!-- <p> {{ 'Content' | translate }}: {{!isRtl()?news.DetailsEn:news.DetailsAr}}</p> -->
                
                <p> {{ 'Urgent' | translate }}:
                    <span *ngIf="priortyTypes == 1" class="high"> {{ 'High' | translate }}<mat-icon>priority_high</mat-icon> </span>
                    <span *ngIf="priortyTypes == 2" class="low">{{ 'Low' | translate }}</span></p>

                    <div *ngFor="let attatchement of attatchements,let i = index"> 
                        <i class="{{getFileExtension(attatchement.FileName)}}" style="color:#f00808;" aria-hidden="true"></i> 
                        {{'Release '+(i+1)}}
                        <button mat-raised-button (click)="downloadAttachment(attatchement.FileName, attatchement.AttachmentsPath)">Download<i class="fa fa-arrow-circle-down" aria-hidden="true" ></i>
                        </button>
                        <button mat-raised-button (click)="ViewAttachment(attatchement.FileName, attatchement.AttachmentsPath)">view<i class="fa fa-eye" aria-hidden="true"></i>
                        </button>
                        
                  </div>

</mat-dialog-content>
<mat-dialog-actions class="padding-input-form pad-b-none right-align"> 
<button type="button" mat-dialog-close mat-raised-button  (click)="close()" color="primary">{{'CLOSE'| translate }}</button>

</mat-dialog-actions>
</div>
