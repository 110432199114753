<mat-card class="ticker-slider " dir="ltr" *ngIf="auth.isGranted(_permissions.GetMarketwatCh)">
    <ms-ticker-slider></ms-ticker-slider>
</mat-card>

<div fxLayout="row wrap" fxlayoutAlign="start start" class="dashboard-content">


    <kendo-splitter orientation="vertical" style="height: 50%;" *ngIf="!auth.isGranted(_permissions.ISStoreKeeper) ">
        <kendo-splitter-pane>
            <kendo-splitter>
                <kendo-splitter-pane [collapsible]="true" size="65%">
                    <div class="pane-content">
                        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                             mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                             [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)"
                             *ngIf="auth.isGranted(_permissions.GetMarketwatCh) ||  auth.isGranted(_permissions.GetAuctions) ">

                            <div fxLayout="row wrap" fxlayoutAlign="start start">
                                <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                                    <mat-card class="card-grid market-watch-grid">

                                        <mat-tab-group color="accent" class="dashboard-container"
                                                       (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedIndex">


                                            <mat-tab label="{{ 'Market Watch' | translate }}"
                                                     *ngIf="auth.isGranted(_permissions.GetMarketwatCh)">
                                                <ms-market-watch [_items]="marketWatch" [isfavourite]='false'
                                                                 (symbolEmitter)="getSymbol($event)"
                                                                 (favouriteEmitter)="changeFavourite($event)">
                                                </ms-market-watch>
                                            </mat-tab>


                                            <mat-tab label="{{ 'All Market Watch' | translate }}"
                                                     *ngIf="auth.isGranted(_permissions.GetMarketwatCh)">
                                                <ms-all-market-watch [_items]="allMarketWatch" [isfavourite]='false'
                                                                     (symbolEmitter)="getSymbol($event)"
                                                                     (favouriteEmitter)="changeFavourite($event)">
                                                </ms-all-market-watch>
                                            </mat-tab>


                                            <mat-tab label="{{ 'My Favourites' | translate }}"
                                                     *ngIf="auth.isGranted(_permissions.GetMarketwatCh)">
                                                <ms-market-watch [_items]="favouriteMarketWatch" [isfavourite]='true'
                                                                 (symbolEmitter)="getSymbol($event)"
                                                                 (favouriteEmitter)="changeFavourite($event)">
                                                </ms-market-watch>
                                            </mat-tab>


                                        </mat-tab-group>

                                    </mat-card>
                                </div>

                            </div>
                        </div>
                    </div>
                </kendo-splitter-pane>

                <kendo-splitter-pane>
                    <div class="pane-content">
                        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                             mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                             [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)"
                             *ngIf="auth.isGranted(_permissions.GetTrades)">
                            <div fxLayout="row wrap">

                                <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
 

                                    <mat-card class="card-grid market-watch-grid trades-grid">
                                        <mat-tab-group color="accent" class="dashboard-container" >
                                            <mat-tab label="{{ 'All Trades' | translate }}" >
                                                      <ms-market-trades marketId={{marketId}}> </ms-market-trades>
                                            </mat-tab>
                                            <mat-tab label="{{ 'My Trades' | translate }}" 
                                            >
                                                        <ms-market-my-trades marketId={{marketId}}> </ms-market-my-trades>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </mat-card>
                                </div>

                            </div>
                        </div>
                    </div>
                </kendo-splitter-pane>



            </kendo-splitter>
        </kendo-splitter-pane>
    </kendo-splitter>

    <kendo-splitter orientation="vertical" style="height: 50%;" *ngIf="!auth.isGranted(_permissions.ISStoreKeeper) ">
        <kendo-splitter-pane>
            <kendo-splitter>
                <kendo-splitter-pane [collapsible]="true" size="65%">
                    <div class="pane-content">

                        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)" *ngIf="auth.isGranted(_permissions.GetOrders)">
                            <div fxLayout="row wrap">

                                <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                                    <mat-card class="card-grid orders-grid">
                                        <div class="gene-card-title">
                                            <div fxLayout="row" fxLayoutAlign="start center">
                                                <div fxLayout="column" *ngIf="!isPreOpen">
                                                    <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" class="myOrderBtn">
                                                        <mat-icon>arrow_drop_down</mat-icon>{{orderTitle| translate}}
                                                    </button>
                                                    <mat-menu #anchorMenu="matMenu">
                                                        <a mat-menu-item (click)="setOrderStatus(item)" *ngFor="let item of orderStatusList">
                                                            {{item.Status + ' Orders' | translate }}

                                                        </a>

                                                    </mat-menu>


                                                </div>

                                                <span fxFlex></span>
                                                <mat-card-actions class="mat-pad-none margin-none">
                                                    <button mat-raised-button mat-button-sm color="primary" class="addOrderBtn" (click)="createOrder()" *ngIf="auth.isGranted(_permissions.AddOrders)">
                                                        {{ 'Add Order' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
                                                    </button>

                                                </mat-card-actions>
                                            </div>
                                            <mat-divider></mat-divider>
                                        </div>

                                        <ms-order-watch orderStatus={{orderStatus}} marketId={{marketId}}> </ms-order-watch>
                                    </mat-card>
                                </div>

                            </div>
                        </div>
                    </div>
                </kendo-splitter-pane>

                <kendo-splitter-pane>
                    <div class="pane-content" *ngIf="auth.isGranted(_permissions.GetBidsAsks)">
                        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                            <div fxLayout="row wrap" fxlayoutAlign="start start">
                                <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100" [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)">
                                    <mat-card class="card-grid bids-grid">
                                        <div class="gene-card-title">
                                            <div fxLayout="row" fxLayoutAlign="start center">
                                                <div fxLayout="column">
                                                    <h5 class="mrgn-b-none buy ">
                                                        {{ 'Bids' | translate }}

                                                        <span *ngIf="symbol!=null">
                                                            <span *ngIf="isRtl()"> {{symbol.NameAr}}  </span>
                                                            <span *ngIf="!isRtl()"> {{symbol.NameEn}}  </span>
                                                        </span>



                                                        {{auctionTitle}}
                                                    </h5>
                                                </div>

                                            </div>
                                            <mat-divider></mat-divider>
                                        </div>

                                        <ms-order-bid symbolCode={{symbolCode}}> </ms-order-bid>

                                    </mat-card>
                                </div>




                            </div>
                        </div>
                    </div>
                </kendo-splitter-pane>

                <kendo-splitter-pane>
                    <div class="pane-content" *ngIf="auth.isGranted(_permissions.GetBidsAsks)">


                        <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100" [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)">
                            <mat-card class="card-grid asks-grid">
                                <div class="gene-card-title">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div fxLayout="column">
                                            <h5 class="mrgn-b-none sell">
                                                {{ 'Asks' | translate }}
                                                <span *ngIf="symbol!=null">
                                                    <span *ngIf="isRtl()"> {{symbol.NameAr}}  </span>
                                                    <span *ngIf="!isRtl()"> {{symbol.NameEn}}  </span>
                                                </span>

                                                {{auctionTitle}}
                                            </h5>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                                <ms-order-ask symbolCode={{symbolCode}}> </ms-order-ask>

                            </mat-card>
                        </div>



                    </div>

                </kendo-splitter-pane>

            </kendo-splitter>
        </kendo-splitter-pane>




    </kendo-splitter>



    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
         *ngIf="auth.isGranted(_permissions.ISStoreKeeper) ">
        <ms-warehouse-home></ms-warehouse-home>
    </div>

</div>
<mat-card class="slider-message" *ngIf="!auth.isGranted(_permissions.ISStoreKeeper) ">
    <ms-news-slider></ms-news-slider>
</mat-card>