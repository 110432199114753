<form [formGroup]="warehouseFormGroup" class="client-form add-new-user">
  <div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
    <mat-card fxFlex="100%">
      <div class="gene-card-title">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column">
            <h4 class="mrgn-b-none">{{translate('Warehouse')}} </h4>
            <h5> {{isRtl()?warehouse.StoreNameAr:warehouse.StoreNameEn }} - {{warehouse.CreatedDate |date}} </h5>
          </div>
          <span fxFlex></span>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
        <div class="form-tab trade-crypto crypto-input-prefix">
          <mat-tab-group [selectedIndex]="this.selectedIndex" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="{{translate('Basic info')}}">
              <div class="gene-card-content">
                <div class="trade-crypto crypto-input-prefix">
                  <div class="pad-t-sm">
                    <div class="padding-input-form">

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Code')}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{translate('Code')}}"
                          formControlName="StoreCode" [(ngModel)]="warehouse.StoreCode">
                      </mat-form-field>

           
                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Type')}}*</mat-label>
                        <mat-select [(ngModel)]="warehouse.TradeTypeID" name="TradeTypeID" formControlName="TradeTypeID">
                          <mat-option *ngFor="let type of  tradeTypes" [value]="type.id">
                            {{type.name  | translate }}</mat-option>
                        </mat-select>
                      </mat-form-field>


                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Engilsh Name')}}*</mat-label>
                        <input class="form-control" matInput type="input" placeholder="Engilsh Name"
                          formControlName="StoreNameEn" [(ngModel)]="warehouse.StoreNameEn">
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Arabic Name')}}*</mat-label>
                        <input class="form-control" matInput type="input" placeholder="StoreNameAr"
                          formControlName="StoreNameAr" [(ngModel)]="warehouse.StoreNameAr">
                      </mat-form-field>


                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Telephone')}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{translate('Telephone')}}"
                          formControlName="Telephone" [(ngModel)]="warehouse.Telephone">
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Email')}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{translate('Email')}}"
                          formControlName="Email" [(ngModel)]="warehouse.Email">
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('TotalArea')}}</mat-label>
                        <input class="form-control" matInput type="number" placeholder="{{translate('TotalArea')}}"
                          formControlName="TotalArea" [(ngModel)]="warehouse.TotalArea">
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Location')}}*</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{translate('Location')}}"
                          formControlName="Location" [(ngModel)]="warehouse.Location"/>
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('Website')}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{translate('Website')}}"
                          formControlName="Website" [(ngModel)]="warehouse.Website"/>
                      </mat-form-field>

                      <mat-form-field class="width33 form-field " appearance="outline">
                        <mat-label>{{translate('City')}}*</mat-label>
                        <mat-select
                         [(ngModel)]="warehouse.CityID" 
                         name="CityID" 
                         formControlName="CityID">
                         <mat-option>
                          <ngx-mat-select-search name="city" 
                            placeholderLabel="{{translate('Enter At Least One Character')}}"
                            noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                            (ngModelChange)="onChangeCity($event)"
                            formControlName="CityName">
                          </ngx-mat-select-search>
                        </mat-option>
                          <mat-option *ngFor="let city of  filteredCities" [value]="city.CityID">
                            {{isRtl()?city.CityNameAr:city.CityNameEn}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                  </div>

                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start">

                <button (click)="onFormSubmit()" type="submit"  [disabled]="!warehouseFormGroup.valid"
                *ngIf="selectedIndex == 0 &&  (auth.isGranted(_permissions.EditStores) && this.warehouse.StoreID>0) 
                || (auth.isGranted(_permissions.AddStores) && !(this.warehouse.StoreID>0))" mat-raised-button color="warn">{{translate('SUBMIT')}}</button> 
               
                <a  [routerLink]="['/warehouses/warehouses/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

             </div>
            </mat-tab>

            <mat-tab label="{{translate('Sections')}}" [disabled]="!(warehouse.StoreID>0)">
              <store-section-list [storeSectionsList]="warehouse.StoreSections" [storeId]="warehouse.StoreID">
              </store-section-list>

            </mat-tab>

            <mat-tab label="{{translate('Symbols')}}" [disabled]="!(warehouse.StoreID>0)">
              <mat-form-field class="width33 form-field " appearance="outline">
                <mat-label>{{translate('Symbols')}}</mat-label>
                <mat-select 
                  [ngModel]="warehouseSelectedSymboldIds" 
                  [multiple]="true"
                  name="StoreSymbols"
                  formControlName="StoreSymbols">
                  <mat-option>
                    <ngx-mat-select-search name="store" 
                      placeholderLabel="{{translate('Enter At Least One Character')}}"
                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                      (ngModelChange)="onChangeSymbol($event)"
                      formControlName="StoreName">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let symbol of  filteredSymbols"
                    [disabled]="!auth.isGranted(_permissions.AddStoreSymbol) && !auth.isGranted(_permissions.RemoveStoreSymbol)"
                    (click)="changeStoreSymbol(symbol.Id)" [value]="symbol.Id">
                    {{isRtl()?symbol.NameAr:symbol.NameEn}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-tab>

            <mat-tab label="{{translate('Store Keepers')}}" [disabled]="!(warehouse.StoreID>0)">
              <store-keeper-list [StoreId]="warehouse.StoreID"></store-keeper-list>
            </mat-tab>
          </mat-tab-group>
        </div>

      </div>
    </mat-card>
  </div>
</form>