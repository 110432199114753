import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BankManagementService } from '../../services/bank-management.service';
import { BankDto } from 'app/models/bank';
import { AppComponentBase } from 'app/app-component-base';

@Component({
  selector: 'ms-bank-form-dialog',
  templateUrl: './bank-form-dialog.component.html',
  styleUrls: ['./bank-form-dialog.component.scss']
})
export class BankFormDialogComponent extends AppComponentBase  implements OnInit {

  title: string;
  bank: BankDto = new BankDto();
  addNewBankForm: FormGroup;
  statusLst:any;

  errors:any;
  companyCode:any;
  constructor( 
    public formBuilder: FormBuilder, injector: Injector,
    private dialogRef: MatDialogRef<BankFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,private bankManagementService: BankManagementService  
    ) { 
      super(injector); 
      if (data.bank != null) {
        this.bank=data.bank;
        this.title="Update Bank - "+ this.bank.BankNameEn;
      }else{
        this.title="ADD NEW BANK"
      //  this.company.Code=  this.generateCode(this.data.count);
      }
      
      this.statusLst= [
        { "id": true, "name": "Active" },
        { "id": false, "name": "In Active" }
      ];
    }

  ngOnInit(): void {
    this.addNewBankForm = new FormGroup({  
      BankNameEn:new FormControl({ value: '' }, Validators.compose([Validators.required])),
      BankNameAr:new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SwiftCode: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      FTPIP: new FormControl({ value: '' }),
      FTPUserName: new FormControl({ value: '' }),
      FTPPassword: new FormControl({ value: '' }),

    });
  }

  
  close() {
    this.dialogRef.close(false);
  }

    // onFormSubmit method is submit a add new user form.
 onFormSubmit() { 
     
      this.errors=[];
      if (this.bank.BankID > 0) {
 
        this.bankManagementService.updateBank(this.bank ,this.bank.BankID).subscribe(() => {
          this.dialogRef.close(this.addNewBankForm.value);
         
          this.toastr.success ("Successfully!");
        },
        err =>{   
          
          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
          } );
        
        //this.dialogRef.close(this.addNewOrderForm.value);
      } else {
          this.bankManagementService.insertBank(this.bank) . subscribe(res => { 
            this.dialogRef.close(this.addNewBankForm.value);
            this.toastr.success ("Successfully!");
           },
           err =>{   
             
             this.errors.push(err.error[0]);
             this.toastr.error(err.error[0]);
            // console.log(err)
             } 
         );
  
      
  
      }
    }


    
}
