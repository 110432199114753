
import { Routes } from '@angular/router';
import { MarketCalendarListComponent } from './components/market-calendar-list/market-calendar-list.component';
import { MarketCommissionListComponent } from './components/market-commission-list/market-commission-list.component';
import { MarketFormComponent } from './components/market-form/market-form.component';
import { MarketSymbolListComponent } from './components/market-symbol-list/market-symbol-list.component';
 import { MarketListComponent } from './containers/market-list/market-list.component';
import { SubMarketListComponent } from './containers/sub-market-list/sub-market-list.component';

export const MarketRoutes: Routes = [
   {
      path: '',
      redirectTo: 'markets',
      pathMatch: 'full'
   },
   {
      path: '',
      children: [
         {
            path: 'markets',
            component: MarketListComponent
         }
         , {

            path: 'market-symbols/:id',
            component: MarketSymbolListComponent
         }, {

            path: 'market-commission/:id',
            component: MarketCommissionListComponent
         },
         {
            path: 'create',
            component: MarketFormComponent
         }, {
            path: 'edit/:id',
            component: MarketFormComponent
         },
         {
            path: 'create-sub/:parentId',
            component: MarketFormComponent
         },
         
         {
            path: 'sub-markets',
            component: SubMarketListComponent
         }, {

            path: 'sub-markets/:id',
            component: SubMarketListComponent
         }
         , {

            path: 'calendar',
            component: MarketCalendarListComponent
         } 

         
      ]
   }
];