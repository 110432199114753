 
import { Routes } from '@angular/router';  
import { AdminGuard } from 'app/admin/guards/admin.guard';
import { permissions } from 'app/core/permission';
import { CompanyBrokerListComponent } from './components/company-broker-list/company-broker-list.component';
import { CompanyListComponent } from './containers/company-list/company-list.component';

export const CompanyManagementRoutes: Routes = [
  {
     path: '',
     redirectTo: 'companies',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
        {
           path: 'companies',
           component:  CompanyListComponent,
           data: { permission: permissions.GetCompanies }
        } 
       ,{

         path: 'broker-companies/:id',
         component:  CompanyBrokerListComponent
        } 
     ]
  }
];