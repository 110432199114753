import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ForgetPasswordRequest } from 'app/models/loginRequest';
import { Router } from '@angular/router';
import { AppConsts } from 'app/core/config';

@Component({
   selector: 'ms-forgot-password',
   templateUrl:'./forgot-password-component.html',
   styleUrls: ['./forgot-password-component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent {
	
   email      : string;
   password   : string;

   constructor( public authService: AuthService,
                public translate : TranslateService,
                		private router: Router ) { }

   /**
     * send method is used to send a reset password link into your email.
     */
   send(value) {
      var forgetPasswordRequest=new ForgetPasswordRequest();
     
      forgetPasswordRequest.email=value.email;
      forgetPasswordRequest.clientCallbackUrl=AppConsts.frontEndUrl+'/account/change-password';
      this.authService.forgotPassword(forgetPasswordRequest).subscribe(res=>{
    
         this.router.navigate(['/account/login']);
      },
      err => {  
      }
    );  
   }   
}



