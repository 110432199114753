<form [formGroup]="addNewClientStatementForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form"> 
 
	 
						<mat-form-field class="padding10 form-field"  appearance="outline"  *ngIf="!this.isBroker()" >
                            <mat-label>{{ 'Trading Company' | translate }}</mat-label>
                              <mat-select 							        
                                  [(ngModel)]="CompanyID"
                                  name="CompanyID" 
								  formControlName="CompanyId"
                                  class="filter-select" 
                                  [placeholder]="'Company' | translate "
                                  (selectionChange)="onChangeCompany($event);">
                                  <mat-label>{{ 'Company' | translate }}</mat-label>	
                              <mat-option>								
                                <ngx-mat-select-search name="companySearch" 
								formControlName="companyNameseaRCHbs"
                                  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                               >
                                </ngx-mat-select-search>
                                <ngx-mat-select-filter></ngx-mat-select-filter>
                              </mat-option>
                                <mat-option >	{{ 'All' | translate }} </mat-option>
                                  <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                    {{isRtl()?company.NameAr:company.Name}}
                                </mat-option>
                              </mat-select> 
                          </mat-form-field>
                    
                            <mat-form-field appearance="outline"  class="padding10 form-field">
                               <mat-label>{{ 'Client' | translate }}</mat-label>
                                <mat-select    
                                   [(ngModel)]="ClientStatement.ClientId" 
                                    name="ClientId"	 
									formControlName="ClientId"
                                    class="filter-select"
                                  [placeholder]="'Client' | translate "
                                 >
                                  <mat-label>{{ 'Client' | translate }}</mat-label>								
                                  <mat-option>								
                                    <ngx-mat-select-search name="clientSearch" 
									formControlName="itemNameseaRCHb"
                                      placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                      (ngModelChange)="changeClientFilter($event)">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                    <mat-option > {{ 'All' | translate }} </mat-option>
                                      <mat-option *ngFor="let client of clients" [value]="client.Id">
                                        {{isRtl()?client.NameAr:client.NameEn}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>  
 

					<mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Balance Type' | translate }}</mat-label>
						<mat-select  
						[disabled]='isApprove' readonly='isApprove'
						[(ngModel)]="ClientStatement.BalanceType "
						   formControlName="BalanceType">
 
						   <mat-option *ngFor="let balanceType of balanceTypes" [value]="balanceType.id">
							{{balanceType.name  | translate }}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Date' | translate }}</mat-label>
						<input matInput
						[disabled]='isApprove' readonly='isApprove'
							  placeholder="Choose a date" 
							  formControlName="BalanceDate"
							  [matDatepicker]="picker2"
							  [(ngModel)]="ClientStatement.BalanceDate" 
							  name="OpenDate" (click)="picker2.open()">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						<mat-datepicker #picker2></mat-datepicker>
				     </mat-form-field>

 				 	 <mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Currency' | translate }}</mat-label>
						<mat-select  
						[disabled]='isApprove' readonly='isApprove'
						[(ngModel)]="ClientStatement.CurrencyId "   formControlName="CurrencyId">
 
						   <mat-option *ngFor="let currency of currencies" [value]="currency.Id">
						  	 <span *ngIf="isRtl()">{{currency.NameAr}}</span>
							 <span *ngIf="!isRtl()">{{currency.NameEn}}</span>
						   </mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Amount' | translate }}</mat-label>
					    <input class="form-control"   matInput type="number" placeholder="{{ 'Amount' | translate }}" 
						  formControlName="Amount"  
						  [(ngModel)]="ClientStatement.Amount"     
					   >  
					</mat-form-field>


					<mat-form-field class="width100  form-field " appearance="outline">
						<mat-label>{{ 'Note' | translate }}</mat-label>
					    <input class="form-control"   matInput type="input" placeholder="{{ 'Note' | translate }}" 
						  formControlName="Remark"  
						  [disabled]='isApprove'  
						  [(ngModel)]="ClientStatement.Remark"     
					   >  
					</mat-form-field>
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  
		 
		[disabled]="!addNewClientStatementForm.valid"  mat-raised-button color="warn">
		{{ 'SUBMIT' | translate }}  </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">
			
			{{ 'CLOSE' | translate }} 
			</button>
	
	</mat-dialog-actions>
</form>