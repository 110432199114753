import { Injectable } from '@angular/core';  
import { OrderReuqest } from 'app/models/order';
import { Observable } from 'rxjs'; 
import { SettlementApi } from '../api/settlement.api';

  

@Injectable()
export class SettlementService {

  
  constructor(private settlementApi: SettlementApi ) { }
  

  getClientStatements(request: any): Observable<any> {

    return this.settlementApi.getClientStatements(request);
  }
  getClientUnSettlements(request: any): Observable<any> {

    return this.settlementApi.getClientUnSettlements(request);
  }
  GetStatementsDiskLogs(request: any): Observable<any> {

    return this.settlementApi.GetStatementsDiskLogs(request);
  }

  updateClientStatement(id:any,request : any): Observable<any> {
  
    return this.settlementApi.updateClientStatement(id,request);
  }
  createClientStatement(request : any): Observable<any> {
  
    return this.settlementApi.createClientStatement(request);
  }
  approveClientStatement(id:any,request : any): Observable<any> {
  
    return this.settlementApi.approveClientStatement(id,request);
  }
  rejectClientStatement(id:any): Observable<any> {
  
    return this.settlementApi.rejectStatement(id);
  }
  CancelClientStatement(id:any): Observable<any> {
  
    return this.settlementApi.CancelClientStatement(id);
  }
  UploadClientStatementFile(file:any): Observable<any> {
  
    return this.settlementApi.UploadClientStatementFile(file);
  }
  approveClientStatementDisk(id:any): Observable<any> {
  
    return this.settlementApi.approveClientStatementDisk(id);
  }
  rejectClientStatementDisk(id:any): Observable<any> {
  
    return this.settlementApi.rejectClientStatementDisk(id);
  }

   
  getClientBalances(request: any): Observable<any> {

    return this.settlementApi.getClientBalances(request);
  }
  getClientPurchasePower(id :any,  currencyId :any ): Observable<any> {
    return this.settlementApi.getClientPurchasePower(id,currencyId);
  }
  getClientPurchasePowers(id :any ): Observable<any> {
    return this.settlementApi.getClientPurchasePowers(id);
  }
  getUnSettlementsTrades(tradeRequest: any): Observable<any> {
    return this.settlementApi.getUnSettlementsTrades(tradeRequest);
  }
  getCancelUnSettlementsTrades(tradeRequest: any): Observable<any> {
    return this.settlementApi.getCancelUnSettlementsTrades(tradeRequest);
  }

  getBankMasrClientInquiriesLog(filter: any): Observable<any> {
    return this.settlementApi.getBankMasrClientInquiriesLog(filter);
  }
  getBankMasrPaymentsLog(filter: any): Observable<any> {
    return this.settlementApi.getBankMasrPaymentsLog(filter);
  }
  getBankMasrTransactionInquiriesLog(filter: any): Observable<any> {
    return this.settlementApi.getBankMasrTransactionInquiriesLog(filter);
  }

  saveSettlementTrades(data: any): Observable<any> {
    return this.settlementApi.saveSettlementTrades(data);
  }
  cancelSettlementTrades(data: any): Observable<any> {
    return this.settlementApi.cancelSettlementTrades(data);
  }
}