import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { permissions } from 'app/core/permission';
import { MarketService } from 'app/market-managment/services/market.service';
 

import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ms-market-form-dialog',
  templateUrl: './market-form-dialog.component.html',
  styleUrls: ['./market-form-dialog.component.scss']
})
export class MarketFormDialogComponent   extends AppComponentBase implements OnInit {

  title: string;
  public symbolUnits: MarketDto[];
  market: MarketDto = new MarketDto();
  addNewMarketForm: FormGroup;
  statusLst:any;
  isUpdate :boolean;
  errors:any;  
  isreadonly:boolean;
  click:any=false;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarketFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private marketService: MarketService,    injector: Injector
    ) {
      super(injector);
  
 
      if( !this.auth.isGranted(permissions.EditMarkets)){
        this.isreadonly=true;
      }
      if (data.market.Id!= null) {
        this.market=data.market;
  
        this.isUpdate=true;
        this.title=this.translate("Update Market")+" - "+ this.market.NameEn;
      }else{
        this.isUpdate=false;
        this.title=this.translate("Add New Market" );
      }
    }

  ngOnInit(): void {
    this.addNewMarketForm = new FormGroup({ 
       NameAr: new FormControl({ value: '' }, Validators.compose([Validators.required])),
       NameEn: new FormControl({ value: '' }, Validators.compose([Validators.required])) ,
       Code: new FormControl({ value: '' }, Validators.compose([Validators.required])) ,
       IsActive: new FormControl({ value: '' }, Validators.compose([Validators.required])) 
    });
    this.statusLst= [
      { "id": true, "name": this.translate("Active" )},
      { "id": false, "name": this.translate("In Active") }
    ];
  }

  
  close() {
    this.dialogRef.close(false);
  } 

  onFormSubmit() {  
    if(!this.click){
      this.click=true;
    this.errors = [];   
    if (this.market.Id > 0) { 
 
      this.marketService.updateMarket( this.market,this.market.Id ).subscribe(() => {
        this.dialogRef.close(this.addNewMarketForm.value);
        this.toastr.success(this.translate("Successfully!"));
      },
        err => {

          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
          this.click=false;
        });

    } else {
      this.market.ParentMarketId=null;
      this.marketService.insertMarket(this.market).subscribe(res => {
        this.dialogRef.close(this.addNewMarketForm.value);
        this.toastr.success(this.translate("Successfully!"));
      },
        err => {
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
          this.click=false;

        }
      ); 
    }
  }
  }



    
}
