 
import { Routes } from '@angular/router';  
 import { CommissionListComponent } from './containers/commission-list/commission-list.component';

export const CommissionManagementRoutes: Routes = [
  {
     path: '',
     redirectTo: 'commisions',
     pathMatch: 'full' 
  },
  {
     path: '',
     children: [
        {
        
           path: 'commisions',
           component:  CommissionListComponent
        }  
     ]
  }
];