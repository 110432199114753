  
import { Routes } from '@angular/router';  
import { ClientListComponent } from './containers/client-list/client-list.component';
 import {ClientFormComponent} from './components/client-form/client-form.component';

export const clientManagmentRoutes: Routes = [
  {
     path: '',
     redirectTo: 'clients',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
  
         {
            path: 'clients/:id',
            component:  ClientListComponent
         } ,
         {
          path: 'ExpiryClients/:id/:IsExpiry',
          component:  ClientListComponent
       }   
      
         ,{
            path: 'create/:status',
            component:  ClientFormComponent      
          },{
            path: 'Edit/:id/:status',
            component:  ClientFormComponent      
          }
          ,{
            path: 'Assign/:id/:status/:userType',
            component:  ClientFormComponent      
          }

          

     ]
  }
];