  
  <div class="gene-card-title">
    <div fxLayout="row" fxLayoutAlign="start center">
       <div fxLayout="column">
          <h5 class="mrgn-b-none">{{'Orders' | translate}}</h5>
       </div>
       <span fxFlex></span>
       <mat-card-actions class="mat-pad-none margin-none">
                        

        <button mat-raised-button mat-button-sm color="primary" class="addOrderBtn" (click)="createOrder()"  >
            {{'Add Order' | translate }} <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
        </button>

    </mat-card-actions>

       
    </div>
    <mat-divider></mat-divider>
 </div>
  
    <div class="table-responsive kendo-table">

        <kendo-grid [kendoGridBinding]="items"
        class="order-kendo-grid"
        [resizable]="true"
        [reorderable]="true"
        
        [sortable]="true"
        filterable="menu"
        [columnMenu]="true"
         
        
        > 
         
        <kendo-grid-column field="OrderID" title="" width="50" [columnMenu]="false">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
              <span   [ngClass]=
              "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
             
              >
                {{dataItem.OrderID}} 
              </span> 
         </ng-template>
        
        </kendo-grid-column>
         
        

        
        <kendo-grid-column  field="OrderDate" title="{{ 'Time' | translate }}" width="100">
         <ng-template kendoGridCellTemplate let-dataItem>
           <span   [ngClass]=
           "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
           >
           
           {{dataItem.OrderDate | date: 'h:mm:ss a'}}
           </span> 
         </ng-template>
         </kendo-grid-column>
        <kendo-grid-column  field="OrderDate"  title="{{ 'Date' | translate }}" width="100">
         <ng-template kendoGridCellTemplate let-dataItem>
           <span   [ngClass]=
           "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
           >
           
           {{dataItem.OrderDate | date: 'dd/MM/yyyy'}}
           </span> 
         </ng-template>
         </kendo-grid-column>
        
         <kendo-grid-column field="ClientCode"  title="{{ 'Client Code' | translate }}" width="130"
         *ngIf="auth.isGranted(_permissions.ViewClientOrder)">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
            >
              {{dataItem.ClientCode}} 
            </span> 
          </ng-template>
         </kendo-grid-column>
         
 
         
         
         
         <kendo-grid-column field="SymbolCode"   title="{{ 'Symbol' | translate }}" width="90"
         
         >
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
            >
              {{dataItem.SymbolCode}} 
            </span> 
          </ng-template>
         </kendo-grid-column>
        
        <kendo-grid-column field="OrderPrice" title="{{ 'Price' | translate }}"  width="80">
        
         <ng-template kendoGridCellTemplate let-dataItem>
           <span   [ngClass]=
           "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
          >
           <span   > MKT</span>
           </span> 
           
         </ng-template>
        </kendo-grid-column>
        

         

        
        <kendo-grid-column field="OrderQty" title="{{ 'Qty' | translate }}" width="80"
       >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
            *ngIf="dataItem.OrderQty>0"  > 
            {{dataItem.OrderQty | number }}
            </span>  
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
            *ngIf="!dataItem.OrderQty>0"  > 
            {{(dataItem.OrderValue /dataItem.OrderPrice) | number }}
            </span> 
          </ng-template> 
         </kendo-grid-column>


        <kendo-grid-column field="OrderValue" title="{{ 'Value' | translate }}" width="120" >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
            
        *ngIf="dataItem.OrderValue>0"> 
                  {{dataItem.OrderValue | number }}
            </span>  
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
            
        *ngIf="!dataItem.OrderValue>0"> 
                  {{dataItem.OrderQty *dataItem.OrderPrice | number }}
            </span> 
          </ng-template> 
         </kendo-grid-column>

         
        <kendo-grid-column field="" title="{{ 'CalcMethod' | translate }}" width="160"
        >
         <ng-template kendoGridCellTemplate let-dataItem  >
           <span   [ngClass]=
           "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }" 
           *ngIf="dataItem.OrderQty === 0"> 
                 {{'Value'| translate }}
           </span>  

           <span   [ngClass]=
           "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }" 
           *ngIf="dataItem.OrderValue === 0"
          > 
                 {{'Qty'| translate }}
           </span>   

         </ng-template> 
 
        </kendo-grid-column>

         <kendo-grid-column field="BrokerCode"  title="{{ 'Broker' | translate }}" width="90"
         *ngIf="auth.isGranted(_permissions.ViewBrokerOrder)">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
            >
              {{dataItem.BrokerCode}} 
            </span> 
          </ng-template>
         </kendo-grid-column>
         
         <kendo-grid-column field="CompanyCode"  title="{{ 'Company' | translate }}" width="110"
         *ngIf="auth.isGranted(_permissions.ViewFirmOrder)">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
            <span   [ngClass]=
            "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
            >
              {{dataItem.CompanyCode}} 
            </span> 
          </ng-template>
         </kendo-grid-column>

         <kendo-grid-column field="RefOrderID" title="{{ 'RefOrderID' | translate }}" width="110" [columnMenu]="false">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
              <span   [ngClass]=
              "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
             
              >
                {{dataItem.RefOrderID}} 
              </span> 
         </ng-template>
        
        </kendo-grid-column>

        </kendo-grid>

        
           
        </div> 
       
   

    