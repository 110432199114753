<br/>
<kendo-grid 
#grid
[kendoGridBinding]="items"
	  [filterable]="false" 
	  [pageSize]="20"
	  [resizable]="true"
	  [reorderable]="true"
	  [columnMenu]="false"
	  filterable="menu"
	  [sortable]="true"
    class="market-kendo-grid"
> 
 <kendo-grid-column   title="{{ 'Id' | translate }}  "  headerClass="grid-list-column-header" width="70" > 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
	  {{(rowIndex+1  )}}
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="StoreNameEn" title="{{ 'Name' | translate }} "
 headerClass="grid-list-column-header" >
 <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
	<span *ngIf="isRtl()">{{dataItem.StoreNameAr}}</span>
	<span *ngIf="!isRtl()">{{dataItem.StoreNameEn}}</span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="ContractStartDate" title="{{ 'Start Date' | translate }} " headerClass="grid-list-column-header" > 

	 <ng-template kendoGridCellTemplate let-dataItem> 
		
	  	{{dataItem.ContractStartDate | date: 'MM/dd/yyyy'}}
 
	  </ng-template>

</kendo-grid-column>
<kendo-grid-column field="ContractEndDate" title="{{ 'End Date' | translate }} " headerClass="grid-list-column-header" > 
	<ng-template kendoGridCellTemplate let-dataItem> 
		
		{{dataItem.ContractEndDate | date: 'MM/dd/yyyy'}}

	</ng-template>
</kendo-grid-column>
<kendo-grid-column field="ContractNo" title="{{ 'Contract No' | translate }} "   width="120" headerClass="grid-list-column-header" > </kendo-grid-column>
<kendo-grid-column field="Area" title="{{ 'Area' | translate }} "   width="60" headerClass="grid-list-column-header" > </kendo-grid-column>

<kendo-grid-column field="ContractStatus" title="{{ 'Status' | translate }}  " headerClass="grid-list-column-header"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
	   <span *ngIf="dataItem.ContractStatus!=true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }} </span>
	   <span *ngIf="dataItem.ContractStatus==true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }}  </span>
  </ng-template>
</kendo-grid-column>
 
<kendo-grid-column   title="#"  width="100" headerClass="grid-list-column-header"
*ngIf="auth.isGranted(_permissions.AssignClientsToStores)
&&!this.isBroker()&&!this.isStoreKeeper()&&(approveStatus.Approve==status)">

  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
	<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
	  <mat-icon>menu</mat-icon>
	</button>
	   <mat-menu #anchorMenu="matMenu">
		  <a mat-menu-item     (click)="edit(dataItem)"   >
			 {{ 'Edit' | translate }} 
		  </a>
		  <a mat-menu-item       (click)="Delete(dataItem)"   >
			{{ 'Delete' | translate }} 
	   </a>
	   
	   </mat-menu>
   
	  </ng-template>
 
 </kendo-grid-column>
</kendo-grid>
<br/>
<div fxLayout="row" fxLayoutAlign="start">
                   
	<button (click)="addNewClientStoreDialog()" 
	 *ngIf="auth.isGranted(_permissions.AssignClientsToStores)  &&!this.isBroker()&&!this.isStoreKeeper()&&(approveStatus.Approve==status)"   mat-raised-button
	
	color="warn">{{ 'Assign Client Warehouse' | translate }} </button>

	 <a [routerLink]="['/clients/clients/'+status]"
	  mat-raised-button color="primary"> {{ 'Go To List' | translate }} </a>

 </div>