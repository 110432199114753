<div class="gene-registration-form gene-session pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center center" >
      <div fxLayout="column" fxFlex.xl="600px" fxFlex.lg="600px" fxFlex.md="600px" fxFlex.sm="600px"  fxFlex.xs="100">
         <mat-card>
            <div>
            <div fxLayout="column" fxLayoutAlign="center center">
               <div class="login-logo text-center">
               <img src="assets/img/logo.jpeg">
               <h5 class="">{{'Please Enter your password info for change password'|translate}}</h5>
               </div>
            </div>
            
            <form #form="ngForm" (ngSubmit)="changePassword(form.value)">
               <div fxLayout="column" fxLayoutAlign="start stretch">
 
                 
                  <mat-form-field class="full-wid mrgn-b-sm">
                     <input matInput placeholder="Password" type="password" name="password" required [(ngModel)]="password">
                  </mat-form-field>
                  <mat-form-field class="full-wid mrgn-b-sm">
                     <input matInput placeholder="Password (Confirm)" type="password" name="confirmPassword" required [(ngModel)]="passwordConfirm">
                  </mat-form-field>
                  <div *ngFor="let error of errors">
                     <label class="error-label">{{error}}</label>
                     <br />
                  </div>
                  <button mat-raised-button [disabled]="!form.valid" class="primary-bg mrgn-b-md">{{'Change Password'|translate}}</button>
                  
               </div>
            </form>
            </div>
         </mat-card>
      </div>
   </div>
</div>