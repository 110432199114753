import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
import { BrokerDto, CompanyDto } from '../../../app/models/broker';

@Injectable()
export class CompanyManagementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.brokerUrl}`;

  constructor(private http: HttpClient) {}


  getCompanies(): Observable<[]> { 
    return this.http.get<[]>(`${this.API_URL}/getCompanies`);
  }
  getCompanySymbolsByCompanyId(id:any){ 
    return this.http.get<[]>(`${this.API_URL}/getCompanySymbolsByCompanyId/`+id);
  }
  getActiveCompanies(): Observable<[]> { 
    return this.http.get<[]>(`${this.API_URL}/getActiveCompanies`);
  }
   
  getCompanyBrokers(id:any): Observable<[]> { 
    return this.http.get<[]>(`${this.API_URL}/getCompanyBrokers/`+id);
  }
  
  getCompany(id:any): Observable<CompanyDto> { 
    return this.http.get<CompanyDto>(`${this.API_URL}/getCompany/`+id);
  }
addCompanyBroker(createComapnyRequest: any,id:any): Observable<any> {

  return this.http.put<any>(`${this.API_URL}/addCompanyBroker/`+id, 
   createComapnyRequest)
    .pipe(tap(data => {}));
}
updateCompanyBroker(createComapnyRequest: any,id:any): Observable<any> {

  return this.http.put<any>(`${this.API_URL}/updateCompanyBroker/`+id, 
   createComapnyRequest)
    .pipe(tap(data => {}));
}
  insertComapny(createComapnyRequest: any): Observable<boolean> {

     return this.http.post<any>(`${this.API_URL}/insertComapny`, 
      createComapnyRequest)
       .pipe(tap(data => {}));
  }
  
  updateCompany(ComapnyRequest: any,id:any): Observable<boolean> {
 
    return this.http.put<any>(`${this.API_URL}/updateCompany/`+id, 
    ComapnyRequest)
      .pipe(tap(data => {}));
  }

  deleteCompany(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteCompany/`+id).pipe(tap(data => {}));
  }

  removeCompanyBroker(id: any ): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/removeCompanyBroker/`+id ).pipe(tap(data => {}));
  }

  changeStatusCompanyBroker(id: any ): Observable<boolean> {

    return this.http.get<any>(`${this.API_URL}/changeStatusCompanyBroker/`+id ).pipe(tap(data => {}));
  }

  searchCompanies(request: any): Observable<any> {
    let queryParameters: string = this.convertObjectToQueryParameters(request);
    return this.http.get<[]>(`${this.API_URL}/searchCompanies`+queryParameters)
    }

    convertObjectToQueryParameters(request): string {
      let queryParameters: string = "";
      for (let property in request) {
          if (request[property] != null)
              queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
      }
      if (queryParameters == "")
          return "";
      return queryParameters
  }

}