import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "ms-warehouse-home",
  templateUrl: "./warehouse-home.component.html",
  styleUrls: ["./warehouse-home.component.scss"],
})
export class WarehouseHomeComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  myFunction(routerName) {
    this.router.navigateByUrl(routerName);
  }
}
