import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { LockupsManagementService } from 'app/lockups-management/services/lockups-management.service';
import { MarketService } from 'app/market-managment/services/market.service';
import { StatusEnum } from 'app/core/enums';
import { MarketDto, MarketSessionDto, ValidateSessionDto } from 'app/models/market';
import { SymbolUnitDto } from 'app/models/StatusLookup';
import { SymbolDto, SymbolMarketDto } from 'app/models/symbol';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs-compat/operator/filter';
import { AdminService } from 'app/admin/services/admin.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';

@Component({
  selector: 'ms-market-session-dialog',
  templateUrl: './market-session-dialog.component.html',
  styleUrls: ['./market-session-dialog.component.scss']
})
export class  MarketSessionDialogComponent extends AppComponentBase implements OnInit {

  title: string; 
  marketSession: MarketSessionDto = new MarketSessionDto();
  marketSessionsList:any;
   validate:ValidateSessionDto= new ValidateSessionDto();
   isValid: boolean;
   addNewMarketSessionForm: FormGroup;
  isUpdate: any;
  index:any;
  timeCheck: boolean;
  errors: any;
  lockedLst: any;
  marketId: any;
  sessiobTypeList: any;
  killDialogRef: MatDialogRef<DemoDialog>;
  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarketSessionDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private setupApi: SetupApi,
    private symbolService: SymbolService,
    private marketService: MarketService,
    private toastrService: ToastrService,
    private adminService: AdminService,
    private dialog: MatDialog,
    injector: Injector
  ) {
    super(injector);
    
    if (data.marketSessionItem != null) {
      
      this.marketSession = data.marketSessionItem;  
      this.isUpdate=true;
      this.title = this.translate("Update Market Session") + " - " + this.marketSession.NameEn;
    } else {
      this.isUpdate = false;
      this.title = this.translate("Add Market Session");
    }
    if(data.marketId !=null){
      this.marketId=data.marketId ;
    }
    if(data.marketSessionsList !=null){
      this.marketSessionsList=data.marketSessionsList ;
      if(this.isUpdate){
        this.validate.marketSessions=this.marketSessionsList.
        filter(x=>x.StartSessionTime != this.marketSession.StartSessionTime);
      }else{
        this.validate.marketSessions=this.marketSessionsList;
      }

    }
    if(data.index!=null){
     this.index=data.index;
    }
    
    this.getsessiobTypeList();
  }

 

  ngOnInit(): void {
    this.addNewMarketSessionForm = new FormGroup({
      Sessiontype: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      StartSessionTime: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      EndSessionTime: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      myCheckboxGroup: new FormGroup({
      IsAllowOrder : new FormControl(null),
      IsAllowMatching : new FormControl(null)})
    }); 

  }


  close() {
    this.dialogRef.close(false);
  }

  onStartTimeset(event) {
    this.marketSession.StartSessionTime = event;

  }

  onEndTimeset(event) {
    this.marketSession.EndSessionTime = event;

  }
  
   
  onFormSubmit() {

    this.validationTime();

    if (this.timeCheck) { 
      //mapping value from the form to the object to be valid
   this.validate.endTime = this.marketSession .EndSessionTime;
   this.validate.startTime =this.marketSession .StartSessionTime;
 
   this.marketSession.MarketId = Number(this.marketId);
   // Make sure the session time dosen't conflict with another session
      
   this.marketService.validateMarkerSession(this.validate).subscribe(valid => {
      this.isValid = valid;
        // check if the time is valid
        if (this.isValid) {
        // send object to the list
          if(this.marketSession.Sessiontype!=null){
            var sessionType=this.sessiobTypeList.filter(x=>x.Id==this.marketSession.Sessiontype)[0];
            if(sessionType!=null){
              this.marketSession.NameEn=sessionType.NameEn;
              this.marketSession.NameAr=sessionType.NameAr;
            }
          }
          this.marketSession.LastUpdatedUserId = this.currentUserId(); 
          this.marketSession.IsActive=true;
          this.dialogRef.close(this.marketSession);
        }
        else { this.toastrService.error(' There is another session in this time ');  }
  
      });
    } 

   // this.KillAllSession();
  }
   
 getsessiobTypeList(){
   this.marketService. getMarketSessionTypes().subscribe(res => {
    this.sessiobTypeList = res; 
  });
 }
 
 validationTime() {
   this.timeCheck = true
   if ((this.marketSession.StartSessionTime == null || this.marketSession.EndSessionTime == null)
    ||( (this.marketSession.EndSessionTime) < (this.marketSession.StartSessionTime))) {
      if(( (this.marketSession.EndSessionTime) < (this.marketSession.StartSessionTime)))
        this.toastrService.warning('End Time should be greater than Start Time (24hr format).');
    this.timeCheck = false;
  } 
   return this.timeCheck;
 }

  KillAllSession() {

    this.killDialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'Would you like to kill all the sessions ?' },
      disableClose: false
    });

    this.killDialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.destroyAllSession().subscribe((res) => {
          
          this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.killDialogRef = null;
    });
  }

 
}
