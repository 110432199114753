import { MessageService } from "@progress/kendo-angular-l10n";

const data = {
  ar: {
    rtl: true,
    messages: {
      "kendo.grid.noRecords": "لايوجد بيانات",
      'kendo.grid.sortAscending': 'تنازلي',
      'kendo.grid.sortDescending': 'تصاعدي',
      'kendo.grid.filter': 'بحث',
        'kendo.grid.pagerFirstPage': 'الصفحه الاولى',
        'kendo.grid.pagerPreviousPage': 'الصفحه السابقه',
        'kendo.grid.pagerNextPage': 'الصفحه التاليه',
        'kendo.grid.pagerLastPage': 'الصفحه الاخيره',
        'kendo.grid.pagerPage': 'صفحه',
        'kendo.grid.pagerOf': 'من',
        'kendo.grid.pagerItems': 'عناصر',
        'kendo.grid.pagerItemsPerPage': 'عناصر بالصفحه',
        'kendo.grid.filterEqOperator': 'يساوي',
        'kendo.grid.filterNotEqOperator': 'لا يساوي',
        'kendo.grid.filterIsNullOperator': 'لا توجد قيمه',
        'kendo.grid.filterIsNotNullOperator': 'توجد قيمه',
        'kendo.grid.filterIsEmptyOperator': 'فارغ',
        'kendo.grid.filterIsNotEmptyOperator': 'غير فارغ',
        'kendo.grid.filterStartsWithOperator': 'يبدا ب',
        'kendo.grid.filterContainsOperator': 'يحتوي',
        'kendo.grid.filterNotContainsOperator': 'لا يحتوي',
        'kendo.grid.filterEndsWithOperator': 'ينتهي ب',
        'kendo.grid.filterGteOperator': 'اكبر من او يساوي',
        'kendo.grid.filterGtOperator': 'اكبر من',
        'kendo.grid.filterLteOperator': 'اصغر من او يساوي',
        'kendo.grid.filterLtOperator': 'اصغر من',
        'kendo.grid.filterIsTrue': 'صحيح',
        'kendo.grid.filterIsFalse': 'خطا',
        'kendo.grid.filterBooleanAll': 'كل',
        'kendo.grid.filterAfterOrEqualOperator': 'عند او بعد',
        'kendo.grid.filterAfterOperator': 'بعد',
        'kendo.grid.filterBeforeOperator': 'قبل',
        'kendo.grid.filterBeforeOrEqualOperator': 'عند او قبل',
        'kendo.grid.filterFilterButton': 'بحث',
        'kendo.grid.filterClearButton': 'الغاء',
        'kendo.grid.filterAndLogic': 'و',
        'kendo.grid.filterOrLogic': 'او',
        'kendo.grid.loading': 'تحميل',
        'kendo.grid.sort': 'ترتيب',
        'kendo.grid.columnMenu': 'خيارات',
        'kendo.grid.columns': 'Columns',
        'kendo.grid.lock': 'قفل',
        'kendo.grid.unlock': 'فتح',
        'kendo.grid.columnsApply': 'تطبيق',

            'kendo.grid.columnsReset': 'اعاده'
    },
  },
  fr: {
    rtl: false,
    messages: {
      "kendo.grid.noRecords": "No records available.",
    },
  },
  en: {
    rtl: false,
    messages: {
      "kendo.grid.noRecords": "No records available.",
    },
  },
};

export class KendoService extends MessageService {
  public set language(value: string) {
    
    const lang = data[value];
    if (lang) {
      this.localeId = value;
      this.notify(lang.rtl);
    }
  }

  public get language(): string {
    return this.localeId;
  }

  private localeId = "en-GB";
  private get messages(): any {
    const lang = data[this.localeId];

    if (lang) {
      return lang.messages;
    }
  }

  public get(key: string): string {
    return this.messages[key];
  }
}