<div class="app" [dir]="layout" [ngClass]="{'app-dark': dark, 'compact-sidebar': compactSidebar, 'customizer-in': customizerIn}">
<mat-sidenav-container class="gene-container">
   <mat-sidenav #horizontalSideNav [(mode)]="coreService.horizontalSideNavMode" [(opened)]="coreService.horizontalSideNavOpen" class="sidebar-area">
      <ms-side-bar [menuList]="menuItems" [verticalMenuStatus] = false></ms-side-bar>
   </mat-sidenav>
   
   <mat-toolbar class="gene-header-toolbar">
      <div class="gene-logo mat-pad-none"><img src="assets/img/logo.jpeg" width="120" height="27"></div>
      <a class="navbar-brand" href="javascript:void(0)">{{header}}</a>

      <breadcrumb class="gene-breadcrumbs"></breadcrumb>
      <span fxFlex></span>
      <div class="search-bar" fxFlex>
         <form class="search-form" fxShow="false" fxShow.gt-xs tourAnchor="tour-search">
            <mat-form-field>
               <input matInput placeholder="Search">
            </mat-form-field>
         </form>
      </div>

      <div class="secondary-menu">
      
         <button fxHide="true" fxHide.gt-sm="false" mat-button class="fullscreen-toggle" (click)="toggleFullscreen()" tourAnchor="tour-full-screen">
            <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
            <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
         </button>
         <button class="user-button" mat-button [matMenuTriggerFor]="menu">
            <div fxLayout="row" fxLayoutAlign="center center">
               <mat-icon>account_circle</mat-icon>
            </div>
         </button>
         <button (click)="end.toggle()" mat-icon-button class="overflow-visible">
            <mat-icon>notifications</mat-icon>
         </button>         
         <button mat-icon-button class="overflow-visible" [matMenuTriggerFor]="appsmenu">
            <mat-icon>apps</mat-icon>
         </button>
         <ms-language-drop-down></ms-language-drop-down>
         <button mat-icon-button class="overflow-visible" (click)="chatMenu()">
            <mat-icon>chat</mat-icon>
         </button>
      </div>
   </mat-toolbar>



   <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
         <mat-toolbar class="mat-blue-800">
            <h3 >{{'Notifications'|translate}}</h3>
         </mat-toolbar>
         <mat-nav-list>
            <mat-list-item>
               <mat-icon mat-list-avatar class="primary-text">person_add</mat-icon>
               <h4 mat-line>New User</h4>
               <p mat-line>10 New Users Registered</p>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="primary-text">system_update</mat-icon>
               <h4 mat-line>Updates</h4>
               <p mat-line>New Updates are available for Server</p>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="primary-text">info</mat-icon>
               <h4 mat-line>Backup</h4>
               <p mat-line>Backup task is completed</p>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="primary-text">delete_sweep</mat-icon>
               <h4 mat-line>Junk Files are deleted</h4>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="primary-text">check_circle</mat-icon>
               <h4 mat-line>2 New project Completed</h4>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="warn-text">notifications_paused</mat-icon>
               <h4 mat-line>Need 4 Data Entry Operator</h4>
            </mat-list-item>
            <mat-list-item>
               <mat-icon mat-list-avatar class="warn-text">error</mat-icon>
               <h4 mat-line>Server 2 is down for 1 hour</h4>
            </mat-list-item>
         </mat-nav-list>

         
      </div>
   </mat-sidenav>
   <div class="horizontal-toolbar-wrap">
      <mat-toolbar color="primary">
         <mat-toolbar-row class="hr-menu-wrap">
            <div class="horizontal-menu">
               <div class="gene-ham-icon" fxShow.lt-md="true" fxHide="true" tourAnchor="start.tour">
                  <button class="" mat-mini-fab color="primary" mat-card-icon (click)="toggleSidebar()">
                     <mat-icon>menu</mat-icon>
                  </button>
               </div>
               <ul class="hr-primary-menu" fxHide.lt-md="true">
                  <li *ngFor="let menuItem of menuItems.getAll()" class="child-menu {{menuItem.showColumns}}" [ngClass]="{'make-mega-menu': menuItem.mega, 'menu-item-has-child':menuItem.children}">
                  <ng-container *ngIf="menuItem.type == 'button'; else menu">
                     <a (click)="changeLayout()">{{menuItem.name|translate}}</a>
                  </ng-container>
                  <ng-template #menu>
                     <a>{{menuItem.name|translate}}</a>
                  </ng-template>
                  <ul class="hr-sub-menu" *ngIf="menuItem.type != 'button'">
                     <li *ngFor = "let childItem of menuItem.children" class="child-menu" [ngClass]="{'menu-item-has-child':childItem.children}">
                     <div *ngIf="childItem.type==='link'" class="header-icon-wrap">
                        <a [routerLink]="['/'+childItem.state ]" routerLinkActive="active-link" mat-ripple >
                           <mat-icon>{{ childItem.icon }}</mat-icon>
                           {{childItem.name|translate}}
                        </a>
                     </div>
                     <div *ngIf="childItem.type==='subChild'" class="header-icon-wrap">
                        <a routerLinkActive="active-link" mat-ripple >
                           <mat-icon>{{ childItem.icon }}</mat-icon>
                           {{childItem.name|translate}}
                        </a>
                     </div>
                     <ng-container *ngIf="childItem.children">
                        <ul class="hr-sub-menu">
                           <li *ngFor = "let item of childItem.children">
                              <div *ngIf = "item.type == 'link'" >
                                 <a  [routerLink]="['/'+childItem.state+'/'+item.state ]" routerLinkActive="active-link" mat-ripple>{{item.name|translate}}</a>
                              </div>
                           </li>
                        </ul>
                     </ng-container>
                     </li>
                  </ul>
                  </li>
               </ul>
            </div>
         </mat-toolbar-row>
      </mat-toolbar>
   </div>
   <div class="gene-base-container" #scrollContainer>
      <div class="inner-container" fxLayout="row wrap">
         <div class="full-wid" fxFlex="100">
            <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
            <tour-step-template>
               <ng-template let-step="step">
                  <div (click)="$event.stopPropagation()" class="popover bs-popover-bottom">
                     <div class="popover-header">
                        {{step?.title}}
                     </div>
                     <div class="popover-body">
                        <p class="tour-step-content">
                           {{step?.content}}
                        </p>
                     </div>
                     <div class="tour-step-navigation">
                        <button mat-icon-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
                        <mat-icon>chevron_left</mat-icon>
                        </button>
                        <button mat-icon-button [disabled]="!tourService.hasNext(step)" (click)="tourService.next()">
                        <mat-icon>chevron_right</mat-icon>
                        </button>
                        <button mat-button (click)="tourService.end()">End</button>
                     </div>
                  </div>
               </ng-template>
            </tour-step-template>
         </div>
         <div class="footer-wrap pad-t-none full-wid" fxFLex="100">
            <ms-footer></ms-footer>
         </div>
      </div>
   </div>
</mat-sidenav-container>

<div class="gene-customizer">
   <button class="customizer-toggle" (click)="customizerFunction()">
      <mat-icon class="fa-spin">settings</mat-icon>
   </button>
   <div class="settings-panel">
      <mat-toolbar color="primary">
         <span fxFlex>{{'Options'|translate}}</span>
      </mat-toolbar>
      <mat-card-content class="theme-options">
         <h3>{{'Layout Options'|translate}}</h3>
         <mat-divider></mat-divider>
         <div>
            <mat-checkbox [(ngModel)]="dark" (change) = "addClassOnBody($event)" [align]="end">{{'Dark Mode'|translate}}</mat-checkbox>
         </div>
         <div>
            <mat-checkbox (change)="changeRTL($event.checked)">{{'RTL'|translate}}</mat-checkbox>
         </div>
 
      </mat-card-content>
   </div>
</div>

<div class="gene-chat-wrap" id ="gene-chat">
   <mat-card class="people-list">
      <mat-tab-group class="height-full">
         <mat-tab label="Chat" class="height-full">
            <div class="gene-card-content height-full">
               <div class="chat-people-list height-full">
                  <mat-list role="list">
                     <ng-container *ngFor = "let chat of chatList">
                        <mat-list-item role="listitem" (click) = "onChatOpen()">
                           <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-around center">
                              <div class="image-wrap" fxFlex="50px" class="mrgn-r-sm">
                                 <img src="{{chat.image}}"  alt="User">
                              </div>
                              <div class="people-desc" fxFlex="calc(100% - 100px)">
                                 <h4 class="margin-none font-normal make-ellipse">{{chat.name}}</h4>
                                 <span class="gene-text-md inline-block make-ellipse">{{chat.chat}}</span>
                              </div>
                              <div fxFlex="30px">
                                 <div class="show-status mat-grey-300 {{chat.mode}}"></div>
                              </div>
                           </div>
                        </mat-list-item>
                        <mat-divider class="mrgn-y-xs gene-relative"></mat-divider>
                     </ng-container>
                  </mat-list>
               </div>
            </div>
         </mat-tab>
         <mat-tab label="Settings">
            <div class="gene-card-content height-full">
               <div class="chat-settings-list mrgn-b-xl">
                  <mat-list role="list">
                     <h3 mat-subheader class="mat-pad-none mrgn-b-md"><i class="material-icons">settings</i>Settings</h3>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Notifications</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Quick Results</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Auto Update</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                  </mat-list>
               </div>
               <div class="chat-account-list">
                  <mat-list role="list">
                     <h3 mat-subheader class="mat-pad-none mrgn-b-md"><i class="material-icons">perm_identity</i>Account</h3>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Offline Mode</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Location Share</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Show Offline User</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                     <mat-list-item role="listitem">
                        <div class="gene-list-item full-wid" fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div class="people-desc" fxFlex="200px">
                              <h4 class="margin-none font-normal make-ellipse">Save History</h4>
                           </div>
                           <div fxFlex="60px">
                              <mat-slide-toggle></mat-slide-toggle>
                           </div>
                        </div>
                     </mat-list-item>
                     <mat-divider class="mrgn-y-sm gene-relative"></mat-divider>
                  </mat-list>
               </div>
            </div>
         </mat-tab>
      </mat-tab-group>
   </mat-card>
   <mat-card class="chat-window" id="chat-open">
      <div class="chat-header primary-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
         <div class="profile-wrap" fxFlex="calc(100% - 100px)">
            <div fxLayoutAlign="start center">
               <div class="image-wrap" fxFlex="40px" class="mrgn-r-xs">
                  <img src="assets/img/user-1.jpg" width="" height="" alt="">
               </div>
               <div fxFlex="calc(100% - 40px)">
                  <h5 class="margin-none make-ellipse">Youssef Yassin</h5>
                  <span class="status-text gene-block gene-text-sm">Online</span>
               </div>
            </div>
         </div>
         <div class="icons-wrap text-right" fxFlex="100px" fxLayoutAlign="end center">
            <a href="javascript:void(0)"><i class="material-icons inline-block">phone</i></a>
            <a href="javascript:void(0)"> <i class="material-icons inline-block">videocam</i></a>
            <a href="javascript:void(0)"><i class="material-icons inline-block margin-none" (click)="chatWindowClose()">close</i></a>
         </div>
      </div>
      <mat-card-content class="gene-card-content">
         <div class="chat-block-wrap">
            <div class="chat-block sender-chat mrgn-b-sm">
               <p class="mat-grey-300">Hi There! Recently I updated the latest version of your app, it crashed every time when i open.Need Assistance</p>
               <span class="sent-time gene-text-md gene-block">11:20pm</span>
            </div>
            <div class="chat-block receiver-chat text-right mrgn-b-sm">
               <p class="mat-grey-300">Hi, Can you please tell us your mobile configuraion. Thank You!</p>
               <span class="sent-time gene-text-md gene-block">12:00am</span>
            </div>
         </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions class="gene-card-footer">
         <mat-form-field class="full-wid">
            <input matInput placeholder="Type Your message">
            <mat-icon matSuffix>send</mat-icon>
         </mat-form-field>
      </mat-card-actions>
   </mat-card>
</div>
</div>
<mat-menu class="user-menu" x-position="before" y-position="below" #menu="matMenu">
   <button mat-menu-item>
      <mat-icon>account_circle</mat-icon>
      <span>Profile</span>
   </button>
   <button mat-menu-item>
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
   </button>
   <button mat-menu-item>
      <mat-icon>help</mat-icon>
      <span>Help</span>
   </button>
   <mat-divider></mat-divider>
   <button (click)="logOut()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
   </button>
</mat-menu>
<mat-menu class="apps-menu" x-position="after" y-position="below" #appsmenu="matMenu">
   <button mat-menu-item>
      <mat-icon>storage</mat-icon>
      <span>{{'Total App Memory'|translate}}</span>
   </button>
   <button mat-menu-item>
      <mat-icon>memory</mat-icon>
      <span>{{'Total Memory Used'|translate}}</span>
   </button>
   <button mat-menu-item>
      <mat-icon>mail</mat-icon>
      <span>{{'12 Unread Mail'|translate}}</span>
   </button>
   <button mat-menu-item>
      <mat-icon>feedback</mat-icon>
      <span>{{'2 Feedback'|translate}}</span>
   </button>
</mat-menu>