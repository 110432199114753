import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';

import { StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market';
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { CommissionManagementService } from 'app/commission-management/services/commission-management.service';
import { CommissionDto, MarketCommissionDto } from 'app/models/commission';
import { AppComponentBase } from 'app/app-component-base';
import { MarketCommissionDialogComponent } from '../market-commission-dialog/market-commission-dialog.component';

@Component({
  selector: 'ms-market-manage-commission-list',
  templateUrl: './market-commission-list.component.html',
  styleUrls: ['./market-commission-list.component.scss']
})
export class MarketCommissionListComponent extends AppComponentBase implements OnInit, OnDestroy {

  public items: any;

  public lookups: StatusLookupDto;
 
  marketId: any;
  //market: any;
  title: any;
  public actions: any;
  public action: any;
  selectBy: any;
  dialogRef: MatDialogRef<DemoDialog>;
  @Output() commissionEmitter = new EventEmitter<any>();

  ngOnDestroy() {

  }

  @Input() set _marketId(value: number) {
    if (value > 0) {
      this.marketId = value;

    }


  }

  constructor(private pageTitleService: PageTitleService,

    private route: ActivatedRoute,
    private marketService: MarketService,
    private commissionManagementService: CommissionManagementService,

    private dialog: MatDialog,



    private _helper: EnumHelpers, injector: Injector
  ) {
    super(injector);
    this.actions = _helper.getNames(StatusEnum);
  


  }

  ngOnInit(): void {
    this.title = this.translate('Commissions');

    this.getMarketCommissions();

  }

  getMarketCommissions() {
    this.commissionManagementService.GetMarketCommission(this.marketId).subscribe(items => {
      this.items = items;
  
    });
  }



  addMarketCommission( ) {
 
    const dialog = this.dialog.open(MarketCommissionDialogComponent, {
      data: { marketId:this.marketId ,marketCommisionsList: this.items }
    });
    dialog.afterClosed().subscribe((res) => {
      if(res!=null){
        res.Id=0; 
        this.bindData(res,false,false);
      
			 }
    });

  }


  editMarketCommission(data) {
 
    const dialog = this.dialog.open(MarketCommissionDialogComponent, {
      data: {  marketId:this.marketId , marketCommission:  {...data}  ,marketCommisionsList: this.items }
    });
    dialog.afterClosed().subscribe((res) => {
      if(res!=null){
        this.bindData(res,true,false);
			 
			 }
    });

  }
  delteMarketCommission(element) { 
		this.dialogRef = this.dialog.open(DemoDialog, {
			data: { message:   this.translate('would you like to delete this market Commision')+' # ' + element.CommissionNameEN + '?' },
			disableClose: false
		  });
	  
		  this.dialogRef.afterClosed().subscribe(result => {
			if (result == 'Yes!') {
        element. IsDeleted=true; 
        this.bindData(element,false ,true);

			}
			this.dialogRef = null;
		  });
	}

  bindData(commission,isUpdate,isDeleted){
 
    if(isDeleted!=true){
      if(isUpdate==true){
        this.items.forEach(element => {
          if(commission.CommissionID == element.CommissionID){
            element.Minimum  = commission.Minimum;
            element.Maximum = commission.Maximum;
            element.CommissionPerc= commission.CommissionPerc; 
          }
        });
      }else{
        this.items.push(commission);
      }
    }
    this.items=this.items.filter(x=>x.IsDeleted != true);
    this.commissionEmitter.emit(this.items);  
   
  }



}

