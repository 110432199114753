import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { MarketSymbolListComponent } from 'app/market-managment/components/market-symbol-list/market-symbol-list.component';
import { AppComponentBase } from 'app/app-component-base';
 
import { MarketCommissionDialogComponent } from 'app/market-managment/components/market-commission-dialog/market-commission-dialog.component';
import { SetupApi } from 'app/Shared/api/setup.api';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';



@Component({
  selector: 'ms-sub-market-list',
  templateUrl: './sub-market-list.component.html',
  styleUrls: ['./sub-market-list.component.scss']
})
export class SubMarketListComponent  extends AppComponentBase implements OnInit, OnDestroy {
 
  public items: MarketDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  public actions: any;
  selectBy:any;
  public action: any;
  public _marketId: number;
  mainMarkets: MarketDto[];
  selectedMarket: MarketDto;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private marketService: MarketService    ,
    private setupApi: SetupApi, 
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector);
      this.actions = _helper.getNames(StatusEnum);

    //  this.title =   this.translate('Sub Markets') ; 
      this.pageTitleService.setTitle('Sub Markets');


  
    }


    changePropertiesStatus(data){
 
      let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
      propertiesStatus.ActionOn=EntityTypeEnum.Market;
      propertiesStatus.ResponsibleId=data.Id;
      propertiesStatus.ResponsibleNameAr=data.NameAr;
      propertiesStatus.ResponsibleNameEn=data.NameEn;
    
      if( data.IsActive)
      propertiesStatus.Status= this._StatusEnum.Active;
   else
   propertiesStatus.Status=this._StatusEnum.InActive; 

 const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
   data:{propertiesStatus:propertiesStatus   }  
 });
 dialog.afterClosed().subscribe((res) => { 
if(res!=null){
 if(res==this._StatusEnum.Active)
         data.IsActive=true;
  else
  data.IsActive=false; 
}

 } );
}
    


  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this._marketId = +prams.get('id') as number;
    

    });

    this.setupApi.getMainMarkets().subscribe(res => {
      
      this.mainMarkets=res;
      this._marketId=this.mainMarkets[0].Id;
      if(this.mainMarkets .length>0){
        this.selectedMarket=this.mainMarkets [0];
      
        
      }
      this.getMarkets( ) ;
 
    });

  }

  getMarkets( ) {
    this.marketService.getSubMarketWithSessions( this._marketId ).subscribe(items => {

      this.items = items;  
      if(this.mainMarkets .length>0){

        this.selectedMarket=this.mainMarkets.filter(c=>c.Id==this._marketId)[0];
 
      }
    
    });

  }




 

 

  deleteMarket(market) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:   this.translate('would you like to delete this market')+' # ' + market.NameEn + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.marketService.deleteMarket(market.Id).subscribe((res) => {
         this. getMarkets( );
                 this.toastr.success(  this.translate("Successfully"));
        }, err => {
          this.toastr.error(  this.translate("Error"));
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }



  
 

}

