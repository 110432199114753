import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
 
import { SymbolUnitDto } from 'app/models/StatusLookup';
import { SymbolDto } from 'app/models/symbol'; 
import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MarketDto } from 'app/models/market';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { CommissionDto, SymbolMarketCommissionDto } from 'app/models/commission';
import { CommissionManagementService } from 'app/commission-management/services/commission-management.service';
import { CategoryDto, SectorDto } from 'app/models/category';
import { AppComponentBase } from 'app/app-component-base';
import { permissions } from 'app/core/permission';

@Component({
  selector: 'ms-symbol-market-commission-dialog',
  templateUrl: './symbol-market-commission-dialog.component.html',
  styleUrls: ['./symbol-market-commission-dialog.component.scss']
})
export class  SymbolMarketCommissionDialogComponent   extends AppComponentBase
implements OnInit {

  
  title: string; 
  symbolMarketCommission: SymbolMarketCommissionDto = new SymbolMarketCommissionDto();
  symbolId:any;
  addNewSymbolForm: FormGroup;
  isUpdate :boolean;
  errors:any; ; 
  isdisable:boolean;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SymbolMarketCommissionDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private symbolService: SymbolService,
    private commissionsService: CommissionManagementService,
    private setupApi: SetupApi,     injector: Injector
    ) {
      super(injector)  
             
      if (data.symbolId != null) {
        this.symbolId=data.symbolId ; 

      } 
    
       
      if (data.symbolMarket != null) {
        this.symbolMarketCommission=data.symbolMarket;
         
        this.isUpdate=true;
        this.title=   'Symbol Commision';

      } 
  
    }
 
    ngOnInit(): void {
    this.addNewSymbolForm = new FormGroup({
      CommissionPerc: new FormControl({ value: '' },Validators.compose([Validators.required , Validators.min(0), Validators.max(100)]
        )
      ),
      Minimum: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Maximum: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });
  }

  
  close() {
    this.dialogRef.close(false);
  } 

  onFormSubmit() {  
    
    this.errors = [];   
   
    this.symbolMarketCommission.UpdatedUserId=this.currentUserId();
    this.symbolService. updateOrCreateSymbolMarketCommission(this.symbolId, this.symbolMarketCommission 
 ).subscribe(() => {
  
      this.dialogRef.close(this.addNewSymbolForm.value);
      this.toastr.success("Successfully!");
    },
      err => {

        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);
      });
  }

 

 

    
}
