import { TranslateService } from '@ngx-translate/core';
 import { Injectable } from '@angular/core';
import { Period } from '../../models/period';
export  const langSettingName = "lang"
export const availableLanguages = {
    ar:"ar",en:"en",fr:"fr"
}
@Injectable()
export class CurrentLanguageService {

    defaultLang:string = availableLanguages.en;

    get currentSessionLanguage(){
 
        return sessionStorage.getItem(langSettingName);
        
    }
    setCurrentSessionLanguage(lang){
    
        sessionStorage.setItem(langSettingName,lang);       
    }
    useSessionLanguageOrDefault(){
      
        
        this.translateService.use(this.defaultLang);
    }



    constructor(private translateService:TranslateService)
    {

    }


}
