import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './containers//user-profile/user-profile.component';
import { UserManagementComponent } from './containers/user-management/user-management.component';
 


export const adminRoutes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full'
 },
 {
    path: '',
    children: [
       {
          path: 'users',
          component:  UserManagementComponent
       }   
    ]
 }
 ,
 {
     path: 'profile',
     component:  UserProfileComponent
 }
 
];
 
