import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	 selector: 'ms-change-password',
	 templateUrl:'./change-password-component.html',
	 styleUrls: ['./change-password-component.scss'],
	 encapsulation: ViewEncapsulation.None,
})

export class ChangePasswordComponent {
	
	errors: string[] = [];
	password  : string;
	passwordConfirm : string; 
	constructor( public authService: AuthService,
				    public translate : TranslateService,
				    private route: ActivatedRoute ,
					private router: Router   ) { 
  
 
	}

	//register method is used to sign up on the template.
	changePassword(value) {

	    this.errors=[];
	 
		  value.userId = this.route.snapshot.queryParams.uid;
		  value.code = this.route.snapshot.queryParams.code;

		this.authService.resetPassword(value).subscribe(res=>{
			this.router.navigate(['/account/login']);
		},
		err => {
  
		  this.errors.push(err.error[0]);
		  // console.log(err)
		}
	  );
	}
	
}



