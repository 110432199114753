import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import 'rxjs/Rx' ;

import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'app/client-managment/services/client.service';
import { ClientBankDto, ClientDto, FileAttachmentDto, QueueAttachmentDto, StoreClientDto, UploadAttachmentTypeDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { BankDto } from 'app/models/bank';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { StoreSectionDto, StoreDto, StoreClientViewDto } from 'app/models/store';
import { AppComponentBase } from 'app/app-component-base';
import { AuthService } from 'app/account/services/auth.service';
import { ClientStoreFormDialogComponent } from '../client-store-form-dialog/client-store-form-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import {   ApproveStatusEnum, AttachmentTypeEnum, ClientTypeEnum, EntityTypeEnum, IdentityTypeEnum, StatusEnum, UserTypeEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientCommentsDialogComponent } from '../client-comments-dialog/client-comments-dialog.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { AppConsts } from 'app/core/config';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'ms-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent   extends AppComponentBase implements OnInit {
 
  title: string;
  addNewClientForm: FormGroup;
   
  queueAttachments:QueueAttachmentDto[]=[];
  uploadAttachmentTypes:UploadAttachmentTypeDto[] ;
  addNewWarehouseForm: FormGroup;
  isUpdate: boolean;
  errors: any;
  selectedIndex: number = 0;
  clientCompanies:any;
  clientStores:any;
  clientBanks:any;
  statusList: any;
  genders: any;
  identityTypes: any;
  nationalities: any;
  
  countries: any;
  offices: any;
  cities: any;
  CROfficeAdress: any;
  clientTypes: any;
  client: ClientDto = new ClientDto;  
  fileAttachmentDto:FileAttachmentDto= new FileAttachmentDto(); 
  minDate:any ;
  expiryMinDate:any;
  dateEvent : any;
  minExpiryDate:any ;
  maxExpiryDate:any ;
  maxDate:any ;
  clientId:any;
  ClientTypeEnum=ClientTypeEnum;
  status:any;
  IsExpiry:any;
  ClientStatus=ApproveStatusEnum;
  AttachmentTypeEnum=AttachmentTypeEnum;
  UserTypeEnum=UserTypeEnum;
  CountryID:any =1;
  commentsCount:any;
  userType:any;
  userTypeAssign:any; 
  companyId:any;
  year:any;
  month:any;
  day :any;
  uploader: FileUploader ;
  private _uploaderOptions: FileUploaderOptions = {};
  description: string;
  data:any;
  attachmentTypes:any;
  clientattachments:any=[];
  IdentityTypeC1Text:any = this.translate("Identity 2");
  IdentityTypeC2Text:any = this.translate("Identity 3");
  IdentityTypeText:any = this.translate("Identity");
  isNationalId: boolean = true;
  token:any;
  attachmentType:any=0;
  filteredOffices:any;
  filteredCountries:any;
  filteredCities:any;

  constructor(
    public formBuilder: FormBuilder,
    private clientService: ClientService,
    private dialog: MatDialog  ,
    private router:Router, private activatedRoute:ActivatedRoute,
    private pageTitleService: PageTitleService,
    private _helper :EnumHelpers,
    private setupApi: SetupApi    , injector: Injector
    ,private authService: AuthService 
    ) {
      
      super(injector);
      this. uploadAttachmentTypes=[]; 
     this.token=  this.authService.getToken( );
      this.year = new Date().getFullYear();
      this.month = new Date().getMonth();
      this.day = new Date().getDate();
      this.minDate = new Date(this.year - 16, this.month, this.day);
      this.maxDate = new Date();
      
  } 

  initFileUploader(): void {
 

       this.uploader = new FileUploader(
         {
           url: `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}` +'/UploadFiles',
            
          });
       this._uploaderOptions.autoUpload = false;
       this._uploaderOptions.authToken = 'Bearer ' +this.token;
 
       this._uploaderOptions.removeAfterUpload = true;
       this.uploader.onAfterAddingFile = (file) => {
           file.withCredentials = false;
           
       }; 

       this.uploader.setOptions(this._uploaderOptions);
       this.uploader.onAfterAddingFile = (fileItem: FileItem) => { 
        var queue=new QueueAttachmentDto();
        
        if (this.uploader.queue.length > 0) {
          this.uploader.queue.forEach(queueItem => { 
            if(queueItem. _file==fileItem._file){
              queueItem.formData={'type':this.attachmentType};
            } 
          });
        }
      };
    
    }

 

    public onFileSelected(event: EventEmitter<File[]>) {
      const element = event[0];
 
     var item=new UploadAttachmentTypeDto();
     item.Name=  element.name;
     item.TypeId=this.attachmentType;
     this. uploadAttachmentTypes.push(item); 
     

    }

  ngOnInit(): void { 
      this.pageTitleService.setTitle( "Clients Management");
   
      this.initFileUploader();
    	
    
    this.getStatusLookup();
    this.activatedRoute.paramMap.subscribe(prams => {
   
       this.clientId = +prams.get('id') as number; 
       this.status= +prams.get('status') as number; 
      // this.IsExpiry= +prams.get('IsExpiry') as number; 
       this.userTypeAssign= +prams.get('userType') as number; 
  
      if(this.clientId>0){
          this.isUpdate=true; 
          this. getClient(this.clientId);  
         
      }
      else
      {
        this.isUpdate=false;
        this.title=this.translate('Add New Client');
        this.client.ClientType=ClientTypeEnum.Individual;
  
        if(history.state.ClientType!=null){ 
          var state=history.state;
          this. client. IdentityTypeText=state.IdentityTypeText;
          this. client. IdentityTypeID=state . IdentityTypeID;
          this. client.CROfficeId  =state.  CROfficeId;
          this. client. ClientType=state. ClientType;
          this. client.  NationalityID=state. NationalityID;
        }
      
        this.onChangeClientType(this.client.ClientType); 
  }})

    
  
 
    
    this.addNewClientForm = new FormGroup({

      NameAr:  new FormControl({ value: '' }, Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z\u0600-\u06FF ]*$')])),
      ClientType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      DateofBirth: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Address: new FormControl({ value: '' } ),

      IdentityTypeID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IdentityTypeText: new FormControl({ value: '' }, Validators.compose([ Validators.compose
        ([Validators.required, Validators.maxLength(14),Validators.minLength(14), Validators.pattern("^[0-9]*$")])])),
      Gender: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IssueDate:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      ExpiryDate:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Telephone: new FormControl({ value: '' }, Validators.compose([Validators.required,Validators.pattern("[0-9]{8,15}"),  Validators.maxLength(20)])),
      Email:  new FormControl({ value: '' }, Validators.compose([
      Validators.maxLength(50),Validators.email ,Validators.required])),
      MailAddress:  new FormControl({ value: '' } ),
    
      CityID:  new FormControl({ value: '' } ),
      CityName:  new FormControl({ value: 'CityName' } ),
      CountryID:  new FormControl({ value: '' } ),
      CountryName:  new FormControl({ value: 'CountryName' } ),
      CROfficeId:  new FormControl({ value: '' } ),
      CROfficeName:  new FormControl({ value: 'CROfficeName' } ),
      CROfficeAdress:  new FormControl({ value: '' } ),
      IdentityTypeC1Text:  new FormControl({ value: '' } ),
      IdentityTypeC2Text:  new FormControl({ value: '' } ),

      NationalityID:  new FormControl({ value: '' } ),
      attachmentType:  new FormControl({ value: '' } ),
       
    });

    if(this.isBroker()){
  
     this.client.CreatedUserType= UserTypeEnum. TradingCompany; 
     this.getUserBrokerInfo().subscribe(res=>{
       this.companyId=res.CompanyId ;
      this.client.CompaniesId=[];
      this.getCommentsCount(this.client.CreatedUserType);
        if(res!=null ){  this.client.CompaniesId.push( this.companyId ); } 
   }) 
  
  }
  else if(this.isStoreKeeper()){
    this.client.CreatedUserType= UserTypeEnum.Store;
    this.client.Status=StatusEnum. InActive;
    this.client.ApproveStatusId=0; 
    this.getCommentsCount(this.client.CreatedUserType);
  }else{
    this.client.CreatedUserType= UserTypeEnum.EMX;
    this.getCommentsCount(this.client.CreatedUserType);
  }
  this.userType=this.client.CreatedUserType;
  }

  //////////////////////on Change Events /////////////////////////////
  getAttachmentTypes(){ 
    this.setupApi.getAttachmentTypesByIdentityType(this.client.IdentityTypeID).subscribe(res=>{
                this.attachmentTypes=res;
    }); 
  }
  getAttachments(){
   
    this.clientService.getAttachmentsById(this.client.Id).subscribe(res=>{
          this.clientattachments=res;
    }); 
  }
  onChangeClientType(value){ 
    this.setupApi. getIdentityByType(value).subscribe(res => { 
      this.identityTypes = res;  
      this.getAttachmentTypes();
      this.onChangeIdentityType(this.client.IdentityTypeID);
    });
  }

  onChangeOffice(value){ 
    this.CROfficeAdress=this.offices.filter(x=>x.Id==value)[0].Address;
  }

  onFilterOffice(event) {

    if(this.offices != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredOffices = this.offices;
        else
        this.filteredOffices = this.offices.filter((s) => s.NameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.offices.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onChangeCountry(event){ 
    this.cities=this.countries.filter(x=>x.CountryID==event)[0].Cities;
  }

  onFilterCountry(event) {

    if(this.countries != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCountries = this.countries;
        else
        this.filteredCountries = this.countries.filter((s) => s.CountryNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.countries.filter((s) => s.CountryNameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onFilterCity(event) {

    if(this.cities != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCities = this.cities;
        else
        this.filteredCities = this.cities.filter((s) => s.CityNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.cities.filter((s) => s.CityNameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onChangeIdentityType(id){ 
   
    if(this.client.ClientType==ClientTypeEnum.Corporate){
    //  
       if(this.isRtl()){
         this.IdentityTypeC1Text= this.identityTypes.filter(c=>c.Id!=id)[0].IdentityTypeAr;
         this.IdentityTypeC2Text= this.identityTypes.filter(c=>c.Id!=id)[1].IdentityTypeAr;
       }else{
        this.IdentityTypeC1Text= this.identityTypes.filter(c=>c.Id!=id)[0].IdentityTypeEn;
        this.IdentityTypeC2Text= this.identityTypes.filter(c=>c.Id!=id)[1].IdentityTypeEn;
       }
       this.IdentityTypeText= this.identityTypes.filter(c=>c.Id==id)[0].IdentityTypeEn;
       this.IdentityTypeText= this.identityTypes.filter(c=>c.Id==id)[1].IdentityTypeEn;
       
     
    }else {
      this. IdentityTypeText = this.translate("Identity")
    }
  }
  tabChanged($event){ 
   // this.selectedIndex = $event.tab.textLabel;
    this.selectedIndex = $event.index;
 
    if(this.selectedIndex==0){
      //////////Basic Info Tab////////

    }
    if(this.selectedIndex==1){
      //////////Company Tab////////
      this.getClientCompany(this.clientId);
    }
    if(this.selectedIndex==2){
      //////////Warehouse Tab////////
      this.getClientStore(this.clientId);
    }
    if(this.selectedIndex==3){
      //////////Bank Tab////////
  
      this.getClientBanks(this.clientId);
    }
  }

////////////////////////////////////////////////////////////////
////////////////////////Get Client Info/////////////////////////////////
  getClient(id){
    this.clientService.getClient(id).subscribe(res=>{
 
      this.client=res; 
      this.client.ClientType=this.client.IdentityType.ClientType;
      if(this.client.ClientType !=null){
        this.onChangeClientType(this.client.ClientType);
        this.getAttachments();
      }
      this.title=this.translate('Update Client')+' - ' + this.client.NameAr;
    });
  } 
//////////////////////////////////////////////////////////////////////
/////////////////////////   Get Lookups //// /////////////////////////////////
  getStatusLookup() {
      this.clientTypes=this. _helper.getNamesAndValues(ClientTypeEnum);
      this.genders= [ { "id": true, "name":   this.translate("Male") },{ "id": false, "name":   this.translate("Female") } ];
    
      this.setupApi.getNationalities().subscribe(res => { 
        this.nationalities = res; 
      });
      this.setupApi.getCountries().subscribe(res => { 
        this.countries = res; 
        this.onChangeCountry(this.CountryID);
      });
      this.setupApi.getCROffices().subscribe(res => { 
        this.offices = res; 
      });
  }

  ////////////////////////////////////////////////////////////
/////////////////Client Comments /////////////


getUnSeenClientCommentCount(id ,type){ 
 
  this.clientService.getUnSeenClientCommentCount(id,type).subscribe(res => { 
    this.commentsCount = res; 
  });
}

//////////////////////////////////////////////////////////////////
getCommentsCount(type){
  this.getUnSeenClientCommentCount(this.clientId, type  );
}

  ///////////////////////////////////////////////////////////////////
  
  saveFiles(id){
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
 
      var TypeId=this.uploadAttachmentTypes.filter(x=>x.Name==fileItem.file.name)[0].TypeId   ;
      
      form.append('EntityType', EntityTypeEnum.Client );
      form.append('TypeId', TypeId);
      form.append('EntityId', id); 
   };
    this.uploader.uploadAll();
    
    this.uploadAttachmentTypes=[];
    this.getAttachments();
  }
 
  downloadAttachment(filename ){ 
    this.fileAttachmentDto.filename=filename;
    this.fileAttachmentDto.  EntityType=this.client.Id ;
 
        this.setupApi.DownloadAttachment(this.fileAttachmentDto);
  }
  ViewAttachment(filename ){ 
    this.fileAttachmentDto.filename=filename;
    this. fileAttachmentDto.  EntityType=EntityTypeEnum.Client;
 
        this.setupApi.ViewAttachment(this.fileAttachmentDto);
  }
  deleteAttachment(data ){ 
    this.fileAttachmentDto.Id=data.Id;
    this.fileAttachmentDto.filename=data.AttachmentPath;
    this.  fileAttachmentDto.  EntityType=EntityTypeEnum.Client;
 
        this.setupApi.DeleteAttachment(this.fileAttachmentDto).subscribe(  re=>{

          this.getAttachments();
          }
          
         );
  }
  onBasicFormSubmit() {
    

    if( this. uploadAttachmentTypes!=null &&  this. uploadAttachmentTypes.length>0){
      this.errors = []; 
        
      let expiryDate = new Date(this.client.ExpiryDate);
      this.client.ExpiryDate = new Date(
        Date.UTC(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate()));
  
        let DateofBirth = new Date(this.client.DateofBirth);
        this.client.DateofBirth = new Date(
          Date.UTC(DateofBirth.getFullYear(), DateofBirth.getMonth(), DateofBirth.getDate()));
  
        let IssueDate = new Date(this.client. IssueDate);
        this.client.IssueDate = new Date(
          Date.UTC(IssueDate.getFullYear(), IssueDate.getMonth(), IssueDate.getDate()));
  
      if (this.client.Id > 0) {
        this.clientService.updateClient(this.client ,this.client.Id)
  
        .subscribe(() => {  
          this.saveFiles(this.client.Id);
          this.toastr.success( this.translate("Successfully") 
        );
        },
          err => {
            this.errors.push(err.error[0]);
            this.toastr.error(err.error[0]);
          });
  
      } else {  
        
        this.client.ApproveStatusId=ApproveStatusEnum.AwaitApprove; 
        this.client.Status=StatusEnum.InActive;
        this.client.NameEn=   this.client.NameAr;
  
        this.clientService.insertClient(this.client).subscribe(res => {
          this.client.Id=Number(res);
          this.clientId=this.client.Id;
          this.saveFiles(this.clientId);
          this.toastr.success(  this.translate("Successfully"));
        },
          err => {
            this.errors.push(  this.translate(err.error[0]));
            this.toastr.error(  this.translate(err.error[0]));
  
          }
        ); 
      }
    }else{
      this.toastr.error(  this.translate('Files must be attached'));
    }



  }


////////////////Get Client Companies////////////////////

getClientCompany(id){
 
  if(this.clientCompanies==null){
    if(this.isBroker()){
      
      this.clientService.GetCompanyClientByClientCompanyId(id,this.companyId).subscribe(res=>{
        this.clientCompanies=res; 
     });
    }else{
      this.clientService.GetCompanyClientByClientId(id).subscribe(res=>{
        this.clientCompanies=res; 
     });
    }

  }
 }

 addEvent(event: MatDatepickerInputEvent<Date>) {
  this.dateEvent = event.value;}
////////////Get Client Stores ////////////////
getClientStore(id){

  if(this.clientStores==null){
    this.clientService.getStoreByClientId(id).subscribe(res=>{
      this.clientStores=res;
   });
  }

}
////////getClient Bank//////////////////
getClientBanks(id){
 // if(this.clientBanks!=null){
    this.clientService.getClientBanks(id).subscribe(res=>{
    
      this.clientBanks=res;
     
    });
  //}

 }
///////Get Comments/////////////////////////////////
getCommentsDialog(){
   

  const dialog = this.dialog.open(ClientCommentsDialogComponent, {
    data:{ clientId:this.clientId ,clientComments:this.client.ClientComments }  
  });
  dialog.afterClosed().subscribe((res) => {
     
    this.getCommentsCount(this.userType);
   } );

}
onClose(){
  let NationalIdType = IdentityTypeEnum.NationalID;      
  this.minExpiryDate = this.client.IssueDate;  
  if(this.client.IdentityTypeID == NationalIdType){
  this.year= this.client.IssueDate.getFullYear()+7 ;
  this.month= this.client.IssueDate.getMonth() ;
  this.day= this.client.IssueDate.getDate() ;
  this.client.ExpiryDate = new Date(this.year, this.month, this.day)
  this.isNationalId = true;
  console.log(this.client.ExpiryDate);
}else{
  this.isNationalId = false
}
}

}
