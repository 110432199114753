
<form class="popup-card-width add-new-user order-ticket">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title | translate}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
	
		<div class="gene-card-content">
			<div class="bank-details">

                <div class="mrgn-b-xs" mat-card-widget fxLayout="row wrap" fxLayoutAlign="space-between start"
					fxLayoutGap="15px" *ngIf="statisticsData == null || statisticsData == undefined">
                         No data to display
                </div>

				<div class="mrgn-b-xs" mat-card-widget fxLayout="row wrap" fxLayoutAlign="space-between start"
					fxLayoutGap="5x" *ngIf="statisticsData != null && statisticsData != undefined">
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">

							<!-- <div class="mrgn-b-xs">							
								 <span fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65">	{{'Total Orders #' | translate }} :</span>
							     <span fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65" class="font-normal">{{statisticsData.TotalOrdersCount| number}}</span>								
							</div> -->

							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55">{{'Total Open Orders' | translate }} : </span>
								<span fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{statisticsData.OpenOrdersCount| number}}</span>
							</div>
							
                            <div class="mrgn-b-xs">
                                <span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55">  {{'Total Quantity' | translate}} :</span>  
                                 <span fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  	class="font-normal">{{statisticsData.TotalQuantity| number}}</span></div>

							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55"> {{'Average Quantity' | translate}} :</span>
								 <span fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{statisticsData.AverageQuantity| number}}</span></div>

                                 <div class="mrgn-b-xs">
                                    <span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55" >{{'Highest Price' | translate }} : </span>
                                    <span fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{statisticsData.HightestPrice| number}}</span>
                                </div>

                                <div class="mrgn-b-xs">
                                    <span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55"> {{'Lowest Price' | translate }} :</span>
                                    <span	fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{statisticsData.LowestPrice| number}}</span>
                                </div>

                                <div class="mrgn-b-xs">
                                    <span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55" >  {{'Average Price' | translate }} :</span> 
                                    <span  fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{statisticsData.AveragePrice| number}}</span></div>
                                 <!-- 
                                     <div class="button-wrap">
                                        <button class="gene-block mrgn-b-sm full-wid" mat-raised-button color="primary"
                                            (click)="close()">{{'CLOSE' | translate}}</button>
                                </div> -->                                    
						</div>
					</div>

					<div class="price-wrap" fxFlex.gt-sm="180px" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55" >  {{'Open Date' | translate }} :</span> 
								<span  fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{ openDate | date: 'dd/MM/yyyy'}}</span></div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55" >  {{'Close Date' | translate }} :</span> 
								<span  fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45"  class="font-normal">{{ closeDate | date: 'dd/MM/yyyy'}}</span></div>
						</div>
                        <br> <br> <br>
						<div class="button-wrap">
							<button class="gene-block mrgn-b-sm full-wid" mat-raised-button color="primary"
								(click)="close()">{{'CLOSE' | translate}}</button>
					    </div>
						
					</div>
				</div>

			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->

	</mat-dialog-content>

</form>