<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
			
				
				<div class="mrgn-l-sm mrgn-r-sm">
                        <h4>{{'Commissions'| translate }}</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<mat-card-actions class="mat-pad-none margin-none">
					<button mat-raised-button mat-button-sm color="primary"
					*ngIf="auth.isGranted(_permissions.AddCommissions)" (click)="addNewDialog()">{{'ADD NEW COMMISION'| translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</mat-card-actions>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > 
				  <kendo-grid-checkbox-column headerClass="grid-list-column-header" [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
				   

				  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="CommissionNameEN" title="{{'Name English'| translate }}" headerClass="grid-list-column-header"  width="300"  > </kendo-grid-column>
				    
				  <kendo-grid-column field="CommissionNameAR" title="{{'Name Arabic'| translate }}" headerClass="grid-list-column-header" width="250"  > </kendo-grid-column>

				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item    (click)="edit(dataItem)"  
							*ngIf="auth.isGranted(_permissions.EditCommissions)" >
								{{ 'View'| translate }}
							</a>
 
							<a mat-menu-item     (click)="delete(dataItem)"  
							*ngIf="auth.isGranted(_permissions.DeleteCommissions)"  >
								{{ 'Delete'| translate }}
							  </a>
						 
							 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>