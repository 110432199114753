import { CurrentLanguageService } from './../Shared/services/currentLanguage.service';
import { filter } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, ViewEncapsulation, Injector } from '@angular/core';
import { MenuItems } from '../core/menu/menu-items/menu-items';
import { BreadcrumbService } from 'ng5-breadcrumb';
import { PageTitleService } from '../core/page-title/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TourService } from 'ngx-tour-md-menu';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthService } from 'app/account/services/auth.service';

import { CoreService } from '../service/core/core.service';
import { MarketDto } from 'app/models/market';
import { AnnouncementDto, AnnouncementRequestDto } from 'app/models/announcement';
import { SetupApi } from 'app/Shared/api/setup.api';
import { AppComponentBase } from 'app/app-component-base';
import { SignalRService } from '../service/signalr/signalr.service';
import {   AnnouncementTypeEnum, MarketStatusEnum, MarketTypeEnum } from 'app/core/enums';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { NewsApi } from 'app/news/api/news-api';
import { MatDialog } from '@angular/material/dialog'; 
import { NewViewComponent } from 'app/Shared/components/new-view/new-view.component';
import { KendoService } from 'app/service/kendo/kendo.service';
import * as moment from 'moment';

declare var require;

const screenfull = require('screenfull');

@Component({
	selector: 'gene-layout',
	templateUrl: './main-material.html',
	styleUrls: ['./main-material.scss'],
	encapsulation: ViewEncapsulation.None,
	host: {
		'(window:resize)': 'onResize($event)'
	}
})

export class MainComponent extends AppComponentBase implements OnInit, OnDestroy {

	localTime = new Date().toLocaleTimeString();

	/* #region declaration  */
	currentUrl: any;
	root: any = 'ltr';
	layout: any = 'ltr';
    defualtSelectIndexMarket=0;
	currentLang: any = 'en';
	customizerIn: boolean = false;
	showSettings: boolean = false;
	chatpanelOpen: boolean = false;
	sidenavOpen: boolean = true;
	isMobile: boolean = false;
	isFullscreen: boolean = false;
	collapseSidebarStatus: boolean;
	marketTitle: string;
	market: any;
	header: string;
	header_color:string= ""
	dark: boolean;
	compactSidebar: boolean;
	isMobileStatus: boolean;
	sidenavMode: string = 'side';
	popupDeleteResponse: any;
	sidebarColor: any;
	url: string;
	windowSize: number;
	isDashboard: boolean;
	marketId: number  ;
	marketStatusItem: any[];
	mrketTypeEnum=MarketTypeEnum;
	marketStatus = MarketStatusEnum.Trade;
	filterOptions: AnnouncementRequestDto = new AnnouncementRequestDto();
	isOpen :boolean;
	slideBar: boolean;
	FromDate: Date;
	ToDate: Date;
	markets: MarketDto[];
	announcements: AnnouncementDto[];
	private _routerEventsSubscription: Subscription;
	private _router: Subscription;
	@ViewChild('sidenav', { static: true }) sidenav;

	/* #endregion */

	/* #region  set filter in right SideBar */
	sideBarFilterClass: any = [
		{
			sideBarSelect: "sidebar-color-1",
			colorSelect: "sidebar-color-dark"
		},
		{
			sideBarSelect: "sidebar-color-2",
			colorSelect: "sidebar-color-primary",
		},
		{
			sideBarSelect: "sidebar-color-3",
			colorSelect: "sidebar-color-accent"
		},
		{
			sideBarSelect: "sidebar-color-4",
			colorSelect: "sidebar-color-warn"
		},
		{
			sideBarSelect: "sidebar-color-5",
			colorSelect: "sidebar-color-green"
		}
	]

	headerFilterClass: any = [
		{
			headerSelect: "header-color-1",
			colorSelect: "header-color-dark"
		},
		{
			headerSelect: "header-color-2",
			colorSelect: "header-color-primary"
		},
		{
			headerSelect: "header-color-3",
			colorSelect: "header-color-accent"
		},
		{
			headerSelect: "header-color-4",
			colorSelect: "header-color-warning"
		},
		{
			headerSelect: "header-color-5",
			colorSelect: "header-color-green"
		}
	]

	chatList: any[] = [
		{
			image: "assets/img/user-3.jpg",
			name: "Hatem Iskander",
			chat: "Customer support agent",
			mode: "online"
		},
		{
			image: "assets/img/user-1.jpg",
			name: "Youssef Yassin",
			chat: "Customer support agent",
			mode: "online"
		},
		{
			image: "assets/img/user-3.jpg",
			name: "Mohammed Nabil",
			chat: "Customer support agent",
			mode: "online"
		},
		{
			image: "assets/img/user-1.jpg",
			name: "Mohammed Matarawy",
			chat: "Customer support agent",
			mode: "online"
		},
		{
			image: "assets/img/user-3.jpg",
			name: "Mostafa Abdulhmaed",
			chat: "Customer support agent",
			mode: "online"
		}

	]
	/* #endregion */
	
	/* #region constructor & on in it  */
	constructor(public tourService: TourService,
		private dialog: MatDialog,
		public menuItems: MenuItems,
		private breadcrumbService: BreadcrumbService,
		private pageTitleService: PageTitleService,
		private dashboardParamService:DashboardParamService,
		private router: Router,
		private authService: AuthService,
		public coreService: CoreService,
		private setupApi: SetupApi,
		private newsApi: NewsApi, 
	
		private readonly signalrService: SignalRService,
		private currentLanguageService: CurrentLanguageService,
		injector: Injector,) {
		super(injector);
		
		setInterval(() => {
			this.localTime = new Date().toTimeString().substring(0,9);
		  }, 1000)
     
       
		//this.Date = moment.utc(this.localDateTime).local().format('MMMM DD, LT');
		this.currentLang = this.currentLanguageService.currentSessionLanguage;
	    //this.currentLanguageService.useSessionLanguageOrDefault(	);
		// check window width to open toaster
		if (window.innerWidth > 1199) {
			this.tourService.start();
		}
			// get current language and change langauge


		if (this.currentLang === 'ar') {
			this.changeRTL(true);
		} else {
		this.changeRTL(false);
		}
		// check market status change from all the system
		signalrService.marketStatusChanges.subscribe(item => {
	   
			let oldMarketSessions= this.marketStatus; 
			let marketSessions = JSON.parse(item);
			// get status changes and mapping it
			var marketSession = marketSessions.filter(x => x.MarketId == this.marketId);
			if (marketSession.length > 0) { 
				this.marketStatus = marketSession[0].MarketStatus;
			} 
			if(oldMarketSessions!=this.marketStatus){ 
				this.dashboardParamService.setMarketStatus(this.marketStatus);
			}
           this.checkMarkatStatus();
		});

		
		// get anouncment news from all the system
		signalrService.announcementChanges.subscribe(item => {
			let news = JSON.parse(item);
			// get json news and mapping to list
			if(news!=null && news.length>0){
				this.isOpen = true;
					news.forEach(element => {
						if(element.AnnouncementType==AnnouncementTypeEnum.Announcement){
							
							if(this.announcements.filter(x=>x.AnnouncementID==
								element.AnnouncementID).length>0){
								this.announcements.filter(x=>x.AnnouncementID==
									element.AnnouncementID)[0].HeaderEn=element.HeaderEn;

									this.announcements.filter(x=>x.AnnouncementID==
										element.AnnouncementID)[0].HeadrAr=element.HeadrAr;
							}else{
								this.announcements = [element, ...this.announcements];
							}
						}
			});	 
		}
		});

		breadcrumbService.addFriendlyNameForRoute('/dashboard', 'Dashboard');
		breadcrumbService.addFriendlyNameForRoute('/Auctions', 'Auctions');
	}

	ngOnInit() {


		this.filterOptions.FromDate=this.FromDate=new Date();
        this.filterOptions.ToDate=this.ToDate=new Date();
        //this.filterOptions.AnouncementType = AnnouncementTypeEnum.Announcement;
        this.getFilteredNews();
		// method to check collapse sidebar 
		this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;
		this.pageTitleService.title.subscribe((val: string) => {
			this.header = val;
		});


		
		this.url = this.router.url;
		this.customizeSidebar();

		this.windowSize = window.innerWidth;
		this.resizeSideBar();

		// check route to customize side bar
		this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;
			this.url = event.url;
			this.customizeSidebar();
		});

		// check between mobile or desktop to close side bar
		this._routerEventsSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd && this.isMobile) {
				this.sidenav.close();
			}
		});
		//	this.headerFilter(this.headerFilterClass[1]);
	}
	/* #endregion */


	/* #region  Methods */
	checkMarkatStatus(){
		if(this.marketStatus==MarketStatusEnum.Trade){
			this.marketStatusItem = [
				{
					title: this.marketStatus,
					duration: "Normal",
					color: "success-bg",
					value: null
				} 
			]
			this.header_color= "session-open";
		}else  if(this.marketStatus==MarketStatusEnum. Preopen){
			this.marketStatusItem = [
				{
					title: this.marketStatus,
					duration:  "Pre Open",
					color: "accent-bg",
					value: null
				} 
			]
			this.header_color= "session-preopen";
		}else{
			this.marketStatusItem = [
				{
					title: this.marketStatus,
					duration: "Session Close",
					color: "warn-bg",
					value: null
				} 
			]
			this.header_color= "session-close";
		}

	}
	getLayout($event: string) {
   
		this.layout = $event;

	}

	

	getFilteredNews() {
  
		this.filterOptions.Take = 25;
	 
	 
	   if(this.filterOptions.ToDate !=null){
		this.filterOptions.ToDate = new Date(this.ToDate).toDateString();
	  }
	  if(this.filterOptions.FromDate !=null){
		this.filterOptions.FromDate = new Date(this.FromDate).toDateString();
	  }
	 
		this.newsApi.getAnnouncements(this.filterOptions).subscribe(items => {
		  this.announcements = items;  
		});
	
	  
	  }
	  viewNews(news){ 
  
		if(news.AnnouncementType==AnnouncementTypeEnum.CooperativeReceiptAuction){
			this.router.navigate(['/cooperatives/receipt-auctions-report']);
			
		}else{
			const dialog = this.dialog.open(NewViewComponent, {
				data:{news:news}  
			  });
			  dialog.afterClosed().subscribe(() => {
			  } );
		}

	  }
	  /**
	  * logOut method is used to log out the  template.
	  */
	logOut() {
		this.authService.logout().subscribe(user => { })
	}



	/**
	  * sidebarFilter function filter the color for sidebar section.
	  */
	sidebarFilter(selectedFilter) {

		for (var i = 0; i < this.sideBarFilterClass.length; i++) {
			document.getElementById('main-app').classList.remove(this.sideBarFilterClass[i].colorSelect);
			if (this.sideBarFilterClass[i].colorSelect == selectedFilter.colorSelect) {
				document.getElementById('main-app').classList.add(this.sideBarFilterClass[i].colorSelect);
			}
		}
		document.querySelector('.radius-circle').classList.remove('radius-circle');
		document.getElementById(selectedFilter.sideBarSelect).classList.add('radius-circle');
	}

	/**
	  * headerFilter function filter the color for header section.
	  */
	headerFilter(selectedFilter) {
		for (var i = 0; i < this.headerFilterClass.length; i++) {
			document.getElementById('main-app').classList.remove(this.headerFilterClass[i].colorSelect);
			if (this.headerFilterClass[i].colorSelect == selectedFilter.colorSelect) {
				document.getElementById('main-app').classList.add(this.headerFilterClass[i].colorSelect);
			}
		}
		document.querySelector('.radius-active').classList.remove('radius-active');
		document.getElementById(selectedFilter.headerSelect).classList.add('radius-active');
	}

// method to change market and change title if arabic
selectMarket(market) {
     
	this.marketId = market.Id;
	this.marketStatus=market.MarketStatus;
	this.marketTitle = market.NameEn;
	if (this.isRtl() ) {
		this.marketTitle  = market.NameAr;
	}
	this.market=market;
	this.dashboardParamService.setMarketTitle(this.marketTitle );
	 
	  this.dashboardParamService.setMarketId(market.Id);
	  if (this.marketStatus == MarketStatusEnum.Preopen) {
	//  this.dashboardParamService.setIsOpen(true);
	  } else {
		 //this.dashboardParamService.setIsOpen(false);
	  }

	this.checkMarkatStatus();

}


userProfile() {
	this.router.navigate(['/admin/profile']);
}
menuAnouncment(){
	this.isOpen = false;
}


	/* #endregion */


	/* #region EVents  */
	ngOnDestroy() {
		this._router.unsubscribe();
	}
	notificationMenu() {
		document.getElementById("gene-notification").classList.toggle("show-notification-list");
	}

	chatMenu() {
		document.getElementById("gene-chat").classList.toggle("show-chat-list");
	}
	/**
	  * onChatOpen method is used to open a chat window.
	  */
	onChatOpen() {
		document.getElementById('chat-open').classList.toggle('show-chat-window');
	}

	/**
	  * onChatWindowClose method is used to close the chat window.
	  */
	chatWindowClose() {
		document.getElementById("chat-open").classList.remove("show-chat-window");
	}
	/**
	  *As router outlet will emit an activate event any time a new component is being instantiated.
	  */
	onActivate(e, scrollContainer) {
		scrollContainer.scrollTop = 0;
		if(this.router.url == '/gold-market'||this.router.url == '/Auctions' || this.router.url == '/dashboard'){
			if (window.innerWidth < 1920) {
			 	this.coreService.sidenavOpen = false;
			}
		
		}
		if (this.router.url == '/dashboard'  ) {
			this.isDashboard = true;;

			// check if it is store keeper login to hide market tabs in dash board 
			if (!this.isStoreKeeper()) {
				this.setupApi.getActiveMarkets().subscribe(res => {
					this.markets = res;
					this.markets =this.markets .filter(x=>x.ParentMarketId>0 && x.MarketType == this.mrketTypeEnum.General) ;
                    this.defualtSelectIndexMarket=0;
					this.selectMarket(this.markets [0]);

				}, err => console.log(err));

			} else {
				this.setupApi.getStoreKeeperInfo()
					.subscribe(res => {
						this.pageTitleService.setTitle(this.translate(res.StoreInfo.StoreNameEn))
					});

			}
 
		} else {
			this.isDashboard = false;
		}
	}

	/**
	  * toggleFullscreen method is used to show a template in fullscreen.
	  */
	toggleFullscreen() {

		if (screenfull.isEnabled) {
			screenfull.toggle();
			this.isFullscreen = !this.isFullscreen;
		}
	}

	/**
	  * customizerFunction is used to open and close the customizer.
	  */
	customizerFunction() {
		this.customizerIn = !this.customizerIn;
	}

	/**
	  * addClassOnBody method is used to add a add or remove class on body.
	  */
	addClassOnBody(event) {
		var body = document.body;
		if (event.checked) {
			body.classList.add("dark-theme-active");
		} else {
			body.classList.remove('dark-theme-active');
		}
	}

	/**
	  * changeRTL method is used to change the layout of template.
	  */
	changeRTL(isChecked) {
	
		if (isChecked) {
			this.layout = "rtl"
		}
		else { 
			this.layout = "ltr"
		} 			

	}
	// 	  * toggleSidebar method is used a toggle a slide bar.
	collapseSildebar(isChecked) {
		if (isChecked) {
			this.slideBar = true
		}
		else {
			this.slideBar = false;
		}
	}

	/**
	  * toggleSidebar method is used a toggle a side nav bar.
	  */
	toggleSidebar() {
		this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
	}
	collapseSidebar(event) {
		if (event.checked) {
			this.coreService.collapseSidebar = true;
		} else {
			this.coreService.collapseSidebar = false;
		}
	}

	//onResize method is used to set the side bar according to window width.
	onResize(event) {
		this.windowSize = event.target.innerWidth;
		this.resizeSideBar();
	}
	//customizeSidebar method is used to change the side bar behaviour.
	customizeSidebar() {
		if ((this.url === '/dashboard' || this.url === '/gold-market'||this.url === '/Auctions') && this.windowSize < 1920) {
			this.coreService.sidenavMode = 'over';
			this.coreService.sidenavOpen = false;
			if (!(document.getElementById('main-app').classList.contains('sidebar-overlay'))) {
				document.getElementById('main-app').className += " sidebar-overlay";
			}

		}
		else if ((window.innerWidth > 1200) && !(this.url === '/dashboard'|| this.url == '/gold-market'||this.url === '/Auctions')) {
			this.coreService.sidenavMode = 'side';
			this.coreService.sidenavOpen = true;
			//for responsive
			var main_div = document.getElementsByClassName('app');
			for (let i = 0; i < main_div.length; i++) {
				if (main_div[i].classList.contains('sidebar-overlay')) {
					document.getElementById('main-app').classList.remove('sidebar-overlay');
				}
			}
		}
		//for responsive
		else if (window.innerWidth < 1200) {
			this.coreService.sidenavMode = 'over';
			this.coreService.sidenavOpen = false;
			var main_div = document.getElementsByClassName('app');
			for (let i = 0; i < main_div.length; i++) {
				if (!(main_div[i].classList.contains('sidebar-overlay'))) {
					document.getElementById('main-app').className += " sidebar-overlay";
				}
			}
		}
	}

	//To resize the side bar according to window width.
	resizeSideBar() {
		if (this.windowSize < 1200) {
			this.isMobileStatus = true;
			this.isMobile = this.isMobileStatus;
			this.coreService.sidenavMode = 'over';
			this.coreService.sidenavOpen = false;
			//for responsive
			var main_div = document.getElementsByClassName('app');
			for (let i = 0; i < main_div.length; i++) {
				if (!(main_div[i].classList.contains('sidebar-overlay'))) {
					if (document.getElementById('main-app')) {
						document.getElementById('main-app').className += " sidebar-overlay";
					}
				}
			}
		}
		else if ((this.url === '/dashboard' || this.url === '/gold-market'|| this.router.url === '/Auctions') && this.windowSize < 1920) {
			this.customizeSidebar();
		}
		else {
			this.isMobileStatus = false;
			this.isMobile = this.isMobileStatus;
			this.coreService.sidenavMode = 'side';
			this.coreService.sidenavOpen = true;
			//for responsive
			var main_div = document.getElementsByClassName('app');
			for (let i = 0; i < main_div.length; i++) {
				if (main_div[i].classList.contains('sidebar-overlay')) {
					document.getElementById('main-app').classList.remove('sidebar-overlay');
				}
			}
		}
	}
	
	onTabChanged($event) {
	    
		let clickedIndex = $event.index;
		if(this.markets.length>0){

			this.selectMarket(this.markets[clickedIndex]) ;
		}
	  }

	/* #endregion */
	

	

	
}