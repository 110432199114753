<div class="popup-card-width">

    <mat-dialog-content>
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4 class="mrgn-b-none">{{title}}</h4>
                </div>
    
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="gene-card-content">
            <kendo-grid [kendoGridBinding]="items"          
                [resizable]="true" 
                [reorderable]="true"
                [filterable]="false" 
                [sortable]="true" 
                class="kendo-grid-list trades-kendo-grid" >
     
                <ng-template kendoGridToolbarTemplate>
                  <button type="button" kendoGridExcelCommand icon="file-excel">
                    Export to Excel
                  </button>
                  </ng-template>
                  <kendo-grid-excel fileName="OrderHistory.xlsx" > 
                 </kendo-grid-excel>

                   <kendo-grid-column  field="time" title="{{ 'Time' | translate }}" width="80">
                    <ng-template kendoGridCellTemplate let-dataItem>
                     
                      <span *ngIf="dataItem.LastUpdatedDate!=null">  {{dataItem.LastUpdatedDate | date: 'h:mm:ss'}}</span>
                      <span *ngIf="dataItem.LastUpdatedDate==null">  {{dataItem.CreatedDate | date: 'h:mm:ss'}}</span>
                            
                    </ng-template>
                    </kendo-grid-column>
                   <kendo-grid-column  field="date" title="{{ 'Date' | translate }}" width="80">
                    <ng-template kendoGridCellTemplate let-dataItem>
                         
                     <span *ngIf="dataItem.LastUpdatedDate!=null">  {{dataItem.LastUpdatedDate |  date: 'dd-MM-yyyy'}}</span>
                      <span *ngIf="dataItem.LastUpdatedDate==null">  {{dataItem.CreatedDate |  date: 'dd-MM-yyyy'}}</span>
                            
                    </ng-template>
                    </kendo-grid-column>
                   <kendo-grid-column field="OrderQty" title="{{ 'Qty' | translate }}" width="50">
                    <ng-template kendoGridCellTemplate let-dataItem>
               
                      {{dataItem.OrderQty}}
                    
                    </ng-template>
                   
                   </kendo-grid-column>                  
                   <kendo-grid-column field="OrderPrice" title="{{ 'Price' | translate }}"  width="60">                  
                    <ng-template kendoGridCellTemplate let-dataItem>
 
                      
                      {{dataItem.OrderPrice}}
      
                    </ng-template>
                   </kendo-grid-column>
                   <kendo-grid-column field="OrderStatusTxt" title="{{ 'OrderStatus' | translate }}"   width="80">
                   
                    <ng-template kendoGridCellTemplate let-dataItem>
 
                      
                      {{dataItem.OrderStatusTxt}}
      
                    </ng-template>
                   </kendo-grid-column>
                   <kendo-grid-column field="MsgTypeTxt" title="{{ 'Action' | translate }}" width="70">
                    <ng-template kendoGridCellTemplate let-dataItem>
               
                      {{dataItem.MsgTypeTxt}}
                    
                    </ng-template>
                   
                   </kendo-grid-column>   
            </kendo-grid>
        </div>
    
    </mat-dialog-content>

</div>
