 

<div  >
          <kendo-grid  
              class="market-kendo-grid"
              #grid  [kendoGridBinding]="items" 
              [resizable]="true"
              [reorderable]="true"
              [filter]="filter"
              filterable="menu" 
              [columnMenu]="true"
              [sortable]="true"  
               [kendoGridSelectBy]="selectBy"
              >  
              <kendo-grid-column field="AuctionID" title=" " width="40"></kendo-grid-column> 
 
             <kendo-grid-column  field="TradeDate" title="{{ 'Time' | translate }}" width="100">
              <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.TradeDate | date: 'h:mm:ss a'}}
              </ng-template>
              </kendo-grid-column>
  <kendo-grid-column field="SymbolCode" title="{{ 'Symbol' | translate }}" width="100"></kendo-grid-column>
  <!-- <kendo-grid-column field="TradeDate" title="Date"></kendo-grid-column>  -->
  <kendo-grid-column field="TradedQty" title="{{ 'Qty' | translate }}" width="85">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span 
        > {{dataItem.TradedQty | number }}  
      </span>
    </ng-template>
  </kendo-grid-column> 
  <kendo-grid-column field="TradePrice" title="{{ 'Price' | translate }}" width="85">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span 
        > {{dataItem.TradePrice | number }}  
      </span>
    </ng-template>
  </kendo-grid-column> 
 
  </kendo-grid>
 
    </div> 
   
    