import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LockScreenComponent } from './lockscreen/lockscreen.component';
 import{ChangePasswordComponent} from './change-password/change-password.component';
import{ConfirmComponent} from './confirm/confirm.component';  
import { NewPasswordComponent } from './new-password/new-password.component';
export const AccountRoutes: Routes = [
   {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full'
   },
   {
      path: '',
      children: [
         {
            path: 'login',
            component: LoginComponent
         },
         {
            path: 'register',
            component:  RegisterComponent
         },
         {
            path: 'forgot-password',
            component: ForgotPasswordComponent
         },
         {
            path: 'lockscreen',
            component:  LockScreenComponent
         },
         {
             path: 'change-password',
             component:  ChangePasswordComponent
         },
         {
             path: 'new-password',
             component:  NewPasswordComponent
         }
         ,
         {
             path: 'confirm',
             component:  ConfirmComponent
         }
         
  
         
      ]
   }
];
