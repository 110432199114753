<kendo-grid #grid [kendoGridBinding]="items" class="order-kendo-grid" [pageable]="false" [pageSize]="20"
  [resizable]="true" [reorderable]="true" filterable="menu" [sortable]="true" [columnMenu]="true"
  [kendoGridSelectBy]="selectBy" (cellClick)="onCellClick($event)" (dataStateChange)="onDataStateChange()">
  <kendo-grid-column title="" width="40" [columnMenu]="false"
    *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a class="cursor_pointer"
        *ngIf="dataItem.IsAttachment==true   &&(  isEMX() ||  dataItem.OrderStatus==OrderSataus.Private  || dataItem.CompanyID==this.companyID )"
        (click)="downloadAttachment(dataItem)"> <mat-icon>cloud_download</mat-icon></a>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column field="OrderPrice" title="Price" title="{{ 'Price' | translate }}" width="80"
     >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span  *ngIf="IsAllowShowPrice (dataItem.CompanyID)"
      [ngClass]="{'buy': dataItem.CompanyID!=companyID  }">
        {{dataItem.OrderPrice | number }} </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="RemainingQty" title="{{ 'Qty' | translate }}" width="80"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span   *ngIf="IsAllowShowQty (dataItem.CompanyID)"
       [ngClass]="{'buy': dataItem.CompanyID!=companyID   }">
        {{dataItem.RemainingQty | number }} </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="ClientCode" title="{{ 'Client Code' | translate }}" width="100"
   >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'buy': dataItem.CompanyID!=companyID  }"
      *ngIf="IsAllowClient (dataItem.CompanyID)">
        {{dataItem.ClientCode}}
      </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="BrokerCode" title="{{ 'Broker Code' | translate }}" width="100"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'buy': dataItem.CompanyID!=companyID  }"
      *ngIf="IsAllowBroker (dataItem.CompanyID)">
        {{dataItem.BrokerCode}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="CompanyCode" title="{{ 'Firm' | translate }}" width="200"
   >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span [ngClass]="{'buy': dataItem.CompanyID!=companyID  }"
      *ngIf="IsAllowCompany (dataItem.CompanyID)">
        {{dataItem.CompanyName}}
      </span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>