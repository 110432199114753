<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
			 
				<div class="mrgn-l-sm mrgn-r-sm">
			                <h4>{{title}}</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<mat-card-actions class="mat-pad-none margin-none">

					<button mat-raised-button color="primary" 	
					*ngIf="auth.isGranted(_permissions.AddClients) " 
					[routerLink]=  "['/emx/addEmxUser']"   >{{ 'ADD NEW EMX USER' | translate }} <mat-icon class="mrgn-l-sm">add</mat-icon></button>

				 
				</mat-card-actions>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="table-responsive">

			<kendo-grid [kendoGridBinding]="items"
			class="kendo-grid-list" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"    
				  [sortable]="true" 
				  [kendoGridSelectBy]="selectBy"
				   
				  >
				   
				  <kendo-grid-column   title="{{ 'Id'| translate }}"   width="70" headerClass="grid-list-column-header" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  
 
				
		  
				 <kendo-grid-column field="Name" title="{{ 'Name' | translate }} " headerClass="grid-list-column-header" width="300" > </kendo-grid-column>
				  
				   
				  <kendo-grid-column field="Telephone" title="{{ 'Telephone' | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="Email" title="{{ 'Email' | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
				  
				  <kendo-grid-column field="Suspended" title="{{ 'Status' | translate }}  " headerClass="grid-list-column-header"width="120"
				    > 
 
						<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
							<span *ngIf="dataItem.Status==_StatusEnum.Active" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
							<span *ngIf="dataItem.Status!=_StatusEnum.Active" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
						</ng-template>
				  </kendo-grid-column>
				
				  <kendo-grid-column   title=""  width="80"  
				  headerClass="grid-list-column-header" 
				  *ngIf="auth.isGranted(_permissions.UpdateEMXUser)
				  ||auth.isGranted(_permissions.DeleteEMXUser)"
				   >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
					   aria-label="Open anchor menu" 
				
				>
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item  
							[routerLink]="['/emx/editUser/'+ dataItem.Id ]"  
							*ngIf="auth.isGranted(_permissions.UpdateEMXUser)"
						    >
							
							  {{ 'View'| translate }}
							</a>
							<a mat-menu-item   
						     	(click)="changePropertiesStatus(dataItem)"   >
							   {{ 'Change Status' | translate }} 
					       </a>
							<a mat-menu-item   (click)="deleteEMXUser(dataItem)"  
						    	*ngIf="auth.isGranted(_permissions.DeleteEMXUser)"  >
					           {{ 'Delete'| translate }}
							  </a> 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>