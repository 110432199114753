import { Injectable } from '@angular/core';  
import { Observable } from 'rxjs';
import { SymbolApi } from '../api/symbol.api';
  

@Injectable()
export class SymbolService {

  
  constructor(private symbolApi: SymbolApi ) { }

  getSymbol(id:any): Observable<any> {
    return this.symbolApi.getSymbol(id);
  } 
  getSymbolByCode(code:any): Observable<any> {
    return this.symbolApi.getSymbolByCode(code);
  } 
 
  getSymbols(): Observable< []> {
    return this.symbolApi.getSymbols();
  } 
  
  insertSymbol(SymbolRequest: any): Observable<any> {
 
    return this.symbolApi.insertSymbol(SymbolRequest);
 }

 updateSymbol(SymbolRequest: any,id :any): Observable<boolean> {

  return this.symbolApi.updateSymbol(SymbolRequest,id);
}

 deleteSymbol(id: any): Observable<boolean> {

  return this.symbolApi.deleteSymbol(id);
 }

    
 searchActiveSymbols(model: any): Observable<any> {

  return this.symbolApi.searchActiveSymbols(model);
 }
 
 updateOrCreateSymbolMarketCommission(id:any,SymbolRequest: any): Observable<any> {
  return this.symbolApi.updateOrCreateSymbolMarketCommission(id,SymbolRequest);
 }

 addStoreSymbol(storeSymbol: any,id :any): Observable<boolean> {
  return this.symbolApi.addStoreSymbol(storeSymbol,id);
  }
  deleteStoreSymbol(StoreId,symbolId): Observable<boolean> {
    return this.symbolApi.deleteStoreSymbol(StoreId,symbolId);
   }
    

}