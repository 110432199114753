import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { permissions } from 'app/core/permission';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ApproveStatusEnum, BalanceSourceEnum, BalanceStatusEnum, BalanceTypeEnum, ClientTypeEnum, CompanyTypeEnum, EntityTypeEnum, StatusEnum, TradeTypeEnum } from 'app/core/enums';
import { Helpers } from 'app/helper/helpers';
import { AppConsts } from 'app/core/config';
import { ClientStatementDto } from 'app/models/ClientStatement';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientReuqest } from 'app/models/client';
import { UploadAttachmentDto } from 'app/models/attachment';
import{StatementsDiskLogRequest}  from 'app/models/ClientStatement';
@Component({
  selector: 'ms-client-statement-disk-form-dialog',
  templateUrl: './client-statement-disk-form-dialog.component.html',
  styleUrls: ['./client-statement-disk-form-dialog.component.scss']
})
export class ClientStatementDiskFormDialogComponent  extends AppComponentBase   implements OnInit {
 
  title: string;
  errors=[];
  items:any;
  filter=new StatementsDiskLogRequest();
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientStatementDiskFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers:EnumHelpers    ,
     private setupApi :SetupApi,
     private _settlementService:SettlementService   , 
      injector: Injector
    ) {
      super(injector) 

    }

  ngOnInit(): void {
   this.title = 'Disk Await Approval';   ;
   this.getData();

  }
 
  close() {
    this.dialogRef.close(false);
  }
 
 getData(){
  this.filter.Date=new Date();
  this.filter.Status=BalanceStatusEnum.AwaitApprove;
  this._settlementService.GetStatementsDiskLogs(this.filter).subscribe(res=>{
      this.items=res;
  });
 }
 appoveDisk(dataItem){
  this.errors=[];
  this._settlementService. approveClientStatementDisk(dataItem.Id).subscribe(() => {
    this.dialogRef.close(true);
  
     this.toastr.success ("Successfully!");
 },
 err =>{   
   
   this.errors.push(err.error[0]);
   this.toastr.error(err.error[0]);
   } );
 }
 rejectDisk(dataItem){
  this.errors=[];
  this._settlementService. rejectClientStatementDisk(dataItem.Id).subscribe(() => {
    this.dialogRef.close(true);
  
     this.toastr.success ("Successfully!");
 },
 err =>{   
   
   this.errors.push(err.error[0]);
   this.toastr.error(err.error[0]);
   } );
 }
 

 


    
}
