import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GridModule } from '@progress/kendo-angular-grid';   
import { AdminService } from './services/admin.service';
import { RouterModule } from '@angular/router';
 import{adminRoutes} from './admin-routing.module'; 
import { RoleListComponent } from './components/role-list/role-list.component';
import { RoleFormDialogComponent } from './components/role-form-dialog/role-form-dialog.component';
import { MatMenuModule } from '@angular/material/menu'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DirectivesModule } from 'app/core/directive/directives.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserFormDialogComponent } from './components/user-form-dialog/user-form-dialog.component';
import {UserManagementComponent} from './containers/user-management/user-management.component';
import { TranslateModule } from '@ngx-translate/core';
import{UserSessionListComponent} from './components/user-session-list/user-session-list.component'
import { TreeModule } from '@circlon/angular-tree-component';
import { MatTreeModule } from '@angular/material/tree';
import { SharedModule } from 'app/shared/shared.module';
import { UserProfileComponent } from './containers/user-profile/user-profile.component';
import { WidgetComponentModule } from 'app/widget-component/widget-component.module';

@NgModule({
  imports: [
    CommonModule,
     RouterModule.forChild(adminRoutes),
     ReactiveFormsModule, 
     CommonModule,
     FlexLayoutModule,  
     MatInputModule,
     MatFormFieldModule,
     MatCardModule,
     MatButtonModule,
     MatIconModule,
     MatPaginatorModule,
     MatDividerModule,
     MatCheckboxModule,
     MatTableModule,
     MatTabsModule,
     MatChipsModule,
     MatSelectModule,
     TranslateModule, 
       DirectivesModule,
       MatMenuModule,
       MatListModule,
       MatDialogModule  ,
       FormsModule,
       MatTreeModule,
       TreeModule ,
       GridModule ,
       SharedModule   ,  
       MatListModule 
       ,
		WidgetComponentModule
  ],
  declarations: [ 
  
   RoleListComponent,RoleFormDialogComponent, UserListComponent, UserFormDialogComponent,
   UserManagementComponent,UserSessionListComponent,UserProfileComponent
  ], 
  providers: [AdminService],
  entryComponents: []
})
export class AdminModule { }
