
<form [formGroup]="addNewsForm" class="popup-card-width add-new-user order-ticket">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title | translate}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
	
		<div class="gene-card-content">
			<div class="bank-details">

				<div class="mrgn-b-xs" mat-card-widget fxLayout="row wrap" fxLayoutAlign="space-between start"
					fxLayoutGap="5x" *ngIf="statisticsData != null && statisticsData != undefined">
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<mat-form-field class="form-field" appearance="outline" >
								<mat-label> {{ 'Header EN' | translate }} </mat-label>
								   <input matInput placeholder="{{ 'Header EN' | translate }}" formControlName="HeaderEn" >
							 </mat-form-field>
						</div>
					</div>
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<mat-form-field class="form-field" appearance="outline" >
								<mat-label> {{ 'Header Ar' | translate }} </mat-label>
								   <input matInput placeholder="{{ 'Header Ar' | translate }}" formControlName="HeaderAr" >
							 </mat-form-field>
						</div>
					</div>
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<mat-form-field class="form-field" appearance="outline" >
								<mat-label> {{ 'Details EN' | translate }} </mat-label>
								   <input matInput placeholder="{{ 'Details EN' | translate }}" formControlName="DetailsEn" >
							 </mat-form-field>
						</div>
					</div>
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<mat-form-field class="form-field" appearance="outline" >
								<mat-label> {{ 'Details Ar' | translate }} </mat-label>
								   <input matInput placeholder="{{ 'Details Ar' | translate }}" formControlName="DetailsAr" >
							 </mat-form-field>
						</div>
					</div>

					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">
						<div class="button-wrap">
							<button mat-raised-button color="primary"
								(click)="onSubmit()">{{'SUBMIT' | translate}}</button>

							<button mat-raised-button color="primary"
								(click)="close()">{{'CLOSE' | translate}}</button>
					    </div>
						
					</div>
				</div>

			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->

	</mat-dialog-content>

</form>