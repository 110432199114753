import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
import { BrokerDto, CompanyDto } from 'app/models/broker';

@Injectable()
export class BrokerManagementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.brokerUrl}`;

  constructor(private http: HttpClient) {}


  getBrokers(): Observable<[]> {
    
    return this.http.get<[]>(`${this.API_URL}/getActiveBrokers`);
  } 
  getActiveBrokerNotHaveAccount(): Observable<[]> {
    
    return this.http.get<[]>(`${this.API_URL}/getActiveBrokerNotHaveAccount`);
  } 
  getBrokerCompany(id:any): Observable<any> {
    
    return this.http.get<[]>(`${this.API_URL}/getBrokerCompany/`+id);
  } 
  getAllBrokerCompanies(id:any): Observable<any> {
    
    return this.http.get<[]>(`${this.API_URL}/getAllBrokerCompanies/`+id);
  } 
  getMyBrokerCompany(companyId:any, brokerId: any): Observable<any> {
    
    return this.http.get<[]>(`${this.API_URL}/getMyBrokerCompany/` + companyId +`/`+ brokerId );
  } 
   getBroker(id:any): Observable<any> { 
    return this.http.get<BrokerDto>(`${this.API_URL}/getBroker/`+id);
  }

  getCompanyBrokerByCompanyId(id:any): Observable<any> { 
    return this.http.get<BrokerDto>(`${this.API_URL}/getCompanyBrokerByCompanyId/`+id);
  }


  insertBroker(BrokerRequest: any): Observable<boolean> {
 
    return this.http.post<any>(`${this.API_URL}/insertBroker`, 
    BrokerRequest)
      .pipe(tap(data => {}));
 }
 
 updateBroker(BrokerRequest: any ,id :any): Observable<boolean> {

   return this.http.put<any>(`${this.API_URL}/updateBroker/`+id, 
   BrokerRequest)
     .pipe(tap(data => {}));
 }

 deleteBroker(id: any): Observable<boolean> {

   return this.http.delete<any>(`${this.API_URL}/deleteBroker/`+id).pipe(tap(data => {}));
 }


 getBrokerCompanies(BrokerRequestDto :any){
  let queryParameters: string = this.convertObjectToQueryParameters(BrokerRequestDto);
   return this.http.get<any>(`${this.API_URL}/getAllBrokerCompanies`+queryParameters)    ;
 }

 searchBrokers(request: any): Observable<any> {
  let queryParameters: string = this.convertObjectToQueryParameters(request);
  return this.http.get<[]>(`${this.API_URL}/searchBrokers`+queryParameters)
  }

 convertObjectToQueryParameters(request): string {
  let queryParameters: string = "";
  for (let property in request) {
      if (request[property] != null)
          queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
  }
  if (queryParameters == "")
      return "";
  return queryParameters
}
 
}