<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center"
			   *ngIf="ClientStatus.Approve==status" >
				  <mat-card-actions class="mat-pad-none margin-none">
					<a mat-raised-button color="primary" 						
						(click)="AddClients()" 
						title=" {{ 'Add Or View' | translate }}" >
						 {{ 'Add Or View' | translate }}
						 <mat-icon  >search</mat-icon></a>		   
				  </mat-card-actions>
			   </div>
			</div>

			<div fxLayout="row wrap" fxLayoutAlign="space-between center">
				<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
					<div class="mrgn-l-sm mrgn-r-sm">
						<mat-form-field class="width50" floatLabel="never">
						   <mat-label>{{translate('Search')}}</mat-label>
						   <input class="form-control" matInput type="input" placeholder="{{translate('Search')}}" formControlName="Name" (input)="filterOptions.GeneralFilter=$event.target.value">
					   </mat-form-field>
					   <button mat-button color="primary" (click)="onStateChange()"><mat-icon>search</mat-icon></button>
					   <button mat-button color="primary" (click)="refesh()"><mat-icon>clear</mat-icon> </button>

					   <custom-menu style="margin-left: 3px;" [items]="this.dataSources.types" class="width100 form-field" [displayPath]="'name'" [title]="translate('Client Type')" [icon]="'group'" (onChange)="filterOptions.ClientType = $event.id;getList()"></custom-menu>
				   </div>
			   </div> 
	 
		    </div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between center">
				<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
 
				 <!-- <custom-menu [items]="dataSources.companies" class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'Name'" [title]="translate('Trading Companies')"
				 (onChange)="this.filterOptions.CompanyId = $event.Id;getList()"   *ngIf="!isBroker()" [icon]="'grid_on'">
				 </custom-menu>
				 <custom-menu [items]="dataSources.stores" class="width100 form-field" [displayPath]="isRtl()?'StoreNameAr': 'StoreNameEn'" [title]="translate('Warehouse')" (onChange)="this.filterOptions.StoreID = $event.StoreID;getList()" [icon]="'store_mall_directory'"  *ngIf="!isConfirmationScreen && !isStoreKeeper()" >
				 </custom-menu> -->
				 <!-- <custom-menu [items]="dataSources.status" class="width100 form-field" [displayPath]="'name'" [title]="'Status'" [icon]="'signal_cellular_alt'" (onChange)="this.filterOptions.Status = $event.id;getList()" 	 *ngIf="!isConfirmationScreen" [mandatory]="true"></custom-menu>
                   -->

				<mat-form-field class="overview  width100  form-field "appearance="outline" *ngIf="!isBroker()">
				   <mat-label>{{ 'Trading Companies' | translate }}</mat-label>
					<mat-select 
					[(ngModel)]="filterOptions.CompanyId" 
					(selectionChange)="onChangeCompany($event);getList()"   
					name="CompanyId" >  
						<mat-label>{{ 'Trading Companies' | translate }}</mat-label>
						<mat-option>
							<ngx-mat-select-search name="company" 
								placeholderLabel="{{translate('Enter At Least One Character')}}"
								noEntriesFoundLabel="{{translate('No data found')}}" ngModel
								(ngModelChange)="onChangeCompany($event)">
							</ngx-mat-select-search>
						</mat-option>
						<mat-option> {{ 'All' | translate }} </mat-option>
						<mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
							{{isRtl()?company.NameAr:company.Name}}</mat-option>
					</mat-select>
				</mat-form-field>
				
				<mat-form-field style="margin-left: 5px;" class="overview width100 form-field"appearance="outline" *ngIf="!isConfirmationScreen && !isStoreKeeper()">
					<mat-label>{{ 'Warehouse' | translate }}</mat-label>
					 <mat-select 
					 [(ngModel)]="filterOptions.StoreID" 
					 (selectionChange)="onChangeStore($event);getList()"   
					 name="StoreID" >  
						 <mat-label>{{ 'Warehouse' | translate }}</mat-label>
						 <mat-option>
							 <ngx-mat-select-search name="store" 
								 placeholderLabel="{{translate('Enter At Least One Character')}}"
								 noEntriesFoundLabel="{{translate('No data found')}}" ngModel
								 (ngModelChange)="onChangeStore($event)">
							 </ngx-mat-select-search>
						 </mat-option>
						 <mat-option> {{ 'All' | translate }} </mat-option>
						 <mat-option  *ngFor="let store of  filteredStores" [value]="store.StoreID">
							 {{isRtl()?store.StoreNameAr:store.StoreNameEn}}</mat-option>
					 </mat-select>
				 </mat-form-field>

				</div> 
		 </div>
		 <mat-divider></mat-divider>

		  <br/>
		  <div fxLayout="row wrap" fxLayoutAlign="start center"   >
			 <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
				 <statistics-card [color]="'#19a978'" [title]="translate('Total')" [val]="statistics.Clients"></statistics-card>
			 </div>
 
			 <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
				 <statistics-card [color]="'#9d9b99'" [title]="translate('Individual')" [val]="statistics.Individual"></statistics-card>
			 </div>
 
			 <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
				 <statistics-card [color]="'lightsalmon'" [title]="translate('Corporate')" [val]="statistics.Corporate"></statistics-card>
			 </div>
			 
			 <br/>
		 </div>
		 </div>
		<div  >
			<br/>
	<kendo-grid   #grid
      [data]="view | async"
	  [filterable]="false" 
	  [resizable]="true"
	  [reorderable]="true"
	  filterable="menu"  
	  [sortable]="true" 
	  [kendoGridSelectBy]="selectBy"
	  [columnMenu]="true"
    class="market-kendo-grid"
	[rowClass]="rowCallback"

	[pageSize]="state.take"
	[skip]="state.skip"
	[sort]="state.sort"
 
	[pageable]="true"
 
	(dataStateChange)="dataStateChange($event)" > 
				   <kendo-grid-column   title="{{ 'ID' | translate }}  " 
				   [class]="{ codeColumn: true }"
				   [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					 <span> {{(rowIndex+1)}}</span>	
					</ng-template>
				   </kendo-grid-column>

				   <kendo-grid-column field="NameAr" 
				   title="{{ 'Client Name / Address' | translate }} "
				    headerClass="grid-list-column-header" width="250" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<span class="client-name">{{dataItem.NameAr }}</span>
						<br/>
						<span class="client-name">{{dataItem.Address }}</span>
					</ng-template>

					<ng-template kendoGridHeaderTemplate   let-column>
					  {{ 'Client Name'| translate }} 
					  <br/>
					  {{ 'Address'| translate }} 
				 
					</ng-template>

				</kendo-grid-column>

				  
				<kendo-grid-column field="IdentityTypeText"  headerClass="grid-list-column-header" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<span >	{{dataItem.ClientTypeTxt | translate }}</span>
						<br/>
						<span >{{dataItem.ExpiryDate| date: 'dd/MM/yyyy' }}</span>
					</ng-template>
					<ng-template kendoGridHeaderTemplate        let-column>
						{{ 'Client Type'| translate }} 
						<br/>
						{{ 'ExpiryDate'| translate }} 
				   
					  </ng-template>
				</kendo-grid-column>


 
				  <kendo-grid-column field="IdentityTypeText"  headerClass="grid-list-column-header" width="100" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<span >	{{dataItem.IdentityType.IdentityTypeEn | translate }}</span>
						<br/>
						<span >{{dataItem.IdentityTypeText }}</span>
					</ng-template>
					<ng-template kendoGridHeaderTemplate        let-column>
						{{ 'ID Type'| translate }} 
						<br/>
						{{ 'ID Number'| translate }} 
				   
					  </ng-template>
				</kendo-grid-column>
				
				  <kendo-grid-column field="ExpiryDate" title="{{ 'ExpiryDate' | translate }} " headerClass="grid-list-column-header" width="120" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.GenderTxt  }}
						<br/>
						{{dataItem.DateofBirth  | date: 'dd/MM/yyyy'}}
					</ng-template>
					<ng-template kendoGridHeaderTemplate        let-column>
				 
						{{ 'Gender'| translate }} 
						<br/>
						{{ 'DOB'| translate }} 
				   
					  </ng-template>

				</kendo-grid-column>

				  <kendo-grid-column field="Email" title="{{ 'Email' | translate }} " headerClass="grid-list-column-header" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<span >	{{dataItem.Email| translate }}</span>
						<br/>
						<span >{{dataItem.Telephone| translate }}</span>
					</ng-template>
					<ng-template kendoGridHeaderTemplate        let-column>
						{{ 'Email'| translate }} 
						<br/>
						{{ 'Telephone'| translate }} 
				   
					  </ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.Status==_StatusEnum.Active" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.Status!=_StatusEnum.Active" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="NameAr" 
				  title="{{ 'Comments' | translate }} "
				   headerClass="grid-list-column-header" width="110" 
				   *ngIf="ClientStatus.Approve!=status">
				   <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
		  
					   <span *ngIf="dataItem.CountComments>0"    (click)="getCommentsDialog(dataItem)">
						<mat-icon class="material-icons icon-color count-comments"
						 matBadge="{{dataItem.CountComments}}" matBadgeColor="warn" >mode_comment</mat-icon>
						</span>
						<span *ngIf="dataItem.CountComments==0" (click)="getCommentsDialog(dataItem)" >
							<mat-icon class="material-icons icon-color count-comments"
							 >mode_comment</mat-icon>
							</span>
				   </ng-template>
			   </kendo-grid-column>

				  <kendo-grid-column   title=" " 
				  [columnMenu]="false"
				   width="80" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
					   aria-label="Open anchor menu"
					     >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item   
							[routerLink]="['/clients/Edit/'+ dataItem.Id +'/'+ status]"  >
							 {{ 'View' | translate }} 
						  </a>

						  <a mat-menu-item   
						  *ngIf="ClientStatus.Approve==status  " 
						       (click)="changePropertiesStatus(dataItem)"   >
						       {{ 'Change Status' | translate }} 
						  </a>
						  
							<a mat-menu-item     (click)="Approve(dataItem)" 
							*ngIf="ClientStatus.AwaitApprove==status && auth.isGranted(_permissions.ApproveClients)" >
							    {{ 'Approve' | translate }}
						   </a>
						   <a mat-menu-item   (click)="Reject(dataItem)" 
						   *ngIf="ClientStatus.AwaitApprove==status && auth.isGranted(_permissions.RejectClients)" >
							  {{ 'Reject' | translate }}
						  </a>
							<!-- <a mat-menu-item   
							  *ngIf="auth.isGranted(_permissions.DeleteClients)  && !isConfirmationScreen" (click)="suspendedClient(dataItem)"   >
							   <span *ngIf='dataItem.IsActive'>	{{ 'SuspendedBtn' | translate }} </span>
							   <span *ngIf='!dataItem.IsActive'>	{{ 'Active' | translate }} </span>
							
						     </a>  -->

							<!-- <a mat-menu-item     *ngIf="auth.isGranted(_permissions.DeleteClients)" (click)="deleteClient(dataItem)"   >
							    {{ 'Delete' | translate }} 
						     </a> -->
						 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>