<div class="gene-card-title">

  <input type="file" #fileInput style="display: none;" (change)="uploadExcel($event)" accept=".xlsx">
  
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column">
       <h5 class="mrgn-b-none card-grid-title">{{'Auctions' | translate}}</h5>
    </div>
    <span fxFlex></span>
    <button mat-icon-button [matMenuTriggerFor]="anchorMenu"  class="card-grid-title-button"
    *ngIf="auth.isGranted(_permissions.AddAuctions)"
    > <mat-icon>more_horiz</mat-icon> </button>
     <mat-menu #anchorMenu="matMenu"  >
       <a mat-menu-item (click)="createAuctionOrder()" >{{ 'NEW-AUCTION' | translate }}</a>
       <a mat-menu-item #fileLink (click)="fileInput.click()" >{{ 'UPLOAD-AUCTION-SHEET' | translate }}</a>
     </mat-menu>
 </div>
 <mat-divider></mat-divider> 
</div> 
<div  class=" ">

  <kendo-grid  [kendoGridBinding]="items"
   class="market-kendo-grid"
   [resizable]="true"
   [reorderable]="true" 
   [sortable]="true" 
   filterable="menu"
   [columnMenu]="true" 
   (cellClick)="onCellClick($event)"    
   [kendoGridSelectBy]="selectBy"
  > 
  <!-- <kendo-grid-column  field="Id" title=""  
   width="25"  class="" [columnMenu]="false" >
   <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
    <span    class=" cell-bold">
      {{ dataItem. Id}}
      </span>
  </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-column    [columnMenu]="false"   width="50">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
      <button mat-icon-button          
      title="add order"
      (click)="createOrder(dataItem)" 
         *ngIf="IsOpen(dataItem)"
         [ngClass]=  "{'sell': dataItem.OrderType===2, 'buy' : dataItem.OrderType===1 }">    
        <mat-icon class="favouritesIcon">playlist_add</mat-icon>
      </button> 
      </ng-template>
  </kendo-grid-column>

  
  <kendo-grid-column field="Id" title="{{ 'Id' | translate }}"  width="80" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span    [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }" >  
      {{ dataItem.Id }}
      </span> 
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column field="SymbolCode"  filterable="menu"
  [sortable]="true"
  [columnMenu]="true" title="{{ 'Symbol' | translate }}" width="160" >
   <ng-template kendoGridCellTemplate let-dataItem  >
    <span class="market-watch-symbol-c">{{dataItem.SymbolCode}} </span> 
    <br/>
    <span class="market-watch-symbol-n" *ngIf="isRtl()"> {{dataItem. SymbolNameAr}}   </span>
    <span  class="market-watch-symbol-n" *ngIf="!isRtl()"> {{dataItem.SymbolNameEn}}   </span>
    
     <!-- <a href="" class=""> <span  matPrefix class="fa fa-btc" aria-hidden="true"></span> {{dataItem.SymbolCode}}  </a> -->

   </ng-template>
 </kendo-grid-column> 

 <kendo-grid-column field="SymbolCode"  filterable="menu"
 [sortable]="true"
 [columnMenu]="true" title="{{ 'News' | translate }}" width="160" >
  <ng-template kendoGridCellTemplate let-dataItem  >
    <a mat-menu-item   (click)="showNews(dataItem)"> {{ 'Show News' | translate }} </a> 
  </ng-template>
</kendo-grid-column> 

 <kendo-grid-column field="Note"  filterable="menu"
 [sortable]="true"
 [columnMenu]="true" title="{{ 'Note Number' | translate }}" width="160" >
  <ng-template kendoGridCellTemplate let-dataItem  >
   <span class="market-watch-symbol-c">{{dataItem.Note}} </span> 
   <br/> 
    <!-- <a href="" class=""> <span  matPrefix class="fa fa-btc" aria-hidden="true"></span> {{dataItem.SymbolCode}}  </a> -->

  </ng-template>
</kendo-grid-column> 


  <kendo-grid-column  field="MarketCode"   title="{{ 'Market' | translate }}" width="180" [columnMenu]="true"  [sortable]="true" >
    <ng-template kendoGridCellTemplate let-dataItem  > 
      <span  class="market-session-icon" 
      [ngClass]=
    "{'session-open-btn': dataItem.MarketStatus===1, 'session-preopen-btn' : dataItem.MarketStatus===2 , 'session-close-btn' : dataItem.MarketStatus===3}"
      >{{ dataItem. MarketCode}}</span>  
      <!-- <span  *ngIf="dataItem.Status==this.AuctionstatusEnum.Open"  class="session-open cell-bold">
        {{'Open' | translate}}   

    </span>
    <span  *ngIf="dataItem.Status==this.AuctionstatusEnum.Pending" class="session-preopen cell-bold">
    {{'Pending' | translate}}   
     
     </span>
     <span   *ngIf="dataItem.Status==this.AuctionstatusEnum.Adjustment" class="session-close cell-bold">
      {{'Adjustment' | translate}} 	 

      </span> -->
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Currency" title="{{ 'Currency' | translate }}"  width="150" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
      <span  *ngIf="isRtl()"> {{dataItem.CurrencyNameAr}}  </span>
      <span  *ngIf="!isRtl()"  > {{dataItem.CurrencyNameEn}}  </span>
 
    </span>
    </ng-template>
    </kendo-grid-column> 
   <!-- <kendo-grid-column  field="Remaining" title="{{'Remaining'| translate }}"   width="100" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem> 
         <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
          <span class="timerCol"> {{setTimer(dataItem)}}</span> 
         
         </span>
  </ng-template>
  </kendo-grid-column> -->

  <!-- <kendo-grid-column  field="DurationTime" title="{{ 'Duration Time' | translate }}"   width="120">
    <ng-template kendoGridCellTemplate let-dataItem> 
         <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
           {{dataItem.DurationTime}}
         </span>
  </ng-template>
  </kendo-grid-column> -->
  
  <kendo-grid-column field="Price" title="{{ 'Price' | translate }}"  width="100" [columnMenu]="true" [hidden]="false"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span    *ngIf="_AuctionHelper. IsAllowShowPrice (isEMX() ,dataItem,this.companyID, dataItem.CompanyID)"  [ngClass]=  "{'buy': dataItem.OrderType===1, 
      'sell' : dataItem.OrderType===2 }" >
      
      {{dataItem.Price | number }}
      </span> 
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column field="Type" title="{{ 'Type' | translate }}"  width="140" [columnMenu]="true"  [sortable]="true">
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
    <span  *ngIf="isRtl()"> {{dataItem.AuctionTypeNameAr}}  </span>
    <span  *ngIf="!isRtl()"  > {{dataItem.AuctionTypeNameEn}}  </span>
  </span>
  </ng-template>
  </kendo-grid-column> 

  <kendo-grid-column field="Status" title="{{ 'Status' | translate }}"  width="140" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
      <span> {{_helper.getNamesByValue(AuctionstatusEnum,dataItem.Status ) | translate }} </span>
    </span>
    </ng-template>
  </kendo-grid-column> 
  
  <kendo-grid-column field="Client" title="{{ 'Client' | translate }}" 
   width="150" [columnMenu]="true"  [sortable]="true"
   >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">  
      <span  *ngIf="_AuctionHelper.IsAllowClient (isEMX() ,dataItem,this.companyID, dataItem.CompanyID)" 
      [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"  > {{dataItem.ClientNameAr}}  </span>
     
    </ng-template>
  </kendo-grid-column> 

  <kendo-grid-column field="Company" title="{{ 'Firm' | translate }}" 
  width="150" [columnMenu]="true"  [sortable]="true"
  >
   <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">  
     <span  *ngIf="_AuctionHelper.IsAllowCompany (isEMX() ,dataItem,this.companyID, dataItem.CompanyID)" 
     [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"  > {{dataItem.CompanyNameAr}}  </span>
    
   </ng-template>
 </kendo-grid-column> 

  <kendo-grid-column field="Qty" title="{{ 'Qty' | translate }}"  width="80" [columnMenu]="true" [hidden]="false"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span   *ngIf="_AuctionHelper. IsAllowShowQty (isEMX() ,dataItem,this.companyID, dataItem.CompanyID)" [ngClass]=  "{'buy': dataItem.OrderType===1, 
      'sell' : dataItem.OrderType===2 }" > 
      {{dataItem.Qty | number }}
      </span> 
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column  field="OpenDate" title="{{ 'Open Date' | translate }}"   width="100" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem> 
         <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
           {{dataItem.OpenDate | date: 'dd/MM/yyyy'}}
         </span>
  </ng-template>
  </kendo-grid-column>

  <kendo-grid-column  field="CloseDate" title="{{ 'Close Date' | translate }}"  width="100" [columnMenu]="true"  [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem> 
      <span    [ngClass]=  "{'buy': dataItem.OrderType===1, 
      'sell' : dataItem.OrderType===2 }" >
        {{dataItem.CloseDate | date: 'dd/MM/yyyy'}}
      </span>   
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column  field="OpenTime" title="{{ 'Open Time' | translate }}"   width="100" [columnMenu]="true" [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem> 
        <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
          {{dataItem.OpenTime}}
        </span>
  </ng-template>
  </kendo-grid-column>

  <kendo-grid-column  field="CloseTime" title="{{ 'Close Time' | translate }}"   width="100" [columnMenu]="true" [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem> 
        <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"   >
          {{dataItem.CloseTime}}
        </span>
  </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="CapQty" title="{{ 'Cap Qty' | translate }}"  width="80" [columnMenu]="true" [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span  
      *ngIf="_AuctionHelper.IsAllowCapping(isEMX() ,dataItem,this.companyID, dataItem.CompanyID)"
      [ngClass]=  "{'buy': dataItem.OrderType===1,  'sell' : dataItem.OrderType===2 }" 
     >
      {{dataItem.CapQty | number }}
      </span> 
    </ng-template>
  </kendo-grid-column>





  <kendo-grid-column field="MatchingType" title="{{ 'Matching Type' | translate }}"  width="100" [columnMenu]="true" [sortable]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span  *ngIf="_AuctionHelper.IsAllowMatchingType(isEMX() ,dataItem,this.companyID, dataItem.CompanyID)"  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }" >   
      {{_helper.getNamesByValue(_MatchingType,dataItem.MatchingType ) | translate }}
      </span> 
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="Note" title="{{ 'Description' | translate }}"  width="100" 
  [hidden]="true"
  [columnMenu]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span    [ngClass]=  "{'buy': dataItem.OrderType===1, 
      'sell' : dataItem.OrderType===2 }" >     
      {{dataItem.Note}}
      </span> 
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column   title=""  width="60" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
      <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
       aria-label="Open anchor menu"
       *ngIf="auth.isGranted(_permissions.exeCuteAuctions) || 
              auth.isGranted(_permissions.EditAuctions) ||
              auth.isGranted(_permissions.AdjustmentAuctions) ||
         
              auth.isGranted(_permissions.DeleteAuctions)"
      [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }">
        <mat-icon>menu</mat-icon>
      </button>
         <mat-menu #anchorMenu="matMenu"
         > 


            <a mat-menu-item   (click)="addNews(dataItem)"  
              *ngIf="isEMX()" >
                {{ 'Add News' | translate }}
            </a> 

            <a mat-menu-item   (click)="adjustmentAuction(dataItem)"  
               *ngIf="auth.isGranted(_permissions.AdjustmentAuctions) 
               && dataItem.Status==this.AuctionstatusEnum.Open " >
                {{ 'Disallow Adjustment' | translate }}
            </a> 
            <a mat-menu-item   (click)="unadjustmentAuction(dataItem)"  
               *ngIf="auth.isGranted(_permissions.AdjustmentAuctions) 
               && dataItem.Status==this.AuctionstatusEnum.Adjustment " >
                 {{ 'Allow Adjustment' | translate }}
            </a> 
            <a mat-menu-item   (click)="getAttachments(dataItem)" 
               *ngIf="auth.isGranted(_permissions.UpdateOrderAttachment) &&isEMX()
               "  >
             {{ 'Attachments' | translate }} 
            </a>
            <a mat-menu-item   (click)="GetAuctionStatistics(dataItem)"   
            *ngIf="auth.isGranted(_permissions.GetAuctionStatistics)" >
            {{ 'Statistics' | translate }}
            </a>   

            <a mat-menu-item   
            (click)="pendingTradesAuction(dataItem)" 
            *ngIf=" isAllowPreTreade(dataItem)
            "  >
              {{ 'Pre Execute' | translate }} 
           </a>
               <a mat-menu-item   (click)="matchAuction(dataItem)"  
                *ngIf="auth.isGranted(_permissions.exeCuteAuctions) && 
                (dataItem.Status==this.AuctionstatusEnum.Open
                || dataItem.Status==this.AuctionstatusEnum.Adjustment
                 || dataItem.Status==this.AuctionstatusEnum.PendingExecution
                 || dataItem.Status!=this.AuctionstatusEnum.PreExecute) " >
               {{ 'Execute' | translate }}
             </a> 
             <a mat-menu-item   (click)="editAuctionOrder(dataItem)" 
             
             *ngIf="auth.isGranted(_permissions.EditAuctions) 
             &&(dataItem.Status!=this.AuctionstatusEnum.DeleteAuctions 
             && dataItem.Status!=this.AuctionstatusEnum.Executed) "  >
             {{ 'Edit' | translate }}
            </a> 
            <a mat-menu-item   (click)="Delete(dataItem)"
      
            *ngIf="auth.isGranted(_permissions.DeleteAuctions)&&dataItem.Status!=this.AuctionstatusEnum.Executed"  >
            {{ 'Delete' | translate }}
            </a>

         </mat-menu>   
        </ng-template>
   </kendo-grid-column>

  </kendo-grid>
  </div> 
 
  