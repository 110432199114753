<form [formGroup]="addNewOrderForm" class="popup-card-width add-new-user order-ticket">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<h4 class="mrgn-b-none">{{title}}  
					<span  *ngIf="order.OrderType == 1">  {{this.translate('TranactionBuy')}}  </span>
					<span   *ngIf="order.OrderType == 2"> 		{{this.translate('TranactionSell')}}  </span>
					
					<span  *ngIf="isRtl()"> - {{order. SymbolNameAr}}   </span>
					<span   *ngIf="!isRtl()"> - {{order.SymbolNameEn}}   </span>
				
					<span   *ngIf="orderId>0">	#  {{orderId}}   </span>
				</h4>

			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="">
					<div class="padding-input-form">
 
						<div class="padding-input-form ">
							<div class=' width100 col-md-6 col-xm-12'>

								<mat-button-toggle-group [disabled]='isUpdate  '
									name="fontStyle" (change)="onChangeOrderType($event)" [(ngModel)]="order.OrderType"
									name="OrderType" aria-label="Font Style" formControlName="OrderType"
									class="form-control">
									<mat-button-toggle class="col-md-6" style="margin: 1px;" value="1"
										[class.buy]="order.OrderType == 1" class="form-control">
										{{this.translate('TranactionBuy')}}
									</mat-button-toggle>
									<mat-button-toggle value="2" class="form-control"
										[class.sell]="order.OrderType == 2">
										{{this.translate('TranactionSell')}}</mat-button-toggle>

								</mat-button-toggle-group>

							</div>
						</div>
						<div class="padding-input-form ">
							<div style="display: flex;">
								<mat-form-field appearance="outline" class="width100" style="font-size: small;margin-right: 10px;">
									<mat-label>{{ 'Symbol' | translate }}</mat-label>
									<mat-select [(ngModel)]="order.SymbolCode" name="SymbolCode"
										(selectionChange)="onChangeSymbol($event)" formControlName="SymbolCode"
										[(ngModel)]="order.SymbolCode"
										[disabled]='isUpdate  ||isDisableSymbol '
										[placeholder]="'Symbol' | translate ">
										<mat-label>{{ 'Symbol' | translate }}</mat-label>
										<mat-option>
											<ngx-mat-select-search name="symbolNameseaRCHbs"
												formControlName="symbolNameseaRCHbs"
												placeholderLabel="{{translate('Enter At Least One Chars To Search')}}"
												noEntriesFoundLabel="{{translate('No data found')}}" ngModel
												(ngModelChange)="filterSymboles($event)">
											</ngx-mat-select-search>
										</mat-option>
										<mat-option *ngFor="let symbol of  symbols" [value]="symbol.SymbolCode">
											{{symbol.SymbolCode}} </mat-option>
									</mat-select>
								</mat-form-field>
								<label style="margin-top: 5px;">
									{{selectedSymbolUnit}}
								</label>
							</div>
						</div>
						<div class="padding-input-form ">
							<div class=''>
								<mat-form-field appearance="outline" class="width100" style="font-size: small;">
									<mat-label>{{ 'Warehouse' | translate }} </mat-label>
									<mat-select [disabled]='isUpdate '
										formControlName="CustodianID" [(ngModel)]="order.CustodianID"
										name="CustodianID">
										<mat-option  [value]=null>--{{ 'none' | translate }}--</mat-option>  
										<mat-option *ngFor="let store of stores" [value]="store.StoreID">
											 
											<span	class="font-normal" *ngIf="isRtl()">{{store.StoreNameAr}}</span>
											<span	class="font-normal" *ngIf="!isRtl()">{{store.StoreNameEn}}</span>
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>

						<div class="padding-input-form ">
							<div class='width100 col-md-6 col-xm-12'>
								<mat-form-field appearance="outline" style="font-size: small;">
									<mat-label>{{ 'Client' | translate }}</mat-label>
									<mat-select [disabled]='isUpdate' required formControlName="ClientID"
										name="ClientID" [(ngModel)]="order.ClientID"
										[placeholder]="'Client' | translate ">
										<mat-label>{{ 'Client' | translate }}</mat-label>
										<mat-option>
											<ngx-mat-select-search name="itemNameseaRCHb"
												formControlName="itemNameseaRCHb"
												placeholderLabel="{{translate('Enter At Least One Chars To Search')}}"
												noEntriesFoundLabel="{{translate('No data found')}}" ngModel
												(ngModelChange)="filterclients($event)">
											</ngx-mat-select-search>
										</mat-option>
										<mat-option *ngFor="let client of Clients" [value]="client.Id">
											{{isRtl()?client.NameAr:client.NameEn}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<div class="padding-input-form ">
							<div class=' width100 col-md-6 col-xm-12'>

								<mat-button-toggle-group name="fontStyle" (change)="onChangeMarketPrice($event)"
									name="isMarketPrice" [(ngModel)]="isMarketPrice" aria-label="Font Style"
									formControlName="isMarketPrice" class="form-control">
									<mat-button-toggle class="col-md-6" [value]="0" class="form-control">
										{{ 'Price Limit' | translate }} 
									</mat-button-toggle>
									<mat-button-toggle [value]="1" class="form-control">
										{{ 'Market Price' | translate }} </mat-button-toggle>

								</mat-button-toggle-group>

							</div>

						</div>
						<div class="padding-input-form ">
							<mat-form-field class="width33 form-field " appearance="outline">
								<mat-label>{{ 'Qty' | translate }} </mat-label>
								<input class="form-control"   
								matInput type="number" placeholder="Qty" min="1"
									formControlName="OrderQty" [(ngModel)]="order.OrderQty" name="OrderQty">
							</mat-form-field>
							<mat-form-field class="width33 form-field " appearance="outline">
								<mat-label>{{ 'Price' | translate }} </mat-label>
								<input class="form-control" matInput type="number" placeholder="Price"
									formControlName="OrderPrice" [readonly]="isMarketPrice==1" min="0"
									[(ngModel)]="order.OrderPrice" name="OrderPrice">
							</mat-form-field>

							<mat-form-field class="width33 form-field " appearance="outline"
							>
							<mat-label>{{ 'Price Limit' | translate }}</mat-label>
							<input class="priceLimit form-control" matInput type="text" placeholder="Price"
								formControlName="PriceLimit" [readonly]="true" [(ngModel)]="PriceLimit"
								name="PriceLimit">

						</mat-form-field>
						<mat-form-field class="width33 form-field " appearance="outline"
						
						>
							<mat-label>{{ 'Minimum Fill' | translate }} </mat-label>
							<input class="form-control" matInput type="number" placeholder="Minimum Fill"
								formControlName="MinimumFill" min="0" [(ngModel)]="order.MinimumFill">

						</mat-form-field>

						<mat-form-field class="width33 form-field " appearance="outline"
							>
							<mat-label>{{ 'Order Validity' | translate }} </mat-label>

							<mat-select formControlName="OrderValidity" [(ngModel)]="order.OrderValidity"
								name="OrderValidity">
								<mat-option *ngFor="let orderValidity of orderValidities"
									[value]="orderValidity.id">
									{{orderValidity.name | translate}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="width33 form-field " appearance="outline"
							*ngIf='order.OrderValidity!=orderValidityDay '>
							<mat-label>{{ 'Date' | translate }} </mat-label>
  
								<input matInput placeholder="Choose a date"
								formControlName="GoodTillDate" [matDatepicker]="picker3" 
								[(ngModel)]="order.GoodTillDate"
								name="GoodTillDate">
								<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
								<mat-datepicker #picker3></mat-datepicker>
							<mat-datepicker #picker3></mat-datepicker>

						</mat-form-field>

							 
				 



						</div>

					</div>
					<mat-divider></mat-divider>
					<br>

					<div class="padding-input-form ">
						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Market' | translate }}</mat-label>
							<mat-select (selectionChange)="onChangeMarket($event)" [disabled]='true'
								formControlName="MarketID" [(ngModel)]="order.MarketID" name="MarketID">
								<mat-option *ngFor="let market of markets" [value]="market.MarketId">
									<span	class="font-normal" *ngIf="isRtl()">{{market.MarketNameEn}}</span>
									<span	class="font-normal" *ngIf="!isRtl()">{{market.MarketNameAr}}</span>
									 </mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Date' | translate }}</mat-label>
							<input matInput placeholder="Choose a date" readonly [disabled]='isUpdate'
								formControlName="OrderDate" [matDatepicker]="picker2" 
								[(ngModel)]="order.OrderDate"
								name="OrderDate">
							<!-- [matDatepicker]="picker2"  -->
							<!-- <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> -->
							<mat-datepicker #picker2></mat-datepicker>
						</mat-form-field>


					</div>
  
				</div>
			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none">
		<div *ngFor="let error of errors">
			<label class="error-label">{{error}}</label>
			<br />
		</div>
		<button (click)="onFormSubmit()" [disabled]="!addNewOrderForm.valid" 
		 mat-raised-button color="warn" *ngIf="!isViewOnly">{{ 'SEND' | translate }}
		</button>
		<button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>

	</mat-dialog-actions>
</form>