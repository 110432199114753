import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { Helpers } from 'app/helper/helpers';
import { RoleDto, UseRoleDto } from 'app/models/role';
import { UserLoginDto } from 'app/models/user';
import { ToastrService } from 'ngx-toastr';
import { AppConsts } from '../../../core/config';

@Component({
  selector: 'ms-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.scss']
})
export class UserFormDialogComponent implements OnInit {

  user:UserLoginDto =new UserLoginDto();
  roles:RoleDto[] ;
  userRole:UseRoleDto=new UseRoleDto();
  rolesName:string[]=[];
  randamPassword:any;
  errors:any;
  title:string;

  addNewUserForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<UserFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,private adminService :AdminService 
    ,private toastr : ToastrService
    , private _helpers:Helpers) {
      this.randamPassword=_helpers.generatePassword(12);  

    if (data.user != null) {
      this.user=data.user;
     this.getUserRoles(this.user.id);
      this.title="Update User  - "+ this.user.email;
    }else{
      this.title="Add New User"
    //  this.company.Code=  this.generateCode(this.data.count);
    }
  }

  ngOnInit(): void {
    this.  getRoles();
    this.addNewUserForm = new FormGroup({  
      Name:new FormControl({ value: '' }, Validators.compose([Validators.required])),
      ipAddress: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      rolesName: new FormControl({ value: '' } ),
      phoneNumber: new FormControl({ value: '' }, Validators.compose([ Validators.pattern("[0-9]{8,15}"),  Validators.maxLength(20)])),
      email:  new FormControl({ value: '' }, Validators.compose([
        Validators.maxLength(50),Validators.email ,Validators.required])),
 
        userName:new FormControl({ value: '' }, Validators.compose([Validators.required])),
 
    });
  }

  getRoles(){
    this.adminService.getRoles().subscribe(res=>{
       this.roles=res;
  
    });
  }

  getUserRoles(id: any){

    this.rolesName=[];
    this.adminService.GetRoleByUserId(id).subscribe(res=>{
       res.forEach(element => {
         this.rolesName.push(element);
       });
  
    });
  }
  close() {
    this.dialogRef.close(false);
  }
      // onFormSubmit method is submit a add new user form.
 onFormSubmit( ) {
  //
 
  this.user.password=  this.randamPassword;
  this.user.confirmPassword=  this.randamPassword;
  this.user.clientCallbackUrl=`${AppConsts.frontEndUrl}/account/confirm`

  this.errors=[];
  if (this.user.id!=null) {  
      this.adminService.updateUser(this.user ,this.user.id) . subscribe(res => { 
          
      this.userRole.userId=this.user.id; 
      this.userRole.rolesName=this.rolesName;
      this.dialogRef.close(this.addNewUserForm.value);
       
      this.toastr.success ("Successfully!");
        this.adminService.addUserRoles(this.userRole).subscribe(userRole=>{

        });
       // 
  
      },
      err =>{   
        
        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);
        } ); 
    
  } else {

    this.adminService.insertUserWithRole(this.user  ).subscribe(res => {
  
      this.userRole.userId=res.id; 
      this.userRole.rolesName=this.rolesName;
      this.dialogRef.close(this.addNewUserForm.value);
     
      this.toastr.success ("Successfully!");
      this.adminService.addUserRoles(this.userRole).subscribe(userRole=>{

      });
     // 

    },
    err =>{   
      
      this.errors.push(err.error[0]);
      this.toastr.error(err.error[0]);
      } ); 
  }
 
}



}
