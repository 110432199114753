<form [formGroup]="addNewBrokerForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title| translate }}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

               <div class="padding-input-form">

                  <mat-form-field class="width50 form-field "
                     appearance="outline" *ngIf="!disableCompany" >
                    <mat-label>{{ 'Company' | translate }}</mat-label>
                    <mat-select 
                    [(ngModel)]="brokerCompany.CompanyId" 
                    (selectionChange)="onChangeCompany($event)"   
                    name="CompanyId"
                    [disabled]='isUpdate'  
                    formControlName="CompanyId"  >  
                       <mat-label>{{ 'Company' | translate }}</mat-label>
                       <mat-option>
                          <ngx-mat-select-search name="company" formControlName="companyName"
                             placeholderLabel="{{translate('Enter At Least One Character')}}"
                             noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                             (ngModelChange)="onChangeCompany($event)">
                          </ngx-mat-select-search>
                       </mat-option>
                       <mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
                          {{isRtl()?company.NameAr:company.Name}}</mat-option>
                    </mat-select>
                 </mat-form-field> 

                        <!-- <mat-form-field class="width50 form-field " appearance="outline"
                        *ngIf="!disableCompany"     >
                            <mat-label>{{ 'Company' | translate }} </mat-label>
                            <mat-select  [(ngModel)]="brokerCompany.CompanyId"  
                            name="CompanyId"
                                 formControlName="CompanyId" >  
                                 
                                 <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                 {{company.Name}}</mat-option>
                            </mat-select> 
                         </mat-form-field> -->


                         <mat-form-field class="width50 form-field " appearance="outline"
                         *ngIf="!disableBroker">
                           <mat-label>{{ 'User' | translate }} </mat-label>
                           <mat-select  [(ngModel)]="brokerCompany.BrokerId"  name="BrokerId"
                                formControlName="BrokerId"  >  
                                
                                <mat-option  *ngFor="let broker of  brokers" [value]="broker.Id">
                                {{broker.Name}}</mat-option>
                           </mat-select> 
                        </mat-form-field>
    
                         
 
				  <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{ 'Email' | translate }}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="Email" 
                           formControlName="Email"  
                        
                           [(ngModel)]="brokerCompany.Email"  > 
                       </mat-form-field>

                       <mat-form-field class="width50 form-field " appearance="outline">
                         <mat-label>{{ 'Username' | translate }}</mat-label>
                         <input class="form-control" matInput type="input" placeholder="UserName" 
                            formControlName="UserName"  
                         
                            
                            [(ngModel)]="brokerCompany.UserName"  > 
                        </mat-form-field>

                       <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{ 'IpAddress' | translate }}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="IpAddress" 
                           formControlName="IpAddress"  
                 
                       
                           [(ngModel)]="brokerCompany.IpAddress"  > 
                       </mat-form-field>
                        

                       <!-- <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{ 'Status' | translate }}</mat-label>
                        <mat-select  [(ngModel)]="brokerCompany.IsActive"  name="IsActive"
                             formControlName="IsActive"  
                            > 
                            <mat-option  *ngFor="let status of  statusLst" [value]="status.id">
                             {{status.name}}</mat-option>
                        </mat-select> 
                      </mat-form-field> -->
 
                      <mat-form-field class="width50 form-field " appearance="outline">
                         <mat-label>{{ 'Roles' | translate }}</mat-label>
                         <mat-select  [(ngModel)]="rolesName"  name="rolesName"
                              formControlName="rolesName" 
                              > 
                             <mat-option  *ngFor="let role of  roles" [value]="role.name">
                              {{role.name}}</mat-option>
                         </mat-select> 
                       </mat-form-field>
 
                       
 

					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
  	<button   (click)="onFormSubmit()" type="submit"  
     *ngIf="auth.isGranted(_permissions.EditBrokers)"  [disabled]="!(addNewBrokerForm.valid )&& (isClick)"    mat-raised-button color="warn">{{ 'SUBMIT' | translate }} </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>
	
	</mat-dialog-actions>
</form>