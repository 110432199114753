 
<div  class=" ">
  <kendo-grid  #grid  [kendoGridBinding]="items" 
  
  [ngClass]=
  "{'market-kendo-grid': auth.isGranted(_permissions.GetOrders), 'market-kendo-grid-full' : !auth.isGranted(_permissions.GetOrders)  }"
  [pageable]="false"
  [pageSize]="20"
  [resizable]="true"
  [reorderable]="true"

  filterable="menu"
  [sortable]="true"
  [columnMenu]="true"

   
    (dataStateChange)="onDataStateChange()"
  (cellClick)="onCellClick($event)"     [kendoGridSelectBy]="selectBy"
  >  
 
  
<kendo-grid-column   title=""  width="30" [columnMenu]="false">
    
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
 
    <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" 
     >
      <mat-icon>more_vert</mat-icon>
    </button>
       <mat-menu #anchorMenu="matMenu">


         <a mat-menu-item     title="add favourite"
           (click)="addFavourite(dataItem)" *ngIf="dataItem.isfavourite==false "  >
           {{ 'Add favourite' | translate }} 
          </a>
 
   
        <a mat-menu-item    
        title="remove favourite"
        (click)="removeFavourite(dataItem)" *ngIf="dataItem.isfavourite==true " >
        {{ 'Remove favourite' | translate }} 
         </a>
         <a mat-menu-item   (click)="getNews(dataItem)"   >
          {{ 'News' | translate }} 
        </a>
        <a mat-menu-item   (click)="ViewAttachment(dataItem)"  >
          {{ 'Attachments' | translate }} 
        </a>
        
      </mat-menu>
      </ng-template>
 
 </kendo-grid-column>
 
 

  <kendo-grid-column   *ngIf="auth.isGranted(_permissions.AddOrders)"   [columnMenu]="false"   width="60">
    <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
 
        <a    	*ngIf="auth.isGranted(_permissions.AddOrders)" (click)="createOrder(dataItem ,1)" class="trasaction-icon buy" title="{{ 'TranactionBuy' | translate }}">{{ 'TranactionBuyIcon' | translate }}</a> |
        <a   	*ngIf="auth.isGranted(_permissions.AddOrders)" (click)="createOrder(dataItem ,2)" class="trasaction-icon sell" title="{{ 'TranactionSell' | translate }}"> {{ 'TranactionSellIcon' | translate }}</a> 
             

   </ng-template>
  
  </kendo-grid-column>
  
   <kendo-grid-column field="SymbolCode"  filterable="menu"
   [sortable]="true"
   [columnMenu]="true" title="{{ 'Symbol' | translate }}" width="160" >
    <ng-template kendoGridCellTemplate let-dataItem  >
     <span class="market-watch-symbol-c" >{{dataItem.SymbolCode}} </span> 
     <br/>
     <span class="market-watch-symbol-n" *ngIf="isRtl()"> {{dataItem. SymbolNameAr}}   </span>
     <span  class="market-watch-symbol-n" *ngIf="!isRtl()"> {{dataItem.SymbolNameEn}}   </span>
     
      <!-- <a href="" class=""> <span  matPrefix class="fa fa-btc" aria-hidden="true"></span> {{dataItem.SymbolCode}}  </a> -->

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="UnitNameEn"  title="{{ 'Unit' | translate }}" width="80" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span  *ngIf="isRtl()"> {{dataItem. UnitNameAr}}   </span>
      <span  *ngIf="!isRtl()"> {{dataItem.UnitNameEn}}   </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Last_Tradeprice" title="{{ 'Last' | translate }}" width="85">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.Last_Tradeprice | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="open"  title="{{ 'Open' | translate }} " width="110">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.open}}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="close"  title="{{ 'Close' | translate }}" width="110">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.close | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="high" title="{{ 'High' | translate }}" width="80" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.high | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column field="low"  title="{{ 'Low' | translate }}" width="80">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.low | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Change"  title="{{ 'Change' | translate }}"width="100" >
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span
      [ngClass]="{'buy': dataItem.Change>0, 'sell' : dataItem.Change < 0 }"
      > {{dataItem.Change | number }}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="ChangePer"  title="{{ 'Change %' | translate }}" width="120">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span
      [ngClass]="{'buy': dataItem.ChangePer>0, 'sell' : dataItem.ChangePer < 0 }"
      > {{dataItem.ChangePer | number:'1.2-2'}}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="volume" title="{{ 'Volume' | translate }}" width="100">
  
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.volume | number }}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="Value" title="{{ 'Value' | translate }}" width="85">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Value | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Ask_qty" title="{{ 'Ask Qty' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Ask_qty | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Ask_qty" title="{{ 'Ask Price' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Ask_price | number }}  
    </span>
  </ng-template>
</kendo-grid-column>


<kendo-grid-column field="Bid_qty" title="{{ 'Bid Qty' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Bid_qty | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Bid_price" title="{{ 'Bid Price' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Bid_price | number }}  
    </span>
  </ng-template>
</kendo-grid-column>



<kendo-grid-column class="grid-one-day-chart" media="(min-width: 992px)" field="intraday" 
  title="{{ '1 Day Chart' | translate }}"
[width]="150" [sortable]="false"  [columnMenu]="false">
<ng-template kendoGridCellTemplate let-dataItem>
    <day-chart [data]="dataItem.open | number " [changePct]="dataItem.ChangePer">
    </day-chart>
</ng-template>
</kendo-grid-column>



</kendo-grid>
  </div> 
 
  