<div class="user-manage-list">
	<mat-card>

		<div fxLayout="row wrap" fxLayoutAlign="space-between center">

			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
				<div fxLayout="row wrap">
					<div class="form-group pad-r-md" fxFlex.xs="80" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="80"
						fxFlex.xl="80">
						<h4> {{ title | translate }}</h4>
					</div>
					<div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="80" fxFlex.gt-md="80">

						<mat-form-field class="width50" floatLabel="never">
							<mat-label>{{translate('Search')}}</mat-label>
							<input class="form-control" matInput type="input" placeholder="{{translate('Search')}}"
								formControlName="Name" (input)="this.requestSymbolDto.SearchText=$event.target.value">
						</mat-form-field>

						<button mat-button color="primary" (click)="search()">
							<mat-icon>search</mat-icon>
						</button>
						<button mat-button color="primary" (click)="getSymbols()">
							<mat-icon>clear</mat-icon>
						</button>
					</div>
					<div class="contact-btn" fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="20" fxFlex.gt-md="20">



						<button mat-raised-button color="primary" *ngIf="auth.isGranted(_permissions.AddSymbols)"
							[routerLink]="['/symbols/create/']">{{ 'Add New Symbol' | translate }} <mat-icon
								class="mrgn-l-sm">add</mat-icon></button>

					</div>
				</div>
			</div>

		</div>

		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">


				<custom-menu [items]="mainMarkets" class="width100 form-field"
					[displayPath]="isRtl()?'NameAr': 'NameEn'" [title]="translate('Main Markets')"
					(onChange)="this.requestSymbolDto.MainMarketID = $event.Id;getSubMarkets()"
					[icon]="'bar_chart'">
				</custom-menu>


				<custom-menu [items]="subMarkets" class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'NameEn'"
					[title]="translate('Sub Markets')" (onChange)="this.requestSymbolDto.MarketID = $event.Id;search()"
					[icon]="'bar_chart'">
				</custom-menu>
			</div>

		</div>
		<br />
		<mat-divider></mat-divider>

		<div class="">

			<kendo-grid [data]="items" [resizable]="true" [reorderable]="true" [filterable]="false"
				[kendoGridSelectBy]="selectBy" class="kendo-grid-list">
				<kendo-grid-column title=" {{ 'Id' | translate }}" headerClass="grid-list-column-header" width="50">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1 )}}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field="SymbolCode" title="{{ 'Code' | translate }} "
					headerClass="grid-list-column-header" width="120"> </kendo-grid-column>





					
					<kendo-grid-column field="Name" title="{{ 'Name' | translate }}" width="300" headerClass="grid-list-column-header"> 
						<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
							<span *ngIf="isRtl()"  > {{ dataItem.NameAr}}</span>
							<span *ngIf="!isRtl()"  > {{ dataItem.NameEn}} </span>
						</ng-template>
					  </kendo-grid-column>





					  <kendo-grid-column field="UnitTxt" title="{{ 'Unit' | translate }} "
					  headerClass="grid-list-column-header" width="120"> </kendo-grid-column>

					  <kendo-grid-column field="Currency" title="{{ 'Currency' | translate }}" headerClass="grid-list-column-header"> 
						<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
							<span *ngIf="isRtl() && dataItem.Currency!=null"  > {{ dataItem.Currency.NameAr}}</span>
							<span *ngIf="!isRtl()&& dataItem.Currency!=null"  > {{ dataItem.Currency.NameEn}} </span>
						</ng-template>
					  </kendo-grid-column>
					  
					  <kendo-grid-column field="NoSettlement" title="{{ 'No Of Settlement Days' | translate }} "headerClass="grid-list-column-header" width="200"> 
						<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
							<span *ngIf="  dataItem.SymbolMarkets!=null"  > {{ dataItem.SymbolMarkets[0].NoSettlement}}</span> 
						</ng-template>
					</kendo-grid-column> 
					  
				<kendo-grid-column field="CreatedDate" title="{{ 'Date Created' | translate }} "
					headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				</kendo-grid-column>

				<kendo-grid-column field="Status" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.SymbolMarkets[0].Status==_StatusEnum.Active" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.SymbolMarkets[0].Status!=_StatusEnum.Active" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>


				  
				<kendo-grid-column title="" width="100" headerClass="grid-list-column-header">

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu">
							<mat-icon>menu</mat-icon>
						</button>
						<mat-menu #anchorMenu="matMenu">
							<a mat-menu-item [routerLink]="['/symbols/Edit/'+ dataItem.Id  ]">
								{{ 'View' | translate }}
							</a>
							<!-- <a mat-menu-item    *ngIf="auth.isGranted(_permissions.DeleteSymbols)"   (click)="deleteSymbol(dataItem)"   >
								    {{ 'Delete' | translate }} 
						     </a> -->

							 <a mat-menu-item   
							 (click)="changePropertiesStatus(dataItem)"   >
							 {{ 'Change Status' | translate }} 
						</a>
						</mat-menu>

					</ng-template>

				</kendo-grid-column>
			</kendo-grid>



		</div>

	</mat-card>
</div>