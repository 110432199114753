 

<kendo-grid [kendoGridBinding]="items"
class="order-kendo-grid"
[resizable]="true"
[reorderable]="true"

[sortable]="true"
filterable="menu"
[columnMenu]="true" 
[rowClass]="rowCallback"
[kendoGridSelectBy]="selectBy"
> 

<kendo-grid-column   title=""  width="50" [columnMenu]="false">
    
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
 
    <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu"
    [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
     >
      <mat-icon>menu</mat-icon>
    </button>
       <mat-menu #anchorMenu="matMenu">
         <a mat-menu-item   (click)="viewOrder(dataItem)" 
            
            *ngIf="auth.isGranted(_permissions.ViewOrders)"  >
            {{ 'View' | translate }} 
          </a>
          <a mat-menu-item   (click)="updateOrder(dataItem)" 
              [disabled]="(dataItem.OrderStatus!=1 && dataItem.OrderStatus!=5) ||!IsOpen() "
              *ngIf="auth.isGranted(_permissions.UpdateOrders) &&
               _AuctionHelper.IsAllowOperationOrder(isEMX() ,dataItem,this.brokerID, dataItem.BrokerID)"  >
            {{ 'Edit' | translate }} 
          </a>
          
          <a mat-menu-item   (click)="cancelOrder(dataItem)" 
          [disabled]="(dataItem.OrderStatus!=1 && dataItem.OrderStatus!=5) ||!IsOpen() "
               *ngIf="auth.isGranted(_permissions.CancelOrders) && _AuctionHelper.IsAllowOperationOrder(isEMX() ,dataItem,this.brokerID, dataItem.BrokerID)" >
             {{ 'Cancel' | translate }} 
          </a>
          <a mat-menu-item   (click)="getOrderHistory(dataItem)"  >
                  {{ 'History' | translate }} 
          </a>
          <a mat-menu-item   (click)="getAttachments(dataItem)"  
          *ngIf="auth.isGranted(_permissions.UpdateOrderAttachment)">
              {{ 'Attachments' | translate }} 
          </a>
          <!-- <a mat-menu-item   (click)="downloadAttachment(dataItem)"
           
          *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)&&dataItem.IsAttachment==true
          
          &&(dataItem.CompanyID==this.companyID ||   isEMX()) " >
            {{ 'Download' | translate }} 
        </a> -->
       </mat-menu>
   
      </ng-template>
 
 </kendo-grid-column>
 
 <kendo-grid-column   title=""  width="40" [columnMenu]="false"
   *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)">
    <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" 
>
       <a class="cursor_pointer" (click)="downloadAttachment(dataItem)" 
       
       *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)&&dataItem.IsAttachment==true
          
          &&(dataItem.CompanyID==this.companyID ||   isEMX()) "> <mat-icon>cloud_download</mat-icon></a>     
  </ng-template>

</kendo-grid-column>

<kendo-grid-column field="OrderID" title="#" width="60" [columnMenu]="true"
*ngIf="auth.isGranted(_permissions.AddOrders)" >
  <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
      <span   [ngClass]= "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }">
        {{dataItem.OrderID}} 
      </span> 
 </ng-template>
</kendo-grid-column>
 
<kendo-grid-column field="OrderStatus" title="{{ 'Status' | translate }}"  width="100" [columnMenu]="false">
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span  [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }">
    {{_helper.getNamesByValue(orderstatusEnum,dataItem.OrderStatus ) | translate }}
  </span>
  </ng-template>
  </kendo-grid-column> 


<kendo-grid-column field="ClientCode"  title="{{ 'Client Code' | translate }}" width="140"
[hidden]="true" >
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
    *ngIf="_AuctionHelper.IsAllowShowOrderClient (isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
     {{dataItem.ClientCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="BrokerCode"  title="{{ 'Broker Code' | translate }}" width="140"
[hidden]="true" >
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderBroker(isEMX() ,auction,this.companyID ,dataItem.CompanyID)" >

     {{dataItem.BrokerCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="CompanyCode"  title="{{ 'Company Code' | translate }}" width="140"
 [hidden]="true" >
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderCompany(isEMX() ,auction,this.companyID ,dataItem.CompanyID)" >

     {{dataItem.CompanyCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="CompanyNameAr"  title="{{ 'Company Name' | translate }}" width="170"
>
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderCompany(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
    
     <span  *ngIf="isRtl()">{{dataItem.CompanyNameAr}}</span>
     <span	  *ngIf="!isRtl()">{{dataItem.CompanyNameEn}}</span>
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Client Name"  title="{{ 'Client Name' | translate }}" width="170"
>
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderClient(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
    
     <span  >{{dataItem.ClientName}}</span>
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Broker Name"  title="{{ 'Broker Name' | translate }}" width="170"
>
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderBroker(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
    
     <span  >{{dataItem.BrokerName}}</span>
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="SymbolCode"   title="{{ 'Symbol Code' | translate }}" width="140">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }">

     {{dataItem.SymbolCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column  field="OrderDate" title="{{ 'Time' | translate }}" width="120">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }">
   
   {{dataItem.OrderDate | date: 'h:mm:ss a'}}
   </span> 
 </ng-template>
 </kendo-grid-column>
<kendo-grid-column  field="OrderDate"  title="{{ 'Date' | translate }}" width="100">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }">
   
   {{dataItem.OrderDate | date: 'dd/MM/yyyy'}}
   </span> 
 </ng-template>
 </kendo-grid-column>

<kendo-grid-column field="OrderQty" title="{{ 'Qty' | translate }}" width="80">
 <ng-template kendoGridCellTemplate let-dataItem
 >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
   *ngIf="_AuctionHelper.IsAllowShowOrderQty(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
   
   {{dataItem.OrderQty | number }}
   </span>  
 </ng-template>

</kendo-grid-column>

<kendo-grid-column field="OrderPrice" title="{{ 'Price' | translate }}"  width="100">

 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   *ngIf="_AuctionHelper.IsAllowShowOrderPrice(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
   <span   *ngIf="dataItem.OrderPrice==0"> MKT</span>
   <span   *ngIf="dataItem.OrderPrice!=0"> {{dataItem.OrderPrice | number }}</span>
   </span> 
   
 </ng-template>
</kendo-grid-column>
<kendo-grid-column field="RemainingQty" title="{{ 'Remaining Qty' | translate }}"   width="140">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2}"
   *ngIf="_AuctionHelper.IsAllowShowOrderQty(isEMX() ,auction,this.companyID ,dataItem.CompanyID)">
   
   {{dataItem.RemainingQty | number }}
   </span> 
 </ng-template>
</kendo-grid-column> 

<kendo-grid-column  field="GoodTillDate"  title="{{ 'Valid Till' | translate }}" width="120">
  <ng-template kendoGridCellTemplate let-dataItem>
    <span   [ngClass]=
    "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }">
    
    {{dataItem.GoodTillDate | date: 'dd/MM/yyyy'}}
    </span> 
  </ng-template>
  </kendo-grid-column>



</kendo-grid>
   
    