import { Injectable } from '@angular/core';
import { permissions } from 'app/core/permission';
import { ApproveStatusEnum } from 'app/core/enums';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  permissions: string[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permissions: string[];
  children?: ChildrenItems[];
}

const
  MENUITEMS = [
   
    {
      state: '',
      name: 'DASHBOARD',
      type: 'sub',
      permissions: [permissions.EnableDashboard ,permissions.EnableAuctions ,permissions. EnableGoldMarket ],
      icon: 'dashboard',
      children: [
        
        {
          state: "dashboard",
          name: "Trading",
          permissions: [permissions.EnableDashboard],
          type: 'button'
        },
        {
          state: "Auctions",
          name: "Auctions",
          permissions: [permissions. EnableAuctions],
          type: 'button'
        },
        {
          state: "gold-market",
          name: "Gold Market",
          permissions: [permissions. EnableGoldMarket],
          type: 'button'
        }
        
        
      ]
    },    {
      state: 'dashboard',
      name: 'Home',
      type: 'link',
      permissions: [permissions.ISStoreKeeper],
      icon: 'dashboard',
 

    } , {
      state: 'operations',
      name: 'Operation Management',
      type: 'sub',
      permissions: [permissions.AuctionPayment],
      icon: 'view_list',
      children: [
        {
          state: 'auction-payments',
          name:  'Auction Payments',
          permissions: [permissions.AuctionPayment],
          type: 'people_outline'
        }  
      ]

    },

    {
      state: 'emx',
      name: 'EMX Management',
      type: 'sub',
      permissions: [permissions.GetEMXUsers],
      icon: 'view_list',
      children: [
        {
          state: 'users',
          name:  'Users',
          permissions: [permissions.GetBrokers],
          type: 'people_outline'
        }  
      ]

    },
    {
      state: '',
      name: 'Trading Companies',
      type: 'sub',
      permissions: [permissions.GetCompanies,permissions.GetCooperatives,permissions.GetBrokers],
      icon: 'web',
      children: [
        {
          state: 'companies',
          name:  'Companies',
          permissions: [permissions.GetCompanies],
          type: 'link'
        }  ,


         {
          state: 'brokers',
          name:  'Users',
          permissions: [permissions.GetBrokers],
          type: 'link'
        } 
      ]

    },   
    {
      state: 'cooperatives',
      name: 'Transactions',
      type: 'sub',
      permissions: [permissions.GetReceiptTransactions ],
      icon: 'business',
      children: [
        {
          state: 'transactions',
          name:  'Transactions',
          permissions: [permissions.GetReceiptTransactions],
          type: 'link',
         
        } 
      ]

    },   
    {
      state: 'cooperatives',
      name: 'Farmers',
      type: 'sub',
      permissions: [permissions.GetFarmers ],
      icon: 'business',
      children: [
        {
          state: 'farmers',
          name:  'Farmers',
          permissions: [permissions.GetFarmers],
          type: 'link',
         
        }
      ]

    },
     {
      state: 'cooperatives',
      name: 'Cooperatives',
      type: 'sub',
      permissions: [permissions.GetReportCooperatives ],
      icon: 'business',
      children: [
        
        {
          state: "cooperatives-managers",
          name: "Cooperative Managers",
          permissions: [permissions.GetCooperativeManagers],
          type: 'link'
         } ,{
         state: "cooperatives",
         name: "Cooperatives",
         permissions: [permissions.GetCooperatives],
         type: 'link'
       },
        {
          state: "receipt-auctions-report",
          name: "Report",
          permissions: [permissions.GetReportCooperatives],
          type: 'link'
        } 
        
      ]

    },
    {
      state: 'cooperatives',
      name: 'REPORTS',
      type: 'sub',
      permissions: [permissions.ISCooperative  ,
        permissions.GetReceiptFarmerReport],
      icon: 'business',
      children: [
        {
          state: 'receipts',
          name:  'Receipts',
          permissions: [permissions.ISCooperative],
          type: 'link',
         
        } 
        
      ]

    },

 
 

    {
      state: 'markets',
      name: 'Markets Management',
      type: 'sub',
      permissions: [permissions.GetMarkets],
      icon: 'trending_up',
      children: [
        
        {
          state: 'markets',
          name:  'Markets',
          permissions: [permissions.GetMarkets],
          type: 'link'
        },
        {
          state: 'sub-markets',
          name: "Sub Markets",
          permissions:[ permissions. GetMarkets],
          type: 'link'
        },       
         
        {
          state: 'calendar',
          name: "Calendar",
          permissions:[ permissions. GetMarketSettings],
          type: 'link'
        }
        
        
      ]
     }
      , 
      {
      state: 'symbols',
      name: 'Symbols',
      type: 'link',
      permissions: [permissions.GetSymbols],
      icon: 'view_list',

    },
     {
      state: 'warehouses',
      name: 'Warehouses',
      type: 'link',
      permissions: [permissions.GetStores],
      icon: 'storage',

    }
    , {
      state: 'warehouses',
      name: 'Warehouse Information',
      type: 'sub',
      permissions: [permissions.ISStoreKeeper],
      icon: 'info_outline',
      children: [

        {
          state: "client_position",
          name: "Client Portfolio",
          permissions: [permissions.GetClientsPosition],
          type: 'link'
        },
        {
          state: "symbol-qantity",
          name: "Warehouse Balances",
          permissions:[ permissions.GetStoreSymbolQty],
          type: 'link'
        }
      ]
    },

     {
      state: 'warehouses',
      name: 'Warehouse Operations',
      type: 'sub',
      permissions: [permissions.GetWarehouseTransaCtion],
      icon: 'keyboard_hide',
      children: [

        {
          state: 'deposit',
          name: 'Deposit',
          permissions: [permissions.DepositWarehouseTransaction],
          type: 'link'
        },
        {
          state: 'withdraw',
          name: 'Withdraw',
          permissions: [permissions.WithdrawWarehouseTransaction],
          type: 'link',
        },
        {
          state: "deleption",
          name: "Depletion",
          permissions: [permissions.DeleptWarehouseTransaction],
          type: 'link'
        }, {
          state: "transfer",
          name: "Transfer",
          permissions: [permissions.TransferWarehouseTransaction],
          type: 'link'
        },
        {
          state: "accept_reject",
          name: "Accept/Reject Transfer",
          permissions: [permissions.ApproaveTransfereWarehouseTransaction],
          type: 'link'
        }
      ]
    }, {
      state: 'clients',
      name: 'Clients Management',
      type: 'sub',
      permissions: [permissions.GetClients,permissions.GetPendingClients],
      icon: 'apps',
     
      children: [
        {
          state: 'clients/'+ApproveStatusEnum. Approve,
          name: 'Active Clients',
          permissions: [permissions.GetClients],
          type: 'link'
        },
        {
          state: 'clients/'+ApproveStatusEnum. AwaitApprove,
          name: 'Pending Clients', 
          permissions: [permissions.GetPendingClients],
          type: 'link'
        },
        {
          state: 'clients/'+ApproveStatusEnum. Reject,
          name: 'Rejected Clients', 
          permissions: [permissions. GetRejectClients],
          type: 'link'
        }        
        , {
          state: 'ExpiryClients/'+ApproveStatusEnum. Approve +'/1',
          name: 'Expired Clients',
          permissions: [permissions.GetClients],
          type: 'link'
        }
      ]

    } 
    ,   {
      state: 'report-management',
      name: 'Report Management',
      type: 'sub',
      permissions: [permissions.GetReportTrades],
      icon: 'assessment',
      children: [
        
        {
          state: "trades",
          name: "Trades",
          permissions: [permissions.GetReportTrades],
          type: 'link'
        },
        {
          state: "orders",
          name: "Orders",
          permissions: [permissions.GetReportOrders],
          type: 'link'
        },

        {
          state: "auctions",
          name: "Auctions",
          permissions: [permissions.GetReportOrders],
          type: 'link'
        },
     
        // ,
        // {
        //   state: "properties-status-log",
        //   name: "Properties Status Log",
        //   permissions: [permissions. GetPropertiesStatusLogs],
        //   type: 'link'
        // }
        
      ]
    }  
 ,   {
      state: 'settlement',
      name: 'Settlement Management',
      type: 'sub',
      permissions: [permissions.IsEnableStatements   ],
      icon: 'assessment',
      children: [
        
        {
          state: "client-statements",
          name: "Client Statements",
          permissions: [ permissions.GetClientStatements],
          type: 'link'
        } ,
        {
          state: "client-balances",
          name: "Client Balances",
          permissions: [ permissions.GetClientBalances],
          type: 'link'
        } ,
        {
          state: "client-purchase-power",
          name: "Client Purchase Power",
          permissions: [ permissions.GetClientBalances],
          type: 'link'
        } ,
        {
          state: "client-settlements",
          name: "UnSettled Client",
          permissions: [ permissions.GetUnSettlementsClients],
          type: 'link'
        } ,
        {
          state: "trade-settlements",
          name: "UnSettled Trade",
          permissions: [ permissions.GetUnSettlementsTrades],
          type: 'link'
        } ,
        {
          state: "cancel-trade-settlements",
          name: "Cancel Trades",
          permissions: [ permissions.GetUnSettlementsTrades],
          type: 'link'
        } ,
        {
          state: "bank-masr-log",
          name: "Bank Masr Log",
          permissions: [ permissions.GetBankMasrLogs],
          type: 'link'
        } ,
      ]
    }
    , {
      state: 'banks',
      name: 'Banks',
      permissions: [permissions.GetBanks],
      type: 'link',
      icon: 'payment',

    }
    , {
      state: 'commisions',
      name: 'Commissions',
      type: 'link',
      permissions: [permissions.GetCommissions],
      icon: 'exposure',

    },
    {
      state: 'news',
      name: 'News',
      type: 'link',
      permissions: [permissions.GetNews],
      icon: 'cast',

    },
      
     {
    state: 'lockups',
    name: 'Lookups',
    type: 'link',
    
    permissions: [permissions.GetCategories,permissions.GetSectors,permissions.GetUnits],
    icon: 'layers',

  }  
   ,	  {
    state: 'monitor_services',
    name: 'Monitor Services',
    type: 'sub',
    permissions: [permissions.RunCalculateCommissions,permissions.RunEndOfPreOpenOrder],
    icon: 'security',
   
    children: [
      {
        state: 'monitor_services',
        name: 'Monitor Services',
        permissions: [permissions.RunCalculateCommissions,permissions.RunEndOfPreOpenOrder],
        type: 'link'
      },
      {
        state: 'monitor_services-log',
        name: 'Monitor Services Logs',
        permissions: [permissions.RunCalculateCommissions,permissions.RunEndOfPreOpenOrder],
        type: 'link'
      },
    ]

  }
    , {
      state: 'users',
      name: 'Users Management',
      type: 'link',
      permissions: [permissions.GetUsers],
      icon: 'people',

    } ,
    // , {
    //   state: 'settings',
    //   name: 'System Settings',
    //   type: 'link',
    //   permissions: [permissions.GetSystemSettings],
    //   icon: 'view_list',

    // }
  ];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
 
    return MENUITEMS;
  }
  add(menu: any) {
    MENUITEMS.push(menu);
  }
}
