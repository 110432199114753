import { Injectable } from '@angular/core';
import { BrokerDto, CompanyDto } from '../../../app/models/broker';
import { Observable } from 'rxjs';
  
import { BankDto } from 'app/models/bank';
import { MonitorServicesApi } from '../api/monitor-services.api';

@Injectable()
export class MonitorServicesService {

  
  constructor(private monitorServicesApi: MonitorServicesApi ) { }

 
  RunCalculateCommissions(): Observable<any> { 
    return this.monitorServicesApi.RunCalculateCommissions();
  }
  RunEndOfPreOpenOrder(): Observable<any> { 
    return this.monitorServicesApi.RunEndOfPreOpenOrder();
  }

  RunEndOfDay(): Observable<any> { 
    return this.monitorServicesApi.RunEndOfDay();
  }
  CalcSetteledBalances(): Observable<any> { 
    return this.monitorServicesApi.CalcSetteledBalances();
  } 
  getMonitorServicesLog(id): Observable<any> { 
    return this.monitorServicesApi.getMonitorServicesLog(id);
  }
  
  getMonitorServices(): Observable<any> { 
    return this.monitorServicesApi.getMonitorServices();
  }
}