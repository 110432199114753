import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
 
 
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router'; 
import { BrokerCompanyDto, BrokerDto } from 'app/models/broker';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { StatusEnum } from 'app/core/enums';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CompanyBrokerFormDialogComponent } from '../company-broker-form-dialog/company-broker-form-dialog.component';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { BrokerFormDialogComponent } from 'app/broker-management/components/broker-form-dialog/broker-form-dialog.component';


@Component({
  selector: 'ms-company-broker-list',
  templateUrl: './company-broker-list.component.html',
  styleUrls: ['./company-broker-list.component.scss']
})
export class CompanyBrokerListComponent implements OnInit , OnDestroy{
  
  public items: any;
  public brokers: any;
  companyId:number;
  company:any;
  title:any;
  public actions:any;
  public action :any;
   
  selectBy:any;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy(){ 

	} 

  constructor( private pageTitleService : PageTitleService ,

    private companyManagementService :CompanyManagementService,
    private dialog: MatDialog  ,
    private toastr: ToastrService,
    private route: ActivatedRoute  ,
      private _enumHelpers :EnumHelpers) { 
      this.actions = _enumHelpers.getNames(StatusEnum);
  
    }


  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.companyId = +prams.get('id'); 
      this.getBrokers( ) ;
      this. getCompany();
      this.title='Users';
    })
  
    this.pageTitleService.setTitle("Users");
      
  }

  getBrokers( ){
  
 
 }
  
  getCompany(){
    
    this.companyManagementService.getCompany(this.companyId ).subscribe(item=>{
       this.company=item; 
       this.items=  this.company.BrokerCompanies.filter(x=>  x.IsDeleted!=true); 
       this.title=item.Name+ ' Company';
     });
   } 

   addNewBrokerDialog(){
     

      const dialog = this.dialog.open(BrokerFormDialogComponent, {
        data:{company:this.company } 
      });
      dialog.afterClosed().subscribe((res) => {
        this.getBrokers( );
  
      } );

      
   }
   
  getAccount(broker){ 
  
    broker.CompanyId=broker.BrokerCompanies[0].CompanyId;
    broker=broker.BrokerCompanies[0].CompanyId;
    const dialog = this.dialog.open(CompanyBrokerFormDialogComponent, {
      
        data:{broker:broker}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getCompany( );
      } );
  }
 
  
  removeCompanyBroker(broker){
   
      this.dialogRef = this.dialog.open(DemoDialog, {
        data: { message: 'would you like to remove this broker '  + '?' },
        disableClose: false
      });
  
      this.dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes!') {
          this.companyManagementService.removeCompanyBroker(broker.Id).subscribe(res =>  { 
            this.getCompany( );
            this.toastr.success("Successfully!");
          
          }, err => {
            this.toastr.error("Error");
            console.log(err)
          });
        }
        this.dialogRef = null;
      });
  
  
    
    } 

    changeStatus(broker){ 
      this.dialogRef = this.dialog.open(DemoDialog, {
        data: { message: 'would you like to change status this broker '  + '?' },
        disableClose: false
      });
  
      this.dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes!') {
          this.companyManagementService.changeStatusCompanyBroker(broker.Id).subscribe(res =>  { 
            this.getCompany( );
            this.toastr.success("Successfully!");
          
          }, err => {
            this.toastr.error("Error");
            console.log(err)
          });
        }
        this.dialogRef = null;
      });
  
  
    
    } 
 

    Edit(data){
      const dialog = this.dialog.open(BrokerFormDialogComponent, {
        data:{ brokerCompany:data ,company:this.company  }  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getCompany( );
  
      } );
  
    }
}

