<div class="popup-card-width popup-card-dialog">

    <mat-dialog-content>
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4 class="mrgn-b-none">{{title}}</h4>
                </div>    
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="gene-card-content">
			<div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="0rem" fxLayoutGap.gt-sm="1.5rem">
				<div *ngIf="onlyDownload!=true" fxFlex.gt-md="calc(20% - 0.75rem)" fxFlex.gt-sm="calc(50% - 0.75rem)" fxFlex.gt-xs="100" fxFlex="100">
				   <div class="mrgn-b-md">
					   <input class="mrgn-b-md" type="file" ng2FileSelect [uploader]="uploader" multiple /><br />
                      <row>
						<!-- <span fxFlex style="font-size: 12px;" >Amount: </span> -->
						<!-- <input fxFlex style="width: 130px;" [disabled]="uploader?.queue?.length != 1" type="number" name="amount" [(ngModel)]= "amount"/> -->
					  </row>
				   </div>
				</div>

				<div fxFlex.gt-md="calc(80% - 0.75rem)" fxFlex.gt-sm="calc(50% - 0.75rem)" fxFlex.gt-xs="100" fxFlex="100">
				   <div class="mrgn-b-md">
					  <h6 fxLayout="row" *ngIf="onlyDownload!=true" >
						 <span fxFlex>Upload queue</span>
						 <span fxFlex style="margin-left: 300px;">Queue length: {{ uploader?.queue?.length }}</span>
					  </h6>
					  <div class="set-overflow-x-auto">
						 <table class="table mb-3 material ml-0 mr-0 mrgn-b-md">
							<thead>
							   <tr> 
							    	 <th width="100%">Files</th>							 								
									 <!-- <th width="30%">Amount</th>							 								 -->
							   </tr>	
							</thead>
							<tbody>
								<ng-container   *ngIf="attachments !=null&&attachments.length > 0">
									<tr  *ngFor="let item of attachments">
										<td nowrap>								 
											  <a  class="cursor_pointer"  (click)="downloadAttachment(item.AttachmentPath)" ><mat-icon>cloud_download</mat-icon></a>
											  {{ item.AttachmentPath }}											
										 </td> 

										 <!-- <td nowrap >								 				
											{{ item.Amount }}											
									   </td>  -->
									</tr>
								 </ng-container>
								 <ng-container *ngIf="onlyDownload!=true &&uploader.queue!=null && uploader.queue.length > 0; else elseBlock">
								  <tr *ngFor="let item of uploader.queue">
									<td nowrap>
										<a  class="cursor_pointer"   (click)="saveFile(item)" [disabled]="item.isReady || item.isUploading || item.isSuccess" (click)="downloadAttachment(item.AttachmentPath)" ><mat-icon>cloud_upload</mat-icon></a>
										<a  class="cursor_pointer"  (click)="item.cancel()" [disabled]="!item.isUploading" ><mat-icon>cancel</mat-icon></a>
										<a  class="cursor_pointer"  (click)="item.remove()" ><mat-icon>remove_circle</mat-icon></a>
										{{ item?.file?.name }}
									 </td>		
									 					 
								  </tr>
							   </ng-container>
							   <ng-template  #elseBlock> 
							   </ng-template>
							</tbody>
						 </table>
					  </div>
					  <div class="mrgn-b-md" *ngIf="onlyDownload!=true" >
						 <p fxFlex style="font-size: 12px;">Queue progress:</p>
						 <mat-progress-bar mode="determinate" value="{{uploader.progress}}"></mat-progress-bar>
					  </div>
					  <button *ngIf="onlyDownload!=true" mat-raised-button mat-button-sm type="button" (click)="saveFiles()" [disabled]="!uploader.getNotUploadedItems().length">
						 Upload all
					  </button>
					  <button *ngIf="onlyDownload!=true" mat-raised-button mat-button-sm type="button" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
						 Cancel all
					  </button>
					  <button *ngIf="onlyDownload!=true" mat-raised-button mat-button-sm type="button" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
						 Remove all
					  </button>
				   </div>
				</div>
			 </div>
	   
	</div>
    
    </mat-dialog-content>

</div>
