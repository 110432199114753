<ngx-slick-carousel [config]="sliderConfig">
  <div class="pad-x-md ticker-item-wrap" fxLayoutAlign="space-between center" ngxSlickItem *ngFor="let content of sliderContent">
      <div>
         <div class="price-wrap ">
            <span class="font-bold"> {{content.SymbolCode}}</span>
            
             <span class="volumePrice" > {{content.close | number:'1.2-2'}}   </span>
         </div>

      </div>
      <div>
         <span class="market-cap   {{content.text_color}}">
            <i class=" fa {{content.icon}}"></i>
            {{content.Change | number:'1.2-2'}} ( {{(content.ChangePer/100) |   percent}} ) 
         </span>
      </div>
   </div>
</ngx-slick-carousel>
