import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
 
import { PeriodService } from '../../../shared/services/period.service';
import { DashboardData } from '../../../models/dashboardData';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
 
import { ResizeEvent } from 'angular-resizable-element';
import { MarketWatchDto } from 'app/models/MarketWatch';
import { OrderDto } from 'app/models/order';
import { DatePipe } from '@angular/common';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { MarketDto } from 'app/models/market';
import { State } from '@progress/kendo-data-query';
import { TradeDto } from 'app/models/trade';

import {   MarketStatusEnum, MarketTabIndexEnum } from 'app/core/enums';
import { AppComponentBase } from '../../../app-component-base';


import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { OrderTicketDialogComponent } from 'app/dashboard/components/orderTicket-dialog/orderTicket-dialog.component';
 
@Component({
  selector: 'ms-dashboard',
  templateUrl: './dashboard-component.html',
  styleUrls: ['./dashboard-component.scss']
})
export class DashboardComponent extends AppComponentBase implements OnInit, OnDestroy {

  marketStatus: any;
  marketId: any;
  orderStatus: number;
  marketWatch: any;
  allMarketWatch: any;
  favouriteMarketWatch: any;
  orderTitle: any;
  dashBoardSummary: DashboardData;
  dashBoardSub: Subscription;
  dashBoardLoaded = false;
  orderStatusList: any;
  orders: OrderDto[];
  symbolCode: string;
  auctionId: number;
  markets: MarketDto[];
  trades: TradeDto[];
  checkMarkets: any;
 
  Auctions: any;
  isPreOpen: boolean = false;
  closeSession: boolean = false;
  storeID: any;
  symbol: any;
  auctionTitle:any;
  marketTabIndex  = MarketTabIndexEnum.all;
  selectedIndex: number = 0;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
  };

  
  constructor( 
    private periodService: PeriodService,
    private dialog: MatDialog,
    private dashboardParamService: DashboardParamService,

    private marketWatchApi: MarketWatchApi,
    
    private symbolService: SymbolService,
    injector: Injector,

    private datePipe: DatePipe, private dashboardApi: DashboardApi) {

    super(injector);
    // this.getPermissions();
 
    this.dashboardParamService.loadMarketStatus.subscribe(res => {

      if (res != null) {
        this.marketStatus = res; 
        if (this.marketStatus == MarketStatusEnum.Close) {
          this.closeSession = true;
          this.isPreOpen = false;
        }else      if (this.marketStatus == MarketStatusEnum.Preopen) {
          this.isPreOpen = true;
        }else{
          this.isPreOpen = false;
        }
      }

    });

    this.dashboardParamService.loadIsOpen.subscribe(res => {

      if (res != null) { 
       
        if (res==true) {
          this.isPreOpen = true;
          if (this.orderStatusList != null && this.orderStatusList.length>0) {
            this.setOrderStatus(this.orderStatusList[4]);
            this.orderStatus = this.orderStatusList[4].StatusID;
          }
        } else {
          this.isPreOpen = false;
           
          if (this.orderStatusList != null && this.orderStatusList.length>0) {
            this.setOrderStatus(this.orderStatusList[0]);
            this.orderStatus = this.orderStatusList[0].StatusID;
          }
        }

 
      }

    });
    this.dashboardApi.getStatusLookup().
      subscribe(res => {
        this.orderStatusList = res.OrderStatus;
        this.orderTitle = res.OrderStatus[0].Status + " Orders";
        this.orderStatus = res.OrderStatus[0].StatusID;
      },);
    this.getTrades();
    this.dashboardParamService.loadMarketId.subscribe(value => {
      
      if (value != null) {
        this.marketId = value;
        if(Number(this.marketId )>0){
          this.getMarketWatchData();
          if (this.orderStatusList != null) {
            this.setOrderStatus(this.orderStatusList[0]);
          }
        }



      }

    });

  }
  getSymbol($event: string) {

    this.symbolCode = $event;
    
    this.symbolService.getSymbolByCode(this.symbolCode).subscribe(res => {
      
      
      this.symbol = res;
   
       
    });
 

  }

  changeFavourite($event: string) {


    this.allMarketWatch.forEach(element => {

      if (element.SymbolCode == $event) {
        element.isfavourite = !element.isfavourite;
      }
    });
    if (!(this.favouriteMarketWatch.filter(x => x.SymbolCode == $event).length > 0)) {

      var item = this.allMarketWatch.filter(x => x.SymbolCode == $event);

      this.favouriteMarketWatch = [item[0], ...this.favouriteMarketWatch];

    } else {
      this.favouriteMarketWatch = this.favouriteMarketWatch.filter(x => x.SymbolCode != $event)
    }

  }
 

  getTrades() {
    this.marketWatchApi.getTradesView(this.gridState.skip, this.gridState.take)
      .subscribe(items => {
        this.trades = items;



      });

  }

  public style: object = {};

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 50;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }
  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      position: 'fixed',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }
  tabClick(event) {
 
    this.marketTabIndex=event.index;
  }
  ngOnInit() { }
  title() {
    this.marketId = this.dashboardParamService.getMarketId();

  }


  createOrder() {

    var order = new OrderDto;
    order.MarketID = this.marketId;

    const editingDialog = this.dialog.open(OrderTicketDialogComponent, {
      data: { order: order }
    });
    editingDialog.afterClosed().subscribe((res) => { });


  }
  ngOnDestroy() {
   // this.dashBoardSub.unsubscribe();

  }
  getMarketWatchData() {
  
    this.marketWatchApi.getFavouriteMarketWatch(this.currentUserId(), this.marketId).subscribe(res => {
      this.favouriteMarketWatch = res;
      this.marketWatchApi.getMarketWatch()
        .subscribe(items => {

          this.allMarketWatch = items;

          this.allMarketWatch = this.allMarketWatch.filter(x => x.MarketId != null);
          this.allMarketWatch.forEach(element => {
            element.trades = [0];
            var symbolTrades = this.trades.filter(x => x.SymbolCode == element.SymbolCode 
              && x.MarketID==element.MarketId ) ;
            if (symbolTrades.length > 0) {
              symbolTrades.forEach(tr => { element.trades.push(tr.TradePrice) as number[]; });
            } else {
              element.trades.push(0);
            }

            var favouriteItems = this.favouriteMarketWatch.filter(x => x.SymbolCode == element.SymbolCode);
            if (favouriteItems.length > 0) {
              element.isfavourite = true;
            } else {
              element.isfavourite = false;
            }
          });
          this.favouriteMarketWatch.forEach(element => {
            element.trades = [0];
            var symbolTrades = this.trades.filter(x => x.SymbolCode == element.SymbolCode);
            if (symbolTrades.length > 0) {
              symbolTrades.forEach(tr => { element.trades.push(tr.TradePrice) as number[]; });
            } else {
              element.trades.push(0);
            }

            var favouriteItems = this.favouriteMarketWatch.filter(x => x.SymbolCode == element.SymbolCode);
            if (favouriteItems.length > 0) {
              element.isfavourite = true;
            } else {
              element.isfavourite = false;
            }
            if(this.marketTabIndex==MarketTabIndexEnum. favourits){
            //  this.symbolCode= favouriteItems[0].SymbolCode;
            }
          });
          this.getSymbol(this.allMarketWatch[0].SymbolCode);
          this.marketWatch = this.allMarketWatch.filter(x => x.MarketId == this.marketId);
          if(this.marketTabIndex==MarketTabIndexEnum.  marketWatch  ){
          //  this.symbolCode=this.marketWatch[0].SymbolCode;
            
           }else  {
            //this.symbolCode=this.allMarketWatch[0].SymbolCode;
            }  
        });
    });

  }


  setOrderStatus(orderStatus) {
 
    this.orderStatus = orderStatus.StatusID;
    this.orderTitle = this.translate(orderStatus.Status + " Orders");
  }


}
