<div class="gene-forgot gene-session pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center start">
      <div fxLayout="column"  fxFlex.xl="450px" fxFlex.lg="450px" fxFlex.md="450px" fxFlex.sm="450px"  fxFlex.xs="100" class="gene-forget-pass">
         <mat-card>
            <div>
            <div fxLayout="column" fxLayoutAlign="center center">
               <div class="login-logo">
               <img src="assets/img/logo.jpeg" width="180" height="57">
               </div>
            </div>
            <form #form="ngForm" (ngSubmit)="send(form.value)">
               <div fxLayout="column" fxLayoutAlign="start stretch">
                  <mat-form-field class="full-wid mrgn-b-sm">
                     <input matInput placeholder="E-Mail" type="text" name="email" required [(ngModel)]="email">
                  </mat-form-field>
                  <button color="primary"  type="submit" mat-raised-button [disabled]="!form.valid" class="primary-bg">{{'RECOVER PASSWORD'|translate}}</button>
                  <p style="text-align: center;"><a [routerLink]="['/account/login']">{{'Back to login'|translate}}</a></p>
               </div>
            </form>
            </div>
         </mat-card>
      </div>
   </div>
</div>