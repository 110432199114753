<mat-card>
	<div fxLayout="row wrap" fxLayoutAlign="space-between center">
		<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
		   <div class="pad-r-md">
			  <h4>{{'Roles'| translate }}</h4>
		   </div>
		</div>
		<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="80">
		   <div fxLayout="row wrap">
			  <div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 200px)" fxFlex.lg="calc(100% - 200px)" fxFlex.xl="calc(100% - 200px)">

			  </div>
			  <div class="contact-btn" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="200px" fxFlex.lg="200px" fxFlex.xl="200px"> 
				 <button mat-raised-button color="primary"  class="addOrderBtn" 	(click)="addNewRoleDialog( )" >{{'ADD NEW ROLE'| translate }}<mat-icon class="mrgn-l-sm">add</mat-icon></button>
			  </div>
		   </div>
		</div>
	 </div>	
	<mat-divider></mat-divider>
	<div class="">

		<kendo-grid 
		      [kendoGridBinding]="items" 
			  [resizable]="true"
			  [reorderable]="true"
			  [filterable]="false"    
			  [sortable]="true"  
			  class="kendo-grid-list" 
			  >  
			  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{(rowIndex+1  )}}
				</ng-template>
			  </kendo-grid-column>
			  <kendo-grid-column field="name" title="{{'Name'| translate }}" headerClass="grid-list-column-header" width="250"  > </kendo-grid-column>

			  <kendo-grid-column   title="" width="100"  headerClass="grid-list-column-header">

				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
				  <button mat-icon-button [matMenuTriggerFor]="anchorMenu"  aria-label="Open anchor menu" >
					<mat-icon>menu</mat-icon>
				  </button>
					 <mat-menu #anchorMenu="matMenu">
						<a mat-menu-item   (click)="editRole(dataItem)"   >
						  {{'Edit'| translate }}
						</a>
						<a mat-menu-item   (click)="deleteRole(dataItem)"   >
							{{'Delete'| translate }}
						  </a>
					 
						 
					 </mat-menu>
				 
					</ng-template>
			   
			   </kendo-grid-column>
	 </kendo-grid>
		 

		
	</div>

</mat-card>