<div class="gene-lockscreenV2 pad-y-xl">
    <div fxLayout="row" fxLayoutAlign="center start">
       <div fxLayout="column" fxFlex="100" fxFlex.xs="90" fxFlex.sm="80" fxFlex.md="80" fxFlex.lg="70" fxFlex.xl="60">
          <mat-toolbar-row fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutAlign.xs="center center">
             <div class="mrgn-b-md">
                <!-- <img src="assets/img/logo.jpeg"> -->
             </div>
             <span class="mrgn-b-md">
                <p class="text-center text-inverse"><button [routerLink]="['/account/login']" mat-raised-button color="accent">{{'Back to login'|translate}}</button></p>
             </span>
          </mat-toolbar-row>
          <div fxLayout="row wrap" fxLayoutAlign="center stretch" class="height-full pad-t-xl pad-b-xl form-wrap-row">
             <div fxLayout="column" fxFlex="100" fxFlex.xs="100" fxFlex.sm="500px" fxFlex.md="544px" fxFlex.lg="544px" fxFlex.xl="544px">
                <mat-card class="gene-login-v2 height-full ">
                   <div>    
                      <div fxLayout="column" fxLayoutAlign="center center" class="mrgn-b-sm text-center">
                         <h2>{{'thanks for your registration'|translate}}</h2>                                                    
                         <p>{{' please reset your password by clicking  your email' |translate}}</p>
                      </div>
 
                   </div>
                </mat-card>
             </div>
          </div>
       </div>
    </div>
 </div>
 