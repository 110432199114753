export class LoginRequest {
  username: string;
  password: string;
  otp?: string;
}


export class ResetPasswordRequest {
  userId: string;
  password: string;
  confirmPassword: string;
  code: string;
}
export class SetNewPasswordRequest {
  userId: string;
  password: string;
  confirmPassword: string; 
}

export class ForgetPasswordRequest {
  email: string;
  clientCallbackUrl: string; 
}
export class ForgetUserPasswordRequest {
  userId: string;
  clientCallbackUrl: string; 
}