import { SignalRService } from '../../../service/signalr/signalr.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StoreKeeperDto, StatisticsModel, ClientPositionDto } from './../../../models/warehouse';
import { Component, OnInit, Injector } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { State, process } from '@progress/kendo-data-query';
import { ClientDto, ClientReuqest, SearchRequestDto } from 'app/models/client';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from 'app/client-managment/services/client.service';

@Component({
  selector: 'client-position',
  templateUrl: './client-position.component.html',
  styleUrls: ['./client-position.component.scss']
})
export class ClientPositionComponent extends AppComponentBase implements OnInit {

  public view:GridDataResult;

  selectedClientID:number;
  clients:ClientDto[];
  clientForm:FormGroup;
  title: any;
  gridState:State = {skip:0,take:10}
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;
  storeKeeper:StoreKeeperDto = new StoreKeeperDto();
  dataSources = {storeKeepers:[],
  stores:[],
  sections:[]
  ,intervals:[],
  status:[]};

  statistics:StatisticsModel= new StatisticsModel();
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private warehouseService: WarehouseApi,
    private _setupApi:SetupApi,
    private clientService: ClientService ,
    injector:Injector) {
      super(injector)

     }


  ngOnInit(): void {
 
    this.title = this.translate('Client Portfolio');

    this.clientForm = new FormGroup({ 
      ClientID: new FormControl({ value: 'ClientID' }),
      itemNameseaRCHa: new FormControl({ value: 'itemNameseaRCHa' }),
      itemNameseaRCHb: new FormControl({ value: 'itemNameseaRCHb' } )
    });

    setTimeout(() => {
      this.pageTitleService.setTitle(this.title);
    }, 0);


    
this._setupApi.getStoreKeeperInfo()
.subscribe(res=>{
  this.storeKeeper = res
  this.dataSources.sections = res.StoreInfo.StoreSections;
  this.dataSources.storeKeepers = res.StoreInfo.StoreKeepers
  this.dataSources.stores = [res.StoreInfo];
});

  }



dataItems:ClientPositionDto[] = [];


  getList() {

    if(this.selectedClientID != null && this.selectedClientID != undefined && this.selectedClientID > 0)
    {
      this.warehouseService.getClientsPosition(this.selectedClientID).subscribe(

        data=>{this.dataItems = this.setDataList(data)

          this.view= process(this.dataItems,this.gridState); //process(this.dataItems,this.gridState);
        }
        
        )
    }
  }

  changeClientFilter(filter:string)
  { 
    if(filter.length<=2)return ;
let cfilter:ClientReuqest= new ClientReuqest();
  cfilter.GeneralFilter = filter;
  cfilter.StoreID=  this.storeKeeper .StoreID;
  this.clientService.searchClients(cfilter)
.subscribe(res=> this.clients =  res.clients);
  }

  setDataList(res: ClientPositionDto[]): ClientPositionDto[] {
    if(res == null || res == undefined)
    {
      return Array<ClientPositionDto>();
    }
    else
    return res;
  }

}
