import { StoreSectionApi } from './../../api/storesection.api';
import { StoreSectionFormDialogComponent } from './../../components/store-section-form-dialog/store-section-form-dialog.component';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { SymbolDto } from 'app/models/symbol';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { WarehouseService } from 'app/warehouse-managment/services/warehouse.service';
import { StatusEnum } from 'app/core/enums';
import { StoreSectionDto } from 'app/models/store';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { AppComponentBase } from 'app/app-component-base';
 

@Component({
  selector: 'store-section-list',
  templateUrl: './store-section-list.component.html',
  styleUrls: ['./store-section-list.component.scss']
})
export class StoreSectionListComponent extends AppComponentBase implements OnInit {

  public items: SymbolDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;

  @Input() storeId;
  _storeSectionsList :StoreSectionDto[]= [];
  @Input() 
  get storeSectionsList(){
    return this._storeSectionsList;
  };
  set storeSectionsList(val)
  {
    if(val != null)
    this._storeSectionsList = val;
  }
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private storeSectionApi: StoreSectionApi,
     private _warehouseService:WarehouseApi,
     injector:Injector
    ) { 
      super(injector);
    }
   



  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.title = 'Sections';
    })

    setTimeout(() => {
      this.pageTitleService.setTitle(this.title);
    }, 0);

 

  }



  addStoreSection() {
   
    var storeSection = new StoreSectionDto();
    storeSection.StoreID = this.storeId;
    const dialog = this.dialog.open(StoreSectionFormDialogComponent, {
      data: { storeSection: storeSection }
    });
    dialog.afterClosed().subscribe((res) => {
  
      this.afterClose();
    });
  }
afterClose(){
  this._warehouseService.getStore(this.storeId).subscribe((res)=>{
   
    this.storeSectionsList = res.StoreSections;
    this.storeSectionsList = this.storeSectionsList.filter(item=>item.IsDeleted!=true)
  });
}

  editStoreSection(storeSection) {
    const dialog = this.dialog.open(StoreSectionFormDialogComponent, {
      data: { storeSection: storeSection }
    });
    dialog.afterClosed().subscribe((res) => {
      this.afterClose();
    });
  }

  deleteStoreSection(storeSection){
    this.storeSectionApi
    .deleteStoreSection(storeSection.StoreID,storeSection.StoreSectionId)
    .subscribe(res=>
      this.afterClose()
    ,err=>this.toastr.error(this.translate(err.error[0])));
  }









}
