 
import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { StoreDto , StoreSectionDto } from 'app/models/store';
import { WarehouseService } from 'app/warehouse-managment/services/warehouse.service';
import { WarehouseFormDialogComponent } from 'app/warehouse-managment/components/warehouse-form-dialog/warehouse-form-dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { TransactionComponent } from 'app/warehouse-managment/components/transaction/transaction.component';
import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from 'app/app-component-base';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
 
@Component({
  selector: 'ms-warehouse-manage-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss']
})
export class WarehouseListComponent extends AppComponentBase implements OnInit, OnDestroy {

  public items: StoreDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private warehouseService: WarehouseService,
    injector:Injector,
    private _helper :EnumHelpers) {
      super(injector); 
      this.actions = _helper.getNames(StatusEnum);
    }




  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {

    })
      this.title = this.translate('Warehouses');
    setTimeout(() => {
      this.pageTitleService.setTitle("Warehouses");
    });

    this.getWarehouses(StatusEnum.Active);

  }

  changePropertiesStatus(data){
 
    let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
    propertiesStatus.ActionOn=EntityTypeEnum. Warehouse;
    propertiesStatus.ResponsibleId=data.StoreID;
    propertiesStatus.ResponsibleNameAr=data.StoreNameAr;
    propertiesStatus.ResponsibleNameEn=data.StoreNameEn;
    if( !data.Suspended)
         propertiesStatus.Status= this._StatusEnum.Active;
      else
      propertiesStatus.Status=this._StatusEnum.InActive; 
   
    const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
      data:{propertiesStatus:propertiesStatus   }  
    });
    dialog.afterClosed().subscribe((res) => { 
  if(res!=null){
    if(res!=this._StatusEnum.Active)
            data.Suspended=true;
     else
     data.Suspended=false; 
  }
  
    } );
  }
  
  getWarehouses(action) {
    
    this.warehouseService.getWarehouses().subscribe(items => {
      this.items = items;
 
    if(action==StatusEnum.Active)
      this.items = this.items.filter(x => x.Suspended ==false);
      else if(action== StatusEnum.InActive)
      this.items = this.items.filter(x => x.Suspended ==true);
    });
  }


    addNewWarehouseDialog() {
        var warehouse = new StoreDto();
        const dialog = this.dialog.open(WarehouseFormDialogComponent, {
            data: { StoreID: warehouse.StoreID }
        });
        dialog.afterClosed().subscribe((res) => {
            this.getWarehouses(this.action);
        });
    }

    blockSellOrderDialog() {

        const dialog = this.dialog.open(TransactionComponent, {
            data: {}
        });
        dialog.afterClosed().subscribe((res) => {

        });
    }

  editWarehouse(warehouse) {
    const dialog = this.dialog.open(WarehouseFormDialogComponent, {
      data: { StoreID: warehouse.StoreID }});
    dialog.afterClosed().subscribe((res) => {
      this.getWarehouses(this.action);
    });

  }
deleteWarehouse(warehouse:StoreDto){
  this.warehouseService.deleteWarehouse(warehouse.StoreID).subscribe(res=>{
    this.getWarehouses(null);
  })
}

}

