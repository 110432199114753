 

<kendo-grid [kendoGridBinding]="items"
class="order-kendo-grid"
[resizable]="true"
[reorderable]="true"

[sortable]="true"
filterable="menu"
[columnMenu]="true"
(dataStateChange)="onDataStateChange()"
[rowClass]="rowCallback"
[kendoGridSelectBy]="selectBy"
> 
<kendo-grid-column   title=""  width="50" [columnMenu]="false">
    
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
 
    <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu"
    [ngClass]=  "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
     >
      <mat-icon>menu</mat-icon>
    </button>
       <mat-menu #anchorMenu="matMenu">
         <a mat-menu-item   (click)="viewOrder(dataItem)" 
            
            *ngIf="auth.isGranted(_permissions.ViewOrders)"  >
            {{ 'View' | translate }} 
          </a>
          <a mat-menu-item   (click)="updateOrder(dataItem)" 
              [disabled]="dataItem.OrderStatus!=1 && dataItem.OrderStatus!=5 "
              *ngIf="auth.isGranted(_permissions.UpdateOrders)"  >
            {{ 'Edit' | translate }} 
          </a>
          
          <a mat-menu-item   (click)="cancelOrder(dataItem)" 
               [disabled]="dataItem.OrderStatus!=1 && dataItem.OrderStatus!=5 " 
               *ngIf="auth.isGranted(_permissions.CancelOrders)" >
             {{ 'Cancel' | translate }} 
          </a>
          <a mat-menu-item   (click)="getOrderHistory(dataItem)"  >
                  {{ 'History' | translate }} 
          </a>
          <a mat-menu-item   (click)="getAttachments(dataItem)"  
         >
              {{ 'Attachments' | translate }} 
          </a>
          <a mat-menu-item   (click)="downloadAttachment(dataItem)"
           
          *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)&&dataItem.IsAttachment==true
          &&(dataItem.CompanyID==this.companyID ||   isEMX())" >
            {{ 'Download' | translate }} 
        </a>
       </mat-menu>
   
      </ng-template>
 
 </kendo-grid-column>
<kendo-grid-column field="OrderID" title="#" width="50" [columnMenu]="false">
  <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
      <span   [ngClass]=
      "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
     
      >
        {{dataItem.OrderID}} 
      </span> 
 </ng-template>

</kendo-grid-column>
 

<kendo-grid-column field="ClientCode"  title="{{ 'Client Code' | translate }}" width="120"
*ngIf="auth.isGranted(_permissions.ViewClientOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   >
     {{dataItem.ClientCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="BrokerCode"  title="{{ 'Broker Code' | translate }}" width="140"
*ngIf="auth.isGranted(_permissions.ViewBrokerOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   >
     {{dataItem.BrokerCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column field="CompanyCode"  title="{{ 'Company Code' | translate }}" width="140"
*ngIf="auth.isGranted(_permissions.ViewFirmOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   >
     {{dataItem.CompanyCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>



<kendo-grid-column field="SymbolCode"   title="{{ 'Symbol Code' | translate }}" width="140"

>
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
   >
     {{dataItem.SymbolCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>

<kendo-grid-column  field="OrderDate" title="{{ 'Time' | translate }}" width="120">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
   >
   
   {{dataItem.OrderDate | date: 'h:mm:ss a'}}
   </span> 
 </ng-template>
 </kendo-grid-column>
<kendo-grid-column  field="OrderDate"  title="{{ 'Date' | translate }}" width="120">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
   >
   
   {{dataItem.OrderDate | date: 'dd/MM/yyyy'}}
   </span> 
 </ng-template>
 </kendo-grid-column>

<kendo-grid-column field="OrderQty" title="{{ 'Qty' | translate }}" width="100">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
   >
   
   {{dataItem.OrderQty | number }}
   </span>  
 </ng-template>

</kendo-grid-column>

<kendo-grid-column field="OrderPrice" title="{{ 'Price' | translate }}"  width="100">

 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2 }"
  >
   <span   *ngIf="dataItem.OrderPrice==0"> MKT</span>
   <span   *ngIf="dataItem.OrderPrice!=0"> {{dataItem.OrderPrice | number }}</span>
   </span> 
   
 </ng-template>
</kendo-grid-column>
<kendo-grid-column field="RemainingQty" title="{{ 'Remaining Qty' | translate }}"   width="140">
 <ng-template kendoGridCellTemplate let-dataItem>
   <span   [ngClass]=
   "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2}"
   >
   
   {{dataItem.RemainingQty | number }}
   </span> 
 </ng-template>
</kendo-grid-column> 

<kendo-grid-column  field="GoodTillDate"  title="{{ 'Valid Till' | translate }}" width="120">
  <ng-template kendoGridCellTemplate let-dataItem>
    <span   [ngClass]=
    "{'buy': dataItem.OrderType===1, 'sell' : dataItem.OrderType===2  }"
    >
    
    {{dataItem.GoodTillDate | date: 'dd/MM/yyyy'}}
    </span> 
  </ng-template>
  </kendo-grid-column>



</kendo-grid>
   
    