
export class EMXUserDto {
    Id: number;
    UserId: string;
    Name: string;
    ClientCallbackUrl: string;
    IpAddress: string;
    CreatedUserId: string;
    CreatedDate: Date;
    IssueDate: Date;
    DateofBirth: Date;
    ExpiryDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    Email: string;
    UserName: string;
    Password: string;
    Status:number;
    ConfirmPassword: string; 
    RoleName: string;
    JobTitleId:any;
    DepartmentId:any;
    Address: string;
    NationalityID: number;
    IdentityTypeID: number;
    IdentityTypeText: string; 
    Telephone: string;
    status: number; 
  }
  