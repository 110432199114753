export class TempTradeDto { 
    TradeID :any;
    TradeDate :any;
    BuyOrderid :any;
    SellOrderid :any;
    TradedQty :any;
    TradePrice :any;
    SymbolCode :any;
    MarketID :any;
    AuctionID :any;
    RoundSer :any;
}