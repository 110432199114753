import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AppConsts } from 'app/core/config';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import { ClaimDto, RoleDto } from '../../../models/role';
import { RoleFormDialogComponent } from '../role-form-dialog/role-form-dialog.component';
@Component({
  selector: 'ms-user-session-list',
  templateUrl: './user-session-list.component.html',
  styleUrls: ['./user-session-list.component.scss']
})
export class UserSessionListComponent  extends AppComponentBase  implements OnInit {

  searchTxt: any = "";
  items: any[];
  users: any;
  dialogRef: MatDialogRef<DemoDialog>;
  constructor(private adminService: AdminService
    , private dialog: MatDialog,
    private pageTitleService: PageTitleService,  injector: Injector
    ) {
      super(injector)
      

    setTimeout(() => {
      this.pageTitleService.setTitle("User Sessions");
    }, 0);
  }

  ngOnInit(): void {
    this.getUserSessions();
  }




  KillSession(user) {

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'Would you like to kill the session for this user name ' + user.userName + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.destroySession(user.id).subscribe((res) => {
          this.toastr.success("Successfully!");
          this. getUserSessions();
        }, err => {
          this.toastr.error("Error");
   //       console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
  
  KillAllSession() {

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'Would you like to kill all the sessions ?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.destroyAllSession().subscribe((res) => {
          
          this.toastr.success("Successfully!");
          this. getUserSessions();
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }



  getUserSessions() {
    this.adminService.getUsers().subscribe(usersList => {
     var userId=this.currentUserId();
      this.users = usersList;
      this.users =   this.users.filter(x=>x.id!=userId);
   
      this.adminService.getActiveSessions().subscribe(itemsSessions => {
 
        this.items=[];
        this.users .forEach(element => {
            if(itemsSessions.filter(x=>x.userId==element.id
               ).length>0){
                this.items.push(element);
             }
          });
      });

    });

  }
}
