import { Component, ElementRef, Inject, Injector, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { BrokerCompanyDto, BrokerDto, CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import {Helpers}from 'app/helper/helpers';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AppComponentBase } from 'app/app-component-base';
import { OrderDto } from 'app/models/order';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { GoldOrderDto } from 'app/models/goldMarket';
import { GoldMarketApi } from 'app/gold-market-management/api/gold-market.api';

@Component({
  selector: 'ms-order-cofirmation-dialog',
  templateUrl: './gold-order-cofirmation-dialog.component.html',
  styleUrls: ['./gold-order-cofirmation-dialog.component.scss']
})
export class GoldOrderCofirmationDialogComponent    extends AppComponentBase implements OnInit {
 
  errors: any;
  orderId:any;
  order: GoldOrderDto = new GoldOrderDto();
  title:any;
  isEnable = true;
  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private goldMarketApi:GoldMarketApi,  
    private elRef: ElementRef,
    private  dialogRef: MatDialogRef<GoldOrderCofirmationDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,  
 
    private _helpers:Helpers   , injector: Injector
    ) {

      super(injector);
       
    if (data.order != null) {
      this.order=data.order;
     
    
    } 
    if (data.orderId != null) {
      this.orderId=data.orderId;
    
    } 
    this.title="Order Confirmation"

  }

  ngOnInit(): void {

    

  }
  close() {
 
    this.dialogRef.close(false);
  }


  onFormSubmit( ) {

    this.errors = []; 
    this.isEnable = false; 
    this.order.OrderType=Number(this.order.OrderType);
    this.order.IndicativePrice=Number(   this.order.IndicativePrice);
    
    this.goldMarketApi.createOrder(this.order).subscribe(res => {

      this.dialogRef.close(true);
      this.toastr.success(this.translate('Successfully'));

    },
      err => {
 
        this.isEnable = true;
      });
   
  }

 


  
}
