<div class="user-manage-list">
  <mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayoutAlign="start center">


        <div class="mrgn-l-sm mrgn-r-sm">
          <h4> {{ title }}</h4>
        </div>
      </div>
      <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

        <mat-card-actions class="mat-pad-none margin-none">
          <button mat-raised-button mat-button-sm color="primary"
            *ngIf="auth.isGranted(_permissions.UpdateMarketCalendar)" (click)="addNewCalendarDialog()"> {{ 'Add New
            Calendar' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
        </mat-card-actions>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">

        <div fxFlex.gt-sm="66" fxFlex.gt-xs="66" fxFlex="100" fxFlex.gt-md="66">
          

          <custom-menu [items]="mainMarkets" class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'NameEn'"
            [title]="translate('Main Markets')" (onChange)="this.MainMarketID = $event.Id;getSubMarkets()"
            [icon]="'bar_chart'">
          </custom-menu>


          <custom-menu [items]="subMarkets" class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'NameEn'"
            [title]="translate('Sub Markets')" (onChange)="this.MarketID = $event.Id;getMarketCalendar()" [mandatory]="true" [icon]="'bar_chart'">
          </custom-menu>

          
        
          <mat-checkbox class="example-margin" [checked]="IsPublic"  (change)="onChangeIsPublic($event)">{{ 'Public' | translate }}</mat-checkbox>
          
          
        </div>
      </div>

    </div>
    <br />
    <mat-divider></mat-divider>
    <div class="">


      <kendo-grid #grid [kendoGridBinding]="items" [filterable]="false" [sortable]="true" class="kendo-grid-list">
        <kendo-grid-column title="{{ 'Id' | translate }} " width="40" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{(rowIndex+1 )}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="Sessiontype" title="{{ 'Description' | translate }}" width="100"
          headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{dataItem.Description}}</span>
          </ng-template>

        </kendo-grid-column>


        <kendo-grid-column field="StartSessionTime" width="80" title="{{ 'Start Time' | translate }} "
          headerClass="grid-list-column-header">

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{dataItem.StartDate| date: 'dd/MM/yyyy'}}</span>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="EndSessionTime" width="80" title="{{ 'End Time' | translate }} "
          headerClass="grid-list-column-header">

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span>{{dataItem.EndDate| date: 'dd/MM/yyyy'}}</span>
          </ng-template>
        </kendo-grid-column>




        <kendo-grid-column title="" [columnMenu]="false" width="60" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

            <a (click)="edit(dataItem )">
              <mat-icon class="mrgn-l-sm">create</mat-icon>
            </a> |
            <a class="icon_hover" (click)="delete(dataItem )">
              <mat-icon class="mrgn-l-sm">delete_sweep</mat-icon>
            </a>


          </ng-template>

        </kendo-grid-column>

      </kendo-grid>


    </div>

  </mat-card>
</div>