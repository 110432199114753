import { MarketSessionTypeDto } from "./StatusLookup";
import { SymbolMarketDto } from "./symbol";

export class MarketSettingsDto {
    AllowCheckBalance: boolean;
    AllowCheckQty: boolean;
    AllowCheckWarehouse: boolean;
    marketId: number;
}

export class MarketDto {
    Id: number;
    Name: string;
    NameEn: string;
    NameAr: string;
    IsDeleted: boolean;
    IsActive: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    SymbolMarkets: SymbolMarketDto[];
    AllowCheckBalance: boolean;
    AllowCheckQty: boolean;
    AllowCheckWarehouse: boolean;
    Code: string;
    ParentMarketId: number;
    MarketStatus: number;
    marketWorkingDays:any;
    marketSessions:any;
    marketCommissions:any[];
    MarketType:any;
}

export class SubMarketSessionDto {
    Id: number;
    Name: string;
    NameEn: string;
    NameAr: string;
    IsDeleted: boolean;
    IsActive: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    SymbolMarkets: SymbolMarketDto[];
    AllowCheckBalance: boolean;
    AllowCheckQty: boolean;
    AllowCheckWarehouse: boolean;
    Code: string;
    ParentMarketId: number;
    MarketStatus: number;
    CloseTime : string;
    StartSessionTime: string;
    EndSessionTime: string;
    SessionTypeId : number;
}

export class MarketSessionDto {
    Id: number;
    MarketId: number;
    StartSessionTime: string;
    EndSessionTime: string;
    Sessiontype: any;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    MarketSessionTypeDto: MarketSessionTypeDto;
    NameEn: string;
    NameAr: string;
    Remark: string;
    IsAllowOrder: boolean;
    IsAllowMatching: boolean;
    MarketStatus: any;
    MarketSessionType: any;
    MarketType: any;
}


export class MarketWorkingDayDto {
    Id: number;
    MarketId: number;
    WorkingDay: number;
    WorkingDays: any;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;

}

export class CalendarDto {
    Id: number;
    MarketId: number;
    Description: string;
    StartDate: Date;
    EndDate: Date;
    CreateUserID: string;
    CreateDate: Date;
    LastUpdateUserID: string;
    LastUpdateDate: Date;
    IsPublic:boolean;

}
export class ValidateSessionDto {
    startTime: any;
    endTime: any;
    marketSessions: any;
    marketId: any;
}
export class ValidationMarketCommissionDto {
    commId: any;
    marketCommissions: any;
    marketId: any;
}