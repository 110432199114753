import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { MarketService } from 'app/market-managment/services/market.service';
import { StatusEnum } from 'app/core/enums';


import { MarketDto } from 'app/models/market';
import { SymbolUnitDto } from 'app/models/StatusLookup';
import { SymbolDto, SymbolMarketDto } from 'app/models/symbol';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ms-symbol-market-form-dialog',
  templateUrl: './symbol-market-form-dialog.component.html',
  styleUrls: ['./symbol-market-form-dialog.component.scss']
})
export class SymbolMarketFormDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  public symbols: SymbolDto[];
  symbol: SymbolDto = new SymbolDto();
  symbolMarket: SymbolMarketDto = new SymbolMarketDto();
  addNewSymbolForm: FormGroup;

  isUpdate: boolean;
  errors: any;
  lockedLst: any;
  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SymbolMarketFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private setupApi: SetupApi,
    private symbolService: SymbolService,
    private marketService: MarketService,
    injector: Injector
  ) {
    super(injector);
    
    if (data.symbol.Id != null) {
      this.symbol = data.symbol;
      this.symbolMarket = data.symbol.SymbolMarkets[0];
      this.isUpdate = true;
      this.title = this.translate("Update Market Symbol") + " - " + this.symbol.NameEn;
    } else {
      this.isUpdate = false;
      this.symbolMarket.MarketId = data.symbol.MarketId;
      this.title = this.translate("Assign New Symbol");
    }
  }

  ngOnInit(): void {
    this.addNewSymbolForm = new FormGroup({
      OpenPrice: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      UpperLValue: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      UpperLPerc: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      LowerLValue: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      LowerLPerc: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      locked: new FormControl({ value: '' }),
      SymbolId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });
    this.lockedLst = [
      { "id": false, "name": this.translate("Unlocked") },
      { "id": true, "name": this.translate("Locked") }

    ];
    this.getSymbols();
  }


  close() {
    this.dialogRef.close(false);
  }

  onFormSubmit() {

    this.errors = [];
    if (this.symbolMarket.Locked == null)
      this.symbolMarket.Locked = false;
    this.symbolMarket.SuspensionTime = this.symbolMarket.SuspensionTimeCircuit;
    this.symbolMarket.SuspensionPeriod = this.symbolMarket.SuspensionPeriodCircuit;
    this.symbolMarket.Status = StatusEnum.Active;
    this.marketService.addSymbolMarket(this.symbolMarket, this.symbolMarket.SymbolId).subscribe(res => {
      this.dialogRef.close(this.addNewSymbolForm.value);
      this.toastr.success(this.translate("Successfully!"));
    },
      err => {
        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);

      }
    );
  }

  getSymbols() {
    this.symbolService.getSymbols().subscribe(items => {

      this.symbols = items;
      if (this.isUpdate != true) {

        this.symbols = this.symbols.
          filter(x => !(x.SymbolMarkets.filter(x => x.SymbolId == this.symbol.Id).length > 0));
      }

    });
  }

  calUpperValue(){
   if(this.symbolMarket.UpperLPerc!=null){

    this.symbolMarket.UpperLValue=(this.symbolMarket.OpenPrice*
      (this.symbolMarket.UpperLPerc /100))+this.symbolMarket.OpenPrice;
   }
  }

  calLowerValue(){ 
    if(this.symbolMarket.LowerLPerc!=null){

      this.symbolMarket.LowerLValue=this.symbolMarket.OpenPrice-(this.symbolMarket.OpenPrice*
        (this.symbolMarket.LowerLPerc /100));
    }
  }
  changeOpenPrice(){
    this.calUpperValue();
    this.calLowerValue();
  }


}
