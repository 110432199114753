<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
 
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
			   <div fxLayout="row wrap">
				  <div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.xl="calc(100% - 300px)">
					  <h4> {{ title | translate }}</h4>
				  </div>
				  <div fxFlex.gt-sm="85" fxFlex.gt-xs="85" fxFlex="85" fxFlex.gt-md="85">

					<mat-form-field class="width50" floatLabel="never">
						<mat-label>{{translate('Search')}}</mat-label>
						<input class="form-control" matInput type="input" placeholder="{{translate('Search')}}" formControlName="Name" (input)="filterOptions.GeneralFilter=$event.target.value">
					</mat-form-field>
	
					<button mat-button color="primary" (click)="onStateChange()"><mat-icon>search</mat-icon>
			         </button>
					 <button mat-button color="primary" (click)="clearFilter()"><mat-icon>clear</mat-icon>
			         </button>
				</div>
				  <div class="contact-btn" fxFlex.gt-sm="15" fxFlex.gt-xs="15" fxFlex="15" fxFlex.gt-md="15"> 
				  </div>
			   </div>
			</div>

		 </div>	
		 
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">

				<!-- <custom-menu [items]="dataSources.companies"
				 class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'Name'" [title]="translate('Trading Companies')"
				(onChange)="this.filterOptions.CompanyId = $event.Id;getUserCompany()"  
				   [icon]="'people_outline'">
				</custom-menu>  -->

				<mat-form-field class="overview width100 form-field" appearance="outline">
					<mat-label>{{ 'Trading Companies' | translate }}</mat-label>
					<mat-select 
					[(ngModel)]="filterOptions.CompanyId" 
					(selectionChange)="onChangeCompany($event);getUserCompany()"   
					name="CompanyId" >  
						<mat-label>{{ 'Trading Companies' | translate }}</mat-label>
						<mat-option>
							<ngx-mat-select-search name="company" 
								placeholderLabel="{{translate('Enter At Least One Character')}}"
								noEntriesFoundLabel="{{translate('No data found')}}" ngModel
								(ngModelChange)="onChangeCompany($event)">
							</ngx-mat-select-search>
						</mat-option>
						<mat-option>{{ 'All' | translate }}</mat-option>
						<mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
							{{isRtl()?company.NameAr:company.Name}}</mat-option>
					</mat-select>
				</mat-form-field> 

				<!-- <custom-menu [items]="this.dataSources.roles"
				 class="width100 form-field" [displayPath]="'name'" 
				 [title]="translate('Roles')" [icon]="'place'" 
				 (onChange)="filterOptions.Role = $event.name;getRoleUser()"></custom-menu>   -->

				 <mat-form-field style="margin-left: 3px;" class="overview width100 form-field" appearance="outline">
					<mat-label>{{ 'Roles' | translate }}</mat-label>
					<mat-select 
					[(ngModel)]="filterOptions.Role" 
					(selectionChange)="onChangeRole($event);getRoleUser()"   
					name="CompanyId" >  
						<mat-label>{{ 'Roles' | translate }}</mat-label>
						<mat-option>
							<ngx-mat-select-search name="company" 
								placeholderLabel="{{translate('Enter At Least One Character')}}"
								noEntriesFoundLabel="{{translate('No data found')}}" ngModel
								(ngModelChange)="onChangeRole($event)">
							</ngx-mat-select-search>
						</mat-option>
						<mat-option>{{ 'All' | translate }}</mat-option>
						<mat-option  *ngFor="let role of  filteredRoles" [value]="role.name">
							{{role.name}}</mat-option>
					</mat-select>
				</mat-form-field> 
			</div>
	
		</div>
			<br/>
			<mat-divider></mat-divider>
		   <div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"    
				  class="kendo-grid-list" 
				  >  
				  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="userName" title="{{'User Name'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
				  <kendo-grid-column field="email" title="{{'Email'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
				  <kendo-grid-column field="phoneNumber" title="{{'Phone'| translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>
                  <kendo-grid-column field="ipAddress" title="{{'IpAddress'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
                  
				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item   (click)="editUser(dataItem)"   >
							 {{'View'| translate }}
							</a>
							<a mat-menu-item   (click)="deleteUser(dataItem)"   >
								{{'Delete'| translate }}
						    </a>					
						 </mat-menu>					 
						</ng-template>				   
				   </kendo-grid-column>
		    </kendo-grid>    
		</div>
	</mat-card>
</div>