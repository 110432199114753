<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
 
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
			   <div fxLayout="row wrap">
				<div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 400px)" fxFlex.lg="calc(100% - 400px)" fxFlex.xl="calc(100% - 400px)">
					<h3> {{ title | translate }}</h3>
				</div>
				<div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="80" fxFlex.gt-md="80">

				  <mat-form-field class="width50" floatLabel="never">
					  <mat-label>{{translate('Search')}}</mat-label>
					  <input class="form-control" matInput  placeholder="{{translate('Search')}}" (input)="brokerRequest.GeneralFilter=$event.target.value" >
				  </mat-form-field>
  
				  <button mat-button color="primary" (click)="onStateChange()"><mat-icon>search</mat-icon>
				   </button>
				   <button mat-button color="primary" (click)="clear()"><mat-icon>clear</mat-icon>
				   </button>
			  </div>
				<div class="contact-btn" fxFlex.xs="20" fxFlex.sm="20" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20"> 

				   <button mat-raised-button color="primary" 	
				   *ngIf="auth.isGranted(_permissions.AddBrokers)  " 
				   [routerLink]=  "['/brokers/create/']"   >{{ 'ADD NEW USER' | translate }} <mat-icon class="mrgn-l-sm">add</mat-icon></button>

				   <!-- <button mat-raised-button color="primary" 	
				   *ngIf="auth.isGranted(_permissions.AddBrokers)  " 
				   (click)="addNewBrokerCompanyDialog()"   >{{ 'NEW ACCOUNT' | translate }} <mat-icon class="mrgn-l-sm">add</mat-icon></button> -->

				</div>
			   </div> 
			</div>

		 </div>	
		 
		<div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="!isBroker()">
               <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">

				<!-- <custom-menu [items]="companies"
				 class="width100 form-field" [displayPath]="isRtl()?'NameAr': 'Name'" [title]="translate('Trading Companies')"
				(onChange)="this.companyId = $event.Id;getBrokers()"  
				[icon]="'people_outline'" >	</custom-menu>  -->

					<mat-form-field class=" form-field "appearance="outline" style="width: 25% !important;" >
						<mat-label>{{ 'Trading Companies' | translate }}</mat-label>
						<mat-select 
						[(ngModel)]="this.companyId" 
						(selectionChange)="onChangeCompany($event);getBrokers()"   
						name="CompanyId" >  
							<mat-label>{{ 'Trading Companies' | translate }}</mat-label>
							<mat-option>
								<ngx-mat-select-search name="company" 
									placeholderLabel="{{translate('Enter At Least One Character')}}"
									noEntriesFoundLabel="{{translate('No data found')}}" ngModel
									(ngModelChange)="onChangeCompany($event)">
								</ngx-mat-select-search>
							</mat-option>
							<mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
								{{isRtl()?company.NameAr:company.Name}}</mat-option>
						</mat-select>
					</mat-form-field> 

				<!-- <custom-menu [items]="this.roles"
				class="width100 form-field" [displayPath]="'name'" 
				[title]="translate('Roles')" [icon]="'place'" 
				(onChange)="getRoleUser($event.name)"></custom-menu> -->
			</div>
	    </div>
		
		<mat-divider></mat-divider>
		<div class="table-responsive">
			<kendo-grid 
			#grid
 
			[data]="view | async"
			[pageSize]="state.take"
			[skip]="state.skip"
			[sort]="state.sort"
			[pageable]="true"
			   
			(dataStateChange)="dataStateChange($event)"
			     class="kendo-grid-list" 
				  [resizable]="true"
				  [reorderable]="true"
				  filterable="menu"  
				  [sortable]="true" 
				  [kendoGridSelectBy]="selectBy"
				  [columnMenu]="true" >
				   
				  <kendo-grid-column   title="{{ 'Id'| translate }}" [columnMenu]="false"    width="70" headerClass="grid-list-column-header" > 
					<ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  
				  <kendo-grid-column field="Code" title="{{ 'Code' | translate }} " filterable="menu"  headerClass="grid-list-column-header" width="100"> </kendo-grid-column>
				  <kendo-grid-column field="Name" title="{{ 'Name' | translate }} " filterable="menu" headerClass="grid-list-column-header" width="300" > </kendo-grid-column>
				  
				   
				  <kendo-grid-column field="Telephone" title="{{ 'Telephone' | translate }} "  filterable="menu" headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="BrokerEmail" title="{{ 'Email' | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
				  
				  <kendo-grid-column field="Suspended" title="{{ 'Status' | translate }}  "  filterable="menu" filterable="menu" headerClass="grid-list-column-header"width="120"
				    > 
						<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
							<span *ngIf="dataItem.Status==StatusEnum.Active" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
							<span *ngIf="dataItem.Status==StatusEnum.InActive" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
						</ng-template>
				  </kendo-grid-column>
				
				  <kendo-grid-column   title=""  width="80"  
				  headerClass="grid-list-column-header" 
				  *ngIf="auth.isGranted(_permissions.AssignBrokerToCompanies)
				  ||auth.isGranted(_permissions.DeleteBrokers)"
				   >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"  [columnMenu]="false"  >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
					   aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							   
							<a mat-menu-item   
							     (click)="changePropertiesStatus(dataItem)"   >
						     	{{ 'Change Status' | translate }} 
					       </a>
							<a mat-menu-item  
							[routerLink]="['/brokers/edit/'+ dataItem.BrokerId ]"  
							*ngIf="auth.isGranted(_permissions.AssignBrokerToCompanies)"  >

							  {{ 'View'| translate }}
							</a>
							<a mat-menu-item   (click)="deleteBroker(dataItem)"  
						    	*ngIf="auth.isGranted(_permissions.DeleteBrokers)"  >
					           {{ 'Delete'| translate }}
							  </a> 
						 </mat-menu>			 
						</ng-template>	   
				   </kendo-grid-column>
		 </kendo-grid>
			        
		</div>
 
	</mat-card>
</div>