export class RoleDto {
    id: string;
    name: string;
    normalizedName: string;
    concurrencyStamp: string;
}


export class UseRoleDto {
    userId: string;
    rolesName: string[]; 
}

export class RoleClaimDto {
    name: string;
    id:string;
    claims: any[]; 
}

export class ClaimDto {
    Node: string;
    Items: ClaimItemDto[]; 
}


export class ClaimItemDto {
    Name: string;
    Value: string; 
    IsSuperAdmin:boolean;
}