export class GoldOrderDto {
    OrderType: number;
    SymbolID: number;
    OrderValue: number;
    OrderDate: any;
    OrderQty: number;
    OrderPrice: number; 
    ClientID: number;
    BrokerID: number;
    CompanyID: number;
    BrokerCode: string;
    CompanyCode: string;
    CreatedUserId: string;
    CalcMethod: string;
    IndicativePrice: number;
    ClientNameAr: string;
    ClientNameEn: string;
    SymbolNameAr: string;
    SymbolNameEn: string;
    ClientCode: string;
}

export class GlobalMarketWatchesDto {
    Id: number;
    SymbolId: number;
    BidPrice: number;
    AskPrice: number;
    Currency: string;
    Change: number;
    PercentageChange: number;
    PriceUnit: string;
    LastUpdated: string;
    LastUpdatedDate: Date;
}