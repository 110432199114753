<form [formGroup]="addNewCalendarForm" class="popup-card-width add-new-user">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

					<div class="padding-input-form">


						<section class="width100 form-field" *ngIf="!isUpdate"> 
							<mat-radio-group    >
							<mat-radio-button class="radio-button" value="0" [checked]='true'
							(change)="radioChange($event)"  >{{ 'All' | translate }}</mat-radio-button>
							<mat-radio-button class="radio-button" value="1"
							(change)="radioChange($event)">{{ 'Select Market' | translate }}</mat-radio-button>
							</mat-radio-group>
						 </section>
						 <br/>
						 <div *ngIf="showMarket==1">

							<mat-form-field class="width50 form-field" appearance="outline">
								<mat-label> {{ 'Main Market' | translate }}</mat-label>
								<mat-select  [(ngModel)]="mainMarketId"  name="mainMarketId"
								(selectionChange)="onChangeMainMarket($event)"
									  formControlName="mainMarketId"  
									  [disabled]="isUpdate"  > 
									 <mat-option  *ngFor="let market of  mainMarkets " [value]="market.Id">
	
									  <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
									 <span 	*ngIf="isRtl()">{{market.NameAr}}</span> 
									  </mat-option>
								</mat-select> 
							 </mat-form-field>
	
							  
							 <mat-form-field class="width50 form-field" appearance="outline">
								<mat-label> {{ 'Sub Market' | translate }}</mat-label>
								<mat-select  [(ngModel)]="calendar.MarketId"  name="Market"
								[disabled]="isUpdate" 
								
									  formControlName="MarketId"  > 
									 <mat-option  *ngFor="let market of  subMarkets" [value]="market.Id">
	
									  <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
									 <span 	*ngIf="isRtl()">{{market.NameAr}}</span> 
									  </mat-option>
								</mat-select> 
							 </mat-form-field>
						 </div>


						 <mat-form-field class=" width100 form-field" appearance="outline">
							<mat-label>{{ 'Pick Date' | translate }} </mat-label>
							<mat-date-range-input  [rangePicker]="picker">
								<input matStartDate [(ngModel)]="calendar.StartDate"
								formControlName="StartDate" placeholder="Start date">
								<input matEndDate  [(ngModel)]="calendar.EndDate" 
								formControlName="EndDate" placeholder="End date">
							  </mat-date-range-input>
							<mat-datepicker-toggle color="accent" matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>


						<mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{ 'Description' | translate }} </mat-label>
							<input class="form-control" matInput type="input"
							placeholder="{{'Description'  | translate }} " formControlName="Description"
							[(ngModel)]="calendar.Description">
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none">
		<!-- <div *ngFor="let error of errors">
			<label class="error-label">{{error}}</label>
			<br />
		</div> -->
		<button (click)="onFormSubmit()"
		*ngIf=" auth.isGranted(_permissions.UpdateMarketSettings) "  type="submit" [disabled]="!addNewCalendarForm.valid" mat-raised-button
			color="warn">{{'SUBMIT' | translate }} </button>
		<button type="button" mat-raised-button (click)="close()" color="primary">{{'CLOSE' | translate }} </button>

	</mat-dialog-actions>
</form>