<div class="user-manage-list"  >
	<mat-card>

		<div class="">

			<mat-tab-group color="accent" class="dashboard-container" >
				<mat-tab label="{{'Users'| translate }}"  >
					<ms-user-list  ></ms-user-list>
			   </mat-tab>
			   <mat-tab label="{{'Roles'| translate }}"   >
				   <ms-role-list  ></ms-role-list>

				</mat-tab>
				<mat-tab label="{{'User Sessions'| translate }}"   >
					<ms-user-session-list  ></ms-user-session-list >
 
				 </mat-tab>
	   
	           </mat-tab-group>
			 

            
		</div>
 
	</mat-card>
</div>