import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Inject, Injectable, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { AdminService } from 'app/admin/services/admin.service';
import { AppComponentBase } from 'app/app-component-base';
import { AppConsts } from 'app/core/config';
import { permissions } from 'app/core/permission';
import { ClaimDto, ClaimItemDto, RoleClaimDto } from 'app/models/role';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

/**
 * Node for to-do item
 */
export class TodoItemNode {
  Items: TodoItemNode[];
  Node: string;
  Value: string;
  IsSelected: boolean;
  IsSuperAdmin: boolean;
}


/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string;
  Value: string;
  IsSelected: boolean;
  IsSuperAdmin: boolean;
  level: number;
  expandable: boolean;
}





/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'ms-role-form-dialog',
  templateUrl: './role-form-dialog.component.html',
  styleUrls: ['./role-form-dialog.component.scss']
})
export class RoleFormDialogComponent extends AppComponentBase implements OnInit {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  title: string;
  isAdmin:any;
  role: RoleClaimDto = new RoleClaimDto();
  addNewRoleForm: FormGroup;
  statusLst: any;
  claims: ClaimDto[];
  nodes: TodoItemNode[];
  errors: any;
  claimLst: any[] = new Array();
  companyCode: any;
  isUpdate: boolean;
  RoleTypeList: any[] = new Array();
  RoleType:string;
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
 
  initialize() {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    
    const data = this.buildFileTree(this.nodes, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {

    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {

      const value = obj[key].Items;
      const node = new TodoItemNode();
      node.Node = obj[key].Node;
      node.IsSelected = obj[key].IsSelected;
      node.IsSuperAdmin= obj[key].IsSuperAdmin;
      node.Value = obj[key].Value;
      if (value != null) {
        if (typeof value === 'object') {
          node.Items = this.buildFileTree(value, level + 1);
        } else {
          node.Node = value;
        }
      }
      return accumulator.concat(node);

    }, []);
  }


  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<RoleFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private roleData: any
    , private adminService: AdminService
    , injector: Injector
    ) {
      super(injector); 

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.dataChange.subscribe(data => {

      this.dataSource.data = data;
 

    });

    if (roleData != null && roleData.role != null) {

      this.role = roleData.role;

      this.title = "Update Role - " + this.role.name;
      this.isUpdate = true;
      this.getClaims(this.role.id);
      if(this.role.name==AppConsts.superAdminRole){
           this.isAdmin=true;
      }
    } else {
      this.title = "ADD NEW ROLE"
      this.isUpdate = false;
      this.getClaims(null);
      //  this.company.Code=  this.generateCode(this.data.count);
    }


  }




  getClaims(id:any) {
    var roleClaims= [];
    if(id!=null){
      this.adminService.getRoleClaims(id).subscribe(res=>{
  
        if(res!=null){
          roleClaims.push(res);
        }
        this.getRoleClaims(roleClaims); 
      });
    }else{
      this.getRoleClaims(roleClaims); 
    }

  }


  getRoleClaims(roleClaims:any){
 
    this.adminService.getAllClaims().subscribe(res => {
      this.claims = res.claims;
      this.nodes = [];
      this. RoleTypeList=[];
      if(this.isAdmin){
        let claimItemDto=new ClaimItemDto();
        claimItemDto.Name=AppConsts.superAdminRole;
        claimItemDto.Value="0";
        this. RoleTypeList.push(claimItemDto);
        this.RoleType="0";
      }
      this.claims.forEach(element => {

        var node = new TodoItemNode();
        node.Node = element.Node;
        node.Items = [];
        node.IsSelected = false;
        element.Items.forEach(elementItem => {
   
          if(elementItem.Value==permissions.ISBroker
          ||elementItem.Value==permissions.ISEMX
          ||elementItem.Value==permissions.ISStoreKeeper
          ||elementItem.Value==permissions.ISCooperative
          ||elementItem.Value==permissions.ISCooperativeManager
          ){
              if (roleClaims!=null &&roleClaims.length>0 &&roleClaims[0].filter(x=>x== elementItem.Value).length>0) {
                 this.RoleType=elementItem.Value;
              }  
              this. RoleTypeList.push(elementItem);
           }else{ 

            var subNode = new TodoItemNode();
            subNode.Node = elementItem.Name;
            subNode.Value = elementItem.Value;
            subNode.IsSuperAdmin= elementItem.IsSuperAdmin;
            if (roleClaims!=null &&roleClaims.length>0&&roleClaims[0].filter(x=>x== elementItem.Value).length>0) {

              subNode.IsSelected = true; 
            } else {
              subNode.IsSelected = false; 
            } 
            node.Items.push(subNode);
           }
        });
        this.nodes.push(node);
      });


      this.initialize();
  });
  }
 


  ngOnInit(): void {

    this.addNewRoleForm = new FormGroup({
      Name: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      PermissionsName: new FormControl({ value: '' }),
      RoleName: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });
  }
  close() {
    this.dialogRef.close(false);
  }

  onFormSubmit() {
    let selectedList=this.checklistSelection .selected;
    this.errors = [];
    this.role.claims=[];
  
    selectedList.forEach(element => {
  
      if(element.Value!=null){
        
        this.role.claims.push(element.Value);
      }
    });
    if(!this.isAdmin){

      this.role.claims.push(this.RoleType);
    }else{ 
      this.role.claims.push(permissions.ISEMX);
    }
    this.adminService.addRoleWithClaims(this.role).subscribe(() => {

      this.dialogRef.close(this.addNewRoleForm.value);

      this.toastr.success("Successfully!");
    },
      err => {

        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);
      });
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.Items;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
  
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.Node
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.Node;
    flatNode.level = level;

    flatNode.expandable = !!node.Items?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    flatNode.IsSelected = node.IsSelected;
    if(node.IsSelected){
      this.todoItemSelectionToggle(flatNode);
    }
    flatNode.IsSuperAdmin= node.IsSuperAdmin;
    flatNode.Value = node.Value;
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
 
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
 
     
      return this.checklistSelection.isSelected(child);
    });
   
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {

    node.IsSelected=!node.IsSelected;
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
 
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
 
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {

    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    node.IsSelected =!node.IsSelected;
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}