import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
 
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout'; 
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LockScreenComponent } from './lockscreen/lockscreen.component';;
 
import { AccountInterceptor } from './Account.interceptor';

import { authStrategyProvider } from './services/auth.strategy';
import { AccountRoutes } from './Account.routing';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OtpComponent } from './components/otp-dialog/otp.component';
import{ChangePasswordComponent} from './change-password/change-password.component';
import{ConfirmComponent}from './confirm/confirm.component';
import { WidgetComponentModule } from 'app/widget-component/widget-component.module';
import{NewPasswordComponent} from './new-password/new-password.component';
 
@NgModule({
	declarations: [
		LoginComponent,
		RegisterComponent,
		ForgotPasswordComponent,
		ChangePasswordComponent,
		NewPasswordComponent,
		LockScreenComponent,  
		OtpComponent ,
		ConfirmComponent
	],
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		FlexLayoutModule,
		MatCardModule,
		MatButtonModule,
		MatIconModule,
		MatToolbarModule,
		MatCheckboxModule,
		MatDividerModule,
		FormsModule,
		TranslateModule,
		ReactiveFormsModule,
		RouterModule.forChild(AccountRoutes),
		ToastrModule.forRoot(),
	
		WidgetComponentModule
	] 
	,
  providers: [
    { 
      provide: HTTP_INTERCEPTORS,
      useClass: AccountInterceptor,
      multi: true
    },
    authStrategyProvider
  ]
})
export class AccountModule { }
