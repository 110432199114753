<!-- #region news Slider -->
<ngx-slick-carousel class="slider-height" [config]="sliderConfig">
    <div class="pad-x-sm ticker-item-wrap" ngxSlickItem *ngFor="let newsItem of news">
           <div class="price-wrap">
           
              <p class="font-bold message-slider" (click)="viewNews(newsItem)" *ngIf="newsItem != null">
                    
         
               
                <span  *ngIf="isRtl()"  style="direction: rtl;" [innerHTML]="newsItem.HeadrAr"> </span>
                <span	*ngIf="!isRtl()"  style="direction: rtl;" [innerHTML]="newsItem.HeaderEn"></span>

                
                
            </p>
        </div>
     </div>
  </ngx-slick-carousel>
  
<!-- #endregion -->