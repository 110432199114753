<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
 
 
				<div class="mrgn-l-sm mrgn-r-sm">
					<h4>{{title| translate}}</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<mat-card-actions class="mat-pad-none margin-none"
				 *ngIf="!(_marketId!=null)">
					<button mat-raised-button mat-button-sm color="primary"
					*ngIf="auth.isGranted(_permissions.AddMarkets)"
					(click)="addNewMarketDialog()" >  {{ 'ADD MAIN MARKET' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</mat-card-actions>

				<mat-card-actions class="mat-pad-none margin-none"
				*ngIf="_marketId!=null">
				   <button mat-raised-button mat-button-sm color="primary"
				   *ngIf="auth.isGranted(_permissions.AddMarkets)"
				   [routerLink]=  "['/markets/create-sub/'+ _marketId ]" >  {{ 'ADD SUB MARKET' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
			   </mat-card-actions>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="master_detials_kendo">

			<kendo-grid 
			      [kendoGridBinding]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > <kendo-grid-column   title="{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
                  
				  <kendo-grid-column field="Code" title="{{ 'Code' | translate }}"  headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="NameEn" title="{{ 'Name English' | translate }}"  headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="NameAr" title="{{ 'Name Arabic' | translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>
                  <kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>

				  <kendo-grid-column field="CreatedDate" title= "{{ 'Date Created' | translate }}"  headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <div *kendoGridDetailTemplate="let dataItem"  
				 >
    
					<ms-market-manage-list *ngIf="!(_marketId!=null)"
					marketId='{{dataItem.Id}}' 
					 > </ms-market-manage-list>
				  </div>



				  <kendo-grid-column   title=""   
				  headerClass="grid-list-column-header"
				  *ngIf="auth.isGranted(_permissions.GetMarketSettings) 
				  || auth.isGranted(_permissions.AssignMarketsToSymbols)
				  ||auth.isGranted(_permissions.DeleteMarkets)" >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item
						   (click)="editMarket(dataItem)"    >
								{{ 'View' | translate }}
							  </a>
							<!-- <a mat-menu-item    (click)="GetMarketSettings(dataItem)" >
								{{ 'Settings' | translate }}
							</a>

							<a mat-menu-item    	
							[routerLink]="['/markets/market-commission/'+dataItem.Id]"   
						  >
						  {{ 'Commissions' | translate }}
							  </a> 

							<a mat-menu-item    	 [routerLink]="['/markets/market-symbols/'+dataItem.Id]"  
							*ngIf="auth.isGranted(_permissions.AssignMarketsToSymbols)"  >
							{{ 'Symbols' | translate }}
							  </a>  -->

							  <!-- <a mat-menu-item  *ngIf="auth.isGranted(_permissions.DeleteMarkets)  "  (click)="deleteMarket(dataItem)"   >
								{{ 'Delete' | translate }}
						   </a> -->
							 
						   
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>



 
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>