import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
import { TradeDto } from 'app/models/trade';
import { OrderReuqest } from 'app/models/order';

@Injectable()
export class AuctionApi {

    constructor(private http: HttpClient) { }

    GetAuctionsLog(request: OrderReuqest): Observable< any> {
      let queryParameters: string = this.convertObjectToQueryParameters(request);
      return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/GetAuctionsLog`+queryParameters);
    } 

    getAuctions(id:any): Observable<[]> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getActiveAuctionView/`+id);
    }
    getCompanySymbolsByCompanyId(id:any): Observable<[]> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.brokerUrl}/getCompanySymbolsByCompanyId/`+id);
    }
    getAuctionView(id: any): Observable<any> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionViewbyId/` + id);
    }
    insertAuction(createOrderRequest: any): Observable<boolean> {

        return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/insertAuction`, createOrderRequest)
            .pipe(tap(data => { }));
    }
    updateAuction(orderRequest: any, id: any): Observable<boolean> {

        return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/updateAuction/` + id, orderRequest)
            .pipe(tap(data => { }));
    }

    matchAuction(orderRequest: any, id: any): Observable<boolean> {

        return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/matchAuction/` + id, orderRequest)
            .pipe(tap(data => { }));
    }
    preMatchAuction(orderRequest: any, id: any): Observable<boolean> {

        return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/preMatchAuction/` + id, orderRequest)
            .pipe(tap(data => { }));
    }
    adjustmentAuction(orderRequest: any, id: any): Observable<boolean> {

        return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/adjustmentAuction/` + id, orderRequest)
            .pipe(tap(data => { }));
    }
    getAuctionOrderId(id: any): Observable<any> {

        return this.http.get<any>
            (`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionOrderId`
                + '/' + id);
    }
    getAuctionStatistics(id: any): Observable<any> {

        return this.http.get<any>
            (`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionStatistics`
                + '/' + id);
    }

    unadjustmentAuction(orderRequest: any, id: any): Observable<boolean> {

        return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/unadjustmentAuction/` + id, orderRequest)
            .pipe(tap(data => { }));
    }
    deleteAuction(id: any): Observable<boolean> {

        return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/deleteAuction/` + id)
            .pipe(tap(data => { }));
    }
    GetAuctionTranactions(id: any): Observable<any> {

        return this.http.get<any>
            (`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/GetAuctionTranactions`
                + '/' + id);
    }
    getTrades(Skip: any, Take: any): Observable<[TradeDto]> {

        return this.http.get<[TradeDto]>
            (`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getTrades`
                + '?Skip=' + Skip + '&Take=' + Take + '&AuctionTradesOnly=' + true);
    }

    getCompanyTrades(Skip: any, Take: any, companyId: any): Observable<[TradeDto]> {

        return this.http.get<[TradeDto]>
            (`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getCompanyTrades`
                + '?Skip=' + Skip + '&Take=' + Take + '&CompanyId=' + companyId + '&AuctionTradesOnly=' + true);
    }



    getBids(AuctionId: any, companyId: any, isEMX: any): Observable<[]> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getBids?AuctionId=`
            + AuctionId + '&CompanyId=' + companyId + '&AllCompanies=' + isEMX);
    }
    getAsks(AuctionId: any, companyId: any, isEMX: any): Observable<[]> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getAsks?AuctionId=`
            + AuctionId + '&CompanyId=' + companyId + '&AllCompanies=' + isEMX);
    }

    getAuction(id: any): Observable<any> {

        return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuction/` + id);
    }
    getOrdersAuctionByDate(acutionId: any, Skip: any, Take: any): Observable<[]> {

        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionOrderWatches?` +
            'Skip=' + Skip + '&Take=' + Take + '&AuctionId=' + acutionId
        )
    }

     getMyOrdersActionByDate( companyId :any ,acutionId:any,Skip :any ,Take :any ): Observable<[]> {
   
      return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionOrderWatches?`
      +'Skip='+Skip +'&Take='+Take
      +'&CompanyId='+companyId+'&AuctionId='+acutionId+'&AllBrokers=true');
     }
 getAuctionpendingTrades(acutionId: any, Skip: any, Take: any): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionpendingTrades?AuctionId=`
        + acutionId + '&Skip=' + Skip + '&Take=' + Take);
}

updatependingTrade(orderRequest: any): Observable<boolean> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/updatependingTrade/` , orderRequest)
        .pipe(tap(data => { }));
}
deletependingTrade(id: any): Observable<any> {
 
    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/deletependingTrade/`+id )
        .pipe(tap(data => { }));
}

savePreexecutedTrade(id: any): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/savePreexecutedTrade/` + id)
        .pipe(tap(data => { }));
}

 convertObjectToQueryParameters(request): string {
    let queryParameters: string = "";
    for (let property in request) {
        if (request[property] != null)
            queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
    }
    if (queryParameters == "")
        return "";
    return queryParameters
  }

  insertAuctionNews(request: any): Observable<any> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/insertAuctionNews/`, request)
        .pipe(tap(data => { }));
}

getAuctionNews(acutionId: any): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/GetAuctionNews/` + acutionId );
}
}
 