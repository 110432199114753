import { Component, EventEmitter, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'app/account/services/auth.service';
import { AppComponentBase } from 'app/app-component-base';
import { AppConsts } from 'app/core/config';
import { AnnouncementTypeEnum, EntityTypeEnum } from 'app/core/enums';
import { AnnouncementDto, AnnouncementRequestDto } from 'app/models/announcement';
import { FileAttachmentDto, QueueAttachmentDto, UploadAttachmentTypeDto } from 'app/models/client';
 
import { SetupApi } from 'app/Shared/api/setup.api';
import { FileItem, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

@Component({
  selector: 'ms-order-attachment',
  templateUrl: './order-attachment.component.html',
  styleUrls: ['./order-attachment.component.scss']
})
export class OrderAttachmentComponent extends AppComponentBase implements OnInit {
  /* #region declaration*/
 
  uploader: FileUploader ;
  private _uploaderOptions: FileUploaderOptions = {};
  OrderID: any;
  selectBy:any;
  attachments: any; 
  token: any; 
  SymbolCode: any;
  title: string;
  onlyDownload:true;
  //amount: number = 0;

  fileAttachmentDto:FileAttachmentDto= new FileAttachmentDto(); 
  /* #endregion */

  /* #region constructor & on in it */
  constructor(  private dialogRef: MatDialogRef<OrderAttachmentComponent>,
                // inject mat dialog if present
                @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
                private authService: AuthService ,
                private setupApi :SetupApi,
                injector: Injector ) {

    super(injector);

    if(data != null)
    {
        if (data.OrderID != 0 && data.SymbolCode != null ) {
          this.OrderID = data.OrderID;
          this.SymbolCode = data.SymbolCode;
          this.title = this.translate('Order Attachments') +' ' + this.OrderID + ' - ' + this.SymbolCode;
        }

        if(data.OnlyDownload!=null){
          this.onlyDownload=data.OnlyDownload;
        }
    }

      if(this.onlyDownload==true){
        this.title = this.translate('Download Order Attachments') +' ' + this.SymbolCode;
      }
   }

  public ngOnInit(): void {

      this.token=  this.authService.getToken( );
      this.getOrderAttachments();
      this.  initFileUploader();
      this.uploader.onCompleteAll = () => {
        this.getOrderAttachments();
      };
  }
  /* #endregion */
  
  /* #region  methods */
  close() {
    this.dialogRef.close(false);
  }
 
  public onFileSelected(event: EventEmitter<File[]>) {

   const element = event[0];
   var item=new UploadAttachmentTypeDto();
   item.Name=  element.name;
   this. attachments.push(item); 
  }

  getOrderAttachments() {
  
    this.setupApi.GetOrderAttachments(this.OrderID).subscribe(items => {
      this.attachments = items;  
    });
  }

  saveFiles(){

      this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
    
          form.append('EntityType', EntityTypeEnum.Order );
          form.append('EntityId', this.OrderID); 
          if(this.uploader.queue != null && this.uploader.queue.length == 1)
          {
           // form.append('Amount', this.amount);  
          }
      };
      this.uploader.uploadAll();    
   //   this.amount = 0; 
  }

  saveFile(item :any){

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
  
      form.append('EntityType', EntityTypeEnum.Order );
      form.append('EntityId', this.OrderID); 
     // form.append('Amount', this.amount); 
   };
   this.uploader.uploadItem(item);
   //this.amount = 0; 
  }
 
  downloadAttachment(filename){ 

    this.fileAttachmentDto.filename=filename;
    this.fileAttachmentDto.EntityType=EntityTypeEnum.Order  ;
 
        this.setupApi.DownloadAttachment(this.fileAttachmentDto);
  }

  ViewAttachment(filename ){ 

    this.fileAttachmentDto.filename=filename;
    this. fileAttachmentDto.  EntityType=EntityTypeEnum.Order;
 
        this.setupApi.ViewAttachment(this.fileAttachmentDto);
  }

  deleteAttachment(data ){ 
    
    this.fileAttachmentDto.Id=data.Id;
    this.fileAttachmentDto.filename=data.AttachmentPath;
    this.  fileAttachmentDto.  EntityType=EntityTypeEnum.Order;
 
        this.setupApi.DeleteOrderAttachment(data.Id,data.AttachmentPath).subscribe(  re=>{

          this.getOrderAttachments();
          } );
  }
  /* #endregion */

  initFileUploader(): void {
 
    this.uploader = new FileUploader(
      {
        url: `${AppConsts.bussinessApiUrl}` + `${AppConsts.orderUrl}` +'/UpdateOrderAttachment',      
       });

    this._uploaderOptions.autoUpload = false;
    this._uploaderOptions.authToken = 'Bearer ' +this.token;

    this._uploaderOptions.removeAfterUpload = true;
    this.uploader.onAfterAddingFile = (file) => {
        file.withCredentials = false;
    }; 

    this.uploader.setOptions(this._uploaderOptions);
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => { 
     var queue=new QueueAttachmentDto();
 
   };
 
 }
}
