<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >

			   </div>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="start center">				
                    <div class="trade-crypto crypto-input-prefix">
                        <div class="padding-input-form">

                          <mat-form-field class="padding10 form-field"  appearance="outline"  *ngIf="!this.isBroker()" >
                            <mat-label>{{ 'Trading Company' | translate }}</mat-label>
                              <mat-select 							        
                                  [(ngModel)]="filterOptions.CompanyId"
                                  name="CompanyID" 
                                  class="filter-select" 
                                  [placeholder]="'Company' | translate "
                                  (selectionChange)="onChangeCompany($event);">
                                  <mat-label>{{ 'Company' | translate }}</mat-label>	
                              <mat-option>								
                                <ngx-mat-select-search name="companySearch" 
                                  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                               >
                                </ngx-mat-select-search>
                                <ngx-mat-select-filter></ngx-mat-select-filter>
                              </mat-option>
                                <mat-option >	{{ 'All' | translate }} </mat-option>
                                  <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                    {{isRtl()?company.NameAr:company.Name}}
                                </mat-option>
                              </mat-select> 
                          </mat-form-field>
                    
                            <mat-form-field appearance="outline"  class="padding10 form-field">
                               <mat-label>{{ 'Client' | translate }}</mat-label>
                                <mat-select    
                                   [(ngModel)]="filterOptions.ClientId" 
                                    name="ClientID"	 
                                    class="filter-select"
                                   [placeholder]="'Client' | translate "
                                 >
                                  <mat-label>{{ 'Client' | translate }}</mat-label>								
                                  <mat-option>								
                                    <ngx-mat-select-search name="clientSearch" 
                                      placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                      (ngModelChange)="changeClientFilter($event)">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                    <mat-option > {{ 'All' | translate }} </mat-option>
                                      <mat-option *ngFor="let client of clients" [value]="client.Id">
                                        {{isRtl()?client.NameAr:client.NameEn}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
                  <mat-card-actions class="mat-pad-none margin-none">
                    <a mat-raised-button color="" (click)="getList()" title=" {{ 'Search' | translate }}" > <mat-icon  >search</mat-icon></a>	
                  </mat-card-actions>
                </div>
             </div>
 
	

		 </div>
        </mat-card> 
        <mat-card> 

			<br/>
	<kendo-grid   #grid
      [data]="view | async"
	  [filterable]="false" 
	  [resizable]="true"
	  [reorderable]="true"
	  filterable="menu"  
	  [sortable]="true" 
	  [kendoGridSelectBy]="selectBy"
	  [columnMenu]="true"
    class="market-kendo-grid"
	[rowClass]="rowCallback"

	[pageSize]="state.take"
	[skip]="state.skip"
	[sort]="state.sort"
 
	[pageable]="true"
 
	(dataStateChange)="dataStateChange($event)" > 

  <ng-template kendoGridToolbarTemplate>
    <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
  </ng-template>
  <kendo-grid-excel fileName="UnSettled Client.xlsx" > </kendo-grid-excel>
				   <kendo-grid-column   title="{{ 'ID' | translate }}  " 
				   [class]="{ codeColumn: true }"
				   [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					 <span> {{(rowIndex+1)}}</span>	
					</ng-template>
				   </kendo-grid-column>


       <kendo-grid-column field="CompanyCode"  title="{{ 'Company Code' | translate }} "
           headerClass="grid-list-column-header" width="150" >
         <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                       <span class="client-name">{{dataItem.CompanyCode}} </span>        
         </ng-template>
         <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Company Code'| translate }} </ng-template>
     
       </kendo-grid-column>


    <kendo-grid-column field="CompanyNameAr"  title="{{ 'Company Name' | translate }} "
       headerClass="grid-list-column-header" width="250" >
     <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">   
                   <span class="client-name" *ngIf="isRtl()"  >{{dataItem.CompanyNameAr}} </span>
                   <span class="client-name" *ngIf="!isRtl()" >{{dataItem.CompanyNameEn }} </span>   
     </ng-template>
     <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Company Name'| translate }} </ng-template>
   </kendo-grid-column>


   <kendo-grid-column field="IdentityTypeText"  title="{{ 'Client Code' | translate }} "
   headerClass="grid-list-column-header" width="250" >
 <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="client-name" >{{dataItem.IdentityTypeText}} </span> 
 </ng-template>
 <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Code'| translate }} </ng-template>
</kendo-grid-column>


     <kendo-grid-column field="ClientNameAr"  title="{{ 'Client Name' | translate }} "
       headerClass="grid-list-column-header" width="250" >
       <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                   <span class="client-name"  *ngIf="isRtl()">{{dataItem.ClientNameAr}} </span>
                   <span class="client-name"  *ngIf="!isRtl()">{{dataItem.ClientNameEn }} </span>
        </ng-template>
         <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Name'| translate }} </ng-template>
      </kendo-grid-column>




        <!-- <kendo-grid-column field="CompanyNameAr"  title="{{ 'Company Name' | translate }} "
           headerClass="grid-list-column-header" width="250" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                    <span class="client-name"  *ngIf="isRtl()">{{dataItem.CompanyNameAr}} </span>
                    <span class="client-name"  *ngIf="!isRtl()">{{dataItem.CompanyNameEn }} </span>
        </ng-template>
        <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Company Name'| translate }} </ng-template>
       </kendo-grid-column> -->


  

        <kendo-grid-column field="Balance"  title="{{ 'Balance' | translate }} "  headerClass="grid-list-column-header" width="100" >
             <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.Balance "  >{{dataItem.Balance | number }} </span>
             </ng-template>
              <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Balance'| translate }} </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-column field="NoSettlement"  title="{{ 'NoSettlement' | translate }} "  headerClass="grid-list-column-header" width="100" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span  >{{dataItem.NoSettlement  }} </span>
          </ng-template>
           <ng-template kendoGridHeaderTemplate   let-column>  {{ 'NoSettlement'| translate }} </ng-template>
       </kendo-grid-column>

         <kendo-grid-column field="CurrencyCode"  title="{{ 'Currency' | translate }} " headerClass="grid-list-column-header" width="100" >
           <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.CurrencyCode }} </span>
               </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Currency'| translate }} </ng-template>
        </kendo-grid-column>
               
        <kendo-grid-column field="SettlementDate"  title="{{ 'Settlement Date' | translate }} " headerClass="grid-list-column-header" width="100" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="client-name"  >{{dataItem.SettlementDate| date: 'dd/MM/yyyy' }} </span>
              </ng-template>
           <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Settlement Date'| translate }} </ng-template>
       </kendo-grid-column>
        
       
       <div *kendoGridDetailTemplate="let dataItem">
        <ms-unSettlement-trades   [client]='dataItem.ClientId'> </ms-unSettlement-trades >
     </div>   
		 </kendo-grid> 
	</mat-card>
</div>