import {Component, Optional, ViewEncapsulation} from '@angular/core';
import { TranslateService} from '@ngx-translate/core';

import { AppInitService } from './app-init.service';
import { IdleService } from './core/userIdle.service';

@Component({
  	selector: 'commTrade-app',
   template:`<router-outlet></router-outlet>
   ` 
})

export class CommTradeAppComponent {
   constructor(translate: TranslateService , public idleService : IdleService
      ) {
     const browserLang: string = translate.getBrowserLang();

     
       translate.addLangs(['en', 'fr', 'ar' ]);
 
  
       translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
       
   } 
  
    ngOnInit(): void {
   
      this.idleService.intiateIdleTimeout();
    }
}
