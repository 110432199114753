<form [formGroup]="storeSectionForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{translate("Add Section")}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
                       <mat-form-field class="width50 form-field " appearance="outline">
                          <mat-label>{{'English Name'|translate}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{'English Name'|translate}}" 
                            formControlName="SectionNameEn"  
                            [(ngModel)]="storeSection.SectionNameEn"  >  
                      </mat-form-field>

					             <mat-form-field class="width50 form-field" appearance="outline">
                        <mat-label>{{'Arabic Name'|translate}}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{'Arabic Name'|translate}}" 
                           formControlName="SectionnameAr"  
                           [(ngModel)]="storeSection.SectionnameAr"  > 
                       </mat-form-field>

                       <mat-form-field class="width50 form-field " appearance="outline">
                        <mat-label>{{'Area'|translate}}</mat-label>
                        <input class="form-control" matInput type="number" placeholder="{{'Section Area'|translate}}" 
                           formControlName="SectionArea"  
                           [(ngModel)]="storeSection.SectionArea"  > 
                       </mat-form-field>


   <mat-form-field class="width50 form-field " appearance="outline">
                            <mat-label>{{'Type'|translate}}</mat-label>
                            <mat-select  [(ngModel)]="storeSection.StoreTypeID"  name="StoreTypeID"
                                 formControlName="StoreTypeID"  > 
                                <mat-option  *ngFor="let type of  StoreTypes" [value]="type.StoreTypeID">
                                 {{isRtl()?type.StoreTypeNameAr: type.StoreTypeNameEn}}</mat-option>
                            </mat-select> 
                         </mat-form-field> 


					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
  	<button   (click)="onFormSubmit()" type="submit"  [disabled]="!storeSectionForm.valid"    mat-raised-button color="warn">{{'SUBMIT'|translate}}</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{'CLOSE'|translate}}</button>
	
	</mat-dialog-actions>
</form>