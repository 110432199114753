<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">

			<div fxLayout="row" fxLayoutAlign="start center">
 
				<div class="mrgn-l-sm mrgn-r-sm">
					<mat-form-field>
						<mat-select placeholder="{{translate('Filter')}}" >
							<mat-option *ngFor="let action of actions; let i = index" value="{{i}}"
										(click)="getWarehouses((i+1))">{{translate(action)}}</mat-option>
 
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" style="margin-left:auto !important;padding-right:10px;padding-right: 10px;">
				<mat-card-actions class="mat-pad-none margin-none">
					<a mat-raised-button mat-button-sm color="primary" 
					*ngIf="auth.isGranted(_permissions.AddStores)" 
				 
					[routerLink]="['/warehouses/create/']" >{{translate('ADD NEW WAREHOUSE')}}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></a>
				</mat-card-actions>
			</div>

		</div>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  class="kendo-grid-list" 
				  > 
				   <kendo-grid-column   title="{{translate('Id')}}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="StoreNameEn" title="{{translate('Name English')}}" headerClass="grid-list-column-header" w  > </kendo-grid-column>
				  <kendo-grid-column field="StoreNameAr" title="{{translate('Name Arabic')}}" headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="Location" title="{{translate('Location')}}" headerClass="grid-list-column-header" > </kendo-grid-column>
			

 

				  <kendo-grid-column field="CreatedDate" title="{{translate('Date Created')}}" headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <kendo-grid-column field="" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.Suspended!=true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.Suspended==true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header"
				  *ngIf="auth.isGranted(_permissions.EditStores)|| auth.isGranted(_permissions.DeleteStores)">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" 
					>
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item   
							
							[routerLink]="['/warehouses/Edit/'+ dataItem.StoreID]"  *ngIf="auth.isGranted(_permissions.EditStores)"  >
								{{translate('View')}}
							</a>
							<a mat-menu-item (click)="deleteWarehouse(dataItem)" *ngIf="auth.isGranted(_permissions.DeleteStores)">
								{{translate('Delete')}}
							</a>
						 				  
							<a mat-menu-item   
							(click)="changePropertiesStatus(dataItem)"   >
							{{ 'Change Status' | translate }} 
					   </a>
					   
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>