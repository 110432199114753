import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardParamService {

    public loadMarketId: BehaviorSubject<string> = new BehaviorSubject<string>(null );
    public loadMarketTitle: BehaviorSubject<string> = new BehaviorSubject<string>(null );
    public loadMarketStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null );
    public loadIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null );
    setMarketId(id: any) {
        this.loadMarketId.next(id);
    }
    getMarketId() {
        return this.loadMarketId;
    }


    setIsOpen(id: any) {
        this.loadIsOpen.next(id);
    }
    getIsOpen() {
        return this.loadIsOpen;
    }

    
    setMarketTitle(title: any) {
        this.loadMarketTitle.next(title);
    }
    getMarketTitle() {
        return this.loadMarketTitle;
    }

    setMarketStatus(status: any) {
        this.loadMarketStatus.next(status);
    }
    getMarketStatus() {
        return this.loadMarketStatus;
    }
}