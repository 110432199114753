
<form [formGroup]="addNewSymbolForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form"> 

						<mat-form-field  form-field class=" width50  form-field " appearance="outline">
							<mat-label>{{'Symbol'  | translate }}</mat-label>
							<mat-select  [(ngModel)]="symbolMarket.SymbolId"  name="SymbolId"
								  formControlName="SymbolId"  	  [disabled]='isUpdate' > 
								 <mat-option  *ngFor="let symbol of  symbols" [value]="symbol.Id">
								  {{symbol.NameEn}}</mat-option>
						   </mat-select>
						</mat-form-field>

 
						<mat-form-field  form-field class=" width50  form-field " appearance="outline">
							<mat-label>{{'Open'  | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Open Price" 
							  formControlName="OpenPrice"  
							  (input)="changeOpenPrice()"
							  [(ngModel)]="symbolMarket.OpenPrice"  >  
						</mat-form-field>
						<mat-form-field  form-field class=" width50  form-field " appearance="outline">
							<mat-label>{{'Upper%'  | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Upper %" 
							  formControlName="UpperLPerc"  
							  (input)="calUpperValue()"
							  [(ngModel)]="symbolMarket.UpperLPerc"  >  
						</mat-form-field>

                       <mat-form-field  form-field class=" width50  form-field " appearance="outline">
                          <mat-label>{{'Upper Value'  | translate }}</mat-label>
                        <input class="form-control" matInput type="number" placeholder="Upper Value" 
                            formControlName="UpperLValue"  

                            [(ngModel)]="symbolMarket.UpperLValue"  >  
                      </mat-form-field>


					  <mat-form-field  form-field class=" width50  form-field " appearance="outline">
						<mat-label> {{'Lower%'  | translate }}</mat-label>
					  <input class="form-control" matInput type="number" placeholder="Lower %" 
						  formControlName="LowerLPerc"  
						  (input)="calLowerValue()"
						  [(ngModel)]="symbolMarket.LowerLPerc"  >  
					</mat-form-field>

				    <mat-form-field  form-field class=" width50  form-field " appearance="outline">
						<mat-label>  {{'Lower Value'  | translate }}</mat-label>
					  <input class="form-control" matInput type="number" placeholder="Lower Value" 
						  formControlName="LowerLValue"  
						  [(ngModel)]="symbolMarket.LowerLValue"  >  
					</mat-form-field>








 
					<mat-form-field  form-field class=" width50  form-field " appearance="outline">
						<mat-label>{{'Unlock'  | translate }} </mat-label>
						<mat-select  [(ngModel)]="symbolMarket.Locked"  name="locked"
							  formControlName="locked"  > 
				
							  
							  <mat-option  *ngFor="let locked of  lockedLst" [value]="locked.id">
							  {{locked.name}}</mat-option>
					    </mat-select>
					</mat-form-field>

					  
			 
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit" [disabled]="!addNewSymbolForm.valid"  
		 mat-raised-button color="warn"> {{'SUBMIT'  | translate }} </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary"> {{'CLOSE'  | translate }}</button>
	
	</mat-dialog-actions>
</form>