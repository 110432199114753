<div
  id="main-app"
  class="app sidebar-color-dark header-color-dark"
  [dir]="layout"
  [ngClass]="{'app-dark': dark, 'collapsed-sidebar': coreService.collapseSidebar, 'compact-sidebar': compactSidebar, 'customizer-in': customizerIn}"
  [class.side-panel-opened]="coreService.sidenavOpen"
  [class.side-panel-closed]="!coreService.sidenavOpen"
>
  <!-- #region Container-->
  <mat-sidenav-container class="gene-container">
    <!-- #region Side Nav -->
    <mat-sidenav
      #sidenav
      [(mode)]="coreService.sidenavMode"
      [(opened)]="coreService.sidenavOpen"
      class="sidebar-area"
    >
      <ms-side-bar
        [menuList]="menuItems"
        [verticalMenuStatus]="true"
      ></ms-side-bar>
    </mat-sidenav>
    <!-- #endregion -->
    <!-- #region nav toolBar -->
    <mat-toolbar class="gene-header-toolbar">
      <div class="gene-ham-icon" tourAnchor="start.tour">
        <button
          class=""
          mat-mini-fab
          color="primary"
          mat-card-icon
          (click)="toggleSidebar()"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div fxFlex.gt-sm="20" fxFlex.gt-md="20" fxFlex.gt-xs="20" fxFlex="20"  >
        <div *ngIf="isDashboard!=true">
          <a  class="navbar-brand">{{header|translate}}</a>
            <div *ngIf="header=='Auctions' || header=='Gold Market Dashboard' ">{{localTime}}</div>
          <!-- <breadcrumb class="gene-breadcrumbs"></breadcrumb> -->
        </div>
        
       
        <div
          class="gene-card-content crm-progress-wrap"
          *ngIf="isDashboard==true && !auth.isGranted(_permissions.ISStoreKeeper)"
        >
          <mat-list class="mat-pad-none">
            <mat-list-item
              *ngFor="let status of marketStatusItem"
              class="header-title"
            >
              <div class="full-wid">
                <div
                  class="full-wid pad-b-sm pad-t-xs"
                  fxLayout="row"
                  fxLayoutAlign="space-between start"
                >
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="crypto-duration {{status.color}} inline-block"
                    >
                      <span *ngIf="isRtl()">
                        {{market.NameAr}} - {{status.duration | translate}}
                      </span>
                      <span *ngIf="!isRtl()">
                        {{market.NameEn}} - {{status.duration | translate}}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <breadcrumb
          class="gene-breadcrumbs"
          *ngIf="isDashboard==true"
        ></breadcrumb>
      </div>
      <div fxFlex.gt-sm="60" fxFlex.gt-md="60" fxFlex.gt-xs="60" fxFlex="60">
        <mat-tab-group
          color="accent"
          [ngClass]="{'market-tab-rtl': isRtl(), 'market-tab' : !isRtl()  }"
          *ngIf="isDashboard==true 	&&auth.isGranted(_permissions.GetMarketwatCh) && markets != undefined && markets.length>0"
          (selectedTabChange)="onTabChanged($event);"
        >
          <mat-tab *ngFor="let market of markets">
            <ng-template mat-tab-label>
              <span *ngIf="isRtl()" class="tab-label"> {{market.NameAr}} </span>
              <span *ngIf="!isRtl()" class="tab-label">  {{market.NameEn}}  </span>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

      <span fxFlex></span>

      <div class="secondary-menu">
        <button
          fxHide="true"
          fxHide.gt-sm="false"
          mat-button
          class="fullscreen-toggle"
          (click)="toggleFullscreen()"
          tourAnchor="tour-full-screen"
        >
          <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
          <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
        </button>

 
        <button (click)="end.toggle()" mat-icon-button class="overflow-visible"
        *ngIf="auth.isGranted(_permissions.EnableNotification)">
          <mat-icon>notifications</mat-icon>
        </button>



        <ms-language-drop-down  (layoutEmitter)="getLayout($event)" ></ms-language-drop-down>
        <button mat-icon-button class="overflow-visible" (click)="chatMenu()"
        *ngIf="auth.isGranted(_permissions.EnableChat)">
          <mat-icon>chat</mat-icon>
        </button>
        <button class="user-button" mat-button [matMenuTriggerFor]="menu">
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon>account_circle</mat-icon>
          </div>
        </button>
      </div>
    </mat-toolbar>
    <!-- #endregion -->

    <!-- #region Side notification Toolbar -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-toolbar class="primary-bg">
          <h3>{{ 'Announcment' | translate }}</h3>
        </mat-toolbar>
        <mat-nav-list>

          <mat-list-item  (click)="viewNews(item)"    class="notifcation"  
          *ngFor="let item of announcements; let i=index" >
            <h5 mat-line class="flex-item">
              <span *ngIf="item.SymbolCode != null">({{item.SymbolCode}})</span>
                    {{item.CreatedDate | date: 'h:mm:ss a'}}
            </h5>
            <mat-icon mat-list-icon class="flex-item"> email </mat-icon>
            <p><span  *ngIf="isRtl()" [innerHTML]="item.HeadrAr"> </span>
            <span  *ngIf="!isRtl()" [innerHTML]="item.HeaderEn"> </span></p>
            <mat-divider></mat-divider>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>
    <!-- #endregion -->

    <!-- #region Base active Container -->
    <div class="gene-base-container" #scrollContainer>
      <div fxLayout="row wrap">
        <div class="full-wid" fxFlex="100">
          <router-outlet
            (activate)="onActivate($event, scrollContainer)"
          ></router-outlet>

          <ng-container>
            <tour-step-template>
              <ng-template let-step="step">
                <div
                  (click)="$event.stopPropagation()"
                  class="popover bs-popover-bottom"
                >
                  <div class="popover-header">{{step?.title}}</div>
                  <div class="popover-body">
                    <p class="tour-step-content">{{step?.content}}</p>
                  </div>
                  <div class="tour-step-navigation">
                    <button
                      mat-icon-button
                      [disabled]="!tourService.hasPrev(step)"
                      (click)="tourService.prev()"
                    >
                      <mat-icon>chevron_left</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      [disabled]="!tourService.hasNext(step)"
                      (click)="tourService.next()"
                    >
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                    <button mat-button (click)="tourService.end()">End</button>
                  </div>
                </div>
              </ng-template>
            </tour-step-template>
          </ng-container>
        </div>
        <div class="footer-wrap pad-t-none full-wid" fxFLex="100">
          <ms-footer></ms-footer>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </mat-sidenav-container>
  <!-- #endregion -->
  <div class="gene-customizer">
    <!-- #region Setting Panel -->
    <div class="settings-panel">
      <mat-toolbar color="primary">
        <span fxFlex>{{'Options' | translate }}</span>
        <button
          (click)="customizerFunction()"
          color="primary"
          type="button"
          mat-raised-button
        >
          <span>{{'CLOSE'|translate}}</span>
        </button>
      </mat-toolbar>
      <mat-card-content class="theme-options">
        <h3>{{'Layout Options'| translate }}</h3>
        <mat-divider></mat-divider>
        <div class="collapse-option">
          <mat-checkbox (change)="collapseSidebar($event)" 
            >{{'Collapsed Sidebar'| translate }}</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox
            [(ngModel)]="dark"
            (change)="addClassOnBody($event)"
         
            >{{'Dark Mode'| translate }}</mat-checkbox
          >
        </div>
        <div>
          <mat-checkbox (change)="changeRTL($event.checked)"
            >{{'RTL'| translate }}</mat-checkbox
          >
        </div>

        <div class="inner-toolbar" fxHide fxShow.gt-md>
          <div class="theme-color">
            <div class="text-center mrgn-b-md">
              <h4>{{'Sidebar Filters'|translate}}</h4>
            </div>
            <div class="gene-list" fxLayoutAlign="center center">
              <div *ngFor="let filter of sideBarFilterClass; let i=index">
                <a class="gene-list-link" (click)="sidebarFilter(filter)">
                  <span
                    [ngClass]="{'radius-circle': i == 0 }"
                    id="{{filter.sideBarSelect}}"
                  ></span>
                </a>
              </div>
            </div>
          </div>
          <div class="theme-color">
            <div class="text-center mrgn-b-md">
              <h4 class="theme-light-color">{{'Header Filters'|translate}}</h4>
            </div>
            <div class="gene-list" fxLayoutAlign="center center">
              <div *ngFor="let filter of headerFilterClass; let i=index">
                <a class="gene-list-link" (click)="headerFilter(filter)">
                  <span
                    [ngClass]="{'radius-active': i == 0 }"
                    id="{{filter.headerSelect}}"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #region Chat Panel -->
  <div class="gene-chat-wrap" id="gene-chat">
    <mat-card class="people-list">
      <mat-tab-group class="height-full">
        <mat-tab label="Chat" class="height-full">
          <div class="gene-card-content height-full">
            <div class="chat-people-list height-full">
              <mat-list role="list">
                <ng-container *ngFor="let chat of chatList">
                  <mat-list-item role="listitem" (click)="onChatOpen()">
                    <div
                      class="gene-list-item full-wid"
                      fxLayout="row wrap"
                      fxLayoutAlign="space-around center"
                    >
                      <div class="image-wrap" fxFlex="50px" class="mrgn-r-sm">
                        <img src="{{chat.image}}" alt="User" />
                      </div>
                      <div class="people-desc" fxFlex="calc(100% - 100px)">
                        <h4 class="margin-none font-normal make-ellipse">
                          {{chat.name}}
                        </h4>
                        <span class="gene-text-md inline-block make-ellipse"
                          >{{chat.chat}}</span
                        >
                      </div>
                      <div fxFlex="30px">
                        <div
                          class="show-status mat-grey-300 {{chat.mode}}"
                        ></div>
                      </div>
                    </div>
                  </mat-list-item>
                  <mat-divider class="mrgn-y-xs gene-relative"></mat-divider>
                </ng-container>
              </mat-list>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
    <mat-card class="chat-window" id="chat-open">
      <div
        class="chat-header primary-bg"
        fxLayout="row wrap"
        fxLayoutAlign="space-between center"
      >
        <div class="profile-wrap" fxFlex="calc(100% - 100px)">
          <div fxLayoutAlign="start center">
            <div class="image-wrap" fxFlex="40px" class="mrgn-r-xs">
              <img src="assets/img/user-1.jpg" width="" height="" alt="" />
            </div>
            <div fxFlex="calc(100% - 40px)">
              <h5 class="margin-none make-ellipse">Youssef Yassin</h5>
              <span class="status-text gene-block gene-text-sm">Online</span>
            </div>
          </div>
        </div>
        <div
          class="icons-wrap text-right"
          fxFlex="100px"
          fxLayoutAlign="end center"
        >
          <a href="javascript:void(0)">
            <i class="material-icons inline-block">phone</i></a
          >
          <a href="javascript:void(0)">
            <i class="material-icons inline-block">videocam</i></a
          >
          <a href="javascript:void(0)">
            <i
              class="material-icons inline-block margin-none"
              (click)="chatWindowClose()"
              >close</i
            ></a
          >
        </div>
      </div>
      <mat-card-content class="gene-card-content">
        <div class="chat-block-wrap">
          <!-- <div class="chat-block sender-chat mrgn-b-sm">
            <p class="mat-grey-300">
              Hi There! Recently I updated the latest version of your app, it
              crashed every time when i open.Need Assistance
            </p>
            <span class="sent-time gene-text-md gene-block">11:20pm</span>
          </div> -->
          <div class="chat-block receiver-chat text-right mrgn-b-sm">
            <p class="mat-grey-300">
              Hello, welcome to EMX thank you for visiting our website. How can we assist you
            </p>
            <!-- <span class="sent-time gene-text-md gene-block">12:00am</span> -->
          </div>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions class="gene-card-footer">
        <mat-form-field class="full-wid">
          <input matInput placeholder="Type Your message" />
          <mat-icon matSuffix>send</mat-icon>
        </mat-form-field>
      </mat-card-actions>
    </mat-card>
  </div>
  <!-- #endregion -->
</div>
<!-- #region Menu Setting -->
<mat-menu
  class="user-menu"
  x-position="before"
  y-position="below"
  #menu="matMenu"
>
  <a mat-menu-item [routerLink]="['/users/profile']">
    <mat-icon>account_circle</mat-icon>
    <span>{{'Profile'|translate}}</span>
  </a>
  <button mat-menu-item (click)="customizerFunction()">
    <mat-icon>settings</mat-icon>
    <span>{{'Settings'|translate}}</span>
  </button>
  <button mat-menu-item>
    <mat-icon>help</mat-icon>
    <span>{{'Help'|translate}}</span>
  </button>
  <mat-divider></mat-divider>
  <button (click)="logOut()" mat-menu-item>
    <mat-icon>exit_to_app</mat-icon>
    <span>{{'Logout'|translate}}</span>
  </button>
</mat-menu>
<!-- #endregion -->

