import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../core/directive/directives.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { NgxChartsModule } from "@swimlane/ngx-charts";


import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
 

import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
 
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { DatePipe } from "@angular/common";
import { OrderHistoryComponent } from "./components/order-history/order-history.component";
import { NewsBySymbolComponent } from './components/news-by-symbol/news-by-symbol.component';
import { TickerSliderComponent } from './components/ticker-slider/ticker-slider.component';

import{SharedModule} from '../Shared/shared.module';

 import { MatButtonToggleModule } from '@angular/material/button-toggle';
 
import { LayoutModule } from "@progress/kendo-angular-layout";
import { OrderAttachmentComponent } from './components/order-attachment/order-attachment.component';
import { FileUploadModule } from 'ng2-file-upload';
import { OrderManagementApi } from "./api/order-management.api";
 import { OrderManagementService } from "./services/order-management.service";
import { NewsSliderComponent } from './components/news-slider/news-slider.component';
import { CooperativeManagementModule } from "app/cooperative-management/cooperative-management.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    PerfectScrollbarModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    MatSortModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    MatExpansionModule,
    NgxDatatableModule,
    FlexLayoutModule,
    MatOptionModule,
    MatSelectModule, 
    MatPaginatorModule,
    ReactiveFormsModule,
    MatInputModule, 
    GridModule,
    ExcelModule,
    SlickCarouselModule,
    DragDropModule,
    ResizableModule,
    SharedModule,MatButtonToggleModule,
     
    LayoutModule ,
    FileUploadModule
  ],
  declarations: [
     OrderHistoryComponent,NewsBySymbolComponent, 
     NewsSliderComponent ,   TickerSliderComponent,OrderAttachmentComponent
  ] ,
  exports: [
 
    OrderHistoryComponent    ,
    TickerSliderComponent,
      NewsBySymbolComponent,
      NewsSliderComponent ,OrderAttachmentComponent
     
  ],

  providers: [
    DatePipe,
    OrderManagementService,
    OrderManagementApi, 
    
  ],
})
export class OrderManagementModule {}
