import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators'; 
import{CommissionDto} from '../../models/commission'

@Injectable()
export class CommissionManagementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}


  getCommissions(): Observable<[]> { 
    return this.http.get<[]>(`${this.API_URL}/getCommissions`);
  }  
  GetSymbolMarketCommissionList( symbolId :any): Observable< []> {
    return this.http.get< any>(`${this.API_URL}/GetSymbolMarketCommissionList/`+symbolId);
  }

  getCommission(id:any): Observable<CommissionDto> { 
    return this.http.get<CommissionDto>(`${this.API_URL}/getCommission/`+id);
  } 
  insertCommission(createCommissionRequest: any): Observable<boolean> {

     return this.http.post<any>(`${this.API_URL}/insertCommission`, 
     createCommissionRequest)
       .pipe(tap(data => {}));
  }
  
  updateCommission(CommissionRequest: any,id:any): Observable<boolean> {
 
    return this.http.put<any>(`${this.API_URL}/updateCommission/`+id, 
    CommissionRequest)
      .pipe(tap(data => {}));
  }

  deleteCommission(id: any): Observable<any> {

    return this.http.delete<any>(`${this.API_URL}/deleteCommission/`+id).pipe(tap(data => {}));
  } 

  GetMarketCommission( id :any ): Observable< any> {
    return this.http.get< any>(`${this.API_URL}/GetMarketCommissions/`+id);
  }
  GetMarketCommissionByCommissionId( id :any ,commissionId:any): Observable< any> {
    return this.http.get< any>(`${this.API_URL}/GetMarketCommissionByCommissionId/`+id+'/'+commissionId);
  }

  UpdateMarketCommission(CommissionRequest: any,id:any): Observable<any> {
 
    return this.http.put<any>(`${this.API_URL}/addMarketCommission/`+id, 
    CommissionRequest)
      .pipe(tap(data => {}));
  }

}