import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators'; 
import{BankDto} from '../../models/bank'

@Injectable()
export class MonitorServicesApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.  orderUrl}`;
  private readonly Monitor_API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.monitorUrl}`;

  constructor(private http: HttpClient) {}


  RunCalculateCommissions(): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/RunCalc_Commission`);
  }
  RunEndOfPreOpenOrder(): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/RunEndOfPreOpenOrder`);
  }
  RunEndOfDay(): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/RunEndOfDay`);
  }
  CalcSetteledBalances(): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/CalcSetteledBalances`);
  }
  getMonitorServicesLog(id): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/getMonitorServicesLog/`+id);
  }
  getMonitorServices(): Observable<any> { 
    return this.http.get<any>(`${this.API_URL}/getMonitorServices/`);
  }
}