import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { AuthService } from 'app/account/services/auth.service';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { EMXUserDto } from 'app/models/EMX';
import { AppConsts } from 'app/core/config';
import { NationalityDto } from 'app/models/StatusLookup';
import {  Router } from '@angular/router';
import {  IdentityTypeEnum } from 'app/core/enums';
import { EnumHelpers } from 'app/helper/EnumHelpers';



@Component({

	selector: 'ms-userprofile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends AppComponentBase implements OnInit {
	
	userProfile: any; 
	userInfo: any; 
	role: any; 
	isAdmin: boolean = false;
	nationality: any=new NationalityDto();
	errors: string[] = [];
	public userId: string;
	password : string;
	passwordConfirm: string;
	identityTypeTxt: string = "NationalID";
    isDisabled:boolean = true;
	IdentityTypeEnumList:any;
	constructor(private pageTitleService: PageTitleService,
		private setupApi: SetupApi,
		private authService: AuthService,
		private router: Router,
		private _helper :EnumHelpers,
		injector: Injector
	) {
		super(injector);
	    this.authService.getUserRoles$().subscribe((res) => {
			this.role = res; 
			if(this.role == AppConsts.superAdminRole){
			    this.isAdmin=true;
	      }
		});
		this.IdentityTypeEnumList=this. _helper.getNamesAndValues(IdentityTypeEnum);
	}

ngOnInit() {

	this.authService.getCurrentUser$().subscribe((res) => {
		this.userInfo = res; 	
		this.userId = res.id

	});

		if (this.isBroker()) {
			this.setupApi.getBrokerInfo().subscribe(res => {
				if(res != null)
                {
					this.getNationalityById(res.NationalityID);
					this.getIdentityType(res.IdentityTypeID);
					this.userProfile=
						{
							NameAr: res.Name,
							NameEn: res.Name,
							AdressAr: res.Address,
							AddressEn: res.Address,
							Telephone: res.Telephone,				
							Email: res.Email,
							DateofBirth: res.DateofBirth,			
							Identity: res.IdentityTypeText
							// Nationality: res.Nationality,
							// Department: Department,
						   // JobTitle: JobTitle,
					};
				}	
			});

		} 

		else if (this.isEMX() && !this.isAdmin) {
			this.setupApi.getEMXUserInfo().subscribe(res => {
				if(res != null)
                {
					this.getIdentityType(res.IdentityTypeID);
                    this.getNationalityById(res.NationalityID);
					this.userProfile=
						{
							NameAr: res.Name,
							NameEn: res.Name,
							AdressAr: res.Address,
							AddressEn: res.Address,
							Telephone: res.Telephone, 
							Email: res.Email,
							DateofBirth: res.DateofBirth,				
							Identity: res.IdentityTypeText
							//	Department: Department,
							//	JobTitle: JobTitle,
						};
				}
				
			});
		}

		else if (this.isCooperativeManager()) {	
			this.setupApi.getCooperativeManagerInfo().subscribe(res => {
				if(res != null)
                {
				  this.getIdentityType(res.IdentityTypeID);
				  this.getNationalityById(res.NationalityID);
				  this.userProfile=
						{
							NameAr: res.NameAr,
							NameEn: res.Name,
							CityNameAr: res.CityNameAr,
							CityNameEn: res.CityNameEn,
							AddressEn: res.AddressEn,
							AddressAr: res.AdressAr,
							Telephone: res.Telephone,
							Email :res.Email,
							DateofBirth: res.DateofBirth,	
							Identity: res.IdentityTypeText

						};
				}

				
			});
		} 

		else if (this.isStoreKeeper()) {
		}

		else if(this.isAdmin)
		{
			this.userProfile= 	
						{
							NameAr: this.userInfo.userName,
							NameEn: this.userInfo.userName,
							AdressAr: "",
							AddressEn: "",
							Telephone: "",
							Email: this.userInfo.email,
							DateofBirth: "",
							Identity: ""
							// Department: Department,
						   // JobTitle: JobTitle,
					};
			this.nationality.NationalityEn = "Egyptian";	
			this.nationality.NationalityAr = "مصري";
			
 
	
		}

		this.pageTitleService.setTitle("User Profile");
	}


	getNationalityById(id:any){

		this.setupApi.getNationalityById(id).subscribe((res) => { 
			this.nationality = res; 

		});
	}

	getIdentityType(value){ 

		let identity=this.IdentityTypeEnumList.filter(x=>x.id==value);
		if(identity!=null && identity.length>0)
        {
			this.identityTypeTxt = identity[0].name; 
	    }

		// this.setupApi. getIdentityTypeById(value).subscribe(res => { 
		//   this.identityTypeTxtEn = res.IdentityTypeEn; 
		//   this.identityTypeTxtAr = res.IdentityTypeAr;
		//  });
	}

	setPassword(value) {

	    this.errors=[];
	    value.userId = this.userId;
		this.authService.setNewPassword(value).subscribe(res=>{

			this.isDisabled = true;
			this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
				this.router.navigate(['users/profile']);
			}); 
			this.toastr.success("Successfully!");
			//this.router.navigate(['users/profile']);
		},
		err => {
			this.toastr.error(err.error[0]);
			this.errors.push(err.error[0]);
			this.password = "";
			this.passwordConfirm = "";
		}
	  );
	}

	changePass(){
		this.isDisabled = false;
	}

	close(){
        this.isDisabled = true;
	}
}
