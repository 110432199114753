 
import { Routes } from '@angular/router';  
import{MonitorServicesComponent} from './containers/monitor-services-list/monitor-services.component';
import{MonitorServicesLogComponent}from './containers/monitor-services-log/monitor-services-log.component';
export const MonitorServicesRoutes: Routes = [
  {
     path: '',
     redirectTo: 'monitor_services',
     pathMatch: 'full' 
  },
  {
     path: '',
     children: [
        {
        
           path: 'monitor_services',
           component:  MonitorServicesComponent
        }  ,
        {
        
         path: 'monitor_services-log',
         component:  MonitorServicesLogComponent
      }  
     ]
  }
];