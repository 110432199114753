<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div class="mrgn-r-sm">
					<h4> {{ title | translate }}</h4>
				</div>
				<div class="mrgn-l-sm mrgn-r-sm">
 
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

				
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="monitorServices" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  class="kendo-grid-list" 
				  > 
				  

				  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(dataItem.Id  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="ServicesName" title="{{'Name'| translate }}" headerClass="grid-list-column-header" width="250"  >
				
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(dataItem.ServicesName | translate )}}
					</ng-template>
				</kendo-grid-column>
				  <kendo-grid-column field="ServicesDescription" title="{{'Description'| translate }}" headerClass="grid-list-column-header"  width="150"  > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(dataItem.ServicesDescription| translate  )}}
					</ng-template>
				  </kendo-grid-column>
				  
				  <kendo-grid-column field="LastUpdatedDate" title="{{'Date'| translate }}" headerClass="grid-list-column-header"  width="150"  > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(dataItem.LastUpdatedDate | date: 'dd/MM/yyyy'  )}}
					</ng-template>
				  </kendo-grid-column>

				  <kendo-grid-column field="LastUpdatedDate" title="{{'Time'| translate }}" headerClass="grid-list-column-header"  width="300"  > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(dataItem.LastUpdatedDate | date: 'h:mm:ss a'  )}}
					</ng-template>
				  </kendo-grid-column>
				   
				<kendo-grid-column title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					  <button *ngIf="!isDateEqualToNow(dataItem.LastUpdatedDate)" mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						<mat-menu #anchorMenu="matMenu">
						<a mat-menu-item   (click)="run(dataItem.Id)">
							{{'Run'| translate }}
						</a>
						</mat-menu>
					 
						<span *ngIf="isDateEqualToNow(dataItem.LastUpdatedDate)">Is Running Today</span>

					</ng-template>
				   
				</kendo-grid-column>

		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>