import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from 'app/app-component-base';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { ClientService } from 'app/client-managment/services/client.service';
import {  UserTypeEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { BankDto } from 'app/models/bank';
import { ClientBankDto, ClientCommentDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
 import { ClientCompanyFormDialogComponent } from '../client-company-form-dialog/client-company-form-dialog.component';

@Component({
  selector: 'ms-client-comments-dialog',
  templateUrl: './client-comments-dialog.component.html',
  styleUrls: ['./client-comments-dialog.component.scss']
})
export class ClientCommentsDialogComponent extends   AppComponentBase implements OnInit, OnDestroy  {

  ngOnDestroy() {

  }
  
  UserTypeEnum =UserTypeEnum;
  comments:any;
  private _clientId: number;
  errors: any;
  addNewClientForm: FormGroup;
  clientComment :ClientCommentDto= new ClientCommentDto();
  approveStatus:any;
 
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ClientCommentsDialogComponent>,
    private route: ActivatedRoute,
    private _setupApi:SetupApi,
    private _enumHelpers :EnumHelpers   , 
    private bankManagementService: BankManagementService,
    private clientService: ClientService   , injector: Injector,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ) {
      
      super(injector);  

      if (data.clientId != null) {
        this._clientId = Number(data.clientId);
        this.getClientCommentsById(  this._clientId );
 
      }
    
    }

 
  ngOnInit(): void {
 
    if(this.isStoreKeeper()){ 
      this._setupApi .getStoreKeeperInfo().subscribe(res=>{
        if(res!=null){

         this.clientComment.CreatedUserType=UserTypeEnum.Store; 
         this.  seenComments();
        }
      }) 

    }
    else    if(this. isBroker()) { 
      this.getUserBrokerInfo().subscribe(res=>{
        if(res!=null){
   
         this.clientComment. CompanyId=res.CompanyId; 
         this.clientComment.CreatedUserType=UserTypeEnum. TradingCompany; 
         this.  seenComments();
        }
      }) 

    }else{ 
      this.clientService.GetCompanyClientByClientId(this._clientId).subscribe(res=>{
       
        if(res!=null){
          this.clientComment.CompanyId=res[0].CompanyId; 
        }
      }) ;
      this.clientComment.CreatedUserType=UserTypeEnum.EMX; 
      this.  seenComments();
    }
    this.addNewClientForm= new FormGroup({ 
      Comment:   new FormControl({ value: '' }, Validators.compose([Validators.required])), 
    });
 
  }
  
  getClientCommentsById(id){
   this.clientService.getClientCommentsById(id).subscribe(res=>{
    this.comments=res;
   
   });


  }

  seenComments(){
    this.clientService.seenComments(this._clientId,this.clientComment.CreatedUserType)
    .subscribe((res) => { } );
  }

    
  onFormSubmit() {
    this.errors=[]; 
    this.clientComment.ClientId=Number(this._clientId) ; 
    this.clientService.addClientComment(this.clientComment
       ,this._clientId).subscribe(() => {
         this.getClientCommentsById(this._clientId);
         this.clientComment.Comment=null;
         
    },
      err => {
        this.errors.push(  this.translate(err.error[0]));
        this.toastr.error(  this.translate(err.error[0]));
    });

  }

  close() {
    this.dialogRef.close(false);
  }

 
}
