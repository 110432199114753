
<form [formGroup]="addNewMarketForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 

						<mat-form-field class="Width50 form-field " appearance="outline">
                            <mat-label>{{'Market Code'  | translate }} </mat-label>
                          <input class="form-control"
						   matInput type="input" 
						   [readonly]="this.isUpdate" 
						   placeholder="{{'Market Code'  | translate }} " 
                              formControlName="Code"  
						 
                              [(ngModel)]="market.Code"  >  
                        </mat-form-field>
 
                        <mat-form-field class="Width100 form-field " appearance="outline">
                            <mat-label>{{'Market Name English'  | translate }} </mat-label>
                          <input class="form-control"
						   matInput type="input" 
						 
						   placeholder="{{'Market Name English'  | translate }} " 
                              formControlName="NameEn"  
							  [readonly]="isreadonly"
                              [(ngModel)]="market.NameEn"  >  
                        </mat-form-field>


                        <mat-form-field class="Width100 form-field " appearance="outline">
                            <mat-label>{{'Market Name Arabic'  | translate }}  </mat-label>
                          <input class="form-control" matInput type="input" placeholder="{{'Market Name Arabic'  | translate }} " 
                              formControlName="NameAr"  
						
							  [readonly]="isreadonly"
                              [(ngModel)]="market.NameAr"  >  
                        </mat-form-field>

						


						
						<mat-form-field class="Width50 form-field " appearance="outline">
							<mat-label>{{'Status'  | translate }} </mat-label>
							<mat-select  [(ngModel)]="market.IsActive"  name="IsActive"
								  formControlName="IsActive" 
								  [disabled]='isreadonly' 
								  > 
								 <mat-option  *ngFor="let status of  statusLst" [value]="status.id">
								  {{status.name}}</mat-option>
						   </mat-select>
						</mat-form-field>

			 
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()"
		*ngIf=" auth.isGranted(_permissions.EditMarkets) "   type="submit" 
		[disabled]="!addNewMarketForm.valid"   mat-raised-button color="warn">{{'SUBMIT'  | translate }} </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{'CLOSE'  | translate }} </button>
	
	</mat-dialog-actions>
</form>