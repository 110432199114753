<button mat-raised-button [matMenuTriggerFor]="ourMenu" style="font-size:small;color:rgb(94, 97, 100);font-family: sans-serif !important;" class="hover-blue">

  <mat-icon>{{icon}}</mat-icon>
  {{ selectedItem[displayPath] == null? 
         title:translate(selectedItem[displayPath])}}
</button>

<mat-menu #ourMenu="matMenu">

    <button *ngIf="!mandatory" class="custom-menu-btn" mat-menu-item (click)="onChangeItem({})">
    <span>{{ translate('All')}}</span>
  </button>

    <button  style="max-height: 200px;" class="custom-menu-btn" *ngFor="let item of items" mat-menu-item (click)="onChangeItem(item)">
    <span>{{translate(item[displayPath])}} 
      <mat-icon style="color: dodgerblue !important;" *ngIf="item==selectedItem"
        inline="true">done</mat-icon></span>
  </button>

</mat-menu>