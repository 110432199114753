
			<kendo-grid 
			[data]="items" 
			[resizable]="true"
			[reorderable]="true"
			[filterable]="false"   
			class="kendo-grid-list" 
			[sortable]="true"
			> 
			 <kendo-grid-column   title="Id"  headerClass="grid-list-column-header" width="40" > 
			  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
				  {{(rowIndex+1  )}}
			  </ng-template>
			</kendo-grid-column>

			<kendo-grid-column field="KeeperNameEn" title="{{translate('Name English')}}" headerClass="grid-list-column-header"> 
			</kendo-grid-column>

			<kendo-grid-column field="KeeperNameAr" title="{{translate('Name Arabic')}}" headerClass="grid-list-column-header"> 
		  </kendo-grid-column>


		  <kendo-grid-column field="Email" title="{{translate('Email')}}" headerClass="grid-list-column-header"> 
		</kendo-grid-column>

		  <kendo-grid-column field="Telephone" title="Telephone" headerClass="grid-list-column-header"> 
		  </kendo-grid-column>

		  <kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
				<span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
				<span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
			</ng-template>
		  </kendo-grid-column>


			 <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">

			  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
				<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
				  <mat-icon>menu</mat-icon>
				</button>
				   <mat-menu #anchorMenu="matMenu">
					   <a mat-menu-item (click)="edit(dataItem)" *ngIf="auth.isGranted(this._permissions.EditStoreKeeper)">
						   {{translate("View")}} 
					   </a>
					  <a mat-menu-item   (click)="delete(dataItem)" *ngIf="this._permissions.DeleteStoreKeeper" >
						  {{translate("Delete")}}
				   </a>
				   				  
				   <a mat-menu-item   
				   (click)="changePropertiesStatus(dataItem)"   >
				   {{ 'Change Status' | translate }} 
			  </a>
			  
				   </mat-menu>
			   
				  </ng-template>
			 
			 </kendo-grid-column> 
   </kendo-grid> 
   <br/>
<div fxLayout="row" fxLayoutAlign="start">

	<button  *ngIf="auth.isGranted(this._permissions.AddStoreKeeper)" (click)="add()" mat-raised-button
		color="warn">	 {{'Add Store Keeper'|translate}}</button>

	<a [routerLink]="['/warehouses/warehouses/']" mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

</div>