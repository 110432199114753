
import { MSGType } from './../../../core/enums';
import { StoreDto } from 'app/models/store';
import { warehouseManagmentRoutes } from '../../warehouse-managment.routing';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ClientReuqest, SearchRequestDto, StoreClientDto } from 'app/models/client';
import { ClientApi } from '../../../client-managment/api/client.api';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from 'app/client-managment/services/client.service';
import { CompanyFormDialogComponent } from 'app/company-management/components/company-form-dialog/company-form-dialog.component';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { StatusEnum } from 'app/core/enums';
import {  StoreKeeperDto, TransactionDto, TransactionLookupDto } from 'app/models/warehouse';
import { ToastrService } from 'ngx-toastr';
import { symbol } from 'd3';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppComponentBase } from 'app/app-component-base';

@Component({
  selector: 'ms-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent extends AppComponentBase implements OnInit {


  title: string;
  transactionDto: TransactionDto = new TransactionDto();
  filteredLookups: TransactionLookupDto = new TransactionLookupDto();
  lookups : TransactionLookupDto = new TransactionLookupDto(); 
  isViewOnly:boolean = false;
  transactionTypeId:number;
  markets:any;
  blockSellOrderForm: FormGroup;
  errors:any;
  companyCode:any;
  storeKeeper: any = new StoreKeeperDto();
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TransactionComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _warehouseApi:WarehouseApi,
    private _setupApi:SetupApi,
    private clientService: ClientService ,
    injector:Injector
    ) { 
      super(injector)
      this.isViewOnly = this.data.isViewOnly;
      this.filteredLookups.Clients = [];
      if(this.data.dto!=null)
      {
        this.transactionDto = this.data.dto
        this.filteredLookups.Clients = [this.transactionDto.Client]
        this.filteredLookups.Symbols = [this.transactionDto.Symbol]
      }
      else{
        this.transactionDto.CreatedDate = new Date().toLocaleDateString();
      }
        this.transactionTypeId = data.transactionTypeId;
      
    }



getLoockups()
{
  this._warehouseApi.getTransactionLookups()
  .subscribe(res=>{
    this.lookups.Stores = res.Stores;
    this.lookups.TransactionTypes = res.TransactionTypes;
    this.lookups.Symbols = res.Symbols;
    this.filteredLookups.Symbols = [...res.Symbols];

    
  });
}

  ngOnInit(): void {
    this.blockSellOrderForm = new FormGroup({ 
      StoreID: new FormControl({ value: 'StoreID' },[Validators.required] ),
      ClientID: new FormControl({ value: 'ClientID' } ,[Validators.required]),
      SymbolID: new FormControl({ value: 'SymbolID' } ,[Validators.required]),
      Quantity: new FormControl({ value: 'Quantity' } ,[Validators.required]),
      SectionID: new FormControl({ value: 'SectionID' },[Validators.required] ),
      ToStoreID: new FormControl({ value: 'ToStoreID' },[Validators.required] ),
      Humidity: new FormControl({ value: 'Humidity' }),
      Losses: new FormControl({ value: 'Losses' } ),
      LotNumber: new FormControl({ value: 'LotNumber' } ),
      
      itemNameseaRCHa: new FormControl({ value: 'itemNameseaRCHa' }),
      itemNameseaRCHb: new FormControl({ value: 'itemNameseaRCHb' } )


    });
    this._setupApi.getStoreKeeperInfo().subscribe(res=>{
      this.storeKeeper = res;
    if(this.transactionDto.StoreID == null)
      this.transactionDto.StoreID = res.StoreInfo.StoreID
    });
    this.getLoockups();
  }

  
  close() {
    this.dialogRef.close(false);
  }

    // onFormSubmit method is submit a add new user form.
 onFormSubmit() { 
   this.transactionDto.TransactionTypeID = this.transactionTypeId;
   if(this.transactionDto.TransactionID> 0)
      this.transactionDto.MSGType = MSGType.Updated;
    else
      this.transactionDto.MSGType = MSGType.Created;

   this.confirm(this._warehouseApi.handleTransaction(this.transactionDto),this.translate("do you want to submit"),
   ()=>{this.dialogRef.close()},null
   );
 }



      changeClientFilter(filter:string)
      {
        if(filter.length<=2)return ;
        let cfilter:ClientReuqest= new ClientReuqest();
        cfilter.GeneralFilter = filter;
        cfilter.StoreID= this.transactionDto.StoreID;
        this.clientService.searchClients(cfilter)
      .subscribe(res=>this.filteredLookups.Clients  =  res.clients);
      }
      changeSymbolFilter(filter:string)
      {
        if(filter.length<=2)return;
      let sFilter =   new SearchRequestDto(); 
      sFilter.SearchText = filter;

      this._setupApi.searchActiveSymbols(sFilter).subscribe((res)=>{
          this.filteredLookups.Symbols = res;
      });
      
      }
    

}
