<div class="user-manage-list"  >
	<mat-card>

		
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > 
				  
				   <kendo-grid-column   title="{{translate('Id')}}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="StoreNameEn" title="{{translate('Name English')}}" headerClass="grid-list-column-header" w  > </kendo-grid-column>
				  <kendo-grid-column field="StoreNameAr" title="{{translate('Name Arabic')}}" headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="Location" title="{{translate('Location')}}" headerClass="grid-list-column-header" > </kendo-grid-column>
			

				  

 

				  <kendo-grid-column field="CreatedDate" title="{{translate('Date Created')}}" headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header"
				  *ngIf="auth.isGranted(_permissions.EditStores)|| auth.isGranted(_permissions.DeleteStores)">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" 
					>
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<!-- <a mat-menu-item   
							
							[routerLink]="['/warehouses/Edit/'+ dataItem.StoreID]"  *ngIf="auth.isGranted(_permissions.EditStores)"  >
								{{translate('Edit')}}
							</a> -->
							<a mat-menu-item (click)="deleteStoreSymbol(dataItem)" *ngIf="auth.isGranted(_permissions.RemoveStoreSymbol)">
								{{translate('Delete')}}
							</a>
						 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
				   

				  


				  
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>
<div fxLayout="row" fxLayoutAlign="start">

	<button    mat-raised-button

	color="warn" (click)="addStoreSymbol()">{{ 'Assign  Warehouse' | translate }} </button> 
	<a  [routerLink]="['/symbols/symbols/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

 </div>