<kendo-grid #grid  [kendoGridBinding]="items"
class="order-kendo-grid"
[pageable]="false"
[pageSize]="20"
[resizable]="true"
[reorderable]="true"
filterable="menu"
[columnMenu]="true"
[sortable]="true" 
         (cellClick)="onCellClick($event)"
         [kendoGridSelectBy]="selectBy"
         (dataStateChange)="onDataStateChange()"
         >
         <kendo-grid-column   title=""  width="40" [columnMenu]="false"
         *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment) ">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" 
   >
             <a 
             *ngIf="dataItem.IsAttachment==true   &&(dataItem.CompanyID==this.companyID ||   isEMX()  ||   dataItem.OrderStatus==OrderSataus.Private || dataItem.CompanyID==this.companyID  )" class="cursor_pointer"
             (click)="downloadAttachment(dataItem)"> <mat-icon>cloud_download</mat-icon></a>     
        </ng-template>
   
      </kendo-grid-column>
         <kendo-grid-column field="OrderPrice"  title="{{ 'Price' | translate }}" width="80" 
     >
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
           <span 
           *ngIf="IsAllowShowPrice (dataItem.CompanyID)"
            [ngClass]= "{'sell': dataItem.CompanyID!=companyID  }" > {{dataItem.OrderPrice | number }} </span>
          </ng-template>
          </kendo-grid-column>
          
          <kendo-grid-column field="RemainingQty"  title="{{ 'Qty' | translate }}" width="80"
          >
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
             <span       *ngIf="IsAllowShowQty (dataItem.CompanyID)"
          [ngClass]="{'sell': dataItem.CompanyID!=companyID  }"> {{dataItem.RemainingQty | number }} </span>
          </ng-template>
          </kendo-grid-column> 
          <kendo-grid-column field="CustodianID"  title="{{ 'Warehouse' | translate }}" width="220">
          <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
          <span    [ngClass]=
          "{'sell': dataItem.CompanyID!=companyID  }"> {{ dataItem.StoreNameEn }} </span>
          </ng-template>
          </kendo-grid-column> 

          <kendo-grid-column field="ClientID"  title="{{ 'Client Code' | translate }}" width="100"
           >
           <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
             <span       *ngIf="IsAllowClient (dataItem.CompanyID)"
              [ngClass]=  "{'sell': dataItem.CompanyID!=companyID  }"
              >
               {{dataItem.ClientCode}} 
             </span> 
           </ng-template>
          </kendo-grid-column>
          
          
          <kendo-grid-column field="BrokerID"  title="{{ 'Broker Code' | translate }}" width="100"
          >
           <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
             <span   [ngClass]=
             "{'sell': dataItem.CompanyID!=companyID  }"
             *ngIf="IsAllowBroker (dataItem.CompanyID)">
               {{dataItem.BrokerCode}} 
             </span> 
           </ng-template>
          </kendo-grid-column>


          <kendo-grid-column field="CompanyID"  title="{{ 'Firm' | translate }}" width="200"
            >
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   [ngClass]=
   "{'sell': dataItem.CompanyID!=companyID  }"
   *ngIf="IsAllowCompany (dataItem.CompanyID)">
     {{dataItem.CompanyName}} 
   </span> 
 </ng-template>
 
</kendo-grid-column>


</kendo-grid>
    