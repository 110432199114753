
import { Routes } from '@angular/router';
 
 import {ClientStatementsComponent} from './containers/client-statements/client-statements.component';
import { ClientBalancesComponent } from './containers/client-balances/client-balances.component';
import { ClientSettlementsComponent } from './containers/client-settlements/client-settlements.component';
import{TradeSettlementsComponent} from './containers/trade-settlements/trade-settlements.component';
import{BankMasrLogComponent} from './containers/bank-masr-log/bank-masr-log.component';
import{ClientPurchasePowerComponent}from './containers/client-purchase-power/client-purchase-power.component';
import{CancelTradeSettlementsComponent}from './containers/cancel-trade-settlements/cancel-trade-settlements.component';
export const settlementManagmentRoutes: Routes = [
   {
      path: '',
      redirectTo: 'settlement',
      pathMatch: 'full'
   },
   {
      path: '',
      children: [ 
         {
            path: 'client-statements',
            component: ClientStatementsComponent
         },   
         {
            path: 'client-purchase-power',
            component: ClientPurchasePowerComponent
         }, 


         {
            path: 'client-balances',
            component: ClientBalancesComponent
         },
         {
            path: 'client-settlements',
            component: ClientSettlementsComponent
         },
         {
            path: 'trade-settlements',
            component: TradeSettlementsComponent
         },
         {
            path: 'cancel-trade-settlements',
            component: CancelTradeSettlementsComponent
         }, 
         {
            path: 'bank-masr-log',
            component: BankMasrLogComponent
         },
         //
      ]
   }
];