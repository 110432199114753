import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'day-chart',
    templateUrl: './day-chart.template.html'
})
export class DayChartComponent implements OnInit {
    @Input() public data: number[];
 

    public color: any = "gray";
    @Input() set changePct(value: number) {
 
  
      if (value > 0) {
        this.color = 'green';
    } else if (value < 0) {
        this.color = 'red';
    }else{
      this.data== [0];
    }
    
    }

    constructor(
    ) {


    }
      
  ngOnInit(): void { 
    
  }
}
