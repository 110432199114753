import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  
import { ClientReuqest } from 'app/models/client';

@Injectable()
export class ClientApi  {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts. setupUrl}`;

  constructor(private http: HttpClient) {}


 getActiveClients(): Observable< []> {

   return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getActiveClients`);
 } 
 getClientCodeRelations(id:any): Observable< []> {

  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getClientCodeRelations/`+id);
} 

 GetRejectClients(): Observable< []> {

  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getRejectClients`);
} 


GetPendingClients(): Observable< []> {

  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getPendingClients`);
} 

getClientCommentsById(id:any): Observable< []> {

  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getClientCommentsById/`+id);
} 

addClientComment(request: any,id :any): Observable<boolean> {

  return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/addClientComment/`+id, 
  request)
    .pipe(tap(data => {}));
  }


seenComments(id :any, type:any): Observable<boolean> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/seenComments/`+id +'/'+type)
      .pipe(tap(data => {}));
}


getUnSeenClientCommentCount(id:any,type:any): Observable<any> {

  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getUnSeenClientCommentCount/`+id+'/'+type);
} 











getOnlyStoreClients(id:any): Observable< []> {
  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getOnlyStoreClients/`+id);
}
insertClient(ClientRequest: any): Observable<number> {
 
  return this.http.post<any>(`${this.API_URL}/insertClient`, 
  ClientRequest)
    .pipe(tap(data => {}));
}

assginClientCodeRelation(ClientRequest: any): Observable<any> {
 
  return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/assginClientCodeRelation`, 
  ClientRequest)
    .pipe(tap(data => {}));
}

updateClient(ClientRequest: any,id :any): Observable<boolean> {

return this.http.put<any>(`${this.API_URL}/updateClient/`+id, 
ClientRequest)
  .pipe(tap(data => {}));
}


addClientBank(ClientRequest: any,id :any): Observable<boolean> {

  return this.http.put<any>(`${this.API_URL}/addClientBank/`+id, 
  ClientRequest)
    .pipe(tap(data => {}));
  }
  addStoreClient(ClientRequest: any,id :any): Observable<boolean> {

    return this.http.put<any>(`${this.API_URL}/addStoreClient/`+id, 
    ClientRequest)
      .pipe(tap(data => {}));
    }

deleteClient(id: any): Observable<boolean> {

return this.http.delete<any>(`${this.API_URL}/deleteClient/`+id).pipe(tap(data => {}));
}

 searchClients(request: ClientReuqest): Observable<any> {
  let queryParameters: string = this.convertObjectToQueryParameters(request);
  return this.http.get<[]>(`${this.API_URL}/searchClients`+queryParameters)
  }

  getStoreByClientId(id: any): Observable<any> {

    return this.http.get<any>(`${this.API_URL}/GetStoreByClientId/`+id).pipe(tap(data => {}));
  }
  getClientBanks(id: any): Observable<any> {
 
    return this.http.get<any>(`${this.API_URL}/GetClientBanks/`+id).pipe(tap(data => {}));
  }
  getClient(id: any): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/getClient/`+id).pipe(tap(data => {}));
  }
  GetCompanyClientByClientId(id: any): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/GetCompanyClientByClientId/`+id).pipe(tap(data => {}));
  } 

  GetCompanyClientByClientCompanyId(id: any ,companyId:any): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/GetCompanyClientByClientCompanyId/`+id+'/'+companyId).pipe(tap(data => {}));
  } 


  UpdateCompanyClient(ClientRequest: any,id :any): Observable<boolean> {

    return this.http.put<any>(`${this.API_URL}/UpdateCompanyClient/`+id, 
    ClientRequest)
      .pipe(tap(data => {}));
    }
   insertCompanyClient(ClientRequest: any): Observable<boolean> {

      return this.http.post<any>(`${this.API_URL}/insertCompanyClient/`     , 
      ClientRequest)
        .pipe(tap(data => {}));
      }


      convertObjectToQueryParameters(request): string {
        let queryParameters: string = "";
        for (let property in request) {
            if (request[property] != null)
                queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
        }
        if (queryParameters == "")
            return "";
        return queryParameters
    }

    
    ChangeStatusClient(id: any): Observable<boolean> {

  return this.http.delete<any>(`${this.API_URL}/ChangeStatusClient/`+id).pipe(tap(data => {}));
  }
  removeCompanyClient(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/RemoveCompanyClient/`+id).pipe(tap(data => {}));
    }

  approveClient(ClientRequest: any,id :any): Observable<boolean> {

    return this.http.put<any>(`${this.API_URL}/approveClient/`+id, 
    ClientRequest)
      .pipe(tap(data => {}));
    }

    getAttachmentTypes(): Observable<boolean> {

      return this.http.get<any>(`${this.API_URL}/getAttachmentTypes`).pipe(tap(data => {}));
    }
      
    rejectClient(ClientRequest: any,id :any): Observable<boolean> {

      return this.http.put<any>(`${this.API_URL}/rejectClient/`+id, 
      ClientRequest)
        .pipe(tap(data => {}));
      }


  
        getAttachmentsById(id: any  ): Observable<any> {
          return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getAttachmentsById/`+id).pipe(tap(data => {}));
        } 

        getClientByCompanyId  (id: any  ): Observable<any> {
          return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getClientByCompanyId/`+id).pipe(tap(data => {}));
        } 

        getClientPurchasePower  (id: any   ,currencyId:any ): Observable<any> {
          return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getClientPurchasePower/`+id +'/'+currencyId).pipe(tap(data => {}));
        } 
        getAvilableQty  (filter:any ): Observable<boolean> {
          return  this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAvilableQty`,  filter)  .pipe(tap(data => {}));
            
        } 

}