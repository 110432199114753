import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HorizontalLayoutComponent } from './horizontal-layout/horizontal-layout.component';
import { MainComponent }   from './main/main.component'; 
import { AuthGuard } from './core/guards/auth.guard';
 

import {AdminGuard} from './admin/guards/admin.guard';
import { permissions } from './core/permission';
const appRoutes: Routes = [
   {
      path: '',
      redirectTo: '',
      pathMatch: 'full',
      canActivate: [AdminGuard],

   },
   {	
      path: 'account',
      loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
   } ,
   {
      path: '',
      component: MainComponent,
      canActivate: [AdminGuard],
      
      runGuardsAndResolvers: 'always',
      children: [
         {  
           path: 'dashboard', 

           loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)} , 
           
                                 
         {  path: 'report-management',   loadChildren: () => import('./report-managment/report-managment.module').then(m => m.OrderManagmentModule)} ,
         {  path: 'operations',   loadChildren: () => import('./operation-managment/operation-managment.module').then(m => m.OperationManagmentModule)} ,
         {  path: 'Auctions', loadChildren: () => import('./auction-management/auction-management.module').then(m => m.AuctionManagementModule)} ,
         {  path: 'brokers', loadChildren: () => import('./broker-management/broker-management.module').then(m => m.BrokerManagementModule)} ,
         {  path: 'companies', loadChildren: () => import('./company-management/company-management.module').then(m => m.CompanyManagementModule)},
         {  path: 'markets', loadChildren: () => import('./market-managment/market-managment.module').then(m => m.MarketManagmentModule)} ,
         {  path: 'warehouses', loadChildren: () => import('./warehouse-managment/warehouse-managment.module').then(m => m.WarehouseManagmentModule)} ,
         {  path: 'symbols', loadChildren: () => import('./symbol-managment/symbol-managment.module').then(m => m.SymbolManagmentModule)} ,
         {  path: 'commisions',    loadChildren: () => import('./commission-management/commission-management.module').then(m => m.CommissionManagementModule)} ,
         {  path: 'emx', loadChildren: () => import('./emx-management/emx-management.module').then(m => m.EmxManagementModule)} ,
         {  path: 'clients', loadChildren: () => import('./client-managment/client-management.module').then(m => m.ClientManagementModule)} ,
         {  path: 'banks', loadChildren: () => import('./bank-management/bank-management.module').then(m => m.BankManagementModule)} ,
         {  path: 'users', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)} ,
         {  path: 'settings', loadChildren: () => import('./settings-managment/settings-managment.module').then(m => m.SettingsManagmentModule)} ,
         {  path: 'lockups', loadChildren: () => import('./lockups-management/lockups-management.module').then(m => m.LockupsManagementModule)} ,
         {  path: 'monitor_services', loadChildren: () => import('./monitor-services/monitor-services.module').then(m => m.MonitorServicesModule)} ,
         {  path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
         {  path: 'commisions',    loadChildren: () => import('./commission-management/commission-management.module').then(m => m.CommissionManagementModule)} ,
         {  path: 'settlement', loadChildren: () => import('./settlement-managment/settlement-managment.module').then(m => m.SettlementManagementModule) },
         {  path: 'cooperatives', loadChildren: () => import('./cooperative-management/cooperative-management.module').then(m => m.CooperativeManagementModule) },
         {  path: 'gold-market', loadChildren: () => import('./gold-market-management/gold-market-management.module').then(m => m.GoldMarketManagementModule) },
         
      ]
   }, 
   {
      path: 'horizontal',
      component: HorizontalLayoutComponent,
      canActivate: [AuthGuard],
      runGuardsAndResolvers: 'always',
      children: [
         {  path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},  
         {  path: 'components', loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)},
         {  path:  'brokers', loadChildren: () => import('./broker-management/broker-management.module').then(m => m.BrokerManagementModule)} ,
         {  path: 'companies', loadChildren: () => import('./company-management/company-management.module').then(m => m.CompanyManagementModule)} ,
         {  path: 'markets', loadChildren: () => import('./market-managment/market-managment.module').then(m => m.MarketManagmentModule)} ,
         {  path: 'warehouses', loadChildren: () => import('./warehouse-managment/warehouse-managment.module').then(m => m.WarehouseManagmentModule)} ,
         {  path: 'symbols', loadChildren: () => import('./symbol-managment/symbol-managment.module').then(m => m.SymbolManagmentModule)} ,
         {  path: 'clients', loadChildren: () => import('./client-managment/client-management.module').then(m => m.ClientManagementModule)} ,
         {  path: 'banks', loadChildren: () => import('./bank-management/bank-management.module').then(m => m.BankManagementModule)} ,
         {  path: 'commisions', loadChildren: () => import('./commission-management/commission-management.module').then(m => m.CommissionManagementModule)} ,
         {  path: 'users', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)} ,
         {  path: 'settings', loadChildren: () => import('./settings-managment/settings-managment.module').then(m => m.SettingsManagmentModule)} ,
         {  path: 'lockups', loadChildren: () => import('./lockups-management/lockups-management.module').then(m => m.LockupsManagementModule)} ,
         {  path: 'monitor_services', loadChildren: () => import('./monitor-services/monitor-services.module').then(m => m.MonitorServicesModule)} ,
         {  path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
         {  path: 'gold-market', loadChildren: () => import('./gold-market-management/gold-market-management.module').then(m => m.GoldMarketManagementModule) },
      ]
   },
  
   {
      path: '**',
      redirectTo: 'account/login'
   }
]

@NgModule({
  	imports: [RouterModule.forRoot(appRoutes)],
 	exports: [RouterModule],
  	providers: []
})
export class RoutingModule { }
