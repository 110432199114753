 
import { CompanyDto } from './broker';
import { ClientDto } from './client';
import { MarketDto } from './market';
import { SymbolDto } from './symbol';


export class StoreSymbolDto {
    StoreSymbolID: number;
    StoreID: number;
    SymbolId: number;
}
export class StoreDto {
    StoreID: number;
    StoreNameEn: string;
    StoreNameAr: string;
    Location: string;
    Suspended: boolean;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    CityID: number;
    TotalArea: number;
    Website: string;
    Email: string;
    Telephone: string;
    StoreSections: StoreSectionDto[];
    StoreSymbols: StoreSymbolDto[];
    StoreClients: StoreClientDto[];
    StoreKeepers:StoreKeeperDto[];    
}
export class StoreSectionDto {
    SectionID: number;
    StoreID: number;
    SectionNameEn: string
    SectionnameAr: string
    StoreTypeID: number
    SectionArea: number
    IsDeleted: boolean;

}

export class StoreTypeDto {
    StoreTypeID: number
    StoreTypeNameEn: string
    StoreTypeNameAr: string
}

export class StoreClientDto{
    StoreClientID: number;
    StoreID: number;
    ClientID: number
    SectionID: number
    ContractNo: string
    ContractStartDate: Date
    ContractEndDate: Date;
    Area: number;
    ContractStatus: number;
}


export class TransactionLookupDto{

    Stores:StoreDto[];
    Symbols:SymbolDto[];
    TransactionTypes:TransactionTypeDto[];
    Clients:ClientDto[];
}
export class TransactionTypeDto{
    Id:number;
    NameEn: string;
    NameAr: string;
    }
//    export  class TransactionDto{

//         StoreID :number   
//         ClientID:number
//         SymbolID:number
//         Quantity:number
//         BlockExpirationDate:Date
//         UpdatedUserId:  string
//         TransactionDate:Date
//         }

        export class TransactionDto {
            TransactionID: number;
            TransactionTypeID: number | undefined;
            TransactionDate: Date | undefined;
            StoreID: number | undefined;
            SectionID:number |undefined;
            ClientID: number | undefined;
            SymbolID: number | undefined;
            RecieptNo: string | undefined;
            Quantity: number | undefined;
            InspectorCompanyID: number | undefined;
            Humidity: number | undefined;
            Losses: number | undefined;
            InspectionNumber: string | undefined;
            InspectionDate: Date | undefined;
            CompanyID: number | undefined;
            BlockExpirationDate: Date | undefined;
            StatusID: number | undefined;
            CreatedUserId: string | undefined;
            CreatedDate: string;
            LastUpdatedUserId: string | undefined;
            LastUpdatedDate: Date | undefined;
            Client: ClientDto;
            Company: CompanyDto;
            Store: StoreDto;
            Symbol: SymbolDto;
            //InspectionCompany: InspectionCompanyDto;
            TransactionType: TransactionTypeDto;
            LotNumber:number;
            ToStoreID:number;
            StoreKeeper:StoreKeeperDto;
            MSGType:number;
            IsClientBelongToDropStore:boolean;
            StatusDate:string;
        }

        export class TransactionReuqest{
            Skip:number;
            Take:number;
            MSGType:string;
            TransactionTypeID:number;
            FromDate:Date;
            ToDate:Date;BlockExpiredFromDate:Date;
            KeeperUserID:any;
            StoreID:number;
            BlockExpiredToDate:Date
             Status:number;
             Interval:number;
             KeeperUserId :string;
            StoreSectionID :number;
            GeneralFilter:string;
            StatusID:number;
        }



        export class StoreKeeperDto {
            IpAddress:string;
            KeeperID: number;
            StoreID: number | undefined;
            KeeperNameEn: string | undefined;
            KeeperNameAr: string | undefined;
            Username: string | undefined;
            UserId: string | undefined;
            Telephone: string | undefined;
            Email: string | undefined;
            IsDeleted: boolean;
            IsActive: boolean;
            ClientCallbackUrl:string;
            CreatedUserId: string | undefined;
            CreatedDate: Date;
            LastUpdatedUserId: string | undefined;
            LastUpdatedDate: Date | undefined;
            Password:string;
            ConfirmPassword:string;
            StoreInfo: StoreDto
        }

export class  StatisticsModel{
            Created: number;
            Updated:number;
            Cancelled:number;
            Pending:number;
            Accepted:number;
            Rejected:number;
        }

       export class TransferApproveDto {
            TransactionId: number;
            IsApproved: Boolean;
            ApprovedQty: number;
            UpdatedUserId: string;
          }

          export class ClientPositionDto {
            ClientPortfolioID: number;
            StoreID?: any;
            ClientID: number;
            ClientNameAr: string;
            ClientNameEn: string;
            SymbolId: number;
            SymbolCode: string;
            SymbolNameEn: string;
            SymbolNameAr: string;
            OriginalQty: number;
            ApprovedQty?: any;
            BlockedQty: number;
            SoldQty: number;
            WastedQty: number;
            UnderSettlementTransferQty: number;
            AvailableQty: number;
        }

        export class SymbolQtySummaryDto {
            SymbolCode: string;
            NameEn: string;
            NameAr: string;
            OriginalQty: number;
            ApprovedQty?: any;
            BlockedQty: number;
            SoldQty: number;
            WastedQty: number;
            UnderSettlementTransferQty: number;
            AvailableQty: number;
        }