import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from 'app/app-component-base';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'custom-menu',
  templateUrl: './custom-menu.component.html',
  styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent implements OnInit,AfterViewInit,OnChanges {
@Input() title:string = "";
@Input() items:any[]=[];
@Input() displayPath:string = ""
@Input() valuePath:string = "";
@Input() icon:string="";
@Input() mandatory:boolean = false;
selectedItem:any = {};
@Output() onChange:EventEmitter<any> = new EventEmitter();

  constructor(private _translate:TranslateService) { }
ngAfterViewInit(): void {

}

translate(key){
  
  if(key!=null){

    return this._translate.instant(key);
  }else{
    return "";
  }
}
  ngOnInit(): void {
 
  }
  ngOnChanges(){
    
    if(this.mandatory && this.items!=null && this.items.length>0 ){

      this.selectedItem = this.items[0];
    }else{
      this.selectedItem=[];
    }
  }
onChangeItem(item){
  this.onChange.emit(item);
  this.selectedItem = item;
}

}
