

import {BankDto} from './bank';
import { CompanyDto } from './broker';
export class ClientDto {
    Id: number;
    NameEn: string;
    NameAr: string; 
    DateofBirth: Date;
    Address: string;
 
    ClientType: number;  
    NationalityID :number;
    IdentityTypeID: number;  
    IssueDate: Date;
    ExpiryDate: Date;
    Telephone: string;
    Gender:any;
    CityID: number; 
    Email: string;
    MailAddress: string;
    IsDeleted: boolean; 
    CreatedUserId: string;
    CreatedDate: Date;
    ApproveStatusId: number;
    CROfficeId: number;
    IdentityTypeText: string;
    IdentityTypeC1Text : number;
    IdentityTypeC2Text : number;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    Status:number;
    ClientBanks : ClientBankDto[];
    StoreClients :StoreClientDto[];
    IdentityType :IdentityTypeDto;
    ClientTypeTxt:string;
    GenderTxt:string;
    CreatedUserType :number;
    CompaniesId :number[];  
    ClientComments:ClientCommentDto[];
    ClientCodeRelations:ClientCodeRelationDto[];
    CountComments:number;
    ExpiryFlag:any;
   // CompanyClients:ClientCompanyDto[];
 
}

export class ClientBankDto {
  ClientID: number;
  BankID: number;
  Balance : number;
  IBan : string;
}

export class ClientCommentDto{
   
  Id : number;
  ClientId : number;
  CompanyId : number;
  Comment: string;
  IsSeen :any;
  CreatedUserType  :any; 
  CompanyNameEn: string;
  CompanyNameAr : string;
  ClientNameEn : string;
  ClientNameAr : string;
}

export class IdentityTypeDto {
   Id: any;
   ClientType: any;
   IdentityTypeEn: any;
   IdentityTypeAr: any;
  }

  export class StoreClientDto {
    StoreClientID: number;
    StoreID: number;
    ClientID : number;
    SectionID : number;
    ContractNo : string;
    ContractStartDate : Date;
    ContractEndDate : Date;
    Area : number;
    ContractStatus : number;
  }
  
  export class SearchRequestDto
  {
    
     SearchText:string
  }

  export class ClientCompanyDto {
    Id: number;
    ClientId: number;
    CompanyId: number;
    IsDeleted: boolean;
    IsActive: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    Client : ClientDto;
    Company : CompanyDto;
    Remark:string;
  }


  export class CompanyRequest{
    Skip:number;
    Take:number;
    CompanyType :any;
    IsActive :any;
    GeneralFilter :any;
  }

  export class ClientReuqest{
    Skip:number;
    Take:number;
    CompanyId:number;
    ClientId:number;
    Type:string;
    FromDate:Date;
    ToDate:Date;BlockExpiredFromDate:Date;
    StoreID:number;
    Status:number;
    GeneralFilter:string;
    ApproveStatusId:number;
    
     NationalityID: any;
     ClientType : any;
     IdType : any;
     IdTypeTxt : any;
     CROfficeId : any;
    
}
export class  ClientModel{
  Active: number;
  Individual:number;
  Corporate:number;
  Clients:number;
  
}

export class ClientCodeRelationDto {
   Id  : any;
   ClientId  : any;
   RelationClientId  : any;
   Type  : any;
   CreatedUserId: string;
   CreatedDate: Date;
   LastUpdatedUserId: string;
   LastUpdatedDate: Date;
}

export class UploadAttachmentDto
{
      UploadAttachmentTypes:UploadAttachmentTypeDto[];
      Identity : any;
      Path : any;

}
export class UploadAttachmentTypeDto
{
      Name : any;
      TypeId : any; 

}
export class FileAttachmentDto
{
  Id:any;
  EntityType : any;
  filename : any;  
}

export class QueueAttachmentDto
{ 
      lastModified:any;
      name : any;
      type : any;
      size: any;
      progress : any;
      isSuccess : any;
      isError : any;
      isCancel: any;
}

export class ClientPortfolioFilter
{
    CustodianID : any;
    ClientID : any;
    SymbolID : any;
    MarketID : any;
}