<form [formGroup]="addNewBrokerForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				  
 
						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Broker' | translate }} </mat-label>
							<mat-select  [(ngModel)]="brokerCompany.BrokerId"  name="broker"
								 formControlName="brokerId"  > 
								<mat-option  *ngFor="let broker of  brokers" [value]="broker.Id">
								 {{broker.Name}}</mat-option>
							</mat-select> 
						  </mat-form-field>

						  <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Email' | translate }}</mat-label>
							<input class="form-control" matInput type="input" placeholder="{{ 'Email' | translate }}" 
							   formControlName="Email"  
							   [(ngModel)]="brokerCompany.Email"  > 
						   </mat-form-field>

						   
						   <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'IpAddress' | translate }}</mat-label>
							<input class="form-control" matInput type="input" placeholder="{{ 'IpAddress' | translate }} " 
							   formControlName="IpAddress"  
							   [(ngModel)]="brokerCompany.IpAddress"  > 
						   </mat-form-field>

						   

 
  
 


					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
  	<button   (click)="onFormSubmit()" type="submit"  [disabled]="!addNewBrokerForm.valid"  
	    mat-raised-button color="warn">{{ 'SUBMIT' | translate }} </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">
			{{ 'CLOSE' | translate }} </button>
	
	</mat-dialog-actions>
</form>