
<form [formGroup]="addNewStoreSymbolForm" class="popup-card-width add-new-user">
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{ 'Assign  Warehouse' | translate }}</h4>
			   </div>
			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">
                <div class="padding-input-form">				 	
            <mat-form-field class="full-wid form-field " appearance="outline">
              <mat-label>{{ 'Warehouses' | translate }} </mat-label>
              <mat-select [(ngModel)]="warehouseSymbols" 
			  name="warehouseSymbols" 
			  formControlName="warehouseSymbols" 
			  multiple>
			  <mat-label>{{ 'Warehouses' | translate }}</mat-label>
			  <mat-option>
				  <ngx-mat-select-search name="warehouseName" formControlName="warehouseName"
					  placeholderLabel="{{translate('Enter At Least One Character')}}"
					  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
					  (ngModelChange)="onChangeWerehouse($event)">
				  </ngx-mat-select-search>
			  </mat-option>
                <mat-option *ngFor="let warehouse  of  filteredWerehouses" [value]="warehouse.StoreID">
                  <span *ngIf="!isRtl()"> {{warehouse.StoreNameEn  }}</span>
                  <span *ngIf="isRtl()"> {{warehouse.StoreNameAr  }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
					 </div> 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
			<button  	*ngIf=" auth.isGranted(_permissions.AddStoreSymbol) " (click)="onFormSubmit()" type="submit" 
			[disabled]="!addNewStoreSymbolForm.valid"   mat-raised-button color="warn">{{ 'SUBMIT' | translate }}</button>
		   <button type="button" mat-raised-button  (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>  
	</mat-dialog-actions>
</form>