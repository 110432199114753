import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { or } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { AskDto } from 'app/models/ask';
import { EntityTypeEnum, MarketStatusEnum } from 'app/core/enums';
import { OrderDto } from 'app/models/order';
import { SetupApi } from 'app/Shared/api/setup.api';
import { take } from 'rxjs/operators';
import { OrderTicketDialogComponent } from '../orderTicket-dialog/orderTicket-dialog.component';
import { FileAttachmentDto } from 'app/models/client';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';

@Component({
  selector: 'ms-order-ask',
  templateUrl: './order-ask.component.html',
  styleUrls: ['./order-ask.component.scss']
})
export class OrderAskComponent   extends AppComponentBase implements OnInit {


  public clickedRowItem;
 

 
  private _symbolCode: string;
  @Input() set symbolCode(value: string) {
     
  this._symbolCode = value;
  if (this._symbolCode !=null  ){
  this.getAsk( );
  }

}
  selectBy:any;
   companyID:any;
  brokerID:any;
  dialogRef : MatDialogRef<DemoDialog>;
  
  isPreOpen: any=false;
  items: any;
  marketStatus:any;
  constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService,private marketWatchApi: MarketWatchApi,
    private setupApi: SetupApi,
    private dashboardParamService :DashboardParamService,
    private dialog: MatDialog, private pageTitleService :PageTitleService
  ,  injector: Injector
    ) {
      
      super(injector);
    signalrService.orderChanges.subscribe(item => {
      this.  getAsk();
      
    });
    signalrService.monitorServiceChanges.subscribe(item => {
      this.getAsk();
    });
    this.dashboardParamService.loadIsOpen.subscribe(res => {

      if (res != null) {  
          if (res==true) {
          this.isPreOpen = true;
        } else {
          this.isPreOpen = false;
        }
      }

    });
    this.dashboardParamService.loadMarketStatus.subscribe(res=>{

      if(res!=null){
        this.marketStatus=res;
        this.  getAsk();
      }

    });
    
    if(this.isBroker()){
    
      this.getUserBrokerInfo().subscribe(res=>{
        if(res!=null  ){ 
          this.companyID=res.CompanyId;
          this.brokerID=res.BrokerId;
        } 
      }) 

    }
  }
  downloadAttachment(order ){ 
    const editingDialog = this.dialog.open(OrderAttachmentComponent, {
      data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:true}
    });
    editingDialog.afterClosed().subscribe((res) => { });
  }
  getAsk()
  {
    
 
    if (  this._symbolCode!=null && this._symbolCode!=""
    ) {
      this.marketWatchApi.getAsksBySymbol(this._symbolCode)
        .subscribe(items => {
          this.items = items;
    
          if(this.marketStatus == MarketStatusEnum.Preopen ||this.isPreOpen){
            this.items=   this.items.filter(x=>x.AuctionID==-1);
          } 
       
        });
    }
 

  }
  
  public rowCallback(context: RowClassArgs) {


    if(context.dataItem.CompanyID!=this.companyID){
      return {
        myOrderColorSell: true 
      };
    } 
    return {};
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;  
  }
  ngOnInit(): void {
    // this.  getAsk();
  }
  onDblClick( ) {
    if(this.auth.isGranted(permissions.AddOrders)){
      var order=this.clickedRowItem;
      order.OrderQty=order.RemainingQty;
    
      order.OrderType=1; 
      const editingDialog = this.dialog.open(OrderTicketDialogComponent, {
        data: { order: {...order },isBidAsk:true}
   });
    editingDialog.afterClosed().subscribe((res) => {} );
    }

  }
 
  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(2))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
}


