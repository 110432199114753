import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketSessionDto } from 'app/models/market';
import { MarketSessionDialogComponent } from '../market-session-dialog/market-session-dialog.component';
@Component({
	selector: 'ms-market-session-list',
	templateUrl: './market-session-list.component.html',
	styleUrls: ['./market-session-list.component.scss']
})

export class MarketSessionListComponent extends AppComponentBase implements OnInit {

popupDeleteRespone: any;
popupAddNumberCard: any;
marketSessions: MarketSessionDto[];
_marketId: any;
dialogRef: MatDialogRef<DemoDialog>;

@Output() sessionEmitter = new EventEmitter<any>();

@Input() set marketId(value: any) {

	this._marketId = value;
	this.getMarketSessions();
}
constructor(private marketService: MarketService
	,private dialog: MatDialog, injector: Injector
) {
	super(injector);
}

ngOnInit() {
//	this.	getMarketSessions();
}

getMarketSessions() {
	
	this.marketService.getMarketSessionsById(this._marketId).subscribe(res => {
	
		this.marketSessions = res; 

	});
}

/**
	 * onRemoveCard is used to remove the product Number Card.
	 */
	delete(res,index) { 
	this.dialogRef = this.dialog.open(DemoDialog, {
		data: { message:   this.translate('would you like to delete this market session')+' # ' + res.NameEn + '?' },
		disableClose: false
		});
	
		this.dialogRef.afterClosed().subscribe(result => {
		if (result == 'Yes!') {
			if(res!=null ){
				res. IsDeleted=true; 
				this.bindData(res,false ,true,index);
				
				} 
			this.marketSessions=this.marketSessions.filter(x=>x.IsDeleted!=true );

		}
		this.dialogRef = null;
		});
}

addNewSessionDialog(){ 
		const dialog = this.dialog.open(MarketSessionDialogComponent, {
		data: { marketId:this._marketId ,	marketSessionsList:	this.marketSessions}
		
	});
		dialog.afterClosed().subscribe((res) => {
		
			if(res!=null){
			res.Id=0; 
			this.bindData(res,false,false,0);
			}
			
		});
}

edit(marketSession,index){ 
	var obj=marketSession;

	
		const dialog = this.dialog.open(MarketSessionDialogComponent, {
		data: { marketSessionItem: {...obj} ,marketId:this._marketId ,	marketSessionsList:	  this.marketSessions  
		,index :index}
		});
		dialog.afterClosed().subscribe((res) => {
		
		if(res!=null ){
			res.IsDeleted=false;
			this.bindData(res,true,false,index);
			
			} 
			
		});
}


bindData(session,isUpdate,isDeleted,index){

	if(isDeleted!=true){
		if(isUpdate==true){
		for (let i = 0; i < this.marketSessions.length ; i++) {
			if(i==index){ 
				let element = this.marketSessions[i];
				element.StartSessionTime=session.StartSessionTime;
				element.EndSessionTime=session.EndSessionTime;
				element. IsAllowMatching=session.IsAllowMatching;
				element.IsAllowOrder=session.IsAllowOrder;
				element.IsDeleted=session.IsDeleted; 
			}
			}
		}else{
		this.marketSessions.push(session);
		}
	}
	this.marketSessions=this.marketSessions.filter(x=>x.IsDeleted != true);
	this.sessionEmitter.emit(this.marketSessions);  
	
}

}
