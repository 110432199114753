import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../core/directive/directives.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ChartsModule } from "@progress/kendo-angular-charts";

import { AuctionManagementRoutes } from "./auction-management.routing";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { GridModule } from "@progress/kendo-angular-grid";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { SharedModule } from "app/shared/shared.module";
import { TranslocoRootModule } from "app/transloco/transloco-root.module";

import { MarketWatchApi } from "../dashboard/api/market.watch.api";

 
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { AuctionListComponent } from "./components/auction-list/auction-list.component";
 
import { DatePipe } from "@angular/common";

import { AuctionApi } from "./api/auction.api";
import { Auctionservice } from "./services/auction.service";
import { AuctionFormDialogComponent } from "./components/auction-form-dialog/auction-form-dialog.component";

import { MatButtonToggleModule } from '@angular/material/button-toggle';
 
import { LayoutModule } from "@progress/kendo-angular-layout";
import{AuctionTradesComponent}from "./components/auction-trades/auction-trades.component";

import{AuctionMyTradesComponent}from "./components/auction-my-trades/auction-my-trades.component";
import { AuctionDashboardComponent } from "./containers/auction-dashboard/auction-dashboard.component";
import { OrderManagementModule } from "app/order-management/order-management.module";
import{AuctionOrderAskComponent}from "./components/auction-order-ask/auction-order-ask.component";
import{AuctionOrderBidComponent}from "./components/auction-order-bid/auction-order-bid.component";
import {AuctionOrderCofirmationDialogComponent} from './components/auction-order-cofirmation-dialog/auction-order-cofirmation-dialog.component'
import {AuctionOrderTicketDialogComponent } from "./components/auction-orderTicket-dialog/auction-orderTicket-dialog.component";
import { AuctionOrderWatchComponent } from "./components/auction-order-watch/auction-order-watch.component";  
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { AuctionStatisticsDialogComponent } from './components/auction-statistics-dialog/auction-statistics-dialog.component';
import {AuctionpendingTradesDialogComponent} from './components/auction-pending-trades-dialog/auction-pending-trades-dialog.component';
import {AuctionNewsComponent} from './components/auction-news/auction-news.component';
import {AuctionNewsListComponent} from './components/auction-news/auction-news-list/auction-news-list.component';
import{AuctionHelper} from './auctionHelper'
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    PerfectScrollbarModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    ChartsModule,
    MatSortModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    MatExpansionModule,
    NgxDatatableModule,
    FlexLayoutModule,
    MatOptionModule,
    MatSelectModule,
    SlickCarouselModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatInputModule,
    RouterModule.forChild(AuctionManagementRoutes),
    GridModule, 
    DragDropModule,
    ResizableModule,
    SharedModule,MatButtonToggleModule,
    LayoutModule ,
    OrderManagementModule,
    NgxMatTimepickerModule,
  ],
  declarations: [
    AuctionDashboardComponent,
    AuctionListComponent,
    AuctionFormDialogComponent,
    AuctionTradesComponent,
    AuctionOrderAskComponent,
    AuctionOrderBidComponent, 
    AuctionOrderTicketDialogComponent,  
    AuctionOrderWatchComponent,
    AuctionMyTradesComponent,
    AuctionOrderCofirmationDialogComponent,
    AuctionStatisticsDialogComponent,
    AuctionpendingTradesDialogComponent,
    AuctionNewsComponent,
    AuctionNewsListComponent
  ],
  

  providers: [
    DatePipe,

    Auctionservice,
    MarketWatchApi,
    AuctionApi,
    AuctionHelper,
  ],
})
export class AuctionManagementModule {}
