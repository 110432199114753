<form [formGroup]="blockSellOrderForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				   <h4 *ngIf="this.transactionTypeId ==TransactionTypes.deposit" class="mrgn-b-none">{{translate("Deposit")}}</h4>
				   <h4 *ngIf="this.transactionTypeId ==TransactionTypes.withdrw" class="mrgn-b-none">{{translate("Withdraw")}} </h4>
				   <h4 *ngIf="this.transactionTypeId ==TransactionTypes.deleption" class="mrgn-b-none">{{translate("Depletion")}} </h4>
				   <h4 *ngIf="this.transactionTypeId ==TransactionTypes.transfer" class="mrgn-b-none">{{translate("Transfer")}} </h4>
				   
				   <h3>{{transactionDto.RecieptNo}} </h3>
				  <span>{{storeKeeper.StoreInfo?.StoreNameEn }} - {{transactionDto.CreatedDate}} </span>
				</div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 
						<mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Client')}}*</mat-label>
							<mat-select  [(ngModel)]="transactionDto.ClientID"  name="ClientID"
								 formControlName="ClientID"  > 
								 <mat-option>
									<ngx-mat-select-search name="itemNameseaRCHa" formControlName="itemNameseaRCHa"
										placeholderLabel="{{translate('Enter At Least Three Chars To Search')}}"
										noEntriesFoundLabel="{{translate('No data found')}}" ngModel
										(ngModelChange)="changeClientFilter($event)">
									</ngx-mat-select-search>
								</mat-option>
								 <mat-option  *ngFor="let entity of  filteredLookups.Clients" [value]="entity.Id">
								 {{isRtl()?entity.NameAr:entity.NameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>
						<!-- <span class="details">{{translate('search by National Id, Passport, mobile .... etc')}}</span> -->
						 <mat-form-field class="width100 form-field " appearance="outline">
							 <mat-label>{{translate('Commodity')}}*</mat-label>
							<mat-select  [(ngModel)]="transactionDto.SymbolID"  name="SymbolID"
								 formControlName="SymbolID"  > 
								 <mat-option>
									<ngx-mat-select-search name="itemNameseaRCHb" formControlName="itemNameseaRCHb"
										placeholderLabel="{{translate('Enter At Least Three Chars To Search')}}"
										noEntriesFoundLabel="{{translate('No data found')}}" ngModel
										(ngModelChange)="changeSymbolFilter($event)">
									</ngx-mat-select-search>
								</mat-option>
								<mat-option  *ngFor="let entity of  filteredLookups.Symbols" [value]="entity.Id">
								 {{isRtl()?entity.NameAr:entity.NameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>
						 <!-- <span class="details">{{translate('Search by code or name then select from list')}}</span> -->
 
						
						<mat-form-field class="width100 form-field " appearance="outline" *ngIf="this.transactionTypeId !=TransactionTypes.transfer">
							<mat-label>{{translate('Section')}} *</mat-label>
							<mat-select  [(ngModel)]="transactionDto.SectionID"  name="StoreID"
								 formControlName="SectionID"  > 
								<mat-option  *ngFor="let entity of  storeKeeper?.StoreInfo?.StoreSections" [value]="entity.SectionID">
								 {{isRtl()?entity.SectionnameAr:entity.SectionnameAr}}</mat-option>
							</mat-select> 
						 </mat-form-field>
						 <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate('Quantity')}}*</mat-label>
						 <input class="form-control" matInput  placeholder="{{translate('Quantity')}}*" type="number" 
							 formControlName="Quantity"  
							 [(ngModel)]="transactionDto.Quantity"  >  
					   </mat-form-field>	
						 <mat-form-field class="width50 form-field " *ngIf="this.transactionTypeId ==TransactionTypes.deposit" appearance="outline">
							 <mat-label>{{translate('Lot Number')}} </mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Lot Number')}}" type="text" 
							  formControlName="LotNumber"  
							  [(ngModel)]="transactionDto.LotNumber"  >  
						</mat-form-field>	
						
						<mat-form-field class="width50 form-field " *ngIf="this.transactionTypeId ==TransactionTypes.deposit" appearance="outline">
							<mat-label>{{translate('Wastage')}} %</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Wastage')}}" type="number" 
							  formControlName="Losses"  
							  [(ngModel)]="transactionDto.Losses"  >  
						</mat-form-field>	

						<mat-form-field class="width50 form-field " *ngIf="this.transactionTypeId ==TransactionTypes.deposit" appearance="outline">
							<mat-label>{{translate('Humidity')}} %</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Humidity')}}" type="number" 
							  formControlName="Humidity"  
							  [(ngModel)]="transactionDto.Humidity"  >  
						</mat-form-field>	

						<mat-form-field class="width100 form-field"  appearance="outline" *ngIf="this.transactionTypeId ==TransactionTypes.transfer || this.transactionTypeId ==TransactionTypes.AcceptReject">
							<mat-label>{{translate('Pickup From')}}*</mat-label>
							<mat-select  [(ngModel)]="transactionDto.StoreID" [disabled]="true" name="StoreID" 
								 formControlName="StoreID"
								 > 
								<mat-option  *ngFor="let entity of  lookups.Stores" [value]="entity.StoreID">
								 {{isRtl()?entity.StoreNameAr:entity.StoreNameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>

						 <mat-form-field class="width100 form-field " appearance="outline" *ngIf="this.transactionTypeId ==TransactionTypes.transfer || this.transactionTypeId ==TransactionTypes.AcceptReject">
							<mat-label>{{translate('Drop To')}}*</mat-label>
							<mat-select  [(ngModel)]="transactionDto.ToStoreID"  name="ToStoreID" 
								 formControlName="ToStoreID" 
								 
								 > 
								<mat-option  *ngFor="let entity of  lookups.Stores" [value]="entity.StoreID" [style.display]="transactionDto.StoreID==entity.StoreID?'none':'block'">
								 {{isRtl()?entity.StoreNameAr:entity.StoreNameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>



<!-- 
 -->
						 <!-- <span class="details">{{'Search by code or name then select from list')}}</span>
						 						 <mat-form-field  class="width100 form-field " appearance="outline">
							<mat-label>{{'Block Expiration Date')}}*</mat-label> 
							<input matInput placeholder="{{'Choose a date')}}" 
							formControlName="BlockExpirationDate"
							 [matDatepicker]="picker2"
							[(ngModel)]="transactionDto.BlockExpirationDate" name="BlockExpirationDate"
						   [matDatepicker]="picker2">
						    
						 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> 
							<mat-datepicker #picker2></mat-datepicker>
						 </mat-form-field> -->


						 <!-- <mat-form-field  class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Transaction Date')}}*</mat-label> 
							<input matInput placeholder="{{translate('Choose a date')}}" 
							formControlName="TransactionDate"
							 [matDatepicker]="TransactionDate"
							[(ngModel)]="transactionDto.TransactionDate" name="TransactionDate"
						   [matDatepicker]="TransactionDate">
						 <mat-datepicker-toggle matSuffix [for]="TransactionDate"></mat-datepicker-toggle> 
							<mat-datepicker #TransactionDate></mat-datepicker>
						 </mat-form-field> -->
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button (click)="onFormSubmit()" type="submit" [disabled]="!blockSellOrderForm.valid" *ngIf="!isViewOnly" mat-raised-button color="warn">{{translate('SUBMIT')}}</button>
		<button type="button" mat-raised-button (click)="close()" color="primary">{{translate('CLOSE')}}</button>
	
	</mat-dialog-actions>
</form>