
export class AttachmentTypeDto {
    Id: number;
    NameEn: string;
    NameAr: string; 
}

export class UploadAnnouncementAttatchment
    {
        Id : number;
        file: File;
        FileName: string;
        AttachmentsPath : string;
        CreatedUserId : string;
        CreatedDate : Date;
        AnnouncementType : number;
        SymbolCode : string;
        AnnouncementID: number;
         
    }

   
export class UploadAttachmentDto{
    EntityType: number;
    EntityId: number;
    TypeId: number; 
    file: File;
    FileName: string;
  }