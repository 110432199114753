<form   class="popup-card-width add-new-user popup-card-dialog">

     <mat-dialog-content>
          <div class="gene-card-title">
               <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column">
                         <h4 class="mrgn-b-none">{{title}}</h4>
                    </div>

               </div>
               <mat-divider></mat-divider>
          </div>
          <div class="gene-card-content">
               <div class="trade-crypto crypto-input-prefix">
                    <div class="pad-t-sm">

                         <div class="padding-input-form">
                              <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewWarehouseForm">
                                   <div fxLayout="column">
                                      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32"
                                         *ngIf="!isStoreKeeper()">
                                         <mat-form-field class="full-wid form-field" appearance="outline">
                                          <mat-label>{{ 'Warehouse' | translate }}  </mat-label>
                                          <mat-select 
                                          name="StoreID" 
                                          [(ngModel)]="warehouse.StoreID"
                                          (selectionChange)="onChangeWarehouse($event.value)" 
                                          formControlName="StoreID">
                                          <mat-label>{{ 'Warehouse' | translate }}</mat-label>
                                          <mat-option>
                                             <ngx-mat-select-search name="company"      formControlName="StoreName"
                                                placeholderLabel="{{translate('Enter At Least One Character')}}"
                                                noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                                (ngModelChange)="onFilterWarehouse($event)" >
                                             </ngx-mat-select-search>
                                          </mat-option>
                                          <mat-option>{{ 'All' | translate }}   </mat-option>
                                             <mat-option *ngFor="let warehouse of filteredWarehouses" [value]="warehouse.StoreID">
                                                {{isRtl()?warehouse.StoreNameAr:warehouse.StoreNameEn}}
                                             </mat-option>
                                          </mat-select>
                                       </mat-form-field>
                                         </div>
                                   
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                                            <mat-form-field class="full-wid form-field" appearance="outline">
                                               <mat-label>{{ 'Section' | translate }} </mat-label>
                                               <mat-select name="SectionID" [(ngModel)]="warehouse.SectionID" formControlName="SectionID">
                                                  <mat-option *ngFor="let sections of sections" [value]="sections.SectionID">
                                                     {{sections.SectionNameEn}}
                                                  </mat-option>
                                               </mat-select>
                                            </mat-form-field>
                                         </div>            
                                    
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                                            <mat-form-field class="full-wid form-field" appearance="outline">
                                               <mat-label>{{ 'Contract No' | translate }} </mat-label>
                                               <input matInput placeholder="ContractNo" [(ngModel)]="warehouse.ContractNo"
                                                  formControlName="ContractNo" >
                                            </mat-form-field>
                                         </div>
 
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                                          <mat-form-field class="full-wid form-field" appearance="outline">
                                             <mat-label> {{'Contract Start Date' | translate }} </mat-label>
                                             <input matInput [matDatepicker]="picker"  
                                             [(ngModel)]="warehouse.ContractStartDate"
                                              formControlName="ContractStartDate" >
                                             <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                             <mat-datepicker #picker></mat-datepicker>
                                       </mat-form-field>
                                         </div>
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">

                                           <mat-form-field class="full-wid form-field" appearance="outline">
                                             <mat-label> {{'Contract End Date' | translate }} </mat-label>
                                             <input matInput [matDatepicker]="picker1"  
                                             [(ngModel)]="warehouse.ContractEndDate"
                                              formControlName="ContractEndDate"  >
                                             <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                             <mat-datepicker #picker1></mat-datepicker>
                                       </mat-form-field>
                                         </div>
              
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                                            <mat-form-field class="full-wid form-field" appearance="outline">
                                               <mat-label>{{ 'Area' | translate }} </mat-label>
                                               <input matInput placeholder="Area" formControlName="Area"
                                                  [(ngModel)]="warehouse.Area"   type="number" >
                                            </mat-form-field>
                                         </div>                                       
              
                                         <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                                            <!-- <mat-form-field class="full-wid form-field" appearance="outline">
                                               <mat-label> {{ 'Contract Status' | translate }}</mat-label>
                                               
              
                                                  <mat-select name="ContractStatus" [(ngModel)]="warehouse.ContractStatus" formControlName="BankID">
                                                     <mat-option *ngFor="let status of statusList" [value]="status.id">
                                                        {{status.name}}
                                                     </mat-option>
                                                  </mat-select>
                                            </mat-form-field> -->
                                         </div>
               
                                      </div> 
                                   </div>
                                </form>
                         </div>

                    </div>
               </div>
          </div>
          <!-- (click)="dialogRef.close()" -->
     </mat-dialog-content>
     <mat-dialog-actions class="padding-input-form pad-b-none">
          <button (click)="onWarehouseFormSubmit()" type="submit"
          *ngIf="	auth.isGranted(_permissions.EditClients) " [disabled]="!addNewWarehouseForm.valid" mat-raised-button
               color="warn">{{ 'SUBMIT' | translate }} </button>
          <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>

     </mat-dialog-actions>
</form>