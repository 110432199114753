<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
   <mat-card fxFlex="100%">
      <div class="gene-card-title">
         <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
               <h4>{{title| translate }} </h4>
            </div>
            <span fxFlex></span>
         </div>
         <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
         <div class="form-tab trade-crypto crypto-input-prefix">
            <mat-tab-group [selectedIndex]="selectedIndex"   (selectedTabChange)="tabChanged($event)" >
               <mat-tab label=" {{ 'Basic Info' | translate }}">
                  <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewEMXUserForm">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>  {{ 'Full Name' | translate }}  </mat-label>
                                 <input matInput placeholder=" {{ 'Full Name' | translate }}" formControlName="Name"
                                    [(ngModel)]="EMXUser.Name" required>
                              </mat-form-field>
                           </div>
 


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Role Type' | translate }} </mat-label>
                                 <mat-select name="" [(ngModel)]="rolesName" formControlName="RoleType">
                                    <mat-option *ngFor="let role of roles" [value]="role.name">
                                       {{role.name}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                    <mat-label>{{ 'Date Of Birth' | translate }} </mat-label>
                                    <input matInput [matDatepicker]="picker"  
                                    [(ngModel)]="EMXUser.DateofBirth"
                                     formControlName="DateofBirth"
                                [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                           </div>




                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Identity Type' | translate }}</mat-label>
                                 <mat-select name="" [(ngModel)]="EMXUser.IdentityTypeID"
                                    formControlName="IdentityTypeID">
                                    <mat-option *ngFor="let identityType of identityTypeList" [value]="identityType.Id">
                       
                                       <span 	*ngIf="!isRtl()">{{identityType.IdentityTypeEn}}</span> 
                                       <span 	*ngIf="isRtl()">{{identityType.IdentityTypeAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Identity' | translate }} </mat-label>
                                 <input matInput placeholder=" {{ 'Identity' | translate }}" formControlName="NationalID"
                                    [(ngModel)]="EMXUser.IdentityTypeText" required>
                              </mat-form-field>
                           </div>



                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Issue Date' | translate }}   </mat-label> 
                                    <input matInput [matDatepicker]="picker2" [max]="maxDate" (dateInput)="onClose()"
                                    [(ngModel)]="EMXUser.IssueDate" formControlName="IssueDate">
                                    <mat-datepicker-toggle  matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker  #picker2 (closed)="onClose()"></mat-datepicker>


                              </mat-form-field>
                           </div>

                         
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Expiry Date' | translate }}  </mat-label>
              

                                    <input matInput [matDatepicker]="picker1" [disabled]="isNationalId"
                                     [(ngModel)]="EMXUser.ExpiryDate" formControlName="ExpiryDate" [min]="minExpiryDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker  #picker1></mat-datepicker>
                              </mat-form-field>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Nationality' | translate }}   </mat-label>
                                 <mat-select name="NationalityID" [(ngModel)]="EMXUser.NationalityID" formControlName="NationalityID">
                                    <mat-option *ngFor="let nationality of statuslookups.Nationalities"
                                       [value]="nationality.NationalityID">
                                      <span 	*ngIf="!isRtl()">{{nationality.NationalityEn}}</span> 
                                      <span 	*ngIf="isRtl()">{{nationality.NationalityAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">

                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Telephone' | translate }} </mat-label>
                                 <input matInput placeholder="{{ 'Telephone' | translate }} " formControlName="Telephone"
                                    [(ngModel)]="EMXUser.Telephone" required>
                              </mat-form-field>
                           </div>

                        </div>




                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"> 

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Department' | translate }}   </mat-label>
                                 <mat-select name="DepartmentsID" [(ngModel)]="EMXUser.DepartmentId" formControlName="DepartmentId">
                                    <mat-option *ngFor="let department of departments"
                                       [value]="department.Id">
                                      <span 	*ngIf="!isRtl()">{{department.NameEn}}</span> 
                                      <span 	*ngIf="isRtl()">{{department.NameAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'JobTitle' | translate }}   </mat-label>
                                 <mat-select name="JobTitleId" [(ngModel)]="EMXUser.JobTitleId" formControlName="JobTitleId">
                                    <mat-option *ngFor="let jobTitle of jobTitles"
                                       [value]="jobTitle.Id">
                                      <span 	*ngIf="!isRtl()">{{jobTitle.NameEn}}</span> 
                                      <span 	*ngIf="isRtl()">{{jobTitle.NameAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>


 
                           
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Address' | translate }} </mat-label>
                                 <textarea matInput placeholder="{{ 'Address' | translate }}" [(ngModel)]="EMXUser.Address"
                                    formControlName="Address" ></textarea>
                              </mat-form-field>
                           </div>


                        </div> 





                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"> 


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Email' | translate }} </mat-label>
                                 <input matInput placeholder="{{ 'Email' | translate }}" formControlName="Email" [(ngModel)]="EMXUser.Email"
                                    required>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32"
                           *ngIf="!isUpdate">
 
                              <mat-form-field class="full-wid  form-field " appearance="outline">
                                 <mat-label>{{ 'Username' | translate }}</mat-label>
                                 <input class="form-control" matInput type="input" placeholder="UserName" 
                                    formControlName="UserName" 
                                    [(ngModel)]="EMXUser.UserName"  > 
                                </mat-form-field>
                     
                           </div>
                           
                           
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32"
                           *ngIf="!isUpdate">
                              <mat-form-field class="full-wid  form-field " appearance="outline">
                                 <mat-label>{{ 'IpAddress' | translate }}</mat-label>
                                 <input class="form-control" matInput type="input" placeholder="IpAddress" 
                                    formControlName="IpAddress"  
                          
                                
                                    [(ngModel)]="EMXUser.IpAddress"  > 
                                </mat-form-field>
                           </div>
                        </div> 

                        <div fxLayout="row" fxLayoutAlign="start">


                           <button 
                           (click)="onBasicFormSubmit()" [disabled]="!addNewEMXUserForm.valid"   type="submit" mat-raised-button color="warn"
                           *ngIf="auth.isGranted(_permissions.UpdateEMXUser)">{{ 'SUBMIT' | translate }}  </button>
                           <a  [routerLink]="['/emx/users/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                        </div>
                     </div>
                  </form>
               </mat-tab>

               


            </mat-tab-group>
         </div>

      </div>
   </mat-card>
</div>