import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  
import { OrderReuqest } from 'app/models/order';

@Injectable()
export class SettlementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}

  GetStatementsDiskLogs(filter: any): Observable<any> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/GetStatementDiskLogs/` ,
    filter)
  }
  
  getClientStatements(ClientStatementFilter: any): Observable<any> {
  
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getClientStatements/` ,
    ClientStatementFilter)
  }
  getClientUnSettlements(ClientStettlementFilter: any): Observable<any> {
  
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getClientUnSettlements/` ,
      ClientStettlementFilter)
  }
  createClientStatement(saveClientStatement : any): Observable<any> {
  
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/CreateClientStatement/` ,
    saveClientStatement)
  }
  approveClientStatement(id:any,saveClientStatement : any): Observable<any> {
  
    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/approveStatement/` +id,
    saveClientStatement)
  }
  rejectStatement(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/RejectStatement/` +id )
  }
  updateClientStatement(id:any,saveClientStatement : any): Observable<any> {
  
    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/UpdateClientStatement/` +id,
    saveClientStatement)
  }
  CancelClientStatement(id: any): Observable<boolean> {
  
    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/CancelClientStatement/`+id).pipe(tap(data => {}));
  }
     
  UploadClientStatementFile(attachement: any): Observable<any> {
      
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/uploadClientStatements`,attachement, {reportProgress: true, observe: 'events'});
  }
  
  approveClientStatementDisk(id): Observable<any> {
  
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/approveClientStatementDisk/`+id);
  }
  
  rejectClientStatementDisk(id): Observable<any> {
  
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/rejectClientStatementDisk/`+id);
  }
  
  
  getClientPurchasePower(id :any,  currencyId :any ): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getClientPurchasePower/`+id+'/'+currencyId);
  }
  getClientPurchasePowers(id :any ): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getClientPurchasePowers/`+id);
  }


  getClientBalances(ClientBalanceFilter: any): Observable<any> {
  
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getClientBalances/` , ClientBalanceFilter)
  }
   

  getUnSettlementsTrades(tradeRequest: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getUnSettlementsTrades/` , tradeRequest)
  }
  getCancelUnSettlementsTrades(tradeRequest: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getCancelUnSettlementsTrades/` , tradeRequest)
  }
  getBankMasrClientInquiriesLog(filter: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getBankMasrClientInquiriesLog/` , filter)
  }
  getBankMasrPaymentsLog(filter: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getBankMasrPaymentsLog/` , filter)
  }
  getBankMasrTransactionInquiriesLog(filter: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/getBankMasrTransactionInquiriesLog/` , filter)
  }
  saveSettlementTrades(data: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/SaveSettlementTrades/` , data)
  }
  cancelSettlementTrades(data: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.settlementUrl}/CancelSettlementTrades/` , data)
  }
  convertObjectToQueryParameters(request): string {
    let queryParameters: string = "";
    for (let property in request) {
        if (request[property] != null)
            queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
    }
    if (queryParameters == "")
        return "";
    return queryParameters
}


}