  
import { Routes } from '@angular/router';   
import { SymbolFormComponent } from './components/symbol-form/symbol-form.component';
import { SymbolListComponent } from './containers/symbol-list/symbol-list.component'; 

export const symbolManagmentRoutes: Routes = [
  {
     path: '',
     redirectTo: 'symbols',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
         {
         path: 'symbols',
         component:  SymbolListComponent
         }   
         ,{
            path: 'create',
            component:  SymbolFormComponent      
          },{
            path: 'Edit/:id',
            component:  SymbolFormComponent      
          }
     ]
  }
];