import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	 selector: 'ms-new-password',
	 templateUrl:'./new-password.component.html',
	 styleUrls: ['./new-password.component.scss'],
	 encapsulation: ViewEncapsulation.None,
})

export class NewPasswordComponent {
	
	errors: string[] = [];
	password  : string;
	passwordConfirm : string;
	public userId: string;
	constructor( public authService: AuthService,
				    public translate : TranslateService,
				    private route: ActivatedRoute ,
					private router: Router   ) { 

	   this.authService.getCurrentUser$().subscribe(res=>{
		  	this.userId=res.id;
		 });

	}

	//register method is used to sign up on the template.
	setPassword(value) {

	    this.errors=[];
	    value.userId = this.userId;
		this.authService.setNewPassword(value).subscribe(res=>{
			this.router.navigate(['/account/login']);
		},
		err => {
  
		  this.errors.push(err.error[0]);
		}
	  );
	}
	
}



