 
 

export const MarketTabIndexEnum= {
  all: 1,
  marketWatch: 0,
  favourits: 2

}

export const OrderValidityEnum= {
  Day: 1,
  GoodTillDate: 2,
  GoodTillCancel: 3,
  Immidiate: 4

}

export const StatusEnum= {

  Active: 1,
  InActive: 2 
}



export const auctionTradingsEnum= {
  
  Trading: 1,
  Auction: 2,
 
}

export const UserTypeEnum= {
  Store: 1,
  TradingCompany: 2,
  EMX: 3,
}

export const IdentityTypeEnum= {
  NationalID: 1,
  PassportNumber: 2,
  CommercialRegister: 3,
  TaxNumber : 4,
  InsuranceNumber : 5,
  OmniWarehouse : 6,
}

export const TransactionType= {
  Deposit: 1,
  Block: 2
}
 
export const MSGType ={
    Created: 1,
    Updated: 2,
    Cancel: 3,
}
export const TransactionStatus ={

    Deposited:  1,
    Withdrawn:  2,
    Depleted:  3,
    Pending:  4,
    Accepted:  5,
    Updated:  6,
    Cancelled:  7,
    Rejected:  8,
    AcceptedWithRestriction:  9,
}
export const Intervals={
  Today: 1,
  YasterDay: 2,
  LastWeek: 3,
  LastMonth: 4
}

export const TransactionTypes ={
   deposit: 1,
   withdrw: 2,
   deleption: 3,
   transfer: 4,
   AcceptReject: 5,
}

 
 
export const ApproveStatusEnum= {

  Approve : 1,
  AwaitApprove : 2,
  Reject : 3

}
export const ClientTypeEnum= {
  Individual: 1,
  Corporate:  2 ,
  Warehouse :3

}

export const IdentityExpiryEnum= {
  None:0,
  Expiry: 1,
  preExpiry:  2,
  WrongExpiry:  3,

}

export const WorkingDayEnum= {

  Saturday:0,
  Sunday:1,
  Monday:2,
  Tuesday:3,
  Wednesday:4,
  Thursday:5,
  Friday:6
 

}

 
export const NewsPriortyEnum= {
  High: 1,
  Low: 2,
}

export const OrderEnum= {
  Buy: 1,
  Sell: 2,
}

export const OrderSatausEnum= {
  Open: 1,
  Matched: 2,
  Withdrawn: 3,
  Expired: 4,
  Private: 5,
}

export const MarketStatusEnum= {
  Trade: 1,
  Preopen: 2,
  Close: 3,
}



export const AnnouncementTypeEnum= {
  Announcement: 1,
  News: 2,
  Auction :  3,
  CooperativeReceiptAuction :  4 
   
}

export const MarketTypeEnum= {
  General : 1,
  GoldMarket : 2
}




export const CalcMethodEnum=
{
    quantity : 'Q'
  , value : 'V'

}

export const AttachmentTypeEnum= {
  FrontIdentityCard : 1,
  BackIdentityCard : 2
}
export const EntityTypeEnum=
{
  Client :  1,
  Broker :  2,
  BrokerAccount :  3,
  TradingCompanies :  4,
  EMX :  5,
  Symbol:  6,
  Market :  7,
  Warehouse :  8,
  WarehouseUsers :  9,
  Bank : 10,
  Order:11,
  Cooperative:12,
}
export const MonitorServicesTypeEnum= {
  RunEndOfDay :  1,
  RunCommission:2,
  RunEndOfPreOpenOrder :  3, 
  RunSetteledBalances: 4
}

export const  AuctionstatusEnum=
{
    Pending : 0,
    Open : 1,
    Close : 2,
    Withdrawn : 3,
    Adjustment : 4,
    Executed : 5,
    PendingExecution : 6,
    PreExecute : 7,
}
export  const CompanyTypeEnum=
{
    A : 1,
    B  : 2,
    AgriculturalCooperative : 3

}
export  const TradeTypeEnum=
{
    A : 1,
    B  : 2 

}
export  const CooperativeReceiptStatusFilterEnum = { 
  CooperativePending  :  0, 
  CooperativeOpen: 2,
  CooperativeRejected:3, 
  CooperativeCancel:5, 
}
 
export  const CooperativeReceiptStatusEnum = { 
  CooperativePending  :  0,
  CooperativeAwaitApprove  :  1, 
  CooperativeOpen: 2,
  CooperativeRejected:3, 
  CooperativeCancel:5,
  CooperativeClose  :  4,
}
  //const DaysEnum: Object.freeze(ApproveStatusEnum)

  export  const RankEnum = { 
  CooperativePending  :  0,
  CooperativeAwaitApprove  :  1, 
  CooperativeOpen: 2,
  CooperativeRejected:3, 
  CooperativeCancel:5,
  CooperativeClose  :  4,
}
export  const SymbolUnitEnum = {
  KG:  1, 
  Ton:  2, 
  Ard:  3, 
  Gm:  4, 
  Qantar :  5, 
}

  export  const  ReceiptTypeCottonEnum = {
        Commercial : 1,
        Contractual : 2,
        Propagation:3
    }

  export const MatchTypeEnum = {
        Price : 1,
       	Qty  : 2,
        Prorata :3,
        Cap : 4,
        Avg: 5
    }

    export const BalanceStatusEnum={
      AwaitApprove : 1,
      Approve:2,
      Rejected:3,
    }
    export const BalanceSourceEnum={
        Manual : 1,
        Disk:2,
        SFTP:3,
        Settelment:4,
        Commissions:5,
        BankMsir:6,
    }
    export const BalanceTypeEnum={
      Deposit:1 ,
      Withdraw : 2
    }

    export const BankMasrRequestTypeEnum={
      ClientInquiry:1 ,
      Payment : 2,
      TransactionInquiry : 3
    }