 
import {BrokerListComponent} from '../broker-management/containers/broker-list/broker-list.component';
import { Routes } from '@angular/router';   
import { EmxUserListComponent } from './containers/emx-user-list/emx-user-list.component';
import { EmxUserFormComponent } from './components/emx-user-form/emx-user-form.component';
 

export const EmxManagementRoutes: Routes = [
  {
     path: '',
     redirectTo: 'emx',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
         {
         path: 'users',
         component:  EmxUserListComponent
         },{
            path: 'addEmxUser',
            component:  EmxUserFormComponent
          }    ,         {
            path: 'editUser/:id',
            component:  EmxUserFormComponent
         }   
     ]
  }
  
];