import { Injectable } from '@angular/core';   
import { Observable } from 'rxjs';
import { OperationApi } from '../api/operation.api';
 

@Injectable()
export class OperationService {

  
  constructor(private operationApi: OperationApi ) { }
  
  getAuctions(): Observable<[]> {
    return this.operationApi.getAuctions();
  }  
  getFilterAuctions(request:any): Observable<any>  {
    return this.operationApi.getFilterAuctions(request);
  }  
  getAuctionPayments(request:any): Observable<any> {
    return this.operationApi.getAuctionPayments(request);
  }  
  insertOrupdateauctionPayment(auctionPaymentDto: any): Observable<any> {
    return this.operationApi.insertOrupdateauctionPayment(auctionPaymentDto);
  }  



}