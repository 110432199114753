 
<div class="table-responsive">
 
  <div fxLayout="row wrap" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="start center">


      <div class="mrgn-l-sm mrgn-r-sm">
        <h4>   {{  'Sessions' | translate }}</h4>
      </div>
    </div>
    <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

      <mat-card-actions class="mat-pad-none margin-none"
      >
         <button mat-raised-button mat-button-sm color="primary" (click)="addNewSessionDialog()"  > {{ 'New Session' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
       </mat-card-actions>
    </div>
  </div>

<kendo-grid 
 
#grid
[kendoGridBinding]="marketSessions"
	  [filterable]="false"
	  [sortable]="true"  
    class="market-kendo-grid"

> 
 <kendo-grid-column   title="{{ 'Id' | translate }}  " 
 width="40" headerClass="grid-list-column-header"  > 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
	  {{(rowIndex+1  )}}
  </ng-template>
</kendo-grid-column>
 
<kendo-grid-column  field="Sessiontype"
title= "{{ 'Session' | translate }}"
width="100"
 headerClass="grid-list-column-header">
 <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
	 <span *ngIf="isRtl()">{{dataItem.NameAr}}</span>
	 <span *ngIf="!isRtl()">{{dataItem.NameEn}}</span>
 </ng-template>

</kendo-grid-column>


<kendo-grid-column field="StartSessionTime"  width="80" title="{{ 'Start Time' | translate }} "    headerClass="grid-list-column-header" > 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
    {{ dataItem.StartSessionTime }}
    
 </ng-template>
</kendo-grid-column>
  
<kendo-grid-column field="EndSessionTime" width="80" title="{{ 'End Time' | translate }} "    headerClass="grid-list-column-header" > 

  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
     {{ dataItem.EndSessionTime  }}
    
 </ng-template>
</kendo-grid-column>
 
<kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}  " headerClass="grid-list-column-header"width="80"
> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
     <span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
     <span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
  </ng-template>
</kendo-grid-column>
 
 
<kendo-grid-column field="IsAllowOrder" title="{{ 'Allow Order' | translate }}  " headerClass="grid-list-column-header"width="80"
> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
     <span *ngIf="dataItem.IsAllowOrder==true" class="oval-span" style="background-color:#1565c0">  {{ 'Allow' | translate }}</span>
     <span *ngIf="dataItem.IsAllowOrder!=true" class="oval-span" style="background-color:#e53935"> {{ 'Not Allowed' | translate }}</span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="IsAllowMatching" title="{{ 'Allow Matching' | translate }}  " headerClass="grid-list-column-header"width="80"
> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
     <span *ngIf="dataItem.IsAllowMatching==true" class="oval-span" style="background-color:#1565c0">  {{ 'Allow' | translate }}</span>
     <span *ngIf="dataItem.IsAllowMatching!=true" class="oval-span" style="background-color:#e53935"> {{ 'Not Allowed' | translate }} </span>
  </ng-template>
</kendo-grid-column>




<kendo-grid-column   title=" "   [columnMenu]="false"   width="60"  headerClass="grid-list-column-header" >
    <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
 
        <a    (click)="edit(dataItem ,rowIndex)"><mat-icon class="mrgn-l-sm">create</mat-icon></a> |
        <a    class="icon_hover"  (click)="delete(dataItem ,rowIndex)"> <mat-icon class="mrgn-l-sm">delete_sweep</mat-icon></a> 
             

   </ng-template>
  
  </kendo-grid-column>
  
</kendo-grid>
 

</div>
 <br/><br/>