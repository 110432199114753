import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { BidDto } from 'app/models/bid';
import { EntityTypeEnum, MarketStatusEnum, OrderEnum, OrderSatausEnum } from 'app/core/enums';
import { OrderDto } from 'app/models/order';
import { SetupApi } from 'app/Shared/api/setup.api';
import { take } from 'rxjs/operators';
 import { Auctionservice } from 'app/auction-management/services/auction.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AuctionOrderTicketDialogComponent } from '../auction-orderTicket-dialog/auction-orderTicket-dialog.component';
import { State } from '@progress/kendo-data-query';
import { AuctionDto, AuctionViewDto } from 'app/models/auction';
import { FileAttachmentDto } from 'app/models/client';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';
import { AuctionHelper } from 'app/auction-management/auctionHelper';


@Component({
  selector: 'ms-auction-order-bid',
  templateUrl: './auction-order-bid.component.html',
  styleUrls: ['./auction-order-bid.component.scss']
})
export class AuctionOrderBidComponent extends AppComponentBase  implements OnInit {
 
    public clickedRowItem; 
    _auctionId: any;
    public gridState: State = {
      sort: [],
      skip: 0,
      take: 1000
    };

    @Input() set auctionInfo(auction: any) {
  
      //load with empty list
      this.items =[];

      if (auction !=null ){
        this.auction  = auction;
          this.getBids(  );
      }
      else
      {
        this.items =[];
      }
    
    
    }
    OrderSataus= OrderSatausEnum;
    auction :any;
    selectBy:any;
    companyID:any;
    brokerID:any;
    dialogRef : MatDialogRef<DemoDialog>; 
    items: any;
 
    constructor(
      private ngZone: NgZone,
      private readonly signalrService: SignalRService,private Auctionservice: Auctionservice,
      private setupApi: SetupApi, 
      private  _AuctionHelper :AuctionHelper,
      private dialog: MatDialog 
    ,  injector: Injector
      ) {
        super(injector);
   
      signalrService.auctionOrderChanges.subscribe(item => { 
        //load with empty list
        this.  getBids();
      });
   
 
    }
    ngOnInit(): void {

    }
    getBids()
    {      
      if(this.isBroker()){
        this.getUserBrokerInfo().subscribe(res=>{
          this.companyID=res.CompanyId;   this.brokerID=res.BrokerId;

          this.Auctionservice.getBids(Number(this.auction.Id), this.companyID ,this.auction.IsPublic)
          .subscribe(items => {    this.items = items;    }); 
         }) ; 
     } else
      if(this.isEMX()){
       this.Auctionservice.getBids(Number(this.auction.Id), this.companyID ,true)
          .subscribe(items => {   this.items = items;  }); 
     }
      
 
    }
    downloadAttachment(order ){ 
      const editingDialog = this.dialog.open(OrderAttachmentComponent, {
        data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:true}
      });
      editingDialog.afterClosed().subscribe((res) => { });
     }
    public rowCallback(context: RowClassArgs) {
     
      var isMyOrder = 0; 
      if(context.dataItem.CompanyID===this.companyID ){
        var isMyOrder = 1;  
      }
      return {
        myOrderColor: isMyOrder 
      };
    }
  
    onCellClick(e) { this.clickedRowItem = e.dataItem;  }
  
    onDblClick( ) {
      if(this.auth.isGranted(permissions.AddOrders)){
        var order=this.clickedRowItem;
        order.Qty=order.RemainingQty;
        order.ClientID=null;
        order.OrderType=2; 
        const editingDialog = this.dialog.open(AuctionOrderTicketDialogComponent, {
          data:{order :   order}  
     });
      editingDialog.afterClosed().subscribe((res) => {} );
      }
  
    }
    IsOpen(auction){
      var isValid = true;
       if(auction!=null){ 
        let current = new Date(); 
      
        if (new Date(auction.OpenDate)>new Date(current) || new Date(auction.CloseDate) <new Date(current))  {
         // this.errors.push('Close date should be grater than open date.');
          isValid = false;
        }
       }
  
      return isValid;
   
     }

     public IsAllowClient(CompanyID){

      var allowOrder = this._AuctionHelper.IsAllowShowOrderClient (this.isEMX() ,this.auction,this.companyID ,CompanyID)
      if(this.auction!=null&&this.auction.OrderType== OrderEnum.Buy ){
        var allowAuction = this._AuctionHelper.IsAllowClient (this.isEMX() ,this.auction,this.companyID ,CompanyID)
        if(allowAuction)
        return true;
     else
       return false;
      }
      return allowOrder;
    }
     public IsAllowBroker(CompanyID){
        var allowOrder =this._AuctionHelper.IsAllowShowOrderBroker (this.isEMX() ,this.auction,this.companyID ,CompanyID)
        if(this.auction!=null&&this.auction.OrderType== OrderEnum.Buy ){
          var allowAuction = this._AuctionHelper.IsAllowBroker (this.isEMX() ,this.auction,this.companyID ,CompanyID)
          if(allowAuction)
          return true;
          else
            return false;
        }
        return allowOrder;
    }
   
     public IsAllowCompany(CompanyID){
       var allowOrder = this._AuctionHelper.IsAllowShowOrderCompany (this.isEMX() ,this.auction,this.companyID ,CompanyID)
       if(this.auction!=null&&this.auction.OrderType== OrderEnum.Buy ){
        var allowAuction = this._AuctionHelper.IsAllowCompany (this.isEMX() ,this.auction,this.companyID ,CompanyID)
        if(allowAuction)
        return true;
     else
       return false;
      }
      return allowOrder;
    }
   
     public IsAllowShowPrice(CompanyID){
   
      var allowPrice = this._AuctionHelper.IsAllowShowOrderPrice (this.isEMX() ,this.auction,this.companyID ,CompanyID);
      if(this.auction!=null&&this.auction.OrderType== OrderEnum.Buy ){
       var allowAuction = this._AuctionHelper.IsAllowShowPrice (this.isEMX() ,this.auction,this.companyID ,CompanyID)
       if(allowAuction)
       return true;
       else
         return false;
      }
       return allowPrice;
     }
   
     public IsAllowShowQty(CompanyID){
      var allowQty = this._AuctionHelper.IsAllowShowOrderQty (this.isEMX() ,this.auction,this.companyID ,CompanyID);
      if(this.auction!=null&&this.auction.OrderType== OrderEnum.Buy ){
       var allowAuction = this._AuctionHelper.IsAllowShowQty (this.isEMX() ,this.auction,this.companyID ,CompanyID)
       if(allowAuction)
       return true;
       else
         return false;
     }
     return allowQty;
      }
 
    @ViewChild(GridComponent)
    public grid: GridComponent;
    
    public onDataStateChange(): void {
      this.fitColumns();
    }
  
    private fitColumns(): void {
      this.ngZone.onStable
        .asObservable()
        .pipe(take(2))
        .subscribe(() => {
          this.grid.autoFitColumns();
        });
    }
  }
