import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { CacheService } from '../../core/cache.service';
import { AuthStrategy, AUTH_STRATEGY } from './auth.strategy';
import { ForgetPasswordRequest, ForgetUserPasswordRequest, LoginRequest, SetNewPasswordRequest,ResetPasswordRequest } from '../../models/loginRequest';
import { User } from '../../models/user';
//import { Role } from '@models/types';
import { SignUpRequest } from '../../models/signUpRequest';
import { AppConsts } from '../../core/config';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { permissions } from '../../core/permission';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthService {



  isGranted(permission: string) {
   
    let ret: boolean = false;

    // See if an array of values was passed in.
    if (typeof permission === "string") {
        ret = this.isPermissionValid(permission);
    }
    else {
        let claims: string[] = permission;
        if (claims) {
            for (let index = 0;
            index < claims.length;
            index++) {
                ret = this.isPermissionValid(claims[index]);
                // If one is successful, then let them in
                if (ret) {
                    break;
                }
            }
        }
    }

    return ret;
  }
  isGrantedList(_permissions: string[]) {
  
    let ret: boolean = false;

    // See if an array of values was passed in.
    for (let index = 0;
      index < _permissions.length;
      index++) {
      
        return this. isGranted(_permissions[index]);
      }

    return ret;
  }



  isPermissionValid(permission: string): boolean {
 
    var claims=this.auth.getCurrentUserClaims();
    if (typeof claims === "string") {
        return String(claims).toLowerCase() == permission.toLowerCase();
    }
     if(claims != undefined && claims != null && claims.length > 0)
     {
      return claims.find(c => c.toLowerCase() == permission.toLowerCase()) != null;
     }

     return false;
    
  }

  public readonly INITIAL_PATH = '/dashboard';
  public readonly INITIAL_Auction_PATH = '/Auctions';
  public readonly INITIAL_Gold_PATH = '/gold-market';
  
  public readonly INITIAL_COIPERATIVE_PATH = '/cooperatives/transactions';
  public readonly INITIAL_COIPERATIVE_MANAGER_PATH = '/cooperatives/transactions';
  private readonly userRolesClaims = 'userRolesClaims';
    private readonly RoleName = 'RoleName';
  public readonly ADMIN_PATH = '/admin';
  public readonly LOGIN_PATH = '/login';
  public readonly CONFIRM_PATH = '/confirm';
  public readonly New_PASSWORD_PATH = '/account/new-password'

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialogRef: MatDialog, 
    @Inject(AUTH_STRATEGY) private auth: AuthStrategy<any>
  ) {    }

  signup(user: SignUpRequest): Observable<void> {
    user.clientCallbackUrl = AppConsts.frontEndUrl + '/confirm';
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/register`, user);
  }

  getUserRoles(): Observable<[string]> {
    let id:any;
    this.getCurrentUser$().subscribe(res=>{
      id=res.id;
    });
    return this.http.get<[string]>(`${AppConsts.authApiUrl}/api/userRoles/get/`+id);
  }



  confirm(userId: string, code: string): Observable<void> {
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/confirmEmail`, {userId : userId, code : code});
  }

  login(loginRequest: LoginRequest, rememberMe : boolean): Observable<any> {
 
    this.auth.setRememberMe(rememberMe);  
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/token`, loginRequest)
      .pipe(tap(data => {this.auth.doLoginUser(data); }));
  }
  getRoleClaims(roleName): Observable<any>{
   return this.http.get<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/getByName/`+roleName) 
     .pipe(tap(data => {}));
  }
  logout( ) {
    return this.http.delete<any>(`${AppConsts.authApiUrl}${AppConsts.manageUrl}/logout`)
      .pipe(tap(() => this.  doLogoutAndRedirectToLogin()),catchError(() => of(this. doLogoutAndRedirectToLogin())));
  }

  isLoggedIn$(): Observable<boolean> {
 
    return this.auth.getCurrentUser().pipe(
      map(user => !!user),
      catchError(() => of(false))
    );
  }

  getCurrentUser$(): Observable<any> {
    return this.auth.getCurrentUser();
  }



  getUserEmail$(): Observable<string> {
    return this.auth.getCurrentUser().pipe(
      map(user => user.email)
    );
  }
  getUserRoles$(): Observable<string> {
     return this.auth.getCurrentUser().pipe( map(  user => user.roleName)
     );
  }
  

 
  doLogoutAndRedirectToLogin() {
    
    this.doLogoutUser();
    this.dialogRef.closeAll();
    this.router.navigate(['/login']);
  }

  private doLogoutUser() {

   // this.cacheService.pruneAll();
    this.auth.doLogoutUser(); 
  }

  refreshToken() {
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/refreshToken`, this.auth.getRefreshTokenRequest())
      .pipe(tap(data => this.auth.doLoginUser(data)));
  }

  resetPassword(resetPasswordRequest: ResetPasswordRequest) {
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/resetPassword`, resetPasswordRequest)
      .pipe(tap(data =>  {}));
  }
  setNewPassword(setNewPasswordRequest: SetNewPasswordRequest) {
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/setNewPassword`, setNewPasswordRequest)
      .pipe(tap(data =>  {}));
  }
  forgotPassword(forgetPasswordRequest: ForgetPasswordRequest) {
    return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/forgotPassword`, forgetPasswordRequest)
      .pipe(tap(data =>  {}));
  }
 forgotPasswordByUserId(forgetPasswordRequest: ForgetUserPasswordRequest) {
  return this.http.post<any>(`${AppConsts.authApiUrl}${AppConsts.authUrl}/forgotPasswordByUserId`, forgetPasswordRequest)
    .pipe(tap(data =>  {}));
}

getRememberMe() : boolean
{
  return this.auth.getRememberMe();
}
getToken() : string
{
  return this.auth.getToken();
}
}
