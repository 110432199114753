import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { MarketSymbolListComponent } from 'app/market-managment/components/market-symbol-list/market-symbol-list.component';
import { AppComponentBase } from 'app/app-component-base';
 
import { MarketCommissionDialogComponent } from 'app/market-managment/components/market-commission-dialog/market-commission-dialog.component';
import { SetupApi } from 'app/Shared/api/setup.api';



@Component({
  selector: 'ms-market-manage-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss']
})
export class MarketListComponent  extends AppComponentBase implements OnInit, OnDestroy {

  public items: MarketDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  public actions: any;
  selectBy:any;
  public action: any;
  public _marketId: number;
  @Input() set marketId(value: number) {

    this._marketId = value;


  }
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private marketService: MarketService    ,
    private setupApi: SetupApi, 
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector);
      this.actions = _helper.getNames(StatusEnum);
      if(this._marketId!=null){
        this.title ='Sub Markets' ; 
      }else{
        this.title ='Main Markets' ; 
      }
  
      this.pageTitleService.setTitle('Markets');
  
  
    }





  ngOnInit(): void {

  

    this.getMarkets(StatusEnum.Active);

  }

  getMarkets(action) {
    this.action = action;
    if(this._marketId!=null){
      this.setupApi.getSubMarkets( this._marketId ).subscribe(items => {

        this.items = items;  
   
        this.items=this.items.filter(x=>  x.IsDeleted!=true); 
      });
    }else{
      this.setupApi.getMainMarkets().subscribe(items => {

        this.items = items;  
        this.items=this.items.filter(x=>  x.IsDeleted!=true); 
  
      });
    }

  }




  addNewMarketDialog() {
   var market = new MarketDto();
    const dialog = this.dialog.open(MarketFormDialogComponent, {
      data: { market: market }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getMarkets(this.action);
    });
  }


  editMarket(market) {
   const dialog = this.dialog.open(MarketFormDialogComponent, {
      data: { market: market }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getMarkets(this.action);
    }); 

  } 

  deleteMarket(market) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:   this.translate('would you like to delete this market')+' # ' + market.NameEn + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.marketService.deleteMarket(market.Id).subscribe((res) => {
         this. getMarkets(this.action);
                 this.toastr.success(  this.translate("Successfully"));
        }, err => {
          this.toastr.error(  this.translate("Error"));
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }


  getSymbols(market){
    const dialog = this.dialog.open(MarketSymbolListComponent, {
      data: { market: market }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getMarkets(this.action);
    }); 
  }

 
  
  GetMarketCommissions(market){
    const dialog = this.dialog.open(MarketCommissionDialogComponent, {
      data: { market: market }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getMarkets(this.action);
    }); 
  }
  
 

}

