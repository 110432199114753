import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CompanyManagementService } from '../../services/company-management.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import{CompanyDto } from 'app/models/broker';
import {   MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { Router } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CompanyFormDialogComponent } from 'app/company-management/components/company-form-dialog/company-form-dialog.component';
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import{EnumHelpers } from 'app/helper/EnumHelpers';

import { from, Observable } from 'rxjs';
import { AppComponentBase } from 'app/app-component-base';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { CompanyRequest } from 'app/models/client';
@Component({
  selector: 'ms-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent  extends AppComponentBase  implements OnInit , OnDestroy{

  companyId:any;
  public actions:any;
  public action :any;
  companyCount:any;
  searchTxt:any ;
  title:any;
  filterOptions :CompanyRequest=new CompanyRequest();
  dialogRef: MatDialogRef<DemoDialog>;
  public view: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 25,
  };
  selectBy:any;
  ngOnDestroy(){ 

	}

  constructor( private pageTitleService : PageTitleService ,
    private dialog: MatDialog  ,
    private companyManagementService :CompanyManagementService,
 
    private _router: Router ,
    private _helper :EnumHelpers   
    , injector: Injector
    ) {
      super(injector);
      
      this.actions = _helper.getNames(StatusEnum);
  
    }

  ngOnInit(): void {

    if ((this.isBroker()) && !this.isEMX()) {

      this.getUserBrokerInfo().subscribe(res => {
   
        if (res != null  ) {

          this.companyId=res.CompanyId;
          this.getCompanies() ;
        }
      });

      
      }else
      {
        this.getCompanies() ;
      }

   
 		this.pageTitleService.setTitle(this.translate("Trading Companies"));
    this.title=this.translate('Trading Companies') ;
  
  }
  changePropertiesStatus(data){
 
    let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
    propertiesStatus.ActionOn=EntityTypeEnum.TradingCompanies;
    propertiesStatus.ResponsibleId=data.Id;
    propertiesStatus.ResponsibleNameAr=data.Name;
    propertiesStatus.ResponsibleNameEn=data.Name;
    propertiesStatus.NotifiledEmail=data.Email;
    if( data.IsActive)
         propertiesStatus.Status= this._StatusEnum.Active;
      else
      propertiesStatus.Status=this._StatusEnum.InActive; 
   
    const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
      data:{propertiesStatus:propertiesStatus   }  
    });
    dialog.afterClosed().subscribe((res) => { 
  if(res!=null){
    if(res==this._StatusEnum.Active)
            data.IsActive=true;
     else
     data.IsActive=false; 
  }
  
    } );
  }

  public dataStateChange(state: DataStateChangeEvent): void {
  
    this.state = state;
    this. getCompanies();
  }
  

  getCompanies(){
     this.filterOptions.IsActive=true;
     this.filterOptions.Take = this.state.take;
     this.filterOptions.Skip = this.state. skip;
     this.view =  this.companyManagementService.searchCompanies(this.filterOptions).pipe(
       map(data => { 

        if ((this.isBroker()) && !this.isEMX()) {
    
          data.companies = data.companies.filter(x=>x.Id == this.companyId);
              data.count = data.companies.length;

              this.companyCount=data.count;
              let result = <GridDataResult>{ data:  data.companies, total: data.count}
              return result;

          }else
          {
            this.companyCount=data.count;
            let result = <GridDataResult>{ data:  data.companies, total: data.count}
            return result;;
          }


      })); 

  }
  
  addNewCompanyDialog(){ 
    const dialog = this.dialog.open(CompanyFormDialogComponent, {
           data:{count : this.companyCount}
      });
      dialog.afterClosed().subscribe((res) => {
 
         //this.items.push(res);
         this.getCompanies();
         
      } );
  }
  editCompany(company){ 
    const dialog = this.dialog.open(CompanyFormDialogComponent, {
        data:{company:company}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getCompanies();
      } );
  }
  detailsCompany(){
    var company= new CompanyDto;
    const dialog = this.dialog.open(CompanyFormDialogComponent, {
        data:{company:company}  
      });
      dialog.afterClosed().subscribe((res) => {} );
  }
  
  deleteCompany(company) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this company code ' + company.Code + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.companyManagementService.deleteCompany(company.Id).subscribe((res) => {
          this.getCompanies();
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
 

  onStateChange(){ 
   
    if(this.filterOptions.GeneralFilter!= null &&this.filterOptions.GeneralFilter.length>2 ){
      this.filterOptions.GeneralFilter
      this.getCompanies();
      
    } 
  }

  clear(){
     this.filterOptions.GeneralFilter=null;
     this.filterOptions.GeneralFilter=" ";
     this.getCompanies();
  }
}

 