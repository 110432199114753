import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConsts } from '../../core/config';
import { TradeDto } from 'app/models/trade';
import { tap } from 'rxjs/operators';

@Injectable()
export class MarketWatchApi {

  constructor(private http: HttpClient) { }



  getMarketWatch(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getMarketWatch`);
  }

  getMarketWatchBySymbol(marketId: any): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getMarketWatch` + '?MarketId=' + marketId)
  }
  getBids(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getBids`);
  }
  getBidsBySymbol(symbolCode: any): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getBids?SymbolCode=`
      + symbolCode +  '&AuctionTradesOnly=' + false);
  }
  getAsksBySymbol(symbolCode: any): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getAsks?SymbolCode=`
      + symbolCode +  '&AuctionTradesOnly=' + false);
  }

  


  getAsks(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getAsks`);
  }

getCompanyTrades(Skip: any, Take: any ,MarketId :any,CompanyId:any): Observable<[TradeDto]> {

    return this.http.get<[TradeDto]>
    (`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getTrades`
      + '?Skip=' + Skip + '&Take=' + Take +'&MarketId=' +MarketId +'&CompanyId='+CompanyId);
  }

  getTrades (Skip: any, Take: any ,MarketId :any): Observable<[TradeDto]> {

    return this.http.get<[TradeDto]>
    (`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getCompanyTrades`
      + '?Skip=' + Skip + '&Take=' + Take +'&MarketId=' +MarketId);
  }
   

  getTradesView(Skip: any, Take: any): Observable<[TradeDto]> {

    return this.http.get<[TradeDto]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getTradesView`
      + '?Skip=' + Skip + '&Take=' + Take);
  }
  getTradesBySymbolCode(SymbolCode: any): Observable<[TradeDto]> {

    return this.http.get<[TradeDto]>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getTrades`
      + '?SymbolCode=' + SymbolCode + '&Skip=' + 1 + '&Take=' + 100);
  }
  getFavouriteMarketWatch(userId: any, markerId: any): Observable<boolean> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getFavouriteMarketWatch/` + userId + '/' + markerId);
  }


  updateFavouriteMarketWatches(MarketRequest: any): Observable<boolean> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/updateFavouriteMarketWatches`,
      MarketRequest)
      .pipe(tap(data => { }));
  }
}
