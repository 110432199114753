<mat-card class="ticker-slider  " dir="ltr" *ngIf="auth.isGranted(_permissions.GetMarketwatCh)">
    <ms-ticker-slider></ms-ticker-slider>
</mat-card>

<div fxLayout="row wrap" fxlayoutAlign="start start" class="dashboard-content">
 
        <kendo-splitter orientation="vertical" style="height: 50%;" *ngIf="!auth.isGranted(_permissions.ISStoreKeeper) ">
            <kendo-splitter-pane>
                <kendo-splitter>
                    <kendo-splitter-pane [collapsible]="true" size="70%">
                        <div class="pane-content" >
                            <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                                mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                                [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)"
                                *ngIf="auth.isGranted(_permissions.GetMarketwatCh) 
                                ||  auth.isGranted(_permissions.GetAuctions) ">

                                <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                                    <mat-card class="card-grid market-watch-grid">
                                        <ms-auction-list    (aucthionEmitter)="getAuction($event)"  ></ms-auction-list>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </kendo-splitter-pane>
    
                    <kendo-splitter-pane>
                        <div class="pane-content">
                            <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                                mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                                [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)"
                                *ngIf="auth.isGranted(_permissions.GetTrades)">
                                    <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                                        <mat-card class="card-grid market-watch-grid">
                                            <mat-tab-group color="accent" class="dashboard-container" >
                                                <mat-tab label="{{ 'All Trades' | translate }}" >
                                                           <ms-auction-trades  > </ms-auction-trades>
                                                </mat-tab>
                                                <mat-tab label="{{ 'My Trades' | translate }}"  >
                                                           <ms-auction-my-trades  > </ms-auction-my-trades>
                                                </mat-tab>
                                            </mat-tab-group>
                                        </mat-card>
                                    </div>
                            </div>
                        </div>
                    </kendo-splitter-pane>

                </kendo-splitter>
            </kendo-splitter-pane>
        </kendo-splitter>
    
        <kendo-splitter orientation="vertical" style="height: 50%;"  *ngIf="!auth.isGranted(_permissions.ISStoreKeeper) "> 
            <kendo-splitter-pane    >
                <kendo-splitter>
                    <kendo-splitter-pane [collapsible]="true" size="65%">
                        <div class="pane-content">
    
                            <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"  [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)" *ngIf="auth.isGranted(_permissions.GetOrders)">
                                <div fxLayout="row wrap">
                        
                                    <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                                        <mat-card class="card-grid orders-grid">
 
                                            <div class="gene-card-title">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div fxLayout="column">
                                                       <h5 class="mrgn-b-none card-grid-title">{{'Order Watch' | translate}}</h5>
                                                    </div>
                                                    <span fxFlex></span> </div>
                                                <mat-divider></mat-divider>
                                            </div>
                                            <ms-order-watch [auctionInfo]=auction> </ms-order-watch>
                                        </mat-card>
                                    </div>
                        
                                </div>
                            </div>
                        </div>
                    </kendo-splitter-pane>
    
                    <kendo-splitter-pane>
                        <div class="pane-content"> 
                            <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="auth.isGranted(_permissions.GetBidsAsks)">
                                <div fxLayout="row wrap" fxlayoutAlign="start start">
                                    <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100"   [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)">
                                        <mat-card class="card-grid bids-grid">
 
                                            <div class="gene-card-title">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div fxLayout="column">
                                                       <h5 class="mrgn-b-none card-grid-title buy">{{ 'Bids' | translate }}  {{symbolTitle}} {{auctionTitle}} </h5>
                                                    </div>
                                                    <span fxFlex></span> </div>
                                                <mat-divider></mat-divider>
                                            </div>
                                            <ms-auction-order-bid  [auctionInfo]=auction> </ms-auction-order-bid>
                                        </mat-card>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </kendo-splitter-pane>
                    
                    <kendo-splitter-pane >
                        <div class="pane-content">                        
                                    <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100"  [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)">
                                        <mat-card class="card-grid asks-grid">
 
                                            <div class="gene-card-title">
                                                <div fxLayout="row" fxLayoutAlign="start center">
                                                    <div fxLayout="column">
                                                       <h5 class="mrgn-b-none card-grid-title sell">{{ 'Asks' | translate }}  {{symbolTitle}} {{auctionTitle}} </h5>
                                                    </div>
                                                    <span fxFlex></span> </div>
                                                <mat-divider></mat-divider>
                                            </div>
                                            <ms-auction-order-ask   [auctionInfo]=auction> </ms-auction-order-ask> 
                                        </mat-card>
                                   </div>                   
                        </div>
                    </kendo-splitter-pane>  
                </kendo-splitter>
            </kendo-splitter-pane>
        </kendo-splitter>
        
</div>

<mat-card class="slider-message ">
    <ms-news-slider></ms-news-slider>
</mat-card>