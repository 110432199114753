import { Component, Inject, Injector, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { WorkingDayEnum } from 'app/core/enums';
import { MarketWorkingDayDto } from 'app/models/market';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { StoreSymbolDto } from 'app/models/symbol';
import { SetupApi } from 'app/Shared/api/setup.api';
@Component({
selector: 'ms-symbol-warehouse-dialog',
templateUrl: './symbol-warehouse-dialog.component.html',
styleUrls: ['./symbol-warehouse-dialog.component.scss']
})

export class SymbolWarehouseDialogComponent extends AppComponentBase implements OnInit {


addNewStoreSymbolForm: FormGroup;
errors:any;
warehouseSymbols: any[] = new Array();
items: any[] = new Array();
warehouses:any; 
symbolId:any;
filteredWerehouses: any;

constructor(   
    private setupApi: SetupApi,
		private symbolService: SymbolService    ,
		private dialogRef: MatDialogRef<SymbolWarehouseDialogComponent>
		, @Optional() @Inject(MAT_DIALOG_DATA) private data: any, 
		injector: Injector) {
	super(injector);
	if(data.symbolId!=null){
		this.symbolId=data.symbolId;
		this.GetWarehouseSymbols(	this.symbolId);
	}
}

ngOnInit() {
	this.setupApi.getWarehouseLookups().subscribe(res => {
		this.warehouses = res;  });
	this.addNewStoreSymbolForm = new FormGroup({
	
	warehouseSymbols: new FormControl({ value: '' } ),
	warehouseName: new FormControl({ value: 'warehouseName' } )
});
		
}

GetWarehouseSymbols(id) {
	this.warehouseSymbols=[]; 
	this.setupApi.GetStoreSymbols(this.symbolId).subscribe(res => {
		let storeSymbolLst = res; 
		storeSymbolLst.forEach(element => {
			this.	warehouseSymbols.push(element.StoreID);
		});


	});
}


onFormSubmit() {
	this.errors = []; 
	let storeSymbols = [];
	this.warehouseSymbols.forEach(i=>{
		let ss = new StoreSymbolDto();
		ss.SymbolId = Number(this.symbolId);
		ss.StoreID = i;
		storeSymbols.push(ss);});

	this.symbolService.addStoreSymbol(storeSymbols, Number(this.symbolId)).subscribe(res=>{
		close();
		this.toastr.success("Successfully!");
	
	},
	err => {
	this.errors.push(err.error[0]);
	this.toastr.error(err.error[0]);
	});
}

close() { 
	this.dialogRef.close(false);
} 


onChangeWerehouse(event) {
    //this.getMarketCommission(id.value);
    if(this.warehouses != null)
    { 
      if(typeof event === 'string')
      {
      if( event.match(/^ *$/) !== null)
      this.filteredWerehouses = this.warehouses;
      else
      this.filteredWerehouses = this.warehouses.filter((s) => s.StoreNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
        || this.warehouses.filter((s) => s.StoreNameAr.indexOf(event.toLowerCase()) !== -1 );
      }
    }
  }
}
