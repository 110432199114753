import { Routes } from '@angular/router';
 import { GoldMarketDashboardComponent } from './containers/gold-market-dashboard/gold-market-dashboard.component';

 
export const GoldMarketManagementRoutes:   Routes = [{
  path: '',
  redirectTo: '', 
  pathMatch: 'full',
  component: GoldMarketDashboardComponent
} ];


 







 
