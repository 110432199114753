import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 
import { WarehouseService } from './services/warehouse.service';
import { WarehouseApi } from './api/warehouse.api';
import { StoreSectionApi } from './api/storesection.api';
import { warehouseManagmentRoutes } from './warehouse-managment.routing';
import { WarehouseListComponent} from './containers/warehouse-list/warehouse-list.component';
import{  WarehouseFormDialogComponent} from './components/warehouse-form-dialog/warehouse-form-dialog.component';
import { StoreSectionListComponent } from './containers/store-section-list/store-section-list.component';
import { StoreSectionFormDialogComponent } from './components/store-section-form-dialog/store-section-form-dialog.component';
import { TransactionComponent } from './components/transaction/transaction.component';

import { TransactionsListComponent } from './containers/transactions/transactions-list.component';
import { StoreKeeperListComponent } from './containers/store-keeper-list/store-keeper-list.component';
import { StoreKeeperFormDialogComponent } from './components/store-keeper-form-dialog/store-keeper-form-dialog.component';
import { DepositComponent } from './components/deposit/deposit.component';

 import {ClientPositionComponent} from './containers/client-position/client-position.component'
import {SymbolQuantityComponent} from './containers/symbol-qantity/symbol-quantity.component'
import { SharedModule } from './../Shared/shared.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
 
 

@NgModule({
  imports: [  
 

  FormsModule,
    ReactiveFormsModule, 
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(warehouseManagmentRoutes),
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
    CommonModule,
    DirectivesModule,
    MatListModule,
    MatDialogModule  ,
    DropDownsModule,
    GridModule
    ,TranslocoRootModule
    ,DragDropModule
    ,ResizableModule
      ,SharedModule ,
      TranslateModule

  ],
  declarations: [ 
       StoreSectionFormDialogComponent,
       WarehouseListComponent,
       WarehouseFormDialogComponent, 
       StoreSectionListComponent,
       TransactionComponent,
       TransactionsListComponent, 
       StoreKeeperListComponent,
       StoreKeeperFormDialogComponent,
       DepositComponent,
       ClientPositionComponent,
       SymbolQuantityComponent, 
       //FarmerFormComponent,   
  ], 
  providers: [WarehouseApi
     ,WarehouseService
    ,StoreSectionApi]
})
export class WarehouseManagmentModule { }