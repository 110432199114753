import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { TradeDto } from 'app/models/trade';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, startWith, switchMap, catchError, map, filter, take} from 'rxjs/operators';
import { OrderService } from 'app/report-managment/services/order.service';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ClientBalanceReuqest } from 'app/models/ClientStatement';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';
import { BalanceSourceEnum, BalanceStatusEnum } from 'app/core/enums';
@Component({
  selector: 'ms-client-balance-detials',
  templateUrl: './client-balance-detials.component.html',
  styleUrls: ['./client-balance-detials.component.scss']
})
export class ClientBalanceDetialsComponent implements OnInit {

  filterOptions:ClientBalanceReuqest = new ClientBalanceReuqest();
  _balance: any;
  SourceEnum=BalanceSourceEnum;
  StatusEnum=BalanceStatusEnum;
  public state: State = {
    skip: 0,
    take: 25,
  };
  @Input() set balance(value: any) {
    if(value!=null){
      
      this._balance=value;
      this.filterOptions.FromDate=this._balance.BalanceDate;
      this.filterOptions.ToDate=this._balance.BalanceDate;
      this.filterOptions.ClientId=this._balance.ClientId;
      this.getList();
    }
  }

  selectBy:any;
  public view: Observable<GridDataResult>;
  public items:any= TradeDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
   };
   constructor(
    private ngZone: NgZone,
    private _setupApi: SetupApi,
    private _settlementService:SettlementService) {

  }

  public onStateChange(state: State) {
 
    this.gridState = state;
} 
public rowCallback(context: RowClassArgs) {
   
  var isFlash = 0;
   
  if(context.dataItem.isUpdate==true ){

    var isFlash = 1;
    context.dataItem.isUpdate=false;
  }
  return {

      flash_down: isFlash,
      normal: !isFlash
  };
}
getList() {  
  this.filterOptions.Take = this.state.take;
  this.filterOptions. Skip = this.state. skip; 
  let FromDate = new Date(this.filterOptions.FromDate);
  this.filterOptions.FromDate = new Date(
   Date.UTC(FromDate.getFullYear(), FromDate.getMonth(), FromDate.getDate()));

   let ToDate = new Date(this.filterOptions.ToDate);
   this.filterOptions.ToDate = new Date(
    Date.UTC(ToDate.getFullYear(), ToDate.getMonth(), ToDate.getDate()));
   this.view =  this._settlementService. getClientStatements(this.filterOptions).pipe(
    map(data => {
      if(data.ClientStatements.length==0){
        data.count=0;
      }
      debugger;
        let result = <GridDataResult>{ data:  data.ClientStatements, total: data.count}
        return result;
    }) ); 
}
  
  ngOnInit(): void {

  }

  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    
    this.fitColumns();
  }
  public dataStateChange(state: DataStateChangeEvent): void {
  
    this.state = state;
    this. getList();
  }
  
  private fitColumns(): void {
    
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
}
