<form [formGroup]="addNewOrderForm" class="popup-card-width add-new-user">

      <mat-dialog-content>
      <div fxLayout="row" fxLayoutAlign="start center">
         <div fxLayout="column">
            <h4 class="mrgn-b-none">{{title}}</h4>
         </div>
      </div>

      <mat-divider></mat-divider>

      <div class="gene-card-content">
         <div class="trade-crypto crypto-input-prefix">
            <div class="pad-t-sm">
               <div class="padding-input-form">
                  <div class=' width100 col-md-6 col-xm-12'>
                     <mat-button-toggle-group 
                        name="fontStyle" (change)="onChangeOrderType($event.value)"
                         [(ngModel)]="auction.OrderType"
                         [disabled]='isUpdate && false'  
                        name="OrderType" aria-label="Font Style"
                         formControlName="OrderType"
                        class="form-control">
                        <mat-button-toggle class="col-md-6" style="margin: 1px;" value="1"
                           [class.buy]="auction.OrderType == 1" class="form-control">
                           {{this.translate('TranactionBuy')}}
                        </mat-button-toggle>
                        <mat-button-toggle value="2" class="form-control"
                           [class.sell]="auction.OrderType == 2">
                           {{this.translate('TranactionSell')}}</mat-button-toggle>
                     </mat-button-toggle-group>
                  </div>           
 
               <mat-form-field class="width50 form-field " appearance="outline" >
                  <mat-label>{{ 'Auction Type' | translate }}</mat-label>
                  <mat-select [(ngModel)]="auction.AuctionType" name="AuctionType" 
                  (ngModelChange)="onChangeCompanyByType($event)"
                  (selectionChange)="onAuctionTypeSelect($event)"
                     [disabled]='isUpdate'    formControlName="AuctionType"> 
                     <mat-option *ngFor="let auctionType of  auctionTypes" [value]="auctionType.Id">
                        <span  *ngIf="isRtl()">{{auctionType.NameAr}}</span>
                        <span	  *ngIf="!isRtl()">{{auctionType.NameEn}}</span>                       
                     </mat-option>
                     </mat-select>
               </mat-form-field>


               <mat-form-field class="width50 form-field "
                   appearance="outline" *ngIf="!this.isBroker()">
                  <mat-label>{{ 'Company' | translate }}</mat-label>
                  <mat-select 
                  [(ngModel)]="auction.CompanyID"
                  (selectionChange)="onChangeCompany($event)"   
                  name="CompanyId"
                  [disabled]='isUpdate'  
                  formControlName="companyId"  >  
                     <mat-label>{{ 'Company' | translate }}</mat-label>
                     <mat-option>
                        <ngx-mat-select-search name="company" formControlName="companyName"
                           placeholderLabel="{{translate('Enter At Least One Character')}}"
                           noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                           (ngModelChange)="onChangeCompany($event)">
                        </ngx-mat-select-search>
                     </mat-option>
                     <mat-option  *ngFor="let company of  filteredCompanies" [value]="company.Id">
                        {{isRtl()?company.NameAr:company.Name}}</mat-option>
                  </mat-select>
               </mat-form-field>  

               <!-- <mat-form-field class="width50 form-field "
               appearance="outline" *ngIf="!this.isBroker()">
                  <mat-label>{{ 'Company' | translate }}</mat-label>
                     <mat-select 
                        [(ngModel)]="auction.CompanyID"
                        (selectionChange)="onChangeCompany($event)"   
                           name="CompanyId"
                           [disabled]='isUpdate'  
                        formControlName="companyId"  >  
                     <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                     {{company.Name}}</mat-option>
                  </mat-select> 
               </mat-form-field> -->


               <mat-form-field appearance="outline" class="width100 form-field"                 
               *ngIf="companyType!=CompanyTypeEnum.B">
                  <mat-label>{{ 'Client' | translate }}</mat-label>
                  <mat-select [disabled]='isUpdate'   formControlName="MainClient"  name="ClientID"
                     [(ngModel)]="auction.MainClient" [placeholder]="'Client' | translate ">
                     <mat-label>{{ 'Client' | translate }}</mat-label>
                     <mat-option>
                        <ngx-mat-select-search name="itemName" formControlName="itemName"
                           placeholderLabel="{{translate('Enter At Least One Chars To Search')}}"
                           noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                           (ngModelChange)="filterclients($event)">
                        </ngx-mat-select-search>
                     </mat-option>
                     <mat-option *ngFor="let client of clients" [value]="client.Id">
                        {{isRtl()?client.NameAr:client.NameEn}}</mat-option>
                  </mat-select>
               </mat-form-field>              
               
               <mat-form-field class="width50 form-field" appearance="outline">
                  <mat-label> {{ 'Main Market' | translate }}</mat-label>
                  <mat-select  [(ngModel)]="mainMarketId"  name="mainMarketId"
                  (selectionChange)="onChangeMainMarket($event)"
                        formControlName="MainMarketId"  
                        [disabled]="isUpdate"  > 
                        <mat-option  *ngFor="let market of  mainMarkets " [value]="market.Id">

                        <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
                        <span 	*ngIf="isRtl()">{{market.NameAr}}</span> 
                        </mat-option>
                  </mat-select> 
              </mat-form-field>
                      
            <mat-form-field class="width50 form-field" appearance="outline">
                      <mat-label> {{ 'Sub Market' | translate }}</mat-label>
                      <mat-select  [(ngModel)]="auction.MarketId"  name="Market"
                           (selectionChange)="onChangeSubMarket($event.value)" 
                            [disabled]="isUpdate" 
                            formControlName="MarketId"  > 
                           <mat-option  *ngFor="let market of  subMarkets" [value]="market.Id">

                            <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
                           <span *ngIf="isRtl()">{{market.NameAr}}</span> 
                            </mat-option>
                      </mat-select> 
             </mat-form-field>

             <mat-form-field appearance="outline" class="width50 form-field " >
                     <mat-label>{{ 'Symbol' | translate }}</mat-label>
                     <mat-select [(ngModel)]="auction.SymbolCode" name="SymbolCode"
                       formControlName="SymbolCode" 
                       [disabled]='isUpdate'  
                       (selectionChange)="onChangeSymbol($event.value)" 
                        [placeholder]="'Symbol' | translate ">
                        <mat-label>{{ 'Symbol' | translate }}</mat-label>
                        <mat-option>
                           <ngx-mat-select-search name="symbolName"
                              formControlName="symbolName"
                              [disabled]='isUpdate'                        
                              placeholderLabel="{{translate('Enter At Least One Character')}}"
                              noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                              (ngModelChange)="filterSymboles($event)">
                           </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let symbol of  symbolsFilter" [value]="symbol.SymbolCode">
                           {{symbol.symbolText}}</mat-option>
                     </mat-select>
               </mat-form-field>

               <mat-form-field class="width50 form-field " appearance="outline">
                     <mat-label>{{ 'Warehouse' | translate }}</mat-label>
                     <mat-select [(ngModel)]="auction.StoreId" name="StoreId"  [disabled]='isUpdate'    formControlName="StoreId">
                        <mat-option *ngIf="auction.OrderType == 1" [value]=null>--{{ 'none' | translate }}--</mat-option>  
                        <mat-option *ngFor="let warehouse of  warehouses" [value]="warehouse.StoreID">
                           {{warehouse.StoreNameEn}}</mat-option>
                       </mat-select>
               </mat-form-field>
 
                  <!-- <mat-form-field class="width50 form-field " appearance="outline">
                     <mat-label>{{ 'Unit' | translate }}</mat-label>
                     <mat-select [(ngModel)]="auction.QtyUnit" name="QtyUnit"  [disabled]='isUpdate'      formControlName="QtyUnit">
                       
                        <mat-option *ngFor="let unit of  units" [value]="unit.UnitID">
                                           
                           <span	class="font-normal" *ngIf="isRtl()">{{unit.UnitNameAr}}</span>
                           <span	class="font-normal" *ngIf="!isRtl()">{{unit.UnitNameEn}}</span>                       
                        
                        </mat-option>
                       </mat-select>
                  </mat-form-field> -->
                  <mat-form-field class="width33 form-field " appearance="outline" >
                     <mat-label>{{ 'Currency' | translate }}</mat-label>
                     <input class="form-control" matInput placeholder="{{ 'Currency' | translate }}"
                        formControlName="Currency" readonly [(ngModel)]="SymbolCurrency" name="Currency">
                 </mat-form-field>       
               <mat-form-field class="width33 form-field " appearance="outline">
                     <mat-label>{{ 'Price' | translate }}</mat-label>
                     <input class="form-control" 
                     readonly='{{this.isUpdate && false }}'  matInput type="number" placeholder="Price" formControlName="Price"
                        [(ngModel)]="auction.Price" name="Price">
                  </mat-form-field>
                  <mat-form-field class="width33 form-field " appearance="outline"
                  >
                  <mat-label>{{ 'Price Limit' | translate }}</mat-label>
                  <input class="priceLimit form-control" matInput type="text" placeholder="Price"
                     formControlName="PriceLimit" [readonly]="true" [(ngModel)]="PriceLimit"
                     name="PriceLimit">
                </mat-form-field>

               <mat-form-field id="matchType" class="width33 form-field" appearance="outline">
                  <mat-label > {{ 'Matching Type' | translate }}</mat-label>
                  <!-- <mat-label *ngIf="IsAllowCapping"> {{ 'Cap' | translate }}</mat-label> -->
                  <mat-select  [(ngModel)]="auction.MatchingType" 
                   name="MatchingType" formControlName="MatchingType" 
                   [disabled]="disableMatchingType" 
                   (selectionChange)="onChangeMatchingType($event)" > 
                       <mat-option  *ngFor="let matchingType of  matchingTypes" [value]="matchingType.id">
                        <span >{{ matchingType.name | translate }}</span>                       
                      </mat-option>
                  </mat-select> 
               </mat-form-field>

               <mat-form-field class="width33 form-field " appearance="outline">
                  <mat-label>{{ 'Qty' | translate }}</mat-label>
                     <input class="form-control" 
                        readonly='{{this.isUpdate && false }}'
                        matInput type="number" placeholder="{{ 'Qty' | translate }}" min="1" formControlName="Qty"
                           [(ngModel)]="auction.Qty" name="Qty">
               </mat-form-field>

               <mat-form-field class="width33 form-field "
                        *ngIf="IsAllowCapping" appearance="outline">
                        <mat-label>{{ 'CapQty' | translate }}</mat-label>
                  <input class="form-control" 
                     readonly='{{this.isUpdate && false }}'
                     matInput type="number" placeholder="{{ 'CapQty' | translate }}" min="1" formControlName="CapQty"
                     [(ngModel)]="auction.CapQty" name="CapQty" >
               </mat-form-field>

               <mat-form-field class="width100 form-field" appearance="outline"> 
                     <mat-label>{{ 'Description' | translate }}</mat-label>
                     <input class="form-control" 
                        matInput  placeholder="Note" formControlName="Note"
                        [(ngModel)]="auction.Note" name="note">     
               </mat-form-field>

               <mat-form-field class="width50 form-field " appearance="outline">
                     <mat-label>{{ 'Open Date' | translate }}</mat-label>
                     <input matInput
                           placeholder="Choose a date"    
                           [min]="minDate" 
                           [disabled]='isUpdate && false' 
                           formControlName="OpenDate"
                           [matDatepicker]="picker2"
                           [(ngModel)]="auction.OpenDate" name="OpenDate" (click)="picker2.open()">
                     <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                     <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

               <mat-form-field class="width50 form-field " appearance="outline">
                     <mat-label>{{ 'Close Date' | translate }}</mat-label>
                     <input matInput 
                           placeholder="Choose a date"
                           formControlName="CloseDate" 
                           [matDatepicker]="picker3"
                           [(ngModel)]="auction.CloseDate" 
                           [min]="minDate" 
                           name="CloseDate" 
                           (click)="picker3.open()">
                     <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                     <mat-datepicker #picker3 (closed)="validationDate()"></mat-datepicker>
               </mat-form-field>

               <mat-form-field class="width50 form-field " appearance="outline">
                  <mat-label>{{ 'Open Time' | translate }} </mat-label> 

                  <input matInput
                        name="OpenTime"
                        [format]="24"
                        formControlName="OpenTime"
                        [(ngModel)]="auction.OpenTime"                 
                        [ngxMatTimepicker]="pickerTimeA"
                        [value]="auction.OpenTime"
                        [disabled]="isDisabled"
                        (click)="pickerTimeA.open()">
                  <mat-icon matSuffix  (click)="pickerTimeA.open()">watch_later</mat-icon>
                 </mat-form-field>
                 <ngx-mat-timepicker  (timeSet)="onStartTimeset($event)" #pickerTimeA  > 
              </ngx-mat-timepicker>
<!-- 
                  <mat-form-field class="width33 form-field " appearance="outline">
							<mat-label>{{ 'Duration Time' | translate }} </mat-label> 
							<input matInput
							formControlName="DurationTime" 
							[format]=24
							name="DurationTime" 
							[value]="auction.DurationTime"
								   [(ngModel)]="auction.DurationTime"
								   [ngxMatTimepicker]="pickerTimeC"    />
							<mat-icon matSuffix  (click)="pickerTimeC.open()">
								watch_later
							</mat-icon>
						</mat-form-field>
						<ngx-mat-timepicker  	#pickerTimeC  (timeSet)="onDurationTimeset($event)"  ></ngx-mat-timepicker>-->

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Close Time' | translate }} </mat-label> 
							<input matInput
                           formControlName="CloseTime" 
                           [format]="24"
                           name="CloseTime" 
                           [value]="auction.CloseTime"
								   [(ngModel)]="auction.CloseTime"
								   [ngxMatTimepicker]="pickerTimeB"
                           (click)="pickerTimeB.open()">
							<mat-icon matSuffix  (click)="pickerTimeB.open()">
								watch_later
							</mat-icon>
						</mat-form-field>

						 <ngx-mat-timepicker  (timeSet)="onEndTimeset($event)"  #pickerTimeB >     
                  </ngx-mat-timepicker>
               </div>
            </div>
         </div>
      </div>
      <!-- (click)="dialogRef.close()" -->
      </mat-dialog-content>
      <mat-dialog-actions class="padding-input-form pad-b-none">
         <div *ngFor="let error of errors">
            <label class="error-label">{{error}}</label>
            <br/>
         </div>
         <button (click)="onFormSubmit()" type="Submit" [disabled]="!addNewOrderForm.valid || !isEnable"
         mat-raised-button color="warn">{{ 'SUBMIT' | translate }}</button>
         <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }}</button>
         <mat-checkbox class="example-margin" [(ngModel)]="auction.IsAutoExecute" formControlName="IsAutoExecute"  > {{'Auto Execute' | translate}}</mat-checkbox>

      </mat-dialog-actions>
</form>