import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 
import { ClientService } from './services/client.service';
import { ClientApi } from './api/client.api';
import { clientManagmentRoutes } from './client-management.routing';
import {ClientListComponent} from './containers/client-list/client-list.component';
import{ClientFormComponent} from './components/client-form/client-form.component';
import{ClientStoreFormDialogComponent} from './components/client-store-form-dialog/client-store-form-dialog.component';
import{ClientStoreListComponent} from './components/client-store-list/client-store-list.component';
import{ClientCompanyFormDialogComponent} from'./components/client-company-form-dialog/client-company-form-dialog.component';
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClientCompanyListComponent  } from './components/client-company-list/client-company-list.component';
import { ClientAttachmentsComponent  } from './components/client-attachments/client-attachments.component';

import{ClientSettingsComponent}from './components/client-settings/client-settings.component';
import { ClientStoreCompanyFormDialogComponent } from './components/client-store-company-form-dialog/client-store-company-form-dialog.component';
import { SharedModule } from './../Shared/shared.module';
import{ClientBankComponent } from './components/client-bank/client-bank.component';
import {ClientSearchFormDialogComponent } from './components/client-search-form-dialog/client-search-form-dialog.component';
import{ClientCommentsDialogComponent}from './components/client-comments-dialog/client-comments-dialog.component';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { MatBadgeModule } from '@angular/material/badge';
import { FileUploadModule } from 'ng2-file-upload';



@NgModule({
  imports: [       FormsModule,  ReactiveFormsModule, 
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(clientManagmentRoutes),

    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule, 
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      FileUploadModule,
      MatProgressBarModule,
      TranslateModule, 
      PerfectScrollbarModule,
      SharedModule ,
      MatBadgeModule,
      GridModule, DragDropModule,ResizableModule
  ],
  declarations: [ 

    ClientListComponent,ClientFormComponent,ClientStoreFormDialogComponent,
    ClientStoreListComponent,ClientCompanyFormDialogComponent,
    ClientCompanyListComponent,ClientStoreFormDialogComponent, 
    ClientBankComponent,
    ClientSearchFormDialogComponent,
    ClientCommentsDialogComponent,
    ClientStoreCompanyFormDialogComponent,
    ClientSettingsComponent,
    ClientAttachmentsComponent
  ], 
  providers: [ DatePipe,ClientApi ,ClientService]
})
export class ClientManagementModule { }