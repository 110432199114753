
<form class="popup-card-width add-new-user auction-pending">

	<mat-dialog-content>
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" >


<h4 class="mrgn-b-none">{{title | translate}}</h4>
          </div>

          <span fxFlex></span>
          <mat-card-actions class="mat-pad-none margin-none">

<button mat-raised-button mat-button-sm color="primary" class="addOrderBtn" (click)="SavePreexecutedTrade()" *ngIf="auth.isGranted(_permissions.SavePreexecutedTrade)">
{{ 'Execute Trade' | translate }}
</button>

          </mat-card-actions>
      </div>
      <mat-divider></mat-divider>
  </div>
		<div class="gene-card-content">
	<kendo-grid 

#grid  
[pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" 
[pageable]="true" 
(dataStateChange)="dataStateChange($event,null)"
[data]="view | async"
(edit)="editHandler($event)"
(cancel)="cancelHandler($event)" (save)="saveHandler($event,null)" 
(remove)="removeHandler($event)"
 [resizable]="false"
[reorderable]="true" 
[rowClass]="rowCallback"
[filterable]="false" class="kendo-grid-list" 
>
  


<kendo-grid-column field="AuctionID" title="Id" headerClass="grid-list-column-header" [hidden]="true" >
			   
</kendo-grid-column>
 <kendo-grid-column [editable]="false" field="TradeID" title="{{ 'ID' | translate }}" width="80" headerClass="grid-list-column-header"  >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    {{ dataItem.TradeID  }}
  </ng-template>
</kendo-grid-column>
<kendo-grid-column [editable]="false" field="BuyClientNameAr" title="{{ 'Buy Client' | translate }} " width="200" headerClass="grid-list-column-header"  >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    {{ dataItem.BuyClientNameAr  }}
  </ng-template>
</kendo-grid-column>
<kendo-grid-column [editable]="false" field="SellClientNameAr" title="{{ 'Sell Client' | translate }}" width="200" headerClass="grid-list-column-header"  >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    {{ dataItem.SellClientNameAr  }}
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="TradedQty" title="{{ 'Qty' | translate }}" editor="number" width="100"  headerClass="grid-list-column-header"  >

</kendo-grid-column>
 <kendo-grid-column field="TradePrice" title="{{ 'Price' | translate }}" editor="number" width="100"  headerClass="grid-list-column-header"  >

</kendo-grid-column>
<kendo-grid-command-column title=" " width="150"  headerClass="grid-list-column-header">
  <ng-template kendoGridCellTemplate let-isNew="isNew">
   <button kendoGridEditCommand [primary]="true"
   *ngIf="auth.isGranted(_permissions.UpdatependingTradeAuctions) "><mat-icon >edit</mat-icon></button>
   <button kendoGridRemoveCommand> <mat-icon class="delete-btn  "
    *ngIf="auth.isGranted(_permissions.DeletependingTradeAuctions) "> delete  </mat-icon></button>
  
  
  
   <button kendoGridSaveCommand [disabled]="formGroup?.invalid">
      <span *ngIf="!isNew">  <mat-icon> done_all  </mat-icon></span>
      <span *ngIf="isNew">  <mat-icon   >done_all </mat-icon></span>
    </button>
    <button kendoGridCancelCommand> 
      <span *ngIf="isNew">   <mat-icon  class="cancel-btn  ">cancel </mat-icon></span>
      <span *ngIf="!isNew">  <mat-icon  class="cancel-btn  ">cancel </mat-icon></span>								
    </button>
    
  </ng-template>
</kendo-grid-command-column>
</kendo-grid>

		</div> 
	</mat-dialog-content>

</form>