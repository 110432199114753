
export const permissions = {
EnableGoldMarket: "GoldMarket:Enable",
EnableAuctions: "Auctions:Enable",
EnableDashboard: "Dashboard:Enable",
  GetUsers: "Users:Get",
  AddUsers: "Users:Add",
  EditUsers: "Users:Edit",
  SuspendUsers: "Users:Suspend",
  GetRoles: "Roles:Get",
  AddRoles: "Roles:Add",
  EditRoles: "Roles:Edit",
  DeleteRoles: "Roles:Delete",
  ManageUserRoles: "UserRoles:Manage",
  ManageRolesClaims: "RoleClaims:Manage",
  GetBrokers: "Brokers:Get",
  AddBrokers: "Brokers:Add",
  EditBrokers: "Brokers:Edit",
  DeleteBrokers: "Brokers:Delete",
  GetCompanies: "Companies:Get",
  AddCompanies: "Companies:Add",
  EditCompanies: "Companies:Edit",
  DeleteCompanies: "Companies:Delete",
  AssignBrokerToCompanies: "Companies:Brokers",

  GetOrders: "Orders:Get",
  ViewOrders: "Orders:View",
  AddOrders: "Orders:Add",
  UpdateOrders: "Orders:Update",
  CancelOrders: "Orders:Cancel",
  ViewClientOrder: "Orders:ViewClient",
  ViewBrokerOrder : "Orders:ViewBroker",
  ViewFirmOrder : "Orders:ViewFirm",

 

  GetTrades: "Trades:Get",
  GetMarketwatCh: "marketwatCh:Get",
  GetBidsAsks: "bidsasks:Get",
  GetSymbols: "Symbols:Get",
  AddSymbols: "Symbols:Add",
  EditSymbols: "Symbols:Edit",
  DeleteSymbols: "Symbols:Delete",
  GetMarkets: "Markets:Get",
  AddMarkets: "Markets:Add",
  EditMarkets: "Markets:Edit",
  DeleteMarkets: "Markets:Delete",
  AssignMarketsToSymbols: "Markets:Symbols",
  GetClients: "Clients:Get",
 
  GetPendingClients: "Clients:Get Pending",
  GetRejectClients: "Clients:Get Reject",

  GetAttchments: "Clients:Get Attchments",
  UploadClientAttchment : "Clients:Upload Attchment",
  DownloadClientAttchment : "Clients:Download Attchment",

  AddClients: "Clients:Add",
  EditClients: "Clients:Edit",
  DeleteClients: "Clients:Delete",
  AssignClientsToBanks: "Clients:Banks",
  AssignClientsToStores: "Clients:Stores",
  AssignClientsToCompanies: "Clients:Companies",

  GetAuctions: "Auctions:Get",
  AddAuctions: "Auctions:Add",
  EditAuctions: "Auctions:Edit",
  DeleteAuctions: "Auctions:Delete",
  exeCuteAuctions: "Auctions:ExeCute",
  AdjustmentAuctions: "Auctions:Adjustment",
  GetAuctionStatistics : "Auctions:GetStatistics",
  GetpendingTradeAuctions : "Auctions:Get pending Trades",
  UpdatependingTradeAuctions : "Auctions:Update pending Trades",
  DeletependingTradeAuctions : "Auctions:Delete pending Trades",
  SavePreexecutedTrade : "Auctions:Save Pre Executed Trade",
  AuctionPayment : "Auctions:Payment",
  GetBanks: "Banks:Get",
  AddBanks: "Banks:Add",
  EditBanks: "Banks:Edit",
  DeleteBanks: "Banks:Delete",
  GetStores: "Stores:Get",
  AddStores: "Stores:Add",
  EditStores: "Stores:Edit",
  DeleteStores: "Stores:Delete",
  GetInspeCtionCompany: "InspeCtionCompany:Get",
  AddInspeCtionCompany: "InspeCtionCompany:Add",
  EditInspeCtionCompany: "InspeCtionCompany:Edit",
  DeleteInspeCtionCompany: "InspeCtionCompany:Delete",
  GetStoreKeeper: "StoreKeeper:Get",
  AddStoreKeeper: "StoreKeeper:Add",
  EditStoreKeeper: "StoreKeeper:Edit",
  DeleteStoreKeeper: "StoreKeeper:Delete",
  AddAnnounCment: "AnnounCment:Add",
  EditAnnounCment: "AnnounCment:Edit",
  DeleteAnnounCment: "AnnounCment:Delete",
  GetWarehouseTransaCtion: "WarehouseTransaCtion:Get",
  BloCkWarehouseTransaCtion: "WarehouseTransaCtion:Block",
  DepositeWarehouseTransaCtion: "WarehouseTransaCtion:Deposite",
  Default: 'resources:access',
  ISBroker: 'IS:Broker',
  ISStoreKeeper: 'IS:StoreKeeper',
  ISEMX: 'IS:EMX',
  ISCooperative: 'IS:Cooperative',
  ISCooperativeManager: 'IS:CooperativeManager',
  DeleptWarehouseTransaction: "WarehouseTransaction:Depletion",
  WithdrawWarehouseTransaction: "WarehouseTransaction:Withdraw",
  ApproaveTransfereWarehouseTransaction: "WarehouseTransaction:TransferApprove",
  GetWarehouseTransaction: "WarehouseTransaction:Get",
  TransferWarehouseTransaction: "WarehouseTransaction:Transfer",
  DepositWarehouseTransaction: "WarehouseTransaction:Deposite",
  GetClientsPosition : "Warehouse:ClientPosition",
  GetStoreSymbolQty : "Warehouse:StoreSymbolQty",
  AddStoreSymbol:"StoreSymbol:Add",
  RemoveStoreSymbol:"StoreSymbol:Remove",
  AddStoreSection:"StoreSection:Add",
  RemoveStoreSection:"StoreSection:Remove",
   
  ApproveClients: "Clients:Approve",
  RejectClients : "Clients:Reject",
  AssginToRelationCodeClient : "Clients:AssginToRelationCodeClient",

  GetSystemSettings : "SystemSettings:GetSystemSettings",
  UpdateSystemSettings : "SystemSettings:UpdateSystemSettings",
  GetMarketSettings : "Markets:Settings",
  UpdateMarketSettings : "Markets:UpdateSettings",
  KillSession: "Users:KillSession" ,
  UpdateCategory: "Category:Update",
  DeleteCategory : "Category:Delete",

  UpdateSector : "Sector:Update",
  DeleteSector : "Sector:Delete",

  
  UpdateSymbolUnit : "SymbolUnit:Update",
  DeleteSymbolUnit : "SymbolUnit:Delete",

  GetCommissions   : "Commissions:Get",
  AddCommissions    : "Commissions:Add",
  EditCommissions  : "Commissions:Edit",
  DeleteCommissions : "Commissions:Delete",
  // AssignCommissionToMarket  :"Markets:Commissions",
  // ManageClientAttchment :"ManageClientAttchment"

  GetCategories   : "Lookups:Categories",
  GetSectors   : "Lookups:Sectors",
  GetUnits   : "Lookups:Units",
  UpdateMarketSessionType : "Lookups:UpdateMarketSessionType",
  GetMarketSessionTypes : "Lookups:GetMarketSessionTypes",
  DeleteMarketSessionType :"Lookups:DeleteMarketSessionType",
  
  GetJobTitles : "Lookups:GetJobTitles",
  GetDepartments : "Lookups:GetDepartments",

  GetReasonStatus: "Lookups:GetReasonStatus",
  UpdateReasonStatus : "Lookups:UpdateReasonStatus",
  
  GetEMXUsers : "EMX:Get Users",
  AddEMXUser : "EMX:Add User",
  UpdateEMXUser : "EMX:Update User",
  DeleteEMXUser : "EMX:Delete User",


  GetReportTrades  : "Report:Trades",
  GetReportOrders  : "Report:Orders",
  RunCalculateCommissions :"Monitor Services:Run Calculate Commissions",
  RunEndOfPreOpenOrder : "Monitor Services:Run End Of Pre OpenOrder",
  

   GetMarketCalendar:  "Markets:GetMarketCalendar",
   UpdateMarketCalendar : "Markets:UpdateMarketCalendar",
   DeleteMarketCalendar : "Markets:DeleteMarketCalendar",
   GetAttachmentTypes : "Lookups:GetAttachmentTypes",
   UpdateAttachmentType : "Lookups:UpdateAttachmentType",
   GetPropertiesStatusLogs : "Settlement:Get",
   UpdateEntityOperations : "Settlement:Update",
   DownloadOrderAttachment:"Orders:Download Attachments",
   UpdateOrderAttachment:"Orders:Update Attachments",

GoldMarketAddOrders: "GoldMarket:Add Orders",
GoldMarketAddClients: "GoldMarket:Add Clients",
GetGoldMarketWatchs: "GoldMarket:Get Market Watchs",
GetGoldMarketSymbols: "GoldMarket:Get Symbols",
GetGoldMarketTrades: "GoldMarket:Get Trades",
GetGoldMarketOrders: "GoldMarket:Get Orders",
GoldMarketViewChart: "GoldMarket:View Chart",
GetAuctionTypes : "Lookups:Get Auction Types",
 GetAuctionsSettings : "Lookups:Get Auction Settings",
AddAuctionType : "Lookups:Add Auction Type" ,
UpdateAuctionType : "Lookups:Update Auction Type" ,
GetCompanyTypes : "Lookups:GetCompanyTypes",
AddUpdateCompanyType : "Lookups:Add Update CompanyType",
SaveAuctionStoreFarmer : "StoresFarmer:Save Auction Store Farmer",

 GetCooperatives : "Cooperative:Get",
 AddCooperative : "Cooperative:Add",
 EditCooperative : "Cooperative:Update",
 DeleteCooperative : "Cooperative:Delete",
 SuspendCooperative : "Cooperative:Suspend",
 UnsuspendCooperative : "Cooperative:Unsuspend", 

  GetFarmers : "Cooperative:Get Farmer" ,
  AddFarmer : "Cooperative:Add Farmer" ,
  EditFarmer : "Cooperative:Update Farmer" ,
  DeleteFarmer : "Cooperative:Delete Farmer"  ,

   GetCooperativeReceipts : "Cooperative:Get Cooperative Receipt",
   AddCooperativeReceipt : "Cooperative:Add Cooperative Receipt",
   EditCooperativeReceipt : "Cooperative:Update Cooperative Receipt",
   DeleteCooperativeReceipt : "Cooperative:Delete Cooperative Receipt" ,
   EnableNotification:"Notification:Enable",
   GetNews:"Notification:Get News",
   EnableChat:"Chat:Enable",
   GetReceiptTransactions : "Cooperative:Get Receipt Transaction",
   AddReceiptTransaction :"Cooperative:Add Receipt Transaction",
   EditReceiptTransaction :"Cooperative:Update Receipt Transaction",
   DeleteReceiptTransaction : "Cooperative:Delete Receipt Transaction",
   GenerateReceiptAuction : "Cooperative:Generate Receipt Auction" ,
   RequestReceiptAuction:"Cooperative:Active Cooperative Receipt",
   RejectReceiptAuction: "Cooperative:Rejected Receipt" ,
   GetReceiptFarmerReport : "Cooperative:Receipt Farmer Report" ,
   GetReportCooperatives : "Cooperative:Cooperatives Report",
   
  GetCooperativeManagers : "Cooperative Manager:Get",
  AddCooperativeManager : "Cooperative Manager:Add",
  UpdateCooperativeManager : "Cooperative Manager:Update",
  DeleteCooperativeManager : "Cooperative Manager:Delete", 
  GetCooperativeGroups : "Cooperative Group:Get",
  AddCooperativeGroup  :"Cooperative Group:Add",
  UpdateCooperativeGroup : "Cooperative Group:Update",
  DeleteCooperativeGroup : "Cooperative Group:Delete",
  CompanyChangeStatus : "CompanyChangeStatus:Enable",

  GetCurrency : "Lookups:Get Currency",
  AddCurrency  : "Lookups:Add Currency",
  UpdateCurrency  : "Lookups:Update Currency",
  DeleteCurrency  : "Lookups:Delete Currency",


  IsEnableStatements : "Settlement:Enable",
  GetClientStatements : "Settlement:Get Client Statements",
  CreateClientStatement : "Settlement:Create Client Statement",
  UpdateClientStatement : "Settlement:Update Client Statement",
  ApproveClientStatement : "Settlement:Approve Client Statement",
  RejectClientStatement : "Settlement:Reject Client Statement",
  CancelClientStatement : "Settlement:Cancel Client Statement",
  GetUnSettlementsTrades : "Settlement:Get UnSettlements Trades",
  GetUnSettlementsClients : "Settlement:Get UnSettlements Clients",
  GetBankMasrLogs : "Settlement:Bank Misr Logs",
  GetClientBalances : "Settlement:Get Client Balances",
  SaveSettlementTrade : "Settlement:Save Settlement Trade",
  CancelSettlementTrade : "Settlement:Cancel Settlement Trade",
  UploadFileClientStatements: "Settlement:Upload Client Statement Disk",

}  

 