import { WarehouseApi } from './../../api/warehouse.api';
import { DemoDialog } from './../../../components/dialog/dialog.component';
import { StoreSectionApi } from './../../api/storesection.api';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { StoreSectionDto, StoreTypeDto } from 'app/models/store';
import { ToastrService } from 'ngx-toastr';
import { SetupApi } from 'app/Shared/api/setup.api';
import { AppComponentBase } from 'app/app-component-base';
 

@Component({
  selector: 'ms-store-section-form-dialog',
  templateUrl: './store-section-form-dialog.component.html',
  styleUrls: ['./store-section-form-dialog.component.scss']
})
export class   StoreSectionFormDialogComponent extends AppComponentBase
implements OnInit {

  StoreTypes:StoreTypeDto[]=[];
  title: string;
  storeSection:StoreSectionDto=new StoreSectionDto();
  storeSectionForm: FormGroup;
  public lookups: StatusLookupDto=new StatusLookupDto();
  isUpdate :boolean;
  errors:any; 
   
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DemoDialog>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _setupApi:SetupApi,
    private _storeSectionService:StoreSectionApi,
    injector:Injector,
    private _wareHouseService:WarehouseApi) { 
      super(injector);
      this.storeSection = data.storeSection;
  }
    onItemSelect(item: any) {
      console.log(item);
    }
    onSelectAll(items: any) {
      console.log(items);
    }
    ngOnInit(): void {
      this._setupApi.
      getStatusLookup()
      .subscribe(res=>{this.lookups=res
      //  this.lookups.StoreTypes=[{StoreTypeId:1,Name:1}]
      });


      this._wareHouseService.getStoreTypes().subscribe((res)=>{
        this.StoreTypes = res;
      })
    this.storeSectionForm = new FormGroup({
      SectionNameEn: new FormControl({ value: 'SectionNameEn' },Validators.compose([Validators.maxLength(50),Validators.required])),
      SectionnameAr: new FormControl({ value: 'SectionnameAr' }, Validators.compose([Validators.maxLength(50),Validators.required])),
      StoreTypeID: new FormControl({ value: 'StoreTypeID' }, Validators.compose([Validators.maxLength(200),Validators.required])),
      SectionArea: new FormControl({ value: 'SectionArea' }, Validators.compose([Validators.required])),
     });
    this.title =this.storeSection.SectionID>0? "Update Section": "Add Section";
  }

  
  close() {
    this.dialogRef.close(false);
  } 

  onFormSubmit() {
    this._storeSectionService
    .AddOrupdateStoreSection(this.storeSection,this.storeSection.StoreID)
    .subscribe(res=>{this.toastr.success(this.translate("Success") )

  },(err)=> this.toastr.error(this.translate(err.error[0])));
   
  this.dialogRef.close();
  }

 

}
