<form class="popup-card-width add-new-user order-ticket">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title | translate}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="gene-card-content">
			<div class="bank-details">

				<div class="mrgn-b-xs" mat-card-widget fxLayout="row wrap" fxLayoutAlign="space-between start"
					fxLayoutGap="15px">
					<div fxFlex.gt-sm="calc(100% - 180px)" fxFlex.gt-xs="100" fxFlex="100">


						<div class="font-bold">
							<div class="mrgn-b-xs">
								 <span fxFlex.gt-sm="35" fxFlex.gt-xs="35" fxFlex="35">	{{'Date' | translate }} :</span>
							     <span fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65" class="font-normal">{{order.OrderDate| date: 'dd/MM/yyyy'}}</span>
							</div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="35" fxFlex.gt-xs="35" fxFlex="35">{{'Client' | translate }} : </span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="isRtl()">{{order.ClientNameAr}}</span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="!isRtl()">{{order.ClientNameEn}}</span>
							</div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="35" fxFlex.gt-xs="35" fxFlex="35">{{'Order Type' | translate }} : </span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="order.OrderType===1">{{ 'TranactionBuy' | translate }}</span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="order.OrderType===2">{{ 'TranactionSell' | translate }}</span>
							</div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="35" fxFlex.gt-xs="35" fxFlex="35" >{{'Symbol' | translate }} : </span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="isRtl()">{{order.SymbolNameAr}}</span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="!isRtl()">{{order.SymbolNameEn}}</span>
							</div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="35" fxFlex.gt-xs="35" fxFlex="35">{{'Warehouse' | translate }} :</span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="isRtl()">{{order.StoreNameAr}}</span>
								<span	fxFlex.gt-sm="65" fxFlex.gt-xs="65" fxFlex="65"  class="font-normal" *ngIf="!isRtl()">{{order.StoreNameEn}}</span>
							</div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="40"> {{'Minimum Fill' | translate}} :</span>
								 <span  fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="60"  class="font-normal">{{order.MinimumFill}}</span></div>

						</div>
					</div>
					<div class="price-wrap" fxFlex.gt-sm="160px" fxFlex.gt-xs="100" fxFlex="100">
						<div class="font-bold">
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="40" >  {{'Qty' | translate }} :</span> 
								<span  fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="60"  class="font-normal">{{order.OrderQty |	number}}</span></div>
							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="40">  {{'Price' | translate}} :</span>  
								 <span  fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="60"  	class="font-normal">{{order.OrderPrice | number }}</span></div>

							<div class="mrgn-b-xs">
								<span fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="45" >{{'Value' | translate}} :</span>   
								 <span fxFlex.gt-sm="55" fxFlex.gt-xs="55" fxFlex="55"  class="font-normal">{{order.OrderQty * order.OrderPrice | number }}</span></div>
						</div>


						<div class="button-wrap">
							<button class="gene-block mrgn-b-sm full-wid" mat-raised-button color="warn" [disabled]="!isEnable"
							(click)="onFormSubmit()">{{ 'CONFIRM' | translate }}</button>
							<button class="gene-block mrgn-b-sm full-wid" mat-raised-button color="primary"
								(click)="close()">{{'CLOSE' | translate}}</button>


						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->

	</mat-dialog-content>

</form>