import { Component, Inject, Injector, Input, NgZone, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { OrderManagementApi } from 'app/order-management/api/order-management.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { OrderDto } from 'app/models/order';
 import { DatePipe } from '@angular/common';
import { DataStateChangeEvent, GridComponent, GridDataResult, GridItem, RowClassArgs } from '@progress/kendo-angular-grid';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
 import { State } from '@progress/kendo-data-query';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { map, take } from 'rxjs/operators';
import {  OrderSatausEnum, MarketStatusEnum, EntityTypeEnum } from 'app/core/enums';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
 
import { OrderHistoryComponent } from 'app/order-management/components/order-history/order-history.component';
import { AuctionOrderTicketDialogComponent } from '../auction-orderTicket-dialog/auction-orderTicket-dialog.component';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { AuctionViewDto } from 'app/models/auction';
import { FileAttachmentDto } from 'app/models/client';

import { EnumHelpers } from 'app/helper/EnumHelpers';
import { Helpers } from 'app/helper/helpers';
import { TempTradeDto } from 'app/models/TempTrade';
import { number } from 'echarts';
import { timeThursdays } from 'd3';

const createFormGroup = (dataItem) =>
new FormGroup({
  AuctionID: new FormControl(dataItem.AuctionID ),
  TradeID: new FormControl(dataItem.TradeID ),
  BuyClientNameAr: new FormControl(dataItem.BuyClientNameAr, Validators.required),
  SellClientNameAr: new FormControl(dataItem.SellClientNameAr, Validators.required),
  TradedQty: new FormControl(dataItem.TradedQty, Validators.required),
  TradePrice: new FormControl(dataItem.TradePrice, Validators.required),
});

@Component({
  selector: 'ms-auction-pending-trades-dialog',
  templateUrl: './auction-pending-trades-dialog.component.html',
  styleUrls: ['./auction-pending-trades-dialog.component.scss']
})

export class AuctionpendingTradesDialogComponent extends AppComponentBase implements OnInit {
 
  private editedRowIndex: number;
  public view: Observable<GridDataResult>;
  public formGroup: FormGroup;
  tempTradeDto:TempTradeDto= new  TempTradeDto() ;
  trades:any;
  auctionId:any;
  dialogRefd: MatDialogRef<DemoDialog>;
  public state: State = {
    skip: 0,
    take: 50,
  };
  errors: any;
  title:any;
  constructor( 
    private dialog: MatDialog, 
    private setupApi: SetupApi, 
    private Auctionservice: Auctionservice,
    private  dialogRef: MatDialogRef<AuctionpendingTradesDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers:Helpers   , injector: Injector
    ) 
    {

      super(injector);
      if ( data.auction != null) { 
          this.title="pending Trades # " +data.auction.Id ;
          this.auctionId=data.auction.Id ;
          this.getTrades(data.auction.Id );
      } 
 

  }

  ngOnInit() 
  {   }  

  getTrades(id){
 
    this.view =   this.Auctionservice.getAuctionpendingTrades(id, this.state.skip,this.state.take).pipe(
      map(data => { 
         
         let result = <GridDataResult>{ data:  data.result, total: data.count}
          return result;
      }) );  
  } 

  public dataStateChange(state: DataStateChangeEvent,id:any): void {
    this.state = state; 
    this.getTrades(this.auctionId);
  }


  
  public editHandler({ sender, rowIndex, dataItem }) {
   
    this.closeEditor(sender);

    this.formGroup = createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  
  public saveHandler({ sender, rowIndex, formGroup, isNew },receiptId): void {
    const tradeAuction = formGroup.value;
 
    //this.save(transaction, isNew);
     this.tempTradeDto.TradePrice=tradeAuction.TradePrice ;
     this.tempTradeDto. TradedQty=tradeAuction.TradedQty ;
     this.tempTradeDto. TradeID=tradeAuction.TradeID ; 
  //  this.receiptTransactionDto. SackCodeTo=tradeAuction.SackCodeTo ;
  //  this.receiptTransactionDto. SackCount==Number((tradeAuction.SackCodeTo);
     
     this.save();
    sender.closeRow(rowIndex);
  }

  save() {  
    if(this. tempTradeDto. TradeID>0){
      this. tempTradeDto.TradedQty=new Number(      this. tempTradeDto.TradedQty);
      this. tempTradeDto.TradePrice=new Number(      this. tempTradeDto.TradePrice);
      this.Auctionservice. updatependingTrade(this.tempTradeDto).subscribe(res => { 
        this.getTrades(this.auctionId);
        this.toastr.success(  this.translate("Successfully"));  
      },
        err => {   this.toastr.error(  this.translate(err.error[0]));  }
      ); 
    } 
    
  }
  delete(id:any) {  
    if(id>0){
      this.Auctionservice. deletependingTrade(id).subscribe(res => { 
        this.getTrades(this.auctionId);
        this.toastr.success(  this.translate("Successfully"));  
      },
        err => {   this.toastr.error(  this.translate(err.error[0]));  }
      ); 
    } 
    
  }


  public removeHandler({ dataItem }): void {
    this.dialogRefd = this.dialog.open(DemoDialog, {
      data: { message:this.translate('Are you sure ?')   },
      disableClose: false
    });

    this.dialogRefd.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

    if(dataItem.TradeID>0){
      this.Auctionservice. deletependingTrade(dataItem.TradeID).subscribe(res => { 
        this.getTrades(this.auctionId);
        this.toastr.success(  this.translate("Successfully"));  
      },
        err => {   this.toastr.error(  this.translate(err.error[0]));  }
      ); 
    } 
      }
   
    });

  }



  public SavePreexecutedTrade( ): void {
    this.dialogRefd = this.dialog.open(DemoDialog, {
      data: { message:this.translate('Are you sure to execute the auction ?')   },
      disableClose: false
    });

    this.dialogRefd.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

    if(this.auctionId>0){
      this.Auctionservice.savePreexecutedTrade(this.auctionId).subscribe(res => { 
        this.toastr.success(  this.translate("Successfully")); 
        this.dialogRef.close(true);
         
      },
        err => {   this.toastr.error(  this.translate(err.error[0]));  }
      ); 
    } 
      }
    //  this.dialogRef = null;
    });

  }

  public rowCallback(context: RowClassArgs) {

    
  }
}
