import { AppConsts } from 'app/core/config';
import { Observable } from 'rxjs';
import { StoreKeeperDto } from './../../../models/warehouse';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SearchRequestDto } from 'app/models/client';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from 'app/client-managment/services/client.service';
import { CompanyFormDialogComponent } from 'app/company-management/components/company-form-dialog/company-form-dialog.component';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { StatusEnum } from 'app/core/enums';
import {  TransactionLookupDto } from 'app/models/warehouse';
import { ToastrService } from 'ngx-toastr';
import { symbol } from 'd3';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppComponentBase } from 'app/app-component-base';
import { AdminService } from 'app/admin/services/admin.service';
import { permissions } from 'app/core/permission';
import { Helpers } from 'app/helper/helpers';
import { UseRoleDto } from 'app/models/role';
 
@Component({
  selector: 'ms-store-keeper-form-dialog',
  templateUrl: './store-keeper-form-dialog.component.html',
  styleUrls: ['./store-keeper-form-dialog.component.scss']
})
export class StoreKeeperFormDialogComponent extends AppComponentBase implements OnInit {


  title: string;
  storeKeeper: StoreKeeperDto = new StoreKeeperDto();
  filteredLookups: TransactionLookupDto = new TransactionLookupDto();
  lookups : TransactionLookupDto = new TransactionLookupDto(); 
  randamPassword: any;
  storeKeeperForm: FormGroup;
  statusLst:any;
  roles: any;
  errors:any;
  rolesName: any;
  companyCode:any;
  constructor( 
    public formBuilder: FormBuilder,
    private adminService: AdminService,
    private dialogRef: MatDialogRef<StoreKeeperFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _warehouseApi:WarehouseApi,
    private _helpers: Helpers,
    injector:Injector) { 

      
      super(injector);
        this.title="Store Keeper";  
    if(data.dto!=null){

         this.storeKeeper = data.dto;
         this.adminService.GetRoleByUserId( this.storeKeeper .UserId).subscribe(
          res=>{
            res.forEach(element => {  this.rolesName=element;    });
         });
    }
    else{
      this.storeKeeper.StoreID = data.StoreID;

    }
         
    }

  ngOnInit(): void {
    this.storeKeeperForm = new FormGroup({ 
      KeeperNameEn: new FormControl({ value: 'KeeperNameEn' },[Validators.required,Validators.maxLength(100)] ),
      KeeperNameAr: new FormControl({ value: 'KeeperNameAr' },[Validators.required,Validators.maxLength(100)] ),
      Username: new FormControl({ value: 'Username' },[Validators.required,Validators.maxLength(100)] ),
      Telephone: new FormControl({ value: 'Telephone' },[Validators.required] ),
      IpAddress: new FormControl({ value: '' }),
      ClientCallbackUrl: new FormControl({ value: 'ClientCallbackUrl' },[Validators.required] ),
      //Password: new FormControl({ value: 'Password' },[Validators.required] ),
     // ConfirmPassword: new FormControl({ value: 'ConfirmPassword' },[Validators.required] ),
      Email: new FormControl({ value: 'Email' },[Validators.required,Validators.email] ),
      rolesName: new FormControl({ value: '' }, Validators.compose([Validators.required]))
    });

    this.randamPassword = this._helpers.generatePassword(12);
  
    this.getRoles();

  }

  
  close() {
    this.dialogRef.close(false);
  }

    // onFormSubmit method is submit a add new user form.
 onFormSubmit() { 
      let subscription:Observable<any>;
      this.storeKeeper.ClientCallbackUrl =  AppConsts.frontEndUrl+'/account/confirm';
        if(this.storeKeeper.KeeperID>0){
 
          var userRole = new UseRoleDto();
          userRole.rolesName = [];
          userRole.userId = this.storeKeeper.UserId;
          userRole.rolesName.push(this.rolesName);
          this.adminService.addUserRoles(userRole).subscribe(ress => {
          });
          
          this._warehouseApi.updateStoreKeeper(this.storeKeeper).subscribe(() => { 
            this.dialogRef.close(null); 
            this.toastr.success ( this.translate("Successfully!"));
          },
          err =>{      this.toastr.error(this.translate(err.error[0])); } );
        }
          

        else{
          this.storeKeeper.Password = this.randamPassword;
          this.storeKeeper.ConfirmPassword = this.storeKeeper.Password;
            this._warehouseApi.insertStoreKeeper(this.storeKeeper).subscribe(res => {
            
              var userRole = new UseRoleDto();
              userRole.rolesName = [];
              userRole.userId = res;
              userRole.rolesName.push(this.rolesName);
              this.adminService.addUserRoles(userRole).subscribe(ress => {
              });

            this.dialogRef.close(null);
   
            this.toastr.success ( this.translate("Successfully!"));
          },
          err =>{   
            
      
            this.toastr.error(this.translate(err.error[0]));
            } );
        }
           
          
        
         
        
    
      
  
      }


      getRoles() {
 

        this.adminService.getRolesByType(permissions.ISStoreKeeper).subscribe(
          res=>{  
             this.roles=res; 
             this.roles = this.roles.filter(y => y.name != AppConsts.superAdminRole);
          }
         );
      }
    

}
