import { Account } from './account';
//import { Role } from './types';

export class User {
  id?: string;
  userName?: string;
  email?: string;
  roles?: string[];
  roleName?:string;
  
}


export class UserLoginDto {
  id: string;
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
  userId: string;
  roleId: string;
  phoneNumber: string;
  emailConfirmed: boolean;
  isSuspended: boolean;
  ipAddress: string;
  clientCallbackUrl: string; 
}


export class UserReuqest{
  Skip:number;
  Take:number;
  CompanyId:number;
  Role:string; 
  Type:string; 
  Status:number;
  GeneralFilter:string;
  IsActive:boolean;
}
 