import { Routes } from '@angular/router';
import { AuctionPaymentsComponent } from './containers/auction-payments/auction-payments.component';

export const operationManagmentRoutes: Routes = [
   {
      path: '',
      redirectTo: 'operations',
      pathMatch: 'full'
   },
   {
      path: '',
      children: [
         {
            path: 'auction-payments',
            component: AuctionPaymentsComponent
         }
         
      ]
   }
];