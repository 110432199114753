<form class="popup-card-width add-new-user">

     <mat-dialog-content>
          <div fxLayout="row" fxLayoutAlign="start center">
               <div fxLayout="column">
                    <h4 class="mrgn-b-none">{{title}}</h4>
               </div>
          </div>
          <mat-divider></mat-divider>
          <div class="gene-card-content">
               <div class="trade-crypto crypto-input-prefix">
                    <div class="pad-t-sm">
                         <div class="padding-input-form">
                              <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewClientForm">
                                  
                                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                                             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="49" fxFlex.gt-md="49">
                                                  <mat-form-field appearance="outline" style="font-size: small;">
                                                       <mat-label>{{ 'Nationality' | translate }}</mat-label>
                                                       <mat-select [(ngModel)]="filterOptions.NationalityID"
                                                             name="NationalityID"
                                                            formControlName="NationalityID">
                                                            <mat-option *ngFor="let nationality of  nationalities"
                                                                 [value]="nationality.NationalityID">
                                                                 <span *ngIf="isRtl()">{{nationality.NationalityAr}}</span>
                                                                 <span *ngIf="!isRtl()">{{nationality.NationalityEn}}</span>
                                                            </mat-option>
                                                       </mat-select>
                                                  </mat-form-field>
                                             </div>
                                             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="49" fxFlex.gt-md="49">
                                                  <mat-form-field appearance="outline" style="font-size: small;">
                                                       <mat-label>{{ 'Client Type' | translate }}</mat-label>
                                                       <mat-select [(ngModel)]="filterOptions.ClientType"
                                                             name="ClientType"
                                                             (selectionChange)="onChangeClientType($event)"
                                                            formControlName="ClientType">
                                                            <mat-option *ngFor="let clientType of  clientTypes"
                                                                 [value]="clientType.id">
                                                                 <span >{{clientType.name}}</span>
                                                            </mat-option>
                                                       </mat-select>
                                                  </mat-form-field>
                                             </div>
                                             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="49" fxFlex.gt-md="49">
                                                  <mat-form-field appearance="outline" style="font-size: small;">
                                                       <mat-label>{{ 'Identity Type' | translate }}</mat-label>
                                                       <mat-select [(ngModel)]="filterOptions.IdType"
                                                             name="IdentityTypeID"
                                                            formControlName="IdentityTypeID">
                                                            <mat-option *ngFor="let identityType of  identityTypes"
                                                                 [value]="identityType.Id">
                                                                 <span *ngIf="isRtl()">{{identityType.IdentityTypeAr}}</span>
                                                                 <span *ngIf="!isRtl()">{{identityType.IdentityTypeEn}}</span>
                                                            
                                                            </mat-option>
                                                       </mat-select>
                                                  </mat-form-field>
                                             </div>

                                             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="49" fxFlex.gt-md="49">
                                                  <mat-form-field appearance="outline" style="font-size: small;">
                                                       <mat-label>{{ 'Identity' | translate }}</mat-label>
                                                       <input class="form-control" matInput placeholder="Identity"
                                                       formControlName="IdentityTypeText"  [(ngModel)]="filterOptions.IdTypeTxt" name="IdentityTypeText">
                                                  </mat-form-field>
                                             </div>

                                             <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="49" fxFlex.gt-md="49"
                                             *ngIf="this.identityTypeEnum.CommercialRegister == client.IdentityTypeID">
                                                  <mat-form-field appearance="outline" style="font-size: small;">
                                                       <mat-label>{{ 'Offices' | translate }}</mat-label>
                                                       <mat-select [(ngModel)]="filterOptions.CROfficeId"
                                                             name="CROfficeId"
                                                            formControlName="CROfficeId">
                                                            <mat-option *ngFor="let crOffice of  crOffices"
                                                                 [value]="crOffice.Id">
                                                                 <span *ngIf="isRtl()">{{crOffice.NameAr}}</span>
                                                                 <span *ngIf="!isRtl()">{{crOffice.NameEn}}</span>
                                                            
                                                            </mat-option>
                                                       </mat-select>
                                                  </mat-form-field>
                                             </div>

                                        </div>
                              
                              </form>


                         </div>

                    </div>
               </div>
          </div>
          <!-- (click)="dialogRef.close()" -->
     </mat-dialog-content>
     <mat-dialog-actions class="padding-input-form pad-b-none">
          <button (click)="checkClient()" type="submit" *ngIf="auth.isGranted(_permissions.AddClients)||	auth.isGranted(_permissions.EditClients) "
               [disabled]="!addNewClientForm.valid" mat-raised-button color="warn">{{ 'Search' | translate }} </button>
          <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>

     </mat-dialog-actions>
</form>