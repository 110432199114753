import { CurrentLanguageService } from './../../../Shared/services/currentLanguage.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoService } from 'app/service/kendo/kendo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ms-language-drop-down',
  templateUrl: './language-drop-down.component.html',
  styleUrls: ['./language-drop-down.component.scss']
})
export class LanguageDropDownComponent implements OnInit {

   currentLang = 'en';
   @Output() layoutEmitter = new EventEmitter<string>();
   selectImage = 'assets/img/en.png';
	layout                : any = 'ltr';
	langArray : any [] = [
      {  
         img:"assets/img/en.png",
         name:"English",
         value	: "en"
      },     
      {  
         img:"assets/img/france.png",
         name:"French",
         value:"fr"
      },
      {  
         img:"assets/img/ar.png",
         name:"Arabic",
         value:"ar"
      }

   ];

	constructor(public translate : TranslateService,
      private currentLanguageService:CurrentLanguageService,
      private _router:Router,
      private messages: MessageService) { }

	ngOnInit() {
      if( this.currentLanguageService.currentSessionLanguage ==='ar'){

         this.layout="rtl"
         this.selectImage="assets/img/ar.png";
      }else{
         this.layout="ltr"
         if(this.currentLanguageService.currentSessionLanguage==='en'){
            this.selectImage="assets/img/en.png";
         }else{
            this.selectImage="assets/img/en.png";
         }
      }
      this.setLang(this.currentLanguageService.currentSessionLanguage);
      
	}

   public changeLanguage(lang) {
      const svc = <KendoService>this.messages;
  
      svc.language = lang;
    }
   //setLang method is used to set the language into template.
   setLang(lang) {
    this.changeLanguage(lang); 
      if(lang==='ar'){
 
         this.layout="rtl" 
      }else{
         this.layout="ltr" 
      }
 
     this.layoutEmitter.emit( this.layout); 
    if(lang!=null){
     
      for(let data of this.langArray) {
         if(data.value == lang) {
            this.selectImage = data.img;
            break;
         }
      }
     this.translate.setDefaultLang(lang);
    //  this.translate.currentLang=lang;
      this. translate.use(lang);
      

     this.currentLanguageService.setCurrentSessionLanguage(lang);
 
    }

    
     // this.translate.setDefaultLang(lang);
     
   
   }
   reloadCurrentRoute() {
   
      let currentUrl = this._router.url;
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this._router.navigate([currentUrl]);
      });
  }
}
