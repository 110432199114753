import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { MarketDto } from 'app/models/market'; 
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketFormDialogComponent } from 'app/market-managment/components/market-form-dialog/market-form-dialog.component';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
 import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SymbolWarehouseDialogComponent } from '../symbol-warehouse-dialog/symbol-warehouse-dialog.component';



@Component({
  selector: 'ms-symbol-warehouse-list',
  templateUrl: './symbol-warehouse-list.component.html',
  styleUrls: ['./symbol-warehouse-list.component.scss']
})
export class SymbolWarehouseListComponent extends AppComponentBase implements OnInit, OnDestroy {

  public items: any;
  public lookups: StatusLookupDto;
  marketId: any;
  title: any;
  public actions: any;
  public action: any;
  selectBy:any;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }
  @Input()
  symbolId: any;
 
 
  constructor(private pageTitleService: PageTitleService,
    private setupApi: SetupApi,
    private route: ActivatedRoute,
    private marketService: MarketService    ,
    private symbolService: SymbolService    ,
    private dialog: MatDialog  , 
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector); 
      this.actions = _helper.getNames(StatusEnum);
    
  
    }
    
  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.marketId = +prams.get('id'); 
 
    })



    this.getSymbolWarehouses( );

  }

  getSymbolWarehouses( ) {
  
 
    if(this.symbolId!=null){
 
      this.setupApi.GetStoreSymbols(this.symbolId).subscribe(res => { this.items = res; });
    } 

  }


  addStoreSymbol() {
  
    const dialog = this.dialog.open(SymbolWarehouseDialogComponent, {
     data: { symbolId:this.symbolId  }
   });
   dialog.afterClosed().subscribe((res) => {
        this.getSymbolWarehouses( );
   }); 

 }


  deleteStoreSymbol(store) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:  this.translate( 'would you like to delete this warehouse')+ ' # ' + store.StoreNameEn   },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.symbolService.deleteStoreSymbol(store.StoreID ,this.symbolId).subscribe((res) => {
         this. getSymbolWarehouses( );
                 this.toastr.success(this.translate("Successfully"));
        }, err => {
          this.toastr.error(this.translate("Error"));
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }

 

}

