<kendo-grid #grid  [kendoGridBinding]="items"
class="order-kendo-grid"
 
[pageable]="false"
[pageSize]="20"
[resizable]="true"
[reorderable]="true"
filterable="menu"
   [sortable]="true"
   [columnMenu]="true"
   [kendoGridSelectBy]="selectBy"
   (cellClick)="onCellClick($event)"
   (dblclick)="onDblClick()"
   [rowClass]="rowCallback"
   (dataStateChange)="onDataStateChange()"
 
   >
   <kendo-grid-column   title=""  width="40" [columnMenu]="false"
   *ngIf="auth.isGranted(_permissions.DownloadOrderAttachment)" >
       <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex" 
>
          <a class="cursor_pointer"
          *ngIf="dataItem.IsAttachment==true   &&(dataItem.CompanyID==this.companyID ||   isEMX())"
          (click)="downloadAttachment(dataItem)"> <mat-icon>cloud_download</mat-icon></a>     
     </ng-template>

   </kendo-grid-column>
<kendo-grid-column field="OrderPrice" title="Price" title="{{ 'Price' | translate }}" width="80">
<ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
<span > {{dataItem.OrderPrice | number }} </span>
</ng-template>
</kendo-grid-column>
<kendo-grid-column field="RemainingQty" title="{{ 'Qty' | translate }}" width="80">
<ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
<span   >  {{dataItem.RemainingQty | number }} </span>
</ng-template>
</kendo-grid-column> 
<!-- <kendo-grid-column field="AuctionID" title="{{ 'AuctionNumber' | translate }}">
   <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   class= "buy"> {{dataItem.AuctionID}} </span>
   </ng-template>
   </kendo-grid-column>  -->

   <kendo-grid-column field="ClientID"  title="{{ 'Client Code' | translate }}" width="100"
*ngIf="auth.isGranted(_permissions.ViewClientOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span   >
     {{dataItem.ClientCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>


<kendo-grid-column field="BrokerID"  title="{{ 'Broker Code' | translate }}" width="100"
*ngIf="auth.isGranted(_permissions.ViewBrokerOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
   <span >
     {{dataItem.BrokerCode}} 
   </span> 
 </ng-template>
</kendo-grid-column>
<kendo-grid-column field="CompanyName"  title="{{ 'Firm' | translate }}" width="110"
*ngIf="auth.isGranted(_permissions.ViewFirmOrder)">
 <ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >

   <span  *ngIf="isRtl()"> {{dataItem. CompanyNameAr}}   </span>
   <span  *ngIf="!isRtl()"> {{dataItem.CompanyName}}   </span>
 </ng-template>
</kendo-grid-column>

</kendo-grid>