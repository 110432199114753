import { Injectable } from '@angular/core';
import { BrokerDto, CompanyDto } from '../../../app/models/broker';
import { Observable } from 'rxjs';
 
import {CompanyManagementApi} from '../api/company-management.api'

@Injectable()
export class CompanyManagementService {

  
  constructor(private manageCompanyApi: CompanyManagementApi ) { }


  getCompanies(): Observable<[]> {
    return this.manageCompanyApi.getCompanies();
  }
  getCompanySymbolsByCompanyId(id:any){
    return this.manageCompanyApi.getCompanySymbolsByCompanyId(id);
  }
  getActiveCompanies(): Observable<[]> { 
    return this.manageCompanyApi.getActiveCompanies();
  }
   
  getCompanyBrokers(id:any): Observable<[]> {
    
    return this.manageCompanyApi.getCompanyBrokers(id);
  }
  searchCompanies(request:any): Observable<any> {
    
    return this.manageCompanyApi.searchCompanies(request);
  }
  getCompany(id:any): Observable<CompanyDto> {
    
    return this.manageCompanyApi.getCompany(id);
  }
  insertComapny(ComapnyRequest: any): Observable<boolean> {

    return this.manageCompanyApi.insertComapny(ComapnyRequest);
 }
 
 updateCompany(ComapnyRequest: any,id:any): Observable<boolean> {

  return this.manageCompanyApi.updateCompany(ComapnyRequest,id);
 }
 addCompanyBroker(createComapnyRequest: any,id:any): Observable<any> {

  return this.manageCompanyApi.addCompanyBroker(createComapnyRequest,id);
 }
 updateCompanyBroker(createComapnyRequest: any,id:any): Observable<any> {

  return this.manageCompanyApi.updateCompanyBroker(createComapnyRequest,id);
 }
 deleteCompany(id: any): Observable<boolean> {

  return this.manageCompanyApi.deleteCompany(id);
 }
 removeCompanyBroker(id: any ): Observable<boolean> {

  return this.manageCompanyApi.removeCompanyBroker(id);
}
changeStatusCompanyBroker(id: any ): Observable<boolean> {

  return this.manageCompanyApi.changeStatusCompanyBroker(id);
}


 
 
 
 
}