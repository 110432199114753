
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './account/services/auth.service';
import { DemoDialog } from './components/dialog/dialog.component';
 
import { permissions } from './core/permission';
import { StatusEnum, TransactionStatus, TransactionTypes } from './core/enums';
import { BrokerInfoDto } from './models/account'; 
import { UserApi } from './shared/api/user.api';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoService } from './service/kendo/kendo.service';
import { AppConsts } from './core/config';
export abstract class AppComponentBase {
  _permissions = permissions;
  _StatusEnum=StatusEnum;
  TransactionTypes=TransactionTypes;
  TransactionStatus=TransactionStatus;
  auth: AuthService;
  userApi:UserApi;
  translateService: TranslateService;
  toastr: ToastrService
   messages: MessageService;
  constructor(private injector: Injector ) {
    
    this.userApi=injector.get(UserApi);
    this.auth = injector.get(AuthService);
    this.toastr = injector.get(ToastrService);
    this.translateService = injector.get(TranslateService);
    this.messages = injector.get(MessageService);
    this.changeLanguage( this.translateService.currentLang);

  }
   

  private readonly CompanyId = 'CompanyId';
  private readonly BrokerId = 'BrokerId';
  private readonly LoginCompanyType = 'CompanyType';
  public changeLanguage(lang) {
		const svc = <KendoService>this.messages;
	
		svc.language = lang;
	  }
  getUserBrokerInfo(): any { 

    var brokerInfo =new BrokerInfoDto();
    var brokerId=sessionStorage.getItem(this.BrokerId);
    var companyId=sessionStorage.getItem(this.CompanyId);
    var companyType=sessionStorage.getItem(this.LoginCompanyType);
    
    if(brokerId==null || companyId==null ){
      var subject = new Subject<BrokerInfoDto>();
      this.userApi.getBrokerInfo().subscribe(res=>{
       if(res!=null){ 
          sessionStorage.setItem(this.CompanyId, res.CompanyId); 
          sessionStorage.setItem(this.BrokerId, res.BrokerId);
          sessionStorage.setItem(this.LoginCompanyType, res.TypeId);

          brokerInfo.BrokerId=res.BrokerId;
          brokerInfo.CompanyId=res.CompanyId;
          brokerInfo.CompanyType =res.TypeId;
      
          subject.next(brokerInfo);
        } 
   
      });
      return subject.asObservable();
    } else{
      brokerInfo.BrokerId=Number (brokerId);
      brokerInfo.CompanyId=Number (companyId);
      brokerInfo.CompanyType=Number (companyType);
      return Observable.create( observer => { observer.next( brokerInfo ) 
        observer.complete() });
    }

   
  }
  isRtl() {  
    return this.translateService.currentLang === 'ar';
  }
  isBroker() {
    let ret: boolean = false;
    let permission = permissions.ISBroker;
    if (this.auth.isPermissionValid(permission)) {
      return true;
    }
    return ret;
  }
  isEMX() {

    let ret: boolean = false;
    let permission = permissions.ISEMX;
    if (this.auth.isPermissionValid(permission)) {
      return true;
    }
    return ret;
  }
  isStoreKeeper() {

    let ret: boolean = false;
    let permission = permissions.ISStoreKeeper;
    if (this.auth.isPermissionValid(permission)) {

      return true;
    }
    return ret;
  }
  isCooperative() {
    let ret: boolean = false;
    let permission = permissions.ISCooperative;
    if (this.auth.isPermissionValid(permission)) {
        return true;
    }
    return ret;
  }
  isCooperativeManager() {
    let ret: boolean = false;
    let permission = permissions.ISCooperativeManager;
    if (this.auth.isPermissionValid(permission)) {
        return true;
    }
    return ret;
  }
  currentLang() { 
 
    return this.translateService.currentLang;
  }
  currentUserId() { 
    let ret: string  ;
    this.auth.getCurrentUser$().subscribe(res=>{
         ret=res.id;
    });
    return ret;
  }
  static unlocalizedResources = [];

  translate(key: string): string {

    
      
  if(key!=null){

      return this.translateService.instant(key);
  }   else{
    return "";
    }
  }


  confirm(subscribtion: Observable<any>, message: string, successFunc: any, errorFunc: any) {
    let dRef = this.injector.get(MatDialog);
    let demoDialogRef = dRef.open<DemoDialog>(DemoDialog, {
      data: { message: message },
      disableClose: false
    });

    demoDialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        subscribtion.subscribe((res) => {
          this.toastr.success(this.translate('Successfully'));
          if (successFunc != null)
            successFunc();
        }, err => {
          if (err.error != null && err.error.length > 0)
            this.toastr.error(this.translate(err.error[0]));
          else
            this.toastr.error("error")
         // console.log(err)
          if (errorFunc != null) errorFunc();
        });
      }
      demoDialogRef = null;
    });

  }


  enums = {

  }
}