import { CustomMenuComponent } from './components/custom-menu/custom-menu.component';
import { StatisticsCardComponent } from './components/statistics-card/statistics-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { PeriodSelectorComponent } from './components/period-selector/period-selector.component';
import{PropertiesStatusDialogComponent} from './components/properties-status-dialog/properties-status-dialog.component'
import { UserApi } from './api/user.api'; 
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatMenuModule } from '@angular/material/menu';
import {CurrentLanguageService} from './services/currentLanguage.service'
 import{FormUploadComponent} from './components/form-upload/formupload.component';
import { FilterPipe } from './pipes/filter.pipe';
import { NewViewComponent } from './components/new-view/new-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatToolbarModule,
    TranslateModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatListModule,
    MatExpansionModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule, 
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule, 
       MatMenuModule,
       FormsModule,  ReactiveFormsModule, 
  ],
  declarations: [
    PeriodSelectorComponent,
    SnackBarComponent,
    StatisticsCardComponent,
    CustomMenuComponent,
    FilterPipe,
    NewViewComponent,
    PropertiesStatusDialogComponent
  ],
  exports: [
    CommonModule,
   // ForRolesDirective,
    PeriodSelectorComponent,
    SnackBarComponent,
    StatisticsCardComponent,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    MatListModule,
    MatExpansionModule,
    MatTableModule,
    
    MatDialogModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    MatMenuModule,
    CustomMenuComponent,
    NewViewComponent,
    PropertiesStatusDialogComponent
 //   FormUploadComponent
  ],
  providers: [UserApi,CurrentLanguageService],
  entryComponents: [SnackBarComponent]
})
export class SharedModule { }
