import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import {  StatusEnum, ClientTypeEnum, IdentityExpiryEnum, ApproveStatusEnum, UserTypeEnum, EntityTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import {ClientDto, ClientModel, ClientReuqest, QueueAttachmentDto, UploadAttachmentTypeDto} from 'app/models/client';
import { ClientService } from 'app/client-managment/services/client.service';
import { AppComponentBase } from 'app/app-component-base'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientCompanyFormDialogComponent } from 'app/client-managment/components/client-company-form-dialog/client-company-form-dialog.component';
import { ClientStoreCompanyFormDialogComponent } from 'app/client-managment/components/client-store-company-form-dialog/client-store-company-form-dialog.component';
import { ClientSearchFormDialogComponent } from 'app/client-managment/components/client-search-form-dialog/client-search-form-dialog.component';
import { ClientCommentsDialogComponent } from 'app/client-managment/components/client-comments-dialog/client-comments-dialog.component';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State } from "@progress/kendo-data-query";
import { finalize, startWith, switchMap, catchError, map, filter} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';

@Component({
  selector: 'ms-client-manage-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent   extends AppComponentBase implements OnInit, OnDestroy {
 

  public lookups: StatusLookupDto;
  company: any;

  companies: any;
  stores: any;
  types: any;
  userType:any;
  title: any;
  selectBy:any;
  status:any;
  IsExpiry:any=0;
  ClientStatus=ApproveStatusEnum;
 
  isConfirmationScreen:any;
  IdentityExpiryEnum=IdentityExpiryEnum;
  searchTxt:string="";
  public actions: any;
  public action: any;
  public view: Observable<GridDataResult>;
  filteredCompanies:any;
  filteredStores:any;

  dataSources = {companies: [],
    stores:[],
    types:[],
    status:[]
  };
  
  public state: State = {
    skip: 0,
    take: 25,
  };

  dataStatus:any   ;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _setupApi:SetupApi,
    
    private _helper :EnumHelpers   ,
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector); 

      this.route.paramMap.subscribe(prams => {
 
        this.status = +prams.get('id') as number; 
        this.IsExpiry = +prams.get('IsExpiry') as   number; 
        
    
        if(this.IsExpiry>0){
          this.title =   'Expired Clients';
          this.isConfirmationScreen=false;
        }else if(this.status == ApproveStatusEnum. Approve) {
          this.title =   'Active Clients';
          this.isConfirmationScreen=false;
        }else   if(this.status == ApproveStatusEnum.AwaitApprove){
          this.title =   'Pending Clients';
           this.isConfirmationScreen=true;
        }else if(this.status == ApproveStatusEnum.Reject){
          this.title =   'Rejected Clients';
          this.isConfirmationScreen=false;
        }else{
          this.title =  'Clients';
          this.isConfirmationScreen=false;
        }
        this. refesh();
      });
    
    
    }


  filterOptions:ClientReuqest = new ClientReuqest();
  statistics:ClientModel= new ClientModel();

  ngOnInit(): void {
    this.pageTitleService.setTitle( "Clients Management");
    this._setupApi.getWarehouseLookups() .subscribe(res=>{ 
      this.dataSources.stores = res; 
      this.stores = res; 
  
     });
    this._setupApi.getActiveCompanies().subscribe(res=>{ this.dataSources.companies = res;this.companies = res; })

    this.dataSources.status= this. _helper.getNamesAndValues(StatusEnum);
    // this.filterOptions.Status=   this.status;
    this.dataSources.types=this. _helper.getNamesAndValues(ClientTypeEnum);

 
  }

  refesh(){
    this.filterOptions=new ClientReuqest();
    this.dataSources.stores=[];
    this.dataSources.companies=[];
    this.filterOptions.ApproveStatusId=this.status ;
    if(this.isBroker()){
 
      this.getUserBrokerInfo().subscribe(res=>{
      
        if(res!=null){
           this.userType= UserTypeEnum. TradingCompany;
           this.filterOptions.CompanyId=res.CompanyId ;
           this.getList();
        }
      }) 

    }else  if(this.isStoreKeeper()){
      this.userType= UserTypeEnum.Store;
      this._setupApi .getStoreKeeperInfo().subscribe(res=>{
        if(res!=null){
          this.filterOptions.StoreID=res.StoreID; 
           
         this.getList();
        } 
      }) 

    }else{
      this.userType= UserTypeEnum. EMX;
       this.getList();
    }
  }
  
  
  changePropertiesStatus(data){
 
    let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
    propertiesStatus.ActionOn=EntityTypeEnum.Client;
    propertiesStatus.ResponsibleId=data.Id;
    propertiesStatus.ResponsibleNameAr=data.NameAr;
    propertiesStatus.ResponsibleNameEn=data.NameEn;
    propertiesStatus.Status= data.Status;
    propertiesStatus.NotifiledEmail=data.Email;
    const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
      data:{propertiesStatus:propertiesStatus   }  
    });
    dialog.afterClosed().subscribe((res) => { 
 if(res!=null){
  data.Status=res;
 }

    } );
  }
  onStateChange(){
    if(this.filterOptions.GeneralFilter != null && this.filterOptions.GeneralFilter!=""
    && this.filterOptions.GeneralFilter.length>3 ){
      
      this.filterOptions. Skip = 0
     this. getList()
    }
  }
  
  public dataStateChange(state: DataStateChangeEvent): void {
  
    this.state = state;
    this. getList();
  }
  
  getList() {  
    var currentDate = new Date() ;
      this.filterOptions.Take = this.state.take;
      this.filterOptions. Skip = this.state. skip;
     // this.filterOptions.Status=   this.status;
     this.view =  this.clientService.searchClients(this.filterOptions).pipe(
        map(data => {
      
 
          data.clients.forEach(element => {
           
            element.CountComments=element.ClientComments.filter(x=>x.IsSeen==false
              && x.CreatedUserType!= this.userType).length;
             
            if(element.IdentityType.ClientType==ClientTypeEnum.Individual){ 
              element.ClientTypeTxt='Individual';
        
              if(element.Gender==true){
               element.  GenderTxt="Male";
              }else{
               element.  GenderTxt="Female";
              }
             }else{
              element.ClientTypeTxt='Corporate';
              element.  GenderTxt="-";
             }
    
  
  
    if(element.ExpiryDate!=null){
      var expiryDate  =new Date(element.ExpiryDate);
      var preExpiryDate  =new Date(element.ExpiryDate);
      var wrongExpiryDate  =new Date(element.ExpiryDate); 
      preExpiryDate.setDate(expiryDate.getDate() - 14);
      wrongExpiryDate.setMonth(expiryDate.getMonth() - 1);
          if(expiryDate <=currentDate )
          {
              element.ExpiryFlag=IdentityExpiryEnum.Expiry;
               
          }else   if(currentDate >= wrongExpiryDate  )
          {
               element.ExpiryFlag=IdentityExpiryEnum.WrongExpiry;
          }else   if(currentDate >= preExpiryDate )
          {
               element.ExpiryFlag=IdentityExpiryEnum.preExpiry;
          }else{
               element.ExpiryFlag=IdentityExpiryEnum. None;
          }
    }
  
  
  
          });
       

          this.dataSources.companies=this.companies;
          this.dataSources.stores=this.stores;
 
          if(this.IsExpiry>0){
            data.clients= data.clients.filter(x=>x.ExpiryFlag==IdentityExpiryEnum. Expiry);
          }
          if(data.clients.length==0){
            data.count=0;
          }
          this.recalculateStatistics(  data.clients);
            let result = <GridDataResult>{ data:  data.clients, total: data.count}
            return result;
        }) ); 
 
 
   
  }
  
  recalculateStatistics(clients:ClientDto[]) {
    this.statistics.Clients = clients .length;
 
    this.statistics. Corporate = clients.filter(item=>item.IdentityType.ClientType == ClientTypeEnum.Corporate)?.length;
    this.statistics. Individual = clients.filter(item=>item.IdentityType.ClientType == ClientTypeEnum.Individual)?.length;
    this.statistics. Active = clients.filter(item=>item. Status == StatusEnum.Active)?.length;
     
  }

  public rowCallback(context: RowClassArgs) {
    

    switch (context.dataItem.ExpiryFlag) {
      case IdentityExpiryEnum.Expiry:
        return { Expiry: true };
      case IdentityExpiryEnum.preExpiry:
        return { preExpiry: true };
     case IdentityExpiryEnum. WrongExpiry:
          return { Warning: true };
      default:
        return {};
    }
 
  }

  Approve(data){ 

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:this.translate('ara your sure approve this client ?') },
      disableClose: false
    });  
   this.changeStatus(data,ApproveStatusEnum.Approve);
   data.ApproveStatusEnum=ApproveStatusEnum.Approve;

  }

  Reject(data){ 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:this.translate('ara your sure reject this client ?') },
      disableClose: false
    });
   this.changeStatus(data,ApproveStatusEnum.Reject);
   data. ApproveStatusEnum=ApproveStatusEnum.Reject;

  }
  AddClients(){ 
    const dialog = this.dialog.open(ClientSearchFormDialogComponent, {
      data:{   }  
    });
    dialog.afterClosed().subscribe((res) => { 
      this.getList();
    } );

  }

  AssignClients(){ 
    const dialog = this.dialog.open(ClientCompanyFormDialogComponent, {
      data:{   }  
    });
    dialog.afterClosed().subscribe((res) => { 
      this.getList();
    } );

  }
  AssignClientStores(){ 
    const dialog = this.dialog.open(ClientStoreCompanyFormDialogComponent, {
      data:{   }  
    });
    dialog.afterClosed().subscribe((res) => { 
      this.getList();
    } );

  }
  

  changeStatus(data ,status ){
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        if(status ==ApproveStatusEnum.Approve){

          this.clientService.approveClient(data ,data .Id).subscribe(() => {
        
    this.getList();
            this.toastr.success(  this.translate("Successfully"));
          },
            err => { 
              this.toastr.error(  this.translate(err.error[0]));
          });
        }else{
          
        this.clientService.rejectClient(data ,data .Id).subscribe(() => {
        
    this.getList();
          this.toastr.success(  this.translate("Successfully"));
        },
          err => { 
            this.toastr.error(  this.translate(err.error[0]));
        });
        }
      }
      this.dialogRef = null;
    });



  }
 

  getCommentsDialog(data){
    
    const dialog = this.dialog.open(ClientCommentsDialogComponent, {
      data:{ clientId:data.Id}  
    });
    dialog.afterClosed().subscribe((res) => {
       // this.userType=
       
     } );
  
  }

  onChangeCompany(event) {

    if(this.dataSources.companies != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCompanies = this.dataSources.companies;
        else
        this.filteredCompanies = this.dataSources.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.dataSources.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onChangeStore(event) {

    if(this.dataSources.stores != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
          this.filteredStores = this.dataSources.stores;
        else
          this.filteredStores = this.dataSources.stores.filter((s) => s.StoreNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.dataSources.stores.filter((s) => s.StoreNameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }


}

