import { Routes } from '@angular/router';

import { DashboardComponent } from './containers/dashboard/dashboard.component';

export const DashboardRoutes: Routes = [{
  path: '',
  redirectTo: '', 
  pathMatch: 'full',
  component: DashboardComponent
} ];


