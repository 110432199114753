import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { AnnouncementTypeEnum } from 'app/core/enums';
import { AnnouncementDto } from 'app/models/announcement';
import { UploadAnnouncementAttatchment } from 'app/models/attachment';
import { NewsApi } from 'app/news/api/news-api';
import { SetupApi } from 'app/Shared/api/setup.api';
import { index } from 'd3';

@Component({
  selector: 'ms-new-view',
  templateUrl: './new-view.component.html',
  styleUrls: ['./new-view.component.scss']
})
export class NewViewComponent extends AppComponentBase implements OnInit {
  news: AnnouncementDto=new AnnouncementDto();
  title: string;
  allSymbol: any;
  priortyTypes: any;
  attatchements :any;
  fileAttatchmentDto: UploadAnnouncementAttatchment = new UploadAnnouncementAttatchment();
  iconList = [ // array of icon class list based on type
    { type: "xlsx", icon: "fa fa-file-excel-o" },
    { type: "pdf", icon: "fa fa-file-pdf-o" },
    { type: "jpg", icon: "fa fa-file-image-o" }
  ];

  constructor(  injector: Injector,
                private setupApi: SetupApi,
                private  newsApi: NewsApi,
                private dialogRef: MatDialogRef<NewViewComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
                ) 
                {    super(injector);
               
                  if (data.news.AnnouncementID) {
                    this.news=data.news;
                    this.priortyTypes = data.news.Priority
                  }

                }

  ngOnInit(): void {
    this.getSymbolCode(); 
    this.symbolCodeName();  
    this.getAttatchements(this.news);
 
  }
  getSymbolCode(){
    this.setupApi.getActiveSymbols().subscribe(res => {
      this.allSymbol = res;
      if(this.news.SymbolCode != null){
        var type = this.allSymbol.filter(x => x.SymbolCode == this.news.SymbolCode)
        if(this.isRtl()){
        this.news.SymbolCodeName= type[0].NameAr;
      }
        else{
        this.news.SymbolCodeName=type[0].NameEn;
      }
      }
     });
    }

  close() {
    this.dialogRef.close(false);
  }
  symbolCodeName(){
      
   }
   getAttatchements(news: any){

  if(news.AnnouncementType==AnnouncementTypeEnum.Auction){
     this.newsApi.GetAttatchementByAuctionAnnouncement(news.AnnouncementID ,news.EntityId).subscribe(res =>{
        this.attatchements = res ;
    })
  }else{
    this.newsApi.GetAttatchementByAnnouncement(news.AnnouncementID).subscribe(res =>{
      this.attatchements = res ;
    })
  }

   }
   downloadAttachment(filename, attachmentsPath ){ 
        this.fileAttatchmentDto.FileName = filename;
        this.fileAttatchmentDto.AttachmentsPath = attachmentsPath;
        this.newsApi.DownloadAttachment(this.fileAttatchmentDto);
  }
  ViewAttachment(filename, attachmentsPath ){ 
        this.fileAttatchmentDto.FileName = filename;
        this.fileAttatchmentDto.AttachmentsPath = attachmentsPath;
        this.newsApi.ViewAttachment(this.fileAttatchmentDto);
  }
   

  getFileExtension(filename) { // this will give you icon class name
    let ext = filename.split(".").pop();
    let obj = this.iconList.filter(row => {
      if (row.type === ext) {
        return true;
      }
    });
    if (obj.length > 0) {
      let icon = obj[0].icon;
      return icon;
    } else {
      return "";
    }
  }
}
