import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { LockupsManagementService } from 'app/lockups-management/services/lockups-management.service';
import { MarketService } from 'app/market-managment/services/market.service';
import { StatusEnum } from 'app/core/enums';


import { CalendarDto, MarketDto, MarketSessionDto } from 'app/models/market';
import { SymbolUnitDto } from 'app/models/StatusLookup';
import { SymbolDto, SymbolMarketDto } from 'app/models/symbol';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ms-market-calendar-dialog',
  templateUrl: './market-calendar-dialog.component.html',
  styleUrls: ['./market-calendar-dialog.component.scss']
})
export class MarketCalendarDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  calendar: CalendarDto = new CalendarDto();
  isValid: any;
  addNewCalendarForm: FormGroup;
  isUpdate: any;
  errors: any;
  lockedLst: any;

  calendarId: any;
  markets: MarketDto[];
  mainMarkets: MarketDto[];
  subMarkets: MarketDto[];
  market_title: any;
  showMarket: any = false;
  sessiobTypeList: any;
  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarketCalendarDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private setupApi: SetupApi,
    private symbolService: SymbolService,
    private marketService: MarketService,

    injector: Injector
  ) {
    super(injector);

    if (data.calendar != null) {

      this.calendar = data.calendar;
      this.isUpdate = true;
      if(data.market_title!=null){
          this.market_title=data.market_title;
      }
      this.title = this.translate("Update Calendar")  ;
    } else {
      this.isUpdate = false;
      this.calendar.Id = 0;
      this.title = this.translate("Add Calendar");
    }

 

    if (data.calendarId != null) {
      this.calendarId = data.calendarId;
      //this.getCalendarById();
    } else {
      this.calendarId = 0;
    }


  }

  ngOnInit(): void {
    this.getMarkets();
    this.addNewCalendarForm = new FormGroup({
      Description: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      StartDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      EndDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      mainMarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });
  }


  close() {
    this.dialogRef.close(false);
  }

  radioChange(event: any) {
  
    
    var selectMarket = event.value;
    if (!this.isUpdate) {
      if (selectMarket == 1) {
        this.showMarket = true;
      } else {
        this.showMarket = false;
      }
    }


  }
  onFormSubmit() {

    this.errors = [];

    this.calendar.LastUpdateUserID = this.currentUserId();
    let openDate = new Date(this.calendar. StartDate);
    this.calendar.StartDate = new Date(
      Date.UTC(openDate.getFullYear(), openDate.getMonth(), openDate.getDate()));


      let EndDate = new Date(this.calendar.  EndDate);
      this.calendar.EndDate = new Date(
        Date.UTC(EndDate.getFullYear(), EndDate.getMonth(), EndDate.getDate()));


    if (this.calendar.MarketId==null) {
      this.calendar.IsPublic = true;
    }
    this.marketService.updateMarketCalendar(this.calendar, this.calendar.Id).subscribe(() => {
      this.dialogRef.close(this.addNewCalendarForm.value);
      this.toastr.success(this.translate("Successfully!"));
    }, err => {

      this.errors.push(this.translate(err.error[0]));
      this.toastr.error(this.translate(err.error[0]));
    });
  }

  getCalendarById() {
    this.marketService.getCalendarById(this.calendarId).subscribe(res => {
      this.calendar = res;
    });
  }


  getMarkets() {
    this.setupApi.getActiveMarkets().subscribe(res => {

      this.markets = res;
      this.mainMarkets = this.markets.filter(c => c.ParentMarketId == null);

    });
  }

  onChangeMainMarket(id) {

    this.getSubMarket(id.value);
  }

  getSubMarket(id) {
    this.setupApi.getSubMarkets(id).subscribe(items => {
      this.subMarkets = items;

    });
  }

}
