import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BankManagementService } from '../../services/bank-management.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import{CompanyDto } from 'app/models/broker';
import {   MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { Router } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CompanyFormDialogComponent } from 'app/company-management/components/company-form-dialog/company-form-dialog.component';
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import{EnumHelpers } from 'app/helper/EnumHelpers';

import { from } from 'rxjs';
import { BankFormDialogComponent } from 'app/bank-management/components/bank-form-dialog/bank-form-dialog.component';
import { AppComponentBase } from 'app/app-component-base';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
@Component({
  selector: 'ms-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss']
})
export class BankListComponent extends AppComponentBase  implements  OnInit , OnDestroy{
 
  public items:  CompanyDto [] ;
  public actions:any;
  public action :any;
  dialogRef: MatDialogRef<DemoDialog>;
   
  selectBy:any;
  ngOnDestroy(){ 

	}

  constructor( private pageTitleService : PageTitleService ,
    private dialog: MatDialog  ,
    private bankManagementService :BankManagementService,
 
    private _router: Router ,
    private _helper :EnumHelpers, injector: Injector
    ) {
      super(injector); 
      this.actions = _helper.getNames(StatusEnum);
  
    }

  ngOnInit(): void {
    this.getBanks( ) ;
    this.pageTitleService.setTitle("Banks");
      
  
  }
  changePropertiesStatus(data){
 
    let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
    propertiesStatus.ActionOn=EntityTypeEnum. Bank;
    propertiesStatus.ResponsibleId=data.BankID;
    propertiesStatus.ResponsibleNameAr=data.BankNameAr;
    propertiesStatus.ResponsibleNameEn=data.BankNameEn;
    propertiesStatus.Status= data.Status;
    const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
      data:{propertiesStatus:propertiesStatus   }  
    });
    dialog.afterClosed().subscribe((res) => { 
 if(res!=null){
  data.Status=res;
 }

    } );
  }
  getBanks( ){
   //  this.action=action;
     this.bankManagementService.getBanks().subscribe(items=>{

       this.items=items;
 
    });
  }
  
  addNewBankDialog(){ 
    const dialog = this.dialog.open(BankFormDialogComponent, {
           data:{count : this.items.length}
      });
      dialog.afterClosed().subscribe((res) => {
 
         //this.items.push(res);
         this.getBanks( );
         
      } );
  }
  editBank(bank){ 
    const dialog = this.dialog.open(BankFormDialogComponent, {
        data:{bank:bank}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getBanks( );
      } );
  }
  
  
  deleteBank(bank) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this bank code ' + bank.BankNameEn + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.bankManagementService.deleteBank(bank.BankID).subscribe((res) => {
          this.getBanks( );
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
 
}

 