<form [formGroup]="addNewRoleForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title| translate }}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 

 
                       <mat-form-field class="  form-field " appearance="outline">
                          <mat-label>{{'Name'| translate }}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{'Name'| translate }}" 
                            formControlName="Name"  
                            [(ngModel)]="role.name"  >  
                      </mat-form-field>

 
    
					  <mat-form-field class="  form-field " appearance="outline">
                        <mat-label>{{'Type'| translate }}</mat-label>
                        <mat-select  [(ngModel)]="RoleType"  name="roleId"
						
						
						[disabled]="isAdmin" 
                             formControlName="RoleName"   >  
							 <mat-option *ngFor="let item of RoleTypeList" [value]="item.Value">
								{{item.Name}}
							  </mat-option>
                        </mat-select> 
                      </mat-form-field> 
 
					  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
						<mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
						  <button mat-icon-button disabled></button>
						  <mat-checkbox class="checklist-leaf-node"
						  [checked]="checklistSelection.isSelected(node)"
						  (change)="checklistSelection.toggle(node);">{{node.item}}</mat-checkbox>
						</mat-tree-node>
					   
						<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
						  <button mat-icon-button matTreeNodeToggle
								  [attr.aria-label]="'Toggle ' + node.item">
							<mat-icon class="mat-icon-rtl-mirror">
							  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
							</mat-icon>
						  </button>
						  <mat-checkbox [checked]="descendantsAllSelected(node)"
						  [indeterminate]="descendantsPartiallySelected(node)"
										(change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
 
						</mat-tree-node>
					  </mat-tree>
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  [disabled]="!addNewRoleForm.valid"  mat-raised-button color="warn">SUBMIT</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">CLOSE</button>
	
	</mat-dialog-actions>
</form>