import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Optional, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { OrderDto } from 'app/models/order';
import { OrderTicketLookupDto } from 'app/models/orderTicketLookup';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { ApproveStatusEnum, CompanyTypeEnum, MatchTypeEnum, OrderEnum, StatusEnum, TradeTypeEnum } from 'app/core/enums'
import { SymbolDto } from 'app/models/symbol';
import { ToastrService } from 'ngx-toastr';
import { SetupApi } from 'app/Shared/api/setup.api';
import { AuctionDto } from 'app/models/auction';


import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientDto, ClientReuqest } from 'app/models/client';
import { DatePipe, Time } from '@angular/common';
import { AppComponentBase } from 'app/app-component-base';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { ClientService } from 'app/client-managment/services/client.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { time } from 'echarts';
import { MarketDto } from 'app/models/market';
import { action } from '@circlon/angular-tree-component/lib/mobx-angular/mobx-proxy';
import { AuthService } from 'app/account/services/auth.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AdminService } from 'app/admin/services/admin.service';
import { AuctionHelper } from 'app/auction-management/auctionHelper';
@Component({
  selector: 'auction-form-dialog',
  templateUrl: './auction-form-dialog.component.html',
  styleUrls: ['./auction-form-dialog.component.scss'],

})

export class AuctionFormDialogComponent extends AppComponentBase implements OnInit {

  isDisabled: boolean = false;
  selectedSymbolUnit: any;
  symbolsFilter: any;
  FilterClients: any;
  SymbolCurrency: any;
  addNewOrderForm: FormGroup;
  lookups: OrderTicketLookupDto = new OrderTicketLookupDto();
  isTimeValid: boolean = false;
  isDateValid: boolean = false;
  isPriceValid: boolean = false;
  isCapValid: boolean = false;

  isEnable: boolean = true;
  auctionId: any;
  symbols: any;
  auction: any;
  clients: any;

  auctionTypes: any;
  units: any;
  companyType: any;
  OrderEnum = OrderEnum;
  title: string;
  errors: string[] = [];
  isUpdate: boolean;
  warehouses: any;
  companies: any;
  subMarkets: any;
  mainMarkets: any;
  markets: MarketDto[] = [];
  matchingTypes: any;
  minDate: any;
  mainMarketId: any;
  timeCheck: any = false;
  defaultOpenTime: any;
  defaultCloseTime: any;
  PriceLimit: string;
  CompanyTypeEnum = CompanyTypeEnum;
  clientReuqest: ClientReuqest;

  symbolEdit: string;
  subMarketEdit: number;
  warehouseEdit: number;
  IsAllowCapping: boolean = false;
  disableMatchingType: boolean = false;
  killDialogRef: MatDialogRef<DemoDialog>;
  filteredCompanies: any;

  constructor(public formBuilder: FormBuilder,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<AuctionFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , private Auctionservice: Auctionservice,
    private setupApi: SetupApi,
    private _helper: EnumHelpers,
    private clientService: ClientService,
    private authService: AuthService,
    public datepipe: DatePipe,
    private adminService: AdminService,
    private dialog: MatDialog,
    private  _AuctionHelper :AuctionHelper,
    injector: Injector) {

    super(injector);
    this.defaultOpenTime = this.addHours(new Date(), 1).getHours() + ":" + new Date().getMinutes();
    this.defaultCloseTime = this.addHours(new Date(), 2).getHours() + ":" + new Date().getMinutes();
    this.minDate = new Date();
    this.auctionId = dialogRef.id;


    this.matchingTypes = _helper.getNamesAndValues(MatchTypeEnum);

    this.setupApi.GetAuctionTypes().subscribe(res => {
      this.auctionTypes = res;
    });

    if (data != null) {
      this.auction = data.auction;

      if (this.auction.Id > 0) {

        this.isUpdate = true;
        this.title = "Update Auction";
        this.onChangeCompanyByType(this.auction.AuctionType);     

        if (this.auction.IsAllowCapping || this.auction.MatchingType == MatchTypeEnum.Cap) {
            this.IsAllowCapping = true;
            //this.disableMatchingType = true;
            //this.auction.MatchingType = MatchTypeEnum.Cap;
        }
      
        
        this.setupApi.getSymbolUnits().subscribe(res => { this.units = res; }
          , err => console.log(err));
        this.getMarkets();

        this.clientReuqest = new ClientReuqest();
        this.clientReuqest.CompanyId = this.auction.CompanyID;
        this.clientReuqest.ApproveStatusId = ApproveStatusEnum.Approve;
        this.clientReuqest.Status = StatusEnum.Active;
        this.setupApi.searchActiveClients(this.clientReuqest).subscribe(res => { this.clients = res });

        let currentTime = this.datepipe.transform((new Date), 'h:mm:ss');
        let currentDate = this.datepipe.transform((new Date), 'MM/dd/yyyy');

        // check if the auction is openned
        if ((this.auction.OpenTime <= currentTime <= this.auction.OpenTime) &&
          (this.auction.OpenDate.toString() <= currentDate && currentDate <= this.auction.CloseDate.toString())) {
          this.isDisabled = true;
        }

      } else {
       
        this.title = "Add New Auction";
        this.isUpdate = false;
        this.auction.OpenTime = this.defaultOpenTime;
        this.auction.CloseTime = this.defaultCloseTime;
        // Default matching type
        this.auction.MatchingType = MatchTypeEnum.Price;   
      }
    }

  }

  addHours(date, hours) {
    date.setHours(date.getHours() + hours);

    return date;
  }

  ngOnInit() {

    this.symbols = [];
    this.addNewOrderForm = new FormGroup({

      MinimumFill: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      OpenTime: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      CloseTime: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      // DurationTime: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MainMarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MarketId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SymbolCode: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MainClient: new FormControl({ value: '' }),
      OpenDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      CloseDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Qty: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      StoreId: new FormControl({ value: '' }),
      AuctionType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      OrderType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Price: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(1)])),
      ClientFilter: new FormControl({ value: '' }),
      itemName: new FormControl(['itemName']),
      symbolName: new FormControl(['symbolName']),
      companyName: new FormControl(['companyName']),
      mainMarketId: new FormControl({ value: '' }),
      companyId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Note: new FormControl({ value: '' }),
      QtyUnit: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      MatchingType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      PriceLimit: new FormControl({ value: '' }),
      CapQty: new FormControl({ value: 0 }),
      IsAutoExecute: new FormControl({ value: '' }),
      Currency: new FormControl([]),

    });

    this.setupApi.getSymbolUnits().subscribe(res => { this.units = res; }
      , err => console.log(err));

    this.clientReuqest = new ClientReuqest();
    this.clientReuqest.ApproveStatusId = ApproveStatusEnum.Approve;

    if (this.isBroker()) {

      this.getUserBrokerInfo().subscribe(res => {
        if (res != null) {
          if (this.auction != null && this.auction.Id > 0 ){
            this.auction.CompanyID = res.CompanyId;
          }
       
          this.clientReuqest.CompanyId = res.CompanyId;       
        }
      });
    }
    this.getMarkets();
  }


  getMarkets() {

    this.setupApi.getActiveMarkets().subscribe(items => {
      this.markets = items;
      this.mainMarkets = items.filter(c => c.ParentMarketId == null);

      if (this.auction.Id > 0) {
        this.mainMarketId = this.markets.filter(x => x.Id == this.auction.MarketId)[0].ParentMarketId;

        if (this.mainMarketId != null) {
          this.subMarkets = this.markets.filter(c => c.ParentMarketId == this.mainMarketId);
        }
        this.onChangeSubMarket(this.auction.MarketId);
 
      }

    }, err => console.log(err));


  }
 
  calPriceLimit(market) {

    if (market != null) {
      //var market = markets.filter(x => x.MarketId == this.order.MarketID);
      this.PriceLimit = market[0].LowerLValue + ' - ' + market[0].UpperLValue;

    } else {
      this.PriceLimit = ' - ';
    }

  }

  onChangeMainMarket(event) {

    this.subMarkets = this.markets.filter(c => c.ParentMarketId == event.value);
    this.symbolsFilter = [];
    this.symbols=[];
    this.auction.SymbolCode = null;

    this.PriceLimit = ' - ';
  }


  onChangeSubMarket(event) {

    // this.auction.MarketId = event.value;
    this.setupApi.getSymbolMarketsByMarketId(event).subscribe(res => {
      this.symbols = res;
      if (this.symbols != null) {
        this.symbols.forEach(element => {
          var selectedSymbolUnit = this.units.filter(c => c.UnitID == element.Unit)[0];
          if (selectedSymbolUnit != null) {
            if (this.isRtl()) {
              element.symbolText = element.SymbolCode + " / " + selectedSymbolUnit.UnitNameAr;
            } else {
              element.symbolText = element.SymbolCode + " / " + selectedSymbolUnit.UnitNameEn;
            }
          }
          this.PriceLimit = ' - ';
        });
        this.symbolsFilter = this.symbols;

        if (this.auction.Id > 0) {
          this.onChangeSymbol(this.auction.SymbolCode);
        }
      }

    }, err => console.log(err));
  }

  filterclients(value) {

    this.clientReuqest.GeneralFilter = value;

    this.clientReuqest.ApproveStatusId = ApproveStatusEnum.Approve;
    this.clientReuqest.Status = StatusEnum.Active;

    if (value.length >= 2 && this.clientReuqest.CompanyId > 0) {
      this.setupApi.searchActiveClients(this.clientReuqest).
        subscribe(res => { this.clients = res });
    }
  }

  filterSymboles(value) {

    if (value.length > 1)
      this.symbolsFilter = this.symbols.filter(x => x.SymbolCode.toLowerCase().includes(value));
    else
      this.symbolsFilter = this.symbols;
  }

  onChangeOrderType(event) {

    this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', event == OrderEnum.Buy ? '#dce2f3' : '#ffe8e8');
  }

  onStartTimeset(event) {

    this.auction.OpenTime = event;
  }

  onEndTimeset(event) {

    this.auction.CloseTime = event;
    this.auction.DurationTime = this.auction.CloseTime;
  }

  onDurationTimeset(event) {
    this.auction.DurationTime = event;
  }

  onChangeSymbol(event) {
    // check the === 
    if (this.symbols.length > 0) {
      var symbol = this.symbols.filter(c => c.SymbolCode === event);
      if (symbol != null) {
        this.calPriceLimit(symbol);
        if (this.companyType == CompanyTypeEnum.B) {

          this.setupApi.GetStoreSymbolByTradeType(symbol[0].SymbolCode, TradeTypeEnum.B).subscribe(res => { this.warehouses = res; });
        } else {

          this.setupApi.GetStoreSymbols(symbol[0].SymbolId).subscribe(res => { this.warehouses = res; });
        } 
        if(this.isRtl()){
          this.SymbolCurrency=  symbol[0].CurrencyNameAr;
        }else{
          this.SymbolCurrency=symbol[0].CurrencyNameEn;
        }

      }
    }

  }

  onChangeCompany(event) {

   
    if(event!=null && event!=""){
      this.auction.MainClient = null;
      this.clients = [];
    }

    if(this.companies != null)
      { 
        if(typeof event === 'string')
        {
        if(event.match(/^ *$/) !== null)
        this.filteredCompanies = this.companies;
        else
        this.filteredCompanies = this.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
        else
        this.clientReuqest.CompanyId = event.value;

      }


    if (this. companyType == CompanyTypeEnum.B) {

      this.clientService.getClientByCompanyId(event.value).subscribe(client => {

        this.auction.MainClient = client.Id;
        this.clients = [];
        this.clients = [client, ...this.clients];
      });
    }

  }

  onAuctionTypeSelect(type){
   
    if (this.auction.AuctionType != null ) {
      var validations = this.auctionTypes.filter(a => a.Id == type.value);
      if (validations[0].IsAllowCapping) {
          this.IsAllowCapping = true;
          this.auction.IsAllowCapping = true;
          //this.disableMatchingType = true;
          //this.auction.MatchingType = MatchTypeEnum.Cap;
      } 
      else{
        //this.disableMatchingType = false;
        this.IsAllowCapping = false;
        this.auction.IsAllowCapping = false;
      }
    }
  }


  
 

  onChangeCompanyByType(type) {

    //add the below line code to get all companies if type not (B - Agricultural Cooperative)
    if (type != 2 && type != 3) type = 1;
    this.companyType = type;
    this.setupApi.getCompanyByType(type).subscribe(res => {
      this.companies = res;
    });
  }

  onChangeMatchingType(event){

    if(event.value == MatchTypeEnum.Cap || this.auction.IsAllowCapping)  {
        this.IsAllowCapping = true;
        //this.auction.CapQty = 0;
    }
    else {
      this.IsAllowCapping = false;
      this.auction.CapQty = 0;
    }
    
  }

  onFormSubmit() {

    this.errors = [];
    this.validationDate();
    this.validationTime();
    //this.checkMatchingTypeCap();
    this.validationOfPrice();
    this.validationOfCapping();

    // if (this.auction.MainClient > 0) {
      if (this.isTimeValid && this.isDateValid 
         && this.isPriceValid && this.isCapValid) {

        let openDate = new Date(this.auction.OpenDate);
        this.auction.OpenDate = new Date(
          Date.UTC(openDate.getFullYear(), openDate.getMonth(), openDate.getDate()));


        let closeDate = new Date(this.auction.CloseDate);
        this.auction.CloseDate = new Date(
          Date.UTC(closeDate.getFullYear(), closeDate.getMonth(), closeDate.getDate()));

        this.isEnable = false;
        if (this.auction.Id > 0) {

          
          this.Auctionservice.updateAuction(this.auction, this.auction.Id).subscribe(() => {
            this.dialogRef.close(this.addNewOrderForm.value);
            this.toastr.success("Successfully!");
          },
            err => {
              this.isEnable = true;
              this.errors.push(err.error[0]);
              this.toastr.error(err.error[0]);
            });

         //   this.KillAllSession();
        } else {
          this.auction.SymbolID = this.symbols.filter(x => x.SymbolCode == this.auction.SymbolCode)[0].Id;
          this.auction.CompanyCode = this.companies.filter(x => x.Id == this.auction.CompanyID)[0].Code;
          this.auction.ClientCode = this.clients.filter(x => x.Id == this.auction.MainClient)[0].IdentityTypeText;

          this.auction.OrderType = Number(this.auction.OrderType);
          this.auction.AuctionType = Number(this.auction.AuctionType);

          this.Auctionservice.insertAuction(this.auction).subscribe(res => {
            this.dialogRef.close(this.addNewOrderForm.value);
            this.toastr.success("Successfully!");
          },
            err => {
              this.toastr.error(err.error[0]);
              this.isEnable = true;
            }
          );
        }
      }
    //}
  }

  close() {
    this.dialogRef.close(false);
  }

  validationDate() {

    this.errors.pop();
    if ((this.auction.OpenDate != null && this.auction.CloseDate != null)
      && (this.auction.CloseDate) < (this.auction.OpenDate)) {
      this.errors.push('Closing date should be greater than openning date.');
      return this.isDateValid = false;
    }
    return this.isDateValid = true;
  }

  validationTime() {

    this.errors.pop();

    //   if ((this.auction.OpenTime != null && this.auction.DurationTime != null)
    //    && (this.auction.DurationTime) < (this.auction.OpenTime)) { 
    //     this.errors.push('duration time should be grater than open time.');
    //     this.isValid = false;
    //     return this.isValid;
    //   } 

    //  if ((this.auction.DurationTime != null && this.auction.CloseTime != null)
    //    && (this.auction.CloseTime) < (this.auction.DurationTime)) { 
    //     this.errors.push('Close time should be grater than duration time.');
    //     this.isValid = false;
    //     return this.isValid;
    //  } 


    if ((this.auction.OpenTime != null && this.auction.CloseTime != null)
      && (this.auction.CloseTime) < (this.auction.OpenTime) && (this.auction.OpenDate) == (this.auction.CloseDate)) {
      this.errors.push('Closing time should be greater than openning time (24hr format).');
      return this.isTimeValid = false;
    } 
    return this.isTimeValid = true;
  }

  // checkMatchingTypeCap() {

  //   if (this.auction.MatchingType == MatchTypeEnum.Cap) {
  //     if (this.auction.CapQty <= 0 || this.auction.CapQty == null) {
  //       this.errors.push('Qty should be greater than Cap Qty');
  //       this.isValid = false;
  //       return this.isValid;
  //     }
  //   }

  //   return this.isValid;
  // }

  validationOfPrice() {

    var validations = this.auctionTypes.filter(a => a.Id == this.auction.AuctionType);
    if (validations[0].IsAllowPriceWithinLimit) {
      var symbol = this.symbols.filter(c => c.SymbolCode === this.auction.SymbolCode);
      if (symbol[0].LowerLValue <= this.auction.Price && this.auction.Price <= symbol[0].UpperLValue)
        this.isPriceValid = true;
      else {
        this.errors.push('Price should be within the price limits.');
        this.isPriceValid = false;
      }
    }
    else
     this.isPriceValid = true;
  }

  validationOfCapping() {

    var validations = this.auctionTypes.filter(a => a.Id == this.auction.AuctionType);
    if (( validations != null  && validations[0].IsAllowCapping ) || this.auction.MatchingType == MatchTypeEnum.Cap ) {
     
      if (this.auction.CapQty == 0) 
      {
        this.errors.push('Due to Capping, Cap Qty can not be 0');
        this.isCapValid = false;
      }
      else if (this.auction.CapQty != null && this.auction.CapQty <= this.auction.Qty ) {
        this.isCapValid = true;
      }
      else {
        this.auction.CapQty = this.auction.Qty;
        this.isCapValid = false;
        this.errors.push('Cap Qty should be smaller than or equal Qty.');
      }
    }
    else
    return this.isCapValid = true;
  }

  KillAllSession() {

    this.killDialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'Would you like to kill all the sessions ?' },
      disableClose: false
    });

    this.killDialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.destroyAllSession().subscribe((res) => {
          
          this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.killDialogRef = null;
    });
  }

}

