import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '../../../app-component-base';

import { ToastrService } from 'ngx-toastr';
import { SetupApi } from '../../../Shared/api/setup.api';
import { ClientService } from '../../services/client.service';
import { ClientCompanyDto, ClientDto, ClientReuqest } from '../../../models/client'
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ApproveStatusEnum, ClientTypeEnum, IdentityTypeEnum  } from 'app/core/enums';
import { Router } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ClientCompanyFormDialogComponent } from '../client-company-form-dialog/client-company-form-dialog.component';
import { ClientStoreCompanyFormDialogComponent } from '../client-store-company-form-dialog/client-store-company-form-dialog.component';


@Component({
  selector: 'ms-client-search-form-dialog',
  templateUrl: './client-search-form-dialog.component.html',
  styleUrls: ['./client-search-form-dialog.component.scss']
})
export class ClientSearchFormDialogComponent extends AppComponentBase implements OnInit {

  dialogRefD: MatDialogRef<DemoDialog>;
  addNewClientForm: FormGroup;
  nationalities:any;
  crOffices:any;
  identityTypes:any;
  clientTypes:any;
  client: ClientDto = new ClientDto;
  title:any;
  companyId:any;
  storeID:any;
  clientId:any;
  filterOptions:ClientReuqest = new ClientReuqest();
  identityTypeEnum=IdentityTypeEnum;
  constructor(public formBuilder: FormBuilder,
    private clientService: ClientService,
    private setupApi: SetupApi,
    private dialog: MatDialog,
    private _helper :EnumHelpers,
    private _router:Router,
    private dialogRef: MatDialogRef<ClientSearchFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , injector: Injector
  ) {
    super(injector); 
    this.clientTypes= _helper.getNamesAndValues(ClientTypeEnum);
   
    this.title=this.translate('Search Client');
  }

  ngOnInit(): void {
    this.addNewClientForm = new FormGroup({
       NationalityID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IdentityTypeID: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      IdentityTypeText: new FormControl({ value: '' }, Validators.compose([
        Validators.required, Validators.maxLength(14),Validators.minLength(14), Validators.pattern("^[0-9]*$")])),
      ClientType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      CROfficeId: new FormControl({ value: '' }
      ),

    });
 
    this.setupApi.getNationalities().subscribe(res => { 
      this.nationalities = res; 
    });
    this.setupApi. getCROffices().subscribe(res => { 
      this.crOffices = res; 
    });
    if(this.isBroker()){ 
      this.getUserBrokerInfo().subscribe(res=>{
        if(res!=null){ 
          this.companyId=res.CompanyId ; 
        }
      }) 

    }else  if(this.isStoreKeeper()){ 
      this.setupApi .getStoreKeeperInfo().subscribe(res=>{
        if(res!=null){
          this.storeID=res.StoreID;  
        } 
      }) 

    } 
  }

  
  close() {
    this.dialogRef.close(false);
  }

 
  onChangeClientType(event){
  
    this.setupApi. getIdentityByType(event.value).subscribe(res => { 
      this.identityTypes = res;  
    });
  }

  checkClient(){
    

    this.clientService.searchClients(this.filterOptions).subscribe(items => {
      if(!(items.clients.length>0)){
     
         var client= new ClientDto();
       client.   IdentityTypeText=this.filterOptions.IdTypeTxt;
        client.   IdentityTypeID=this.filterOptions.  IdType;
        client.   CROfficeId  =this.filterOptions.  CROfficeId;
       client.   ClientType=this.filterOptions. ClientType;
        client.   NationalityID=this.filterOptions. NationalityID; 
        this._router.navigateByUrl('/clients/create/'+  ApproveStatusEnum. Approve, { state:  client});
     
      }else{ 
        
        this.clientId=items.clients[0].Id;
        if(this.isBroker()){ 
           var companyClients=items.clients[0].CompanyClients.filter(x=>x.CompanyId==this.companyId);
         
           if(items.clients[0].ApproveStatusId ==ApproveStatusEnum.AwaitApprove){
            this.toastr.error(this.translate("Client is pending "));
           }else if(items.clients[0].ApproveStatusId ==ApproveStatusEnum.Reject){
            this.toastr.error(this.translate("Client is rejected "));
           }else{
            if(!(companyClients.length>0)){
              this.openDialog(); 
             } else{
              this._router.navigateByUrl('/clients/Edit/'+this.clientId+'/'+ ApproveStatusEnum. Approve );
             }
           
           }
 
        }else  if(this.isStoreKeeper()){ 
           
          var storeClients=items[0].StoreClients.filter(x=>x.StoreID==this.storeID);
          
          if(items[0].ApproveStatusId ==ApproveStatusEnum.AwaitApprove){
           this.toastr.error(this.translate("Client is pending "));
          }else if(items[0].ApproveStatusId ==ApproveStatusEnum.Reject){
           this.toastr.error(this.translate("Client is rejected "));
          }else{
           if(!(storeClients.length>0)){
             this.openDialog(); 
            } else{
             this._router.navigateByUrl('/clients/Edit/'+this.clientId+'/'+ ApproveStatusEnum. Approve );
            }
          
          }

        }else{
          this._router.navigateByUrl('/clients/Edit/'+this.clientId+'/'+ ApproveStatusEnum. Approve );
        }
        
      


  
      }
      this.close();
  }); 




    
  }

  AssignClientsToCompany(){ 
    const dialog = this.dialog.open(ClientCompanyFormDialogComponent, {
      data:{ clientId: this.clientId  }  
    });
    dialog.afterClosed().subscribe((res) => { 
    } );

  }
  AssignClientToStores(){ 
    const dialog = this.dialog.open(ClientStoreCompanyFormDialogComponent, {
      data:{ clientId: this.clientId  }  
    });
    dialog.afterClosed().subscribe((res) => { 
    } );

  }

  openDialog(  ):any {

    var message="";
    if(this.isBroker()){
      message= 'would you like to assign client to the company  ?'
    }else  if(this. isStoreKeeper()){
      message= 'would you like to assign client to the warehouse ?'
    }
    this.dialogRefD = this.dialog.open(DemoDialog, {
      data: { message: message },
      disableClose: false
    });

    this.dialogRefD.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        if(this.isBroker()){
          this.AssignClientsToCompany();
        }else  if(this. isStoreKeeper()){
          this. AssignClientToStores()
        }
        return true;

      }else{
        this.dialogRefD = null;
        return false;
      }
    
    });
    this.close();
  }
}

