<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
 
				<div class="mrgn-l-sm mrgn-r-sm">
                    <h4>{{title}}</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<!-- <mat-card-actions class="mat-pad-none margin-none">
					<button mat-raised-button mat-button-sm color="primary" (click)="addNewBrokerDialog()">{{ 'New Account' | translate }} <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</mat-card-actions>  -->
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="table-responsive">

			<kendo-grid [kendoGridBinding]="items"
			class="kendo-grid-list" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"    
				  [sortable]="true" 
				  [kendoGridSelectBy]="selectBy"
				   
				  >
				  
				  <kendo-grid-checkbox-column [showSelectAll]="true" headerClass="grid-list-column-header"  [width]="40"></kendo-grid-checkbox-column>
				   

				  <!-- <kendo-grid-column field="Id" title="{{ 'Broker Code' | translate }}" 
				  headerClass="grid-list-column-header"> 
				  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{(dataItem.Broker.Code +'-'+ (rowIndex+1)  )}}
				  </ng-template>
				  </kendo-grid-column>
				  
			      <kendo-grid-column field="Broker.Name" title="{{ 'Broker Name' | translate }}" headerClass="grid-list-column-header"> </kendo-grid-column>
				   -->
				   <kendo-grid-column field="UserName" title="{{ 'Username' | translate }}"  headerClass="grid-list-column-header"> </kendo-grid-column>
				  <kendo-grid-column field="Email" title="{{ 'Email' | translate }}"  headerClass="grid-list-column-header"> </kendo-grid-column>
				  <kendo-grid-column field="IpAddress" title="{{ 'IpAddress' | translate }}"   headerClass="grid-list-column-header"> </kendo-grid-column>
				
				  <kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column   title=""  width="80"  headerClass="grid-list-column-header" >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item  
							[routerLink]="['/brokers/edit/'+ dataItem.BrokerId ]"   >
							    
								{{ 'View' | translate }}
							</a> 
							<a mat-menu-item   (click)="removeCompanyBroker(dataItem)"   >
							    
								{{ 'Delete' | translate }}
							</a> 
							<a mat-menu-item   (click)="changeStatus(dataItem)"   >
								<span *ngIf="dataItem.IsActive">{{ 'Suspended' | translate }}</span>
								<span *ngIf="!dataItem.IsActive">{{ 'Unsuspended' | translate }}</span>
							</a>

						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>