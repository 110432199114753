import { Component, ElementRef, Inject, Injector, Input, NgZone, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission'; 
import { SignalRService } from '../../../service/signalr/signalr.service'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { GoldMarketApi } from 'app/gold-market-management/api/gold-market.api';
import { GoldOrderDto } from 'app/models/goldMarket';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalcMethodEnum, OrderEnum } from 'app/core/enums';
import { AuctionOrderCofirmationDialogComponent } from 'app/auction-management/components/auction-order-cofirmation-dialog/auction-order-cofirmation-dialog.component';
import { GoldOrderCofirmationDialogComponent } from '../gold-order-cofirmation-dialog/gold-order-cofirmation-dialogcomponent';
 
@Component({
  selector: 'ms-gold-order',
  templateUrl: './gold-order.component.html',
  styleUrls: ['./gold-order.component.scss']
})
export class GoldOrderComponent   extends AppComponentBase implements OnInit {

  public clients:  any [] ; 
  public symbols:  any [] ;
  public symbolFeed:  any ;
  errors: any;
  isEnable = true;
  order :GoldOrderDto=new GoldOrderDto();
  addNewOrderForm: FormGroup;
  CalcMethodEnum= CalcMethodEnum;
  OrderTypeEnum= OrderEnum;
  constructor(   
    private goldMarketApi:GoldMarketApi,  
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GoldOrderComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private elRef: ElementRef,
    private renderer: Renderer2,
    injector: Injector
    ) {
      super(injector); 
      this.order.CalcMethod=this.CalcMethodEnum.quantity;
      if(data!=null && data.order!=null){
        
        this.order.OrderType=data.order.OrderType;
        this.order.SymbolID=data.order.SymbolID;
        this.changePriceByTrasnactionType();
        this.onChangeSymbol( this.order.SymbolID);
      }
  }
  ngOnInit(): void { 
     this.goldMarketApi.getClients().subscribe(item=>{
           this.clients=item;
     });
     this.goldMarketApi. getSymbols().subscribe(item=>{
          this.symbols=item;
    });

        
    this.addNewOrderForm = new FormGroup({
      client:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      symbol:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      orderType:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      calcMethod:  new FormControl({ value: '' }, Validators.compose([Validators.required])),
      value:  new FormControl({ value: '' } ),
      qty:  new FormControl({ value: '' } ),
      price:  new FormControl({ value: '' } ),
    });
    this.userApi.getBrokerInfo().subscribe(res=>{
   
      if(res!=null ){     
          this.order.CompanyID=res.CompanyId ;
          this.order. BrokerID=res.BrokerId ;
          this.order. CompanyCode=res.CompanyCode ;
          this.order. BrokerCode=res.Code ;
      } 
   });
    
  }
  onChangeOrderType(event) {

   // this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', event.value == this.OrderTypeEnum.Buy ? '#dce2f3' : '#ffe8e8');
    this.changePriceByTrasnactionType();
  }
  onChangeCalcMethod(event) {
    this.order.CalcMethod=event.value ;
    if(this.order.CalcMethod=='Q')
       this.order. OrderValue =0;
       else
       this.order.OrderQty =0;
  }
  changePriceByTrasnactionType(){
    if(this.symbolFeed!=null){
      if(this.order.OrderType==this.OrderTypeEnum.Buy){
        this.order.IndicativePrice=this.symbolFeed.AskPrice;
      }else{
        this.order.IndicativePrice=this.symbolFeed.BidPrice;
      }
    }
  }
  onChangeSymbol(value) {
    this.goldMarketApi.getMarketWatchById(value).subscribe(res=>{
      this.symbolFeed=res; 
      this.changePriceByTrasnactionType();

    })

  }
  

 

  onBasicFormSubmit( ) {
  
    this.errors = []; 
    this.order.OrderType = Number(this.order.OrderType); 
   this.order.OrderDate=new Date();
     this.order.ClientNameAr=this.clients.filter(c=>c.Id== this.order.ClientID)[0].NameAr;
     this.order.ClientNameEn=this.clients.filter(c=>c.Id== this.order.ClientID)[0].NameEn;
     this.order. ClientCode=this.clients.filter(c=>c.Id== this.order.ClientID)[0].IdentityTypeText;
 
     this.order.SymbolNameAr=this.symbols.filter(c=>c.SymbolId== this.order.SymbolID)[0].SymbolNameAr;
     this.order.SymbolNameEn=this.symbols.filter(c=>c.SymbolId== this.order.SymbolID)[0].SymbolNameEn;
     const dialog = this.dialog.open(GoldOrderCofirmationDialogComponent, {
      data:{ order:this.order ,orderId:0 }  
    });
    dialog.afterClosed().subscribe((res) => {
      if(res==true){

        this. close() ;
      }

    } );
 
 
}

  close() {
    this.dialogRef.close(false);
  }
}


