import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';

import { Subject } from 'rxjs';
import { AppConsts } from '../../core/config';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private connection: HubConnection;
  // itemUpdated: Subject<OrderDto> = new Subject<OrderDto>();
  // itemAdded: Subject<OrderDto> = new Subject<OrderDto>();
  orderChanges: Subject<string> = new Subject<string>();
  tradeChanges: Subject<string> = new Subject<string>();
  auctionOrderChanges: Subject<string> = new Subject<string>();
  auctionCompanyOrderChanges: Subject<string> = new Subject<string>();
  auctionTradeChanges: Subject<string> = new Subject<string>();
  marketChanges: Subject<string> = new Subject<string>();
  askChanges: Subject<string> = new Subject<string>();
  bidChanges: Subject<string> = new Subject<string>();
  transactionChanges:Subject<string> = new Subject<string>();
  auctionChanges:Subject<string> = new Subject<string>();
  marketStatusChanges:Subject<string> = new Subject<string>();
  announcementChanges:Subject<string> = new Subject<string>();
  monitorServiceChanges:Subject<string> = new Subject<string>();

  goldMarketWatchChanges:Subject<string> = new Subject<string>();
  goldTradeChanges:Subject<string> = new Subject<string>();
  goldOrderChanges:Subject<string> = new Subject<string>();
  goldMarketStatusChanges:Subject<string> = new Subject<string>();
  private readonly signalR_URL = `${AppConsts.signalRUrl}` ;
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(this.signalR_URL +'/marketchanges')
      .build();
    this.registerOnEvents();
    this.connection.start()
    .catch(err => console.log(err.toString()));
  }

  
  JoinGroup(companyId:any){

       this.connection.invoke("JoinGroup",companyId+"");
 
  }
  LeaveGroup(companyId:any){

    this.connection.invoke("LeaveGroup",companyId)
  }

  registerOnEvents() {

    this.connection.on('SendGoldMarketWatchChanges', item => {
      //console.log('SendMarketWatchChanges');
      this.goldMarketWatchChanges.next(item);
    });


    this.connection.on('SendOrderChanges', item => {
      //console.log('SendOrderChanges');
      this.orderChanges.next(item);
    });





    this.connection.on('SendAuctionTradeChanges', item => {
     // console.log('SendTradeChanges');
      this.auctionTradeChanges.next(item);
    });

    this.connection.on('SendAuctionOrderChangesByCompany', item => {
      this.auctionCompanyOrderChanges.next(item);
    });

    this.connection.on('SendAuctionOrderChanges', item => {
      //console.log('SendOrderChanges');
      this.auctionOrderChanges.next(item);
    });


    this.connection.on('SendTradeChanges', item => {
     // console.log('SendTradeChanges');
      this.tradeChanges.next(item);
    });

    this.connection.on('SendMarketWatchChanges', item => {
      //console.log('SendMarketWatchChanges');
      this.marketChanges.next(item);
    });

    this.connection.on('SendBidsChanges', item => {
     // console.log('SendBidsChanges');
      this.bidChanges.next(item);
    });

    this.connection.on('SendAsksChanges', item => {
     // console.log('SendAsksChanges');
      this.askChanges.next(item);
    });

    this.connection.on("SendTransactionChanges",item=>{
     // console.log('TransactionChanges');
      this.transactionChanges.next(item);
    });

    this.connection.on("SendAuctionChanges",item=>{
     // console.log('AuctionChanges');
      this.auctionChanges.next(item);
    });


    this.connection.on("SendMarketStatusChanges",item=>{
    //  console.log('MarketStatusChanges');
      this.marketStatusChanges.next(item);
    });

    this.connection.on("SendMonitorServiceChanges",item=>{
    
        this.monitorServiceChanges.next(item);
      });
    this.connection.on("SendAnnouncementChanges",item=>{
      //  console.log('announcementChanges');
        this.announcementChanges.next(item);
      });
    
      this.connection.on('SendGoldOrderChanges', item => {
        //console.log('SendOrderChanges');
        this.goldOrderChanges.next(item);
      });

      this.connection.on('SendGoldTradeChanges', item => {
        //console.log('SendOrderChanges');
        this.goldTradeChanges.next(item);
      });

      this.connection.on('SendGoldMarketStatusChanges', item => {
        //console.log('SendOrderChanges');
        this.goldMarketStatusChanges.next(item);
      });
      
    
  }
}