import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
 
 
import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar' 


import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element';

import { GridModule } from '@progress/kendo-angular-grid';
import { MatSelect } from '@angular/material/select';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
 
import { CompanyListComponent } from './containers/company-list/company-list.component';

import { CompanyFormDialogComponent } from './components/company-form-dialog/company-form-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { CompanyManagementApi } from './api/company-management.api';
import { CompanyManagementService } from './services/company-management.service';
import {CompanyManagementRoutes} from './company-management.routing';
import {CompanyBrokerListComponent} from './components/company-broker-list/company-broker-list.component'
import { from } from 'rxjs';
import {CompanyBrokerFormDialogComponent} from './components/company-broker-form-dialog/company-broker-form-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { BrokerFormDialogComponent } from 'app/broker-management/components/broker-form-dialog/broker-form-dialog.component';
import { BrokerManagementModule } from 'app/broker-management/broker-management.module';

@NgModule({
  imports: [   ReactiveFormsModule, 
    CommonModule,
    TranslateModule, 
    FormsModule      ,
    FlexLayoutModule,
    RouterModule.forChild(CompanyManagementRoutes),
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
          CommonModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      GridModule, DragDropModule,ResizableModule
  ],
  declarations: [  CompanyListComponent, CompanyFormDialogComponent 
    ,CompanyBrokerListComponent,
    CompanyBrokerFormDialogComponent
  ], 
  providers: [CompanyManagementApi ,CompanyManagementService]
})
export class CompanyManagementModule { }