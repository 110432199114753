import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '../../../app-component-base';

import { ToastrService } from 'ngx-toastr';
import { SetupApi } from '../../../Shared/api/setup.api';
import { ClientService } from '../../services/client.service';
import { ClientCompanyDto } from '../../../models/client'
import { CompanyManagementService } from 'app/company-management/services/company-management.service';


@Component({
  selector: 'ms-client-store-company-form-dialog',
  templateUrl: './client-store-company-form-dialog.component.html',
  styleUrls: ['./client-store-company-form-dialog.component.scss']
})
export class ClientStoreCompanyFormDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  clientId: number;
  companies: any;
  addNewClientForm: FormGroup;
  isUpdate: boolean;
  errors: any;
  statusList: any;
  Clients: any;
  FilterClients: any;
  isWarehouse: any;
  clientCompany: ClientCompanyDto = new ClientCompanyDto();
  disableClient:any;
  constructor(public formBuilder: FormBuilder,
    private clientService: ClientService,
    private setupApi: SetupApi,
    private dialog: MatDialog,
    private companyManagementService: CompanyManagementService,
    private dialogRef: MatDialogRef<ClientStoreCompanyFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , injector: Injector
  ) {
    super(injector);
  

    

    this.title = this.translate("Assign Client To Company From Warehouse");
    if (this.isBroker()) {
      this.getUserBrokerInfo().subscribe(res => {

        if (res != null  ) {
          this.clientCompany.CompanyId = res.CompanyId;
           
        }
      })

    }  else {
      this.getCompanies();
    }


  }

  ngOnInit(): void {
    this.addNewClientForm = new FormGroup({
      Remark: new FormControl({ value: '' }),
      itemNameseaRCHb: new FormControl({ value: '' }),
      ClientId: new FormControl({ value: '' }),
      CompanyId: new FormControl({ value: '' })
    });

    this.clientService.getOnlyStoreClients(null).
      subscribe(res => {
        this.Clients = res;
        this.FilterClients = res
      });
  }

  getCompanies() {
    this.companyManagementService.getActiveCompanies().subscribe(res => {
      this.companies = res;

    });
  }
  filterclients(value) {

    if (value.length > 1)
      this.FilterClients = this.Clients.filter(x => 
        x.NameAr.toLowerCase().includes(value) 
        || x.NameEn.toLowerCase().includes(value)
        || x.NationalID.toLowerCase().includes(value));
    else
      this.FilterClients = this.Clients;
  }
  close() {
    this.dialogRef.close(false);
  }

  onFormSubmit() {

    this.errors = [];
    
    if(this.isUpdate){
      this.clientCompany.CreatedUserId = this.currentUserId();
      this.clientCompany.IsActive = true;
      this.clientService.UpdateCompanyClient(this.clientCompany,this.clientId).subscribe(() => {
        this.dialogRef.close(this.addNewClientForm.value);
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
  
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        });
    }else{
      this.clientCompany.CreatedUserId = this.currentUserId();
      this.clientCompany.IsActive = true;
      this.clientService.insertCompanyClient(this.clientCompany).subscribe(() => {
        this.dialogRef.close(this.addNewClientForm.value);
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
  
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        });
    }


  }



}

