import { Component, OnInit, Input } from '@angular/core';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';

@Component({
  selector: 'ms-ticker-slider',
  templateUrl: './ticker-slider.component.html',
  styleUrls: ['./ticker-slider.component.scss']
})
export class TickerSliderComponent implements OnInit {
 

 sliderContent : any   ;
  
 marketId: any; 
 //ticker slider config
 sliderConfig  = {
	   "speed": 10000,
	   "autoplay": true,
	   "autoplaySpeed": 0,
	   "cssEase": 'linear',
	   "slidesToShow": 5,
	   "slidesToScroll": 1,
	   "arrows": false,
	   "dots": false,
	   "responsive": [
	   {
		   breakpoint: 1480,
		   settings: {
			   slidesToShow: 4,
			   slidesToScroll: 1
		   }
	   },
	   {
		   breakpoint: 1280,
		   settings: {
			   slidesToShow: 3,
			   slidesToScroll: 1
		   }
	   },
	   {
		   breakpoint: 960,
		   settings: {
			   slidesToShow: 2,
			   slidesToScroll: 1,
			   speed: 7000
		   }
	   },
	   {
		   breakpoint: 599,
		   settings: {
			   slidesToShow: 1,
			   slidesToScroll: 1
		   }
	   }
   ]};


	constructor(
		private readonly signalrService: SignalRService  ,

		private marketWatchApi: MarketWatchApi,
		private pageTitleService :PageTitleService
	  ) {

		
		  this. getMarketWatchData();
	  }
 getMarketWatchData(){
     
	this.marketWatchApi.getMarketWatch().subscribe(items => {
	    this.sliderContent = items; 
        this.checkMarketStat();
	}); 
  }
  checkMarketStat(){
	this.sliderContent.forEach(element => {
		if(element.ChangePer >0){
			element.icon="fa-arrow-up";
			element.text_color="arrow-up";
			
		}else if(element.ChangePer <0){
			element.icon="fa-arrow-down";
			element.text_color="arrow-down";
		}else{
			element.icon="fa-minus";
			element.text_color="arrow-none";
		}

	});
}
	ngOnInit() { 
	}


 

}
