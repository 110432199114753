import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'app/client-managment/services/client.service';
import { ClientBankDto, ClientDto, StoreClientDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { BankDto } from 'app/models/bank';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { StoreSectionDto, StoreDto, StoreClientViewDto } from 'app/models/store';
import { AppComponentBase } from 'app/app-component-base';
import { AuthService } from 'app/service/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MarketTypeEnum, StatusEnum, UserTypeEnum, WorkingDayEnum } from 'app/core/enums';
import { BrokerDto } from 'app/models/broker';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service'
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MarketService } from 'app/market-managment/services/market.service';
import { MarketDto, MarketSessionDto, MarketWorkingDayDto } from 'app/models/market';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AdminService } from 'app/admin/services/admin.service';

@Component({
  selector: 'ms-market-form',
  templateUrl: './market-form.component.html',
  styleUrls: ['./market-form.component.scss']
})
export class MarketFormComponent extends AppComponentBase implements OnInit {

  WarehouseTitle: string;
  title: string;

  addNewMarketForm: FormGroup;

  isUpdate: boolean;
  errors: any;
  selectedIndex: number = 0;

  statusList: any;
  marketWorkingDay: MarketWorkingDayDto = new MarketWorkingDayDto();
  market: MarketDto = new MarketDto();
  parentMarketId: any;
  marketId: any;
  status: any;
  workingDayLst: any[] = new Array();
  itemWorkingDays: any[] = new Array();
  marketTypes: any[] = new Array();
  WorkingDays: any;
  marketSessions: any[] = new Array();
  marketCommissions: any[] =[];
  dialogRef: MatDialogRef<DemoDialog>;
  
  constructor(
    public formBuilder: FormBuilder,
    private marketService: MarketService,
    private dialog: MatDialog,
    private pageTitleService: PageTitleService,
    private route: ActivatedRoute,
    private _helper: EnumHelpers,
    private adminService: AdminService,
    private setupApi: SetupApi, injector: Injector
  ) {
    super(injector);

    this.itemWorkingDays = _helper.getNamesAndValues(WorkingDayEnum);
    this.marketTypes = _helper.getNamesAndValues(MarketTypeEnum);
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.marketId = +prams.get('id') as number;
      this.parentMarketId = +prams.get('parentId') as number;
      if (this.marketId > 0) {
        this.getMarket(this.marketId);
        this.getWorkingDays(this.marketId);
        this.getMarketSessions(this.marketId);
        this.isUpdate=true;
      } else {
        this.title = 'Add New Market';
        this.isUpdate=false;

      }


    })

    this.pageTitleService.setTitle('Sub Markets');
    this.statusList = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];



    this.addNewMarketForm = new FormGroup({
      WorkingDayName: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      NameAr: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      NameEn: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IsActive: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Code: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      AllowBalance: new FormControl({ value: '' }),
      AllowQty: new FormControl({ value: '' }),
      AllowWarehouse: new FormControl({ value: '' }),
      MarketType: new FormControl({ value: '' })
    });


  }


  tabChanged($event) {
    this.selectedIndex = $event.index;
  }


  getWorkingDays(id) {
    this.WorkingDays = [];

    this.marketService.getMarketWorkingDays(id).subscribe(res => {
      this.workingDayLst = res;
      this.marketWorkingDay.WorkingDays = [];
      this.marketWorkingDay.MarketId = id;

      this.workingDayLst.forEach(element => {
        this.marketWorkingDay.WorkingDays.push(element.WorkingDay);
      });


    });
  }

	getMarketSessions(id) {
	 
		this.marketService.getMarketSessionsById(id).subscribe(res => {
		
			this.marketSessions = res; 
	
		});
	}

  getMarket(id) {
    this.marketService.getMarket(id).subscribe(res => {

      this.market = res;
      var marketName = this.market.NameEn;
      if (this.isRtl()) {
        marketName = this.market.NameAr;
      }
      this.parentMarketId = this.market.ParentMarketId as number;
      if (this.parentMarketId > 0) {

        this.title = this.translate('Update ') + ' - ' + marketName;
      } else {

        this.title = this.translate('Main Market Update') + ' - ' + marketName;
      }
    });
  }





  onBasicFormSubmit() {
    this.errors = [];

  if(this.marketSessions!=null && this.marketSessions.length>0){
    this.market.marketSessions = this.marketSessions;
    this.market.marketWorkingDays = this.marketWorkingDay.WorkingDays;
    this.market.marketCommissions = this.marketCommissions;
 
    if (this.market.Id > 0) {
      this.marketService.updateMarket(this.market, this.market.Id).subscribe(() => {

        this.toastr.success(this.translate("Successfully"));
      },
        err => {

          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
        });

    } else {

      if (this.parentMarketId != null) {
        this.market.ParentMarketId = this.parentMarketId;
      }

      this.marketService.insertMarket(this.market).subscribe(res => {

        this.market.Id = Number(res);
        this.marketId = this.market.Id;

        this.toastr.success(this.translate("Successfully"));


      },
        err => {
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        
        }
      );
    }
     
    
  //this.KillAllSession();
    
  }else{
    this.toastr.error("Please, create at least one market session");
  }


  }


  getSessions($event: any) {
    var res = $event;
   
    this.marketSessions=res; 
  }

  getCommissions($event: any) {
    var res = $event;
    this.marketCommissions=res; 
  }

  KillAllSession() {
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'Would you like to kill all the sessions ?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.destroyAllSession().subscribe((res) => {
          
          this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }


}
     