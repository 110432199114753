<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
				  <mat-card-actions class="mat-pad-none margin-none">  </mat-card-actions>
			   </div>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="start center">				
                    <div class="trade-crypto crypto-input-prefix">
                        <div class="padding-input-form">
                          <mat-form-field appearance="outline"  class="padding10 form-field">
                            <mat-label>{{ 'Request Type'| translate }}</mat-label>
                             <mat-select     [(ngModel)]="RequestType"  name="RequestType"	
                             (selectionChange)="onChangeRequestType($event);"   
                                    class="filter-select"    [placeholder]="'Request Type' | translate " > 
                                   <mat-option *ngFor="let bankMasrRequests of BankMasrRequests" [value]="bankMasrRequests.id">
                                     {{bankMasrRequests.name | translate }}
                                   </mat-option>
                             </mat-select>
                         </mat-form-field>

                          <mat-form-field class="padding10 form-field"  appearance="outline"  *ngIf="!this.isBroker()" >
                            <mat-label>{{ 'Trading Company' | translate }}</mat-label>
                              <mat-select 							        
                                  [(ngModel)]="filterOptions.CompanyId"
                                  name="CompanyID" 
                                  class="filter-select" 
                                  [placeholder]="'Company' | translate "
                                  (selectionChange)="onChangeCompany($event);">
                                  <mat-label>{{ 'Company' | translate }}</mat-label>	
                              <mat-option>								
                                <ngx-mat-select-search name="companySearch" 
                                  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                               >
                                </ngx-mat-select-search>
                                <ngx-mat-select-filter></ngx-mat-select-filter>
                              </mat-option>
                                <mat-option >	{{ 'All' | translate }} </mat-option>
                                  <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                    {{isRtl()?company.NameAr:company.Name}}
                                </mat-option>
                              </mat-select> 
                          </mat-form-field>
                    
                            <mat-form-field appearance="outline"  class="padding10 form-field">
                               <mat-label>{{ 'Client' | translate }}</mat-label>
                                <mat-select    
                                   [(ngModel)]="filterOptions.ClientId" 
                                    name="ClientID"	 
                                    class="filter-select"
                                   [placeholder]="'Client' | translate "
                                 >
                                  <mat-label>{{ 'Client' | translate }}</mat-label>								
                                  <mat-option>								
                                    <ngx-mat-select-search name="clientSearch" 
                                      placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                      (ngModelChange)="changeClientFilter($event)">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                    <mat-option > {{ 'All' | translate }} </mat-option>
                                      <mat-option *ngFor="let client of clients" [value]="client.Id">
                                        {{isRtl()?client.NameAr:client.NameEn}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="  padding10 form-field" appearance="outline">
                              <mat-label>{{ 'Date' | translate }} </mat-label>
                              <mat-date-range-input [rangePicker]="picker" >
                                <input matStartDate [(ngModel)]="filterOptions.FromDate"    placeholder="Start date">
                                <input matEndDate  [(ngModel)]="filterOptions.ToDate"   placeholder="End date">
                                </mat-date-range-input>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
                  <a mat-raised-button color=""  (click)="getList()"   title=" {{ 'Search' | translate }}" ><mat-icon  >search</mat-icon></a>	
          
                </div>
             </div>
 
	

		 </div>
   </mat-card> 
   <mat-card *ngIf="RequestType==BankMasrRequestTypeEnum.TransactionInquiry"> 
			<br/> 
        <kendo-grid   #grid
        [data]="view | async"
        [filterable]="false" 
        [resizable]="true"
        [reorderable]="true"
        filterable="menu"  
        [sortable]="true" 
        [kendoGridSelectBy]="selectBy"
        [columnMenu]="true"
        class="market-kendo-grid"
        [rowClass]="rowCallback"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [pageable]="true"
        (dataStateChange)="dataStateChange($event)" >      
         <ng-template kendoGridToolbarTemplate>
				  <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
			  </ng-template>
			  <kendo-grid-excel fileName="BankMisr_TransactionInquiryLog.xlsx" > </kendo-grid-excel>
               <kendo-grid-column   title="{{ 'ID' | translate }}  " 
               [class]="{ codeColumn: true }"
               [columnMenu]="false" headerClass="grid-list-column-header" width="40" >
    
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span> {{(rowIndex+1)}}</span>	
              </ng-template>
               </kendo-grid-column>
    
               <kendo-grid-column field="serviceRefNum"  title="{{ 'Client Code' | translate }} " headerClass="grid-list-column-header" width="120" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                     <span class="client-name"  >{{dataItem.serviceRefNum  }} </span> 
                </ng-template>
                <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Code'| translate }} </ng-template>
             </kendo-grid-column>
       

              <kendo-grid-column field="requestId"  title="{{ 'Request Id' | translate }} " headerClass="grid-list-column-header" width="120" >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                     <span class="client-name"  >{{dataItem.requestId  }} </span> 
                </ng-template>
                <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Id'| translate }} </ng-template>
             </kendo-grid-column>
       
             <kendo-grid-column field="InquiredId"  title="{{ 'Inquired Id' | translate }} " headerClass="grid-list-column-header" width="120" >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                   <span class="client-name"  >{{dataItem.InquiredId  }} </span> 
              </ng-template>
              <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Inquired Id'| translate }} </ng-template>
           </kendo-grid-column>
     
   
  

            <kendo-grid-column field="requestDate"  title="{{ 'Request Date' | translate }} " headerClass="grid-list-column-header" width="120" >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                   <span class="client-name"  >{{dataItem.requestDate | date: 'dd/MM/yyyy'}} </span> 
             
              </ng-template>
              <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Date'| translate }} </ng-template>
           </kendo-grid-column>
    
           <kendo-grid-column field="bankTerminalId"  title="{{ 'Bank Terminal Id' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.bankTerminalId  }} </span> 
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Bank Terminal Id'| translate }} </ng-template>
         </kendo-grid-column>

 
     </kendo-grid> 

   </mat-card>

   <mat-card *ngIf="RequestType==BankMasrRequestTypeEnum.Payment"> 
    <br/> 
      <kendo-grid   #grid
      [data]="view | async"
      [filterable]="false" 
      [resizable]="true"
      [reorderable]="true"
      filterable="menu"  
      [sortable]="true" 
      [kendoGridSelectBy]="selectBy"
      [columnMenu]="true"
      class="market-kendo-grid"
      [rowClass]="rowCallback"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [pageable]="true"
      (dataStateChange)="dataStateChange($event)" > 
      <ng-template kendoGridToolbarTemplate>
				  <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
			  </ng-template>
			  <kendo-grid-excel fileName="BankMisr_PaymentLog.xlsx" > </kendo-grid-excel>
             <kendo-grid-column   title="{{ 'ID' | translate }}  " 
             [class]="{ codeColumn: true }"
             [columnMenu]="false" headerClass="grid-list-column-header" width="40" >
  
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             <span> {{(rowIndex+1)}}</span>	
            </ng-template>
             </kendo-grid-column>
  
             <kendo-grid-column field="serviceRefNum"  title="{{ 'Client Code' | translate }} " headerClass="grid-list-column-header" width="120" >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                   <span class="client-name"  >{{dataItem.serviceRefNum  }} </span> 
              </ng-template>
              <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Code'| translate }} </ng-template>
           </kendo-grid-column>
     

            <kendo-grid-column field="requestId"  title="{{ 'Request Id' | translate }} " headerClass="grid-list-column-header" width="120" >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                   <span class="client-name"  >{{dataItem.requestId  }} </span> 
              </ng-template>
              <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Id'| translate }} </ng-template>
           </kendo-grid-column>
     
           <kendo-grid-column field="asyncId"  title="{{ 'Async Id' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.asyncId  }} </span> 
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Async Id'| translate }} </ng-template>
         </kendo-grid-column>
   
         <kendo-grid-column field="externalTransactionNumber"  title="{{ 'External Transaction Number' | translate }} " headerClass="grid-list-column-header" width="120" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="client-name"  >{{dataItem.externalTransactionNumber  }} </span> 
          </ng-template>
          <ng-template kendoGridHeaderTemplate   let-column>  {{ 'External Transaction Number'| translate }} </ng-template>
       </kendo-grid-column>


          <kendo-grid-column field="requestDate"  title="{{ 'Request Date' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.requestDate | date: 'dd/MM/yyyy'}} </span> 
           
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Date'| translate }} </ng-template>
         </kendo-grid-column>
  
         <kendo-grid-column field="bankTerminalId"  title="{{ 'Bank Terminal Id' | translate }} " headerClass="grid-list-column-header" width="120" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="client-name"  >{{dataItem.bankTerminalId  }} </span> 
          </ng-template>
          <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Bank Terminal Id'| translate }} </ng-template>
       </kendo-grid-column>

       <kendo-grid-column field="paymentCode"  title="{{ 'Payment Code' | translate }} " headerClass="grid-list-column-header" width="120" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             <span class="client-name"  >{{dataItem.paymentCode  }} </span> 
        </ng-template>
        <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Payment Code'| translate }} </ng-template>
     </kendo-grid-column>


     <kendo-grid-column field="Currency"  title="{{ 'Currency' | translate }} " headerClass="grid-list-column-header" width="120" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <span class="client-name"  >{{dataItem.Currency  }} </span> 
      </ng-template>
      <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Currency'| translate }} </ng-template>
   </kendo-grid-column>

   <kendo-grid-column field="Amount"  title="{{ 'Amount' | translate }} " headerClass="grid-list-column-header" width="120" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
         <span class="client-name"  >{{dataItem.Amount  }} </span> 
    </ng-template>
    <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Amount'| translate }} </ng-template>
 </kendo-grid-column>
   </kendo-grid> 

 </mat-card>

 <mat-card *ngIf="RequestType==BankMasrRequestTypeEnum.ClientInquiry"> 
  <br/> 
    <kendo-grid   #grid
    [data]="view | async"
    [filterable]="false" 
    [resizable]="true"
    [reorderable]="true"
    filterable="menu"  
    [sortable]="true" 
    [kendoGridSelectBy]="selectBy"
    [columnMenu]="true"
    class="market-kendo-grid"
    [rowClass]="rowCallback"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [pageable]="true"
    (dataStateChange)="dataStateChange($event)" > 

    <ng-template kendoGridToolbarTemplate>
      <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
    </ng-template>
    <kendo-grid-excel fileName="BankMisr_ClientInquiryLog.xlsx" > </kendo-grid-excel>
    
           <kendo-grid-column   title="{{ 'ID' | translate }}  " 
           [class]="{ codeColumn: true }"
           [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <span> {{(rowIndex+1)}}</span>	
          </ng-template>
           </kendo-grid-column>

           <kendo-grid-column field="serviceRefNum"  title="{{ 'Client Code' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.serviceRefNum  }} </span> 
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Code'| translate }} </ng-template>
         </kendo-grid-column>
   

          <kendo-grid-column field="requestId"  title="{{ 'Request Id' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{dataItem.requestId  }} </span> 
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Id'| translate }} </ng-template>
         </kendo-grid-column>
   
 
  


        <kendo-grid-column field="requestDate"  title="{{ 'Request Date' | translate }} " headerClass="grid-list-column-header" width="120" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="client-name"  >{{dataItem.requestDate | date: 'dd/MM/yyyy'}} </span> 
         
          </ng-template>
          <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Request Date'| translate }} </ng-template>
       </kendo-grid-column>

       <kendo-grid-column field="bankTerminalId"  title="{{ 'Bank Terminal Id' | translate }} " headerClass="grid-list-column-header" width="120" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             <span class="client-name"  >{{dataItem.bankTerminalId  }} </span> 
        </ng-template>
        <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Bank Terminal Id'| translate }} </ng-template>
     </kendo-grid-column>

     <kendo-grid-column field="paymentCode"  title="{{ 'Payment Code' | translate }} " headerClass="grid-list-column-header" width="120" >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <span class="client-name"  >{{dataItem.paymentCode  }} </span> 
      </ng-template>
      <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Payment Code'| translate }} </ng-template>
   </kendo-grid-column>
 </kendo-grid> 

</mat-card>
</div>