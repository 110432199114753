<form  class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 

                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

 
							<div fxFlex.gt-sm="48" fxFlex.gt-xs="48" fxFlex="100" fxFlex.gt-md="24">
							   <div class="full-wid form-field" appearance="outline"> 
								  <input type="file" accept=".csv" (change)="importFile($event)" multiple="multiple" />
							   </div>
							</div>
							<div fxFlex.gt-sm="48" fxFlex.gt-xs="48" fxFlex="100" fxFlex.gt-md="48">
					
							</div>
 
						 </div>
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  
		*ngIf="auth.isGranted(_permissions.UploadFileClientStatements) "
	    mat-raised-button color="warn">
		{{ 'SUBMIT' | translate }}  </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">
			
			{{ 'CLOSE' | translate }} 
			</button>
	
	</mat-dialog-actions>
</form>