import { paramsHelper } from './helpers/helpers';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 import{BrokerInfoDto}  from 'app/models/account';
import { AppConsts } from '../../core/config';
import { SymbolDto } from 'app/models/symbol';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { ClientDto, ClientReuqest, SearchRequestDto } from 'app/models/client';
import { SectorDto , CategoryDto } from 'app/models/category';
import { StoreDto } from 'app/models/store';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { MarketDto } from 'app/models/market';







@Injectable()
export class SetupApi {

  constructor(private http: HttpClient) {}

  
  getStoreKeeperInfo(): Observable<any> {
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoreKeeperInfo/`);
  }
  
  getBrokerInfo(): Observable<any> {
    
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.brokerUrl}/getBrokerInfo/`);
  }

  getEMXUserInfo(): Observable<any> {
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getEMXUserInfo/`);
  }

  getCooperativeInfo(): Observable<any> {
   
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.cooperativeUrl}/getCooperativeInfo/`);
  }
  getCooperativeManagerInfo(): Observable<any> {
   
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.cooperativeUrl}/getCooperativeManagerInfo/`);
  }


 
  getActiveSymbols(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getActiveSymbols`);
  }

  GetMarketSymbols(id:any): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbols/`+id);
  }
  // getSymbols(): Observable< []> {
  //   return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbols`);
  // }
  getMarkets(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarkets`);
  }

 getActiveCompanies(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.brokerUrl}/getActiveCompanies`);
  }

  getActiveMarkets(): Observable<MarketDto[]> {
    return this.http.get< MarketDto[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getActiveMarkets`);
  }
  getWarehouse(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStores`);
  }
  getWarehouseLookups(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoresLookups`);
  }
  getWarehouseById(id:any): Observable<any> {
    return this.http.get<any >(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStore/`+id);
  }
  
 


   getAnnouncements(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.marketWatchUrl}/getAnnouncements`);
  }

 
  getBrokers(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getBrokers`);
  }
  getStatusLookup(): Observable<StatusLookupDto> {
    
    return this.http.get<StatusLookupDto>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getStatusLookup`);
  }
  getActiveClients(filter:SearchRequestDto = null): Observable<[]> {
      return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.clientUrl}/getActiveClients`);
  }
  getActiveStoresView(){ 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getActiveStoresView`);
  }
  getSymbolAuctions(id:any){ 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getSymbolAuctions/`+id);
  }
  getOminBusAuctionsBySymbol(id:any){ 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getOminBusAuctionsBySymbol/`+id);
  }

  searchActiveSymbols(filter:SearchRequestDto = null): Observable<SymbolDto[]> {
    let queryParams = "";
    if (filter!=null)
      queryParams = paramsHelper.convertObjectToQueryParameters(filter);
    return this.http.get<SymbolDto[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/searchActiveSymbols`+queryParams);
  }

  searchActiveClients(request: ClientReuqest): Observable<[]> {
    let queryParameters: string = paramsHelper.convertObjectToQueryParameters(request);
   
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getActiveClientsView`+queryParameters)
  }


  getSectors(): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSectors`);
  }
  GetStoreSymbols(symbol): Observable<any> {
     
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/GetStoreSymbol/`+symbol);
  }
  getStoresLookups(): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoresLookups`);
  }
  GetStoreSymbolByCode(symbol): Observable<any> {
     
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/GetStoreSymbolByCode/`+symbol);
  }
  GetStoreSymbolByTradeType(code ,type): Observable<any> {
     
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/GetStoreSymbolByTradeType/`+code+'/'+type);
  }
   

   getCategories(): Observable<[]> {
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCategories`);
  }
  getMarketStatus(id:any): Observable<any> {
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarketStatus/`+id);
  }


    

  
  getMainMarkets( ): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMainMarkets`);
  }

  getSubMarkets(id:any): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSubMarkets/`+id);
  }

  getDepartments(): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getDepartments`);
  }

  getJobTitles(): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getJobTitles`);
  }

  getIdentityTypes(): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getIdentityTypes`);
  }
  getSymbolMarkets(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbolMarkets/`+id);
  }
  getSymbolMarketsByMarketId(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbolMarketsByMarketId/`+id);
  }
  getAllSymbolMarkets(): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getAllSymbolMarkets`);
  }
  getIdentityByType(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getIdentityByType/`+id);
  }
  getIdentityTypeById(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getIdentityTypeById/`+id);
  }
  getNationalities(): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getNationalities`);
  }
  getNationalityById(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getNationalityById/`+id);
  }
 
  getCROffices(): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCROffices`);
  }
  getCountries(): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCountries`);
  }

  getAttachmentTypesByIdentityType(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getAttachmentTypesByIdentityType/`+id);
  }
  getAttachmentTypes( ): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getAttachmentTypes/`);
  }
  
  DownloadAttachment(Request: any ) { 
  this.http.get(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/DownloadAttachment?filename=`+Request.filename+'&EntityType='+Request.EntityType, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' } }).subscribe(_response => {
      
     saveAs(_response, Request.filename, {
     type: 'application/octet-stream'
    });
  }) ; 
  }
  GetOrderAttachmentsByOrderId(id: any ) { 
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getOrderAttachmentsByOrderId/`+id); 
  }


  GetOrderAttachments(id: any): Observable<any> { 
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getOrderAttachments/`+id);
  }

  ViewAttachment(Request: any )  { 
    this.http.get(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/DownloadAttachment?FileName=`+Request.filename+`&EntityType=`+Request.EntityType, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' as 'application/pdf' } }).subscribe(_response => {
        let file = new Blob([_response], { type: 'application/pdf' });            
        var fileURL = URL.createObjectURL(file);
        
       window.open(fileURL, '_blank')
    }) ; 
    }


  getAttachmentsByEntityId(type:any,id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getAttachmentsByEntityId/`+type+'/'+id);
  }
  DeleteAttachment(Request: any ) : Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/DeleteAttachment?filename=`+Request.filename+'&EntityType='+Request.EntityType+'&Id='+Request.Id );
   }
   DeleteOrderAttachment(id: any  ,fileName:any) : Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/DeleteOrderAttachment/`+id+ '&filename='+fileName );
   }
   UpdatePropertiesStatus(Request: any ): Observable<boolean> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/UpdatePropertiesStatus/`, 
    Request)
      .pipe(tap(data => {}));
  }
  getAuction(id:any): Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getAuction/`+id);
   }
  getAuctionSettingByTypeId(id:any): Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getAuctionSettingByTypeId/`+id);
   }
   getAuctionSettings(): Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getAuctionSettings`);
   }

  GetAuctionTypes(): Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getAuctionTypes`);
   }
  getPropertiesStatusLogs(filter:any): Observable<any> {
    let queryParams = ""; 
    if (filter!=null)
      queryParams = paramsHelper.convertObjectToQueryParameters(filter);
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getPropertiesStatusLogs`+queryParams);
  }
  getReasonStatus(): Observable<any>{ 
    return  this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getReasonStatus`);
   }
   SuspendUser(userId: any ): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/SuspendUser/`+ userId);
   }

   UnsuspendUser(userId: any ): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/UnsuspendUser/`+ userId);
   }


   
   UploadFiles(attachement: any): Observable<any> {
    
   return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/UploadFiles`,attachement, {reportProgress: true, observe: 'events'});
 }
 getSymbolUnits( ): Observable<any> {
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbolUnits`);
}

getCompanyTypes( ): Observable<any> {
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCompanyTypes`);
}
getStoresByType(id:any){
  return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoresByType/`+id );
}

getCompanyByType( type): Observable<any> {
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.brokerUrl}/getCompanyByType/`+type);
}
getSymbolUnitRated( from ,to ): Observable<any> {
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbolUnitRated/`+from +'/'+to);
}

getCityById(id:any){
  return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCityById/`+id );
}
getCurrentCurrencyRateById(id:any ,date:any): Observable<any> { 
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. setupUrl}/getCurrentCurrencyRateById?Id=`+id+'&date='+date);
} 
getCurrentCurrencyRateBySymbol(code:any ,date:any): Observable<any> { 
  return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts. setupUrl}/getCurrentCurrencyRateBySymbol?SymbolCode=`+code+'&date='+date);
} 
getCurrencies( ): Observable<[]> { 
  return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts. setupUrl}/getCurrencies`);
} 
}

