<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
 
 
				<div class="mrgn-l-sm mrgn-r-sm">
					<h4 *ngIf="selectedMarket!=null">
						<span  *ngIf="isRtl()" > {{selectedMarket.NameAr}}  </span>
						<span  *ngIf="!isRtl()" > {{selectedMarket.NameEn}}  </span>
					</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">

				<mat-card-actions class="mat-pad-none margin-none"
				>
				   <button mat-raised-button mat-button-sm color="primary"
				   *ngIf="auth.isGranted(_permissions.AddMarkets)"
				   [routerLink]=  "['/markets/create-sub/'+ _marketId ]" >  {{ 'ADD SUB MARKET' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
			   </mat-card-actions>
			</div>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">


				<custom-menu [items]="mainMarkets" class="width100 form-field"
					[displayPath]="isRtl()?'NameAr': 'NameEn'" [title]="translate('Main Markets')"
				  [mandatory]='true'
					[icon]="'bar_chart'"
					(onChange)="this._marketId = $event.Id;getMarkets()">
				</custom-menu>

 
			</div>

		</div>
		<br/>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [kendoGridBinding]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > <kendo-grid-column   title="{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
                  
				  <kendo-grid-column field="Code" title="{{ 'Code' | translate }}"  width="100" headerClass="grid-list-column-header" > </kendo-grid-column>
				  <kendo-grid-column field="NameEn" title="{{ 'Name' | translate }}"  width="200" headerClass="grid-list-column-header" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						
						<span  *ngIf="isRtl()" > {{dataItem.NameAr}}  </span>
						<span  *ngIf="!isRtl()" > {{dataItem.NameEn}}  </span>

					</ng-template>
				  </kendo-grid-column> 
				  

				  <kendo-grid-column field="State" title="{{ 'State' | translate }}"  width="150" headerClass="grid-list-column-header session-column" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						

					 
						<span  *ngIf="dataItem.MarketStatus==1" class="session-open">
						    {{'Normal' | translate}}  	 <mat-icon class="session-column">lens</mat-icon>  

						</span>
						<span  *ngIf="dataItem.MarketStatus==2" class="session-preopen">
						{{'Pre Open' | translate}}  	<mat-icon class="session-column">lens</mat-icon> 
						 
					   </span>
					   <span  *ngIf="dataItem.MarketStatus==3"  class="session-close">
						  {{'Session Close' | translate}} 	<mat-icon class="session-column">lens</mat-icon>   

				      </span>
					</ng-template>
				  </kendo-grid-column>


				  <kendo-grid-column field="CloseTime" title="{{ 'Closing at' | translate }}" width="150" headerClass="grid-list-column-header"> 
 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span  *ngIf="dataItem.MarketStatus==1" class="session-open">
							{{dataItem.EndSessionTime}}
					   </span>
					   <span  *ngIf="dataItem.MarketStatus==2" class=" session-preopen">
						{{dataItem.EndSessionTime}}
					  </span>
					  <span  *ngIf="dataItem.MarketStatus==3" class=" session-close">
						{{dataItem.EndSessionTime}}
					 </span>
					</ng-template>
				  </kendo-grid-column>
				   <kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" width="150" headerClass="grid-list-column-header"> 
 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>

				  <kendo-grid-column field="CreatedDate" title= "{{ 'Updated On' | translate }}"  headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.LastUpdatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
 



				  <kendo-grid-column   title=""   
				  headerClass="grid-list-column-header"
				  *ngIf="auth.isGranted(_permissions.GetMarketSettings) 
				  || auth.isGranted(_permissions.AssignMarketsToSymbols)
				  ||auth.isGranted(_permissions.DeleteMarkets)" >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item
							[routerLink]="['/markets/edit/'+ dataItem.Id ]"  	  >
								{{ 'View' | translate }}
							  </a>
							  <a mat-menu-item   
							  (click)="changePropertiesStatus(dataItem)"   >
							  {{ 'Change Status' | translate }} 
						 </a>

							  <!-- <a mat-menu-item  *ngIf="auth.isGranted(_permissions.DeleteMarkets)  "  (click)="deleteMarket(dataItem)"   >
								{{ 'Delete' | translate }}
						   </a>
							  -->
						   
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>



 
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>