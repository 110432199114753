import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { ApproveStatusEnum, StatusEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import {ClientDto} from 'app/models/client';
import { ClientService } from 'app/client-managment/services/client.service';
import { AppComponentBase } from 'app/app-component-base';
import { ClientStoreFormDialogComponent } from '../client-store-form-dialog/client-store-form-dialog.component';
 
@Component({
  selector: 'ms-client-store-list',
  templateUrl: './client-store-list.component.html',
  styleUrls: ['./client-store-list.component.scss']
})
export class ClientStoreListComponent   extends AppComponentBase implements OnInit, OnDestroy {

  public items: ClientDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  searchTxt:string="";
  approveStatus=ApproveStatusEnum;
  public actions: any;
  public action: any; 
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }
  private _clientId: number;
  @Input()
 status: number;

 @Input() set clientId(value: number) {
  this._clientId = value; 
 }
 
 @Input() set clientStores(value: any) {
  this.items = value;
 }
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector); }

  ngOnInit(): void {
 
  }
  
  getClientStore(id){
 
    this.clientService.getStoreByClientId(id).subscribe(res=>{
       this.items=res;
    });
  }



  addNewClientStoreDialog(){ 
    const dialog = this.dialog.open(ClientStoreFormDialogComponent, {
        data:{ clientId:this._clientId }  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getClientStore(this._clientId);

      } );
  }

 edit(data){
  const dialog = this.dialog.open(ClientStoreFormDialogComponent, {
    data:{  clientStore:data ,clientId:this._clientId }  
  });
  dialog.afterClosed().subscribe((res) => {
    this.getClientStore(this._clientId);

  } );
 }



}

