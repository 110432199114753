export class AnnouncementDto {
    AnnouncementID: number;
    EntityId: number;
    AnnouncementType: number;
    AnnouncementTypeTxt: string;
    SymbolCode: string;
    SymbolCodeName:string
    HeaderEn: string;
    HeadrAr: string;
    DetailsEn: string;
    DetailsAr: string;
    URL: string;
    Priority: number;
    PriorityName: string;
    IsDeleted: boolean;
    IsActive: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    RecSerial: string;
    isChecked: boolean;
    FileId?: number;

  }
  export class CreateAnnouncementDto
{
          AnnouncementID: number;
          AnnouncementType: number;
          SymbolCode: string;
          HeaderEn: string;
          HeadrAr: string;
          DetailsEn: string;
          DetailsAr: string;
          URL: string;
          Priority: any;
          IsDeleted: boolean;
          IsActive: boolean;
          UpdatedUserId: string;
}
export class AnnouncementTypeDto {
  AnTypeID: number;
  AnTypeEn: string;
  AnTypeAr: string;
}

export class AnnouncementRequestDto {
  Skip: number;
  Take: number;
  AnouncementType: number;
  DateTime?: any;
  SymbolCode: string;
  FromDate: any;
  ToDate: any;
  IsPublic: any;
}