<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
   <mat-card fxFlex="100%">
      <div class="gene-card-title">
         <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
               <h4>{{title}}</h4>
            </div>
            <span fxFlex></span>
         </div>
         <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
         <div class="form-tab trade-crypto crypto-input-prefix">
            <mat-tab-group    
            
            >
               <mat-tab label=" {{ 'Basic Info' | translate }}">
                  <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewSymbolForm">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">

                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>  {{ 'Symbol Code' | translate }} </mat-label>
                               <input class="form-control" matInput type="input" placeholder="{{ 'Symbol Code' | translate }}" 
                                   formControlName="SymbolCode"  
                                   [readonly]="this.isUpdate" 
                                   [(ngModel)]="symbol.SymbolCode"  >  
                             </mat-form-field>



                           </div>
 


                           <div fxFlex.gt-sm="39" fxFlex.gt-xs="39" fxFlex="100" fxFlex.gt-md="39">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Symbol Name English' | translate }}</mat-label>
                               <input class="form-control" matInput type="input" placeholder="Symbol Name English" 
                                   formControlName="SymbolNameEn"  
                            
                                   [(ngModel)]="symbol.NameEn"  >  
                             </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="39" fxFlex.gt-xs="39" fxFlex="100" fxFlex.gt-md="39">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Symbol Name Arabic' | translate }}  </mat-label>
                               <input class="form-control" matInput type="input" placeholder="Symbol Name Arabic" 
                                   formControlName="SymbolNameAr"  
                            
                                   [(ngModel)]="symbol.NameAr"  >  
                             </mat-form-field>
                           </div>




                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
					 
                             <mat-form-field class="full-wid form-field" appearance="outline">
                              <mat-label> {{ 'Main Market' | translate }}</mat-label>
                              <mat-select  [(ngModel)]="mainMarketId"  name="mainMarketId"
                              (selectionChange)="onChangeMainMarket($event)"
                                    formControlName="mainMarketId"  
                                    [disabled]="isUpdate"  > 
                                   <mat-option  *ngFor="let market of  mainMarkets " [value]="market.Id">

                                    <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
                                   <span 	*ngIf="isRtl()">{{market.NameAr}}</span> 
                                    </mat-option>
                              </mat-select> 
                           </mat-form-field>

               
                           </div>

                           
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
					 
                              <mat-form-field class="full-wid form-field" appearance="outline">
                               <mat-label> {{ 'Sub Market' | translate }}</mat-label>
                               <mat-select  [(ngModel)]="symbol.MarketId"  name="Market"
                               [disabled]="isUpdate" 
                                     formControlName="MarketId"  > 
                                    <mat-option  *ngFor="let market of  subMarkets" [value]="market.Id">
 
                                     <span *ngIf="!isRtl()">{{market.NameEn}}</span> 
                                    <span 	*ngIf="isRtl()">{{market.NameAr}}</span> 
                                     </mat-option>
                               </mat-select> 
                            </mat-form-field>
 
                
                            </div>

 


                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
					
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Unit' | translate }}</mat-label>
                                 <mat-select  [(ngModel)]="symbol.Unit"  name="Unit"
                                  
                                       formControlName="Unit"  > 
                                      <mat-option  *ngFor="let unit of  symbolUnits" [value]="unit.UnitID">
                                      {{unit.UnitNameEn}}</mat-option>
                                 </mat-select> 
                              </mat-form-field>
               
                           </div>
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
					
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Currency' | translate }}</mat-label>
                                 <mat-select  [(ngModel)]="symbol.CurrencyId"  name="CurrencyId"
                                  
                                       formControlName="CurrencyId"  > 
                                      <mat-option  *ngFor="let currency of  currencies"  [value]="currency.Id">
                            
                                      <span *ngIf="isRtl()"  > {{ currency.NameAr}}</span>
                                      <span *ngIf="!isRtl()"  > {{ currency.NameEn}} </span>

                                      </mat-option>
                                 </mat-select> 
                              </mat-form-field>
               
                           </div>
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Cover%' | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="Cover %" 
                                   formControlName="CoverPerc"
                                   
                                   [(ngModel)]="symbol.CoverPerc"  >  
                              </mat-form-field>
                           </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
 

                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label>{{'Open'  | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="Open Price" 
                                   formControlName="OpenPrice"  
                                   (input)="changeOpenPrice()"
                                   [(ngModel)]="symbolMarket.OpenPrice"  >  
                              </mat-form-field>
                           </div> 
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label>{{'Upper%'  | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="Upper %" 
                                   formControlName="UpperLPerc"  
                                   (input)="calUpperValue()"
                                   [(ngModel)]="symbolMarket.UpperLPerc"  >  
                              </mat-form-field>
                           </div> 
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label>{{'Upper Value'  | translate }}</mat-label>
                               <input class="form-control" matInput type="number" placeholder="Upper Value" 
                                   formControlName="UpperLValue"  
                                   
                                   [(ngModel)]="symbolMarket.UpperLValue"  >  
                             </mat-form-field>
                           </div> 
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label> {{'Lower%'  | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="Lower %" 
                                   formControlName="LowerLPerc"  
                                   (input)="calLowerValue()"
                                   [(ngModel)]="symbolMarket.LowerLPerc"  >  
                              </mat-form-field>
                           </div> 
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label>  {{'Lower Value'  | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="Lower Value" 
                                   formControlName="LowerLValue"  
                                  
                                   [(ngModel)]="symbolMarket.LowerLValue"  >  
                              </mat-form-field>
                           </div> 

                        </div>
 
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field  form-field class=" full-wid  form-field " appearance="outline">
                                 <mat-label>{{'No Of Settlement Days'  | translate }}</mat-label>
                                <input class="form-control" matInput type="number" placeholder="{{'No Of Settlement Days'  | translate }}" 
                                   formControlName="NoSettlement"  
                                   [(ngModel)]="symbolMarket.NoSettlement"  >  
                              </mat-form-field>
                           </div> 
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Attachment Type' | translate }}</mat-label>
                                 <mat-select 
                    
                                 [(ngModel)]="uploadAttachmentTypeId"
                                 name="attachmentType" 
                                    formControlName="attachmentType">
                                    <mat-option *ngFor="let attachment of attachmentTypes" [value]="attachment.Id">
                               
                                       <span *ngIf="isRtl()">{{attachment.NameAr}}</span>
                                       <span *ngIf="!isRtl()">{{attachment.NameEn}}</span>
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>  
                           </div>
                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <div class="full-wid form-field" appearance="outline"> 
                                 <input type="file" accept=".pdf" (change)="importFile($event)" multiple="multiple" />
                              </div>
                           </div>
                           <div fxFlex.gt-sm="39" fxFlex.gt-xs="39" fxFlex="100" fxFlex.gt-md="39">
                   
                           </div>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="start">
                           <button 
                           (click)="onFormSubmit()" [disabled]="!addNewSymbolForm.valid"   type="submit" mat-raised-button color="warn"
                           *ngIf="auth.isGranted(_permissions.EditSymbols)" >{{ 'SUBMIT' | translate }}  </button>
                           <a  [routerLink]="['/symbols/symbols/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                        </div>
                        
                     </div>
                  </form>
               </mat-tab>

 
<!--  
               <mat-tab label=  "{{ 'Symbol Markets' | translate }}" 
                   >
                 <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <ms-symbol-market-manage-list  symbolId={{symbolId}}>  </ms-symbol-market-manage-list>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start">

                     <button    mat-raised-button
	
                     color="warn">{{ 'Assign  Market' | translate }} </button> 
                     <a  [routerLink]="['/symbols/symbols/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                  </div>
               </mat-tab> -->


               

               <mat-tab label=  "{{ 'Symbol Warehouses' | translate }}" 
               [disabled]="!(symbolId>0)">
             <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <ms-symbol-warehouse-list  symbolId={{symbolId}}>  </ms-symbol-warehouse-list>
              </div>

 
           </mat-tab>

           <mat-tab label=  "{{ 'Symbol Commissions' | translate }}" 
           [disabled]="!(symbolId>0)">
         <div fxLayout="row wrap" fxLayoutAlign="space-between center" >
            <ms-symbol-market-manage-commission-list  symbolId={{symbolId}}>  </ms-symbol-market-manage-commission-list>
          </div>

          <div fxLayout="row" fxLayoutAlign="start">
 
             <a  [routerLink]="['/symbols/symbols/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

          </div>
       </mat-tab>
            </mat-tab-group>
         </div>

      </div>
   </mat-card>
</div>