import { Component, ElementRef, Inject, Injector, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { BrokerCompanyDto, BrokerDto, CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import {Helpers}from 'app/helper/helpers';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AppComponentBase } from 'app/app-component-base';
import { OrderDto } from 'app/models/order';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';

@Component({
  selector: 'ms-order-cofirmation-dialog',
  templateUrl: './order-cofirmation-dialog.component.html',
  styleUrls: ['./order-cofirmation-dialog.component.scss']
})
export class OrderCofirmationDialogComponent    extends AppComponentBase implements OnInit {
 
  errors: any;
  orderId:any;
  order: OrderDto = new OrderDto();
  title:any;
  isEnable = true;
  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private  dialogRef: MatDialogRef<OrderCofirmationDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , private dashboardApi: DashboardApi,
 
    private _helpers:Helpers   , injector: Injector
    ) {

      super(injector);
       
    if (data.order != null) {
      this.order=data.order;
     
    
    } 
    if (data.orderId != null) {
      this.orderId=data.orderId;
    
    } 
    this.title="Order Confirmation"

  }

  ngOnInit(): void {

    

  }
  close() {
 
    this.dialogRef.close(false);
  }


  onFormSubmit( ) {

    if (this.orderId > 0) { 
      this.updateOrder();

    } else {
      this.addOrder();

    }
   
  }


  addOrder() {
    this.errors = [];
    this.isEnable = false;
    this.dashboardApi.createOrder(this.order).subscribe(res => {

      this.dialogRef.close(true);
      this.toastr.success(this.translate('Successfully'));

    },
      err => { 
        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);
        this.isEnable = true;
        // console.log(err)
      }
    );
  }

  updateOrder() {

    this.errors = [];
    this.isEnable = false;
    this.dashboardApi.updateOrder(this.order, this.orderId).subscribe(() => {

 
     this.dialogRef.close(true);
      this.toastr.success(this.translate('Successfully'));

    },
      err => {
        this.errors.push(err.error[0]);
        this.toastr.error(err.error[0]);
        this.isEnable = true;
        // console.log(err)
      });

  }


  
}
