import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
 
 
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
 import { EMXUserDto } from 'app/models/EMX';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import{EmxManagementService}from '../../services/emx-management.service';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';

@Component({
  selector: 'ms-emx-user-list',
  templateUrl: './emx-user-list.component.html',
  styleUrls: ['./emx-user-list.component.scss']
})
export class EmxUserListComponent extends AppComponentBase
 implements OnInit , OnDestroy{
  
  public items:  EMXUserDto  [] ;
   
  public allItems:  EMXUserDto  [] ;
  company:any;
  companyId:any;
  selectBy:any;
  title:any;
  public actions:any;
  public action :any;
  companies: any;
  searchTxt:any ;
  
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy(){ 

	} 

  constructor( private pageTitleService : PageTitleService ,
 
    private emxManagementService :EmxManagementService,
    private dialog: MatDialog  ,
    private setupApi: SetupApi,
    private route: ActivatedRoute  ,
      private _enumHelpers :EnumHelpers   , injector: Injector
      ) {
        super(injector); 
      this.actions = _enumHelpers.getNames(StatusEnum);
  
    }
 

  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => { 
        this.getEmxUsers( ); 
    })
    this.title=this.translate('EMX Users') ;
    setTimeout(() =>{ 
      this.pageTitleService.setTitle( 'EMX Management');
     },0);
      
  }
  onStateChange(){ 
    
    if(this.searchTxt!= null && this.searchTxt!=""
    && this.searchTxt.length>=3 ){
      
 
     


    }else{
      this.getEmxUsers( );
    }
  }
  getEmxUsers( ){
    this.searchTxt="";
 
    this.emxManagementService. getEmxUsers().subscribe(items=>{
      this.items=items;
   });
 }
 
 changePropertiesStatus(data){
 
  let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
  propertiesStatus.ActionOn=EntityTypeEnum.EMX;
  propertiesStatus.ResponsibleId=data.Id;
  propertiesStatus.ResponsibleNameAr=data.Name;
  propertiesStatus.ResponsibleNameEn=data.Name;
  propertiesStatus.Status= data.Status;
  propertiesStatus.UserId=data.UserId;
  propertiesStatus.NotifiledEmail=data.Email;
  const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
    data:{propertiesStatus:propertiesStatus   }  
  });
  dialog.afterClosed().subscribe((res) => { 
if(res!=null){
data.Status=res;
}

  } );
}
  
 
  deleteEMXUser(emxUser) {

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:this.translate('would you like to delete this EMX User Name ')   + emxUser.Name + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.emxManagementService.deleteEmxUser(emxUser.Id).subscribe((res) => {
          
    
                 this.toastr.success(this.translate('Successfully'));
                 this.getEmxUsers( );
        }, err => {
          this.toastr.error(this.translate('Error') );
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }

}

