import { Routes } from '@angular/router';
import { AuctionDashboardComponent } from './containers/auction-dashboard/auction-dashboard.component';

 
export const AuctionManagementRoutes:   Routes = [{
  path: '',
  redirectTo: '', 
  pathMatch: 'full',
  component: AuctionDashboardComponent
} ];











 
