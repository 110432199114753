import { Injectable } from '@angular/core';
import { BrokerDto, CompanyDto } from '../../../app/models/broker';
import { Observable } from 'rxjs';
 
import {CommissionManagementApi} from '../api/commission-management.api'
import { CommissionDto } from 'app/models/commission';

@Injectable()
export class CommissionManagementService {

  
  constructor(private commissionManagementApi: CommissionManagementApi ) { }

 
 
  getCommissions(): Observable<[]> { 
    return this.commissionManagementApi.getCommissions();
  } 
  GetSymbolMarketCommissionList(   symbolId :any): Observable<[]> { 
    return this.commissionManagementApi.GetSymbolMarketCommissionList( symbolId);
  } 


  getCommission(id:any): Observable<CommissionDto> { 
    return this.commissionManagementApi.getCommission(id);
  } 
  insertCommission(createCommissionRequest: any): Observable<boolean> {
    return this.commissionManagementApi.insertCommission(createCommissionRequest);
  }
  
  updateCommission(CommissionRequest: any,id:any): Observable<boolean> {
    return this.commissionManagementApi.updateCommission(CommissionRequest,id);
  }

  deleteCommission(id: any): Observable<any> {
    return this.commissionManagementApi.deleteCommission(id);
  } 

 
  GetMarketCommission( id :any ): Observable< any> {
    return this.commissionManagementApi.GetMarketCommission(id );
  }
  GetMarketCommissionByCommissionId( id :any ,commissionId:any): Observable< any> {
    return this.commissionManagementApi.GetMarketCommissionByCommissionId(id ,commissionId);
  }
  UpdateMarketCommission(CommissionRequest: any,id:any)  {
    return this.commissionManagementApi.UpdateMarketCommission(id,CommissionRequest);
  }
 
 
}