export class CommissionDto {
    CommissionID: number;
    CommissionNameAR: string;
    CommissionNameEN: string;
    IsActive:any;
}

export class SymbolMarketCommissionDto {
    Id: number;
    SymbolMarketId: number;
    CommissionID: number;
    CommissionPerc: number;
    Minimum: number;
    Maximum: number;
    Commission: CommissionDto;
    SymbolMarket: string;
    UpdatedUserId: string;

}

export class  MarketCommissionDto {
    Id: number;
    MarketId: number;
    CommissionID: number;
    CommissionPerc: number;
    Minimum: number;
    Maximum: number; 
    UpdatedUserId: string; 
    CommissionNameAR: string;
    CommissionNameEN: string;
    IsDeleted:any;
}
 

export class DailyCommissionDto {
    ID : number;
    CurrencyId: number;
    SymbolCode : string;
    MarketID : number;
    BuyerID: number;
    SellerID : number;
    TradeID: number;
    SymbolMarketCommissionsID : number;
    Qty : number;
    Price : number;
    CommissionValue: number;
    TradeDate: Date;
    CreateDate: Date;
}
