import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
 
 
import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar' 
  
import {BrokerManagementRoutes} from './broker-management.routing';
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element';
import { SharedModule } from 'app/shared/shared.module';
import { TranslocoRootModule } from 'app/transloco/transloco-root.module';
import { GridModule } from '@progress/kendo-angular-grid';  
import { BrokerManagementApi } from'./api/broker-management.api';
import { BrokerManagementService }from './services/broker-management.service';
import { PeriodService } from 'app/shared/services/period.service';
import {BrokerListComponent} from './containers/broker-list/broker-list.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrokerFormDialogComponent } from './components/broker-form-dialog/broker-form-dialog.component';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { Helpers } from 'app/helper/helpers';
import { TranslateModule } from '@ngx-translate/core';
import{BrokerFormComponent} from './components/broker-form/broker-form.component';
import{BrokerCompanyListComponent}from './components/broker-company-list/broker-company-list.component';
@NgModule({
  imports: [FormsModule,   
    ReactiveFormsModule, 
    CommonModule,
    TranslateModule, 
    FlexLayoutModule,
    RouterModule.forChild(BrokerManagementRoutes),
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    SharedModule ,
    MatSelectModule,
        CommonModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      GridModule, DragDropModule,ResizableModule
  ],
  declarations: [ 
    BrokerListComponent,  BrokerFormDialogComponent ,BrokerFormComponent ,BrokerCompanyListComponent
  ], 
  providers: [BrokerManagementApi ,BrokerManagementService,CompanyManagementService, PeriodService ,Helpers]
})
export class BrokerManagementModule { }