import { StoreKeeperFormDialogComponent } from './../../components/store-keeper-form-dialog/store-keeper-form-dialog.component';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StoreKeeperDto, TransactionLookupDto, TransactionReuqest } from './../../../models/warehouse';
import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import { TransactionDto,  StoreSectionDto } from 'app/models/warehouse';
import { WarehouseService } from 'app/warehouse-managment/services/warehouse.service';
import { WarehouseFormDialogComponent } from 'app/warehouse-managment/components/warehouse-form-dialog/warehouse-form-dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { FormControl, FormGroup } from '@angular/forms';
import { GridData } from '@swimlane/ngx-charts';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators' 
import { AppComponentBase } from 'app/app-component-base';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
@Component({
  selector: 'store-keeper-list',
  templateUrl: './store-keeper-list.component.html',
  styleUrls: ['./store-keeper-list.component.scss']
})
export class StoreKeeperListComponent extends AppComponentBase implements OnInit {
  filterOptions:any = {};
  public items: StoreKeeperDto[];
  public lookups : TransactionLookupDto = new TransactionLookupDto(); 
  company: any;
  title: any;
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;
  keeperInfo: StoreKeeperDto=new StoreKeeperDto();
  ngOnDestroy() {

  }
  _storeId:number;
  @Input() get StoreId(){
    return this._storeId;
  } 
  set StoreId(value){
 
    if(value==null)return;
    this._storeId = value
    this.getList(StatusEnum.Active);
  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private warehouseService: WarehouseApi,
    private _setupApi:SetupApi,
    private _helper :EnumHelpers,
    injector:Injector
    ) {
    super(injector);
      this.actions = _helper.getNames(StatusEnum);
     }


changeType(id){
 
  this.filterOptions.TransactionTypeID=id;
}

  ngOnInit(): void {
    this.filterOptions.Take = 10;
    this.filterOptions.Skip = 0;

    this.route.paramMap.subscribe(prams => {
      this.title = 'Warehouses Management';
    })
    //this.filterOptions.TransactionTypeID=1;
    this.warehouseService.getTransactionLookups()
    .subscribe(res=>{this.lookups = res
    });

    this._setupApi.getStoreKeeperInfo()
    .subscribe(res=> this.keeperInfo = res)
  

    setTimeout(() => {
      this.pageTitleService.setTitle(this.title);
    }, 0);


   

  }


  getList(action) {
 this._setupApi.getWarehouseById(this.StoreId).subscribe(
   (res)=>{
     this.items= res.StoreKeepers.filter(item=>item.IsDeleted!=true);
   });
  }


  add() {
 
      const dialog = this.dialog.open(StoreKeeperFormDialogComponent, {
          data: { StoreID: this.StoreId }
    });
    dialog.afterClosed().subscribe((res) => {
      this.getList(null);
    });
  }

   edit(dto) {
   
     const dialog = this.dialog.open(StoreKeeperFormDialogComponent, {
       data: { dto: dto }});
     dialog.afterClosed().subscribe((res) => {
       this.getList(this.action);
     },        err => {

      this.toastr.error(this.translate(err.error[0]));
    });
   }
    delete(dto) {
 
    this.warehouseService.deleteStoreKeeper(dto.KeeperID).subscribe(res=>{
    this.getList(null);
  },        err => {

    this.toastr.error(this.translate(err.error[0]));
  })
}
public onStateChange(state) {
 
  this.filterOptions.Skip = state.skip;
  this.filterOptions.Take = state.take
this.getList(null);
}
changePropertiesStatus(data){
  
  let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
  propertiesStatus.ActionOn=EntityTypeEnum. WarehouseUsers;
  propertiesStatus.ResponsibleId=data.KeeperID;
  propertiesStatus.ResponsibleNameAr=data.KeeperNameAr;
  propertiesStatus.ResponsibleNameEn=data.KeeperNameEn;
  propertiesStatus.NotifiledEmail=data.Email;
  propertiesStatus.UserId=data.UserId;
  if( data.IsActive)
       propertiesStatus.Status= this._StatusEnum.Active;
    else
    propertiesStatus.Status=this._StatusEnum.InActive; 
 
  const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
    data:{propertiesStatus:propertiesStatus   }  
  });
  dialog.afterClosed().subscribe((res) => { 
if(res!=null){
  if(res==this._StatusEnum.Active)
          data.IsActive=true;
   else
   data.IsActive=false; 
}

  } );
}

}
