import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service'
import { TradeDto } from 'app/models/trade';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, startWith, switchMap, catchError, map, filter, take} from 'rxjs/operators';
@Component({
  selector: 'ms-market-trades',
  templateUrl: './market-trades.component.html',
  styleUrls: ['./market-trades.component.scss']
})
export class MarketTradesComponent implements OnInit {

  
  @Input()
  symbolCode: string;  
  _marketId: number;
  @Input() set marketId(value: number) {

    this._marketId  = value;
    this.getTrades( );

  
  }

  selectBy:any;
  public view: Observable<GridDataResult>;
  public items:any= TradeDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
   };
   constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService,private marketWatchApi: MarketWatchApi,
  ) {
       
  
    signalrService.monitorServiceChanges.subscribe(item => {
      this.getTrades();
      this.filterMarker(); 
    });
    
    signalrService.tradeChanges.subscribe(item => {
   
      console.log("Trades Changes");
      let trades = JSON.parse(item);
 
      trades.forEach((element, index) => {

        if (this.items != null) {
         
          if (!(this.items.filter(x => x.TradeID === trades[index].TradeID).length > 0)) {
         
           //  this.items.push(trades[index]);
            this.items = [trades[index], ... this.items ];
  
          }  
        }


      });

      this.filterMarker(); 
    });
  }


  filterMarker(){
    if(Number(  this._marketId )>0){
      this.items = this.items.filter(x => x.MarketID ==  this._marketId);
    }
  }
  getTrades()
  {   
   
    if(Number(  this._marketId )>0){
  
      this.marketWatchApi.getTrades(this.gridState.skip ,this.gridState.take ,
        Number(  this._marketId )) .subscribe(items => { this.items = items;  });
    }
  } 
  
  public onStateChange(state: State) {

    this.gridState = state;
    
      this.  getTrades();
  
} 
public rowCallback(context: RowClassArgs) {
   
  var isFlash = 0;
   
  if(context.dataItem.isUpdate==true ){
    var isFlash = 1;

    context.dataItem.isUpdate=false;
  }
  return {
      flash_down: isFlash,
      normal: !isFlash
  };
}
 
  
  ngOnInit(): void {
    this.  getTrades();
    
  }
  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
}
