import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FilterService, GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';

import { SignalRService } from '../../../service/signalr/signalr.service';
import { TradeDto } from 'app/models/trade';
import { State, process, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, startWith, switchMap, catchError, map, filter, take} from 'rxjs/operators';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { AppComponentBase } from 'app/app-component-base';
@Component({
  selector: 'ms-auction-my-trades',
  templateUrl: './auction-my-trades.component.html',
  styleUrls: ['./auction-my-trades.component.scss']
})
export class AuctionMyTradesComponent extends AppComponentBase  implements OnInit {

    
 
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  selectBy:any;
  public view: Observable<GridDataResult>;
  public items:any= TradeDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
   };
   constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService, 
    private readonly  Auctionservice :Auctionservice,
    injector: Injector, 
  ) {
    super(injector);
    signalrService.auctionTradeChanges.subscribe(item => {
      this.  getTrades();
    });
  }

  getTrades()
  {   
    if(this.isBroker()){
      this.getUserBrokerInfo().subscribe(res=>{

           this.Auctionservice.getCompanyTrades(this.gridState.skip ,this.gridState.take,res.CompanyId )
           .subscribe(items => { this.items = items;   });
        }) ;
    } 
 
  
  } 
 
 

  public onStateChange(state: State) {
 
    this.gridState = state;
    this.getTrades();
} 
public rowCallback(context: RowClassArgs) {
   
  var isFlash = 0;
   
  if(context.dataItem.isUpdate==true ){
    var isFlash = 1;

    context.dataItem.isUpdate=false;
  }
  return {
      flash_down: isFlash,
      normal: !isFlash
  };
}
 
  
  ngOnInit(): void {
    this.  getTrades();
    
  }
  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
}
