export class AuctionDto {
    Id: number;
    MarketId: number;
    SymbolCode: string;
    MainClient: number;
    StoreId: number;
    OpenDate: Date;
    OrderType:number;
    AuctionType:number;
    CloseDate: Date;
    MainActivity: number;
    Qty: number;
    Price: any;
    Status: number;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    CompanyID: number;
    ClientCode : string; 
    CompanyCode : string;
    SymbolID: number; 
    QtyUnit: number;
    OpenTime: any;
    CloseTime: any;
    DurationTime: any;
    Note: any;
    MatchingType:number;
    CapQty: any;
    IsAutoExecute: boolean;
}


 
 

export class AuctionViewDto  {
    
    Id: number;
    MarketId: number;
    SymbolCode: string;
    StoreNameEn: string;
    StoreNameAr: string;
    ClientNameEn: string;
    ClientNameAr: string;
    SymbolNameEn: string;
    SymbolNameAr: string;
    MarketNameEn: string;
    MarketNameAr: string;
    MarketCode: string;
    MarketStatus: number;
    MainClient: number;
    OpenDate: Date;
    CloseDate: Date;
    Qty: number;
    Price: number;
    Status: number;
    StoreId: number;
    CompanyId: number;
    Type: number;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date; 
    QtyUnit: number;
    IsAllowClient : any;
    IsAllowBroker : any;
    IsAllowCompany : any;
    IsPublic: any;
    IsOmnibus: any;
    AuctionTypeNameAr  : string;
    AuctionTypeNameEn  : string;
    MatchingType:number;
    CapQty: any;
    IsAutoExecute: boolean;
    IsAllowEditOrderQty : any;
    IsAllowEditOrderPrice : any;
    IsAllowShowAuctionPrice :any;
    IsAllowShowAuctionQty : any;
    IsAllowShowAuctionOrder : any;
    IsAllowMultiAuctionClientOrder: any;
    IsAllowPriceWithinLimit: any;
    IsAllowCapping: any; 
}
export class AuctionPaymentFilterDto {

      Skip : any;
      Take : any;
      AuctionId : any;
      CompanyId:any
      OrderType: any;
    }

    export class   AuctionPaymentDto  {
    Id: any;
    AuctionID: any;
    ClientID: any;
    Payment: any;
    CreateUserID: any;
    LastUpdatedUserId: any;
    CreateDate: any;
    LastUpdatedDate: any;
    Date: any;
    ClientNameAr: any;
    ClientNameEn: any;
    ClientCode: any;
    AllowedQty: any;
    FinalQty: any;
    AuctionRatio: any;
    OrderId: any; 
    hasAttchment: any;
  
}

export class AuctionFilterDto {
    Skip : any;

    Take : any;

    Id : any;

    Type : any;

     FromDate : any;

     ToDate : any;

   SymbolCode : any;

    MarketId : any;

    ClientId : any;

    CompanyId : any;
    userId : any;

    IsOpen : any;

    LastUpdatedTime : any;
  }

export class AuctionModel {
    Pending: number;
    Open: number;
    Close: number;
    Withdrawn: number;
    Adjustment: number;
    Executed: number;
    PendingExecuted: number;
}