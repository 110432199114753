<div class="popup-card-dialog"><div class="gene-card-title">
    <!-- #region grid title -->
    <div fxLayout="row" fxLayoutAlign="start center">
          <h2 mat-dialog-title class="mrgn-b-none paragraph">{{SymbolName}}</h2>
    </div>
	<mat-divider></mat-divider>

    <!-- #endregion -->

		<!-- #region news grid -->
		<div >
			<kendo-grid 
			      [data]="news" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > 
				   
				  <kendo-grid-column   title="	{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  
				  <kendo-grid-column width="100" field="CreatedDate" title="{{'Time' | translate }}" headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate |  date: 'h:mm:ss a'}}
					</ng-template>
				  </kendo-grid-column>

                  <kendo-grid-column field="Header" title="{{'Header' | translate }}"  width="350" headerClass="grid-list-column-header"  > 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						
						<span  *ngIf="isRtl()" > {{dataItem.HeadrAr}}  </span>
						<span  *ngIf="!isRtl()" > {{dataItem.HeaderEn}}  </span>

					</ng-template>
				  </kendo-grid-column>
                  <kendo-grid-column field="Details" title="{{'Content' | translate }}"  width="350" headerClass="grid-list-column-header"  > 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						
						<span  *ngIf="isRtl()" > {{dataItem.DetailsAr}}  </span>
						<span  *ngIf="!isRtl()" > {{dataItem.DetailsEn}}  </span>

					</ng-template>
				  </kendo-grid-column>				  
		 </kendo-grid>		  
		</div>
		<!-- #endregion -->


</div>