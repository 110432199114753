import{SymbolDto} from'../models/symbol'
import{MarketDto} from'../models/market'
import{ClientDto} from'../models/client'
import{StoreDto} from'../models/store'
import{AuctionDto} from'../models/auction'

export class OrderTicketLookupDto {
    Symbols: SymbolDto[];
    Markets: MarketDto[];
    Auctions: AuctionDto[];
    Clients: ClientDto[];
    Stores: StoreDto[];
}