import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service'
import { TradeDto } from 'app/models/trade';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, startWith, switchMap, catchError, map, filter, take} from 'rxjs/operators';
import { AppComponentBase } from 'app/app-component-base';
@Component({
  selector: 'ms-market-my-trades',
  templateUrl: './market-my-trades.component.html',
  styleUrls: ['./market-my-trades.component.scss']
})
export class MarketMyTradesComponent extends AppComponentBase implements OnInit {

  
  @Input()
  symbolCode: string;  
  _marketId: number;
  @Input() set marketId(value: number) {

    this._marketId  = value;
    this.getTrades( );

  
  }

  selectBy:any;
  public view: Observable<GridDataResult>;
  public items:any= TradeDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
   };
   constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService,private marketWatchApi: MarketWatchApi,
    injector: Injector, 
  ) {
    super(injector);
       
  
    signalrService.monitorServiceChanges.subscribe(item => {
      this.getTrades();
      this.filterMarker(); 
    });
    
    signalrService.tradeChanges.subscribe(item => {
    
      let trades = JSON.parse(item); 
      this.getTrades();

      this.filterMarker(); 
    });
  }


  filterMarker(){
    if(Number(  this._marketId )>0){
      this.items = this.items.filter(x => x.MarketID ==  this._marketId);
    }
  }
  getTrades()
  {   
    if(this.isBroker()){
      this.getUserBrokerInfo().subscribe(res=>{

        if(Number(  this._marketId )>0){
  
          this.marketWatchApi.getCompanyTrades(this.gridState.skip ,this.gridState.take ,
            Number(  this._marketId ),res.CompanyId) .subscribe(items => { this.items = items;  });
        }

 
        }) ;
    } 

  } 
  
  public onStateChange(state: State) {

    this.gridState = state;
    
      this.  getTrades();
  
} 
public rowCallback(context: RowClassArgs) {
   
  var isFlash = 0;
   
  if(context.dataItem.isUpdate==true ){
    var isFlash = 1;

    context.dataItem.isUpdate=false;
  }
  return {
      flash_down: isFlash,
      normal: !isFlash
  };
}
 
  
  ngOnInit(): void {
    this.  getTrades();
    
  }
  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
}
