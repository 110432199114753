 
    <mat-card class="ticker-slider " dir="ltr"  >
        <ms-ticker-slider></ms-ticker-slider>
    </mat-card>

<div fxLayout="row wrap" fxlayoutAlign="start start" class="dashboard-content">

 
  <kendo-splitter orientation="vertical" style="height: 40%;"  >
      <kendo-splitter-pane>
          <kendo-splitter>
              <kendo-splitter-pane [collapsible]="true" size="60%">
                  <div class="pane-content" >
                      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                          mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                          [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)"
                         > 
                          <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">
                            <mat-card class="card-grid mat-card-trade height300" >
                              <ms-gold-watch-list></ms-gold-watch-list> 
                           </mat-card>
                          </div>
                      </div>
                  </div>
              </kendo-splitter-pane>

              <kendo-splitter-pane>
                  <div class="pane-content">
                      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" [ngStyle]="style"
                          mwlResizable [validateResize]="validate" [enableGhostResize]="true"
                          [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)" >
                          <div fxLayout="row wrap">

                              <div fxFlex.gt-xs="100" fxFlex.xs="100" fxFlex="100">

                               <div class="height300">
                                <ms-gold-symbol-details >  </ms-gold-symbol-details>
                              </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </kendo-splitter-pane>



          </kendo-splitter>
      </kendo-splitter-pane>
  </kendo-splitter>

  <kendo-splitter orientation="vertical" style="height: 60%;"  > 
      <kendo-splitter-pane    >
          <kendo-splitter>
              <kendo-splitter-pane [collapsible]="true" size="60%">
                  <div class="pane-content">

                      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"  [ngStyle]="style" mwlResizable [validateResize]="validate" [enableGhostResize]="true" [resizeSnapGrid]="{ left: 60, right: 60 }" (resizeEnd)="onResizeEnd($event)" >
                        <mat-card class="card-grid mat-card-trade height470" >
        
                          <ms-gold-order-list></ms-gold-order-list>
                        </mat-card>
                      </div>
                  </div>
              </kendo-splitter-pane>

              <kendo-splitter-pane>
                  <div class="pane-content"> 
                    <mat-card class="card-grid mat-card-trade height470" >
                        <ms-gold-trades></ms-gold-trades> 
                     </mat-card>
                  </div>
              </kendo-splitter-pane>

 

          </kendo-splitter>
      </kendo-splitter-pane>
  </kendo-splitter>
</div>

 