
import { Injectable } from '@angular/core'; 
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../account/services/auth.service'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { permissions } from '../permission';
 

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

 

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
  
    if ( this.authService.isLoggedIn$()) {
        // logged in so return true
         //  this.router.navigate([this.authService.INITIAL_PATH]);
      return true;
    }
    
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
    return false;
}
}
 