import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
 
import {BrokerManagementService} from '../../services/broker-management.service';
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BrokerFormDialogComponent } from '../../components/broker-form-dialog/broker-form-dialog.component';
import { BrokerDto, BrokerRequestDto } from 'app/models/broker';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { number } from 'echarts';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { permissions } from 'app/core/permission';
import { AppConsts } from 'app/core/config';
import { AdminService } from 'app/admin/services/admin.service';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
  selector: 'ms-broker-manage-list',
  templateUrl: './broker-list.component.html',
  styleUrls: ['./broker-list.component.scss']
})
export class BrokerListComponent extends AppComponentBase
 implements OnInit , OnDestroy{
  
  brokerRequest:BrokerRequestDto=new BrokerRequestDto;
  company:any;
  companyId:any;
  brokerId: any;
  selectBy:any;
  StatusEnum =StatusEnum;
  public actions:any;
  public action :any;
  companies: any;
  searchTxt:any ;
  title:any;
  roles:any ;
  Role:any ;
  filteredCompanies:any;

  dialogRef: MatDialogRef<DemoDialog>;
  public view: Observable<GridDataResult>;
  public state: State = {
    skip: 0,
    take: 25,
  };


  ngOnDestroy(){ 

	} 

  constructor( private pageTitleService : PageTitleService ,
    private brokerManagementService :BrokerManagementService ,
    private companyManagementService :CompanyManagementService,
 
    private adminService:AdminService,
    private dialog: MatDialog  ,
    private setupApi: SetupApi,
    private route: ActivatedRoute  ,
      private _enumHelpers :EnumHelpers   , injector: Injector
      ) {
        super(injector); 
      this.actions = _enumHelpers.getNames(StatusEnum);
    
    }
 

  ngOnInit(): void {
  //  this. getRoles();
    this.route.paramMap.subscribe(prams => {
     this. getCompanies();
     this.getBrokers( );
   
 
    })
    this.title=this.translate('Users') ;
    this.pageTitleService.setTitle( 'Trading Companies'  );
      
  }
  onStateChange(){ 
  
    if(this.brokerRequest.GeneralFilter!= null &&this.brokerRequest.GeneralFilter.length>2 ){
      this.brokerRequest.GeneralFilter
      this.getBrokers( );
      
    } 
  }

  clear(){
    this.brokerRequest.GeneralFilter=null;
    this.getBrokers( );
  }
  
  getRoles(){
    this.adminService.getRolesByType(permissions.ISBroker).subscribe(
      res=>{  
         this.roles=res; 
         this.roles = this.roles.filter(y => y.name != AppConsts.superAdminRole);
      }
     );
   
  }
 
changePropertiesStatus(data){
 
  let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
  propertiesStatus.ActionOn=EntityTypeEnum.Broker;
  propertiesStatus.ResponsibleId=data.UserId;
  propertiesStatus.UserId=data.UserId;
  
  propertiesStatus.IgnoreUnsuspend=true;
  propertiesStatus.ResponsibleNameAr=data.Name;
  propertiesStatus.ResponsibleNameEn=data.Name;
  propertiesStatus.NotifiledEmail=data.BrokerEmail;
  if( data.IsActive)
       propertiesStatus.Status= this._StatusEnum.Active;
    else
    propertiesStatus.Status=this._StatusEnum.InActive; 
 
  const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
    data:{propertiesStatus:propertiesStatus   }  
  });
  dialog.afterClosed().subscribe((res) => { 
if(res!=null){
  if(res==this._StatusEnum.Active)
  { 
    data.IsActive=true;
  }
   else{

    data.IsActive=false;
   } 
}

  } );
}
  getBrokers( ){ 
    this.brokerRequest.Take = this.state.take;
    this.brokerRequest.Skip = this.state. skip;
      if (!this.isBroker()) { 
      
        this.brokerRequest.CompanyId=this.companyId;
        this.view =  this.brokerManagementService.searchBrokers(this.brokerRequest).pipe(
          map(data => { 
              let result = <GridDataResult>{ data:  data.brokers, total: data.count}
              return result;
         })); 
 
      }else{
        this.getUserBrokerInfo().subscribe(res=>{
 
            this.brokerRequest.BrokerId= res.BrokerId ;
            this.brokerRequest.CompanyId= res.CompanyId ;
            this.view =  this.brokerManagementService.searchBrokers(this.brokerRequest).pipe(
              map(data => { 
                  let result = <GridDataResult>{ data:  data.brokers, total: data.count}
                  return result;
             })); 
         })
      }

 }
 
 public dataStateChange(state: DataStateChangeEvent): void {
  
  this.state = state;
  this. getBrokers( );
}

 addNewBrokerCompanyDialog(){
  const dialog = this.dialog.open(BrokerFormDialogComponent, {
    data:{ }  
  });
  dialog.afterClosed().subscribe((res) => {
    this.getBrokers();

  } );
 }

  
 getCompanies(){
  this.companyManagementService.getActiveCompanies().subscribe(res=>{
  this.companies=res;

  });
 }


  addNewBrokerDialog(){
      var broker =new BrokerDto(); 
      const dialog = this.dialog.open(BrokerFormDialogComponent, {
        data:{broker:broker } 
      });
      dialog.afterClosed().subscribe((res) => {
        this.getBrokers();
      } );
  }
   

   editBroker(broker){ 
    
    const dialog = this.dialog.open(BrokerFormDialogComponent, {
      
        data:{broker:broker}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getBrokers();
      } );
  }
 
  
  deleteBroker(broker) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:this.translate('would you like to delete this broker code')   + broker.Code + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.brokerManagementService.deleteBroker(broker.Id).subscribe((res) => {
          
    
                 this.toastr.success(this.translate('Successfully'));
                 this.getBrokers( );
        }, err => {
          this.toastr.error(this.translate('Error') );
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
  

  onChangeCompany(event) {

    if(this.companies != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCompanies = this.companies;
        else
        this.filteredCompanies = this.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }


}

