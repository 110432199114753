import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SymbolUnitDto } from 'app/models/StatusLookup';
import { SymbolDto } from 'app/models/symbol';
import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MarketDto, ValidationMarketCommissionDto } from 'app/models/market';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { CommissionDto, MarketCommissionDto, SymbolMarketCommissionDto } from 'app/models/commission';
import { CommissionManagementService } from 'app/commission-management/services/commission-management.service';
import { CategoryDto, SectorDto } from 'app/models/category';
import { AppComponentBase } from 'app/app-component-base';
import { permissions } from 'app/core/permission';
import { MarketService } from 'app/market-managment/services/market.service';

@Component({
  selector: 'ms-market-commission-dialog',
  templateUrl: './market-commission-dialog.component.html',
  styleUrls: ['./market-commission-dialog.component.scss']
})
export class MarketCommissionDialogComponent extends AppComponentBase
  implements OnInit {

  error = '';
  isValid: boolean = true;
  title: string;
  commissions: any;
  marketCommissionsList: any;
  marketCommission: MarketCommissionDto = new MarketCommissionDto();
  validateMarketComission: ValidationMarketCommissionDto = new ValidationMarketCommissionDto();
  addNewCommisionsForm: FormGroup;
  isUpdate: boolean;
  errors: any;
  disableMarket: boolean = false;
  marketId: any;
  commissionId: any;
  isdisable: boolean;
  filteredCommissions: any;

  constructor(
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarketCommissionDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private symbolService: SymbolService,
    private toastar: ToastrService,
    private marketService: MarketService,
    private commissionsService: CommissionManagementService,
    private setupApi: SetupApi, injector: Injector
  ) {
    super(injector)
    if(data.marketCommisionsList !=null){
      
      this.marketCommissionsList=data.marketCommisionsList ;

    }
    if (data.marketCommission != null) {
      this.marketCommission = data.marketCommission;
      this.isUpdate = true;
      this.disableMarket = true;
      this.title = this.translate("Update Commission");
      if(this.isUpdate){
        this.validateMarketComission.marketCommissions = this.marketCommissionsList
        .filter(x=>x.CommissionID != this.marketCommission. CommissionID); 
      }else{
        this.validateMarketComission.marketCommissions = this.marketCommissionsList; 
      }
    } else {
      this.isUpdate = false;
      this.disableMarket = false;
      this.marketCommission.CommissionID = data.CommissionId;
      this.commissionId = data.CommissionId;
      this.title = this.translate("Add New Commission");
    }

    if (data.marketId != null) {
      this.marketId = data.marketId;
    }

  }

  ngOnInit(): void {
    this.getCommissions();
    this.addNewCommisionsForm = new FormGroup({
      CommissionPerc: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])),
      Minimum: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      Maximum: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.min(0)])),
      CommissionID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      commissionName: new FormControl(['commissionName']),
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  onFormSubmit() {
    let valid ;
      this.validateMarketComission.commId = this.marketCommission.CommissionID;
      this.validateMarketComission.marketId = Number( this.marketId);      

      if(this.validationComission())
      {
        if(this.validateMarketComission != null){

        this.marketService.ValidateMarketCommissions(this.validateMarketComission).subscribe(validate => {
          valid = validate;        
          if (valid) {
            if (this.marketCommission.CommissionID != null) {
            var commission = this.commissions.filter(x => x.CommissionID == this.marketCommission.CommissionID)[0];
        if (commission != null) {
          this.marketCommission.CommissionNameAR = commission.CommissionNameAR;
          this.marketCommission.CommissionNameEN = commission.CommissionNameEN;
        }
            }
        this.marketCommission.UpdatedUserId = this.currentUserId();
        this.dialogRef.close(this.marketCommission);
          } else {
            this.toastar.error('error')
          }
        });}
      }
    
  }


  getCommissions() {

    this.commissionsService.getCommissions().subscribe(res => {
      this.commissions = res;
      // this.commissionsService.GetMarketCommission(this.marketId).subscribe(items => {
        this.marketCommissionsList.forEach(element => {
 
            var item =   this.commissions.filter(x=>x.CommissionID==element.CommissionID
              &&       this.marketCommission.CommissionID!=element.CommissionID);
            if(item.length>0){
               this.commissions=this.commissions.filter(x=>x.CommissionID!=item[0].CommissionID);
            }
         });

      // });
    });
  }

  onChangeCommission(event) {
    //this.getMarketCommission(id.value);
    if(this.commissions != null)
    { 
      if(typeof event === 'string')
      {
      if( event.match(/^ *$/) !== null)
      this.filteredCommissions = this.commissions;
      else
      this.filteredCommissions = this.commissions.filter((s) => s.CommissionNameEN.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
        || this.commissions.filter((s) => s.CommissionNameAR.indexOf(event.toLowerCase()) !== -1 );
      }
    }
  }

  getMarketCommission(id: any) {
    if (this.marketId != null) {
      this.commissionsService.GetMarketCommissionByCommissionId(this.marketCommission.MarketId,
        id).subscribe(res => {
          this.marketCommission = res;



        })
    }

  }

  validationComission(event?) {
    this.isValid = true;
    this.error = '';
    if(event != null)
      this.marketCommission.Maximum = event;
      
    if ((this.marketCommission.Minimum != null && this.marketCommission.Maximum != null)
      && (this.marketCommission.Maximum) < (this.marketCommission.Minimum)) {
      this.error ='Maximum should be greater than Minimum.';
      this.isValid = false;
      
    }

    return this.isValid;
  }
}
