 
<div  class=" ">
  <kendo-grid  #grid  [kendoGridBinding]="items" 
  
  [ngClass]=
  "{'market-kendo-grid': auth.isGranted(_permissions.GetOrders), 'market-kendo-grid-full' : !auth.isGranted(_permissions.GetOrders)  }"
  [pageable]="false"
  [pageSize]="20"
  [resizable]="true"
  [reorderable]="true"

  filterable="menu"
  [sortable]="true"
  [columnMenu]="true"
 
    (dataStateChange)="onDataStateChange()"
  (cellClick)="onCellClick($event)"     [kendoGridSelectBy]="selectBy"
  >  
 
  <kendo-grid-column   title=""  width="30"  [columnMenu]="false">
    
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
   
      <button mat-icon-button  
      title="add favourite"
      (click)="addFavourite(dataItem)" *ngIf="dataItem.isfavourite==false " >
        <mat-icon class="favouritesIcon">star_border</mat-icon>
      </button> 
      <button mat-icon-button  
      title="remove favourite"
      (click)="removeFavourite(dataItem)" *ngIf="dataItem.isfavourite==true " >
        <mat-icon>star</mat-icon>
      </button> 
        </ng-template>
   
   </kendo-grid-column>

 
  
   <kendo-grid-column field="SymbolCode"  filterable="menu"
   [sortable]="true"
   [columnMenu]="true" title="{{ 'Symbol' | translate }}" width="150" >
    <ng-template kendoGridCellTemplate let-dataItem  >
     <span class="market-watch-symbol-c">{{dataItem.SymbolCode}} </span> 

     
     <br/>
     <span class="market-watch-symbol-n" *ngIf="isRtl()"> {{dataItem. SymbolNameAr}}   </span>
     <span  class="market-watch-symbol-n" *ngIf="!isRtl()"> {{dataItem.SymbolNameEn}}   </span>
     
      <!-- <a href="" class=""> <span  matPrefix class="fa fa-btc" aria-hidden="true"></span> {{dataItem.SymbolCode}}  </a> -->

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field=" "  filterable="menu"
  [sortable]="true"
  [columnMenu]="false" title="{{ 'Market' | translate }}" width="165" >
   <ng-template kendoGridCellTemplate let-dataItem  > 
     <span  class="market-session-icon" 
     [ngClass]=
   "{'session-open-btn': dataItem.MarketStatus===1, 'session-preopen-btn' : dataItem.MarketStatus===2 , 'session-close-btn' : dataItem.MarketStatus===3}"
     *ngIf="isRtl()">{{ dataItem. MarketNameAr}}</span>  

     <span  class="market-session-icon" [ngClass]=
     "{'session-open-btn': dataItem.MarketStatus===1, 'session-preopen-btn' : dataItem.MarketStatus===2 , 'session-close-btn' : dataItem.MarketStatus===3}"
     *ngIf="!isRtl()">{{ dataItem. MarketNameEn}}</span>  
   </ng-template>
 </kendo-grid-column>
  <kendo-grid-column field="UnitNameEn"  title="{{ 'Unit' | translate }}" width="80" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span  *ngIf="isRtl()"> {{dataItem. UnitNameAr}}   </span>
      <span  *ngIf="!isRtl()"> {{dataItem.UnitNameEn}}   </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Last_Tradeprice" title="{{ 'Last' | translate }}" width="85">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.Last_Tradeprice | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="open"  title="{{ 'Open' | translate }} " width="110">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.open | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="close"  title="{{ 'Close' | translate }}" width="110">
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.close | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="high" title="{{ 'High' | translate }}" width="80" >
    <ng-template kendoGridCellTemplate let-dataItem  >
      <span > {{dataItem.high | number }}  
      </span>
    </ng-template>
  </kendo-grid-column>
<kendo-grid-column field="low"  title="{{ 'Low' | translate }}" width="80">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.low | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Change"  title="{{ 'Change' | translate }}"width="100" >
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span
      [ngClass]="{'buy': dataItem.Change>0, 'sell' : dataItem.Change < 0 }"
      > {{dataItem.Change | number }}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="ChangePer"  title="{{ 'Change %' | translate }}" width="120">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span
      [ngClass]="{'buy': dataItem.ChangePer>0, 'sell' : dataItem.ChangePer < 0 }"
      > {{dataItem.ChangePer | number:'1.2-2'}}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="volume" title="{{ 'Volume' | translate }}" width="100">
  
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.volume | number }}  
    </span>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="Value" title="{{ 'Value' | translate }}" width="85">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Value | number }}  
    </span>
  </ng-template>
</kendo-grid-column>


<kendo-grid-column class="grid-one-day-chart" media="(min-width: 992px)" field="intraday" 
  title="{{ '1 Day Chart' | translate }}"
[width]="150" [sortable]="false"  [columnMenu]="false">
<ng-template kendoGridCellTemplate let-dataItem>
    <day-chart [data]="dataItem.trades" [changePct]="dataItem.ChangePer">
    </day-chart>
</ng-template>
</kendo-grid-column>

<kendo-grid-column field="Ask_qty" title="{{ 'Ask Qty' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Ask_qty | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Ask_qty" title="{{ 'Ask Price' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Ask_price | number }}  
    </span>
  </ng-template>
</kendo-grid-column>


<kendo-grid-column field="Bid_qty" title="{{ 'Bid Qty' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Bid_qty | number }}  
    </span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column field="Bid_price" title="{{ 'Bid Price' | translate }}" width="110">
  <ng-template kendoGridCellTemplate let-dataItem  >
    <span > {{dataItem.Bid_price | number }}  
    </span>
  </ng-template>
</kendo-grid-column>



</kendo-grid>
  </div> 
 
  