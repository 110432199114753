import { filter } from 'rxjs/operators';
import { style } from '@angular/animations';
import { Component, ElementRef, Inject, Injector, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { OrderDto } from 'app/models/order';
import { OrderTicketLookupDto } from 'app/models/orderTicketLookup';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { StatusEnum,  OrderEnum, OrderValidityEnum, ApproveStatusEnum,  CompanyTypeEnum, MatchTypeEnum } from 'app/core/enums'
import { SymbolDto } from 'app/models/symbol';
import { ToastrService } from 'ngx-toastr';
import { SetupApi } from 'app/Shared/api/setup.api';
import { DatePipe } from '@angular/common';
import { AppComponentBase } from 'app/app-component-base';
import { ClientPortfolioFilter, ClientReuqest } from 'app/models/client';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AuctionOrderCofirmationDialogComponent } from '../auction-order-cofirmation-dialog/auction-order-cofirmation-dialog.component';
import { AuctionApi } from 'app/auction-management/api/auction.api';
import { ClientService } from 'app/client-managment/services/client.service';
import { SymbolApi } from 'app/symbol-managment/api/symbol.api';
 
@Component({
  selector: 'orderTicket-dialog',
  templateUrl: './auction-orderTicket-dialog.component.html',
  styleUrls: ['./auction-orderTicket-dialog.component.scss']
})
export class AuctionOrderTicketDialogComponent extends AppComponentBase implements OnInit {
  clientReuqest:ClientReuqest;
  companyType:any;
  selectedSymbolUnit: string = 'Unit';
  addNewOrderForm: FormGroup;
  orderId: any;
  auctionId: any  ;
  auction: any  ;
  orderValidityDay: any = OrderValidityEnum.Day;
  statuslookups: StatusLookupDto = new StatusLookupDto();
  filter :any=new ClientPortfolioFilter();
  symbols: any;
  stores: any;
  filteredWarehouses:any;
  markets: any;
  //balance: any;
  isViewOnly: any;
  symbolsFilter: any;
  orderTypes = [
    { "id": 1, "name": this.translate('TranactionBuy') },
    { "id": 2, "name": this.translate('TranactionSell') }
  ]

  IsMarketList = [
    { "id": false, "name": this.translate('NormalPrice') },
    { "id": true, "name": this.translate('MarketPrice') }
  ]
  isMarketPrice: Number = 0;
  order: OrderDto = new OrderDto();
  Clients: any;
  Currency: any
  Auctions: any;
  disableQty: boolean= false;
  isValid: boolean = true;
  FilterClients: any;
  SymbolCurrency: any;
  updatedOrder: OrderDto = new OrderDto();
  title: string;
  PriceLimit: string;
  errors: string[] = [];
  isUpdate: boolean;
  isDisableSymbol: boolean;
  AuctionCloseDate: any;
  AuctionOpenDate: any;
  AuctionType: any;
  ClientAuction:any;
  CompanyTypeEnum=CompanyTypeEnum;
  IsAllowEditOrderQty :boolean = false;
  IsAllowEditOrderPrice :boolean = false;
  IsAllowCapping: boolean = false;
  IsAllowPriceWithinLimit: boolean = false;
  CapQty: any;

  constructor(public formBuilder: FormBuilder,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private symbolApi:SymbolApi,
    private dialogRef: MatDialogRef<AuctionOrderTicketDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , private dashboardApi: DashboardApi,
     private auctionApi: AuctionApi,
    private setupApi: SetupApi,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private clientService:ClientService,
    injector: Injector
  ) {
    super(injector); 
    if(data.isViewOnly!=null && data.isViewOnly==true){
         this.isViewOnly=true;
    }

    if (data != null) {
      this.order = data.order;
      this.orderId = data.id; 

    } 

    if (this.orderId > 0) {
      this.isUpdate = true;
      this.getOrderClient(this.order.ClientID);
      this.title = this.translate('UpdateOrder-title'  ) ;
    }
    else {
      this.isUpdate = false;
      this.title = this.translate('CreateOrder-title');
    }


    this.clientReuqest=new ClientReuqest();
    this.clientReuqest.Status=StatusEnum.Active;
    this.clientReuqest.ApproveStatusId=ApproveStatusEnum.Approve;
 
    
    this.getUserBrokerInfo().subscribe(res => {

      if (res != null  ) {  
        this.clientReuqest.CompanyId=res.CompanyId; 
        this.companyType=res.CompanyType;
 
         if( this.companyType==CompanyTypeEnum.B){
              this.clientService.getClientByCompanyId(res.CompanyId).subscribe(client=>{
            
                this.order.ClientID=client.Id;
                this.Clients =[];
                this.Clients = [client, ...this.Clients];
              });
         }
       }
    });

    this.symbolApi.getSymbolByCode( this.order.SymbolCode). subscribe(res => {
      this.symbols =[];   this.symbols = [res, ...this.symbols];
      this.bindNewOrderData();
    }, err => console.log(err));
  }


  ngOnInit() { 
    this.addNewOrderForm = this.fb.group({

      MinimumFill: ['', Validators.compose([Validators.required, Validators.min(0)])],
      ClientID: [''],
      OrderType: ['', Validators.compose([Validators.required])],
      SymbolCode: ['', Validators.compose([Validators.required])],
      OrderDate: ['', Validators.compose([Validators.required])],
      CustodianID: ['' ],
      AuctionID: [''],
      OrderPrice: ['', Validators.compose([Validators.required, Validators.min(0)])],
      OrderQty: ['', Validators.compose([Validators.required, Validators.min(1)])],
      CapQty: [''],
      PriceLimit: [''],
      ClientFilter: [''],
      CloseDate: [''],
      AuctionType: [''],
      MarketID: [''],
      Currency: [''],
      Unit: [''],
    //  balance: [''],
      itemNameseaRCHb: ['itemNameseaRCHb'],
      symbolNameseaRCHbs: ['symbolNameseaRCHbs'],
      CustionList: ['CustionList'],
    });
 
    if (this.order.OrderType == 1)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#dce2f3');
    if (this.order.OrderType == 2)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#ffe8e8');

    this.setupApi.GetStoreSymbolByCode(this.order.SymbolCode).subscribe(res => { this.stores = res;
    this.filteredWarehouses = res; });
    this.auctionApi.getAuctionView(this.order.AuctionID).subscribe(res=>{
      this.Auctions=[];
      this.Auctions = [res, ...this.Auctions];
      this.FillAuctionType(res);
      this.onChangeAuction(this.order.AuctionID);
    })
  }

  
  filterclients(value) 
  {
   
    this.clientReuqest.GeneralFilter=value;
    
    if (value.length > 2){ 
      this.setupApi.searchActiveClients(this.clientReuqest).
        subscribe(res => {   this.Clients = res ;
        this.Clients=this.Clients.filter(x=>x.Id!=this.ClientAuction) ;});
    } 
  }
  onChangeClient( ){
   
    this.getBalance();
  }
  getCurrentCurrencyRateById(id){
    this.setupApi.getCurrentCurrencyRateById(id,null).subscribe(res=>{
      this.Currency=res;
    
      if(this.isRtl()){ 
        this.SymbolCurrency=  this.Currency.Currency.NameAr;
      }else{
        this.SymbolCurrency=  this.Currency.Currency.NameEn;
      }
    });
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit( ) {
  
      this.errors = [];
      this.validationForm();
      this.order.OrderType = Number(this.order.OrderType);
      this.order.OrderValidity=OrderValidityEnum.GoodTillDate;
      if (this.isValid &&this.errors.length==0 ) {
     
        var store=this.stores.filter(c=>c.StoreID== this.order.CustodianID);
       if(store.length>0){

        this.order.StoreNameAr =store[0].StoreNameAr;
        this.order.StoreNameEn=store[0].StoreNameEn;
       }
       this.order.SymbolID=this.symbols.filter(x=>x.SymbolCode== this.order.SymbolCode)[0].Id;
        this.order.ClientNameAr=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].NameAr;
        this.order.ClientNameEn=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].NameEn;
        this.order. ClientCode=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].IdentityTypeText;
        if (this.orderId > 0) 
        {

          this.bindUpdateData();
          const dialog = this.dialog.open(AuctionOrderCofirmationDialogComponent, {
            data:{ order:this.updatedOrder ,companyType:this.companyType  }  
          });
          dialog.afterClosed().subscribe((res) => {
            if(res==true){
              this. close() ;
            }
      
          } );
  
        }
        else
        {
          const dialog = this.dialog.open(AuctionOrderCofirmationDialogComponent, {
            data:{ order:this.order ,companyType:this.companyType }  
          });
          dialog.afterClosed().subscribe((res) => {
            if(res==true){

              this. close() ;
            }
      
          } );
  
        }
       
    }
   
  }


  getSymbolInfo(){
  
  this.order.SymbolNameAr=this.symbols.filter(c=>c.SymbolCode== this.order.SymbolCode)[0].NameAr;
  this.order. SymbolNameEn=this.symbols.filter(c=>c.SymbolCode== this.order.SymbolCode)[0].NameEn;
 
  }

  validationMinimumFillField() {
   // this.errors = [];
    if (this.addNewOrderForm != null) {
      var MinimumFill = this.addNewOrderForm.get("MinimumFill").value;
      var OrderQty = this.addNewOrderForm.get("OrderQty").value;
      if (MinimumFill > OrderQty) {

        this.isValid = false;
        
        this.errors.push('Qty value cannot be lower than Minimum Fill Value');
      } 


    }

  }

  validationPriceLimitField() {
  
      if(this.order.OrderPrice == 0 ){
        this.isValid = false;
        this.errors.push('Violating price limits ');
       }
       else if ( this.IsAllowPriceWithinLimit && (this.order.OrderPrice < this.markets[0].LowerLValue
        || this.order.OrderPrice > this.markets[0].UpperLValue))
        this.errors.push('Violating price limits ');

      else{
        this.isValid = true;
        var auction =   this.Auctions.filter(x => x.Id ==this.order.AuctionID);
        if( auction!=null && auction.length>0 ) {

          // if (auction[0].MatchingType ==MatchTypeEnum.Cap ) {
          //   //var market = markets.filter(x => x.MarketId == this.order.MarketID);
          //   if(this.markets .length > 
          //     0&&!(this.order.OrderPrice >=this.markets [0].LowerLValue 

          //     //comment this line bcs, they need to validate min price only  
          //     //&& this.order.OrderPrice <=this.markets [0].UpperLValue
          //     ) ) {
          //       this.isValid = false;
          //       this.errors.push('Violating price limits ');
          //     }         
          // } 

          // else{
          //   if(auction[0].OrderType==OrderEnum.Buy){
          //     if(this.order.OrderPrice>auction[0].Price){
          //       this.isValid = false;
          //       this.errors.push('Violating price limits ');
          //     }else{
          //       this.isValid = true;
          //     }
          //   }else{
          //     if(this.order.OrderPrice<auction[0].Price){
          //       this.isValid = false;
          //       this.errors.push('Violating price limits ');
          //     }else{
          //       this.isValid = true;
          //     }
          //   }
          // }
  
        }
        
      }
 
  }

  validationCapQty() {
      if(this.IsAllowCapping &&  this.order.OrderQty > this.CapQty){
        this.isValid = false;
        this.errors.push('Qty should be less than the Cap Qty');
        this.order.OrderQty = this.CapQty;
      }
      else  this.isValid = true;
  }

  validationWarehouseField() {
    //this.errors = [];
    
    if (this.addNewOrderForm != null) {
      var CustodianID = this.addNewOrderForm.get("CustodianID").value;
      var OrderType = this.addNewOrderForm.get("OrderType").value;
      if (CustodianID  ==null  && OrderType== OrderEnum.Sell) {

        this.isValid = false;
        this.errors.push('Warehouse is requried ');
      } 


    }
  }

  validationForm() {
    this.validationMinimumFillField();
   // this.validationWarehouseField();
    //commented by karim
    this.validationPriceLimitField();
    this.validationCapQty();
  }

  bindNewOrderData() {

    if (this.isUpdate == true) {
      this.dashboardApi.getOrder(this.orderId).  subscribe(res => {
           this.order = res
           this.getSymbolInfo();
           this.getBalance();
        },
          err => console.log(err)
        );
    } else {
      
      this.order.MsgType = '1';
      this.order.MinimumFill = 0;
      this.order.OrderValidity = 1;
    
      this.isMarketPrice = 0;
      this.order.OrderDate = new Date();

      if (this.order.SymbolCode != null) { 
        this.isDisableSymbol=true; 
        this.getSymbolInfo(); 
      }

    }
   

  }
  
  bindUpdateData() {

    this.updatedOrder.OrderID = this.orderId;
    this.updatedOrder.OrderPrice = this.order.OrderPrice;
    this.updatedOrder.OrderQty = this.order.OrderQty;
    this.updatedOrder.OrderValidity = this.order.OrderValidity;
    this.updatedOrder.GoodTillDate = this.order.GoodTillDate;
    this.updatedOrder.MinimumFill = this.order.MinimumFill;

    
    this.updatedOrder.OrderType =  this.order.OrderType ;
    this.updatedOrder.OrderDate  =  this.order.OrderDate ;
    this.updatedOrder.CustodianID=this.order.CustodianID;
    this.updatedOrder.StoreNameAr =  this.order.StoreNameAr ;
    this.updatedOrder.StoreNameEn =  this.order.StoreNameEn;
    this.updatedOrder.SymbolNameAr = this.order.SymbolNameAr;
    this.updatedOrder.SymbolNameEn = this.order. SymbolNameEn;
    this.updatedOrder.ClientNameAr =  this.order.ClientNameAr;
    this.updatedOrder.ClientNameEn = this.order.ClientNameEn;
  }

  onChangeAuction(id) {
    
    this. auction = this.Auctions.filter(x => x.Id == id);
    if (this. auction .length > 0) {
      this.ClientAuction=this. auction [0].MainClient;
      this.AuctionCloseDate = this. auction [0].CloseDate;
      this.AuctionOpenDate = this. auction [0]. OpenDate;
      //  this.AuctionCloseDate= this.datePipe.transform( this.AuctionCloseDate, "dd-MM-yyyy");
      if (this. auction [0].OrderType == 1) {

        this.AuctionType = 'Buy';
        this.order.OrderType = 2;
      } else {
        this.AuctionType = 'Sell';
        this.order.OrderType = 1;
      }
   
      this.order.GoodTillDate = this.AuctionCloseDate;

      this.order.SymbolCode = this. auction [0].SymbolCode;
      this.getSymbolInfoData(this.order.SymbolCode);
 
      if(this. auction [0].AuctionType==CompanyTypeEnum.AgriculturalCooperative){
          this.disableQty=true;
          this.order.OrderQty= this. auction [0].Qty;
          this.order.OrderPrice= this. auction [0].Price;
      }else{
          this.disableQty= false ;
      }
      this.order.CustodianID = this. auction [0].StoreId;
      if (this.order.OrderType == 1)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#dce2f3');
    if (this.order.OrderType == 2)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#ffe8e8');
    }
  }
 
  FillAuctionType(auctionView){
   
    this.IsAllowEditOrderPrice = auctionView.IsAllowEditOrderPrice;
    this.IsAllowEditOrderQty = auctionView.IsAllowEditOrderQty;
    this.IsAllowPriceWithinLimit = auctionView.IsAllowPriceWithinLimit;
    this.IsAllowCapping = auctionView.IsAllowCapping;
    if (auctionView.MatchingType == MatchTypeEnum.Cap)  this.IsAllowCapping = true;
    this.CapQty = auctionView.CapQty;
  }

  onChangeMarketPrice(event) {
    if (event.value == 1) {
      this.order.OrderPrice = 0;
    }
  }

  onChangeSymbol(event) {

    this.getSymbolInfoData(event.value);
    this.getBalance();
  }

  getSymbolInfoData(code:any){
  
    var symbol = this.symbols.filter(c => c.SymbolCode === code);
    if(symbol.length>0){
      this.setupApi.getSymbolMarkets(symbol[0].Id).subscribe(res => {
          this.markets = res; 
          this.order.MarketID=this.markets[0].MarketId;  
          this.calPriceLimit(this.markets);
     });
      this.setUnitLable(symbol[0].Unit);
      this.getSymbolInfo();
      this.getCurrentCurrencyRateById(symbol[0].CurrencyId);
  
    }
  }

  calPriceLimit(markets) {
 
    if (markets !=null && markets.length > 0) {

      var market = markets.filter(x => x.MarketId == this.order.MarketID);
      //comment this line bcs they need to show min value only
      this.PriceLimit = markets[0].LowerLValue + ' - ' + markets[0].UpperLValue;
      //this.PriceLimit = ' Min - ' + markets[0].LowerLValue ;
    } else {
      this.PriceLimit = ' - ';
    }

    this.setUnitLable(markets[0].Unit);
  }

  setUnitLable(Unit: any) {
    if (Unit != null && Unit != undefined) {
      if (Unit == 1) {
        this.selectedSymbolUnit = "KG";
      } else if (Unit == 2) {
        this.selectedSymbolUnit = "Ton";
      } else if (Unit == 3) {
        this.selectedSymbolUnit = "Ard";
      } else if (Unit == 4) {
        this.selectedSymbolUnit = "gm";
      } else if (Unit == 5) {
        this.selectedSymbolUnit = "Pound";
      }
    }
  }

 
  onChangeOrderType(event) {

    this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', event.value == 1 ? '#dce2f3' : '#ffe8e8');
  }

  close() {
    this.dialogRef.close(this.order);
  }

  getOrderClient(id){
     this.clientService.getClient(id).subscribe(res=>{
       this.Clients=[];
       this.Clients.push(res);
     })
  }

  
  onChangeWarehouse(value) {
 
    if(this.stores != null)
      { 
        this.order.CustodianID = null;
        if(value.match(/^ *$/) !== null)
        this.filteredWarehouses = this.stores;
        else
        this.filteredWarehouses = this.stores.filter((s) => s.StoreNameEn.toLowerCase().indexOf(value.toLowerCase()) !== -1 )
          || this.stores.filter((s) => s.StoreNameAr.indexOf(value.toLowerCase()) !== -1 );
          this.getBalance();
      }
    
  }

  getClientPurchasePower( ){
    // var symbol = this.symbols.filter(c => c.SymbolCode === this.order.SymbolCode);
    // this.clientService.getClientPurchasePower(this.order.ClientID,symbol[0].CurrencyId).subscribe(res=>{
    //   if(res!=null){ this.balance=res.TotalBalance;}else{ this.balance=0;}
    // });
  }
  getAvilableQty(){
    // var symbol = this.symbols.filter(c => c.SymbolCode === this.order.SymbolCode)[0];
 
    // this.filter.CustodianID=this.order.CustodianID;
    // this.filter.ClientID=this.order.ClientID;
    // this.filter.SymbolID=symbol.Id ;
    // this.filter.MarketID=this.order.MarketID;
    // debugger;
    // this.clientService.getAvilableQty(this.filter).subscribe(res=>{
    //     this.balance=res;
    // });
   }
   getBalance(){
    // debugger;
    // if(this.order.OrderType==2){
    //   this.getAvilableQty();
    // }else{
    //   this.getClientPurchasePower();
    // }


   }
}
 

