<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >

			   </div>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="start center">				
                    <div class="trade-crypto crypto-input-prefix">
                        <div class="padding-input-form">

                          <mat-form-field class="padding10 form-field"  appearance="outline"  *ngIf="!this.isBroker()" >
                            <mat-label>{{ 'Trading Company' | translate }}</mat-label>
                              <mat-select 							        
                                  [(ngModel)]="filterOptions.CompanyId"
                                  name="CompanyID" 
                                  class="filter-select" 
                                  [placeholder]="'Company' | translate "
                                  (selectionChange)="onChangeCompany($event);">
                                  <mat-label>{{ 'Company' | translate }}</mat-label>	
                              <mat-option>								
                                <ngx-mat-select-search name="companySearch" 
                                  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                               >
                                </ngx-mat-select-search>
                                <ngx-mat-select-filter></ngx-mat-select-filter>
                              </mat-option>
                                <mat-option >	{{ 'All' | translate }} </mat-option>
                                  <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                    {{isRtl()?company.NameAr:company.Name}}
                                </mat-option>
                              </mat-select> 
                          </mat-form-field>
                    
                            <mat-form-field appearance="outline"  class="padding10 form-field">
                               <mat-label>{{ 'Client' | translate }}</mat-label>
                                <mat-select    
                                   [(ngModel)]="filterOptions.ClientId" 
                                    name="ClientID"	 
                                    class="filter-select"
                                   [placeholder]="'Client' | translate "
                                 >
                                  <mat-label>{{ 'Client' | translate }}</mat-label>								
                                  <mat-option>								
                                    <ngx-mat-select-search name="clientSearch" 
                                      placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                      (ngModelChange)="changeClientFilter($event)">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                    <mat-option > {{ 'All' | translate }} </mat-option>
                                      <mat-option *ngFor="let client of clients" [value]="client.Id">
                                        {{isRtl()?client.NameAr:client.NameEn}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>
 

                        </div>
                    </div>
                </div>
                <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
                  <mat-card-actions class="mat-pad-none margin-none">
                    <a mat-raised-button color=""  (click)="getList()"   title=" {{ 'Search' | translate }}" *ngIf="filterOptions.CompanyId>0" ><mat-icon  >search</mat-icon></a>	
                  </mat-card-actions>
                </div>
             </div>
 
	

		 </div>
        </mat-card> 
        <mat-card> 

			<br/>
	<kendo-grid   #grid
    [data]="view | async"
	  [filterable]="false" 
	  [resizable]="true"
	  [reorderable]="true"
	  filterable="menu"  
	  [sortable]="true" 
	  [kendoGridSelectBy]="selectBy"
	  [columnMenu]="true"
    class="market-kendo-grid"
	  [rowClass]="rowCallback"
	  [pageSize]="state.take"
	  [skip]="state.skip"
	  [sort]="state.sort"
	  [pageable]="true"
	  (dataStateChange)="dataStateChange($event)" > 

    <ng-template kendoGridToolbarTemplate>
      <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
    </ng-template>
    <kendo-grid-excel fileName="Client Purchase Power.xlsx" > </kendo-grid-excel>
				   <kendo-grid-column   title="{{ 'ID' | translate }}  " 
				   [class]="{ codeColumn: true }"
				   [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					 <span> {{(rowIndex+1)}}</span>	
					</ng-template>
				   </kendo-grid-column>

           <kendo-grid-column field="CompanyCode"  title="{{ 'Company Code' | translate }} "
           headerClass="grid-list-column-header" width="150" >
         <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                       <span class="client-name" >{{dataItem.CompanyCode}} </span>         
         </ng-template>
         <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Company Code'| translate }} </ng-template>
     
       </kendo-grid-column>


    <kendo-grid-column field="CompanyNameA"  title="{{ 'Company Name' | translate }} "
       headerClass="grid-list-column-header" width="250" >
     <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">   
                   <span class="client-name" *ngIf="isRtl()"  >{{dataItem.CompanyNameA}} </span>
                   <span class="client-name" *ngIf="!isRtl()" >{{dataItem.CompanyNameE }} </span>   
     </ng-template>
     <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Company Name'| translate }} </ng-template>
   </kendo-grid-column>


   <kendo-grid-column field="ClientCode"  title="{{ 'Client Code' | translate }} "
      headerClass="grid-list-column-header" width="150" >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="client-name"  >{{dataItem.ClientCode}} </span>         
    </ng-template>
    <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Code'| translate }} </ng-template>

  </kendo-grid-column>
       
  <kendo-grid-column field="ClientNameA"  title="{{ 'Client Name' | translate }} " headerClass="grid-list-column-header" width="250" >
					  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="client-name" *ngIf="isRtl()" >{{dataItem.ClientNameA}} </span>
                   <span class="client-name" *ngIf="!isRtl()">{{dataItem.ClientNameE }} </span>                 
					  </ng-template>
					  <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Client Name'| translate }} </ng-template>
	</kendo-grid-column>


       <kendo-grid-column field="CurrencyCode"  title="{{ 'Currency Code' | translate }} "
       headerClass="grid-list-column-header" width="180" >
                   <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="client-name"  >{{dataItem.CurrencyCode}} </span> 
      </ng-template>
      <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Currency Code'| translate }} </ng-template>

    </kendo-grid-column>

           <kendo-grid-column field="TotalBalance" title="{{'Purchase Power' | translate }}" headerClass="grid-list-column-header"   >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.TotalBalance| number}}
            </ng-template>
          </kendo-grid-column>
    
     
          <kendo-grid-column field="UntsettledBalance" title="{{'Untsettled Balance' | translate }}" headerClass="grid-list-column-header"  >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.UntsettledBalance| number}}
            </ng-template>
          </kendo-grid-column>
    
          <kendo-grid-column field="Balance" title="{{'Current Balance' | translate }}" headerClass="grid-list-column-header"  >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.Balance| number}}
            </ng-template>
          </kendo-grid-column>
    
    
          <kendo-grid-column field="OrderBalance" title="{{'Open Orders' | translate }}" headerClass="grid-list-column-header"  >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.OrderBalance| number}}
            </ng-template>
          </kendo-grid-column>
    
    
          <kendo-grid-column field="OrderCommissionBalance" title="{{'Open Orders Commissions' | translate }}" headerClass="grid-list-column-header"  >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.OrderCommissionBalance| number}}
            </ng-template>
          </kendo-grid-column>  
		 </kendo-grid> 
	</mat-card>
</div>