<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
				  <mat-card-actions class="mat-pad-none margin-none">

            <a mat-raised-button color=""  (click)="getList()"   title=" {{ 'Search' | translate }}" ><mat-icon  >search</mat-icon></a>	
     

				  </mat-card-actions>
			   </div>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="start center">				
                    <div class="trade-crypto crypto-input-prefix">
                        <div class="padding-input-form">
                          <mat-form-field appearance="outline"  class="padding10 form-field">
                            <mat-label>{{ 'Service Type'| translate }}</mat-label>
                             <mat-select     [(ngModel)]="serviceId"  name="MonitorServicesType"	
                             (selectionChange)="onChangeType($event);"   
                                    class="filter-select"    [placeholder]="'Request Type' | translate " > 
                                   <mat-option *ngFor="let type of MonitorServicesTypes" [value]="type.id">
                                     {{type.name | translate }}
                                   </mat-option>
                             </mat-select>
                         </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
 
                </div>
             </div>
 
	

		 </div>
   </mat-card> 


 <mat-card > 
  <br/> 
    <kendo-grid   #grid
    [data]="view | async"
    [filterable]="false" 
    [resizable]="true"
    [reorderable]="true"
    filterable="menu"  
    [sortable]="true" 

    [columnMenu]="true"
    class="market-kendo-grid"
   
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [pageable]="true"
    (dataStateChange)="dataStateChange($event)" > 
           <kendo-grid-column   title="{{ 'ID' | translate }}  " 
           [class]="{ codeColumn: true }"
           [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <span> {{(rowIndex+1)}}</span>	
          </ng-template>
           </kendo-grid-column>


          <kendo-grid-column field="ServiceId"  title="{{ 'Service Id' | translate }} " headerClass="grid-list-column-header" width="120" >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                 <span class="client-name"  >{{ servicesType[dataItem.ServiceId - 1].name  }} </span> 
            </ng-template>
            <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Service Id'| translate }} </ng-template>
         </kendo-grid-column>
   
 

        <kendo-grid-column field="CreateDate"  title="{{ 'Date' | translate }} " headerClass="grid-list-column-header" width="120" >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
               <span class="client-name"  >{{dataItem.CreateDate | date: 'dd/MM/yyyy'}} </span> 
         
          </ng-template>
          <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Date'| translate }} </ng-template>
       </kendo-grid-column>

       <kendo-grid-column field="CreateDate"  title="{{ 'Time' | translate }} " headerClass="grid-list-column-header" width="120" >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             <span class="client-name"  >{{dataItem.CreateDate | date: 'h:mm:ss'}} </span> 
       
        </ng-template>
        <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Time'| translate }} </ng-template>
     </kendo-grid-column>

 </kendo-grid> 

</mat-card>
</div>