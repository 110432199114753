import { filter } from 'rxjs/operators';
import { style } from '@angular/animations';
import { Component, ElementRef, Inject, Injector, Input, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { OrderDto } from 'app/models/order';
import { OrderTicketLookupDto } from 'app/models/orderTicketLookup';
import { StatusLookupDto } from 'app/models/StatusLookup';
import {OrderEnum, OrderValidityEnum, ApproveStatusEnum, StatusEnum } from 'app/core/enums'
import { SymbolDto } from 'app/models/symbol';
import { ToastrService } from 'ngx-toastr';
import { SetupApi } from 'app/Shared/api/setup.api';
import { DatePipe } from '@angular/common';
import { AppComponentBase } from 'app/app-component-base';
import { ClientReuqest, SearchRequestDto } from 'app/models/client';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { OrderCofirmationDialogComponent } from '../order-cofirmation-dialog/order-cofirmation-dialog.component';
import { ClientService } from 'app/client-managment/services/client.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { SymbolApi } from 'app/symbol-managment/api/symbol.api';
import { translate } from '@ngneat/transloco';
 
@Component({
  selector: 'orderTicket-dialog',
  templateUrl: './orderTicket-dialog.component.html',
  styleUrls: ['./orderTicket-dialog.component.scss']
})
export class OrderTicketDialogComponent extends AppComponentBase implements OnInit {
  clientReuqest:ClientReuqest=new ClientReuqest();
  selectedSymbolUnit: string = this.translate('Unit');
  addNewOrderForm: FormGroup;
  lookups: OrderTicketLookupDto = new OrderTicketLookupDto();
  orderId: any;
  orderValidityDay: any = OrderValidityEnum.Day;
  orderValidities:any;
  statuslookups: StatusLookupDto = new StatusLookupDto();
  symbols: any;
  stores: any;
  markets: any;
  isViewOnly: any;

  orderTypes = [
    { "id": 1, "name": this.translate('TranactionBuy') },
    { "id": 2, "name": this.translate('TranactionSell') }
  ]

  IsMarketList = [
    { "id": false, "name": this.translate('NormalPrice') },
    { "id": true, "name": this.translate('MarketPrice') }
  ]
  isMarketPrice: Number = 0;
  order: OrderDto = new OrderDto();
  Clients: any;
  isValid: boolean = true;
  FilterClients: any;
  updatedOrder: OrderDto = new OrderDto();
  title: string;
  PriceLimit: string;
  errors: string[] = [];
  isUpdate: boolean;
  isDisableSymbol: boolean;


  constructor(public formBuilder: FormBuilder,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OrderTicketDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , private dashboardApi: DashboardApi,
    private setupApi: SetupApi,
    private symbolApi:SymbolApi,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private clientService :ClientService,
    injector: Injector,
    private _helper: EnumHelpers,
  ) {


    super(injector);

    if(data.isViewOnly!=null && data.isViewOnly==true){
         this.isViewOnly=true;
    }

    
    if (data != null) {
      this.order = data.order;
      this.orderId = data.id;
      if(data.isBidAsk!=null && data.isBidAsk==true){
        this.order.ClientID= null;
       }
  
    } 
    if (this.orderId > 0) {
      this.isUpdate = true;
      this.getOrderClient(this.order.ClientID);
      this.title = this.translate('UpdateOrder-title'  ) ;
    }
    else {
      this.isUpdate = false;
      this.title = this.translate('CreateOrder-title');
      this.order.MsgType = '1';
      this.order.MinimumFill = 0;
      this.order.OrderValidity = 1;
      this.order.AuctionID = 0;
      this.isMarketPrice = 0;
    }

  }


  ngOnInit() {

    this.orderValidities=this._helper.getNamesAndValues(OrderValidityEnum);
    // renderer.setElementStyle(el.nativeElement, 'backgroundColor', 'yellow');

    this.addNewOrderForm = this.fb.group({

      MinimumFill: ['', Validators.compose([Validators.required, Validators.min(0)])],
      ClientID: ['', Validators.compose([Validators.required])],
      OrderType: ['', Validators.compose([Validators.required])],
      SymbolCode: ['', Validators.compose([Validators.required])],
      OrderDate: ['', Validators.compose([Validators.required])],
      CustodianID: ['' ],
      MarketID: ['', Validators.compose([Validators.required])],
    
      OrderPrice: ['', Validators.compose([Validators.required, Validators.min(0)])],
      OrderQty: ['', Validators.compose([Validators.required, Validators.min(0)])],
      OrderValidity: [''],
      GoodTillDate: [''],
      isMarketPrice: [''],
      PriceLimit: [''],
      ClientFilter: [''],
      CloseDate: [''],

      itemNameseaRCHb: ['itemNameseaRCHb'],
      symbolNameseaRCHbs: ['symbolNameseaRCHbs'],
      CustionList: ['CustionList'],
    });
    
    if (this.order.OrderType == OrderEnum.Buy)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#dce2f3');
    if (this.order.OrderType == OrderEnum.Sell)
      this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', '#ffe8e8');
 
    this.clientReuqest=new ClientReuqest(); 
    this.clientReuqest.ApproveStatusId=ApproveStatusEnum.Approve;
    this.clientReuqest.Status=StatusEnum.Active;
    if (this.isBroker()) {
 
      this.getUserBrokerInfo().subscribe(res => {
        if (res != null ) {
 
          this.clientReuqest.CompanyId= res.CompanyId;
        }
      });
    }
    this.order.OrderDate = new Date();
    if(this.order.SymbolCode!=null){
      this.symbolApi.getSymbolByCode( this.order.SymbolCode).
      subscribe(res => {
        this.symbols =[];
        this.symbols = [res, ...this.symbols];
        this.bindNewOrderData();
      });
    }


  }
  filterSymboles(value) {
 

      var searchRequestDto=new SearchRequestDto();
      searchRequestDto.SearchText=value;
      if (value.length > 1){
        this.setupApi.searchActiveSymbols(searchRequestDto).
        subscribe(res => {   this.symbols = res;});
      }
  }


  filterclients(value) {

    this.clientReuqest.GeneralFilter=value;
    
    if (value.length > 2){
      this.setupApi.searchActiveClients(this.clientReuqest).
      subscribe(res => {   this.Clients = res; this.FilterClients = res });
    }
    
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit( ) {
  
      this.errors = [];
      this.validationForm();
      this.order.OrderType = Number(this.order.OrderType);
      if (this.isValid &&this.errors.length==0 ) {
       var store=this.stores.filter(c=>c.StoreID== this.order.CustodianID);
       if(store.length>0){

        this.order.StoreNameAr =store[0].StoreNameAr;
        this.order.StoreNameEn=store[0].StoreNameEn;
       }
       this.order.SymbolID=this.symbols.filter(x=>x.SymbolCode== this.order.SymbolCode)[0].Id;
        this.order.ClientNameAr=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].NameAr;
        this.order.ClientNameEn=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].NameEn;
        this.order. ClientCode=this.Clients.filter(c=>c.Id== this.order.ClientID)[0].IdentityTypeText;
        if (this.orderId > 0) {
  
          this.bindUpdateData();
          const dialog = this.dialog.open(OrderCofirmationDialogComponent, {
            data:{ order:this.updatedOrder ,orderId:this.orderId  }  
          });
          dialog.afterClosed().subscribe((res) => {
            if(res==true){

              this. close() ;
            }
      
          } );
  
        }
        else
        {
        
       
          const dialog = this.dialog.open(OrderCofirmationDialogComponent, {
            data:{ order:this.order ,orderId:0 }  
          });
          dialog.afterClosed().subscribe((res) => {
            if(res==true){

              this. close() ;
            }
      
          } );
  
        }
       
    }
   
  }


  
   getSymbol(){
 
    this.order.SymbolNameAr=this.symbols.filter(c=>c.SymbolCode== this.order.SymbolCode)[0].NameAr;
    this.order. SymbolNameEn=this.symbols.filter(c=>c.SymbolCode== this.order.SymbolCode)[0].NameEn;
    
   }

  validationMinimumFillField() {
   // this.errors = [];
    if (this.addNewOrderForm != null) {
      var MinimumFill = this.addNewOrderForm.get("MinimumFill").value;
      var OrderQty = this.addNewOrderForm.get("OrderQty").value;
      if (MinimumFill > OrderQty) {

        this.isValid = false;
        
        this.errors.push('Qty value cannot be less than the Minimum Fill Value ');
      } 


    }

  }

  validationPriceLimitField() {
  
    if(this.order.OrderPrice==0 && this.isMarketPrice!=1){
      this.isValid = false;
      this.errors.push('Violating price limits ');
    }else{
      this.isValid = true;
 
      
    }

 
  }
  validationWarehouseField() {
    //this.errors = [];
    
    if (this.addNewOrderForm != null) {
      var CustodianID = this.addNewOrderForm.get("CustodianID").value;
      var OrderType = this.addNewOrderForm.get("OrderType").value;
      if (CustodianID  ==null  && OrderType== OrderEnum.Sell) {

        this.isValid = false;
        this.errors.push('Warehouse is requried ');
      } 


    }
  }


  validationForm() {
    this.validationMinimumFillField();
    this.validationPriceLimitField();
   // this.validationWarehouseField();
  }
  bindNewOrderData() {

    if (this.isUpdate == true) {
      this.dashboardApi.getOrder(this.orderId).
        subscribe(res => {
          this.order = res

          this.getSymbol();
          var symbol = this.symbols.filter(c => c.SymbolCode === this.order.SymbolCode);
          this.setupApi.getSymbolMarkets(symbol[0].Id).subscribe(res => { this.markets = res;          this.calPriceLimit(this.markets); });
          this.setupApi.GetStoreSymbols(symbol[0].Id).subscribe(res => { this.stores = res; });

        },
          err => console.log(err)
        );
    } else {
      



      if (this.order.SymbolCode != null) {
    
        var symbol = this.symbols.filter(c => c.SymbolCode === this.order.SymbolCode);
        this.isDisableSymbol=true;
        this.getSymbol();
        this.setupApi.getSymbolMarkets(symbol[0].Id).subscribe(res => { this.markets = res;        this.calPriceLimit(this.markets); });
        this.setupApi.GetStoreSymbols(symbol[0].Id).subscribe(res => { this.stores = res; });

      }

    }


  }
  bindUpdateData() {

    this.updatedOrder.OrderID = this.orderId;
    this.updatedOrder.OrderPrice = this.order.OrderPrice;
    this.updatedOrder.OrderQty = this.order.OrderQty;
    this.updatedOrder.OrderValidity = this.order.OrderValidity;
    this.updatedOrder.GoodTillDate = this.order.GoodTillDate;
    this.updatedOrder.MinimumFill = this.order.MinimumFill;

    this.order.AuctionID = 0;
    this.updatedOrder.OrderType =  this.order.OrderType ;
    this.updatedOrder.OrderDate  =  this.order.OrderDate ;

    this.updatedOrder.StoreNameAr =  this.order.StoreNameAr ;
    this.updatedOrder.StoreNameEn =  this.order.StoreNameEn;
    this.updatedOrder.SymbolNameAr = this.order.SymbolNameAr;
    this.updatedOrder.SymbolNameEn = this.order. SymbolNameEn;
    this.updatedOrder.ClientNameAr =  this.order.ClientNameAr;
    this.updatedOrder.ClientNameEn = this.order.ClientNameEn;
  }
 
  onChangeMarket(event) {
 
  }
  onChangeMarketPrice(event) {
    if (event.value == 1) {
      this.order.OrderPrice = 0;
    }
  }
  onChangeSymbol(event) {

  
    this.getSymbolData(event.value);
  }

  getSymbolData(code:any){
  
  
    var symbol = this.symbols.filter(c => c.SymbolCode === code);
    if(symbol.length>0){

      this.setupApi.getSymbolMarkets(symbol[0].Id).subscribe(res => { this.markets = res;  this.order.MarketID=this.markets[0].MarketId;  this.calPriceLimit(this.markets); });
      this.setupApi.GetStoreSymbols(symbol[0].Id).subscribe(res => { this.stores = res; });
  
      this.getSymbol();
    }
  }

  setUnitLable(Unit: any) {
    if (Unit != null && Unit != undefined) {
      if (Unit == 1) {
        this.selectedSymbolUnit = "KG";
      } else if (Unit == 2) {
        this.selectedSymbolUnit = "Ton";
      } else if (Unit == 3) {
        this.selectedSymbolUnit = "Ard";
      } else if (Unit == 4) {
        this.selectedSymbolUnit = "gm";
      } else if (Unit == 5) {
        this.selectedSymbolUnit = "Pound";
      }
    }
  }

  calPriceLimit(markets) {
 
    if (markets.length > 0) {

      //var market = markets.filter(x => x.MarketId == this.order.MarketID);
      this.PriceLimit = markets[0].LowerLValue + ' - ' + markets[0].UpperLValue;
    } else {
      this.PriceLimit = ' - ';
    }

    this.setUnitLable(markets[0].Unit);
  }
  onChangeOrderType(event) {

    this.renderer.setStyle(this.elRef.nativeElement.parentElement, 'backgroundColor', event.value == 1 ? '#dce2f3' : '#ffe8e8');

  }

  close() {
    this.dialogRef.close(false);
  }

  getOrderClient(id){
    this.clientService.getClient(id).subscribe(res=>{
      this.Clients=[];
      this.Clients.push(res);
      
    })
 }
}
 

