
<form [formGroup]="addNewCommisionsForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">
                    <div class="padding-input-form">
 					
						<mat-form-field class="width50 form-field " appearance="outline" >
							<mat-label>{{ 'Commission' | translate }}</mat-label>
							<mat-select 
							[(ngModel)]="marketCommission.CommissionID" 
							name="commissionID" 
							formControlName="CommissionID"
							(selectionChange)="onChangeCommission($event)" 
							[disabled]="isUpdate">
							<mat-label>{{ 'Commission' | translate }}</mat-label>
							<mat-option>
								<ngx-mat-select-search name="commission" formControlName="commissionName"
									placeholderLabel="{{translate('Enter At Least One Character')}}"
									noEntriesFoundLabel="{{translate('No data found')}}" ngModel
									(ngModelChange)="onChangeCommission($event)">
								</ngx-mat-select-search>
							</mat-option>
							<mat-option *ngFor="let commission of  filteredCommissions" [value]="commission.CommissionID">			 
								<span *ngIf="isRtl()">{{commission.CommissionNameAR}}</span>
								<span *ngIf="!isRtl()">{{commission.CommissionNameEN}}</span>
							</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Minimum' | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Minimum" 
							  formControlName="Minimum"
							  [(ngModel)]="marketCommission.Minimum"  >  
						</mat-form-field>

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Maximum' | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Maximum" 
							  formControlName="Maximum" (ngModelChange)="validationComission($event)"
							  [(ngModel)]="marketCommission.Maximum"  >  
						</mat-form-field>
			 
					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{ 'Commission%' | translate }}</mat-label>
					  <input class="form-control" matInput type="number" placeholder="Commission %" 
						  formControlName="CommissionPerc"
						  [(ngModel)]="marketCommission.CommissionPerc"  >  
					</mat-form-field>
					 </div> 
				</div>
		    </div>

		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<div *ngIf="error != null">
			<label class="error-label">{{error}}</label>
			<br/>
		</div>
		<button (click)="onFormSubmit()"
		*ngIf=" auth.isGranted(_permissions.UpdateMarketSettings) "   [disabled]="!addNewCommisionsForm.valid || !isValid" mat-raised-button
			color="warn">{{'SAVE' | translate }} </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>  


	</mat-dialog-actions>
</form>