import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission'; 
import { SignalRService } from '../../../service/signalr/signalr.service'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { OrderDto } from 'app/models/order';
import { DatePipe } from '@angular/common';
import { OrderManagementApi } from 'app/order-management/api/order-management.api';
import { GoldMarketApi } from 'app/gold-market-management/api/gold-market.api';
import { State } from '@progress/kendo-data-query';
import { GoldOrderComponent } from '../gold-order/gold-order.component';
 
@Component({
  selector: 'ms-gold-order-list',
  templateUrl: './gold-order-list.component.html',
  styleUrls: ['./gold-order-list.component.scss']
})
export class GoldOrderListComponent   extends AppComponentBase implements OnInit {


  items: OrderDto[];
 
  brokerID: any;
  companyID: any;
  orderStatus: any;
  orderStatusList: any;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 50
  };

  constructor(
    private datePipe: DatePipe,private readonly signalrService: SignalRService,
    
    private dialog: MatDialog,
    private setupApi: SetupApi,
    private goldMarketApi:GoldMarketApi 
    , injector: Injector
  ) {
    super(injector);


 
    signalrService.goldOrderChanges.subscribe(item => { 
      let orders = JSON.parse(item);
 
      orders.forEach((element, index) => {

        if (this.items != null) {
         
          if ((this.items.filter(x => x.OrderID === orders[index].OrderID).length > 0)) {
         
            this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderStatus
              = orders[index].OrderStatus;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderQty
              = orders[index].OrderQty;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].RemainingQty
              = orders[index].RemainingQty;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderPrice
              = orders[index].OrderPrice;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].MinimumFill
              = orders[index].MinimumFill;
            // this.items[index].isUpdate = true;
          } else {
            // this.items[index].isUpdate = false;
             this.items = [element, ...this.items];
             this.items= this.items .slice(0, this.gridState.take);
             this.items.sort(function(a, b) {
              return b.OrderID - a.OrderID;
            });
          }
        }


      });   });


  }



  getOrderWatch() {
  
 
    var date = this.datePipe.transform(new Date(), "dd-MM-yyyyy");
  
    if (this.isBroker()) {
      this.  getUserBrokerInfo().subscribe(res => {
 
        if (res  != null  ) {
          this.companyID = res.CompanyId;
          this.brokerID = res.BrokerId; 
          this.goldMarketApi.getMyOrdersbyDate(date, date,   this.companyID,
            this.gridState.skip, this.gridState.take
          ).subscribe(items => { this.items = items; });
        }
      })


    } else {

      this.goldMarketApi.getOrdersbyDate(date, date,
         this.gridState.skip, this.gridState.take
        ).subscribe(items => {  this.items = items;  });
    }
 

  } 

  ngOnInit() {
    this.getOrderWatch();

  }
 
  createOrder() {
 
    const editingDialog = this.dialog.open(GoldOrderComponent, {
      data: {  }
    });
    editingDialog.afterClosed().subscribe((res) => { });


  }


}



