<form   class="popup-card-width add-new-user">

     <mat-dialog-content>
          <div class="gene-card-title">
               <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column">
                         <h4 class="mrgn-b-none">{{title}}</h4>
                    </div>

               </div>
               <mat-divider></mat-divider>
          </div>
          <div class="gene-card-content">
               <div class="trade-crypto crypto-input-prefix">
                    <div class="pad-t-sm">

                         <div class="padding-input-form">



                              <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewClientForm">
                                   <div fxLayout="column">
                                      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                             
                                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"
                                        fxFlex.gt-md="100"  *ngIf="!isBroker()">
								<mat-form-field appearance="outline" style="font-size: small;">
									<mat-label>{{ 'Company' | translate }}</mat-label>
                                             <mat-select  [(ngModel)]="clientCompany.CompanyId"  name="CompanyId"
                                             formControlName="CompanyId" 
                                            >  
                                             <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                             {{company.Name}}</mat-option>
                                        </mat-select> 
								</mat-form-field>
                                       </div>

                                    
                                        <!--  -->
                                       <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"
                                        fxFlex.gt-md="100" 
                                        >
								<mat-form-field appearance="outline" style="font-size: small;">
									<mat-label>{{ 'Client' | translate }}</mat-label>
									<mat-select [disabled]='isUpdate' required formControlName="ClientId"
										name="ClientID" [(ngModel)]="clientCompany.ClientId"
										[placeholder]="'Client' | translate ">
										<mat-label>{{ 'Client' | translate }}</mat-label>
										<mat-option>
											<ngx-mat-select-search name="itemNameseaRCHb"
												formControlName="itemNameseaRCHb"
												placeholderLabel="{{translate('Enter At Least One Chars To Search')}}"
												noEntriesFoundLabel="{{translate('No data found')}}" ngModel
												(ngModelChange)="filterclients($event)">
											</ngx-mat-select-search>
										</mat-option>
										<mat-option *ngFor="let client of FilterClients" [value]="client.Id">
											{{isRtl()?client.NameAr:client.NameEn}}</mat-option>
									</mat-select>
								</mat-form-field>
                                       </div>
              
                                         <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
                                            <mat-form-field class="full-wid form-field" appearance="outline">
                                               <mat-label>{{ 'Remark' | translate }} </mat-label>
                                               <textarea matInput placeholder="Remark" formControlName="Remark"
                                                  [(ngModel)]="clientCompany.Remark"      ></textarea>
                                            </mat-form-field>
                                         </div>
                                         
              
 
               
                                      </div> 
                                   </div>
                                </form>


                         </div>

                    </div>
               </div>
          </div>
          <!-- (click)="dialogRef.close()" -->
     </mat-dialog-content>
     <mat-dialog-actions class="padding-input-form pad-b-none">
          <button (click)="onFormSubmit()" type="submit" 
          *ngIf="	auth.isGranted(_permissions.EditClients) "
          [disabled]="!addNewClientForm.valid" mat-raised-button
               color="warn">{{ 'SUBMIT' | translate }} </button>
          <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>

     </mat-dialog-actions>
</form>