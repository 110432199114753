import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';  
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RoutingModule } from "./app-routing.module";
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { AuthService } from './service/auth-service/auth.service';
import { PageTitleService } from './core/page-title/page-title.service';
import { DashboardParamService } from './core/page-title/dashboard.param.service';

import { CommTradeAppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { AuthGuard } from './core/guards/auth.guard';
import { HorizontalMenuItems } from './core/menu/horizontal-menu-items/horizontal-menu-items';

import { HorizontalLayoutComponent } from './horizontal-layout/horizontal-layout.component';

import { SideBarComponent } from './Shared/components/side-bar/side-bar.component';
import { FooterComponent } from './Shared/components/footer/footer.component';
import { CoreModule } from './core/core.module';
import { AccountModule } from './account/account.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserIdleModule } from "angular-user-idle";
import { IdleService } from './core/userIdle.service';
import { AppInitService } from './app-init.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrokerManagementModule } from './broker-management/broker-management.module';
import { CompanyManagementModule } from '../app/company-management/company-management.module';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DatePipe } from '@angular/common';
import { SetupApi } from 'app/Shared/api/setup.api';
import { EnumHelpers } from './helper/EnumHelpers'; 
import { EmxManagementModule } from './emx-management/emx-management.module';
import { MarketManagmentModule } from './market-managment/market-managment.module';
import { ClientManagementModule } from './client-managment/client-management.module';
import { WarehouseManagmentModule } from './warehouse-managment/warehouse-managment.module';
import { SymbolManagmentModule } from './symbol-managment/symbol-managment.module';
import { BankManagementModule } from './bank-management/bank-management.module';
import { CommissionManagementModule } from './commission-management/commission-management.module';
import { MonitorServicesModule } from './monitor-services/monitor-services.module';
import { AdminModule } from './admin/admin.module';
import { WidgetComponentModule } from './widget-component/widget-component.module';
import { TreeModule } from '@circlon/angular-tree-component';
import { AccountInterceptor } from './account/Account.interceptor';
import { authStrategyProvider } from './account/services/auth.strategy';
import { HttpErrorInterceptor } from './core/error.interceptor';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { AuctionManagementModule } from './auction-management/auction-management.module';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { OrderManagementModule } from './order-management/order-management.module';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoService } from 'app/service/kendo/kendo.service';
import { GoldMarketManagementModule } from './gold-market-management/gold-market-management.module';
import{OperationManagmentModule} from './operation-managment/operation-managment.module';
import { SettlementManagementModule } from './settlement-managment/settlement-managment.module';
import { Helpers } from './helper/helpers';

// import { IconsModule } from "@progress/kendo-angular-icons";

// AoT requires an exported function for factories
 
export function initializeApp1(appInitService: AppInitService) {
	return (): Promise<any> => {
		return appInitService.Init();
	}
}
export function HttpLoaderFactory(http: HttpClient) {
   // console.log("translate loader running..");

    return new TranslateHttpLoader(http, "/assets/i18n/", ".json");
}
 
@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient], 
			} 
		}),
		FormsModule,
		
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		Ng5BreadcrumbModule.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		TourMatMenuModule.forRoot(),
		PerfectScrollbarModule,
		MenuToggleModule,
		HttpClientModule,
		CoreModule,
		AccountModule,
		DashboardModule,
		CompanyManagementModule,
		BrokerManagementModule,
		MarketManagmentModule,
		WarehouseManagmentModule,
		SymbolManagmentModule,
		ClientManagementModule,
		MonitorServicesModule,
		OperationManagmentModule,
		BankManagementModule,
		AdminModule,
		GoldMarketManagementModule,
		EmxManagementModule,
		CommissionManagementModule,
		SettlementManagementModule,
		UserIdleModule,
		MatSlideToggleModule,
		WidgetComponentModule,


		AngularFireAuthModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		MatSidenavModule,
		MatTabsModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatSliderModule,
		MatRadioModule,
		MatDialogModule,
		MatGridListModule,
		ToastrModule.forRoot(),
		LoadingBarRouterModule,
		LoadingBarRouterModule,
		CommonModule,
		MatInputModule,
		NgxMatSelectSearchModule,
		TreeModule,
		LayoutModule,
		AuctionManagementModule,
		OrderManagementModule,


	],
	declarations: [
		CommTradeAppComponent,
		MainComponent,
		HorizontalLayoutComponent, SideBarComponent, FooterComponent,
	],

	bootstrap: [CommTradeAppComponent],
	providers: [
		DatePipe,
		IdleService,
		AppInitService
		, {
			provide: APP_INITIALIZER
			, useFactory: initializeApp1,
			deps: [AppInitService],
			multi: true
		},

		MenuItems,
		HorizontalMenuItems,
		BreadcrumbService,
		PageTitleService,
		DashboardParamService,
		SetupApi,
		AuthService,
		EnumHelpers,
		Helpers,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true
		}
	  , { provide: MAT_DATE_LOCALE, useValue: 'en' },
		, { provide: MessageService, useClass: KendoService },
		AuthGuard,

	],

	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommTradeAppModule { }
