import { Component, ElementRef, Inject, Injector, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { Helpers } from 'app/helper/helpers';
import { SetupApi } from 'app/Shared/api/setup.api';


@Component({
  selector: 'ms-auction-statistics-dialog',
  templateUrl: './auction-statistics-dialog.component.html',
  styleUrls: ['./auction-statistics-dialog.component.scss']
})

export class AuctionStatisticsDialogComponent extends AppComponentBase implements OnInit {

  errors: any;
  title:any;
  statisticsData: any;
  openDate: any;
  closeDate: any;



  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private setupApi: SetupApi, 
    private  dialogRef: MatDialogRef<AuctionStatisticsDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any, 
    private _helpers:Helpers , injector: Injector) {
     
      super(injector);
      if (data != null && data.data != null) {
        this.statisticsData=data.data;
        this.title="Auction statistics # " + this.statisticsData.AuctionId;
        if(data.openDate != null && data.closeDate != null) {
          this.openDate = data.openDate;
          this.closeDate = data.closeDate;
        }
      } 
      else
      {
        this.title="Auction statistics";
      }
  }

  ngOnInit(): void {}

  close() {
 
    this.dialogRef.close(false);
  }

}
