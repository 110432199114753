<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
 
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.lg="100" fxFlex.xl="100">
			   <div fxLayout="row wrap">
				<div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 400px)" fxFlex.lg="calc(100% - 400px)" fxFlex.xl="calc(100% - 400px)">
					<h4> {{ title | translate }}</h4>
				</div>
				<div fxFlex.gt-sm="80" fxFlex.gt-xs="80" fxFlex="80" fxFlex.gt-md="80">

				  <mat-form-field class="width50" floatLabel="never">
					  <mat-label>{{translate('Search')}}</mat-label>
					  <input class="form-control" matInput  placeholder="{{translate('Search')}}" [(ngModel)]="filterOptions.GeneralFilter" (input)="filterOptions.GeneralFilter=$event.target.value" >
				  </mat-form-field>
  
				  <button mat-button color="primary" (click)="onStateChange()"><mat-icon>search</mat-icon>
				   </button>
				   <button mat-button color="primary" (click)="clear()"><mat-icon>clear</mat-icon>
				   </button>
			  </div>
				<div class="contact-btn" fxFlex.xs="20" fxFlex.sm="20" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20"> 

					<button mat-raised-button mat-button-sm color="primary" 
					     (click)="addNewCompanyDialog()"
						 *ngIf="auth.isGranted(_permissions.AddCompanies)" >
						{{ 'ADD NEW COMPANY' | translate }} <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</div>
			   </div> 
			</div>

		 </div>	
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
				  #grid
 
				  [data]="view | async"
				  [pageSize]="state.take"
				  [skip]="state.skip"
				  [sort]="state.sort" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list"  
				  [pageable]="true"
			   
				  (dataStateChange)="dataStateChange($event)"
				  >  
				  <kendo-grid-column   title="	{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="Code" title="{{ 'Company Code' | translate }}" headerClass="grid-list-column-header"   > </kendo-grid-column>
				  <kendo-grid-column field="Name" title="{{ 'Company Name' | translate }}" headerClass="grid-list-column-header" >
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        
						<span  *ngIf="isRtl()"  > {{dataItem.NameAr}}  </span>
						<span  *ngIf="!isRtl()"   > {{dataItem.Name}}  </span>
				  
					  </ng-template>
				
				</kendo-grid-column>

				  <kendo-grid-column field="CreatedDate" title="{{ 'CreateDate' | translate }} "
				   headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'dd/MM/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>
				  
				  <kendo-grid-column   title=""  width="100"
				   headerClass="grid-list-column-header"
				   
				   >
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
					   aria-label="Open anchor menu" 
					 
					  *ngIf="!dataItem.IsDeleted">
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item 
					
							(click)="editCompany(dataItem)" 
							 >
								{{ 'View' | translate }}
							</a>
							<a mat-menu-item   (click)="deleteCompany(dataItem)"
							   *ngIf="auth.isGranted(_permissions.DeleteCompanies)" >
								{{ 'Delete' | translate }}
							  </a>
						 
							<a mat-menu-item  
							[routerLink]="['/companies/broker-companies/'+dataItem.Id]"   
							
							*ngIf="auth.isGranted(_permissions.AssignBrokerToCompanies)">
							   {{ 'Users' | translate }}
							</a>
							<a mat-menu-item   
							(click)="changePropertiesStatus(dataItem)"
							*ngIf="auth.isGranted(_permissions.CompanyChangeStatus)">
							{{ 'Change Status' | translate }} 
					   </a>
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>