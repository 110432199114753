export class StoreSymbolDto {
    StoreSymbolID: number;
    StoreID: number;
    SymbolId: number;
}
export class StoreDto {
    StoreID: number;
    StoreNameEn: string;
    StoreNameAr: string;
    StoreCode: string;
    Location: string;
    Suspended: boolean;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
    CityID: number;
    TotalArea: number;
    Website: string;
    Email: string;
    Telephone: string;
    TradeTypeID: number;
    StoreSections: StoreSectionDto[];
    StoreSymbols: StoreSymbolDto[];
    StoreClients: StoreClientDto[];
     
}
export class StoreSectionDto {
    SectionID: number;
    StoreID: number;
    SectionNameEn: string
    SectionnameAr: string
    StoreTypeID: number
    SectionArea: number
    IsDeleted: boolean;

}

export class StoreTypeDto {
    StoreTypeID: number
    StoreTypeNameEn: string
    StoreTypeNameAr: string
}

export class StoreClientDto{
    StoreClientID: number;
    StoreID: number;
    ClientID: number
    SectionID: number
    ContractNo: string
    ContractStartDate: Date
    ContractEndDate: Date;
    Area: number;
    ContractStatus: number;
}


export class StoreClientViewDto {
    StoreNameEn: string;
    StoreNameAr: string;
    Location: string;
    Suspended: boolean;
    CityID: number;
    Telephone: string;
    Email: string;
    Website: string;
    TotalArea?: any;
    StoreID: number;
    ClientID: number;
    SectionID: number;
    ContractStartDate: Date;
    ContractEndDate: Date;
    Area: number;
    ContractStatus?: any;
    SectorNameEn: string;
    SectorNameAr: string;
    CategoryID: number;
}