<div class="gene-login gene-session pad-y-xl">
   <div fxLayout="row" fxLayoutAlign="center start">
      <div fxLayout="column" fxFlex.xl="540px" fxFlex.lg="540px" fxFlex.md="540px" fxFlex.sm="540px" fxFlex.xs="100">
         <mat-card class="gene-login-wrapper">
            <div>
               <div fxLayout="column" fxLayoutAlign="center center mrgn-b-md">
                  <div class="login-logo justify-content">
                     <img src="assets/img/logo.jpeg"> 
                  </div>
               </div>
               <form   [formGroup]="loginForm">
               <div fxLayout="column" fxLayoutAlign="start stretch">


                  <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput id="email" placeholder="{{ 'Username' | translate }} " autocomplete="off" formControlName="email" required>
                   </mat-form-field>
                 
                   <mat-form-field class="full-wid mrgn-b-md">
                     <input matInput [type]="isPasswordVisible? 'text':'password'" id="password" placeholder="{{ 'Password' | translate }} " autocomplete="off" formControlName="password" required>
                        <mat-icon class="pw-reveal-icon" matSuffix (click)="isPasswordVisible = !isPasswordVisible" *ngIf="!isPasswordVisible">visibility</mat-icon>
                        <mat-icon class="pw-reveal-icon" matSuffix (click)="isPasswordVisible = !isPasswordVisible" *ngIf="isPasswordVisible">visibility_off</mat-icon>
                   </mat-form-field>




                  <div fxLayout="row" fxLayoutAlign="start center">
                     <div fxLayout="column">
                        <mat-checkbox class="remember-me" formControlName="rememberMe" id="rememberMe" >{{ 'Remember Me' | translate }}</mat-checkbox>
                     </div>
                     <span fxFlex></span>
                     <div><a [routerLink]="['/account/forgot-password']">{{ 'Forgot Password?' | translate }}</a></div>
                  </div>
                  <button class="success-bg" mat-raised-button [disabled]="!loginForm.valid" (click)="login()">{{ 'SIGN IN' | translate }}</button>
                  <p class="text-center"> {{ 'Select Language' | translate }} 
                     <ms-language-drop-down   ></ms-language-drop-down>
                  </p>
               </div>
               </form>
            </div>
         </mat-card>
      </div>
   </div>
</div>