<kendo-grid [data]="storeSectionsList" [resizable]="true" [reorderable]="true" [filterable]="false"
	class="kendo-grid-list">
	<kendo-grid-column title="Id" headerClass="grid-list-column-header" width="50">
		<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
			{{(rowIndex+1 )}}
		</ng-template>
	</kendo-grid-column>
	<kendo-grid-column field="SectionNameEn" title="{{translate('Name English')}}"
		headerClass="grid-list-column-header"> </kendo-grid-column>
	<kendo-grid-column field="SectionnameAr" title="{{translate('Name Arabic')}}" headerClass="grid-list-column-header">
	</kendo-grid-column>
	<kendo-grid-column field="StoreTypeId" title="{{translate('Warehouse Type')}}"
		headerClass="grid-list-column-header">
		<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
			<span *ngIf="dataItem.StoreType!=null">{{dataItem.StoreType.StoreTypeNameEn}}</span>
		</ng-template>
	</kendo-grid-column>

	<kendo-grid-column field="SectionArea" title="{{translate('Area')}}" headerClass="grid-list-column-header">

	</kendo-grid-column>

	<kendo-grid-column title="" width="100" headerClass="grid-list-column-header">

		<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
			<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu">
				<mat-icon>menu</mat-icon>
			</button>
			<mat-menu #anchorMenu="matMenu">
				<a mat-menu-item (click)="editStoreSection(dataItem)"
					*ngIf="auth.isGranted(_permissions.AddStoreSection)">
					{{translate('Edit')}}
				</a>
				<a mat-menu-item (click)="deleteStoreSection(dataItem)"
					*ngIf="auth.isGranted(_permissions.RemoveStoreSection)">
					{{translate('Delete')}}
				</a>

			</mat-menu>

		</ng-template>

	</kendo-grid-column>
</kendo-grid>

<br />

<div fxLayout="row" fxLayoutAlign="start">

	<button *ngIf="auth.isGranted(_permissions.AddStoreSection)" (click)="addStoreSection()" mat-raised-button
		color="warn">{{translate('ADD Section')}}</button>

	<a [routerLink]="['/warehouses/warehouses/']" mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

</div>