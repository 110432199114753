import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  

@Injectable()
export class StoreSectionApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}


AddOrupdateStoreSection(StoreSection: any,id :any): Observable<boolean> {

return this.http.put<any>(`${this.API_URL}/AddStoreSection/`+id, 
StoreSection)
  .pipe(tap(data => {}));
}

deleteStoreSection(storeId: number,sectionId:number): Observable<boolean> {

return this.http.delete<any>(`${this.API_URL}/deleteStore/`+storeId+'/'+sectionId).pipe(tap(data => {}));
}



}