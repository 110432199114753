
 <form [formGroup]="addNewOrderForm" class=""> 
  <div class="gene-card-title">
     <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
           <h5 class="mrgn-b-none">{{'CreateOrder-title' | translate}}</h5>
        </div>


        
     </div>
     <mat-divider></mat-divider>
  </div>
  <div class="order-card-content gene-card-content">
 
   
      <div class="gene-card-content">
         <div class="trade-crypto crypto-input-prefix">
            <div class="pad-t-sm">
               <div class="padding-input-form ">
                  <div class=' width100 '>

                     <mat-button-toggle-group 
                        name="fontStyle" (change)="onChangeOrderType($event)" [(ngModel)]="order.OrderType"
                        name="orderType" aria-label="Font Style" formControlName="orderType"
                        class="form-control">
                        <mat-button-toggle   value="1"
                           [class.buy]="order.OrderType == 1" class="form-control">
                           {{this.translate('TranactionBuy')}}
                        </mat-button-toggle>
                        <mat-button-toggle value="2" class="form-control"
                           [class.sell]="order.OrderType == 2">
                           {{this.translate('TranactionSell')}}</mat-button-toggle>

                     </mat-button-toggle-group>

                  </div>
               </div>
               <div>
                  <mat-form-field class="full-wid" appearance="outline">
							<mat-label>{{ 'Symbol' | translate }} </mat-label>

							<mat-select formControlName="symbol" [(ngModel)]="order.SymbolID"
								name="symbol"
                        (selectionChange)="onChangeSymbol($event.value)" >
								<mat-option *ngFor="let symbol of symbols"
									[value]="symbol.SymbolId">
                           <span *ngIf="isRtl()">{{symbol.SymbolNameAr}}</span>
                           <span *ngIf="!isRtl()">{{symbol.SymbolNameEn}}</span>
                        </mat-option>
							</mat-select>
                  </mat-form-field>
               </div>
               <div>
                  <mat-form-field class="full-wid" appearance="outline">
							<mat-label>{{ 'Clients' | translate }} </mat-label>

							<mat-select formControlName="client" [(ngModel)]="order.ClientID"
								name="client">
								<mat-option *ngFor="let client of clients"
									[value]="client.Id">
                           <span  >{{client.NameEn}}</span> 
                        </mat-option>
							</mat-select>
                  </mat-form-field>
               </div>
               <div class="padding-input-form ">
                  <div class=' width100 '>

                     <mat-button-toggle-group 
                        name="calcMethod" (change)="onChangeCalcMethod($event)" [(ngModel)]="order.CalcMethod"
                        name="calcMethod" aria-label="calc Method" formControlName="calcMethod"
                        class="form-control">
                        <mat-button-toggle   value="Q" class="form-control">
                           {{this.translate('Qty')}}
                        </mat-button-toggle>
                        <mat-button-toggle value="V" class="form-control">
                           {{this.translate('Value')}}</mat-button-toggle>

                     </mat-button-toggle-group>

                  </div>
               </div>
               <div fxLayout="row wrap" fxLayoutAlign="space-between center"> 
               <div *ngIf="this.order.CalcMethod==this.CalcMethodEnum.quantity"
               fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                  <mat-form-field class="full-wid" appearance="outline">
                     <mat-label> {{this.translate('Qty')}}</mat-label>
                     <input   [(ngModel)]="order.OrderQty"
                     formControlName="qty" class="form-control" matInput type="number" placeholder="{{this.translate('Qty')}}">
                  </mat-form-field>
               </div>
               <div *ngIf="this.order.CalcMethod==this.CalcMethodEnum.value"
               fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                  <mat-form-field class="full-wid" appearance="outline">
                     <mat-label> {{this.translate('Value')}}</mat-label>
                     <input  
                     formControlName="value"  [(ngModel)]="order.OrderValue" class="form-control" matInput type="number" placeholder="{{this.translate('Value')}}" >
                  </mat-form-field>
               </div>
               <div  fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                  <mat-form-field class="full-wid" appearance="outline">
                     <mat-label> {{this.translate('Price')}}</mat-label>
                     <input  
                     formControlName="price"  [readonly]="true" 
                     [(ngModel)]="order.IndicativePrice" class="form-control" matInput type="number" 
                        placeholder="{{this.translate('Price')}}"  >
                  </mat-form-field>
               </div>

               </div>
              <div class="button-wrap">
                 <button 
                    (click)="onBasicFormSubmit()" [disabled]="(!addNewOrderForm.valid)"   type="submit" mat-raised-button color="warn"
                              >{{ 'SUBMIT' | translate }}  </button>

                   <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>

              </div>
            </div>
         </div>
  
         
     </div>
  </div>

</form>