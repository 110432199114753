export class paramsHelper{
    public static convertObjectToQueryParameters(request):string
{
  let queryParameters:string="";
  for(let property in request){
    if(request[property]!=null)
      queryParameters += queryParameters!=""? "&" +property+"="+request[property]:"?"+ property+"="+request[property];
  } 
  if(queryParameters=="")
    return "";
return queryParameters
}


}