<div class="user-manage-list " >
	<mat-card> 
		<div class="gene-card-title">		
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
			   <div fxLayout="row" fxLayoutAlign="start center">				
				  <div class="mrgn-l-sm mrgn-r-sm">
						<h4>{{title | translate }}</h4>
				  </div>
			   </div>
			   <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >

			   </div>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutAlign="start center">				
                    <div class="trade-crypto crypto-input-prefix">
                        <div class="padding-input-form">

                          <mat-form-field class="padding10 form-field"  appearance="outline"  *ngIf="!this.isBroker()" >
                            <mat-label>{{ 'Trading Company' | translate }}</mat-label>
                              <mat-select 							        
                                  [(ngModel)]="filterOptions.CompanyId"
                                  name="CompanyID" 
                                  class="filter-select" 
                                  [placeholder]="'Company' | translate "
                                  (selectionChange)="onChangeCompany($event);">
                                  <mat-label>{{ 'Company' | translate }}</mat-label>	
                              <mat-option>								
                                <ngx-mat-select-search name="companySearch" 
                                  placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                  noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                               >
                                </ngx-mat-select-search>
                                <ngx-mat-select-filter></ngx-mat-select-filter>
                              </mat-option>
                                <mat-option >	{{ 'All' | translate }} </mat-option>
                                  <mat-option  *ngFor="let company of  companies" [value]="company.Id">
                                    {{isRtl()?company.NameAr:company.Name}}
                                </mat-option>
                              </mat-select> 
                          </mat-form-field>
                    
                            <mat-form-field appearance="outline"  class="padding10 form-field">
                               <mat-label>{{ 'Client' | translate }}</mat-label>
                                <mat-select    
                                   [(ngModel)]="filterOptions.ClientId" 
                                    name="ClientID"	 
                                    class="filter-select"
                                   [placeholder]="'Client' | translate "
                                 >
                                  <mat-label>{{ 'Client' | translate }}</mat-label>								
                                  <mat-option>								
                                    <ngx-mat-select-search name="clientSearch" 
                                      placeholderLabel="{{translate('Enter At Least 3 Characters')}}"
                                      noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                      (ngModelChange)="changeClientFilter($event)">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                    <mat-option > {{ 'All' | translate }} </mat-option>
                                      <mat-option *ngFor="let client of clients" [value]="client.Id">
                                        {{isRtl()?client.NameAr:client.NameEn}}
                                      </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                    </div>
                </div>
                <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" >
                  <mat-card-actions class="mat-pad-none margin-none">
                    <a mat-raised-button color="" (click)="getList()" title=" {{ 'Search' | translate }}" > <mat-icon  >search</mat-icon></a>	
                  </mat-card-actions>
                </div>
             </div>
 
	

		 </div>
        </mat-card> 
        <mat-card> 

			<br/>
	<kendo-grid   #grid
      [data]="view | async"
	  [filterable]="false" 
	  [resizable]="true"
	  [reorderable]="true"
	  filterable="menu"  
	  [sortable]="true" 
	  [kendoGridSelectBy]="selectBy"
	  [columnMenu]="true"
    class="market-kendo-grid"
	[rowClass]="rowCallback"

	[pageSize]="state.take"
	[skip]="state.skip"
	[sort]="state.sort"
 
	[pageable]="true"
 
	(dataStateChange)="dataStateChange($event)" > 
  <kendo-grid-column   title="{{ 'ID' | translate }}  " 
  [class]="{ codeColumn: true }"
  [columnMenu]="false" headerClass="grid-list-column-header" width="40" >

 <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <span> {{(rowIndex+1)}}</span>	
 </ng-template>
  </kendo-grid-column>
  <ng-template kendoGridToolbarTemplate>
   <button type="button" kendoGridExcelCommand icon="file-excel"> {{'Export to Excel'|translate}}	</button>
 </ng-template>
 <kendo-grid-excel fileName="UnSettled Trades.xlsx" > </kendo-grid-excel>


 <kendo-grid-column field="TradeID"  title="{{ 'Trade ID' | translate }} " headerClass="grid-list-column-header" width="100" >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
         <span >{{dataItem.TradeID}} </span>
  </ng-template>
  <ng-template kendoGridHeaderTemplate   let-column>  {{ 'Trade ID'| translate }} </ng-template>
</kendo-grid-column>



 <kendo-grid-column field="TradeDate" title="{{ 'Date' | translate }}" headerClass="grid-list-column-header">
  <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.TradeDate | date: 'dd/MM/yyyy'}}
  </ng-template>
</kendo-grid-column>


        <kendo-grid-column field="TradeDate" title="{{ 'Time' | translate }}" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.TradeDate | date: 'h:mm:ss a'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="SymbolCode" title="{{ 'Symbol' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>

        <kendo-grid-column field="BuyCompanyCode" title="{{ 'Buy Company Code' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>
        <kendo-grid-column field="SellCompanyCode" title="{{ 'Sell Company Code' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>

        <kendo-grid-column field="BuyCompanyNameAr" title="{{ 'Buy Company Name' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>
        <kendo-grid-column field="SellCompanyNameAr" title="{{ 'Sell Company Name' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>

        <kendo-grid-column field="BuyClientCode" title="{{ 'Buy Client Code' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>
        <kendo-grid-column field="SellClientCode" title="{{ 'Sell Client Code' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>

        <kendo-grid-column field="BuyClientNameAr" title="{{ 'Buy Client Name' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>
        <kendo-grid-column field="SellClientNameAr" title="{{ 'Sell Client Name' | translate }}" headerClass="grid-list-column-header"></kendo-grid-column>



        <!-- <kendo-grid-column field="TradeDate" title="Date"></kendo-grid-column>  -->
        <kendo-grid-column field="TradedQty" title="{{ 'Qty' | translate }}" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{dataItem.TradedQty | number }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="TradePrice" title="{{ 'Price' | translate }}" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{dataItem.TradePrice | number }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="TradePrice" title="{{ 'Rated Price' | translate }}" headerClass="grid-list-column-header">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span> {{(dataItem.TradePrice  *exChangeRate)| number }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column   title=" " 
        [columnMenu]="false"
         width="80" headerClass="grid-list-column-header">
  
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
          <button mat-icon-button [matMenuTriggerFor]="anchorMenu"
           aria-label="Open anchor menu"
             >
          <mat-icon>menu</mat-icon>
          </button>
           <mat-menu #anchorMenu="matMenu">
            <a mat-menu-item    (click)="approveSettlement(dataItem)"   >  {{ 'Approve Settlement' | translate }} </a>
            <a mat-menu-item     (click)="cancelSettlement(dataItem)"  >   {{ 'Cancel Settlement' | translate }}  </a>

          </mat-menu>
         
          </ng-template>
         
         </kendo-grid-column>
      </kendo-grid>

	</mat-card>
</div>