<div fxLayout="row wrap" fxLayoutAlign="start stretch" class="crm-stats-cards" >
    <div class="crm-stats" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
       <div fxLayout="row wrap" fxLayoutAlign="start start">
          <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
             <mat-card class="dashboard-color mat-card">
                <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                  <a   class="text_center"  >
                     <span class="inline-block lead" class='icon-card'>
                        <i class="fa fa-pie-chart" aria-hidden="true"></i>
                     </span>
                     <span class="lead inline-block">Dashboard</span>
                  </a> 
                </div>
             </mat-card>
          </div>
       </div>
       <div fxLayout="row wrap" fxLayoutAlign="start start"> 
          <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="client-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/clients/clients/2' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-user-plus" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">Add Client</span>
                 </a> 
               </div>
            </mat-card>
         </div>

         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="client-color  mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/client_position' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-briefcase" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">Client Portfolio</span>
                 </a> 
               </div>
            </mat-card>
         </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start">
         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="operation-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/deposit/' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-sign-in" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block"> 	  Deposit </span>
                 </a> 
               </div>
            </mat-card>
         </div>

         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="operation-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/withdraw/' ]"  class="text_center" >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">  Withdraw  </span>
                 </a> 
               </div>
            </mat-card>
         </div>
        
         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="operation-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/deleption/' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">  Depletion </span>
                 </a> 
               </div>
            </mat-card>
         </div>
         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="operation-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/transfer/' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-exchange" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">  Transfer</span>
                 </a> 
               </div>
            </mat-card>
         </div>
         </div>
         <div fxLayout="row wrap" fxLayoutAlign="start start">
         <div fxFlex.gt-md="20" fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class="warehouse-color mat-card">
               <div class="mrgn-b-xs" mat-card-widget fxLayout="row" fxLayoutAlign="start center">
                 <a [routerLink]="['/warehouses/symbol-qantity/' ]" class="text_center"  >
                    <span class="inline-block lead" class='icon-card'>
                     <i class="fa fa-home" aria-hidden="true"></i>
                    </span>
                    <span class="lead inline-block">Warehouse Balances</span>
                 </a> 
               </div>
            </mat-card>
         </div>

       </div>
    </div>
    <!--Crm stats cards -->
 </div>