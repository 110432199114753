import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { AppComponentBase } from 'app/app-component-base';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { StatusEnum } from 'app/core/enums';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ToastrService } from 'ngx-toastr';
import{UserLoginDto, UserReuqest} from '../../../models/user';
import { UserFormDialogComponent } from '../user-form-dialog/user-form-dialog.component';
@Component({
  selector: 'ms-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent  extends AppComponentBase  implements OnInit {

  items: UserLoginDto [] ;
  allItems: UserLoginDto [] ;
  title:string;
  dialogRef: MatDialogRef<DemoDialog>;
  filteredCompanies:any;
  filteredRoles:any;

  dataSources = {companies: [],
    roles:[],
    status:[]
  };

  filterOptions:UserReuqest = new UserReuqest();
 
  constructor(
    private dialog: MatDialog  , 
    private brokerManagementService :BrokerManagementService ,
    private _helper :EnumHelpers   ,
    private _setupApi:SetupApi,
    private pageTitleService : PageTitleService ,
   
    private adminService :AdminService , injector: Injector
    ) {
      super(injector); } 

  ngOnInit(): void {
    setTimeout(() =>{ 
      this.pageTitleService.setTitle("Users Management");
      
     },0);
     this.title="Users";
     this.  getList();
     this._setupApi.getActiveCompanies().subscribe(res=>{ this.dataSources.companies = res; })
 
     this.adminService.getRoles().subscribe(res=>{ this.dataSources.roles = res; })
  }

  clearFilter(){
    this.filterOptions=new UserReuqest();
    this.getList( );
    this.filterOptions.GeneralFilter=null;
  }
  getList( ) { 
      this.filterOptions.Take = 10000;
   
     if(this.filterOptions.GeneralFilter!=null){
      this.adminService.searchUsers(this.filterOptions.GeneralFilter).subscribe(items => {
        this.items = items;
        this.allItems=items;
      }); 
     }else{
      this.adminService.getUsers( ).subscribe(items => {
        this.items = items;
        this.allItems=items;
      }); 
     }
  

  }
  onStateChange(){
    if(this.filterOptions.GeneralFilter != null && this.filterOptions.GeneralFilter!=""
    && this.filterOptions.GeneralFilter.length>3 ){
      
 
         this.getList();
    }
  }
  getUserCompany(){

    if(this.filterOptions.CompanyId!=null){

      this. brokerManagementService.getAllBrokerCompanies(this.filterOptions.CompanyId).subscribe(res=>{
        var brokers=[];
        res.forEach(element => {
     
          var item=this.allItems.filter(x=>x.id==element.UserId);
          if(item.length>0){
            brokers.push(item[0]);
          }
        });
        this.items= brokers;
      });
    } else{
      this.items= this.allItems;
    }
  }

  getRoleUser( ) { 
  
    if(this.filterOptions.Role!=null){

      this.filterOptions.Take = 10000;
  
      this.adminService.getUserRole(this.filterOptions.Role).subscribe(res => {
        var users=[];
        res.forEach(element => {

          var item=this.allItems.filter(x=>x.id==element.id);
          if(item.length>0){
            users.push(item[0]);
          }
        });
        this.items= users;
  
      }); 
    }   else{
      this.items= this.allItems;
    }

}
  
  addNewUserDialog(){ 
    const dialog = this.dialog.open(UserFormDialogComponent, {
           data:{ }
      });
      dialog.afterClosed().subscribe((res) => {
 
         //this.items.push(res);
         this.getUsers( );
         
      } );
  }

  editUser(user){ 
    const dialog = this.dialog.open(UserFormDialogComponent, {
        data:{user:user}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getUsers();
      } );
  }
  
  
  deleteUser(user) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this user name ' + user.userName + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.adminService.deleteUser(user.id).subscribe((res) => {
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
    //      console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }

  getUsers(){
       this.adminService.getUsers().subscribe(res=>{
            this.items=res;
  
       });
  }

  onChangeCompany(event) {

    if(this.dataSources.companies != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCompanies = this.dataSources.companies;
        else
        this.filteredCompanies = this.dataSources.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.dataSources.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onChangeRole(event) {

    if(this.dataSources.roles != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredRoles = this.dataSources.roles;
        else
        this.filteredRoles = this.dataSources.roles.filter((s) => s.name.toLowerCase().indexOf(event.toLowerCase()) !== -1 );

        }
      }
  }
} 
