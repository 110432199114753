import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
import { RoleDto } from 'app/models/role';

@Injectable()
export class AdminService {

  constructor(private http: HttpClient) { }

  getActiveSessions(): Observable<any> {
    return this.http.get<any>(`${AppConsts.authApiUrl}${AppConsts.userUrl}/getAllRefreshTokens`);
  }

  destroySession(userId: string): Observable<[]> {
    return this.http.delete<[]>(`${AppConsts.authApiUrl}${AppConsts.userUrl}/revokeToken/${userId}`);
  }
  destroyAllSession(): Observable<[]> {
    return this.http.delete<[]>(`${AppConsts.authApiUrl}${AppConsts.userUrl}/revokeAllToken`);
  }
  getRoles(): Observable<RoleDto[] > {
    return this.http.get<RoleDto[] >(`${AppConsts.authApiUrl}/api/role/get`);
  }

 
  insertRole(createRoleRequest: any): Observable<boolean> {

    return this.http.post<any>(`${AppConsts.authApiUrl}/api/role/insert`, 
    createRoleRequest)
      .pipe(tap(data => {}));
 }

 addRoleWithClaims(createRoleRequest: any): Observable<boolean> {

  return this.http.post<any>(`${AppConsts.authApiUrl}/api/roleClaims/addRoleWithClaims`, 
  createRoleRequest)
    .pipe(tap(data => {}));
}
 
 updateRole(updateRoleRequest: any ,id :any): Observable<boolean> {

     return this.http.put<any>(`${AppConsts.authApiUrl}/api/role/update/`+id, 
     updateRoleRequest)
      .pipe(tap(data => {}));
  }
  deleteRole(id: any): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.authApiUrl}/api/role/delete/`+id).pipe(tap(data => {}));
  }


  getRoleClaims(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.authApiUrl}/api/roleClaims/get/`+id);
  }


  getUser(id:any): Observable<any> {
    return this.http.get<any>(`${AppConsts.authApiUrl}/api/user/get/`+id);
  }


  
  getUsers(): Observable<[]> {
    return this.http.get<[]>(`${AppConsts.authApiUrl}/api/user/get`);
  }
  searchUsers(searchText:string): Observable<[]> {
    return this.http.get<[]>(`${AppConsts.authApiUrl}/api/user/searchUsers/`+searchText);
  }
  insertUserWithRole(createRoleRequest: any): Observable<any> {

    return this.http.post<any>(`${AppConsts.authApiUrl}/api/user/insertWithRole`, 
    createRoleRequest)
      .pipe(tap(data => {}));
 }
 

 addUserRoles(createUserRoleRequest: any): Observable<boolean> {

  return this.http.post<any>(`${AppConsts.authApiUrl}/api/userRoles/addUserRoles`, 
  createUserRoleRequest)
    .pipe(tap(data => {}));
}



 updateUser(updateRoleRequest: any ,id :any): Observable<boolean> {

     return this.http.put<any>(`${AppConsts.authApiUrl}/api/user/update/`+id, 
     updateRoleRequest)
      .pipe(tap(data => {}));
  }
  deleteUser(id: any): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.authApiUrl}/api/user/delete/`+id).pipe(tap(data => {}));
  }

  suspendUser(id: any): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.authApiUrl}/api/user/suspend/`+id).pipe(tap(data => {}));
  }

  unsuspendUser(id: any): Observable<boolean> {

    return this.http.delete<any>(`${AppConsts.authApiUrl}/api/user/unsuspend/`+id).pipe(tap(data => {}));
  }
   getAllClaims( ): Observable< any> {

    return this.http.get<any>(`${AppConsts.authApiUrl}/api/roleClaims/getAllClaims`);
  }

  addUserRole (createUserRoleRequest: any): Observable<boolean> {

    return this.http.post<any>(`${AppConsts.authApiUrl}/api/userRoles/add`, 
    createUserRoleRequest)
      .pipe(tap(data => {}));
  }
  GetRoleByUserId(id:any ): Observable< any> {

    return this.http.get<any>(`${AppConsts.authApiUrl}/api/userRoles/Get/`+id);
  }
  
  getUserRole (roleName:any){
    
    return this.http.get<any>(`${AppConsts.authApiUrl}/api/userRoles/getUserRole/`
    +roleName);
  }
  getRolesByType(type:any){
    
    return this.http.get<any>(`${AppConsts.authApiUrl}/api/userRoles/getRolesByType/`
    +type);
  }
}

 