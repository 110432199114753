import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
 

@Injectable()
export class GoldMarketApi {

  constructor(private http: HttpClient) {}


  getClients(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getClients`);
  }

  getSymbols(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getSymbols`);
  }
  getTrades(Skip :any ,Take :any): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getTrades`+
    '?Skip='+Skip +'&Take='+Take);
  }
  getMarketWatchById(id:any): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getMarketWatchById/`+id);
  }
  getMarketWatches( ): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getMarketWatches`);
  }
  getLastMarketWatches(): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.globalMarketUrl}/getLastMarketWatches`);
  }
  getOrdersbyDate(fromDate :any , toDate :any ,Skip :any ,Take :any ): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
    +'&ToDate='+toDate  +'&GoldOrderOnly=true'+'&Skip='+Skip +'&Take='+Take);
  }
  getMyOrdersbyDate(fromDate :any , toDate :any , companyId :any,Skip :any ,Take :any ): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
    +'&ToDate='+toDate  +'&GoldOrderOnly=true'+'&Skip='+Skip +'&Take='+Take
    +'&CompanyId='+companyId);
  }
  createOrder(createRequest: any): Observable<any> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts. globalMarketUrl}/insert`, createRequest)
      .pipe(tap(data => {}));
  }
  
}
