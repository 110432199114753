<form [formGroup]="addNewCompanyForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 
 
 
                       <mat-form-field class="  form-field " appearance="outline">
                          <mat-label>{{ 'Company Name English' | translate }}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{ 'Company Name English'| translate }}" 
                            formControlName="Name"  
							[readonly]='isdisable'
                            [(ngModel)]="company.Name"  >  
                      </mat-form-field>

					  <mat-form-field class="  form-field " appearance="outline">
						<mat-label>{{ 'Company Name Arabic' | translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="Company Name Arabic" 
						  formControlName="NameAr"    
						  [readonly]='isdisable'
						  [(ngModel)]="company.NameAr"  >  
					</mat-form-field>

 
					<mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Code' | translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="{{ 'Code'| translate }}" 
						  formControlName="Code"  
						  [readonly]='isdisable'
						  [(ngModel)]="company.Code"  >  
					</mat-form-field>



					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{ 'Type' | translate }}</mat-label>
						<mat-select 
						[disabled]='isUpdate' readonly='isUpdate'
						
						[(ngModel)]="company.TypeId"
						   formControlName="TypeId">
						   <mat-option *ngFor="let companyType of companyTypes" [value]="companyType.Id">
							<span *ngIf="isRtl()">{{companyType.NameAr}}</span>
							<span *ngIf="!isRtl()">{{companyType.NameEn}}</span>
						   </mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="  form-field " appearance="outline">
						<mat-label>{{ 'Email' | translate }}</mat-label>
					  <input class="form-control"
					  [readonly]='isdisable'  matInput type="input" placeholder="{{ 'Email' | translate }}" 
						  formControlName="Email"  
						  [(ngModel)]="company.Email" 
						  readonly='isdisable'
					   >  
					</mat-form-field>

                    <div *ngIf="company.TypeId==CompanyTypeEnum.B">
						<mat-form-field class="width50 form-field" appearance="outline">
							<mat-label>{{ 'Identity Type' | translate }}</mat-label>
							<mat-select name="" [(ngModel)]="company.IdentityTypeID"
							   formControlName="IdentityTypeID"
							   [disabled]='isdisable' readonly='isdisable'>
							   <mat-option *ngFor="let identityType of identityTypeList" [value]="identityType.Id">
				  
								  <span 	*ngIf="!isRtl()">{{identityType.IdentityTypeEn}}</span> 
								  <span 	*ngIf="isRtl()">{{identityType.IdentityTypeAr}}</span> 
							   </mat-option>
							</mat-select>
						 </mat-form-field>
							<mat-form-field class="width50 form-field" appearance="outline">
							<mat-label> {{ 'Identity' | translate }} </mat-label>
							<input matInput placeholder=" {{ 'Identity' | translate }}" formControlName="IdentityText"
							   [(ngModel)]="company.IdentityText" required
							   [readonly]='isdisable'>
						 </mat-form-field>
						 
						 <mat-form-field class="width50 form-field" appearance="outline">
							<mat-label>{{ 'Nationality' | translate }}   </mat-label>
							<mat-select name="NationalityID" [(ngModel)]="company.NationalityID" formControlName="NationalityID"
							[disabled]='isdisable' readonly='isdisable'>
							   <mat-option *ngFor="let nationality of nationalityList"
								  [value]="nationality.NationalityID">
								 <span 	*ngIf="!isRtl()">{{nationality.NationalityEn}}</span> 
								 <span 	*ngIf="isRtl()">{{nationality.NationalityAr}}</span> 
							   </mat-option>
							</mat-select>
						 </mat-form-field>

						 <mat-form-field class=" width50 form-field " appearance="outline">
							<mat-label>{{ 'IpAddress' | translate }}</mat-label>
						  <input class="form-control" matInput type="input" placeholder="{{ 'IpAddress' | translate }}" 
							  formControlName="IpAddress" 
							  [readonly]='isdisable'
							  [(ngModel)]="company.IpAddress"    >  
						</mat-form-field>
					</div>

					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{ 'Telephone' | translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="Telephone" 
						  formControlName="Telephone"  [readonly]='isdisable'
						  type="number"
						  [(ngModel)]="company.Telephone" 
						   >  
					</mat-form-field>


					<mat-form-field class="width50  form-field " appearance="outline">
						<mat-label>{{ 'Subnet' | translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="Subnet" 
						  formControlName="Subnet"   
						  [readonly]='isdisable'
						  [(ngModel)]="company.Subnet"   >  
					</mat-form-field>

					<mat-form-field class="  form-field " appearance="outline">
						<mat-label>{{ 'Address English' | translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="{{ 'Address English' | translate }}" 
						  formControlName="AddressEn" 
						  [readonly]='isdisable'
						  [(ngModel)]="company.AddressEn"    >  
					</mat-form-field>

					<mat-form-field class="  form-field " appearance="outline">
						<mat-label> {{ 'Address Arabic' | translate }}</mat-label>
					  <input class="form-control" matInput 
					  [readonly]='isdisable'
					  type="input" placeholder="Address Arabic" 
					  [(ngModel)]="company.AdressAr"    formControlName="{{ 'Address Arabic' | translate }}"    >  
					</mat-form-field>

					
					<mat-form-field class="  form-field " appearance="outline">
						<mat-label>{{ 'Website' | translate }}</mat-label>
					  <input class="form-control" matInput 
					  type="input" placeholder="Website" 
					  [readonly]='isdisable'
						  formControlName="Website"  
						  [(ngModel)]="company.Website"     >  
					</mat-form-field>
					
 
					<mat-form-field class="form-field " appearance="outline">
						<mat-label>{{ 'Symbols' | translate }} </mat-label>
						<mat-select [(ngModel)]="company.SymbolIds" 
						name="SymbolIds" 
						formControlName="SymbolIds" 
						multiple>
						<mat-label>{{ 'Symbols' | translate }}</mat-label>
						<mat-option>
							<ngx-mat-select-search name="SymbolName" formControlName="SymbolName"
								placeholderLabel="{{translate('Enter At Least One Character')}}"
								noEntriesFoundLabel="{{translate('No data found')}}"
								 ngModel
								(ngModelChange)="onChangeSymbol($event)">
							</ngx-mat-select-search>
						</mat-option>
						<mat-option *ngFor="let symbol  of  symbols" [value]="symbol.Id">
							<span *ngIf="!isRtl()"> {{symbol.NameEn  }}</span>
							<span *ngIf="isRtl()"> {{symbol.NameAr  }}</span>
						  </mat-option>
						</mat-select>
					  </mat-form-field>





			 
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  
		*ngIf="auth.isGranted(_permissions.EditCompanies)" 
		[disabled]="!addNewCompanyForm.valid"  mat-raised-button color="warn">
		{{ 'SUBMIT' | translate }}  </button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">
			
			{{ 'CLOSE' | translate }} 
			</button>
	
	</mat-dialog-actions>
</form>