import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { BrokerCompanyDto, BrokerDto, CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import {Helpers}from 'app/helper/helpers';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AppComponentBase } from 'app/app-component-base';

@Component({
  selector: 'ms-company-broker-form-dialog',
  templateUrl: './company-broker-form-dialog.component.html',
  styleUrls: ['./company-broker-form-dialog.component.scss']
})
export class CompanyBrokerFormDialogComponent    extends AppComponentBase implements OnInit {

  title: string;
  brokers:   BrokerDto[];
  brokerId:any;
  brokerCompany  =new BrokerCompanyDto();
  addNewBrokerForm: FormGroup;
  statusLst: any;
  errors: any;
  company =new CompanyDto();
  randamPassword:any;
  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private  dialogRef: MatDialogRef<CompanyBrokerFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , 
    private brokerManagementService: BrokerManagementService,
    private companyManagementService :CompanyManagementService,
 
    private _helpers:Helpers   , injector: Injector
    ) {

      super(injector);
      
      this.randamPassword=_helpers.generatePassword(12);
    this.getBrokers();
      
    if (data.company != null) {
      this.company=data.company;
      this.title =   this.translate("Assign User To")+ " - " + this.company.Name;
    } 
    this.statusLst = [
      { "id": true, "name":   this.translate("Active") },
      { "id": false, "name":   this.translate("In Active") }
    ];

  }

  ngOnInit(): void {
    
    this.addNewBrokerForm = new FormGroup({  
      Email: new FormControl({ value: '' }, Validators.compose([Validators.required])),  
      IpAddress: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      brokerId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });


  }
  close() {
 
    this.dialogRef.close(false);
  }


  
  getBrokers(){
    this.brokerManagementService.getBrokers().subscribe(items =>  { 
      this.brokers=[];
          var brokersItem=[];
           brokersItem= items;
           
           brokersItem=brokersItem.filter(x=>x.BrokerCompanies!=null
           &&   x.IsDeleted!=true);
           this.brokers.forEach(element => {
      
             if(!(element.BrokerCompanies.filter(x=>x.IsActive).length>0)){

              this.brokers.push(element);
             }
           });
   });
  }

  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
 
  
    this.errors = [];
    this.brokerCompany.CompanyId=this.company.Id;
    this.brokerCompany.Password=this.randamPassword;
    this.brokerCompany.ConfirmPassword=this.brokerCompany.Password;
    this.companyManagementService.addCompanyBroker( this.brokerCompany,this.brokerCompany.BrokerId).subscribe(res => {
      this.dialogRef.close(this.addNewBrokerForm.value);
      this.toastr.success(  this.translate("Successfully"));
    },
      err => {
        this.errors.push(this.translate(err.error[0]));
        this.toastr.error(this.translate(err.error[0]));

      }
    ); 
    
  }
 

  
}
