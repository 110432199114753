import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'statistics-card',
  templateUrl: './statistics-card.component.html',
  styleUrls: ['./statistics-card.component.scss']
})
export class StatisticsCardComponent implements OnInit {

@Input() title:string ="";
@Input() val:number=null;
@Input() color:string="";
 
  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
  }
  isRtl() {
    return this.translateService.currentLang == 'ar';
}

}
