import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators'; 
import{BankDto} from '../../models/bank'

@Injectable()
export class BankManagementApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}


  getBanks(): Observable<any> { 
    return this.http.get<[]>(`${this.API_URL}/getBanks`);
  }
  getActiveBanks(): Observable<[]> { 
    return this.http.get<[]>(`${this.API_URL}/getActiveBanks`);
  } 
  getBank(id:any): Observable<BankDto> { 
    return this.http.get<BankDto>(`${this.API_URL}/getBank/`+id);
  } 
  insertBank(createBankRequest: any): Observable<boolean> {

     return this.http.post<any>(`${this.API_URL}/insertBank`, 
     createBankRequest)
       .pipe(tap(data => {}));
  }
  
  updateBank(BankRequest: any,id:any): Observable<boolean> {
 
    return this.http.put<any>(`${this.API_URL}/updateBank/`+id, 
    BankRequest)
      .pipe(tap(data => {}));
  }

  deleteBank(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteBank/`+id).pipe(tap(data => {}));
  } 
}