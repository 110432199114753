import { Id } from './types';
import { User } from './user';

export class Account {
  constructor(
    public id: Id,
    users: User[] = []) { }
}

export class BrokerInfoDto {
  UserId: any;
  BrokerId: any;
  CompanyId: any; 
  CompanyType: any; 
}