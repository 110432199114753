<div class="gene-card-content">
	<kendo-grid  
	[data]="items" 
	[resizable]="true"
	[reorderable]="true"
	[filterable]="false"   
	class="symbol-comm-grid" 
	> 
	 <kendo-grid-column   title=" {{'Id'  | translate }} "  headerClass="grid-list-column-header" width="50" > 
	  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
		  {{(rowIndex+1  )}}
	  </ng-template>
	</kendo-grid-column>
	
	<kendo-grid-column field="CommissionNameEN" title="  {{'Name English'  | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
	<kendo-grid-column field="CommissionNameAR" title="  {{'Name Arabic'  | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
  
	<kendo-grid-column field="Minimum" title="{{'Minimum'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>
	  <kendo-grid-column field="Maximum" title="{{'Maximum'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>
	  <kendo-grid-column field="CommissionPerc" title="	   {{'Commission%'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>


	<kendo-grid-column   title=""  width="80" headerClass="grid-list-column-header">

	  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
		<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
		  <mat-icon>menu</mat-icon>
		</button>
		   <mat-menu #anchorMenu="matMenu">

			
			  <a mat-menu-item   (click)="editSymbolMarketCommission(dataItem)"   >
				 {{'Edit'  | translate }} 
			  </a>

		   </mat-menu>
	   
		  </ng-template>
	 
	 </kendo-grid-column>
</kendo-grid>
 </div>
