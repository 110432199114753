export class TradeDto {

    TradeID: number;
    TradeDate: Date;
    BuyOrderid: number;
    SellOrderid: number;
    TradedQty: number;
    TradePrice: number;
    SymbolCode: string;
    MarketID: number;
    AuctionID: number;
    RecSerial: string;
    isUpdate :boolean;
    IsMyTrade:boolean;
  }
  export class TradeRequestDto
  {
    Skip:any;
    Take:any;
    AuctionTradesOnly:any;
    IsSettled:any;
    GoldTradeOnly:any;
    AllMarkets:any;
    FromDate:any;
    ToDate:any;
    SymbolCode:any;
    MarketId:any;
    AuctionId:any;
    ClientId:any;
    CompanyId:any;
    BrokerId:any;
    LastUpdatedTime:any;
  }