import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { permissions } from 'app/core/permission';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ApproveStatusEnum, BalanceSourceEnum, BalanceTypeEnum, ClientTypeEnum, CompanyTypeEnum, EntityTypeEnum, StatusEnum, TradeTypeEnum } from 'app/core/enums';
import { Helpers } from 'app/helper/helpers';
import { AppConsts } from 'app/core/config';
import { ClientStatementDto } from 'app/models/ClientStatement';
import { OperationService } from 'app/operation-managment/services/operation.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientReuqest } from 'app/models/client';
import { UploadAttachmentDto } from 'app/models/attachment';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';

@Component({
  selector: 'ms-upload-client-statement-form-dialog',
  templateUrl: './upload-client-statement-form-dialog.component.html',
  styleUrls: ['./upload-client-statement-form-dialog.component.scss']
})
export class UploadClientStatementFormDialogComponent  extends AppComponentBase   implements OnInit {
  selectedFiles: File[] = [];
  title: string;
  errors=[];
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UploadClientStatementFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers:EnumHelpers    ,
     private setupApi :SetupApi,
     private _settlementService:SettlementService   , 
      injector: Injector
    ) {
      super(injector) 

    }

  ngOnInit(): void {
   this.title = 'Upload Client Statements';   ;
 

  }
 
  close() {
    this.dialogRef.close(false);
  }
  public importFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
          let file = event.target.files[index];
          // Don't allow file sizes over 1MB
          if (file.size < 50000000) {
              // Add file to list of files
             this.selectedFiles[index]=file;
          }
          else {
            this.toastr.warning("File: " + file.name + " is too large to upload.");
          }
      }
  }
  }
 

    // onFormSubmit method is submit a add new user form.
  onFormSubmit() { 
    this.errors=[];
    let fileToUpload = new UploadAttachmentDto();
    this.selectedFiles.forEach(file => {

        fileToUpload.EntityType=EntityTypeEnum.Symbol;
        fileToUpload.file = file;  
        fileToUpload
        console.log(fileToUpload);
        const formData = new FormData();
        formData.append('file',       fileToUpload.file ,  fileToUpload.file.name);
        formData.append('EntityType',       fileToUpload.EntityType.toString()  );
        formData.append('EntityId',   '1');
        formData.append('TypeId',    '1 '  );
        this._settlementService.UploadClientStatementFile(formData).subscribe((event) => {
            this.toastr.success ("Successfully!");
            this.dialogRef.close(true);

          },err =>{
            this.errors.push(err.error[0]);
            this.toastr.error(err.error[0]);  
          }

          );
    });


    }


    
}
