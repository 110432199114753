import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Period } from '../../models/period';
import { DashboardData } from '../../models/dashboardData';
import { AppConsts } from '../../core/config';
import { OrderDto } from 'app/models/order';
import { OrderTicketLookupDto } from 'app/models/orderTicketLookup';
import { StatusLookupDto } from 'app/models/StatusLookup';

@Injectable()
export class DashboardApi {

  constructor(private http: HttpClient) {}

  getDashboardData(period: Period): Observable<DashboardData> {
  
    return Observable.create( observer => {
      observer.next( new DashboardData() )
      observer.complete()
    });

  }

  getOrders(): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get`);
  }
  getOrder(id: any): Observable<OrderDto> {
    
    return this.http.get<OrderDto>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getOrder/`+id);
  }
  getOrdersbyDate(fromDate :any , toDate :any ,marketId :any ,Skip :any ,Take :any ): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
    +'&ToDate='+toDate  +'&MarketId='+ marketId+'&Skip='+Skip +'&Take='+Take);
  }
 
  getMyOrdersbyDate(fromDate :any , toDate :any ,marketId :any , companyId :any,Skip :any ,Take :any ): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
    +'&ToDate='+toDate  +'&MarketId='+ marketId+'&Skip='+Skip +'&Take='+Take
    +'&CompanyId='+companyId);
  }

  getOrdersAuctionByDate(fromDate :any , toDate :any ,marketId :any ,acutionId:any,Skip :any ,Take :any ): Observable<[]> {
    
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
    +'&ToDate='+toDate  +'&MarketId='+ marketId+'&Skip='+Skip +'&Take='+Take+'&AuctionId='+acutionId
    )}

  getMyOrdersActionByDate(fromDate :any , toDate :any ,marketId :any ,
    companyId :any ,acutionId:any,Skip :any ,Take :any ): Observable<[]> {
   
   return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/get?FromDate=`+ fromDate
   +'&ToDate='+toDate  +'&MarketId='+ marketId+'&Skip='+Skip +'&Take='+Take
   +'&CompanyId='+companyId+'&AuctionId='+acutionId);
 }

 
  createOrder(createOrderRequest: any): Observable<boolean> {
    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/insert`, createOrderRequest)
      .pipe(tap(data => {}));
  }
  
  updateOrder(orderRequest: any,id: any): Observable<boolean> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/update/`+id, orderRequest)
      .pipe(tap(data => {}));
  }

  getOrderTicketLookup(): Observable<OrderTicketLookupDto> {
    
    return this.http.get<OrderTicketLookupDto>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getOrderTicketLookup`);
  }
  getStatusLookup(): Observable<StatusLookupDto> {
    
    return this.http.get<StatusLookupDto>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getStatusLookup`);
  }
  cancelOrder(id: any): Observable<any> 
  {
    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/cancel/` + id );
  }

  getOrdersHistories(id: any): Observable< any> { 
    return this.http.get< any>(`${AppConsts.bussinessApiUrl}${AppConsts. orderUrl}/getOrdersHistories/`+id);
  } 
}
