import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { SignalRService } from '../../../service/signalr/signalr.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
 
import { ResizeEvent } from 'angular-resizable-element';

import { OrderDto } from 'app/models/order';
import { DatePipe } from '@angular/common';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { MarketDto } from 'app/models/market';
import { State } from '@progress/kendo-data-query';
import { TradeDto } from 'app/models/trade';

import { AuctionDto } from 'app/models/auction';

import { AppComponentBase } from '../../../app-component-base';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { AuctionFormDialogComponent } from 'app/auction-management/components/auction-form-dialog/auction-form-dialog.component';
import { AuthService } from 'app/account/services/auth.service';

@Component({
  selector: 'ms-auction-dashboard',
  templateUrl: './auction-dashboard.component.html',
  styleUrls: ['./auction-dashboard.component.scss']
})
export class AuctionDashboardComponent extends AppComponentBase implements OnInit {


  orderStatus: number;
  orderTitle: any; 
  dashBoardLoaded = false;
  orderStatusList: any;
  orders: OrderDto[];

  auctionId: number;
  markets: MarketDto[];
  trades: TradeDto[];
  checkMarkets: any;

  isPreOpen: boolean = false;
  closeSession: boolean = false;
  storeID: any;
  symbolTitle: any;
  auctionTitle:any;
  auction: any;
  selectedIndex: number = 0;

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
  };

  constructor(
    
    private dialog: MatDialog, 
    private pageTitleService: PageTitleService,
    private marketWatchApi: MarketWatchApi,
    private Auctionservice: Auctionservice,
    private symbolService: SymbolService,
    private setupApi:SetupApi,
    private authService: AuthService,
    injector: Injector,

    private datePipe: DatePipe) {

    super(injector);
  

    this.setupApi.getStatusLookup().
      subscribe(res => {
        this.orderStatusList = res.OrderStatus;
        this.orderTitle = this.translate(res.OrderStatus[0].Status + " Orders");
        this.orderStatus = res.OrderStatus[0].StatusID;
      },
        // err => console.log(err) 
      );
    this.getTrades();
 

    this.pageTitleService.setTitle("Auctions");

  }
 
  
  getAuction($event: number) {

    if( $event > 0)
    {
        this.auctionId = $event; 
        this.Auctionservice.getAuctionView(Number(  this.auctionId)).subscribe(res=>{
        this.auction=res;
      });
      this.auctionTitle = '#' + this.auctionId;
    }
   else
   {
    this.auctionTitle = ''
   }

  }
 

  getTrades() {
    this.marketWatchApi.getTradesView(this.gridState.skip, this.gridState.take)
      .subscribe(items => {
        this.trades = items;
 
      });

  }

  public style: object = {};

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 50;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      position: 'fixed',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }
  tabClick(event) {
  }
  ngOnInit() { }

  
  setOrderStatus(orderStatus) {
    this.orderStatus = orderStatus.StatusID;
    this.orderTitle = this.translate(orderStatus.Status + " Orders");
  }


}
