import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'app/client-managment/services/client.service';
import { ClientBankDto, ClientDto, StoreClientDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { BankDto } from 'app/models/bank';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { StoreSectionDto, StoreDto, StoreClientViewDto } from 'app/models/store';
import { AppComponentBase } from 'app/app-component-base';
import { AuthService } from 'app/service/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ClientTypeEnum, IdentityTypeEnum, StatusEnum, UserTypeEnum } from 'app/core/enums';
import { BrokerDto } from 'app/models/broker';
import{BrokerManagementService} from 'app/broker-management/services/broker-management.service'
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { BrokerFormDialogComponent } from '../broker-form-dialog/broker-form-dialog.component';

@Component({
  selector: 'ms-broker-form',
  templateUrl: './broker-form.component.html',
  styleUrls: ['./broker-form.component.scss']
})
export class BrokerFormComponent extends AppComponentBase implements OnInit {
 
  title: string; 
  addNewbrokerForm: FormGroup;
 
  errors: any;
  selectedIndex: number = 0;
  statusList: any;
  nationalityList: any; 
  identityTypeList: any;
  brokerTypeList: any;
  broker: BrokerDto = new BrokerDto(); 
  statuslookups: StatusLookupDto = new StatusLookupDto(); 
  minDate: any;
  minExpiryDate: Date;
  maxExpiryDate: Date;
  maxDate: any;
  brokerId: any;
  status: any;
  year:any;
  month:any;
  day :any;
  isNationalId: boolean;  constructor(
    public formBuilder: FormBuilder,
    private brokerManagementService: BrokerManagementService,
    private dialog: MatDialog,
    private pageTitleService : PageTitleService ,
    private route: ActivatedRoute,
    private setupApi: SetupApi, injector: Injector
  ) {
    super(injector);
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth();
    this.day = new Date().getDate();
    this.minDate = new Date(this.year - 16, this.month, this.day);
    this.maxDate = new Date();
  }


  ngOnInit(): void {

    this.route.paramMap.subscribe(prams => {
      this.brokerId = +prams.get('id') as number;
      this.status = +prams.get('status') as number;

      if (this.brokerId > 0) {
        this.getBroker(this.brokerId); 
      } else {
        this.title = 'ADD NEW USER';

      }
      this.pageTitleService.setTitle('Trading Company Users'  );

    })


    this.statusList = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];

 
    this.brokerTypeList = [
      { "id": 1, "name": this.translate("Back Office") } ,
      { "id": 2, "name": this.translate("Front Office") } 
   
    ];
    this.getStatusLookup();
    this.addNewbrokerForm = new FormGroup({

      Name : new FormControl({ value: '' }, Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z\u0600-\u06FF ]*$')])),
      brokerType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      DateofBirth: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Address: new FormControl({ value: '' }),
      NationalityID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IdentityTypeID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      NationalID: new FormControl({ value: '' }, Validators.compose
      ([Validators.required, Validators.maxLength(14),Validators.minLength(14), Validators.pattern("^[0-9]*$")])),

      IssueDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      ExpiryDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Telephone: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.pattern("[0-9]{8,15}"), Validators.maxLength(20)])),
      Email: new FormControl({ value: '' }, Validators.compose([
        Validators.maxLength(50), Validators.email, Validators.required])),
      MainAddress: new FormControl({ value: '' }),

    });


  }


  tabChanged($event) {


    this.selectedIndex = $event.index;
    // if($event.tab.textLabel==this.WarehouseTitle){

    // }
  }


  getBroker(id) {
    this.brokerManagementService.getBroker(id).subscribe(res => {
    
      this.broker = res;
      this.title = this.translate('Update') + ' - ' + this.broker.Name;
    });
  }
  onClose(){
    let NationalIdType = IdentityTypeEnum.NationalID;      
    this.minExpiryDate = this.broker.IssueDate;  
    if(this.broker.IdentityTypeID == NationalIdType){
    this.year= this.broker.IssueDate.getFullYear()+7 ;
    this.month= this.broker.IssueDate.getMonth() ;
    this.day= this.broker.IssueDate.getDate() ;
    this.broker.ExpiryDate = new Date(this.year, this.month, this.day)
    this.isNationalId = true;
  }else{
    this.isNationalId = false
  }
  }

  getStatusLookup() {
    this.setupApi.getStatusLookup().
      subscribe(res => {
        this.statuslookups = res;
      },
        // err => console.log(err)
      ); 
      this.setupApi.getIdentityTypes().subscribe(res => {
        this.identityTypeList = res;
        this.identityTypeList=   this.identityTypeList.filter(x=>x.ClientType==ClientTypeEnum.Individual)
      });
  }
  Next(){
    const dialog = this.dialog.open(BrokerFormDialogComponent, {
      data:{broker:this.broker }  
    });
    dialog.afterClosed().subscribe((res) => {
      this.selectedIndex++;
      
      this.brokerId=this.broker.Id;
    } );

  }
  
  onBasicFormSubmit() {
    
    let expiryDate = new Date(this.broker.ExpiryDate);
    this.broker.ExpiryDate = new Date(
      Date.UTC(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate()));

      let DateofBirth = new Date(this.broker.DateofBirth);
      this.broker.DateofBirth = new Date(
        Date.UTC(DateofBirth.getFullYear(), DateofBirth.getMonth(), DateofBirth.getDate()));

      let IssueDate = new Date(this.broker. IssueDate);
      this.broker.IssueDate = new Date(
        Date.UTC(IssueDate.getFullYear(), IssueDate.getMonth(), IssueDate.getDate()));


    this.errors = [];
    if (this.broker.Id > 0) {
      this.brokerManagementService. updateBroker(this.broker, this.broker.Id).subscribe(() => {

        this.toastr.success(this.translate("Successfully"));
      },
        err => {

          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
        });

    } else {
    this.Next();

    }


  } 


  
}
