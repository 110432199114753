import { Component, OnInit ,ViewEncapsulation } from '@angular/core'; 
import { TranslateService } from '@ngx-translate/core';

import { SnackBarComponent } from '../../shared/components/snackbar/snackbar.component';
import { OtpComponent } from '../components/otp-dialog/otp.component';
import { AuthService } from '../services/auth.service';
import { LoginRequest } from '../../models/loginRequest';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs'; 

import { retryWhen, tap, switchMap, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppConsts } from 'app/core/config';

import { permissions } from 'app/core/permission';

@Component({
   selector: 'ms-login-session',
   templateUrl:'./login-component.html',
   styleUrls: ['./login-component.scss'],
   encapsulation: ViewEncapsulation.None,
})
export class LoginComponent   {
	
  
 
  isPasswordVisible:boolean = false;

               constructor(private authService: AuthService,
                  private formBuilder: FormBuilder,
                  private snackBar: MatSnackBar,
                  private dialog: MatDialog,
                  private route: ActivatedRoute,
                  private router: Router,
                  private toastr: ToastrService,
                  public translate : TranslateService
                 /* ,
                  public service: TranslocoService
                  */) { }             

   // when email and password is correct, user logged in.
 
   loginForm: FormGroup;

   ngOnInit() {
      this.loginForm = this.formBuilder.group({
        email: [''],
        password: [''],
        rememberMe:[false]
      });
  
      const msg = this.route.snapshot.queryParams.msg;
      if (msg) {
        
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: msg
        });
      }
    }
   get f() { return this.loginForm.controls; }
   login() { 

      const loginRequest: LoginRequest = {
        username: this.f.email.value,
        password: this.f.password.value
      }; 
      let userRolesClaims='userRolesClaims';
      this.authService.login(loginRequest,this.f.rememberMe.value). subscribe(user  =>{
 
        this.authService.getCurrentUser$().subscribe(res=>{

          this. authService. getRoleClaims(res.roleName).subscribe(roleClaims=>{
          
            sessionStorage.setItem(userRolesClaims,roleClaims);
            if(res.emailConfirmed!="True"){
               window.location.href=this.authService.New_PASSWORD_PATH;
            }else{
             if(res.roleName==AppConsts.agriculturalCooperativeRole){
                 window.location.href=this.authService.INITIAL_COIPERATIVE_PATH;
             }else  if(res.roleName==AppConsts. agriculturalCooperativeManagerRole){
           
               window.location.href=this.authService.INITIAL_COIPERATIVE_MANAGER_PATH;
              }
             else{

              //check permission to redirect
                 if(this.authService.isGranted(permissions.EnableDashboard))
                 window.location.href=this.authService.INITIAL_PATH;
                 else if(this.authService.isGranted(permissions.EnableAuctions))
                 window.location.href=this.authService.INITIAL_Auction_PATH;
                 else if (this.authService.isGranted(permissions.EnableGoldMarket))
                 window.location.href=this.authService.INITIAL_Gold_PATH;
                 else
                 {
                  this.authService.doLogoutAndRedirectToLogin();
                 }
              } 
            }  });  });  
 
      },
      err => {
        this.toastr.error('Username or password is incorrect, please try again');
   
      });
       
    }


    private invalidOtp(loginRequest: LoginRequest) {
    
      return (errors: Observable<HttpErrorResponse>) => errors.pipe(
        
        filter(err => err.error.msg === 'OTP_REQUIRED' ),
        switchMap(() => this.requestOtp()),
        tap(otp => loginRequest.otp = otp)
      );
    }
    private requestOtp() {

      const config = {
        width: '400px',
        disableClose: true
      };
      return this.dialog.open(OtpComponent, config).afterClosed();
    }
  
    changeSiteLanguage(language: string): void {
     // this.service.setActiveLang(language);
    }
}



