import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';


import { ApproveStatusEnum, auctionTradingsEnum, StatusEnum, OrderEnum, OrderSatausEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol';

import { StatusLookupDto } from 'app/models/StatusLookup';
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
 import { SetupApi } from 'app/Shared/api/setup.api';
import { AppComponentBase } from 'app/app-component-base';
import { OrderModel, OrderReuqest, OrderTradeDto } from 'app/models/order';
import { OrderService } from 'app/report-managment/services/order.service';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { ClientReuqest } from 'app/models/client';
import { OrderCommissionsFormDialogComponent } from 'app/report-managment/components/order-commissions-form-dialog/order-commissions-form-dialog.component';
import { OrderHistoryComponent } from 'app/order-management/components/order-history/order-history.component';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';
import { DatePipe } from '@angular/common';
import { OperationService } from 'app/operation-managment/services/operation.service';
import { State } from '@progress/kendo-data-query';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuctionFilterDto, AuctionPaymentDto, AuctionPaymentFilterDto } from 'app/models/auction';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
 
const createFormGroup = (dataItem) =>new FormGroup({
  Payment: new FormControl(dataItem.Payment,  Validators.compose([Validators.required, Validators.min(0)])),
  ClientNameAr: new FormControl(dataItem.ClientNameAr ), 
  ClientNameEn: new FormControl(dataItem.ClientNameEn ),
  AuctionID: new FormControl(dataItem.AuctionID),
  Id: new FormControl(dataItem.Id),
  ClientID: new FormControl(dataItem.ClientID), 
  ClientCode:  new FormControl(dataItem.ClientCode),
  OrderId: new FormControl( dataItem.OrderId),
  hasAttchment:  new FormControl(dataItem.hasAttchment),
  Date:  new FormControl(dataItem.Date )
});

@Component({
  selector: 'ms-auction-payments',
  templateUrl: './auction-payments.component.html',
  styleUrls: ['./auction-payments.component.scss']
})
export class AuctionPaymentsComponent extends AppComponentBase implements OnInit, OnDestroy {
 
  company: any;
  title: any;
  items:any;
  markets:any; 
  mainMarkets:any; 
  filteredCompanies:any;
  companies: any;
  SymbolCode:any; 
  subMarkets:any; 
  MainMarketId:any; 
  MarketId:any; 
  companyId:any; 
  symbols:any;
  auctionPayment:AuctionPaymentDto=new AuctionPaymentDto();
  private editedRowIndex: number;
  filterOptions: AuctionFilterDto = new AuctionFilterDto();
  AuctionPaymentFilter  :AuctionPaymentFilterDto=new AuctionPaymentFilterDto();
  searchText: string = "";
  public view: Observable<GridDataResult>;
  public formGroup: FormGroup;
 
  public state: State = {skip: 0,take: 50,};


  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private companyManagementService :CompanyManagementService,
    private route: ActivatedRoute,
    private _setupApi: SetupApi,
    private dialog: MatDialog,
    private operationService:OperationService,
    private _helper: EnumHelpers, injector: Injector
  ) {
    super(injector);
     
  }
  getCompanies(){
    this.companyManagementService.getActiveCompanies().subscribe(res=>{
      this.companies=res;
 
   });
  }

  public editHandler({ sender, rowIndex, dataItem }) {
   
    this.closeEditor(sender);
    this.formGroup = createFormGroup(dataItem);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }
  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    const auctionPayment = formGroup.value;
    this.auctionPayment.Id=auctionPayment.Id;
    this.auctionPayment.Payment=auctionPayment.Payment;
    this.auctionPayment.AuctionID=auctionPayment.AuctionID;
    this.auctionPayment.ClientID=auctionPayment.ClientID;
    this.save();
    sender.closeRow(rowIndex);
  }

  save(): void {
    this.operationService.insertOrupdateauctionPayment(this.auctionPayment).subscribe(res=>{ 
          var auction=this.items.filter(c=>c.Id=this.auctionPayment.AuctionID);
        this.getAuctionPayments(this.auctionPayment.AuctionID,auction.OrderType );
  
    });
 
  } 
  downloadAttachment(auction :any,SymbolCode){
 

    const editingDialog = this.dialog.open(OrderAttachmentComponent, {
      data: {  OrderID: auction.OrderId  ,SymbolCode : SymbolCode, OnlyDownload:true}
    });
    editingDialog.afterClosed().subscribe((res) => { });
   
  }
  
  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => { this.title = 'Auction Payments'; });
    this.pageTitleService.setTitle("Operations Management");
 
    this. getCompanies();
  } 

  onChangeCompany(event) {

    if(this.companies != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCompanies = this.companies;
        else
        this.filteredCompanies = this.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }

  }
  getAuctions()  {
   
     
    this.filterOptions.SymbolCode=this.SymbolCode;
    this.filterOptions.IsOpen=true;
    this.operationService.getFilterAuctions( this.filterOptions).subscribe(res=>{ 
      this.items=res; 
     // this.getAuctionPayments(res[0].Id,res[0].OrderType);
    });

  }
  public dataStateChange(state: DataStateChangeEvent,id:any): void {
    this.state = state; 
    this. getAuctions() ;
  }

  getAuctionPayments(id ,type){
   
    this.AuctionPaymentFilter.AuctionId=id;
    this.AuctionPaymentFilter. OrderType= type;
    this.AuctionPaymentFilter.   Skip= this.state.skip;
    this.AuctionPaymentFilter.   Take= this.state. take;

    this.view =  
     this.operationService.getAuctionPayments( this.AuctionPaymentFilter).pipe(
      map(data => {  
         let result = <GridDataResult>{ data:  data.result, total: data.count}
          return result;
      }) );  
  }
  


  
}

