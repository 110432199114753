export class MarketWatchDto {
    SymbolCode: string;
    SymbolID: number;
    high: number;
    low: number;
    volume: number;
    Value: number;
    open: number;
    close: number;
    RecSerial: string;
    text_color: string;
    icon: string;
    isUpdate: boolean;
    MarketId: number;
    AsksVolum: number;
    BidsVolum: number;
    ChangePer: number;
    Change: number;
    SymbolNameAr: string;
    SymbolNameEn: string;
    SectorID: number;
    SectorNameAr: string;
    SectorNameEn: string;
    CategoryID: number;
    CategoryNameAr: string;
    CategoryNameEn: string;
    TradePrices: number;
    Trades: number[];
    MarketNameAr: string;
    MarketNameEn: string;
    MarketStatus: string;
    Ask_price: number;
      Ask_qty: number;
     Bid_price : number;
     Bid_qty : number;
     Last_Tradeprice : number;
}
export class SymbolMarketWatchViewDto {
    MarketWatchName: string;
    UserId: string;
    IsPublic: boolean;
    MarketWatchId: number;

    SymbolCode: string;
    high: number;
    low: number;
    volume: number;
    Value: number;
    open: number;
    close: number;
    RecSerial: string;
    text_color: string;
    icon: string;
    isUpdate: boolean;
    MarketId: number;
    AsksVolum: number;
    BidsVolum: number;
    ChangePer: number;
    Change: number;
    SymbolNameAr: string;
    SymbolNameEn: string;
    Trades: number[];
    isfavourite: boolean;
}
export class CreateMarketWatchDto {
    SymbolCode: string;
    IsRemove: boolean;
    UserId: string;
    Id: number;

}

