import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderManagementApi } from "../api/order-management.api";

 
 

@Injectable()
export class OrderManagementService {
 
  constructor(private orderManagementApi: OrderManagementApi) { }

  

}
  