import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { AppConsts } from 'app/core/config';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import {ClaimDto, RoleDto} from '../../../models/role';
import { RoleFormDialogComponent } from '../role-form-dialog/role-form-dialog.component';
@Component({
  selector: 'ms-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {

  searchTxt:any="";
  items: RoleDto [] ;

  dialogRef: MatDialogRef<DemoDialog>;
  constructor(private adminService :AdminService
    ,    private dialog: MatDialog  , 
    private pageTitleService : PageTitleService ,
    private toastr: ToastrService) {

        this.pageTitleService.setTitle("Roles");
     }

  ngOnInit(): void {
    this.getRoles();
  }

  

  addNewRoleDialog(){ 
    const dialog = this.dialog.open(RoleFormDialogComponent, {
           data:{count : this.items.length}
      });
      dialog.afterClosed().subscribe((res) => {
 
         //this.items.push(res);
         this.getRoles( );
         
      } );
  }
  editRole(role){ 
    const dialog = this.dialog.open(RoleFormDialogComponent, {
        data:{role:role}  
      });
      dialog.afterClosed().subscribe((res) => {
        this.getRoles();
      } );
  }
  
  
  deleteRole(role) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this role name ' + role.name + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.adminService.deleteRole(role.id).subscribe((res) => {
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
        //  console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }


  getRoles(){
     this.adminService.getRoles().subscribe(items=>{
       
          this.items=items;
        //  this.items=this.items.filter(x=>x.name!=AppConsts.superAdminRole );
          this.items=this.items  .sort((a, b) => (a.name > b.name ? 1 : -1));
     });
  }
}
