import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from 'app/app-component-base';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { ClientService } from 'app/client-managment/services/client.service';
import { ApproveStatusEnum, MarketTypeEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { BankDto } from 'app/models/bank';
import { ClientBankDto, ClientCodeRelationDto } from 'app/models/client';
 import { ClientCompanyFormDialogComponent } from '../client-company-form-dialog/client-company-form-dialog.component';

@Component({
  selector: 'ms-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent extends   AppComponentBase implements OnInit, OnDestroy  {

  ngOnDestroy() {

  }
  
  isUpdate:boolean =true;
  assignGoldMarket:boolean =false;
  clientCodeRelations:ClientCodeRelationDto[];
  private _clientId: number;
  errors: any;
  addNewClientCodeRelationForm: FormGroup;
  goldMarketRelation :ClientCodeRelationDto= new ClientCodeRelationDto();
  approveStatus=ApproveStatusEnum;
  @Input()
  status: number;
 @Input() set clientId(value: number) {
  this._clientId = value; 
  if(value>0){
    this.getClientCodeRelations(value);
  }
 }

 
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _enumHelpers :EnumHelpers   , 
    
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector);  

    }

 
  ngOnInit(): void {

    this.addNewClientCodeRelationForm= new FormGroup({

      assignGoldMarket: new FormControl({ value: '' } ) ,
      RelationClientId: new FormControl({ value: '' } ) ,
     
    });
 
  }
  
  getClientCodeRelations(id){
   this.clientService.getClientCodeRelations(id).subscribe(res=>{
        this.clientCodeRelations=res;
        
        var goldMarketRelation=  this.clientCodeRelations.filter(x=>x.Type==MarketTypeEnum.GoldMarket);
        if(goldMarketRelation.length>0){
                this.assignGoldMarket=true;
                this.goldMarketRelation=goldMarketRelation[0];
        }
 
   });
  }

    
  onFormSubmit() {
    this.errors=[];
 
    this.goldMarketRelation.Type=MarketTypeEnum.GoldMarket;
    this.goldMarketRelation.ClientId=Number(this._clientId) ; 
    this.clientService.assginClientCodeRelation(this.goldMarketRelation).subscribe(res => { 
      this.goldMarketRelation.RelationClientId=res; 
      this.toastr.success(  this.translate("Successfully"));
    },  err => {

        this.errors.push(  this.translate(err.error[0]));
        this.toastr.error(  this.translate(err.error[0]));
    });

  }

 
}
