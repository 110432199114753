import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../../account/services/auth.service';
import { permissions } from '../../core/permission';

export class RouteGuardBase {

  permissionChecker: AuthService;

  constructor(_permissionChecker: AuthService) {
      this.permissionChecker = _permissionChecker
  }

  selectBestRoute(permission: string): string {

   //logout redirect
     return 'account/login'
  }
}
@Injectable({
  providedIn: 'root'
})
export class AdminGuard extends RouteGuardBase implements CanActivate, CanActivateChild {
 

  constructor(
      private _permissionChecker: AuthService,
      private _router: Router,
   //   private _sessionService: AppSessionService,

  ) {
      super(_permissionChecker);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  
     // if (!this.permissionChecker.getCurrentUser$) {
     if (sessionStorage.getItem('JWT_TOKEN')==null) {
          this._router.navigate(['/account/login']);
          return false;
      }

      let INITIAL_PATH = '/dashboard';
      let INITIAL_COIPERATIVE_PATH = '/cooperatives/transactions';
   
     
     if(state.url=="/"){

      if (this._permissionChecker.isGranted(permissions.ISCooperative)){
        this._router.navigate([INITIAL_COIPERATIVE_PATH]);
       return true;
      }
      else if(this._permissionChecker.isGranted(permissions.EnableAuctions))
      {
        this._router.navigate([this._permissionChecker.INITIAL_Auction_PATH]);
       return true;
      }
      else if (this._permissionChecker.isGranted(permissions.EnableGoldMarket))
      {
        this._router.navigate([this._permissionChecker.INITIAL_Gold_PATH]);
       return true;
      }
      else  if (this._permissionChecker.isGranted(permissions.EnableDashboard))
      {
        this._router.navigate([INITIAL_PATH]);
        return true;
      }
      else
      {
        return false;
      }

     }
    // if there is permission and is granted
     // then allow navigation
     if (this._permissionChecker.isGranted(route.data["permission"])) {
        return true;
     }

      if (!route.data || !route.data["permission"]) {
        return true;
     }



      // if there is permission but not granted
      // then get another url
      this._router.navigate([this.selectBestRoute(route.data["permission"])]);

   //   this._sessionService.setRedirectURL(state.url);
      return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      var havePermission = this.canActivate(route, state);
      if (!havePermission) {
       //   this._sessionService.setRedirectURL(state.url);
      }
      return havePermission;
  }

}
