import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from '../../../app-component-base';

import { ToastrService } from 'ngx-toastr';
import { SetupApi } from '../../../Shared/api/setup.api';
import { ClientService } from '../../services/client.service';
import { ClientCompanyDto } from '../../../models/client'
import { CompanyManagementService } from 'app/company-management/services/company-management.service';


@Component({
  selector: 'ms-client-company-form-dialog',
  templateUrl: './client-company-form-dialog.component.html',
  styleUrls: ['./client-company-form-dialog.component.scss']
})
export class ClientCompanyFormDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  clientId: number;
  companies: any;
  addNewClientForm: FormGroup;
  isUpdate: boolean;
  errors: any;
  statusList: any;

  isWarehouse: any;
  statusLst: any;
  clientCompany: ClientCompanyDto = new ClientCompanyDto();
  disableClient:any;
  filteredCompanies:any;

  constructor(public formBuilder: FormBuilder,
    private clientService: ClientService,
    private setupApi: SetupApi,
    private dialog: MatDialog,
    private companyManagementService: CompanyManagementService,
    private dialogRef: MatDialogRef<ClientCompanyFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , injector: Injector
  ) {
    super(injector);
  
    if (data.clientId != null) {
      this.clientId = Number(data.clientId);
      this.clientCompany.ClientId=      this.clientId;
      this.disableClient=true;
    }else{
      this.disableClient=false;
    }
    if (data.clientCompany != null) {
      this.isUpdate=true;
      this.clientCompany = data.clientCompany;
    }
    this.statusLst = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];
    this.title = this.translate("Assign Client To Company");
    if (this.isBroker()) {
      this.  getUserBrokerInfo().subscribe(res => {
        
        if (res != null  ) {
          this.clientCompany.CompanyId = res.CompanyId;
           
        }
      })

    } else {
      this.getCompanies();
    }


  }

  ngOnInit(): void {
    this.addNewClientForm = new FormGroup({
      Remark: new FormControl({ value: '' }),
      itemNameseaRCHb: new FormControl({ value: '' }),
      ClientId: new FormControl({ value: '' }),
      CompanyId: new FormControl({ value: '' }),
      CompanyName: new FormControl({ value: 'CompanyName' })
      // IsActive: new FormControl({ value: '' }, Validators.compose([Validators.required])),
    });


  }

  getCompanies() {
    this.companyManagementService.getActiveCompanies().subscribe(res => {
      this.companies = res;

    });
  }

  close() {
    this.dialogRef.close(false);
  }

  onFormSubmit() {

    this.errors = [];
    
    if(this.isUpdate){
      this.clientCompany.CreatedUserId = this.currentUserId();

      this.clientService.UpdateCompanyClient(this.clientCompany,this.clientId).subscribe(() => {
        this.dialogRef.close(this.addNewClientForm.value);
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
  
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        });
    }else{
      this.clientCompany.CreatedUserId = this.currentUserId();
      this.clientCompany.IsActive = true;
      this.clientService.insertCompanyClient(this.clientCompany).subscribe(() => {
        this.dialogRef.close(this.addNewClientForm.value);
        this.toastr.success(this.translate("Successfully"));
      },
        err => {
  
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));
        });
    }


  }

  onChangeCompany(event) {

    if(this.companies != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredCompanies = this.companies;
        else
        this.filteredCompanies = this.companies.filter((s) => s.Name.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.companies.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }


}

