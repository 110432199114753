import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { DashboardApi } from 'app/dashboard/api/dashboard.api';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MSGType } from 'app/core/enums';
import { OrderDto } from 'app/models/order';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'ms-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent extends AppComponentBase implements OnInit {

  statuslookups: StatusLookupDto = new StatusLookupDto();
  items: any;
  MSGTypes: any;
  OrderID: any;
  SymbolCode: any;
  title: string;
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , private _helper: EnumHelpers
    , private dashboardApi: DashboardApi, injector: Injector) {

    super(injector);
    if (data.OrderID != null && data.SymbolCode != null) {
      this.OrderID = data.OrderID;
      this.SymbolCode = data.SymbolCode;
      this.title = this.translate('OrderHistory-title') + this.OrderID + ' - ' + this.SymbolCode;
    }


    this.getOrderHistories();
  }
  getOrderHistories() {

    const datepipe: DatePipe = new DatePipe('en-US');
    this.dashboardApi.getOrdersHistories(this.OrderID)
      .subscribe(items => {
        this.items = items;
        this.dashboardApi.getStatusLookup().
        subscribe(res => { this.statuslookups = res ;
        
          this.items.forEach(element => {
    
            element.OrderStatusTxt =
              this.statuslookups.OrderStatus.find(x => x.StatusID == element.OrderStatus).Status;
            
            if (element.MsgType == MSGType.Created) {
              element.MsgTypeTxt = this.translate("Created");
              element.date= datepipe.transform(element.CreatedDate, 'dd-MM-YYYY');
              element.time= datepipe.transform(element.CreatedDate, 'HH:mm:ss');
            } else if (element.MsgType == MSGType.Updated) {
              element.MsgTypeTxt = this.translate("Updated");
              element.date= datepipe.transform(element.LastUpdatedDate, 'dd-MM-YYYY');
              element.time= datepipe.transform(element.LastUpdatedDate, 'HH:mm:ss')
            } else if (element.MsgType == MSGType.Cancel) {
              element.MsgTypeTxt = this.translate("Cancel");
            }
          
          
  
          });
        
        
        },
        // err => console.log(err)
        );

      });

  }
  ngOnInit(): void {

  }

}
