import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission'; 
import { SignalRService } from '../../../service/signalr/signalr.service'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { OrderDto } from 'app/models/order';
import { DatePipe } from '@angular/common';
import { OrderManagementApi } from 'app/order-management/api/order-management.api';
import { GoldMarketApi } from 'app/gold-market-management/api/gold-market.api';
import { State } from '@progress/kendo-data-query';
import { GlobalMarketWatchesDto, GoldOrderDto } from 'app/models/goldMarket';
import { GoldOrderComponent } from '../gold-order/gold-order.component';
 
@Component({
  selector: 'ms-gold-watch-list',
  templateUrl: './gold-watch-list.component.html',
  styleUrls: ['./gold-watch-list.component.scss']
})
export class GoldWatchListComponent   extends AppComponentBase implements OnInit {


  items: GlobalMarketWatchesDto[];
 
  brokerID: any;
  companyID: any;
  selectedOrderRow :GoldOrderDto = new GoldOrderDto();
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 50
  };

  constructor( private readonly signalrService: SignalRService,
    private dialog: MatDialog,
    private setupApi: SetupApi,
    private goldMarketApi:GoldMarketApi 
    , injector: Injector
  ) {
    super(injector);


   

    signalrService.goldMarketWatchChanges.subscribe(item => { 

      this.getMarketWatch();
    });


  }



  getMarketWatch() { 
    this.goldMarketApi.getLastMarketWatches().subscribe(res=>{
        this.items=res;
    })
  } 

  ngOnInit() {
    this.getMarketWatch();

  }
 
  createOrder(marketWatch ,orderType){
    this.selectedOrderRow= new GoldOrderDto();
    
     this.selectedOrderRow.OrderType=orderType;
     this.selectedOrderRow. SymbolID=marketWatch.SymbolId;   
    const editingDialog = this.dialog.open(GoldOrderComponent, {
        data:{order: {...this.selectedOrderRow }}  
   });
    editingDialog.afterClosed().subscribe((res) => {} );
 
   }


}



