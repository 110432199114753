<form [formGroup]="depositForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none" >{{translate("Deposit")}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 
						<mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Transaction Id')}}*</mat-label>
						  <input class="form-control"
						   matInput  placeholder="{{translate('Transaction Id')}}" 
						   type="number" 
							  formControlName="TransactionId"  
							  [(ngModel)]="depositDto.TransactionID"
							 [disabled]="true" 
							  >  
						</mat-form-field>

 
						<mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Client')}}*</mat-label>
							<mat-select  [(ngModel)]="depositDto.ClientID"  name="ClientID"
								 formControlName="ClientID"  > 
								 <mat-option>
									<ngx-mat-select-search name="itemNameseaRCHa" formControlName="itemNameseaRCHa"
										placeholderLabel="{{translate('Enter At Least Three Chars To Search')}}"
										noEntriesFoundLabel="{{translate('No data found')}}" ngModel
										(ngModelChange)="changeClientFilter($event)">
									</ngx-mat-select-search>
								</mat-option>
								 <mat-option  *ngFor="let entity of  filteredLookups.Clients" [value]="entity.Id">
								 {{isRtl()?entity.NameAr:entity.NameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>

						 <mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Commodity')}}*</mat-label>
							<mat-select  [(ngModel)]="depositDto.SymbolID"  name="SymbolID"
								 formControlName="SymbolID"  > 
								 <mat-option>
									<ngx-mat-select-search name="itemNameseaRCHb" formControlName="itemNameseaRCHb"
										placeholderLabel="{{translate('Enter At Least Three Chars To Search')}}"
										noEntriesFoundLabel="{{translate('No data found')}}" ngModel
										(ngModelChange)="changeSymbolFilter($event)">
									</ngx-mat-select-search>
								</mat-option>
								<mat-option  *ngFor="let entity of  filteredLookups.Symbols" [value]="entity.Id">
								 {{isRtl()?entity.NameAr:entity.NameEn}}</mat-option>
							</mat-select> 
						 </mat-form-field>

						 <mat-form-field class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Quantity')}}*</mat-label>
						  <input class="form-control"
						   matInput  placeholder="Quantity" 
						   type="number" 
							  formControlName="Quantity"  
							  [(ngModel)]="depositDto.Quantity"  >  
						</mat-form-field>	 


						<mat-form-field  class="width100 form-field " appearance="outline">
							<mat-label>{{translate('Transaction Date')}}*</mat-label> 
							<input matInput placeholder="{{translate('Choose a date')}}" 
							formControlName="TransactionDate"
							 [matDatepicker]="TransactionDate"
							[(ngModel)]="depositDto.TransactionDate" name="TransactionDate"
						   [matDatepicker]="TransactionDate">
						 <mat-datepicker-toggle matSuffix [for]="TransactionDate"></mat-datepicker-toggle> 
							<mat-datepicker #TransactionDate></mat-datepicker>
						 </mat-form-field>
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  [disabled]="!depositForm.valid"  mat-raised-button color="warn">{{translate('SUBMIT')}}</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{translate('CLOSE')}}</button>
	
	</mat-dialog-actions>
</form>