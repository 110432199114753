
<form [formGroup]="storeKeeperForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{translate("Store Keeper")}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 


						 <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate( 'English Name')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Name')}}" type="text" 
							  formControlName="KeeperNameEn"  
							  [(ngModel)]="storeKeeper.KeeperNameEn"  >  
						</mat-form-field>	 

                        <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate('Arabic Name')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('NameAr')}}" type="text" 
							  formControlName="KeeperNameAr"  
							  [(ngModel)]="storeKeeper.KeeperNameAr"  >  
						</mat-form-field>

                        <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate('User name')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('User name')}}" type="text" 
							  formControlName="Username"  
							  [(ngModel)]="storeKeeper.Username"  >  
						</mat-form-field>

                        <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate('Telephone')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('NameEn')}}" type="text" 
							  formControlName="Telephone"  
							  [(ngModel)]="storeKeeper.Telephone"  >  
						</mat-form-field>

                        <mat-form-field *ngIf="storeKeeper.KeeperID==null" class="width50 form-field " appearance="outline">
							<mat-label>{{translate('IpAddress')}}</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Ip')}}" type="text" 
							  formControlName="IpAddress"  
							  [(ngModel)]="storeKeeper.IpAddress"  >  
						</mat-form-field>

                        <!-- <mat-form-field class="width50 form-field " appearance="outline" *ngIf="storeKeeper.KeeperID==null">
							<mat-label>{{translate('Client Callback Url')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('Client Callback Url')}}" type="text" 
							  formControlName="ClientCallbackUrl"  
							  [(ngModel)]="storeKeeper.ClientCallbackUrl"  >  
						</mat-form-field> -->
 

                        <mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{translate('Email')}}*</mat-label>
						  <input class="form-control" matInput  placeholder="{{translate('NameEn')}}" type="text" 
							  formControlName="Email"  
							  [(ngModel)]="storeKeeper.Email"  >  
						</mat-form-field>

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Roles' | translate }}</mat-label>
							<mat-select  [(ngModel)]="rolesName"  name="rolesName"
								 formControlName="rolesName" 
								 > 
								<mat-option  *ngFor="let role of  roles" [value]="role.name">
								 {{role.name}}</mat-option>
							</mat-select> 
						  </mat-form-field>

					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit"  [disabled]="!storeKeeperForm.valid"  mat-raised-button color="warn">SUBMIT</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">CLOSE</button>
	
	</mat-dialog-actions>
</form>