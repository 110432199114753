 
     <mat-dialog-content class="dialog-comments">
 
         <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
               <div fxLayout="column">
                  <h4>{{'Comments'|translate}}</h4>
               </div> <span fxFlex></span>
               <!-- <button mat-icon-button   (click)="close()">
                  <mat-icon>close</mat-icon>
               </button> -->
       
            </div>
            <mat-divider></mat-divider>
         </div>
         <div class="gene-card-content">
            <div class="chat-content-wrap">
               <div fxLayout="row" fxLayoutAlign="start start" class=" chat-list pad-y-sm" 
               [ngClass]=
   "{'sender': comment.CreatedUserType==UserTypeEnum.EMX, 
   'receiver' : comment.CreatedUserType==UserTypeEnum.TradingCompany}"
               *ngFor="let comment of comments"  >
                  <!-- <div class="chat-thumbnail" fxFlex.gt-md="65px" fxFlex.gt-xs="70px" fxFlex="70px" >
                    
                  </div> -->
                  <div class="pad-wrap chat-content" fxFlex.gt-md="calc(80% - 75px)" fxFlex.gt-xs="80" fxFlex.sm="100">
                     <div fxLayout="row wrap" class="title-wrap">
                        <span class="chat-text font-bold inline-block"
                        *ngIf="comment.CreatedUserType==UserTypeEnum.EMX">
                           <span >{{'EMX' |translate }}</span> 
                        </span>
                        <span class="chat-text font-bold inline-block"
                        *ngIf="comment.CreatedUserType==UserTypeEnum.TradingCompany">
                           <span *ngIf="isRtl()">{{comment.CompanyNameAr}}</span>
                           <span *ngIf="!isRtl()">{{comment.CompanyNameEn}}</span>
                        </span>
                        <h5 class="meta-post mrgn-b-none inline-block">
                           <span  >{{comment.CreatedDate | date: 'dd/MM/yyyy'}}</span>
                           <span  >{{comment.CreatedDate | date: 'h:mm:ss a'}}</span>
                        </h5>
                     </div>
                     <div class="gene-chat-content">
                        <p class="mrgn-b-none gene-msg-block">{{comment.Comment}}</p>
                     </div>
                  </div>
               </div>

               
            </div>
            <div class="gene-chat-form">
               <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewClientForm">
               <div>
                  <mat-form-field class="gene-send-text mrgn-r-md">
                     <input matInput placeholder="Write a comment"
                     formControlName="Comment"  [(ngModel)]="clientComment.Comment">
                  </mat-form-field>
                  <button mat-fab color="primary" class="gene-send-btn"
                  [disabled]="!addNewClientForm.valid"
                  (click)="onFormSubmit()">
                     <mat-icon>send</mat-icon>
                  </button>
               </div>
               </form>
            </div>
         </div>
 
     </mat-dialog-content>
 