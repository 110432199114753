import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';
import { StatusLookupDto } from 'app/models/StatusLookup';

@Injectable()
export class MarketApi {

  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) { }


  getMarkets(): Observable<[]> {
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarkets`);
  }



  getMarket(id: any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarket/` + id);
  }



  insertMarket(MarketRequest: any): Observable<boolean> {

    return this.http.post<any>(`${this.API_URL}/insertMarket`,
      MarketRequest)
      .pipe(tap(data => { }));
  }

  updateMarket(MarketRequest: any, id: any): Observable<boolean> {

    return this.http.put<any>(`${this.API_URL}/updateMarket/` + id,
      MarketRequest)
      .pipe(tap(data => { }));
  }

  updateMarketSession(MarketRequest: any): Observable<boolean> {


    return this.http.put<any>(`${this.API_URL}/updateMarketSessions`,
      MarketRequest)
      .pipe(tap(data => { }));
  }

  updateMarketWorkingDays(MarketRequest: any): Observable<boolean> {


    return this.http.put<any>(`${this.API_URL}/updateMarketWorkingDays`,
      MarketRequest)
      .pipe(tap(data => { }));
  }



  updateMarketSettings(MarketRequest: any): Observable<boolean> {


    return this.http.put<any>(`${this.API_URL}/updateMarketSettings`,
      MarketRequest)
      .pipe(tap(data => { }));
  }

  updateMarketWorkingDay(MarketRequest: any): Observable<boolean> {

    return this.http.put<any>(`${this.API_URL}/updateMarketWorkingDays`,
      MarketRequest)
      .pipe(tap(data => { }));
  }

  updateMarketCalendar(marketRequest: any, id: any): Observable<boolean> {


    return this.http.put<any>(`${this.API_URL}/updateMarketCalendar/` + id,
    marketRequest)
      .pipe(tap(data => { }));
  }

  getMarketCalendar(id: any): Observable<[]> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarketCalendar/` + id);
  }

  getAllCalendar( ): Observable<[]> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getAllCalendar`);
  }

  getCalendarById(id: any): Observable<any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getCalendarById/` + id);
  }

  getMarketWorkingDays(id: any): Observable<[]> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarketWorkingDays/` + id);
  }

  getMarketSessions(id: any, type: any): Observable<[]> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarketSessions/` + id
      + '/' + type);
  }

  getMarketSessionsById(id: any): Observable<[]> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getMarketSessionsById/` + id
    );
  }
  deleteCalendar(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteCalendar/`+id).pipe(tap(data => {}));
  }
  deleteMarket(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteMarket/` + id).pipe(tap(data => { }));
  }
  deleteMarketSessionType(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteMarketSessionType/` + id).pipe(tap(data => { }));
  }
  deleteMarketSessions(id: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/deleteMarketSessions/` + id).pipe(tap(data => { }));
  }
  addSymbolMarket(SymbolMarketRequest: any, id: any): Observable<boolean> {


    return this.http.put<any>(`${this.API_URL}/addSymbolMarket/` + id,
      SymbolMarketRequest)
      .pipe(tap(data => { }));
  } 
  removeSymbolMarket(id: any, markerId: any): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/removeSymbolMarket/` + id + '/' + markerId).pipe(tap(data => { }));
  }
  removeMarketCommission(id: any ): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/removeMarketCommission/` + id ).pipe(tap(data => { }));
  }


  getMarketSessionTypes(): Observable<[]> {
    return this.http.get<[]>(`${this.API_URL}/getMarketSessionTypes`);
  }

  getSubMarketWithSessions(id:any): Observable< any> {
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSubMarketWithSessions/`+id);
  }
  validateMarkerSession(marketSession:any): Observable<any>{
    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/validateMarkerSession`,marketSession).pipe(tap(data => { }))
  }
  ValidateMarketCommissions(marketCommission: any): Observable<any>{
    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/ValidateMarketCommissions`,marketCommission).pipe(tap(data => { }))
  }
}