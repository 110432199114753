import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


import { ToastrService } from 'ngx-toastr';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'app/client-managment/services/client.service';
import { ClientBankDto, ClientDto, StoreClientDto } from 'app/models/client';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { BankDto } from 'app/models/bank';
import { BankManagementService } from 'app/bank-management/services/bank-management.service';
import { StoreSectionDto, StoreDto, StoreClientViewDto } from 'app/models/store';
import { AppComponentBase } from 'app/app-component-base';
import { AuthService } from 'app/service/auth-service/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ClientTypeEnum, IdentityTypeEnum, StatusEnum, UserTypeEnum } from 'app/core/enums';
import { EMXUserDto } from 'app/models/EMX';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EmxManagementService } from 'app/emx-management/services/emx-management.service';
import { AdminService } from 'app/admin/services/admin.service';
import { permissions } from 'app/core/permission';
import { Helpers } from 'app/helper/helpers';
import { UseRoleDto } from 'app/models/role';
import { AppConsts } from 'app/core/config';

@Component({
  selector: 'ms-emx-user-form',
  templateUrl: './emx-user-form.component.html',
  styleUrls: ['./emx-user-form.component.scss']
})
export class EmxUserFormComponent extends AppComponentBase implements OnInit {

  WarehouseTitle: string;
  title: string;

  addNewEMXUserForm: FormGroup;
 
  isUpdate: boolean;
  errors: any;
  selectedIndex: number = 0;
  nationalityList: any;
  statusList: any;
  identityTypeList: any; 
  EMXUser: EMXUserDto = new EMXUserDto(); 
  statuslookups: StatusLookupDto = new StatusLookupDto();
  roles:any;
  minDate: any;
  minExpiryDate: any;
  maxExpiryDate: any;
  maxDate: any;
  EMXUserId: any;
  status: any;
  roleType: any;
  departments: any;
  jobTitles: any;
  randamPassword: any;
  rolesName: any;
  year:any;
  month:any;
  day :any;
  isNationalId: boolean;
  
  constructor(
    public formBuilder: FormBuilder,
    private emxManagementService: EmxManagementService,
    private dialog: MatDialog,
    private pageTitleService : PageTitleService ,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private _helpers: Helpers,
    
    private setupApi: SetupApi, injector: Injector
  ) {
    super(injector);
    this.randamPassword = _helpers.generatePassword(12);
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth();
    this.day = new Date().getDate();
    this.minDate = new Date(this.year - 16, this.month, this.day);
    this.maxDate = new Date();

  }


  ngOnInit(): void {

    this.adminService.getRolesByType(permissions.ISEMX).subscribe(
      res=>{ 
         this.roles=res;
      }
     );

    this.route.paramMap.subscribe(prams => {
      this.EMXUserId = +prams.get('id') as number;
      this.status = +prams.get('status') as number;

      if (this.EMXUserId > 0) {
        this.getEMXUser(this.EMXUserId); 
        this.isUpdate=true;

      } else {
        this.title = 'ADD NEW EMX USER';
        this.isUpdate=false;
      }
      setTimeout(() =>{ 
        this.pageTitleService.setTitle( 'EMX Management');
       },0);

    })


    this.statusList = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];


 
    this.getStatusLookup();
    this.addNewEMXUserForm = new FormGroup({

      Name : new FormControl({ value: '' }, Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z\u0600-\u06FF ]*$')])),
      RoleType: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      DateofBirth: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Address: new FormControl({ value: '' }),
      NationalityID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IdentityTypeID: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      NationalID: new FormControl({ value: '' }, Validators.compose([
        Validators.required, Validators.maxLength(14),Validators.minLength(14), Validators.pattern("^[0-9]*$")])),
      IssueDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      ExpiryDate: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      Telephone: new FormControl({ value: '' }, Validators.compose([
        Validators.required, Validators.pattern("[0-9]{8,15}"), Validators.maxLength(20)])),
      Email: new FormControl({ value: '' }, Validators.compose([
        Validators.maxLength(50), Validators.email, Validators.required])),
        DepartmentId: new FormControl({ value: '' }),
        JobTitleId: new FormControl({ value: '' }),

        UserName: new FormControl({ value: '' }, Validators.compose([Validators.required])),

        IpAddress: new FormControl({ value: '' }),
        rolesName: new FormControl({ value: '' }, Validators.compose([Validators.required]))
    });


  }


  tabChanged($event) {
    this.selectedIndex = $event.index;

  }
  onClose(){
    let NationalIdType = IdentityTypeEnum.NationalID;
    this.minExpiryDate = this.EMXUser.IssueDate;  
    if(this.EMXUser.IdentityTypeID == NationalIdType){
    this.year= this.EMXUser.IssueDate.getFullYear()+7 ;
    this.month= this.EMXUser.IssueDate.getMonth() ;
    this.day= this.EMXUser.IssueDate.getDate() ;
    this.EMXUser.ExpiryDate = new Date(this.year, this.month, this.day)
    this.isNationalId = true;
    console.log(this.EMXUser.ExpiryDate);
  }else{
    this.isNationalId = false
  }
  }

  getEMXUser(id) {
    this.emxManagementService.getEMXUsersById(id).subscribe(res => {

      this.EMXUser = res;
      this.title = this.translate('Update') + ' - ' + this.EMXUser.Name;
     
      
      this.adminService.GetRoleByUserId(this.EMXUser.UserId).subscribe(
        res=>{ 
          res.forEach(element => {  this.rolesName=element;    });
       });
 
       
    });
  }

  getStatusLookup() {
    this.setupApi.getStatusLookup().
      subscribe(res => {
        this.statuslookups = res;
      },
        // err => console.log(err)
      ); 
      this.setupApi.getDepartments().subscribe(res => {
        this.departments = res;
      });
      this.setupApi.getJobTitles().subscribe(res => {
        this.jobTitles = res;
      });

      this.setupApi.getIdentityTypes().subscribe(res => { 
        this.identityTypeList = res;
        this.identityTypeList=   this.identityTypeList.filter(x=>x.ClientType==ClientTypeEnum.Individual);
      });
 
  }

  
  onBasicFormSubmit() {
    this.errors = [];
 


    let IssueDate = new Date(this.EMXUser.IssueDate);
    this.EMXUser.IssueDate = new Date(
      Date.UTC(IssueDate.getFullYear(), IssueDate.getMonth(), IssueDate.getDate()));

      let DateofBirth = new Date(this.EMXUser.DateofBirth);
      this.EMXUser.DateofBirth = new Date(
        Date.UTC(DateofBirth.getFullYear(), DateofBirth.getMonth(), DateofBirth.getDate()));


        let ExpiryDate = new Date(this.EMXUser.ExpiryDate);
        this.EMXUser.ExpiryDate = new Date(
          Date.UTC(ExpiryDate.getFullYear(), ExpiryDate.getMonth(), ExpiryDate.getDate()));


    if (this.EMXUser.Id > 0) {
      this.emxManagementService.  updateEmxUser(this.EMXUser, this.EMXUser.Id).subscribe(() => {
         
        this.toastr.success(this.translate("Successfully"));
      },
        err => {

          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
        });

    } else {
 
      this.EMXUser.Password = this.randamPassword;
      this.EMXUser.ConfirmPassword = this.EMXUser.Password;
      this.EMXUser.ClientCallbackUrl =  AppConsts.frontEndUrl+'/account/confirm';
      this.emxManagementService.addEMXUser(this.EMXUser).subscribe(res => {
         
        var userRole = new UseRoleDto();
        userRole.rolesName = [];
        userRole.userId = res;
        userRole.rolesName.push(this.rolesName);
        this.adminService.addUserRoles(userRole).subscribe(ress => {
        });
        this.toastr.success(this.translate("Successfully"));
      }, err => {
          this.errors.push(this.translate(err.error[0]));
          this.toastr.error(this.translate(err.error[0]));

        }
      );
    }


  } 

}
