import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { TradeDto, TradeRequestDto } from 'app/models/trade';
import { State, process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { finalize, startWith, switchMap, catchError, map, filter, take} from 'rxjs/operators';
import { OrderService } from 'app/report-managment/services/order.service';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';
@Component({
  selector: 'ms-unSettlement-trades',
  templateUrl: './unSettlement-trades.component.html',
  styleUrls: ['./unSettlement-trades.component.scss']
})
export class UnSettlementTradesComponent implements OnInit {

 
  clientId: any; 
  @Input() set client(value: any) {
    if(value!=null){      
      this.clientId  =Number( value);
      this.filter.ClientId=this.clientId ;
      this.getData( );
    }
  }
 
  selectBy:any;
  items:any;
  public view: Observable<GridDataResult>;
  public filter:any= TradeRequestDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100
   };
   constructor(
    private ngZone: NgZone, 
    private _settlementService: SettlementService,
    private _setupApi: SetupApi) {

  }
  getData()
  {   
     this.filter.Take=this.gridState.take;
     this.filter.Skip=this.gridState.skip;
     this._settlementService.getUnSettlementsTrades(this.filter).subscribe(items => { this.items = items;  });
  } 
  
  ngOnInit(): void {  this.getData();}

  
  public onStateChange(state: State) { this.gridState = state;} 
  public rowCallback(context: RowClassArgs) {
   
    var isFlash = 0;
    if(context.dataItem.isUpdate==true ){
      var isFlash = 1;
      context.dataItem.isUpdate=false;
    }
    return { flash_down: isFlash, normal: !isFlash};
}
 


  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    
    this.fitColumns();
  }

  private fitColumns(): void {
    
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }
  
}
