import { Component, EventEmitter, Inject, Injector, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { permissions } from 'app/core/permission';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ApproveStatusEnum, BalanceSourceEnum, BalanceStatusEnum, BalanceTypeEnum, ClientTypeEnum, CompanyTypeEnum, EntityTypeEnum, StatusEnum, TradeTypeEnum } from 'app/core/enums';
import { Helpers } from 'app/helper/helpers';
import { AppConsts } from 'app/core/config';
import { ClientStatementDto } from 'app/models/ClientStatement';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientReuqest } from 'app/models/client';
import { UploadAttachmentDto } from 'app/models/attachment';
import{ClientPurchasePowerDto}  from 'app/models/ClientStatement';
@Component({
  selector: 'ms-client-purchase-power-form-dialog',
  templateUrl: './client-purchase-power-form-dialog.component.html',
  styleUrls: ['./client-purchase-power-form-dialog.component.scss']
})
export class ClientPurchasePowerFormDialogComponent  extends AppComponentBase   implements OnInit {
 
  title: string;
  errors=[];
  items:any=[];
  clientId:any;
  currencyId:any;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientPurchasePowerFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers:EnumHelpers    ,
     private setupApi :SetupApi,
     private _settlementService:SettlementService   , 
      injector: Injector
    ) {
      super(injector) 
      this.clientId=data.clientId;
      this.currencyId=data.currencyId;

    }

  ngOnInit(): void {
   this.title = 'Purchase Power';   ;
   this.getData();

  }
 
  close() {
    this.dialogRef.close(false);
  }
 
 getData(){
 
  this._settlementService.getClientPurchasePower(this.clientId ,this.currencyId).subscribe(res=>{
      this.items.push(res);
       
  });
 }
 


    
}
