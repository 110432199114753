<form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewClientCodeRelationForm">
   <br />

   <div class="padding-input-form">
    
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
 
         <div fxFlex.gt-sm="64" fxFlex.gt-xs="64" fxFlex="100" fxFlex.gt-md="64">
            <mat-checkbox class="example-margin" [(ngModel)]="assignGoldMarket"
               formControlName="assignGoldMarket">{{ 'Assign To Gold Market' | translate}}</mat-checkbox>
         </div> 

         
 

      </div>
      <br/>
          
      <div fxLayout="row wrap" fxLayoutAlign="space-between center"*ngIf=" this.goldMarketRelation.RelationClientId!=null" >
  
         

         <div fxFlex.gt-sm="64" fxFlex.gt-xs="64" fxFlex="100" fxFlex.gt-md="64">
            <mat-form-field class="full-wid form-field" appearance="outline">
               <mat-label> {{ 'Client Ref Code' | translate }} </mat-label>
               <input matInput placeholder="{{ 'Client Ref Code' | translate }}" formControlName="RelationClientId" [(ngModel)]="goldMarketRelation.RelationClientId"
                    readonly="readonly">
            </mat-form-field>
         </div>

         
      </div>
   </div>
   <br/>
   <div fxLayout="column">

      <div fxLayout="row" fxLayoutAlign="start">
         <!-- auth.isGranted(_permissions.AssginToRelationCodeClient)&& -->
         <button (click)="onFormSubmit()"  *ngIf=" !(this.goldMarketRelation.RelationClientId!=null)  "
           type="submit"
            mat-raised-button color="warn">{{ 'SUBMIT' | translate }} </button>
         <a [routerLink]="['/clients/clients/'+status]" mat-raised-button color="primary">{{ 'Go To List' | translate
            }}</a>

      </div>
   </div>
</form>