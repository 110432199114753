<div>

    <div class="row">	
			<mat-card>
				<div fxLayout="row wrap" fxLayoutAlign="start">
				<div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
					<div class="user-content">
						<div class="pad-y-sm">
 
							<div fxLayout="row wrap" fxLayoutAlign="start" class="user-profile">
							<!-- <div fxFlex.xl="300px" fxFlex.lg="300px" fxFlex.md="300px"  fxFlex.sm="250px" fxFlex.xs="100" > -->
								<div class="mrgn-b-md">
									<img class="img-responsive  display-ib" src="assets/img/user.png" alt="user profile image" width="300" height="300">
								</div>
							<!-- </div> -->
							<div fxFlex.xl="calc(100% - 300px)" fxFlex.lg="calc(100% - 300px)" fxFlex.md="calc(100% - 300px)"  fxFlex.sm="calc(100% - 250px)" fxFlex.xs="100"  class="user-contact-info">
								<ul>
									<li> 
										<h3 *ngIf="isRtl()">{{userProfile.NameAr}}
											<h5> {{ role | translate}} </h5>
										</h3>
										<h3 *ngIf="!isRtl()">{{userProfile.NameEn}}
											<h5> {{ role | translate}} </h5> 
										</h3>
								
									</li>
									<li>
										<div class="contact-list"><i class="fa fa-envelope primary-text" aria-hidden="true"></i> <strong> {{'Email'| translate }}</strong></div>
										<a class="gene-block" href="">: {{userProfile.Email}}</a>
									</li>
									<li>
										<div class="contact-list"><i class="fa fa-phone primary-text" aria-hidden="true"></i> <strong>{{'Phone'| translate }}</strong></div>
										<a class="gene-block" href="mailto:abcd@abcd.com">: {{userProfile.Telephone}}</a>
									</li>
							
									<li >
										<div class="contact-list"><i class="fa fa fa-globe primary-text" aria-hidden="true"></i> <strong>{{'Nationality'| translate }}</strong></div>
										<span class="gene-block" *ngIf="!isRtl()">: {{nationality.NationalityEn}}</span>
										<span class="gene-block" *ngIf="isRtl()">: {{nationality.NationalityAr }}</span>
									</li>


									<li>
										<div class="contact-list" ><i class="fa fa-info primary-text" aria-hidden="true"></i> <strong>{{identityTypeTxt| translate }}</strong></div>
										<span class="gene-block" >: {{userProfile.Identity}}</span>
										 
									</li> 

									<li>
										<div class="contact-list"><i class="fa fa fa-calendar primary-text" aria-hidden="true"></i> <strong> {{ 'Date Of Birth' | translate }} </strong></div>
										<span class="gene-block">: {{userProfile.DateofBirth | date: 'dd/MM/yyyy'}}</span>
									</li>	

									<li>
										<button (click)="changePass()" type="submit"  style="width: 25%;" 
										mat-raised-button color="primary">{{ 'Change Password' | translate }}</button>
									</li>

								</ul>
								<!-- <div class="user-socail-stats">
			
								</div> -->
							</div>
							</div>			
						</div>						
					</div>
				</div>
			    </div>

		    </mat-card>
    </div>

    <div class="row">
		    <mat-card  *ngIf="!isDisabled">
					<!-- <div class="courses-grid-sec pad-y-lg" >
						<div fxLayout="row wrap" fxLayoutAlign="start stretch" class="height-full" > -->
				
						<!-- <div fxFlex.gt-md="35" fxFlex="100">
							<mat-card class="card-full-height card-full-width" fxLayoutAlign="start center">
							<div class=" ">
								<h3 class="full-wid">Summary about comm-trade </h3>
								<p class="full-wid">Lorem Ipsum is simply dummy text of the printing and type setting industry.</p>
							</div>
							</mat-card>
						</div> -->

						<!-- <div  fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" > -->
							<!-- <mat-card class="overflow-show tab-wrap set-ietab-height"> -->
							<!-- <mat-tab-group animationDuration="0ms" > -->
								<!-- <mat-tab>
									<div fxLayout="row wrap" fxLayoutAlign="start stretch"  >
										<div class="course-item-wrap" fxFlex.gt-sm="33" fxFlex.gt-xs="100" fxFlex="100"  >
					
										</div>
									</div>
									<div class="view-all-wrap text-right mrgn-x-md pad-x-xs pad-b-md">
										
									</div> 
								</mat-tab> -->
								<!-- <mat-tab label="{{'Account' | translate }}" > -->
									<!-- <div fxLayout="row wrap" fxLayoutAlign="start stretch" * >
										<div class="course-item-wrap" fxFlex.gt-sm="33" fxFlex.gt-xs="100" fxFlex="100" > -->

									<div fxLayout="row wrap" fxLayoutAlign="start">
										<div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100">
											<div  fxLayoutAlign="center center" >
												<div  fxFlex.xl="500px" fxFlex.lg="500px" fxFlex.md="500px" fxFlex.sm="500px"  fxFlex.xs="100">
																
													<h4 style="margin-top: 20px;"> {{ 'Change Password' | translate}} </h4>
														<!-- <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 10px;">
															<div  class="login-logo text-center">
															 <h5 class="">{{'Please, enter the new password' | translate}}</h5>	 
															</div>
														</div> -->
														
													<form #form="ngForm" (ngSubmit)="setPassword(form.value)">
														<div fxLayout="column" fxLayoutAlign="start stretch">
											
															<mat-form-field class="full-wid mrgn-b-sm">
																<input matInput placeholder="{{ 'New Password' | translate }}" type="password" name="password" required [(ngModel)]="password">
															</mat-form-field>
															<mat-form-field class="full-wid mrgn-b-sm">
																<input matInput placeholder=" {{ 'Confirm Password' | translate }}" type="password" name="confirmPassword" required [(ngModel)]="passwordConfirm">
															</mat-form-field>

															<div *ngFor="let error of errors">
																<label  style="color: brown;" class="error-label">{{error}}</label>
																<br/>
															</div>

															<div *ngIf="errors.length == 0">
																<label  style="color: brown;" class="error-label">{{'Enter password of at least 6 characters with one alphabet and one special character.' |translate}}</label>
																<br/>
															</div>
															<mat-dialog-actions class="padding-input-form align-center" >
															<button  mat-raised-button [disabled]="!form.valid" type="submit" color="warn" >{{'New Password'|translate}}</button>
															<button   type="button" mat-raised-button (click)="close()" color="primary">{{ 'Cancel' | translate }}</button>
														</mat-dialog-actions>
														</div>
													</form>

													
												
												</div>
											</div>
										</div>
										

								<!-- </mat-tab> -->

								<!-- <mat-tab label="{{'Settings' | translate }}">
									<div fxLayout="row wrap" fxLayoutAlign="start stretch" * >
										<div class="course-item-wrap" fxFlex.gt-sm="33" fxFlex.gt-xs="100" fxFlex="100" >
									
										</div>
									</div>
								</mat-tab> -->
				<!--   </mat-tab-group> -->
							<!-- </mat-card> -->
						            </div>
		    </mat-card>
 		
    </div>

</div>

