import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from 'app/app-component-base';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';
import { ClientService } from 'app/client-managment/services/client.service';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { BrokerCompanyDto, BrokerRequestDto } from 'app/models/broker';
import { ApproveStatusEnum, EntityTypeEnum } from 'app/core/enums';
import { BrokerFormDialogComponent } from '../broker-form-dialog/broker-form-dialog.component';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { SetupApi } from 'app/Shared/api/setup.api';
 
@Component({
  selector: 'ms-broker-company-list',
  templateUrl: './broker-company-list.component.html',
  styleUrls: ['./broker-company-list.component.scss']
})
export class BrokerCompanyListComponent extends   AppComponentBase implements OnInit, OnDestroy  {

  ngOnDestroy() {

  }
  dialogRef: MatDialogRef<DemoDialog>;
  errors:any;
  private _brokerId: number;
  broker:any;
  items:any;
  haveAccount:boolean=false;
  approveStatus:any;
 @Input() set brokerId(value: number) {
    
  this._brokerId = value;
  if(this._brokerId>0){

    this.getBrokerCompany(this._brokerId);
  }

 }
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _enumHelpers :EnumHelpers   , 
    private _setupApi: SetupApi,  
    private brokerManagementService: BrokerManagementService,  
    private companyManagementService: CompanyManagementService,    injector: Injector
    ) {
      super(injector);  
      this.approveStatus = ApproveStatusEnum ;

    }

 
  ngOnInit(): void {
    
  }
  
  getBrokerCompany(id){
    var brokerRequestDto=new BrokerRequestDto();
    brokerRequestDto.BrokerId=id;
   this.brokerManagementService. getBrokerCompanies(brokerRequestDto).subscribe(res=>{
      this.items=res;
      
      if(this.items.filter(x=>x.AccountIsActive==true&& x.IsDeleted!=true).length>0){
           this.haveAccount=true;
      }else{
         this.haveAccount=false;
      }
      this.items=  res.filter(x=>  x.IsDeleted!=true);
   });
   this.brokerManagementService. getBroker(id).subscribe(res=>{
    this.broker=res;
 });
  }

  addNewBrokerCompanyDialog(){
    const dialog = this.dialog.open(BrokerFormDialogComponent, {
      data:{brokerId:this._brokerId  }  
    });
    dialog.afterClosed().subscribe((res) => {
      this.getBrokerCompany(this._brokerId);

    } );

  }
  Edit(data){
    const dialog = this.dialog.open(BrokerFormDialogComponent, {
      data:{ brokerCompany:{...data } ,brokerId:this._brokerId  }  
    });
    dialog.afterClosed().subscribe((res) => {
      this.getBrokerCompany(this._brokerId);

    } );

  }


    
  removeCompanyBroker(data){
   
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to remove this account ' + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.companyManagementService.removeCompanyBroker(data.Id).subscribe(res =>  { 
          this.getBrokerCompany(this._brokerId);
          this.toastr.success("Successfully!");
        
        }, err => {
          this.toastr.error("Error");
         // console.log(err)
        });
      }
      this.dialogRef = null;
    });


  
  } 

  // changeStatus(data){
  
  //   this.dialogRef = this.dialog.open(DemoDialog, {
  //     data: { message: 'would you like to change status this account ' + '?' },
  //     disableClose: false
  //   });

  //   this.dialogRef.afterClosed().subscribe(result => {
  //     if (result == 'Yes!') {
        
  //       this.companyManagementService.changeStatusCompanyBroker(data.Id).subscribe(res =>  { 
  //         this.getBrokerCompany(this._brokerId);
  //         this.toastr.success("Successfully!");
        
  //       }, err => {
  //         this.toastr.error("Error");
  //         console.log(err)
  //       });
  //     }
  //     this.dialogRef = null;
  //   });


  
  // } 
  changePropertiesStatus(data){
 
    let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
    propertiesStatus.ActionOn=EntityTypeEnum.BrokerAccount;
    propertiesStatus.ResponsibleId=data.UserId;
    
    propertiesStatus.UserId=data.UserId;
    propertiesStatus.ResponsibleNameAr=data.Name;
    propertiesStatus.ResponsibleNameEn=data.Name;
    propertiesStatus.NotifiledEmail=data.BrokerEmail;
    if( data.AccountIsActive)
         propertiesStatus.Status= this._StatusEnum.Active;
      else
      propertiesStatus.Status=this._StatusEnum.InActive; 
   
    const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
      data:{propertiesStatus:propertiesStatus   }  
    });
    dialog.afterClosed().subscribe((res) => { 
  if(res!=null){
    if(res==this._StatusEnum.Active)
            data.AccountIsActive=true;
     else
     data.AccountIsActive=false; 
  }
  
    } );
  }
}
