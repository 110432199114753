import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Helpers } from 'app/helper/helpers';
import { AppComponentBase } from 'app/app-component-base';

import { CommissionManagementService } from 'app/commission-management/services/commission-management.service';
import { CommissionDto } from 'app/models/commission';
import { MarketService } from 'app/market-managment/services/market.service';

@Component({
  selector: 'ms-commission-form-dialog',
  templateUrl: './commission-form-dialog.component.html',
  styleUrls: ['./commission-form-dialog.component.scss']
})
export class CommissionFormDialogComponent extends AppComponentBase implements OnInit {

  title: string;
  commission: CommissionDto = new CommissionDto();
  addNewForm: FormGroup;
  statusLst: any;
  click:any=false;
  favoriteSeason: string;


  errors: any;
  companyCode: any;
  constructor(public formBuilder: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CommissionFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,


    private commissionManagementService: CommissionManagementService,
    private _helpers: Helpers, injector: Injector
  ) {
    super(injector);

    if (data.commission != null) {
      this.commission = data.commission;
      this.title = "Update Commission - " + this.commission.CommissionNameEN;
    } else {
      this.title = "ADD NEW COMMISION"
        ;
    }

    this.statusLst = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name": this.translate("In Active") }
    ];


  }

  ngOnInit(): void {

    this.addNewForm = new FormGroup({
      CommissionNameAR: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      CommissionNameEN: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IsActive: new FormControl({ value: '' }),
    });

  }
  close() {

    this.dialogRef.close(false);
  }




  // onFormSubmit method is submit a add new user form.
  onFormSubmit() {
    if (!this.click)
     {
      this.click = true;
      this.errors = [];
      if (this.commission.CommissionID > 0) {

        this.commissionManagementService.updateCommission(this.commission, this.commission.CommissionID).subscribe(() => {
          this.dialogRef.close(this.addNewForm.value);

          this.toastr.success("Successfully!");
        },
          err => {

            this.errors.push(err.error[0]);
            this.toastr.error(err.error[0]);
            this.click = false;
          });

      } else {
        this.commissionManagementService.insertCommission(this.commission).subscribe(res => {
          this.dialogRef.close(this.addNewForm.value);
          this.toastr.success("Successfully!");
        },
          err => {

            this.errors.push(err.error[0]);
            this.toastr.error(err.error[0]);
            this.click = false;
            // console.log(err)
          }
        );



      }
    }
  }



}
