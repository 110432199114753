export class ClientStatementReuqest {
    Skip: number;
    Take: number;
    FromDate: any;
    ToDate: any;
    BalanceCode: any; 
    CompanyId: any; 
    GeneralFilter: any; 
    ClientId : any; 
  }
  export class ClientUnSettlementReuqest {
    Skip: number;
    Take: number;
    ClientId: any;
    CurrencyId: any;
    IsAll: any; 
    CompanyId: any; 
    GeneralFilter: any;  
  }
  export class UpdateSettlementTradeDto{
    TradeIds:any[];
  }
  export class StatementsDiskLogRequest {
   Status: any;
   Date: any;  
 }
  

  export class ClientStatementDto {
     Id : any; 
     ClientCode : any; 
     CurrencyCode : any; 
     BalanceDate : any; 
     BalanceType : any; 
     BalanceSource : any; 
     Amount : any; 
     Remark : any; 
     CurrencyId : any; 
     ClientId : any; 
     CompanyId : any; 
  }

  export class ClientBalanceReuqest {
    Skip: number;
    Take: number;
    FromDate: any;
    ToDate: any; 
    CompanyId: any; 
    GeneralFilter: any; 
    ClientId : any; 
  }

  export class ClientPurchasePowerDto
  {
      ClientId:any;
     Balance:any;
     OrderBalance:any;
     OrderCommissionBalance:any;
     TotalBalance:any;
     UntsettledBalance:any;
  }