<form [formGroup]="addNewBankForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title| translate }}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 

 
                       <mat-form-field class=" form-field " appearance="outline">
                          <mat-label>{{'Name English'| translate }}</mat-label>
                        <input class="form-control" matInput type="input" placeholder="{{'Name English'| translate }}" 
                            formControlName="BankNameEn"  
                            [(ngModel)]="bank.BankNameEn"  >  
                      </mat-form-field>

					  <mat-form-field class=" form-field " appearance="outline">
						<mat-label>{{'Name Arabic'| translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="{{'Name Arabic'| translate }}" 
						  formControlName="BankNameAr"  
						  [(ngModel)]="bank.BankNameAr"  >  
					</mat-form-field>

					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{'Swift Code'| translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="Swift Code" 
						  formControlName="SwiftCode"  
						  [(ngModel)]="bank.SwiftCode"  >  
					</mat-form-field>


					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{'FTP IP'| translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="FTP IP" 
						  formControlName="FTPIP"  
						  [(ngModel)]="bank.FTPIP"  >  
					</mat-form-field>


					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{'FTP User Name'| translate }}</mat-label>
					  <input class="form-control" matInput type="input" placeholder="FTP UserName" 
						  formControlName="FTPUserName"  
						  [(ngModel)]="bank.FTPUserName"  >  
					</mat-form-field>


					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{'FTP Password'| translate }}</mat-label>
					  <input class="form-control" matInput type="password" placeholder="FTP Password" 
						  formControlName="FTPPassword"  
						  [(ngModel)]="bank.FTPPassword"  >  
					</mat-form-field>

 
					

			 
					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit" 	*ngIf="auth.isGranted(_permissions.EditBanks)"   [disabled]="!addNewBankForm.valid"  mat-raised-button color="warn">{{'SUBMIT'| translate }}</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{'CLOSE'| translate }}</button>
	
	</mat-dialog-actions>
</form>