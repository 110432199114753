
  <div class="gene-card-title">
    <div fxLayout="row" fxLayoutAlign="start center">
       <div fxLayout="column">
          <h5 class="mrgn-b-none">{{'Trades' | translate}}</h5>
       </div>
       <span fxFlex></span>
 

       
    </div>
    <mat-divider></mat-divider>
 </div>
  
    <div class="table-responsive kendo-table">
        <table class="full-wid">
          <tbody>
            <tr>
              <kendo-grid  
                  class="market-kendo-grid"
                  #grid  [kendoGridBinding]="items" 
                  [resizable]="true"
                  [reorderable]="true"
                  filterable="menu"
                  [columnMenu]="true"
                  [sortable]="true"  
                  >  
                 <kendo-grid-column  field="TradeDate" title="{{ 'Time' | translate }}" width="100">
                  <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem.TradeDate | date: 'h:mm:ss a'}}
                  </ng-template>
                  </kendo-grid-column>
      <kendo-grid-column field="SymbolCode" title="{{ 'Symbol' | translate }}" width="120"></kendo-grid-column>
      <!-- <kendo-grid-column field="TradeDate" title="Date"></kendo-grid-column>  -->
      <kendo-grid-column field="TradedQty" title="{{ 'Qty' | translate }}" width="85">
        <ng-template kendoGridCellTemplate let-dataItem  >
          <span 
            > {{dataItem.TradedQty | number }}  
          </span>
        </ng-template>
      </kendo-grid-column> 
      <kendo-grid-column field="TradePrice" title="{{ 'Price' | translate }}" width="90">
        <ng-template kendoGridCellTemplate let-dataItem  >
          <span 
            > {{dataItem.TradePrice | number }}  
          </span>
        </ng-template>
      </kendo-grid-column> 
      
 
      </kendo-grid>
            </tr>
          </tbody>
        </table>
        </div> 
       
   