import { SetupApi } from 'app/Shared/api/setup.api';
import { Component, EventEmitter, Inject, Injector, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
 
import { StoreSymbolDto, StoreDto } from 'app/models/store';
import { StatusLookupDto } from 'app/models/StatusLookup';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { SymbolDto } from 'app/models/symbol';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
import {AppComponentBase } from 'app/app-component-base';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { TradeTypeEnum } from 'app/core/enums';
 

@Component({
  selector: 'ms-warehouse-form-dialog',
  templateUrl: './warehouse-form-dialog.component.html',
  styleUrls: ['./warehouse-form-dialog.component.scss']
})

export class WarehouseFormDialogComponent extends AppComponentBase implements OnInit {
  selectedIndex = 0;
 
  StoreID:any;
  title: string;
  warehouse:StoreDto=new StoreDto();
  warehouseFormGroup: FormGroup;
  symbols: SymbolDto[] = [];
  warehouseSelectedSymboldIds:number[] = [];
  public lookups: StatusLookupDto=new StatusLookupDto();
  isUpdate :boolean;
  errors:any; 
  tradeTypes:any;
  filteredCities:any;
  filteredSymbols:any;

  constructor( 
    public formBuilder: FormBuilder,
    private route: ActivatedRoute  , 
    private _setupApi:SetupApi,
    private warehouseService: WarehouseApi,
    private symbolService: SymbolService,
    private _helper: EnumHelpers,
    private pageTitleService: PageTitleService,
    injector:Injector) { 
      super(injector);

      this.tradeTypes = _helper.getNamesAndValues(TradeTypeEnum);
      this.route.paramMap.subscribe(prams => {
        this.StoreID = +prams.get('id') as number; 
        this.warehouseService
        .getStore(this.StoreID)
        .subscribe(res=>
         {
           this.warehouse = res
           this.warehouseSelectedSymboldIds = this.warehouse.StoreSymbols.map(item=>item.SymbolId);
        });
      });
   
  }
    onItemSelect(item: any) {
      console.log(item);
    }
    onSelectAll(items: any) {
      console.log(items);
    }
    ngOnInit(): void {
      this._setupApi.
      getStatusLookup()
      .subscribe(res=>this.lookups=res);

      let parentThis = this;
      this.symbolService.getSymbols().subscribe(items => {
        parentThis.symbols = items.filter(v=>(v as any).IsDeleted !=true);
        this.filteredSymbols = parentThis.symbols;
      });

    this.warehouseFormGroup = new FormGroup({
      StoreNameEn: new FormControl({ value: 'StoreNameEn' },Validators.compose([Validators.maxLength(50),Validators.required])),
      StoreNameAr: new FormControl({ value: 'StoreNameAr' }, Validators.compose([Validators.maxLength(50),Validators.required])),
      Location: new FormControl({ value: 'Location' }, Validators.compose([Validators.maxLength(200),Validators.required])),
      CityID: new FormControl({ value: 'CityID' }, Validators.compose([Validators.required])),
      CityName: new FormControl({ value: 'CityName' }),
      Telephone: new FormControl({ value: 'Telephone' }, Validators.compose([Validators.required,Validators.pattern("[0-9]{8,15}")])), 
      Email: new FormControl({ value: 'Email' },Validators.compose([Validators.required,Validators.maxLength(50),Validators.email])),
      Website: new FormControl({ value: 'Website' }, Validators.compose([Validators.maxLength(200)])),
      Suspended: new FormControl({ value: 'Suspended' }, Validators.compose([Validators.required])),
      TotalArea: new FormControl({ value: 'TotalArea' }, Validators.compose([Validators.maxLength(30), Validators.pattern("[0-9]*\.+[0-9]*")])),
      StoreSymbols: new FormControl({ value: 'StoreSymbols' }, Validators.compose([])),
      StoreName: new FormControl({ value: 'StoreName' }),
      StoreCode: new FormControl({ value: 'StoreCode' }, Validators.compose([])), 
      IsDeleted: new FormControl({ value: 'IsDeleted' }, Validators.compose([])), 
      TradeTypeID: new FormControl({ value: 'TradeTypeID' }, Validators.compose([Validators.required])), 
      
    });
    this.title =this.warehouse.StoreID>0? "Update Warehouse": "Add Warehouse";
  }

  
 
  onFormSubmit() {
     
    if(this.warehouse.StoreID>0)
    this.warehouseService.updateWarehouse(this.warehouse,this.warehouse.StoreID).subscribe(res=>this.toastr.success("Success"),        err => {

      this.toastr.error(this.translate(err.error[0]));
    });
    else{
    this.warehouseService.insertWarehouse(this.warehouse).subscribe(res=>{this.toastr.success(this.translate("Success"))
    this.selectedIndex++;
    this.warehouse.StoreID = +res;
  },        err => {

    this.toastr.error(this.translate( err.error[0]));
  });
}
 
  }

 
  changeStoreSymbol(symbolId:number){

    if(this. warehouseSelectedSymboldIds.some(item=>item == symbolId))
    {
      this.warehouseService.deleteStoreSymbol(this.warehouse.StoreID,symbolId).subscribe(res=>{
        this.warehouseSelectedSymboldIds=this.warehouseSelectedSymboldIds.filter(item=>item!=symbolId)
      });
    }else{
      let ss = new StoreSymbolDto();
      ss.SymbolId = symbolId;
      ss.StoreID = this.warehouse.StoreID;

      this.warehouseService.addStoreSymbol(ss,this.warehouse.StoreID).subscribe(()=>{
        this.warehouseSelectedSymboldIds.push(symbolId);
      });
    }

  }

 
  tabChanged($event){

    this.selectedIndex = $event.index;
  }

  suspendedChange(){
 
    this.warehouse.Suspended=!this.warehouse.Suspended
    }
    
    
  onChangeCity(event) {

    if(this.lookups.Cities != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
          this.filteredCities = this.lookups.Cities;
        else
          this.filteredCities = this.lookups.Cities.filter((s) => s.CityNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.lookups.Cities.filter((s) => s.CityNameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  onChangeSymbol(event) {

    if(this.symbols != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
          this.filteredSymbols = this.symbols;
        else
          this.filteredSymbols = this.symbols.filter((s) => s.NameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.symbols.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

}
