import { Component, Inject, OnInit,Optional,ViewEncapsulation }      from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PageTitleService } from '../../core/page-title/page-title.service';
import {fadeInAnimation} from "../../core/route-animation/route.animation";
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ms-dialogs',
	templateUrl:'./dialog-material.html',
	styleUrls: ['./dialog-material.scss'],
	encapsulation: ViewEncapsulation.None,
	host: {
		"[@fadeInAnimation]": 'true'
	},
	animations: [ fadeInAnimation ]
})

export class DialogOverviewComponent implements OnInit{
 
	dialogRef : MatDialogRef<DemoDialog>;
	result    : string;

	

	constructor( public dialog: MatDialog,
					 private pageTitleService: PageTitleService,
					 public translate : TranslateService,

					 ) {


					  }

	ngOnInit() {
		setTimeout(() =>{
			this.pageTitleService.setTitle("Dialog");
			},0);
	}

	dialogHTML : string = `
		<button mat-raised-button type="button" (click)="openDialog()" color="primary">Open Dialog</button>
		<p *ngIf="result">You chose: {{ result }}</p>`;

	/**
	  * OpenDialog method is used to open a dialog.
	  */
	openDialog() {
		this.dialogRef = this.dialog.open(DemoDialog, {
			disableClose: false
		});
		this.dialogRef.afterClosed().subscribe(result => {
			this.result = result;
			this.dialogRef = null;
		});
	}
}

@Component({
	selector: 'ms-demo-dialog',
	template: `
		<h1>{{message}}</h1>

		<mat-dialog-actions align="end">
		    <button mat-button color="primary" (click)="dialogRef.close('Yes!')">Yes</button>
			<button mat-button (click)="dialogRef.close('No!')">No</button>
		</mat-dialog-actions>`
})
export class DemoDialog {
	message:string;
	constructor(public dialogRef: MatDialogRef<DemoDialog>,
		@Optional() @Inject(MAT_DIALOG_DATA) private data: any) {
		if (data != null) {
			if (data.message != null) {
			  this.message= data.message;
			} 
		  }
	}
}



