<kendo-grid 
[kendoGridBinding]="items" 
[resizable]="true"
[reorderable]="true"
[filterable]="false"
[sortable]="true"  

class="kendo-grid-list" 
>  
>
				  

<kendo-grid-column field="Id" title="{{ 'Code' | translate }}" width="100"  headerClass="grid-list-column-header"> <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  {{(broker.Code +'-'+ (rowIndex+1)  )}}
</ng-template>
</kendo-grid-column>

<kendo-grid-column field="CompanyNameEn" title="{{ 'Company Name' | translate }}" width="250"  headerClass="grid-list-column-header">
  <ng-template kendoGridCellTemplate let-dataItem>
    <span  
      *ngIf="!isRtl()">{{dataItem.CompanyNameEn }}</span>
    <span [ 
      *ngIf="isRtl()">{{dataItem.CompanyNameAr }}</span>

  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="UserName" title="{{ 'Username' | translate }}"  headerClass="grid-list-column-header"> </kendo-grid-column>
<kendo-grid-column field="Email" title="{{ 'Email' | translate }}"  headerClass="grid-list-column-header"> </kendo-grid-column>
<kendo-grid-column field="IpAddress" title="{{ 'IpAddress' | translate }}"   headerClass="grid-list-column-header"> </kendo-grid-column>

<kendo-grid-column field="AccountIsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
  <span *ngIf="dataItem.AccountIsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
  <span *ngIf="dataItem.AccountIsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspend' | translate }} </span>
</ng-template>
</kendo-grid-column>
<kendo-grid-column   title=""  width="80"  headerClass="grid-list-column-header" >

<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
  <mat-icon>menu</mat-icon>
  </button>
   <mat-menu #anchorMenu="matMenu">
    <a mat-menu-item   (click)="Edit(dataItem)"   >
        
      {{ 'View' | translate }}
    </a> 
    <a mat-menu-item   
      (click)="changePropertiesStatus(dataItem)" *ngIf="dataItem.IsActive==true"  >
      {{ 'Change Status' | translate }} 
    </a>
    <a mat-menu-item   (click)="removeCompanyBroker(dataItem)"   >
        
      {{ 'Delete' | translate }}
    </a> 


   </mat-menu>
 
  </ng-template>
 
 </kendo-grid-column>

</kendo-grid>

<br/>
<div fxLayout="row" fxLayoutAlign="start">
                   
	<button (click)="addNewBrokerCompanyDialog()" [disabled]="haveAccount"   mat-raised-button
	color="warn">{{ 'NEW ACCOUNT' | translate }} </button>

	 <a   [routerLink]="['/brokers/brokers/']" 
	  mat-raised-button color="primary"> {{ 'Go To List' | translate }} </a>

 </div>