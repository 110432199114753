import { SectorDto } from './category';
import{SymbolMarketCommissionDto} from './commission'
import { CurrencyDto } from './currency';

export class SymbolDto {
    Id:       number;
    SymbolCode:     string;
    NameEn:     string;
    NameAr:    string;
    MarketId: number;
    CurrencyId: number;
    IsDeleted:     boolean;
    CreatedUserId:   string;
    CreatedDate:      Date;
    LastUpdatedUserId:        null;
    LastUpdatedDate:     null;
    Unit:  number;
    UnitTxt:  string;
    Sector:SectorDto;
    SectorID: number;
    ReutersCode: string;
    CoverPerc: number;
    SymbolMarkets: SymbolMarketDto[];
    symbolMarket: SymbolMarketDto;
    Currency: CurrencyDto;
  }


 
  export class StoreSymbolDto {
    StoreSymbolID: number;
    StoreID: number;
    SymbolId: number;
    Symbol: string;
}
export class RequestSymbolDto {
 
  CategoryID: number;
  SectorID: number;

  MainMarketID: number;
  MarketID: number;


  SearchText: string;
} 


  export class SymbolMarketDto{
    Id: number;
    SymbolId: number;
    MarketId: number;
    UpperLPerc: number;
    LowerLPerc: number;
    UpperLValue: number;
    LowerLValue: number;
    OpenPrice: number;
    UpperLPercCircuit: number;
    LowerLPercCircuit: number;
    SuspensionPeriodCircuit: number;
    SuspensionTimeCircuit: Date;
    SuspensionTime: Date;
    SuspensionPeriod: number;
    UpperLValueCircuit: number;
    LowerLValueCircuit: number;
    CircuitBroken: boolean;
    Status: number;
    Locked: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    UpdatedUserId: string;
    LastUpdatedDate: Date;
    Symbol: string;
    NoSettlement :number;
    SymbolMarketCommissions: SymbolMarketCommissionDto[];
  }