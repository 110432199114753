import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConsts } from '../../core/config';
import { AnnouncementRequestDto } from 'app/models/announcement';
import { saveAs } from 'file-saver';


@Injectable()
export class NewsApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.marketWatchUrl}`;

  constructor(private http: HttpClient) {}

  insertAnnouncement(announcementObj: any): Observable<any> {
    
    return this.http.post<any>(`${this.API_URL}/insertAnnouncement`, announcementObj);
  }
  
  getAnnouncement(id: any): Observable<any> {
    
    return this.http.get<any>(`${this.API_URL}/getAnnouncement/`+id);
  }  
  getAnnouncements(request?: AnnouncementRequestDto): Observable<any> {
    let queryParameters: string = this.convertObjectToQueryParameters(request);
    return this.http.get<any>(`${this.API_URL}/getAnnouncements`+queryParameters);
  }  
  updateAnnouncement(announcementObj: any, id: any): Observable<any> {
    
    return this.http.put<any>(`${this.API_URL}/updateAnnouncement/`+id, announcementObj);
  }
  deleteAnnouncement(id: any): Observable<any> {
    
    return this.http.delete<any>(`${this.API_URL}/deleteAnnouncement/`+id);
  }
  uploadAttachement(attachement: any): Observable<any> {
    
    return this.http.post<any>(`${this.API_URL}/UploadAnnouncementFiles`,attachement, {reportProgress: true, observe: 'events'});
  }
  GetAttatchementByAnnouncement(id: any  ): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/GetAttatchementByAnnouncement/`+id);
  } 
  GetAttatchementByAuctionAnnouncement(id: any  ,auctionId :any): Observable<any> {
    return this.http.get<any>(`${this.API_URL}/GetAttatchementByAuctionAnnouncement/`+id
    +`/`+auctionId);
  } 
  DownloadAttachment(Request: any )  { 
    this.http.get(`${this.API_URL}/DownloadAttachment?FileName=`+Request.FileName+`&AttachmentsPath=`+Request.AttachmentsPath, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' } }).subscribe(_response => {
       saveAs(_response, Request.FileName, {
        type: 'application/octet-stream'
      });
    }) ; 
    }
  ViewAttachment(Request: any )  { 
    this.http.get(`${this.API_URL}/DownloadAttachment?FileName=`+Request.FileName+`&AttachmentsPath=`+Request.AttachmentsPath, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' as 'application/pdf' } }).subscribe(_response => {
        let file = new Blob([_response], { type: 'application/pdf' });            
        var fileURL = URL.createObjectURL(file);

       window.open(fileURL, '_blank')
    }) ; 
    }
  convertObjectToQueryParameters(request): string {
    let queryParameters: string = "";
    for (let property in request) {
        if (request[property] != null)
            queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
    }
    if (queryParameters == "")
        return "";
    return queryParameters
}
}
