<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between center">
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="20" fxFlex.lg="20" fxFlex.xl="20">
			   <div class="pad-r-md">
				  <h4>{{'User Sessions'| translate }}</h4>
			   </div>
			</div>
			<div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="80" fxFlex.lg="80" fxFlex.xl="80">
			   <div fxLayout="row wrap">
				  <div class="form-group pad-r-md" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="calc(100% - 200px)" fxFlex.lg="calc(100% - 200px)" fxFlex.xl="calc(100% - 200px)">
 
				  </div>
				  <div class="contact-btn" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100px" fxFlex.lg="200px" fxFlex.xl="200px"> 
					 <button mat-raised-button color="primary" 	
					 (click)=" KillAllSession()" 
					 class="addOrderBtn"   >{{'KILL ALL SESSIONS'| translate }}</button>
				  </div>
			   </div>
			</div>
		 </div>	
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"    
				  class="kendo-grid-list" 
				  >  
				  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="userName" title="{{'User Name'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
				  <kendo-grid-column field="email" title="{{'Email'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
				  <kendo-grid-column field="phoneNumber" title="{{'Phone'| translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>
                  <kendo-grid-column field="ipAddress" title="{{'IpAddress'| translate }}" headerClass="grid-list-column-header"  > </kendo-grid-column>
                  
				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item   (click)=" KillSession(dataItem)"   >
							  {{'Kill Session'| translate }}
							</a> 
						 
							 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>