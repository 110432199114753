import { Injectable } from '@angular/core'; 
import { SetupApi } from 'app/Shared/api/setup.api';

import { Observable } from 'rxjs';
import { MarketApi } from '../api/market.api';
  

@Injectable()
export class MarketService {

  
  constructor(private marketApi: MarketApi,
              private setupApi: SetupApi ) { }


  getMarkets(): Observable< []> {
    return this.marketApi.getMarkets();
  }

  getMarket(id:any): Observable< any> {
    return this.marketApi.getMarket(id);
  }
  
   
 insertMarket(MarketRequest: any): Observable<boolean> {
 
  return this.marketApi.insertMarket(MarketRequest);
}

updateMarket(MarketRequest: any,id :any): Observable<boolean> {

return this.marketApi.updateMarket(MarketRequest,id);
}

updateMarketSession(MarketRequest: any ): Observable<boolean> {

  return this.marketApi.updateMarketSession(MarketRequest );
  }

  updateMarketSettings(MarketRequest: any ): Observable<boolean> {

    return this.marketApi.updateMarketSettings(MarketRequest );
    }

    updateMarketWorkingDays(MarketRequest: any ): Observable<boolean> {

      return this.marketApi.updateMarketWorkingDays(MarketRequest );
      }
  
  updateMarketWorkingDay(MarketRequest: any ): Observable<boolean> {

    return this.marketApi.updateMarketWorkingDay(MarketRequest );
    }

    getMarketWorkingDays(id :any): Observable< []> {

    return this.marketApi.getMarketWorkingDays(id );
    }
  
    getMarketSessions(id :any ,  type:any): Observable< any> {
      return this.marketApi.getMarketSessions(id ,type);
    }
    getMarketSessionsById(id :any  ): Observable< any> {
      return this.marketApi.getMarketSessionsById(id   );
    }

deleteMarket(id: any): Observable<boolean> {

return this.marketApi.deleteMarket(id);
}
deleteMarketSessionType(id: any): Observable<boolean> {

  return this.marketApi.deleteMarketSessionType(id);
  }

  deleteMarketSessions(id: any): Observable<boolean> {

    return this.marketApi.deleteMarketSessions(id);
    }

    deleteCalendar(id: any): Observable<boolean> {

      return this.marketApi.deleteCalendar(id);
    }
  
addSymbolMarket(SymbolMarketRequest: any,id :any): Observable<boolean> {

  return this.marketApi.addSymbolMarket(SymbolMarketRequest,id);
  }


  removeSymbolMarket(id: any ,markerId :any): Observable<boolean> {
    return this.marketApi.removeSymbolMarket(id,markerId);
  }
  getMarketSessionTypes( ): Observable<any> {
    return this.marketApi.getMarketSessionTypes( );
  }

  removeMarketCommission(id: any ): Observable<boolean> {
 
    return this.marketApi.removeMarketCommission(id );
  }
  
  updateMarketCalendar(marketRequest: any, id: any): Observable<boolean> {

    return this.marketApi.updateMarketCalendar( marketRequest,id);
  }

  getMarketCalendar(id: any): Observable<[]> {
    return this.marketApi.getMarketCalendar( id);
  }

  
  getAllCalendar( ): Observable<[]> {
    return this.marketApi.getAllCalendar();
  }


  getCalendarById(id: any): Observable<any> {
    return this.marketApi.getCalendarById( id);
  }
  getSubMarketWithSessions(id:any): Observable< any> {
    return this.marketApi.getSubMarketWithSessions( id);
  }
  validateMarkerSession(mareketSession:any): Observable<any>{
    return this.marketApi.validateMarkerSession(mareketSession)
  }
  ValidateMarketCommissions(marketCommission:any): Observable<any>{
    return this.marketApi.ValidateMarketCommissions(marketCommission)
  }
}