import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { OrderManagementApi } from 'app/order-management/api/order-management.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { OrderDto } from 'app/models/order';
 import { DatePipe } from '@angular/common';
import { GridComponent, GridItem, RowClassArgs } from '@progress/kendo-angular-grid';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
 import { State } from '@progress/kendo-data-query';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { take } from 'rxjs/operators';
import {  OrderSatausEnum, MarketStatusEnum, EntityTypeEnum, AuctionstatusEnum } from 'app/core/enums';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
 
import { OrderHistoryComponent } from 'app/order-management/components/order-history/order-history.component';
import { AuctionOrderTicketDialogComponent } from '../auction-orderTicket-dialog/auction-orderTicket-dialog.component';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { AuctionViewDto } from 'app/models/auction';
import { FileAttachmentDto } from 'app/models/client';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { CompanyDto } from 'app/models/broker';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { AuctionHelper } from 'app/auction-management/auctionHelper';


@Component({
  selector: 'ms-order-watch',
  templateUrl: './auction-order-watch.component.html',
  styleUrls: ['./auction-order-watch.component.scss']
})

export class AuctionOrderWatchComponent extends AppComponentBase implements OnInit {

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 1000
  };
  loadOrders: boolean = false;
  selectBy: any;
  items: OrderDto[];
  changeOrders: number[];
  form: FormGroup;
  orderUpdates: any[] = [];
  dialogRef: MatDialogRef<DemoDialog>;
  result: string;
  brokerID: any;
  companyID: any;
  orderstatusEnum = OrderSatausEnum;
 
  auction :AuctionViewDto=new AuctionViewDto();
 
 
  @Input() set auctionInfo(auction: any) {
 
    if (auction!=null ){
      this.auction  = auction;
        this.getAuctionOrderWatch();
    } 
  }
 
  constructor(
    private ngZone: NgZone, private readonly signalrService: SignalRService,
    private datePipe: DatePipe,
    private setupApi: SetupApi,
    private dashboardParamService: DashboardParamService,
    private dialog: MatDialog, private orderManagementApi: OrderManagementApi,
    private Auctionservice: Auctionservice,
    private _helper :EnumHelpers,
    private _AuctionHelper :AuctionHelper,
    private pageTitleService: PageTitleService,
    injector: Injector,
    private companyService: CompanyManagementService ) {
    super(injector);

    signalrService.auctionOrderChanges.subscribe(item => {
     
      let orders = JSON.parse(item);
      if ((this.auction.CompanyId!=this.companyID &&this.companyID!=null )&& ! this.isEMX()  
         &&(this.isBroker() || !this.auction.IsPublic  ) ) {
                orders = orders.filter(x=>x.CompanyId == this.companyID);
      }

      orders.forEach((element, index) => {

        if (this.items != null) {
         
          if ((this.items.filter(x => x.OrderID === orders[index].OrderID).length > 0)) {
         
            this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderStatus
              = orders[index].OrderStatus;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderQty
              = orders[index].OrderQty;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].RemainingQty
              = orders[index].RemainingQty;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderPrice
              = orders[index].OrderPrice;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].MinimumFill
              = orders[index].MinimumFill; 

          } else { 
            if(orders[index].OrderStatus == OrderSatausEnum.Open){

              this.items = [element, ...this.items];
            }
          } 
        } 
      });  
      if(this.items.length>0){ 
        this.items= this.items.filter(x=>x.OrderStatus==OrderSatausEnum.Open);
      }
    });


  }

  public rowCallback(context: RowClassArgs) {


    if (context.dataItem.BrokerID == this.brokerID) {
      var isBuy = 0;
      if (context.dataItem.OrderType === 1) {
        var isBuy = 1;
      }
      return {
        myOrderColorBuy: isBuy, myOrderColorSell: !isBuy
      };
    } return null;
  }

  downloadAttachment(order ){ 
    this.setupApi.GetOrderAttachmentsByOrderId( order.OrderID).subscribe(res=>{
      if(res != null)
      {
        const editingDialog = this.dialog.open(OrderAttachmentComponent, {
          data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:true}
        });
        editingDialog.afterClosed().subscribe((res) => { });
      }

    });
}
  getAttachments(order){
    
     const editingDialog = this.dialog.open(OrderAttachmentComponent, {
        data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:false}
    });
  
    editingDialog.afterClosed().subscribe((res) => { });
  }
  getAuctionOrderWatch() {  


    if (this.isBroker()  ) 
    {
      this. getCompanyOrders();
    } else if (this.isEMX()  )  {

      this.getAllOrders();
    }

  }

  getCompanyOrders(){
   
    this.getUserBrokerInfo().subscribe(res => {
      if (res  != null ) { 
        this.companyID=res.CompanyId;
        this.brokerID=res.BrokerId;
        this.Auctionservice.getMyOrdersActionByDate (
             this.companyID,   this.auction.Id, this.gridState.skip, this.gridState.take)
             .subscribe(items => {this.items = items;  });

      }
    })
  }

  getAllOrders(){
    this.Auctionservice.getOrdersAuctionByDate( this.auction.Id,
      this.gridState.skip, this.gridState.take
     ).subscribe(items => {     
       this.items = items;
     });
  }
  IsOpen(){
    var isOpen = true;
 
     if(this.auction!=null){ 
   
      if (this.auction.Status != AuctionstatusEnum.Open)  {
       
        isOpen = false;
      }
     }

    return isOpen;
 
   }
  //////Update Order
  updateOrder(order) {
    var isAuction=false;
    if(order.AuctionID>0){
      isAuction=true;
    }
    const editingDialog = this.dialog.open(AuctionOrderTicketDialogComponent, {
      data: { id: order.OrderID, order: order  ,isAuction:isAuction}
    }
    );
    editingDialog.afterClosed().subscribe((res) => { 
        this.getAuctionOrderWatch();
    }); 
  }
  viewOrder(order) {
    var isAuction=false;
    if(order.AuctionID>0){
      isAuction=true;
    }
    const editingDialog = this.dialog.open(AuctionOrderTicketDialogComponent, {
      data: { id: order.OrderID, order: order ,isViewOnly:true,isAuction:isAuction }
    }
    );
    editingDialog.afterClosed().subscribe((res) => { }); 
  }

  //////  Order History
  getOrderHistory(order) {
    const editingDialog = this.dialog.open(OrderHistoryComponent, {
      data: {  OrderID: order.OrderID ,SymbolCode : order.SymbolCode }
    });
    editingDialog.afterClosed().subscribe((res) => { });

  }

  dialogHTML: string = `
 <button mat-raised-button type="button" (click)="openDialog()" color="primary">Open Dialog</button>
 <p *ngIf="result">You chose: {{ result }}</p>`;

  /**
   * OpenDialog method is used to open a dialog.
   */
  cancelOrder(order, index) {

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to cancel this order number ' + order.OrderID + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.orderManagementApi.cancelOrder(order.OrderID).subscribe((res) => {

          // this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
      //    console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
  
  getCompany(id:any): Observable<CompanyDto> {
    
    return this.companyService.getCompany(id);
  }

  ngOnInit() {
 

  }

  @ViewChild(GridComponent)
  public grid: GridComponent;

  public onDataStateChange(): void {
    // this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(2))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }


}
