import { TransactionDto } from './../../models/warehouse';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  
import {   StoreDto } from 'app/models/store';
import { StoreKeeperDto, TransactionLookupDto ,TransactionReuqest} from 'app/models/warehouse';
import { symbol } from 'd3-shape';
import { TransactionTypes } from 'app/core/enums';

@Injectable()
export class WarehouseApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}


  getSymbolQtySummary() {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoreSymbolQty`);
  }

  getClientsPosition(selectedClientID : number) {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getClientPosition/${selectedClientID}`);
  }



 

  getCities(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStores`);
  } 


  getStoreTypes(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStoreTypes`);
  } 
getWarehouses(): Observable< []> {
  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStores`);
} 
 getActiveWarehouses(): Observable< []> {
  return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getActiveStores`);
} 
insertWarehouse(WarehouseRequest: any): Observable<number> {
 
  return this.http.post<any>(`${this.API_URL}/insertStore`, 
  WarehouseRequest)
    .pipe(tap(data => {}));
}

updateWarehouse(WarehouseRequest: any,id :any): Observable<boolean> {

return this.http.put<any>(`${this.API_URL}/updateStore/`+id, 
WarehouseRequest)
  .pipe(tap(data => {}));
}

addStoreSymbol(storeSymbol: any,id :any): Observable<boolean> {
  return this.http.put<any>(`${this.API_URL}/addStoreSymbol/`+id, 
  storeSymbol)
    .pipe(tap(data => {

    }));
  }
  deleteStoreSymbol(StoreId,symbolId): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/removeStoreSymbol/`+StoreId+"/"+symbolId).pipe(tap(data => {}));
    }
    



deleteWarehouse(id: any): Observable<boolean> {

return this.http.delete<any>(`${this.API_URL}/deleteStore/`+id).pipe(tap(data => {}));
}


getStore(id:any){
  return this.http.get< StoreDto>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getStore/`+id); 
};


    getTransactionLookups() {
        return this.http.get<TransactionLookupDto>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/getTransactionLookups/`);
    }


    
    blockSellOrder(blockDto: any): Observable<number> {

        return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/block`,
            blockDto)
            .pipe(tap(data => { }));
    }
    deposit(depositDto){
      return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/deposite`,
      depositDto)
      .pipe(tap(data => { }));
    }

    getStoreTransactions(request: TransactionReuqest): Observable<[]> {
        let queryParameters: string = this.convertObjectToQueryParameters(request);
        return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/getStoreTransactions` + queryParameters);
    }


    convertObjectToQueryParameters(request): string {
        let queryParameters: string = "";
        for (let property in request) {
            if (request[property] != null)
                queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
        }
        if (queryParameters == "")
            return "";
        return queryParameters
    }

    insertStoreKeeper(keeper:StoreKeeperDto): Observable<any> {
      return this.http.post<any>(`${this.API_URL}/insertStoreKeeper`, 
      keeper)
        .pipe(tap(data => {
    
        }));
      }
      deleteStoreKeeper​(id): Observable<boolean> {
        return this.http.delete<any>(`${this.API_URL}/deleteStoreKeeper/`+id)
          .pipe(tap(data => {
      
          }));
        }
    
    
        updateStoreKeeper(keeper:StoreKeeperDto): Observable<boolean> {
          return this.http.put<any>(`${this.API_URL}/updateStoreKeeper/`+keeper.KeeperID, 
          keeper)
            .pipe(tap(data => {
        
            }));
          }
          



     deposite(transaction: any): Observable<number> {

            return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/deposite`,
            transaction)
                .pipe(tap(data => { }));
        }

      withdraw(transaction: any): Observable<number> {

        return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/withdraw`,
        transaction)
            .pipe(tap(data => { }));
    }

    depletion(transaction: any): Observable<number> {

      return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/depletion`,
      transaction)
          .pipe(tap(data => { })); 
    }
    transfer(transaction: any): Observable<number> {

      return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/transfer`,
      transaction)
          .pipe(tap(data => { }));
          
  }
  transferApprove(transaction: any): Observable<number> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/transferApprove`,
    transaction)
        .pipe(tap(data => { }));
        
  }
getStoreSymbolById(id:any){
  return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/GetStoreSymbolById/`+id );
}
getStoreSymbolByStoreId(id:any){
  return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/GetStoreSymbolByStoreId/`+id );
}


  handleTransaction(dto:TransactionDto){
  /// must set transaction type and msgtype before call this function
  let subscription:Observable<any>;
  if(dto.TransactionTypeID == TransactionTypes.deposit)
    subscription= this.deposit(dto);
  else if(dto.TransactionTypeID == TransactionTypes.withdrw)
    subscription= this.withdraw(dto);
  else if(dto.TransactionTypeID ==  TransactionTypes.deleption)
    subscription = this.depletion(dto);
  else if(dto.TransactionTypeID ==  TransactionTypes.transfer)
    subscription = this.transfer(dto);

  return subscription;
  }


  geFarmers( ): Observable<[]> { 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/geFarmers` );
  }
  geFarmerTransactions   (request: any): Observable<any> {
      let queryParameters: string = this.convertObjectToQueryParameters(request);
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/geFarmerTransactions`+queryParameters );
  }
  geReceiptStoreFarmers(request: any): Observable<any> {
    let queryParameters: string = this.convertObjectToQueryParameters(request);
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/geReceiptStoreFarmers`+queryParameters );
  }
  getReceiptStoreFarmerView(): Observable<any> {
 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/getReceiptStoreFarmerView` );
  }

  getFarmersByStoreId( id:any): Observable<[]> { 
    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/getFarmersByStoreId/`+id );
  }
  geFarmer( id:any): Observable<any> { 
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/geFarmer/`+id );
  }
  getQtyAuctionStoreFarmers( id:any , symbolId:any): Observable<any> { 
    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/getQtyAuctionStoreFarmers/`+id +'/'+symbolId);
  }
  insertFarmer(farmer: any): Observable<any> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/createFarmer`,
    farmer)   .pipe(tap(data => { }));
        
  }
  addOrUpdateStoreFarmer( farmer: any): Observable<any> {

    return this.http.post<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/addOrUpdateStoreFarmer`,
    farmer)   .pipe(tap(data => { }));
        
  }
  updateAuctionStoreFarmer( request: any): Observable<any> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/updateAuctionStoreFarmer`,
    request)   .pipe(tap(data => { }));
        
  }
  
  
  updateFarmer(id:any,farmer: any): Observable<any> {
    return this.http. put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/updateFarmer/`+id , farmer)   .pipe(tap(data => { }));
  }
  updateReceiptStoreFarmer(id:any,receiptStoreFarmer: any): Observable<any> {
    return this.http. put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/updateReceiptStoreFarmer/`+id , receiptStoreFarmer)   .pipe(tap(data => { }));
  }
  deleteFarmer(id:any): Observable<any> {

    return this.http.delete<any>(`${AppConsts.bussinessApiUrl}${AppConsts.storeUrl}/deleteFarmer/`+id ).pipe(tap(data => {}));
    }
    

}