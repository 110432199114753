<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
   <mat-card fxFlex="100%">
      <div class="gene-card-title">
         <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
               <h4>{{title| translate }} </h4>
            </div>
            <span fxFlex></span>
         </div>
         <mat-divider></mat-divider>
      </div>
      <div class="gene-card-content">
         <div class="form-tab trade-crypto crypto-input-prefix">
            <mat-tab-group [selectedIndex]="selectedIndex"   (selectedTabChange)="tabChanged($event)" >
               <mat-tab label=" {{ 'Basic Info' | translate }}">
                  <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewbrokerForm">
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>  {{ 'Full Name' | translate }}  </mat-label>
                                 <input matInput placeholder=" {{ 'Full Name' | translate }}" formControlName="Name"
                                    [(ngModel)]="broker.Name" required>
                              </mat-form-field>
                           </div>
 


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Type' | translate }} </mat-label>
                                 <mat-select name="" [(ngModel)]="broker.BrokerType" formControlName="brokerType">
                                    <mat-option *ngFor="let brokerType of brokerTypeList" [value]="brokerType.id">
                                       {{brokerType.name}}
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                    <mat-label>{{ 'Date Of Birth' | translate }} </mat-label>
                                    <input matInput [matDatepicker]="picker"  
                                    [(ngModel)]="broker.DateofBirth"
                                     formControlName="DateofBirth"
                                [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                           </div>




                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Identity Type' | translate }}</mat-label>
                                 <mat-select name="" [(ngModel)]="broker.IdentityTypeID"
                                    formControlName="IdentityTypeID">
                                    <mat-option *ngFor="let identityType of identityTypeList" [value]="identityType.Id">
                       
                                       <span 	*ngIf="!isRtl()">{{identityType.IdentityTypeEn}}</span> 
                                       <span 	*ngIf="isRtl()">{{identityType.IdentityTypeAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Identity' | translate }} </mat-label>
                                 <input matInput placeholder=" {{ 'Identity' | translate }}" formControlName="NationalID"
                                    [(ngModel)]="broker.NationalID" required>
                              </mat-form-field>
                           </div>


                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Issue Date' | translate }}   </mat-label> 
                                    <input matInput [matDatepicker]="picker2"  [max]="maxDate" (dateInput)="onClose()"
                                    [(ngModel)]="broker.IssueDate" formControlName="IssueDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>


                              </mat-form-field>
                           </div>


                        </div>

                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Expiry Date' | translate }}  </mat-label>
              

                                    <input matInput [matDatepicker]="picker1"  [disabled]="isNationalId"
                                    [min]="minExpiryDate" [(ngModel)]="broker.ExpiryDate" formControlName="ExpiryDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                           </div>
                           
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Nationality' | translate }}   </mat-label>
                                 <mat-select name="NationalityID" [(ngModel)]="broker.NationalityID" formControlName="NationalityID">
                                    <mat-option *ngFor="let nationality of statuslookups.Nationalities"
                                       [value]="nationality.NationalityID">
                                      <span 	*ngIf="!isRtl()">{{nationality.NationalityEn}}</span> 
                                      <span 	*ngIf="isRtl()">{{nationality.NationalityAr}}</span> 
                                    </mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div>
                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">

                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Telephone' | translate }} </mat-label>
                                 <input matInput placeholder="{{ 'Telephone' | translate }} " formControlName="Telephone"
                                    [(ngModel)]="broker.Telephone" required>
                              </mat-form-field>
                           </div>

                        </div>

                        <div fxLayout="row wrap" fxLayoutAlign="space-between center"> 

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Email' | translate }} </mat-label>
                                 <input matInput placeholder="{{ 'Email' | translate }}" formControlName="Email" [(ngModel)]="broker.Email"
                                    required>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label> {{ 'Address' | translate }} </mat-label>
                                 <textarea matInput placeholder="{{ 'Address' | translate }}" [(ngModel)]="broker.Address"
                                    formControlName="Address" ></textarea>
                              </mat-form-field>
                           </div>

                           <div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
                              <!-- <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{ 'Main Address' | translate }}  </mat-label>
                                 <textarea matInput placeholder= "{{ 'Main Address' | translate }}" formControlName="MainAddress"
                                    [(ngModel)]="broker.MainAddress" ></textarea>
                              </mat-form-field> -->
                           </div>
                        </div> 

                        <div fxLayout="row" fxLayoutAlign="start">


                           <button 
                           (click)="onBasicFormSubmit()" [disabled]="!addNewbrokerForm.valid" 
                             type="submit" mat-raised-button color="warn"
                           *ngIf="auth.isGranted(_permissions.EditBrokers)">
                           <span *ngIf="!(broker.Id>0)">  {{ 'Next' | translate }}</span>
                           <span *ngIf="(broker.Id>0)">  {{ 'Submit' | translate }}</span>
                           </button>
                           <a  [routerLink]="['/brokers/brokers/']"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                        </div>
                     </div>
                  </form>
               </mat-tab>
 
               <mat-tab label=  "{{ 'Accounts' | translate }}" 
               *ngIf="(broker.Id>0)"
               [disabled]="!(broker.Id>0)" >

               <div fxLayout="row wrap" fxLayoutAlign="space-between center"  >
                  <!-- <ms-broker-bank brokerId={{brokerId}}>  </ms-broker-bank> -->
                  <ms-broker-company-list   brokerId={{brokerId}}>   </ms-broker-company-list>
                </div> 
               </mat-tab>
 



            </mat-tab-group>
         </div>

      </div>
   </mat-card>
</div>