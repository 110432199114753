

<div class="user-manage-list">



    <mat-card>

 
        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="mrgn-r-sm">
                  <h4>{{storeKeeper.StoreInfo?.StoreNameEn}}</h4>
                </div>
                <div class="mrgn-l-sm mrgn-r-sm">
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="">

      <kendo-grid [data]="view"
       [resizable]="false"
        [reorderable]="true" 
        [filterable]="false" class="kendo-grid-list" [sort]="gridState.sort" [sortable]="true"  >

        <kendo-grid-column field="SymbolCode" title="{{translate('Commodity code')}}" headerClass="grid-list-column-header" width="80">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.SymbolCode}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="SymbolName" title="{{translate('Commodity')}}" headerClass="grid-list-column-header" width="80">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
            <span >{{isRtl()?dataItem.NameAr:dataItem.NameEn}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="OriginalQty" title="{{translate('Original Qty')}}" headerClass="grid-list-column-header" width="30">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.OriginalQty| number }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="BlockedQty" title="{{translate('Blocked Qty')}}" headerClass="grid-list-column-header" width="30">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.BlockedQty| number }}
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="SoldQty" title="{{translate('Sold Qty')}}" headerClass="grid-list-column-header" width="30">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.SoldQty| number }}
          </ng-template>
        </kendo-grid-column> -->

        <kendo-grid-column field="WastedQty" title="{{translate('Wasted Qty')}}" headerClass="grid-list-column-header" width="30">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.WastedQty| number }}
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-grid-column field="UnderSettlementTransferQty" title="{{translate('Under settlement transfer Qty')}}" headerClass="grid-list-column-header" width="60">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.UnderSettlementTransferQty| number }}
          </ng-template>
        </kendo-grid-column> -->

        <kendo-grid-column field="AvailableQty" title="{{translate('Available Qty')}}" headerClass="grid-list-column-header" width="30">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.AvailableQty| number }}
          </ng-template>
        </kendo-grid-column>

            </kendo-grid>



        </div>

    </mat-card>
</div>