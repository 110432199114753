import { Injectable } from '@angular/core';
import { BrokerDto, CompanyDto } from '../../../app/models/broker';
import { Observable } from 'rxjs';
 
import {BankManagementApi} from '../api/bank-management.api'
import { BankDto } from 'app/models/bank';

@Injectable()
export class BankManagementService {

  
  constructor(private bankCompanyApi: BankManagementApi ) { }

 
 
  getBanks(): Observable<any> { 
    return this.bankCompanyApi.getBanks();
  }
  getActiveBanks(): Observable<[]> { 
    return this.bankCompanyApi.getActiveBanks();
  } 
  getBank(id:any): Observable<BankDto> { 
    return this.bankCompanyApi.getBank(id);
  } 
  insertBank(createBankRequest: any): Observable<boolean> {
    return this.bankCompanyApi.insertBank(createBankRequest);
  }
  
  updateBank(BankRequest: any,id:any): Observable<boolean> {
    return this.bankCompanyApi.updateBank(BankRequest,id);
  }

  deleteBank(id: any): Observable<boolean> {
    return this.bankCompanyApi.deleteBank(id);
  } 


 
 
 
 
}