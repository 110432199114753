 
import {BrokerListComponent} from '../broker-management/containers/broker-list/broker-list.component';
import { Routes } from '@angular/router';  
import { BrokerFormComponent } from './components/broker-form/broker-form.component';
 

export const BrokerManagementRoutes: Routes = [
  {
     path: '',
     redirectTo: 'brokers',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
         {
         path: 'brokers',
         component:  BrokerListComponent
         }  ,         {
            path: 'create',
            component:  BrokerFormComponent
         }    ,         {
            path: 'edit/:id',
            component:  BrokerFormComponent
         } 
     ]
  }
  
];