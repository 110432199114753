export class OrderDto {
  OrderID: number;
  MsgType: string;
  OrderType: number;
  OrderDate: Date;
  SymbolCode: string;
  MarketID: number;
  OrderQty: number;
  OrderValue: number;
  RemainingQty: number;
  OrderPrice: any;
  OrderValidity: number;
  GoodTillDate: Date;
  OrderStatus: number;
  MinimumFill: number;
  BrokerID: number;
  CompanyID: number;
  SymbolID: number;
  CustodianID: number;
  ClientID: null;
  Locked: boolean;
  Suspended: boolean;
  AuctionID: number;
  isUpdate: boolean;
  OrderStatusTxt: string;
  OrdersHistories: OrderDto[];
  MsgTypeTxt: string;
  StoreNameAr: string;
  StoreNameEn: string;
  SymbolNameAr: string;
  SymbolNameEn: string;
  ClientNameAr: string;
  ClientNameEn: string;
  MarketNameAr: string;
  MarketNameEn: string;
  
   ClientCode : string;
   BrokerCode : string;
   CompanyCode : string;
   IsAttachment: number;
}

export class OrderReuqest {
  Skip: number;
  Take: number;
  CompanyId: number;
  StoreID: number;
  Status: number;
  AuctionId: number;
  AuctionOrdersOnly: boolean;
  TradingAuction: number;
  GoldOrderOnly: boolean;
  AllBrokers: boolean;
  FromDate: any;
  ToDate: any;
  SymbolCode: string;
  MarketId: number;
  ClientId: number;
  OrderType: number;
  userId: number;
  LastUpdatedTime: Date;
  GeneralFilter: string;
  Id: any;
  IsCancelled: boolean;
}
export class OrderModel {
  All: number;
  Open: number;
  Matched: number;
  Withdrawn: number;
  Expired: number;
  Private: number;
}

export class OrderTradeDto {
  OrderID: number;
  OrderType: number;
  OrderDate: Date;
  BrokerID: number;
  CompanyID: number;
  StoreID: number;
  AuctionID: number;

  ClientID: number;
  SymbolCode: number;
  MarketID: number;
  OrderStatusTxt: string;
  ClientNameEn: string;
  ClientNameAr: string;
  SymbolNameEn: string;
  SymbolNameAr: string;
  StoreNameEn: string;
  StoreNameAr: string;
  StoreLocation: string;
  TradeDate: Date;
  TradedQty: number;
  TradePrice: number;
  Unit: number;
  OrderStatus: number;
}