import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission'; 
import { SignalRService } from '../../../service/signalr/signalr.service'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { Observable } from 'rxjs';
import { TradeDto } from 'app/models/trade';
import { State } from '@progress/kendo-data-query';
import { GoldMarketApi } from 'app/gold-market-management/api/gold-market.api';
 
@Component({
  selector: 'ms-gold-trades',
  templateUrl: './gold-trades.component.html',
  styleUrls: ['./gold-trades.component.scss']
})
export class GoldTradesComponent   extends AppComponentBase implements OnInit {

    
  public items:any= TradeDto;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 50
  };
   constructor( 
    private readonly signalrService: SignalRService,
    private goldMarketApi:GoldMarketApi ,
    injector: Injector
    ) {
      super(injector); 
 

    signalrService.goldTradeChanges.subscribe(item => { 
      let trades = JSON.parse(item);

      trades.forEach((element, index) => {

        if (this.items != null) {
         
          if ((this.items.filter(x => x.TradeID === trades[index].TradeID).length > 0)) {
         
            this.items.filter(x => x.TradeID == trades[index].TradeID)[0].TradeDate = trades[index].TradeDate;

              this.items.filter(x => x.OrderID == trades[index].OrderID)[0].TradedQty
              = trades[index].TradedQty;
              this.items.filter(x => x.OrderID == trades[index].OrderID)[0].TradePrice
              = trades[index].TradePrice;
 
            // this.items[index].isUpdate = true;
          } else {
            // this.items[index].isUpdate = false;
             this.items = [element, ...this.items];
             this.items= this.items .slice(0, this.gridState.take);
             this.items.sort(function(a, b) {
              return b.TradeID - a.TradeID;
            });
          }
        } 

      }); 
     });


 
  }

 
  getTrades()
  {   
   
    this.goldMarketApi.getTrades(this.gridState.skip,this.gridState.take) .subscribe(items => { this.items = items;  });
  } 
  

  ngOnInit(): void {
    this.  getTrades();
    
  }
  
}


