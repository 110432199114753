import { Injectable } from "@angular/core";

@Injectable()
export class EnumHelpers {

    constructor( ) {
       
    }
 

    getNamesAndValues(e: any):any {
         
        var output=[];
         var resultArray = Object.keys(e).map(function(index){
            let result = e[index]; 
            output.push({id:result ,name: index})
            
        });  
        return output;
    }

    getNames(e: any) :any{
        return Object.keys(e);
    }

    getNamesByValue(e: any ,value:any):any {
        var result="";
    
        if(value!=null){
            var output=[];
            var resultArray = Object.keys(e).map(function(index){
               let result = e[index]; 
               output.push({id:result ,name: index})
           });  
             result= output.filter(x=>x.id==value)[0].name;
        }
        return result;

    }

   
}

