import { Component, ElementRef, Inject, Injector, OnInit, Optional, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { Helpers } from 'app/helper/helpers';
import { SetupApi } from 'app/Shared/api/setup.api';


@Component({
  selector: 'ms-auction-news',
  templateUrl: './auction-news.component.html',
  styleUrls: ['./auction-news.component.scss']
})

export class AuctionNewsComponent extends AppComponentBase implements OnInit {

  errors: any;
  title:any;
  statisticsData: any;
  addNewsForm: FormGroup;

  constructor(public formBuilder: FormBuilder,
    private Auctionservice: Auctionservice,
    private dialog: MatDialog,
    private  dialogRef: MatDialogRef<AuctionNewsComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) data: any, 
    injector: Injector) {
     
      super(injector);
      if (data != null && data.data != null) {
        this.statisticsData=data.data;
        this.title= "Add Auction News";
      } 
      else
      {
        this.title="Add Auction News";
      }
  }

  ngOnInit(): void {
    this.addNewsForm = this.formBuilder.group({
      AuctionId: [, Validators.required],
      HeaderEn: [, [Validators.required]],
      HeaderAr: [, [Validators.required]],
      DetailsEn: [, [Validators.required]],
      DetailsAr: [, [Validators.required]]
    });
  }

  close() {
 
    this.dialogRef.close(false);
  }

  onSubmit(){

    var confirm = this.dialog.open(DemoDialog, {
      data: { message: 'Are you sure ?' },
      disableClose: false
    });

    confirm.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.addNewsForm.controls['AuctionId'].setValue(this.statisticsData.Id);
        this.Auctionservice.addAuctionNews(this.addNewsForm.value).subscribe(() => {
          this.dialogRef.close(this.addNewsForm.value);
          this.toastr.success(this.translate("Successfully"));
        },
        err => {
          this.toastr.error(err);
        });
      }
    })

    }
  }