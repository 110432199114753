import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { permissions } from 'app/core/permission'; 
import { EnumHelpers } from 'app/helper/EnumHelpers';

import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';  
import { SetupApi } from 'app/Shared/api/setup.api';
@Component({
  selector: 'ms-properties-status-dialog',
  templateUrl: './properties-status-dialog.component.html',
  styleUrls: ['./properties-status-dialog.component.scss']
})
export class PropertiesStatusDialogComponent   extends AppComponentBase implements OnInit {

  title: string;
  propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
  entityTypes: any; 
  entityActionTypeEnum: any; 
  addNewPropertiesStatusForm: FormGroup;  
  reasonStatus:any;
  status=StatusEnum;
  errors:any;  
  click:any=false;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PropertiesStatusDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers: EnumHelpers,
    private setupApi: SetupApi,    injector: Injector
    ) {
      super(injector);
     
      if(data.propertiesStatus!=null){
        
           this.propertiesStatus=data.propertiesStatus;
          
           if(this.isRtl()){
            this.propertiesStatus.ResponsibleName= this.propertiesStatus.ResponsibleNameAr;
           }else{
            this.propertiesStatus.ResponsibleName= this.propertiesStatus.ResponsibleNameEn;
           }
      }  
      this.propertiesStatus.Id=0;
      this.title=this.translate("Change Status " )+ this.propertiesStatus.ResponsibleName;
    }

  ngOnInit(): void {
    this.addNewPropertiesStatusForm = new FormGroup({  
       ResponsibleName: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       ActionDate: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       ActionType: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       ActionOn: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
      // actionOnNameseaRCHbs: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       Remark: new FormControl({ value: '' } ),
       ReasonId: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       NotifiledEmail: new FormControl({ value: '' }, Validators.compose([Validators.required ])),
       CCEmail: new FormControl({ value: '' }, Validators.compose([Validators.required ]))
    }); 
    this.propertiesStatus.ActionDate=new Date();
    this.entityTypes=this._helpers.getNamesAndValues(EntityTypeEnum);
    this.entityActionTypeEnum=this._helpers.getNamesAndValues(StatusEnum)
    .filter(x=>x.id!=      this.propertiesStatus.Status);
    this.propertiesStatus.ActionType=this.entityActionTypeEnum[0].id;
    this.  getReasonStatus();
  }

  
  close() {
 
    this.dialogRef.close(this.propertiesStatus.Status);
  } 

  getReasonStatus()
  {
    this.setupApi.getReasonStatus().subscribe(res=>{
        this.reasonStatus=res;
        this.reasonStatus=this.reasonStatus.filter(x=>x.StatusType!= this.propertiesStatus.Status);
    });
  }
  onFormSubmit() {  
    if(!this.click)
    {
      
    //  this.click=true;
      this.errors = [];    
      this.propertiesStatus.ResponsibleId=   this.propertiesStatus.ResponsibleId+"";
      this.setupApi.UpdatePropertiesStatus(this.propertiesStatus).subscribe(() => {
     
        this.dialogRef.close(  this.propertiesStatus.ActionType);
       
        // if(this.propertiesStatus.UserId!=null){
        //   if(  this.propertiesStatus.Status== this._StatusEnum.Active){
        //     this.setupApi.SuspendUser(this.propertiesStatus.UserId).subscribe(res=>{
        //       this.toastr.success ("Successfully!");
        //     });
        //   }else {
        //     //if(this.propertiesStatus.IgnoreUnsuspend!=true){
        //     this.setupApi.UnsuspendUser(this.propertiesStatus.UserId).subscribe(res=>{
        //       this.toastr.success ("Successfully!");
        //     });
        //   }
        // }


       
      },
      err =>{   this.errors.push(err.error[0]);
             this.toastr.error(err.error[0]);
        } );
    }
  }



    
}
