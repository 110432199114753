import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, Injector } from '@angular/core';
import { Subscription, Observable } from 'rxjs';

import { SignalRService } from '../../../service/signalr/signalr.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
 
import { ResizeEvent } from 'angular-resizable-element'; 
import { DatePipe } from '@angular/common';
 
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { SetupApi } from 'app/Shared/api/setup.api';
 

import { AppComponentBase } from '../../../app-component-base';
import { GoldOrderComponent } from 'app/gold-market-management/components/gold-order/gold-order.component';
 
@Component({
  selector: 'ms-gold-market-dashboard',
  templateUrl: './gold-market-dashboard.component.html',
  styleUrls: ['./gold-market-dashboard.component.scss']
})
export class GoldMarketDashboardComponent extends AppComponentBase implements OnInit {

  marketStatus:any;
  title:any;
  constructor(

    private pageTitleService: PageTitleService,
 
    injector: Injector ) {
  
    super(injector);
    this.title=this.translate("Gold Market Dashboard");
    this.pageTitleService.setTitle("Gold Market Dashboard");



  }
 
   
  ngOnInit() {   }

  public style: object = {};

  validate(event: ResizeEvent): boolean {
    const MIN_DIMENSIONS_PX: number = 50;
    if (
      event.rectangle.width &&
      event.rectangle.height &&
      (event.rectangle.width < MIN_DIMENSIONS_PX ||
        event.rectangle.height < MIN_DIMENSIONS_PX)
    ) {
      return false;
    }
    return true;
  }
  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      position: 'fixed',
      left: `${event.rectangle.left}px`,
      top: `${event.rectangle.top}px`,
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`
    };
  }
  

}
