<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
   <mat-card fxFlex="100%">
      <div class="gene-card-title">
 
         <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
             
               <div class="mrgn-l-sm mrgn-r-sm">
                   <h4>{{title}}</h4>
               </div>
            </div>
            <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center"
              *ngIf="isUpdate &&  status != ClientStatus.Approve"  >
               <mat-card-actions class="mat-pad-none margin-none">
                  <a
                  title="{{ 'Comments' | translate }}"
                  (click)="getCommentsDialog()"
                  >  
                  <mat-icon class="mrgn-l-sm mode_comment_icon count-comments"
                  *ngIf="commentsCount>0"
                  matBadge="{{commentsCount}}" matBadgeColor="warn" >mode_comment</mat-icon>

                  <mat-icon class="mrgn-l-sm mode_comment_icon count-comments"
                  *ngIf="!(commentsCount>0)"
                  matBadgeColor="warn" >mode_comment</mat-icon>               
               </a>
               </mat-card-actions>
            </div>
         </div>
         <mat-divider></mat-divider>
      </div>

      <div class="gene-card-content">
         <div class=" trade-crypto crypto-input-prefix">
            <mat-tab-group [selectedIndex]="selectedIndex"   (selectedTabChange)="tabChanged($event)" >
               <mat-tab label=" {{ 'Basic Info' | translate }}">
                  <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewClientForm">
                 
                     <mat-accordion class="mrgn-all-md" multi>
                        <ng-container   >

                           <mat-expansion-panel [expanded]="true">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <h4 class=" mrgn-b-none">
                                       <span class="form-title cc primary-text mrgn-r-sm">
                                          <mat-icon class="cursor-pointer"  >info</mat-icon>
                                       </span>{{ 'Personal Information'| translate }} </h4>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>

                              <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                                 <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                                    <mat-form-field 
                                   
                                    class="full-wid form-field" appearance="outline">
                                       <mat-label>  {{ 'Full Name' | translate }}  </mat-label>
                                       <input matInput
                                       readonly='{{this.isUpdate}}' 
                                       placeholder=" {{ 'Full Name' | translate }}" formControlName="NameAr"
                                          [(ngModel)]="client.NameAr" required>
                                    </mat-form-field>
                                 </div>  

                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Nationality' | translate }}   </mat-label>
                                       <mat-select 
                                       [disabled]='isUpdate' readonly='isUpdate'
                                       name="NationalityID" [(ngModel)]="client.NationalityID" formControlName="NationalityID">
                                          <mat-option *ngFor="let nationality of nationalities"
                                             [value]="nationality.NationalityID">
                                      
                                             <span  *ngIf="isRtl()"> {{nationality.NationalityAr}}   </span>
                                             <span  *ngIf="!isRtl()"> {{nationality.NationalityEn}}   </span>
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
      
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ 'Client Type' | translate }} </mat-label>
                                       <mat-select
                                       [disabled]='isUpdate' readonly='isUpdate'
                                       (selectionChange)="onChangeClientType($event.value)"
                                        name="ClientType" [(ngModel)]="client.ClientType" 
                                        formControlName="ClientType">
                                          <mat-option 
                                          [disabled]='isUpdate' readonly='isUpdate'
                                          *ngFor="let clientType of clientTypes" [value]="clientType.id">
                                             {{clientType.name | translate }}
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
                              </div>

                           </mat-expansion-panel>

                          <mat-expansion-panel [expanded]="true" >
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <h4 class="form-title mrgn-b-none"
                                    
                                    *ngIf="client.ClientType == ClientTypeEnum.Individual">
                                    <span class="form-title cc primary-text mrgn-r-sm">
                                       <mat-icon class="cursor-pointer"  >info</mat-icon>
                                    </span>
                                    {{ 'Legal Information'| translate }} </h4>

                                       <h4 class="form-title mrgn-b-none"
                                       *ngIf="client.ClientType == ClientTypeEnum.Corporate">
                                       <span class="form-title cc primary-text mrgn-r-sm">
                                          <mat-icon class="cursor-pointer"  >info</mat-icon>
                                       </span>
                                       {{ 'Corporate Information'| translate }} </h4>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>

                              <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Identity Type' | translate }}</mat-label>
                                       <mat-select 
                                       [disabled]='isUpdate'   [(ngModel)]="client.IdentityTypeID"
                                       (selectionChange)="onChangeIdentityType($event.value)" 
                                          formControlName="IdentityTypeID">
                                          <mat-option *ngFor="let identityType of identityTypes" [value]="identityType.Id">
                                       
                                             <span *ngIf="isRtl()">{{identityType.IdentityTypeAr}}</span>
                                             <span *ngIf="!isRtl()">{{identityType.IdentityTypeEn}}</span>
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
      
      
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ IdentityTypeText}}  </mat-label>
                                       <input matInput

                                       readonly='{{this.isUpdate}}' 
                                       placeholder=
                                       " {{ IdentityTypeText}}" formControlName="IdentityTypeText"
                                          [(ngModel)]="client.IdentityTypeText" required>
                                    </mat-form-field>
                                 </div>
      
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                 *ngIf="client.ClientType == ClientTypeEnum.Individual">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                          <mat-label>{{ 'Date Of Birth' | translate }} </mat-label>
                                          <input matInput [matDatepicker]="picker"  
                                          [(ngModel)]="client.DateofBirth"
                                          [disabled]='isUpdate' readonly='isUpdate'
                                           formControlName="DateofBirth"
                                      [max]="maxDate">
                                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                          <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                 </div>


                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100"
                                 *ngIf="client.ClientType == ClientTypeEnum.Corporate"
                                 fxFlex.gt-md="24">
                                   <mat-form-field class="full-wid form-field" 
                           
                                   appearance="outline">
                                      <mat-label> {{ IdentityTypeC1Text}} </mat-label>
                                      <input matInput 
                                      readonly='{{this.isUpdate}}'  
                                      placeholder="{{ IdentityTypeC1Text}}" formControlName="IdentityTypeC1Text"
                                         [(ngModel)]="client.IdentityTypeC1Text"  >
                                   </mat-form-field>
                                </div>

                                <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" 
                                *ngIf="client.ClientType == ClientTypeEnum.Corporate"
                                fxFlex.gt-md="24">
                                   <mat-form-field class="full-wid form-field"
                                    appearance="outline"
                                 >
                                      <mat-label> {{ IdentityTypeC2Text }} </mat-label>
                                      <input matInput 
                                      readonly='{{this.isUpdate}}'  
                                      placeholder="{{ IdentityTypeC2Text }}" formControlName="IdentityTypeC2Text"
                                         [(ngModel)]="client.IdentityTypeC2Text"  >
                                   </mat-form-field>
                                </div>


                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                 *ngIf="client.ClientType == ClientTypeEnum.Individual">
                                    <mat-form-field class="full-wid form-field" appearance="outline"
                                  >
                                       <mat-label>{{ 'Gender' | translate }}</mat-label>
                                       <mat-select 
                                       [disabled]='isUpdate' readonly='isUpdate'
                                       name="Gender" [(ngModel)]="client.Gender"
                                          formControlName="Gender">
                                          <mat-option *ngFor="let gender of genders" [value]="gender.id">
                                             {{gender.name | translate }} 
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
                                 
                              </div>
                              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Issue Date' | translate }}   </mat-label> 
                                          <input matInput [matDatepicker]="picker2"  
                                          [max]="maxDate" (dateInput)="onClose()"
                                          [(ngModel)]="client.IssueDate" formControlName="IssueDate">
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker #picker2></mat-datepicker>
      
                                    </mat-form-field>
                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Expiry Date' | translate }}  </mat-label>
                    
                                          <input matInput [matDatepicker]="picker1"  [min]="minExpiryDate"
                                           [(ngModel)]="client.ExpiryDate" formControlName="ExpiryDate" [disabled]="isNationalId">
                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                          <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                 *ngIf="client.ClientType == ClientTypeEnum.Individual">
                
                                 
                                </div>
                                <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                *ngIf="client.ClientType == ClientTypeEnum.Individual">
                 
                                </div>

                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                 *ngIf="client.ClientType == ClientTypeEnum.Corporate" >
 
                                 <mat-form-field class="full-wid form-field" 
                                     appearance="outline" >
                                    <mat-label>{{ 'Office' | translate }}</mat-label>
                                    <mat-select name="CROfficeId" [(ngModel)]="client.CROfficeId"
                                       formControlName="CROfficeId"
                                       (selectionChange)="onChangeOffice($event.value)">
                                       <mat-option>
                                          <ngx-mat-select-search name="office" 
                                             placeholderLabel="{{translate('Enter At Least One Character')}}"
                                             noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                             formControlName="CROfficeName"
                                             (ngModelChange)="onFilterOffice($event)">
                                          </ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let office of filteredOffices" [value]="office.Id">
                                          <span *ngIf="isRtl()">{{office.NameAr}}</span>
                                          <span *ngIf="!isRtl()">{{office.NameEn}}</span>
                                       </mat-option>
                                    </mat-select>
                                 </mat-form-field>

                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24"
                                 *ngIf="client.ClientType == ClientTypeEnum.Corporate" >
 
                                 <mat-form-field 
                                  class="full-wid form-field" appearance="outline" >
                                    <mat-label>{{ 'Office Address' | translate }}</mat-label>
                                    <input matInput placeholder="{{ 'Office Address' | translate }}" [(ngModel)]="CROfficeAdress" formControlName="CROfficeAdress"  
                                    readonly>
                                 </mat-form-field>

                                 </div>
                              </div>     
                           </mat-expansion-panel>                    
                           <mat-expansion-panel [expanded]="true"
                           *ngIf="auth.isGranted(_permissions.GetAttachmentTypes)">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <h4 class=" mrgn-b-none">
                                       <span class="form-title cc primary-text mrgn-r-sm">
                                          <mat-icon class="cursor-pointer"  >info</mat-icon>
                                       </span>Attachments</h4>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div fxLayout="row wrap" fxLayoutAlign="start" class="gene-file-upload">
                                 <mat-card fxFlex="100%" class="attachments-card">
                                    
                                    <div class="gene-card-content pad-b-none">
                                       <div fxLayout="row wrap" >
                                          <div fxFlex.gt-md="25" fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="25">
                                             <mat-form-field class="form-attachment-type full-wid form-field " appearance="outline"  >
                                                  <mat-label>{{ 'Attachment Type' | translate }}</mat-label>
                                                  <mat-select 
                                     
                                                  [(ngModel)]="attachmentType"
                                                  name="attachmentType" 
                                                     formControlName="attachmentType">
                                                     <mat-option *ngFor="let attachment of attachmentTypes" [value]="attachment.Id">
                                                
                                                        <span *ngIf="isRtl()">{{attachment.NameAr}}</span>
                                                        <span *ngIf="!isRtl()">{{attachment.NameEn}}</span>
                                                     </mat-option>
                                                  </mat-select>
                                               </mat-form-field>
                                           
                                             <input class="mrgn-b-md" type="file" 
                                             [disabled]="!(attachmentType>0)"
                                             (onFileSelected)="onFileSelected($event)"
                                             ng2FileSelect [uploader]="uploader"  /><br />
                                          </div>
                                          <div fxFlex.gt-md="75" fxFlex.gt-sm="75" fxFlex.gt-xs="100" fxFlex="75">
                                             <div class="mrgn-b-md">

                                                <div class="set-overflow-x-auto">
                                                   <table class="table mb-3 material ml-0 mr-0 mrgn-b-md">
                                                      <thead>
                                                         <tr>
                                                            <th width="30%">{{ 'Name' | translate }}</th>
                                                            <th >{{ 'Type' | translate }}</th>
                                                            <th>{{ 'Size' | translate }}</th>
                                                            <th>{{ 'Version ' | translate }}</th> 
                                                            <th>{{ 'Actions' | translate }}</th>
                                                         </tr>
                                                      </thead>
                                                      <tbody>
                                                         <ng-container   *ngIf="clientattachments.length > 0">
                                                            <tr  *ngFor="let item of clientattachments">
                                                               <td> {{ item.AttachmentPath }}</td>
                                                               <td>   {{   _helper.getNamesByValue( AttachmentTypeEnum,item.AttachmentTypeId ) }} </td>
                                                               <td>  </td>

                                                               <td>  </td>
                                                               <td> </td>
                                                               <td nowrap>
                                                                  <button mat-raised-button mat-button-sm type="button" class="btn btn-success btn-xs btn-b-xs"  (click)="downloadAttachment(item.AttachmentPath)"  >
                                                                     <span class="glyphicon glyphicon-upload"></span> {{ 'Download' | translate }}
                                                                  </button>
                                                                  <button mat-raised-button mat-button-sm type="button" class="btn btn-success btn-xs btn-b-xs"  (click)="ViewAttachment(item.AttachmentPath)"  >
                                                                     <span class="glyphicon glyphicon-upload"></span> {{ 'View' | translate }}
                                                                  </button>
                                                                  
                                                               </td>
                                                            </tr>
                                                         </ng-container>
                                                         <ng-container *ngIf="uploader.queue.length > 0; else elseBlock">
                                                            <tr *ngFor="let item of uploader.queue">
                                                               <td>{{ item?.file?.name }}</td>
                                                               <td  >
                                                                  {{   _helper.getNamesByValue( AttachmentTypeEnum,item?.formData?.type ) }} 
                                                               </td>
                                                               <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2'
                                                                  }} MB</td>
                                          
                                                               <td class="text-center">
                                                                  <span *ngIf="item.isSuccess">
                                                                     <mat-icon>{{ 'check' | translate }}</mat-icon>
                                                                  </span>
                                                                  <span *ngIf="item.isCancel">
                                                                     <mat-icon>{{ 'cancel' | translate }}</mat-icon>
                                                                  </span>
                                                                  <span *ngIf="item.isError">
                                                                     <mat-icon>{{ 'delete' | translate }}</mat-icon>
                                                                  </span>
                                                               </td>
                                                               <td nowrap>
                                                                   
                                                                  <button mat-raised-button mat-button-sm type="button" class="btn btn-warning btn-xs btn-b-xs" (click)="item.cancel()" [disabled]="!item.isUploading">
                                                                     <span class="glyphicon glyphicon-ban-circle"></span> {{ 'Cancel' | translate }}
                                                                  </button>
                                                                  <button mat-raised-button mat-button-sm type="button" class="btn btn-danger btn-xs btn-b-xs" (click)="item.remove()">
                                                                     <span class="glyphicon glyphicon-trash"></span> {{ 'Remove' | translate }}
                                                                  </button>
                                                               </td>
                                                            </tr>
                                                         </ng-container>

                                                         <ng-template #elseBlock>
                                                            <!-- {{ ' No data to display' | translate }} -->
                                                         </ng-template>
                                                      </tbody>
                                                   </table>
                                                </div>
                                                <div class="mrgn-b-md">
                                                   <p>Queue progress:</p>
                                                   <mat-progress-bar mode="determinate" value="{{uploader.progress}}"></mat-progress-bar>
                                                </div>
                                                <!-- <button mat-raised-button mat-button-sm type="button" (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                                                   Upload all
                                                </button> -->
                                                <button mat-raised-button mat-button-sm type="button" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                                                   {{ 'Cancel all' | translate }}
                                                </button>
                                                <button mat-raised-button mat-button-sm type="button" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                                                   {{ 'Remove all' | translate }}
                                                </button>
                                             </div>
                                             <h6 fxLayout="row">
                                                <span fxFlex>Upload files</span>
                                                <span>Count: {{ uploader?.queue?.length }}</span>
                                             </h6>
                                          </div> 
                                       </div>
                                    </div>
                               
                                 </mat-card>
                              </div>

                           </mat-expansion-panel>

                          <mat-expansion-panel [expanded]="true" *ngIf="!(this.userTypeAssign>0)">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <h4 class="form-title mrgn-b-none">
                                       <span class="form-title cc primary-text mrgn-r-sm">
                                          <mat-icon class="cursor-pointer"  >info</mat-icon>
                                       </span>Contact Information</h4>
                                 </mat-panel-title>
                              </mat-expansion-panel-header>

                              <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
      
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Telephone' | translate }} </mat-label>
                                       <input matInput placeholder="{{ 'Telephone' | translate }} " formControlName="Telephone"
                                          [(ngModel)]="client.Telephone" required>
                                    </mat-form-field>
                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ 'Email' | translate }} </mat-label>
                                       <input matInput placeholder="{{ 'Email' | translate }}" formControlName="Email" [(ngModel)]="client.Email"
                                          required>
                                    </mat-form-field>
                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ 'Country' | translate }} </mat-label>
                                       <mat-select
                                       (selectionChange)="onChangeCountry($event.value)"
                                        name="CountryID" 
                                         [(ngModel)]="CountryID" 
                                         formControlName="CountryID">
                                         <mat-option>
                                          <ngx-mat-select-search name="country" 
                                             placeholderLabel="{{translate('Enter At Least One Character')}}"
                                             noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                             formControlName="CountryName"
                                             (ngModelChange)="onFilterCountry($event)">
                                          </ngx-mat-select-search>
                                        </mat-option>
                                          <mat-option *ngFor="let country of filteredCountries" [value]="country.CountryID"> 
                                             <span *ngIf="isRtl()">{{country.CountryNameAr}}</span>
                                             <span *ngIf="!isRtl()">{{country.CountryNameEn}}</span>
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>
                                 <div fxFlex.gt-sm="24" fxFlex.gt-xs="24" fxFlex="100" fxFlex.gt-md="24">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ 'City' | translate }} </mat-label>
                                       <mat-select 
                                        name="CityID" 
                                         [(ngModel)]="client.CityID"  
                                         formControlName="CityID">
                                         <mat-option>
                                          <ngx-mat-select-search name="city" 
                                             placeholderLabel="{{translate('Enter At Least One Character')}}"
                                             noEntriesFoundLabel="{{translate('No data found')}}" ngModel
                                             formControlName="CityName"
                                             (ngModelChange)="onFilterCity($event)">
                                          </ngx-mat-select-search>
                                        </mat-option>
                                          <mat-option *ngFor="let city of filteredCities" [value]="city.CityID">                        
                                             <span *ngIf="isRtl()">{{city.CityNameAr}}</span>
                                             <span *ngIf="!isRtl()">{{city.CityNameEn}}</span>
                                          </mat-option>
                                       </mat-select>
                                    </mat-form-field>
                                 </div>

                              </div>
                              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                 <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label> {{ 'Address' | translate }} </mat-label>
                                       <textarea matInput placeholder="{{ 'Address' | translate }}" [(ngModel)]="client.Address"
                                          formControlName="Address" ></textarea>
                                    </mat-form-field>
                                 </div>
      
                                 <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                                    <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Corresponding Address' | translate }}  </mat-label>
                                       <textarea matInput placeholder= "{{ 'Corresponding Address' | translate }}" formControlName="MailAddress"
                                          [(ngModel)]="client.MailAddress" ></textarea>
                                    </mat-form-field>
                                 </div>
                              </div>

                           </mat-expansion-panel>

                        </ng-container>
                     </mat-accordion>    
                 
                     <div fxLayout="column">
 
                        <div fxLayout="row" fxLayoutAlign="start">

                           <button 
                           (click)="onBasicFormSubmit()" [disabled]="!addNewClientForm.valid"   type="submit" mat-raised-button color="warn"
                           *ngIf="(auth.isGranted(_permissions.EditClients)
                           ||auth.isGranted(_permissions.AddClients))&&(ClientStatus.Approve==status)">{{ 'SUBMIT' | translate }}  </button>
                           <a  [routerLink]="['/clients/clients/'+status ]"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                        </div>
                     </div>
                  </form>
               </mat-tab>
               <mat-tab label=  "{{ 'Client Company' | translate }}" 
           
               [disabled]="!(client.Id>0)">
                 <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <ms-client-company-list clientId={{clientId}}
                    status={{status}}
                 
                     [clientCompanies]="clientCompanies">  </ms-client-company-list>
                  </div>
               </mat-tab>
               <mat-tab label= "{{ 'Client Warehouse' | translate }}"
         
               [disabled]="!(client.Id>0)" >

               <div fxLayout="column">
                 
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                     <ms-client-store-list clientId={{clientId}} 

                     status={{status}}
                     [clientStores]="clientStores" ></ms-client-store-list>

                  </div>

               </div>
               </mat-tab>
               <mat-tab label=  "{{ 'Client Bank' | translate }}" 
               
               [disabled]="!(client.Id>0)">

               <div fxLayout="row wrap"  >
                  <ms-client-bank clientId={{clientId}} 
                  status={{status}}
                  [clientBanks]="clientBanks">  </ms-client-bank>
                </div> 
               </mat-tab>

               <mat-tab label=  "{{ 'Settings' | translate }}" 
           
               [disabled]="!(client.Id>0)">
                 <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <ms-client-settings clientId={{clientId}}
                    status={{status}}  >  </ms-client-settings>
                  </div>
               </mat-tab>

      
               

            </mat-tab-group>
         </div>

      </div>
   </mat-card>
</div>