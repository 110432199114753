import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  

@Injectable()
export class SymbolApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}


  getSymbol(id:any): Observable<any> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbol/`+id);
  }
  getSymbolByCode(code:any): Observable<any> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbolByCode/`+code);
  }

  getSymbols(): Observable< []> {
    return this.http.get< []>(`${AppConsts.bussinessApiUrl}${AppConsts.setupUrl}/getSymbols`);
  }



   

  updateOrCreateSymbolMarketCommission(id:any,SymbolRequest: any): Observable<any> {
 
    return this.http.put<any>(`${this.API_URL}/addSymbolMarketCommission/`+id, 
    SymbolRequest)
      .pipe(tap(data => {}));
 }

  insertSymbol(SymbolRequest: any): Observable<any> {
 
    return this.http.post<any>(`${this.API_URL}/insertSymbol`, 
    SymbolRequest)
      .pipe(tap(data => {}));
 }

 updateSymbol(SymbolRequest: any,id :any): Observable<boolean> {

  return this.http.put<any>(`${this.API_URL}/updateSymbol/`+id, 
  SymbolRequest)
    .pipe(tap(data => {}));
}

 deleteSymbol(id: any): Observable<boolean> {

  return this.http.delete<any>(`${this.API_URL}/deleteSymbol/`+id).pipe(tap(data => {}));
 }
  
 searchActiveSymbols(request: any): Observable<any> {
  let queryParameters: string = this.convertObjectToQueryParameters(request);
  return this.http.get<[]>(`${this.API_URL}/searchActiveSymbols`+queryParameters).pipe(tap(data => {}));
 }
  
 convertObjectToQueryParameters(request): string {
  let queryParameters: string = "";
  for (let property in request) {
      if (request[property] != null)
          queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
  }
  if (queryParameters == "")
      return "";
  return queryParameters
}





addStoreSymbol(storeSymbol: any,id :any): Observable<boolean> {
  return this.http.put<any>(`${this.API_URL}/addStoreSymbolList/`+id, 
  storeSymbol)
    .pipe(tap(data => {

    }));
  }
  deleteStoreSymbol(StoreId,symbolId): Observable<boolean> {

    return this.http.delete<any>(`${this.API_URL}/removeStoreSymbol/`+StoreId+"/"+symbolId).pipe(tap(data => {}));
    }
    
    
}