<form [formGroup]="addNewMarketSessionForm" class="popup-card-width add-new-user">

	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title}}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

					<div class="padding-input-form">
						<mat-form-field class="width33 form-field " appearance="outline">
							<mat-label>{{'Session'  | translate }} </mat-label>
							<mat-select  [(ngModel)]="marketSession.Sessiontype"
							  name="Sessiontype"
							  [disabled]='isUpdate'
							  [value]="marketSession.Sessiontype"
								  formControlName="Sessiontype"  
							 >
								 <mat-option  *ngFor="let session of  sessiobTypeList" [value]="session.Id">
									<span *ngIf="isRtl()">{{session.NameAr}}</span>
									<span *ngIf="!isRtl()">{{session.NameEn}}</span></mat-option>
						   </mat-select>
						</mat-form-field>
			 
						<mat-form-field class="width33 form-field " appearance="outline">
							<mat-label>{{ 'Start Time' | translate }} </mat-label> 
							<input matInput
								   name="StartSessionTime"
								   [format]="24"
								   formControlName="StartSessionTime"								
								   [(ngModel)]="marketSession.StartSessionTime"
								   [ngxMatTimepicker]="pickerA"
								   [value]="marketSession.StartSessionTime"								 			
								    readonly/>
							<mat-icon matSuffix  (click)="pickerA.open()">
								watch_later</mat-icon>
						</mat-form-field>
						<ngx-mat-timepicker (timeSet)="onStartTimeset($event)" #pickerA> </ngx-mat-timepicker>

				 
						<mat-form-field class="width33 form-field " appearance="outline">
							<mat-label>{{ 'End Time' | translate }} </mat-label> 
							<input matInput
							formControlName="EndSessionTime" 
							[format]=24
							name="EndSessionTime" 
							[value]="marketSession.EndSessionTime"						
							[(ngModel)]="marketSession.EndSessionTime"
							[ngxMatTimepicker]="pickerB"
						     readonly	 />
							<mat-icon matSuffix  (click)="pickerB.open()">
								watch_later
							</mat-icon>
						</mat-form-field>
						<ngx-mat-timepicker  (timeSet)="onEndTimeset($event)" #pickerB ></ngx-mat-timepicker>
 

					</div>
					<div class="padding-input-form">
					<div fxLayout="row wrap"  formGroupName="myCheckboxGroup">
						<div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
						   <mat-checkbox class="example-margin"     [(ngModel)]="marketSession.IsAllowOrder"      formControlName="IsAllowOrder"  >{{  'Allow Order' | translate}}</mat-checkbox>
						</div>

						<div fxFlex.gt-sm="32" fxFlex.gt-xs="32" fxFlex="100" fxFlex.gt-md="32">
						   <mat-checkbox class="example-margin"     [(ngModel)]="marketSession.IsAllowMatching"      formControlName="IsAllowMatching"  >{{'Allow Matching' | translate}}</mat-checkbox>
						</div>
					 
					</div>
					</div>
				</div>
			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none">
		<!-- <div *ngFor="let error of errors">
			<label class="error-label">{{error}}</label>
			<br />
		</div> -->
		<button (click)="onFormSubmit()"
		*ngIf=" auth.isGranted(_permissions.UpdateMarketSettings) "   [disabled]="!addNewMarketSessionForm.valid" mat-raised-button
		    color="warn">{{'SAVE' | translate }} </button>

		 <button type="button" mat-raised-button (click)="close()" color="primary">{{ 'CLOSE' | translate }}</button>
	</mat-dialog-actions>
</form>