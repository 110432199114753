import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { MonitorServicesTypeEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { MonitorServicesService } from 'app/monitor-services/services/monitor-services.service';
@Component({
  selector: 'ms-lmonitor-services',
  templateUrl: './monitor-services.component.html',
  styleUrls: ['./monitor-services.component.scss']
})
export class MonitorServicesComponent extends AppComponentBase     implements OnInit    {
  dialogRef: MatDialogRef<DemoDialog>;
  title:any="";
  items: any = [
    { "id": 1, "name":   this.translate("RunEndOfDay") ,"description":   this.translate("RunEndOfDay_description") },
    { "id": 2, "name":   this.translate("RunCalculateCommissions"), "description":   this.translate("RunCalculateCommissions_description")},
    { "id": 3, "name":   this.translate("RunEndOfPreOpenOrder") ,"description":   this.translate("RunEndOfPreOpenOrder_description") }
  ];

  monitorServices: any[] = [];

  constructor(private monitorServicesService :MonitorServicesService,
    private dialog: MatDialog  ,
    private pageTitleService : PageTitleService ,
    private _EnumHelpers:EnumHelpers,
    injector: Injector
    ) {
      super(injector); 

       this.pageTitleService.setTitle("Monitor Services");
       this.title="Monitor Services";
       this.items=_EnumHelpers.getNamesAndValues(MonitorServicesTypeEnum);
     }

  ngOnInit(): void {
    this.getMonitorServices()
  }

  run(id){
    debugger;
   if(id==2){
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to run calculate commissions job '  + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.monitorServicesService.RunCalculateCommissions().subscribe(res =>  { 
 
          this.toastr.success("Successfully!");
        
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });

   }else   
    if(id==3){
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to run end of pre open session job '  + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.monitorServicesService. RunEndOfPreOpenOrder().subscribe(res =>  { 
 
          this.toastr.success("Successfully!");
        
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });

   }else    if(id==1){
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to run end of day job '  + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.monitorServicesService. RunEndOfDay().subscribe(res =>  { 
 
          this.toastr.success("Successfully!");
        
        }, err => {
          this.toastr.error("Error");
    
        });
      }
      this.dialogRef = null;
    });

   }else    if(id==4){
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to run end of day job '  + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {
        this.monitorServicesService. CalcSetteledBalances().subscribe(res =>  { 
 
          this.toastr.success("Successfully!");
        
        }, err => {
          this.toastr.error("Error");
    
        });
      }
      this.dialogRef = null;
    });

   }
  }
 
  getMonitorServices() { 
    this.monitorServicesService.getMonitorServices().subscribe(res => {
      this.monitorServices = res;
    });
  }

  isDateEqualToNow(date: Date): boolean {
    if(date != null){
      const now = new Date();
      const givenDate = new Date(date);
      var isequal = givenDate.toDateString() === now.toDateString();
      return isequal;
    }
    return false;
  }
 
}
