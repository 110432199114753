import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { Auctionservice } from 'app/auction-management/services/auction.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';


@Component({
  selector: 'ms-auction-news-list',
  templateUrl: './auction-news-list.component.html',
  styleUrls: ['./auction-news-list.component.scss']
})

export class AuctionNewsListComponent extends AppComponentBase implements OnInit {

  errors: any;
  title:any;
  statisticsData: any;
  addNewsForm: FormGroup;
  auctionNews: any[] = [];

  constructor(public formBuilder: FormBuilder,
    private Auctionservice: Auctionservice,
    private dialog: MatDialog,
    private  dialogRef: MatDialogRef<AuctionNewsListComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) data: any, 
    injector: Injector) {
     
      super(injector);
      if (data != null && data.data != null) {
        this.statisticsData=data.data;
        this.title= "Auction News";
      } 
      else
      {
        this.title="Auction News";
      }
  }

  ngOnInit(): void {
    this.addNewsForm = this.formBuilder.group({
      AuctionId: [, Validators.required],
      HeaderEn: [, [Validators.required]],
      HeaderAr: [, [Validators.required]],
      DetailsEn: [, [Validators.required]],
      DetailsAr: [, [Validators.required]]
    });

    this.getAuctionNews();
  }

  close() {
 
    this.dialogRef.close(false);
  }


  getAuctionNews(){
    this.Auctionservice.getAuctionNews(this.statisticsData.Id).subscribe(res => {
      this.auctionNews = res;
    })
  }

  onSubmit(){

    var confirm = this.dialog.open(DemoDialog, {
      data: { message: 'Are you sure ?' },
      disableClose: false
    });

    confirm.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.addNewsForm.controls['AuctionId'].setValue(this.statisticsData.Id);
        this.Auctionservice.addAuctionNews(this.addNewsForm.value).subscribe(() => {
          this.dialogRef.close(this.addNewsForm.value);
          this.toastr.success(this.translate("Successfully"));
        },
        err => {
          this.toastr.error(err);
        });
      }
    })

    }
  }