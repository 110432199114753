import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 

 import { DatePipe } from '@angular/common';
 import { MonitorServicesApi } from './api/monitor-services.api';
 import { MonitorServicesService } from './services/monitor-services.service';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core'; 
import{MonitorServicesComponent} from './containers/monitor-services-list/monitor-services.component';
import{MonitorServicesLogComponent}from './containers/monitor-services-log/monitor-services-log.component';
import { MonitorServicesRoutes } from './monitor-services.routing';
 @NgModule({
  imports: [   ReactiveFormsModule, 
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(MonitorServicesRoutes),
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
          CommonModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      FormsModule,
      SharedModule ,
      TranslateModule, 
      
      GridModule,TranslocoRootModule,DragDropModule,ResizableModule
  ],
  declarations: [ 
    MonitorServicesComponent,MonitorServicesLogComponent
  ], 
  providers: [ DatePipe,MonitorServicesApi,MonitorServicesService ]
})
export class MonitorServicesModule { }