export class OrderStatusDto {
    StatusID: number;
    Status: string;
}

export class AuctionstatusDto {
    StatusID: number;
    Status: string;
}

export class OrderValiditysDto {
    Validityid: number;
    ValidityName: string;
}
export class SymbolUnitDto {
    UnitID: number;
    UnitNameEn: string;
    UnitNameAr: string; 
}
export class NationalityDto {
    NationalityID: number;
    NationalityAr: string;
    NationalityEn: string; 
}
export class CityyDto {
    CityID: number;
    CityNameEn: string;
    CityNameAr: string; 
}
export class StatusLookupDto {
    OrderStatus: OrderStatusDto[];
    Auctionstatus: AuctionstatusDto[];
    OrderValidity: OrderValiditysDto[];
    SymbolUnits: SymbolUnitDto[];
    Nationalities: NationalityDto[];
    Cities: CityyDto[];
}

export class MarketSessionTypeDto { 
    Id: number;
    NameEn: string;
    NameAr: string;
    IsDeleted: boolean;
    Remark: string;
    Status: number;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    UpdatedUserId: string;
    LastUpdatedDate: Date;
}

export class ReasonStatusDto {
    Id: number;
    NameEn: string;
    NameAr: string; 
    StatusType: number;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string; 
    LastUpdatedDate: Date;
}
export class AuctionTypeDto {
    Id: number;
    NameEn: string;
    NameAr: string;  
    IsAllowClient:any;
    IsAllowBroker:any;
    IsAllowCompany :any;
    IsOmnibus:any;
    IsMustAttachment:any;
    IsPublic:any;
    IsAllowEditOrderQty: any;
    IsAllowEditOrderPrice: any;
    IsAllowShowAuctionPrice: any;
    IsAllowShowAuctionQty: any;
    IsAllowShowAuctionOrder: any;
    IsAllowMultiAuctionClientOrder: any;
    IsAllowPriceWithinLimit: any;
    IsAllowCapping: any;
    
    IsAllowShowOrderCompany	: any;
    IsAllowShowOrderBroker	: any;
    IsAllowShowOrderClient	: any;
    IsAllowShowOrderQty	: any;
    IsAllowShowOrderPrice	: any;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string; 
    LastUpdatedDate: Date;
}
