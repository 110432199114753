import { AfterViewInit, Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'; 
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { permissions } from 'app/core/permission'; 
import { SignalRService } from '../../../service/signalr/signalr.service'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { LazyLoadScriptService } from 'app/service/core/LazyLoadScriptService';
 
  declare const TradingView:any;
@Component({
  selector: 'ms-gold-symbol-details',
  templateUrl: './gold-symbol-details.component.html',
  styleUrls: ['./gold-symbol-details.component.scss']
})
export class GoldSymbolDetailsComponent   extends AppComponentBase implements OnInit, AfterViewInit {

 
 
  constructor( 
    private readonly signalrService: SignalRService ,
    private setupApi: SetupApi,  
    injector: Injector,
    private lazyLoadService: LazyLoadScriptService
    ) {
      super(injector); 
 
 
  }
  
  ngOnInit(): void { 
    
  }

  ngAfterViewInit(): void {
    this.lazyLoadService.loadScript('https://s3.tradingview.com/tv.js').subscribe(_ => {
      
      var lang='en';
      if(this.isRtl()){
        lang='ar_AE';
      }
      new TradingView.widget(
        {
        "width": '100%',
        "height":300 ,
        "symbol": "XAU",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "1",
        "locale": lang,
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "container_id": "tradingview_6551b"
      }
        );
    });
  }

  
  
   
}


