import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import {  StatusEnum, ClientTypeEnum, IdentityExpiryEnum, ApproveStatusEnum, UserTypeEnum, EntityTypeEnum, BalanceStatusEnum, BalanceSourceEnum, IdentityTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import {ClientDto, ClientModel, ClientReuqest, QueueAttachmentDto, UploadAttachmentTypeDto} from 'app/models/client';
import { ClientService } from 'app/client-managment/services/client.service';
import { AppComponentBase } from 'app/app-component-base'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientSearchFormDialogComponent } from 'app/client-managment/components/client-search-form-dialog/client-search-form-dialog.component';
import { ClientCommentsDialogComponent } from 'app/client-managment/components/client-comments-dialog/client-comments-dialog.component';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State } from "@progress/kendo-data-query";
import { finalize, startWith, switchMap, catchError, map, filter} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { OperationService } from 'app/operation-managment/services/operation.service';
import{ClientBalanceReuqest, ClientStatementReuqest} from 'app/models/ClientStatement';
 import { SettlementService } from 'app/settlement-managment/services/settlement.service';
 import{ClientPurchasePowerFormDialogComponent} from '../../components/client-purchase-power-form-dialog/client-purchase-power-form-dialog.component';

@Component({
  selector: 'client-purchase-power',
  templateUrl: './client-purchase-power.component.html',
  styleUrls: ['./client-purchase-power.component.scss']
})
export class ClientPurchasePowerComponent   extends AppComponentBase implements OnInit, OnDestroy {
 
  CompanyID:any;
  public lookups: StatusLookupDto;
  company: any; 
  filteredCompanies:any;
  companies: any;
  stores: any;
  types: any;
  userType:any;
  title: any;
  selectBy:any;
  status:any;
  SourceEnum=BalanceSourceEnum;
  StatusEnum=BalanceStatusEnum; 
  IdentityType=IdentityTypeEnum;
  searchTxt:string="";
  public actions: any;
  public action: any;
  clientReuqest:ClientReuqest=new ClientReuqest();
  public view: Observable<GridDataResult>;
  clients:any=[];
  items:any=[];

  
  public state: State = {
    skip: 0,
    take: 25,
  };

  dataStatus:any   ;
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _setupApi:SetupApi,
    private _settlementService:SettlementService,
    private _helper :EnumHelpers   ,
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector); 
 
    }


  filterOptions:ClientBalanceReuqest = new ClientBalanceReuqest();
  statistics:ClientModel= new ClientModel();
  cfilter:ClientReuqest= new ClientReuqest();

  ngOnInit(): void {  
    this.route.paramMap.subscribe(prams => { this.title = 'Client Purchase Power'; });
    this.pageTitleService.setTitle( "Settlement");
      
    this.filterOptions.FromDate =new Date();
    this.filterOptions.ToDate=new Date();
    this.filterOptions.GeneralFilter = "";
    this.clientReuqest=new ClientReuqest();
    this.clientReuqest.Status=StatusEnum.Active;
    this.clientReuqest.ApproveStatusId=ApproveStatusEnum.Approve; 
    if (this.isBroker()) {
      this.getUserBrokerInfo().subscribe(res => {
       
        if (res != null  ) {
          this.clientReuqest.CompanyId= res.CompanyId; 
          this.CompanyID= res.CompanyId; 
          this.filterOptions.CompanyId= res.CompanyId; 
        }
      });
 
    } else{

      this._setupApi.getActiveCompanies().subscribe(res => {  this.companies = res;     });
    }
  }

  refesh(){
    this.filterOptions=new ClientStatementReuqest();
    this.getList();

  }
  
 
  onStateChange(){
    if(this.filterOptions.GeneralFilter != null && this.filterOptions.GeneralFilter!=""
    && this.filterOptions.GeneralFilter.length>3 ){
      
      this.filterOptions. Skip = 0
     this. getList()
    }
  }
  
  public dataStateChange(state: DataStateChangeEvent): void {
  
    this.state = state;
    this. getList();
  }
  
  getList() {  
 
     this.view =  this._settlementService. getClientPurchasePowers(this.filterOptions.ClientId).pipe(
      map(data => { 
          let result = <GridDataResult>{ data:   data, total: data.length}
          return result;
      }) );  
        
  }

  
  getClientPurchasePower(clientId:any, currencyId:any){   
   
    const dialog = this.dialog.open(ClientPurchasePowerFormDialogComponent, {
      data:{  clientId: clientId ,currencyId:currencyId}  ,
      width: '700px'
    });
    dialog.afterClosed().subscribe((res) => {  this.getList(); } );

  }
 

  public rowCallback(context: RowClassArgs) {
    

    switch (context.dataItem.ExpiryFlag) {
      case IdentityExpiryEnum.Expiry:
        return { Expiry: true };
      case IdentityExpiryEnum.preExpiry:
        return { preExpiry: true };
     case IdentityExpiryEnum. WrongExpiry:
          return { Warning: true };
      default:
        return {};
    }
 
  }

 
  

  onChangeCompany(event) { 
    this.filterOptions.ClientId=null;
    this.clients=[];
    this.clientReuqest.CompanyId=event.value;
    this.CompanyID=event.value;  

  }
  changeClientFilter(filter:any)
  {  
    if(filter.length<=2)return ;
    if(this.filterOptions.CompanyId>0){
      this.cfilter.GeneralFilter = filter;
      this.cfilter.CompanyId=this.filterOptions.CompanyId;
      this.cfilter.ApproveStatusId = ApproveStatusEnum.Approve;
      this.cfilter.Status = StatusEnum.Active;
      this.clientService.searchClients( this.cfilter).subscribe(res=>this.clients  =  res.clients);
    }

  }


}

