import { Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { AppComponentBase } from 'app/app-component-base';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { MarketService } from 'app/market-managment/services/market.service';
import { CalendarDto, MarketSessionDto } from 'app/models/market';
import { SetupApi } from 'app/Shared/api/setup.api';
import { MarketCalendarDialogComponent } from '../market-calendar-dialog/market-calendar-dialog.component';
import { MarketSessionDialogComponent } from '../market-session-dialog/market-session-dialog.component';
@Component({
	selector: 'ms-market-calendar-list',
	templateUrl: './market-calendar-list.component.html',
	styleUrls: ['./market-calendar-list.component.scss']
})

export class MarketCalendarListComponent extends AppComponentBase implements OnInit {
	title: any;
	popupDeleteRespone: any;
	popupAddNumberCard: any;
	items: CalendarDto[];
	markets: any;
	mainMarkets: any;
	subMarkets: any;
	dialogRef: MatDialogRef<DemoDialog>;
	MarketID: any;
	MainMarketID: any;
	IsPublic: any = true;
	constructor(private pageTitleService: PageTitleService,
		private marketService: MarketService,
		private setupApi: SetupApi,
		private dialog: MatDialog, injector: Injector
	) {
		super(injector);
		this.getMarkets();
		this.getMarketCalendar();
	}

	getMarkets() {
		this.setupApi.getActiveMarkets().subscribe(items => {
			this.markets = items;
			this.mainMarkets = this.markets.filter(c => c.ParentMarketId == null);
		});
	}


	getSubMarkets() {
		if (this.MainMarketID != null) {
			this.IsPublic = false;
			this.MarketID = null;
			this.subMarkets = [];
	
			if (this.MainMarketID != null) {
				var elements = this.markets.filter(x => x.ParentMarketId == this.MainMarketID);
				this.subMarkets = elements;
				if(this.subMarkets!=null&&this.subMarkets.length>0){
					this.MarketID=this.subMarkets[0].Id;
				}
				
			} else {
				this.subMarkets = [];
			}
		} else {
			this.IsPublic = true;
			this.subMarkets = [];
			this.MarketID=null;
			
		}
		this.getMarketCalendar();
		//

	}

	ngOnInit() {
		this.items = [];
		this.title = this.translate('Calendar');

		setTimeout(() => {
			this.pageTitleService.setTitle(this.translate("Markets Management"));
		}, 0);
	}

	getMarketCalendar() {

		if (!this.IsPublic) {
			this.marketService.getMarketCalendar(this.MarketID).subscribe(res => {
				this.items = res;
			});
		} else {
			this.marketService.getAllCalendar().subscribe(res => {
				this.items = res;
			});
		}

	}
	onChangeIsPublic($event) {
		
		this.IsPublic = $event.checked;
		this.getMarketCalendar();
	}

	/**
	  * onRemoveCard is used to remove the product Number Card.
	  */
	delete(element) {
		this.dialogRef = this.dialog.open(DemoDialog, {
			data: { message: this.translate('would you like to delete this calendar') + ' # ' + element.Description + '?' },
			disableClose: false
		});

		this.dialogRef.afterClosed().subscribe(result => {
			if (result == 'Yes!') {

				this.marketService.deleteCalendar(element.Id).subscribe((res) => {
					this.getMarketCalendar();
					this.toastr.success(this.translate("Successfully"));
				}, err => {
					this.toastr.error(this.translate("Error"));
					console.log(err)
				});
			}
			this.dialogRef = null;
		});
	}

	addNewCalendarDialog() {
		const dialog = this.dialog.open(MarketCalendarDialogComponent, {
			data: { marketId: this.MarketID }
		});
		dialog.afterClosed().subscribe((res) => {
			this.getMarketCalendar();
		});
	}

	edit(calendar) {
        var market_title="";
		if(this.MainMarketID!=null && this.MarketID!=null){
			var mainMarket= this.markets.filter(x => x.ParentMarketId == this.MainMarketID);
			var subMarket= this.markets.filter(x => x.Id == this.MarketID);
			if(this.isRtl()){
				market_title=mainMarket.NameAr + "/"+subMarket.NameAr ;
			}else{
				market_title=mainMarket.NameEn + "/"+subMarket.NameEn ;
			}
		}
        
		const dialog = this.dialog.open(MarketCalendarDialogComponent, {
			data: {  market_title:market_title,marketId: this.MarketID, calendar: calendar, calendarId: calendar.ID }
		});
		dialog.afterClosed().subscribe((res) => {
			this.getMarketCalendar();
		});
	}
}
