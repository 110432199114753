
                                <form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewBankForm">
           <br/>
                                 <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                    <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                                       <mat-form-field class="full-wid form-field" appearance="outline">
                                       <mat-label>{{ 'Bank' | translate }}  </mat-label>
                                       <mat-select name="BankID" [(ngModel)]="bank.BankID" 
                                       [disabled]='isUpdate'
                                       formControlName="BankID">
                                          <mat-option *ngFor="let bankI of banks" [value]="bankI.BankID">
                                             {{bankI.BankNameEn}}
                                          </mat-option>
                                       </mat-select>
                                       </mat-form-field>
                                    </div>
         
                                    <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" fxFlex.gt-md="49">
                                       <mat-form-field class="full-wid form-field" appearance="outline">
                                          <mat-label>{{ 'IBan' | translate }}</mat-label>
                                          <input matInput placeholder="IBan" formControlName="IBan"
                                             [(ngModel)]="bank.IBan" required
                                             readonly='{{isUpdate}}'>
                                       </mat-form-field>
                                    </div>
                                 </div> 
                             
                                 <div fxLayout="column">
             
                                    <div fxLayout="row" fxLayoutAlign="start">

                                       <button (click)="onBankFormSubmit()"
                                       [disabled]="!addNewBankForm.valid"
                                       *ngIf="	auth.isGranted(_permissions.AssignClientsToBanks)&&(approveStatus.Approve==status) "
                                        type="submit" mat-raised-button color="warn">{{ 'SUBMIT' | translate }} </button>
                                        <a  [routerLink]="['/clients/clients/'+status]"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>
             
                                    </div> 
                                 </div>
                              </form>

 