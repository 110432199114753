 
<div class="table-responsive">
 
	<div fxLayout="row wrap" fxLayoutAlign="space-between">
	  <div fxLayout="row" fxLayoutAlign="start center">
  
  
		<div class="mrgn-l-sm mrgn-r-sm">
		  <h4>   {{  'Default Commisions' | translate }}</h4>
		</div>
	  </div>
	  <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
  
		<mat-card-actions class="mat-pad-none margin-none"
		>
		   <button mat-raised-button mat-button-sm color="primary" (click)="addMarketCommission()"  > {{ 'New Commission' | translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
		 </mat-card-actions>
	  </div>
	</div>
  
  <kendo-grid 
   
  #grid
  [kendoGridBinding]="items"
		[filterable]="false"
		[sortable]="true"  
	  class="market-kendo-grid"
  
  > 
  <kendo-grid-column   title=" {{'Id'  | translate }} "  headerClass="grid-list-column-header" width="50" > 
	<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
		{{(rowIndex+1  )}}
	</ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column field="CommissionNameEN" title="  {{'Name English'  | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>
  <kendo-grid-column field="CommissionNameAR" title="  {{'Name Arabic'  | translate }} " headerClass="grid-list-column-header" > </kendo-grid-column>

  <kendo-grid-column field="Minimum" title="{{'Minimum'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>
	<kendo-grid-column field="Maximum" title="{{'Maximum'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>
	<kendo-grid-column field="CommissionPerc" title="	   {{'Commission%'  | translate }} " headerClass="grid-list-column-header"> </kendo-grid-column>

   
  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">


   

		<ng-template kendoGridCellTemplate let-dataItem  let-rowIndex="rowIndex"  >
 
			<a    (click)="editMarketCommission(dataItem )"><mat-icon class="mrgn-l-sm">create</mat-icon></a> |
			<a    class="icon_hover"  (click)="delteMarketCommission(dataItem )"> <mat-icon class="mrgn-l-sm">delete_sweep</mat-icon></a> 
				 
	
	   </ng-template>

   </kendo-grid-column>

   

	
  </kendo-grid>
   
  
  </div>
   <br/><br/>