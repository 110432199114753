
<form [formGroup]="addNewPropertiesStatusForm" class="dialog-popup popup-card-width add-new-user">
	 

	<div class="gene-card-title">
		<div fxLayout="row" fxLayoutAlign="start center">
		   <div fxLayout="column">
			  <h4 class="mrgn-b-none">{{title}}</h4>
		   </div>

		</div>
		<mat-divider></mat-divider>
	 </div>
	<div class="gene-card-content">
		<div class="trade-crypto crypto-input-prefix">
			<div class="pad-t-sm">

				<div class="padding-input-form">
			  
					<mat-form-field class="width50 form-field" appearance="outline">
						<mat-label>{{ 'Action Date' | translate }} </mat-label>
						<input matInput [matDatepicker]="picker"  
						readonly='true'  
						disabled='true'
						[(ngModel)]="propertiesStatus.ActionDate"
						 formControlName="ActionDate" >
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
				  </mat-form-field>

				  <mat-form-field class="width50 form-field" appearance="outline">
					<mat-label>{{ 'Action on' | translate }}</mat-label>
					<mat-select 
					[(ngModel)]="propertiesStatus.ActionOn" 
					readonly='true'  
					disabled='true'
					   formControlName="ActionOn">
					   <mat-option *ngFor="let entityType of entityTypes" [value]="entityType.id">
					
						  <span  >{{entityType.name|translate}}</span> 
					   </mat-option>
					</mat-select>
				 </mat-form-field>




				 <mat-form-field class="width50 form-field" appearance="outline">
					<mat-label>{{ 'Responsible' | translate }}</mat-label>
					<input matInput placeholder="{{ 'Responsible Name' | translate }}" [(ngModel)]="propertiesStatus.ResponsibleName" formControlName="ResponsibleName"  
					readonly='true'  
					disabled='true'>
				 </mat-form-field>


				 <mat-form-field class="width50 form-field" appearance="outline">
					<mat-label>{{ 'Action Type' | translate }}</mat-label>
					<mat-select 
					[(ngModel)]="propertiesStatus.ActionType" 
					   formControlName="ActionType">
					   <mat-option *ngFor="let entityType of entityActionTypeEnum" [value]="entityType.id">
					
						  <span  >{{entityType.name|translate}}</span> 
					   </mat-option>
					</mat-select>
				 </mat-form-field>

					
				 <mat-form-field class="full-wid form-field" appearance="outline">
					<mat-label>{{ 'Notifiled Email' | translate }}</mat-label>
					<input matInput placeholder="{{ 'Notifiled Email' | translate }}" [(ngModel)]="propertiesStatus.NotifiledEmail" formControlName="NotifiledEmail"  
					 >
				 </mat-form-field>

				 <mat-form-field class="full-wid form-field" appearance="outline">
					<mat-label>{{ 'CC Email' | translate }}</mat-label>
					<input matInput placeholder="{{ 'CCEmail' | translate }}" [(ngModel)]="propertiesStatus.CCEmail" formControlName="CCEmail"  
					 >
				 </mat-form-field>

				 <mat-form-field class="full-wid form-field" appearance="outline">
					<mat-label>{{ 'Reason' | translate }}</mat-label>
					 
					 <mat-select 
					 [(ngModel)]="propertiesStatus.ReasonId" 
						formControlName="ReasonId">
						<mat-option *ngFor="let reason of reasonStatus" [value]="reason.Id">
							<span *ngIf="isRtl()">{{reason.NameAr}}</span>
							<span *ngIf="!isRtl()">{{reason.NameEn}}</span>
									  
						</mat-option>
					 </mat-select>
				 </mat-form-field>
				 <mat-form-field class="full-wid form-field" appearance="outline">
					<mat-label>{{ 'Remark' | translate }}</mat-label>
					<textarea matInput placeholder="{{ 'Remark' | translate }}" [(ngModel)]="propertiesStatus.Remark" formControlName="Remark"  
					 ></textarea>
				 </mat-form-field>
				 
				 </div> 

			</div>
		</div>
	</div>
	<button   (click)="onFormSubmit()"
	*ngIf=" auth.isGranted(_permissions.EditMarkets) "   type="submit" 
	[disabled]="!addNewPropertiesStatusForm.valid"   mat-raised-button color="warn">{{'SUBMIT'  | translate }} </button>
	<button type="button" mat-raised-button  (click)="close()" color="primary">{{'CLOSE'  | translate }} </button>

	
</form>