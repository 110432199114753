import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from 'app/admin/services/admin.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import {ClaimDto, RoleDto} from '../../../models/role'; 
@Component({
  selector: 'ms-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  searchTxt:any="";
  items: RoleDto [] ;
 
  constructor(private adminService :AdminService,
  
    private pageTitleService : PageTitleService ,
    private toastr: ToastrService) {

      setTimeout(() =>{ 
        this.pageTitleService.setTitle("User Management");
       },0);
     }

  ngOnInit(): void {
    
  }

 
 
}
