<form  class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<kendo-grid 
			[data]="items" 
			[resizable]="true"
			[reorderable]="true"
			[filterable]="false"   
			class="kendo-grid-list" 
			> 
			 
			<kendo-grid-column   title="	{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="70" > 
			  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
				  {{(rowIndex+1  )}}
			  </ng-template>
			</kendo-grid-column>
			

			<kendo-grid-column field="Date" title="{{'Date' | translate }}" headerClass="grid-list-column-header"  width="150">
			  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
				  {{dataItem.Date  | date: 'dd/MM/yyyy'}}
			  </ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="StatementCount" title="{{'Number Of Rows' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.StatementCount}}
				</ng-template>
			  </kendo-grid-column>
			 
			<kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">

			  <!-- #region View & Update & Delete -->
			  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
			  
			  *ngIf="auth.isGranted(_permissions.ApproveClientStatement)">
				<button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
				  <mat-icon>menu</mat-icon>
				</button>
				   <mat-menu #anchorMenu="matMenu">

					  <a mat-menu-item  (click)="appoveDisk(dataItem)">
						{{ 'Approve'| translate }}
					  </a>
					  
					  <a mat-menu-item  (click)="rejectDisk(dataItem)">
						{{ 'Reject'| translate }}
					  </a>
					   
				   </mat-menu>
									   </ng-template>
			  <!-- #endregion -->
			 
			 </kendo-grid-column>
   </kendo-grid>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
 
</form>