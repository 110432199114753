<br/>
<kendo-grid 
#grid
[kendoGridBinding]="items"
	  [filterable]="false" 
	  [pageSize]="20"
	  [resizable]="true"
	  [reorderable]="true"
	  [columnMenu]="false"
	  filterable="menu"
	  [sortable]="true"
    class="market-kendo-grid"
>  
<kendo-grid-column   title="	{{ 'Id' | translate }} "  headerClass="grid-list-column-header" width="50" > 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{(rowIndex+1  )}}
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="Company.Code" title="{{ 'Code' | translate }}" width="100" headerClass="grid-list-column-header"   > </kendo-grid-column>
<kendo-grid-column  title="{{ 'Name' | translate }}" 
headerClass="grid-list-column-header" > 

<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <span *ngIf="isRtl()">{{dataItem.Company.NameAr}}</span>
  <span *ngIf="!isRtl()">{{dataItem.Company.Name}}</span>
</ng-template>
</kendo-grid-column>

<kendo-grid-column field="Company.Email" title="{{ 'Email' | translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>
<kendo-grid-column field="Remark" title="{{ 'Remark' | translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>

<kendo-grid-column field="IsActive" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
    <span *ngIf="dataItem.IsActive==true" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
    <span *ngIf="dataItem.IsActive!=true" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
  </ng-template>
  </kendo-grid-column>

<kendo-grid-column   title=" "  width="100" headerClass="grid-list-column-header"
*ngIf="auth.isGranted(_permissions.AssignClientsToCompanies) &&(approveStatus.Approve==status)" >

  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
    <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" 
 >
      <mat-icon>menu</mat-icon>
    </button>
       <mat-menu #anchorMenu="matMenu">
 
          <a mat-menu-item   (click)="Edit(dataItem)" >
              {{ 'Edit' | translate }}
          </a>
          <!-- <a mat-menu-item   (click)="Delete(dataItem)"   >
              {{ 'Delete' | translate }}
            </a>
        -->
 
       </mat-menu>
   
      </ng-template>
 
 </kendo-grid-column>
</kendo-grid>

<br/>
<div fxLayout="row" fxLayoutAlign="start">
                   
	<button (click)="addNewClientCompanyDialog()"  
        
  *ngIf="auth.isGranted(_permissions.AssignClientsToCompanies) &&!this.isBroker()&&!this.isStoreKeeper()&&(approveStatus.Approve==status)"   mat-raised-button
	color="warn">{{ 'Assign To Company' | translate }} </button>

	 <a  [routerLink]="['/clients/clients/'+status]"
	  mat-raised-button color="primary"> {{ 'Go To List' | translate }} </a>

 </div>