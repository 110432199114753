import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import {  StatusEnum, ClientTypeEnum, IdentityExpiryEnum, ApproveStatusEnum, UserTypeEnum, EntityTypeEnum, BalanceStatusEnum, BalanceSourceEnum, BankMasrRequestTypeEnum, MonitorServicesTypeEnum } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import {ClientDto, ClientModel, ClientReuqest, QueueAttachmentDto, UploadAttachmentTypeDto} from 'app/models/client';
import { ClientService } from 'app/client-managment/services/client.service';
import { AppComponentBase } from 'app/app-component-base'; 
import { SetupApi } from 'app/Shared/api/setup.api';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientSearchFormDialogComponent } from 'app/client-managment/components/client-search-form-dialog/client-search-form-dialog.component';
import { ClientCommentsDialogComponent } from 'app/client-managment/components/client-comments-dialog/client-comments-dialog.component';
import { DataStateChangeEvent, GridDataResult, RowClassArgs } from '@progress/kendo-angular-grid';
import { State } from "@progress/kendo-data-query";
import { finalize, startWith, switchMap, catchError, map, filter} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { OperationService } from 'app/operation-managment/services/operation.service';
import{ClientBalanceReuqest, ClientStatementReuqest} from 'app/models/ClientStatement';
 import { SettlementService } from 'app/settlement-managment/services/settlement.service'; 
import{BankMISRFilter} from'../../../models/bankMasr';
import { MonitorServicesService } from 'app/monitor-services/services/monitor-services.service';
import { keyValuesToMap } from '@angular/flex-layout/extended/typings/style/style-transforms';
@Component({
  selector: 'monitor-services-log',
  templateUrl: './monitor-services-log.component.html',
  styleUrls: ['./monitor-services-log.component.scss']
})
export class MonitorServicesLogComponent   extends AppComponentBase implements OnInit, OnDestroy {
 

  MonitorServicesTypeEnum=MonitorServicesTypeEnum;
  MonitorServicesTypes:any;
  serviceId:any;
  public view: Observable<GridDataResult>;
  title:any;
  public state: State = { skip: 0, take: 25,};

  servicesType: any[] = [];

  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private monitorServicesService :MonitorServicesService,
    private _helper :EnumHelpers   ,
   injector: Injector
    ) {
      super(injector); 
       
    }

   


  ngOnInit(): void {  
    this.pageTitleService.setTitle("Monitor Services");
    this.title="Monitor Services";
    this.MonitorServicesTypes=this._helper.getNamesAndValues(MonitorServicesTypeEnum);

    this.servicesType = this.enumToArray(MonitorServicesTypeEnum);
    console.log(this.servicesType);
  }

  refesh(){
    this.getList();

  }
  
 
  onStateChange(){
      this. getList();
  }
  
  public dataStateChange(state: DataStateChangeEvent): void {
  
    this.state = state;
    this. getList();
  }
  
  getList() {  

    this.view =  this.monitorServicesService. getMonitorServicesLog(this.serviceId).pipe(
      map(data => {
        
          let result = <GridDataResult>{ data:  data, total: data.length}
          return result;
       }) ); 
  }

  onChangeType(event) { 
       this.getList() ;
  }


  enumToArray(data: any): any {
    var output: any = [];
    Object.keys(data)
      .filter(key => isNaN(Number(key))) // Filters out the numeric keys
      .forEach(key => {
        output.push({ name: key }); // Pushes the key name instead of the value
      });
    return output;
  }
  
}

