import { Component, Inject, Injector, OnDestroy, OnInit, Optional } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';


import { StatusEnum, EntityTypeEnum } from 'app/core/enums';
import { RequestSymbolDto, SymbolDto } from 'app/models/symbol';

import { StatusLookupDto } from 'app/models/StatusLookup';
import { ToastrService } from 'ngx-toastr';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { SymbolService } from 'app/symbol-managment/services/symbol.service';
 import{SetupApi} from 'app/Shared/api/setup.api';
import { AppComponentBase } from 'app/app-component-base';
import { permissions } from 'app/core/permission';
import { PropertiesStatusLogDto } from 'app/models/PropertiesStatus';
import { PropertiesStatusDialogComponent } from 'app/shared/components/properties-status-dialog/properties-status-dialog.component';


@Component({
  selector: 'ms-symbol-manage-list',
  templateUrl: './symbol-list.component.html',
  styleUrls: ['./symbol-list.component.scss']
})
export class SymbolListComponent  extends AppComponentBase implements OnInit, OnDestroy {

  public items: SymbolDto[];
  public lookups: StatusLookupDto;
  company: any;
  title: any;
  searchText:string ="";
  public actions: any;
  public action: any;
  selectBy:any;
  categories:any;
  sectors:any; 
  markets:any; 
  mainMarkets:any; 
  subMarkets:any; 
  requestSymbolDto:RequestSymbolDto=new RequestSymbolDto();
  dialogRef: MatDialogRef<DemoDialog>;
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
      private setupApi: SetupApi,
    private symbolService: SymbolService, 
    private _helper :EnumHelpers   , injector: Injector
    ) {
      super(injector);
      this.actions = _helper.getNames(StatusEnum);
     // this.getCategories();
      this.getMarkets();
    }

    getMarkets(){
      this.setupApi.getActiveMarkets().subscribe(items => {
        this.markets=items;
        this.mainMarkets=this.markets.filter(c=>c.ParentMarketId==null);
        
   
      });
    }

    
    getSubMarkets( ){
      
      this.requestSymbolDto.MarketID=null;
       this.subMarkets=[];
        
       if(this.requestSymbolDto.MainMarketID!=null){
        var elements=  this.markets.filter(x=>x.ParentMarketId==this.requestSymbolDto.MainMarketID);
        this.subMarkets=elements;
       }else{
        this.subMarkets=[];
       }

      this. search();
    }
 
    changePropertiesStatus(data){
 
      let propertiesStatus: PropertiesStatusLogDto=new PropertiesStatusLogDto();
      propertiesStatus.ActionOn=EntityTypeEnum.Symbol;
      propertiesStatus.ResponsibleId=data.SymbolMarkets[0].Id;
      propertiesStatus.ResponsibleNameAr=data.NameAr;
      propertiesStatus.ResponsibleNameEn=data.NameEn;
      propertiesStatus.Status= data.SymbolMarkets[0].Status;
      const dialog = this.dialog.open(PropertiesStatusDialogComponent, {
        data:{propertiesStatus:propertiesStatus   }  
      });
      dialog.afterClosed().subscribe((res) => { 
        
        if(res!=null){ 
          data.SymbolMarkets[0].Status=res;
        }
      } );
    }
    getSectors( ){
      
      this.requestSymbolDto.SectorID=null;
       this.sectors=[];
      var cate=  this.categories.filter(x=>x.CategoryID==this.requestSymbolDto.CategoryID);
      cate.forEach(element => {
        
        this.sectors=element.Sectors;
      }); 
      this. search();
    }

  ngOnInit(): void {
    this.route.paramMap.subscribe(prams => {
      this.title = 'Symbols';
    });
   this.pageTitleService.setTitle("Symbols");
 

    this.getSymbols();

  }

  getSymbols() {
   
    this.symbolService.getSymbols().subscribe(items => {

      this.items = items;
      this.getStatusLookup();
      this.items = this.items.filter(x => x.IsDeleted != true);

    });
  }

 
  getStatusLookup() {
    this.setupApi.getStatusLookup().subscribe(items => {
  
      this.lookups = items;
      if(this.lookups.SymbolUnits!=null){

        this.items.forEach(element => {
          if(element.Unit!=null){
            var unit = this.lookups.SymbolUnits.filter(x => x.UnitID == element.Unit);
            if (unit != null) { 
              element.UnitTxt = unit[0].UnitNameEn;
            }
          }
        });
      }
    });
  }


 


  search( ){ 
    this.symbolService.searchActiveSymbols(this.requestSymbolDto).subscribe(items => {
      this.items = items; 
      
      if(this.lookups.SymbolUnits!=null){

        this.items.forEach(element => {
          if(element.Unit!=null){
            var unit = this.lookups.SymbolUnits.filter(x => x.UnitID == element.Unit);
            if (unit != null) { 
              element.UnitTxt = unit[0].UnitNameEn;
            }
          }
        });
      }
    });
    


  }



  deleteSymbol(symbol) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to delete this symbol # ' + symbol.SymbolCode + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.symbolService.deleteSymbol(symbol.Id).subscribe((res) => {
       //  this. getSymbols();
                 this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }




}

