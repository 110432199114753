import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 

import { TranslateModule } from '@ngx-translate/core';
import { OrderCommissionsFormDialogComponent } from  '../report-managment/components/order-commissions-form-dialog/order-commissions-form-dialog.component';
import { AuctionPaymentsComponent } from './containers/auction-payments/auction-payments.component';
import { OperationService } from './services/operation.service';
import { OperationApi } from './api/operation.api';
import { operationManagmentRoutes } from './operation-managment.routing';
import { SharedModule } from 'app/shared/shared.module';
import { OrderManagementModule } from 'app/order-management/order-management.module';
 
 
@NgModule({
  imports: [   ReactiveFormsModule, 
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(operationManagmentRoutes),
    MatInputModule,
    TranslateModule, 
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      FormsModule,
      MatDialogModule  ,
        ExcelModule,
       SharedModule,
      TranslateModule,      OrderManagementModule,
      GridModule,TranslocoRootModule,DragDropModule,ResizableModule 
  ],
  declarations: [ 
    AuctionPaymentsComponent , 

  ], 
  providers: [OperationApi   ,OperationService]
})
export class OperationManagmentModule { }