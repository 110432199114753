<form  class="popup-card-width add-new-user">
	 
	<mat-dialog-content class="dialog-content-pp">
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div> 
			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<kendo-grid 
			[data]="items" 
			[resizable]="true"
			[reorderable]="true"
			[filterable]="false"   
			class="kendo-grid-list" 
			> 
		
			
			<kendo-grid-column field="TotalBalance" title="{{'Purchase Power' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.TotalBalance| number}}
				</ng-template>
			</kendo-grid-column>

 
			<kendo-grid-column field="UntsettledBalance" title="{{'Untsettled Balance' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.UntsettledBalance| number}}
				</ng-template>
			</kendo-grid-column>

			<kendo-grid-column field="Balance" title="{{'Current Balance' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.Balance| number}}
				</ng-template>
			</kendo-grid-column>


			<kendo-grid-column field="OrderBalance" title="{{'Open Orders' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.OrderBalance| number}}
				</ng-template>
			</kendo-grid-column>


			<kendo-grid-column field="OrderCommissionBalance" title="{{'Open Orders Commissions' | translate }}" headerClass="grid-list-column-header"  width="150">
				<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
					{{dataItem.OrderCommissionBalance| number}}
				</ng-template>
			</kendo-grid-column>

	
			  
   </kendo-grid>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
 
</form>