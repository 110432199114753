<h3 style="padding-left:30px;padding-right:30px" *ngIf="storeKeeper!=null">

    {{storeKeeper.StoreInfo?.StoreNameEn}}</h3>

<div class="user-manage-list">

    <!-- <mat-card>

  </mat-card> -->

    <mat-card>

        <div fxLayout="row" fxLayoutAlign="start center">


            <div fxFlex.gt-sm="90" fxFlex.gt-xs="90" fxFlex="90" fxFlex.gt-md="90">

                <mat-form-field class="width33" floatLabel="never">
                    <mat-label>{{translate('Search')}}</mat-label>
                    <input class="form-control" matInput type="input" placeholder="{{translate('Search')}}" formControlName="Name" (input)="filterOptions.GeneralFilter=$event.target.value">
                </mat-form-field>

                <button mat-button color="primary" (click)="onStateChange(this.gridState)">Search<mat-icon>search</mat-icon>
        </button>
            </div>



            <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
                <mat-card-actions class="mat-pad-none margin-none" *ngIf="isStoreKeeper()">
                    <button mat-raised-button mat-button-sm color="primary" (click)="add()" *ngIf="isAuthorized()">
            {{translate('Add New')}}
            <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon>
          </button>
                </mat-card-actions>
            </div>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <custom-menu [items]="dataSources.storeKeepers" class="width100 form-field" [displayPath]="isRtl()?'KeeperNameAr': 'KeeperNameEn'" [title]="translate('Everyone')" (onChange)="this.filterOptions.KeeperUserID = $event.UserId;getList(null)" [icon]="'people_outline'">
            </custom-menu>

            <custom-menu [items]="this.dataSources.sections" class="width100 form-field" [displayPath]="isRtl()?'SectionnameAr': 'SectionNameEn'" [title]="translate('Anywhere')" [icon]="'place'" (onChange)="filterOptions.StoreSectionID = $event.SectionID;getList(null)"></custom-menu>
            <custom-menu [items]="dataSources.stores" class="width100 form-field" [displayPath]="isRtl()?'StoreNameAr': 'StoreNameEn'" [title]="translate('Warehouse')" (onChange)="this.filterOptions.StoreID = $event.StoreID;getList(null)" [icon]="'store_mall_directory'">
            </custom-menu>

            <custom-menu [items]="dataSources.intervals" class="width100 form-field" [displayPath]="'name'" [title]="translate('Intervals')" [icon]="'calendar_today'" (onChange)="this.filterOptions.Interval = $event.id;getList(null)" [mandatory]="true"></custom-menu>

            <custom-menu [items]="dataSources.status" class="width100 form-field" [displayPath]="'name'" [title]="translate('Status')" [icon]="'signal_cellular_alt'" (onChange)="this.filterOptions.StatusID = $event.id;getList(null)"></custom-menu>


        </div>

        <!-- Calling :: statistics-of-withdraw-deposit -->

        <div fxLayout="row wrap" fxLayoutAlign="start center" style="margin-top:20px" *ngIf="transactionTypeId!=TransactionTypes.transfer && transactionTypeId!=TransactionTypes.AcceptReject">
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                <statistics-card [color]="'#19a978'" [title]="translate(this.title)" [val]="statistics.Created"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                <statistics-card [color]="'#9d9b99'" [title]="translate('Updated')" [val]="statistics.Updated"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="25">
                <statistics-card [color]="'lightsalmon'" [title]="translate('Cancelled')" [val]="statistics.Cancelled"></statistics-card>
            </div>
        </div>


        <div fxLayout="row wrap" fxLayoutAlign="start center" style="margin-top:20px" *ngIf="transactionTypeId==TransactionTypes.transfer ||transactionTypeId==TransactionTypes.AcceptReject ">
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="20">
                <statistics-card [color]="'Lavender'" [title]="translate('Pending')" [val]="statistics.Pending"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="20">
                <statistics-card [color]="'#9d9b99'" [title]="translate('Updated')" [val]="statistics.Updated"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="20">
                <statistics-card [color]="'lightsalmon'" [title]="translate('Cancelled')" [val]="statistics.Cancelled"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="20">
                <statistics-card [color]="'#9d9b99'" [title]="translate('Accepted')" [val]="statistics.Accepted"></statistics-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.gt-md="20">
                <statistics-card [color]="'lightsalmon'" [title]="translate('Rejected')" [val]="statistics.Rejected"></statistics-card>
            </div>
        </div>


        <div fxLayout="row wrap" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="mrgn-r-sm">
                </div>
                <div class="mrgn-l-sm mrgn-r-sm">
                </div>
            </div>
            <div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center" style="margin-left:auto !important;padding-right:10px;padding-right: 10px;">


            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="custom-kendo-grid" [dir]="isRtl()?'rtl':'ltr'">

            <kendo-grid [data]="view" [resizable]="true" [reorderable]="true" [filterable]="false" class="kendo-grid-list" [pageSize]="gridState.take" [skip]="gridState.skip" (dataStateChange)="onStateChange($event)" [sort]="gridState.sort" [pageable]="{
                      buttonCount: 5,
                      info: false,
                      type:'numeric',
                      pageSizes: [10,20,50],
                      previousNext: true}" [sortable]="true">

                <kendo-grid-column title="{{translate('Action(A)')}}" headerClass="grid-list-column-header" width="150" *ngIf="filterOptions.TransactionTypeID == TransactionTypes.transfer && filterOptions.StatusID == TransactionStatus.Pending">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button type="button" mat-raised-button color="primary" (click)="transferApprove(dataItem,true)">{{translate('Accept')}}</button>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="RecieptNo" title="{{translate('Reference')}}" headerClass="grid-list-column-header" width="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{dataItem.RecieptNo}}
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Client.NameEn" title="{{translate('Client')}}" headerClass="grid-list-column-header" width="160">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>
                            {{isRtl()?dataItem.Client?.NameAr:dataItem.Client?.NameEn}}&nbsp;
                            <mat-icon *ngIf="filterOptions.TransactionTypeID == TransactionTypes.transfer &&dataItem.IsClientBelongToDropStore"
                                      style="position:absolute;color:green" #tooltip1="matTooltip" [matTooltip]="translate('belong to drop to Warehouse')">check_circle</mat-icon>
                            <mat-icon *ngIf="filterOptions.TransactionTypeID == TransactionTypes.transfer &&!dataItem.IsClientBelongToDropStore"
                                      style="position:absolute;color:red" #tooltip2="matTooltip" [matTooltip]="translate('not belong to drop to Warehouse')">cancel</mat-icon>
                        </span>



                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Symbol.NameEn" title="{{translate('Commodity')}}" headerClass="grid-list-column-header" width="120">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.Symbol?.NameAr:dataItem.Symbol?.NameEn}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Quantity" title="{{translate('QTY')}}" headerClass="grid-list-column-header" width="100">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{dataItem.Quantity | number}}</span>&nbsp;
                        <span>
                            {{dataItem.Symbol.Unit == 1? translate("KG"):""}}
                            {{dataItem.Symbol.Unit == 2? translate("Ton"):""}}
                            {{dataItem.Symbol.Unit == 3? translate("Ard"):""}}
                            {{dataItem.Symbol.Unit == 4? translate("gm"):""}}
                            {{dataItem.Symbol.Unit == 5? translate("Pound"):""}}

                        </span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Store.StoreNameEn" title="{{translate('Warehouse')}}" headerClass="grid-list-column-header" width="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.Store?.StoreNameAr:dataItem.Store?.StoreNameEn}}</span>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column *ngIf="transactionTypeId == TransactionTypes.AcceptReject" field="Store.StoreNameEn" title="{{translate('Pickup Store')}}" headerClass="grid-list-column-header" width="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.Store?.StoreNameAr:dataItem.Store?.StoreNameEn}}</span>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column *ngIf="transactionTypeId == TransactionTypes.transfer" field="ToStore.StoreNameEn" title="{{translate('Drop To')}}" headerClass="grid-list-column-header" width="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.ToStore?.StoreNameAr:dataItem.ToStore?.StoreNameEn}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Section" title="{{translate('Section')}}" *ngIf="transactionTypeId != TransactionTypes.transfer && transactionTypeId != TransactionTypes.AcceptReject" headerClass="grid-list-column-header" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.Section?.SectionnameAr:dataItem.Section?.SectionNameEn}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="Status" title="{{translate('Status')}}" headerClass="grid-list-column-header" width="120">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span *ngIf="dataItem.StatusID == TransactionStatus.Deposited" class="oval-span" style="background-color:#19a978">{{translate('Deposited')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.withdrw" class="oval-span" style="background-color:#19a978">{{translate('Withdrawn')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.Depleted" class="oval-span" style="background-color:#19a978">{{translate('Depleted')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.Accepted" class="oval-span" style="background-color:#19a978">{{translate('Accepted')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.Pending" class="oval-span" style="background-color:Lavender">{{translate('Pending')}}</span>
                        <span *ngIf="dataItem.StatusID ==TransactionStatus.Updated" class="oval-span" style="background-color:#9d9b99">{{translate('Updated')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.Cancelled" class="oval-span" style="background-color:lightsalmon">{{translate('Canceled')}}</span>
                        <span *ngIf="dataItem.StatusID ==TransactionStatus.Rejected" class="oval-span" style="background-color:#9d9b99">{{translate('Rejected')}}</span>
                        <span *ngIf="dataItem.StatusID == TransactionStatus.AcceptedWithRestriction" class="oval-span" style="background-color:lightsalmon">{{translate('AcceptedWithRestriction')}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="StoreKeeper.KeeperNameAr" title="{{translate('Responsible')}}" width="120" headerClass="grid-list-column-header">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{isRtl()?dataItem.StoreKeeper.KeeperNameAr:dataItem.StoreKeeper.KeeperNameEn}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [field]="'StatusDate'" title="{{translate('Date')}}" width="120" headerClass="grid-list-column-header">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{dataItem.StatusDate|date}}</span>
                    </ng-template>
                </kendo-grid-column>


<!-- 

                <kendo-grid-column [field]="'CreatedDate'" title="{{translate('Creation Date')}}" width="120" headerClass="grid-list-column-header">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{dataItem.CreatedDate|date}}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column [field]="'LastUpdate'" title="{{translate('Last Update')}}" width="120" headerClass="grid-list-column-header">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header">
                        <span>{{dataItem.LastUpdatedDate|date}}</span>
                    </ng-template>
                </kendo-grid-column> -->

                <kendo-grid-column title="{{translate('Action(B)')}}" headerClass="grid-list-column-header" width="150" *ngIf="filterOptions.TransactionTypeID == TransactionTypes.transfer && filterOptions.StatusID == TransactionStatus.Pending">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button type="button" mat-raised-button color="warn" (click)="transferApprove(dataItem,false)">{{translate('Reject')}}</button>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="" width="100" headerClass="grid-list-column-header-24">

                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <mat-menu #anchorMenu="matMenu">
                            <a mat-menu-item (click)="showView(dataItem)"  *ngIf="isAuthorized() && isStoreKeeper()">
                                {{translate('view')}}
                            </a>
                            <a mat-menu-item (click)="edit(dataItem)" *ngIf="isAuthorized() && isStoreKeeper()">
                                {{translate('Edit')}}
                            </a>
                            <a mat-menu-item (click)="cancel(dataItem)" *ngIf="isAuthorized() && isStoreKeeper()">
                                {{translate('Cancel')}}
                            </a>
                        </mat-menu>

                    </ng-template>

                </kendo-grid-column>
            </kendo-grid>



        </div>

    </mat-card>
</div>