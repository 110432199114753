import { SignalRService } from '../../../service/signalr/signalr.service';
import { MSGType, TransactionStatus } from './../../../core/enums';
import { SetupApi } from 'app/Shared/api/setup.api';
import { DepositComponent } from './../../components/deposit/deposit.component';
import { TransactionLookupDto, TransactionReuqest, StoreKeeperDto, StatisticsModel, TransferApproveDto } from './../../../models/warehouse';
import { TransactionComponent } from '../../components/transaction/transaction.component';
import { StoreDto } from './../../../models/store';
import { Component, Inject, OnDestroy, OnInit, Optional, Injector, ViewEncapsulation } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
 
import { StatusEnum, Intervals, TransactionTypes } from 'app/core/enums';
import { SymbolDto } from 'app/models/symbol'; 
import { StatusLookupDto } from 'app/models/StatusLookup';
import {  StoreSectionDto, TransactionDto } from 'app/models/warehouse';
import { WarehouseService } from 'app/warehouse-managment/services/warehouse.service';
import { WarehouseFormDialogComponent } from 'app/warehouse-managment/components/warehouse-form-dialog/warehouse-form-dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { FormControl, FormGroup } from '@angular/forms';
import { GridData } from '@swimlane/ngx-charts';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AppComponentBase } from 'app/app-component-base';
import { ClientDto, ClientReuqest, SearchRequestDto } from 'app/models/client';
import { State, process } from '@progress/kendo-data-query';
import { ClientService } from 'app/client-managment/services/client.service';

@Component({
  selector: 'transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent extends AppComponentBase implements OnInit {

  transactionTypeId:number = null;
  filterOptions:TransactionReuqest = new TransactionReuqest();
  public view:GridDataResult// Observable<GridDataResult>;
 
  public lookups : TransactionLookupDto = new TransactionLookupDto(); 
  company: any;
  title: any;
  Clients:ClientDto[] = [];
  gridState:State = {skip:0,take:10}
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;
  storeKeeper:StoreKeeperDto = new StoreKeeperDto();
  dataSources = {storeKeepers:[],
  stores:[],
  sections:[]
  ,intervals:[],
  status:[]};

  statistics:StatisticsModel= new StatisticsModel();
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private warehouseService: WarehouseApi,
    private _setupApi:SetupApi,
    private clientService: ClientService ,
    private _helper:EnumHelpers,
    private signalrService:SignalRService,
    injector:Injector) {
      super(injector)
      this.actions = _helper.getNames(StatusEnum);
      this.filterOptions.Interval =Intervals.Today; 


      signalrService.transactionChanges.subscribe(items => {
        //this.items = [item, ...this.items];
   
        console.log("transaction change");
        let transactions:TransactionDto[] = JSON.parse(items);
        let transaction = transactions[0];

         
        if(this.dataItems.some(item=>item.TransactionID == transaction.TransactionID))
        {
          let v = this.dataItems.filter(item=>item.TransactionID == transaction.TransactionID)[0]
        v.Quantity = transaction.Quantity;
        }else{
          this.dataItems = [transaction, ...this.dataItems];
          
        }

        
        this.onStateChange(this.gridState);
      });
     }


     changeClientFilter(filter:string)
     {
       if(filter.length<=2)return ;
   
       let cfilter:ClientReuqest= new ClientReuqest();
       cfilter.GeneralFilter = filter;
       cfilter.StoreID=  this.storeKeeper .StoreID;
     
       this.clientService.searchClients(cfilter)
     .subscribe(res=> this.Clients =  res.clients);


     }

changeType(id){

}

  ngOnInit(): void {
    
    this.route.url.subscribe(res=>
      {
       
        if(res[res.length-1].path.includes( 'deposit' )){
          this.transactionTypeId = TransactionTypes.deposit;
          this.title = "Deposit"
          this.filterOptions.TransactionTypeID = TransactionTypes.deposit
      }
        if(res[res.length-1].path.includes("withdraw") )
       {
        this.transactionTypeId = TransactionTypes.withdrw;
        this.title = "Withdraw" 
        this.filterOptions.TransactionTypeID = TransactionTypes.withdrw
      }
          if(res[res.length-1].path.includes("deleption") )
        {
          this.transactionTypeId = TransactionTypes.deleption;
          this.title = "Depeltion"
          this.filterOptions.TransactionTypeID = TransactionTypes.deleption
        } 
          if(res[res.length-1].path.includes("transfer")) 
         {
          this.transactionTypeId = TransactionTypes.transfer;
          this.title = "Transfer" 
          this.filterOptions.TransactionTypeID = TransactionTypes.transfer
        }
        if(res[res.length-1].path.includes("accept_reject")) 
        {
         this.transactionTypeId = TransactionTypes.transfer;
         this.title = "Accept/Reject Transfer" 
         this.filterOptions.TransactionTypeID = TransactionTypes.transfer
         this.filterOptions.StatusID = TransactionStatus.Pending;
       }
      })
      this.pageTitleService.setTitle(this.title);
// get 10000 element from backend
    this.filterOptions.Take = 100000;
    this.filterOptions.Skip = 0;

    //this.filterOptions.TransactionTypeID=1;
    this.warehouseService.getTransactionLookups()
    .subscribe(res=>{this.lookups = res
    });
 

this._setupApi.getStoreKeeperInfo()
.subscribe(res=>{
  this.storeKeeper = res;
  
  this.filterOptions.KeeperUserID= this.storeKeeper.UserId;
  this.getList(null);
  if(res!=null){
    this.dataSources.sections = res.StoreInfo.StoreSections;
    this.dataSources.storeKeepers = res.StoreInfo.StoreKeepers
    this.dataSources.stores = [res.StoreInfo];
  }

  
  this.dataSources.intervals = [
    {name:this.translate('Today'),id:1},
  {name:this.translate('Yesterday'),id:2}
  ,{name:this.translate('Last Week'),id:3}  
  ,{name:this.translate('Last Month'),id:4}];

if(this.filterOptions.TransactionTypeID == TransactionTypes.deposit){
  this.dataSources.status=[{name:this.translate('Deposited'),id:TransactionStatus.Deposited},
  {name:this.translate('Updated'),id:TransactionStatus.Updated},
  {name:this.translate('Cancelled'),id:TransactionStatus.Cancelled}]
}
else if(this.filterOptions.TransactionTypeID == TransactionTypes.withdrw)
{
  this.dataSources.status=[{name:this.translate('Withdrawn'),id:TransactionStatus.Withdrawn},
  {name:this.translate('Updated'),id:TransactionStatus.Updated},
  {name:this.translate('Cancelled'),id:TransactionStatus.Cancelled}]
}
else if(this.filterOptions.TransactionTypeID == TransactionTypes.transfer || this.filterOptions.TransactionTypeID == TransactionTypes.AcceptReject){
  this.dataSources.status=[
    {name:this.translate('Pending'),id:TransactionStatus.Pending},
    {name:this.translate('Updated'),id:TransactionStatus.Updated},
  {name:this.translate('Cancelled'),id:TransactionStatus.Cancelled},
  {name:this.translate('Accepted'),id:TransactionStatus.Accepted},
  {name:this.translate('Rejected'),id:TransactionStatus.Rejected},
  
]
}
else if(this.filterOptions.TransactionTypeID == TransactionTypes.deleption){
  this.dataSources.status=[{name:this.translate('Delepted'),id:TransactionStatus.Depleted},
  {name:this.translate('Updated'),id:TransactionStatus.Updated},
  {name:this.translate('Cancelled'),id:TransactionStatus.Cancelled}]
}




});

    
    

    
  }



dataItems:TransactionDto[] = [];
  getList(action) {
    this.filterOptions.Take = 10000;
   
      this.warehouseService.getStoreTransactions(this.filterOptions).subscribe(
        data=>{this.dataItems = data
          this.recalculateStatistics (data);
          this.view= process(this.dataItems,this.gridState); //process(this.dataItems,this.gridState);
        })
}


  add() {
    const dialog = this.dialog.open(TransactionComponent, {
      data: {  
        transactionTypeId:this.transactionTypeId
      }
    });
    dialog.afterClosed().subscribe((res) => {  
      this.getList(null);
    });
  }


addDeposit()
{
  const dialog = this.dialog.open(DepositComponent, {
    data: {  
       transactionTypeId: this.transactionTypeId
    }
  });
  dialog.afterClosed().subscribe((res) => {  
  });
}

showView(dto)
{
  const dialog = this.dialog.open(TransactionComponent, {
    data: { transactionTypeId: this.transactionTypeId,dto: dto,isViewOnly:true }});
    dialog.afterClosed().subscribe((res) => {
    this.getList(null)
  });
}
  edit(dto) {
    dto.TransactionTypeID = this.transactionTypeId;
    const dialog = this.dialog.open(TransactionComponent, {
      data: { dto: dto ,transactionTypeId: this.transactionTypeId}});
    dialog.afterClosed().subscribe((res) => {
      this.getList(this.action);
    });
  }
  cancel(dto:TransactionDto){
    dto.MSGType = MSGType.Cancel;
    dto.TransactionTypeID = this.transactionTypeId;

  this.confirm(this.warehouseService.handleTransaction(dto),this.translate("are you sure you want to cancel"),
  ()=>{this.getList(null)},null)
  

}
public onStateChange(state) {
  this.gridState = {...state};
 let temp = this.dataItems


if(this.filterOptions.GeneralFilter != null && this.filterOptions.GeneralFilter!="")
  temp = this.dataItems.filter(item=>item.RecieptNo?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.Client?.NameEn?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.Symbol?.NameEn?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.Quantity?.toString().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.Store?.StoreNameEn?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.RecieptNo?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  // item.Status?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase())||
  item.StoreKeeper.KeeperNameAr?.toLowerCase().includes(this.filterOptions.GeneralFilter?.toLowerCase()) )

  this.recalculateStatistics(temp);
  this.view= process(temp,this.gridState);
}
  recalculateStatistics(transactions:TransactionDto[]) {
    if(this.transactionTypeId == TransactionTypes.transfer || this.transactionTypeId == TransactionTypes.AcceptReject){
      this.statistics.Updated = transactions.filter(item=>item.StatusID == TransactionStatus.Updated)?.length;
      this.statistics.Cancelled = transactions.filter(item=>item.StatusID == TransactionStatus.Cancelled)?.length;
      this.statistics.Pending = transactions.filter(item=>item.StatusID == TransactionStatus.Pending)?.length;
      this.statistics.Accepted = transactions.filter(item=>item.StatusID == TransactionStatus.Accepted)?.length;
      this.statistics.Rejected = transactions.filter(item=>item.StatusID == TransactionStatus.Rejected)?.length;
    }
    else{
      this.statistics.Created = transactions.filter(item=>(item.StatusID == TransactionStatus.Deposited && this.transactionTypeId == TransactionTypes.deposit)||
      (item.StatusID == TransactionStatus.Withdrawn && this.transactionTypeId == TransactionTypes.withdrw)||
      (item.StatusID == TransactionStatus.Depleted && this.transactionTypeId ==TransactionTypes.deleption)

      )?.length;
      this.statistics.Updated = transactions.filter(item=>item.StatusID == TransactionStatus.Updated)?.length;
      this.statistics.Cancelled = transactions.filter(item=>item.StatusID == TransactionStatus.Cancelled)?.length;
    }
  }


isAuthorized(){

  return (this.transactionTypeId == TransactionTypes.deposit && this.auth.isGranted(this._permissions.DeleptWarehouseTransaction))||
          (this.transactionTypeId == TransactionTypes.withdrw && this.auth.isGranted(this._permissions.WithdrawWarehouseTransaction))||
          (this.transactionTypeId == TransactionTypes.deleption && this.auth.isGranted(this._permissions.DeleptWarehouseTransaction))||
          (this.transactionTypeId == TransactionTypes.transfer && this.auth.isGranted(this._permissions.TransferWarehouseTransaction))
}
  transferApprove(dto:TransactionDto,isAccepted:Boolean){
    let transferApprove :TransferApproveDto = new TransferApproveDto()
    transferApprove.TransactionId = dto.TransactionID;
    transferApprove.ApprovedQty = dto.Quantity;
    transferApprove.IsApproved = isAccepted;
   this.confirm(this.warehouseService.transferApprove(transferApprove),this.translate("Are You Sure")
   ,()=>{this.getList(null)},null);
  }
}
