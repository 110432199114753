<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div class="mrgn-r-sm">
					<mat-icon class="mat-icon-grey cursor-pointer"  >loop</mat-icon>
				</div>
				<div class="mrgn-l-sm mrgn-r-sm">
 
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<mat-card-actions class="mat-pad-none margin-none">
					<button mat-raised-button mat-button-sm color="primary"
					*ngIf="auth.isGranted(_permissions.AddBanks)" (click)="addNewBankDialog()">{{'ADD NEW BANK'| translate }}<mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</mat-card-actions>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  > 
				  <kendo-grid-checkbox-column headerClass="grid-list-column-header" [showSelectAll]="true" [width]="40"></kendo-grid-checkbox-column>
				   

				  <kendo-grid-column   title="{{'Id'| translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
				  <kendo-grid-column field="BankNameAr" title="{{'Name Arabic' | translate }}" headerClass="grid-list-column-header" width="200"  > </kendo-grid-column>
				  <kendo-grid-column field="BankNameEn" title="{{'Name English' | translate }}" headerClass="grid-list-column-header"  width="200"  > </kendo-grid-column>
				  
				  <kendo-grid-column field="SwiftCode" title="{{'Swift Code' | translate }}" headerClass="grid-list-column-header" > </kendo-grid-column>

				  
				  <kendo-grid-column field="CreatedDate" title="{{'Date Created' | translate }}" headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <kendo-grid-column field="" title="{{ 'Status' | translate }}" headerClass="grid-list-column-header" width="150"> 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" headerClass="grid-list-column-header"  >
						<span *ngIf="dataItem.Status==_StatusEnum.Active" class="oval-span" style="background-color:#19a978"> {{ 'Active' | translate }}</span>
						<span *ngIf="dataItem.Status!=_StatusEnum.Active" class="oval-span" style="background-color:#9d9b99">{{ 'Suspended' | translate }} </span>
					</ng-template>
				  </kendo-grid-column>

				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
					
					*ngIf="auth.isGranted(_permissions.DeleteBanks) ||auth.isGranted(_permissions.EditBanks)">
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item   (click)="editBank(dataItem)"  
						 >
							  {{'View'| translate }}
							</a>
							<a mat-menu-item   (click)="deleteBank(dataItem)" 
							*ngIf="auth.isGranted(_permissions.DeleteBanks)"   >
								{{'Delete'| translate }}
							  </a>
						 
							  <a mat-menu-item   
							    (click)="changePropertiesStatus(dataItem)"   >
							    {{ 'Change Status' | translate }} 
						    </a>
							 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>