import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PeriodService } from '../../shared/services/period.service';
 import {GoldMarketApi} from '../api/gold-market.api'
 

@Injectable()
export class GoldMarketService {
 
  constructor(private goldMarketApi:GoldMarketApi ) { }

 
 
}
  