import { warehouseManagmentRoutes } from './../../warehouse-managment.routing';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ClientReuqest, SearchRequestDto } from 'app/models/client';
import { ClientApi } from './../../../client-managment/api/client.api';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientService } from 'app/client-managment/services/client.service';
import { CompanyFormDialogComponent } from 'app/company-management/components/company-form-dialog/company-form-dialog.component';
import { CompanyManagementService } from 'app/company-management/services/company-management.service';
import { StatusEnum } from 'app/core/enums';
import {  StoreKeeperDto, TransactionDto, TransactionLookupDto } from 'app/models/warehouse';
import { ToastrService } from 'ngx-toastr';
import { symbol } from 'd3';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppComponentBase } from 'app/app-component-base';
 
@Component({
  selector: 'ms-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent  extends AppComponentBase implements OnInit {

depositForm:FormGroup;
  title: string;
  depositDto: TransactionDto = new TransactionDto();
  filteredLookups: TransactionLookupDto = new TransactionLookupDto();
  blockSellOrderForm: FormGroup;
  statusLst:any;
  errors:any;
  companyCode:any;
  storeKeeper:StoreKeeperDto = new StoreKeeperDto();

  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DepositComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _warehouseApi:WarehouseApi,
    private _setupApi:SetupApi,
    private clientService: ClientService ,
    injector:Injector
    ) { 
      super(injector)
   
    }

  ngOnInit(): void {
    this.depositForm = new FormGroup({ 
      ClientID: new FormControl({ value: 'ClientID' } ,[Validators.required]),
      SymbolID: new FormControl({ value: 'SymbolID' } ,[Validators.required]),
      Quantity: new FormControl({ value: 'Quantity' } ,[Validators.required]),
      SectionID:new FormControl({ value: 'SectionID' } ,[Validators.required]),
      LotNumber:new FormControl({value:"LotNumber"},[]),
      itemNameseaRCHa: new FormControl({ value: 'itemNameseaRCHa' }),
      itemNameseaRCHb: new FormControl({ value: 'itemNameseaRCHb' } )
    });
    this._setupApi.getStoreKeeperInfo().subscribe(res=>{
      this.storeKeeper = res;
    } );
  }

  
  close() {
    this.dialogRef.close(false);
  }

    // onFormSubmit method is submit a add new user form.
 onFormSubmit() { 
     
    
        this._warehouseApi.deposit(this.depositDto)
        .subscribe(() => {
          this.dialogRef.close(null);
 
          this.toastr.success (this.translate( "Successfully!"));
        },
        err =>{   
          
    
          this.toastr.error(this.translate(err.error[0]));
          } );
      }

      changeClientFilter(filter:string)
      {
        if(filter.length<=2)return ;
 


let cfilter:ClientReuqest= new ClientReuqest();
cfilter.GeneralFilter = filter;
cfilter.StoreID=  this.storeKeeper .StoreID;
this.clientService.searchClients(cfilter)
.subscribe(res=>this.filteredLookups.Clients= res.clients);

      }
      changeSymbolFilter(filter:string)
      {
        if(filter.length<=2)return;
      let sFilter =   new SearchRequestDto(); 
      sFilter.SearchText = filter;

      this._setupApi.searchActiveSymbols(sFilter).subscribe((res)=>{
          this.filteredLookups.Symbols = res;
      });
      
      }
    
}
