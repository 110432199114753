import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppComponentBase } from 'app/app-component-base';
import { ClientService } from 'app/client-managment/services/client.service';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { ApproveStatusEnum } from 'app/core/enums';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { SetupApi } from 'app/Shared/api/setup.api';
 import { ClientCompanyFormDialogComponent } from '../client-company-form-dialog/client-company-form-dialog.component';

@Component({
  selector: 'ms-client-company-list',
  templateUrl: './client-company-list.component.html',
  styleUrls: ['./client-company-list.component.scss']
})
export class ClientCompanyListComponent extends   AppComponentBase implements OnInit, OnDestroy  {

  ngOnDestroy() {

  }
  haveAccount:boolean=false;
  dialogRef: MatDialogRef<DemoDialog>;
  errors:any;
  private _clientId: number;
  items:any;
  approveStatus=ApproveStatusEnum;
  @Input()
  status: number;
 @Input() set clientId(value: number) {
  this._clientId = value;
 }
 @Input() set clientCompanies(value: any) {
   if(value!=null){
      
    this.items = value;
   }
 
 }
 
  constructor(private pageTitleService: PageTitleService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private setupApi: SetupApi    ,
    private _enumHelpers :EnumHelpers   , 
    private clientService: ClientService   , injector: Injector
    ) {
      super(injector);  
    }

 
  ngOnInit(): void {
    
  }
  
 
  addNewClientCompanyDialog(){
    const dialog = this.dialog.open(ClientCompanyFormDialogComponent, {
      data:{ clientId:this._clientId  }  
    });
    dialog.afterClosed().subscribe((res) => {
      this.getClientCompany(this._clientId);

    } );

  }
  Edit(data){
    const dialog = this.dialog.open(ClientCompanyFormDialogComponent, {
      data:{ clientCompany:data, clientId:this._clientId  }  
    });
    dialog.afterClosed().subscribe((res) => { 
      this.getClientCompany(this._clientId);
    } );

  }

  getClientCompany(id){
    
    if(this.isBroker()){
      this.getUserBrokerInfo().subscribe(res=>{
    this.clientService.GetCompanyClientByClientCompanyId(id,res.CompanyId).subscribe(res=>{
      this.clientCompanies=res;
   });
      }) 
    }else{

      this.clientService.GetCompanyClientByClientId(id).subscribe(res=>{
        this.clientCompanies=res;
     });
    }

   }
  Delete(clientCompany) {
 
    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message:this.translate('would you like to delete this account')   +'?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.clientService.removeCompanyClient(clientCompany.Id).subscribe((res) => {
          
          this.getClientCompany(this._clientId);
                 this.toastr.success(this.translate('Successfully'));
                
        }, err => {
          this.toastr.error(this.translate('Error') );
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }

}
