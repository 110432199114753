<div class="user-manage-list"  >
	<mat-card>
		<div fxLayout="row wrap" fxLayoutAlign="space-between">
			<div fxLayout="row" fxLayoutAlign="start center">
			
				
				<div class="mrgn-l-sm mrgn-r-sm">
					<h4>   {{ 'Symbols' | translate }}</h4>
				</div>
			</div>
			<div class="mrgn-l-md mrgn-b-md add-user-btn" fxLayoutAlign="start center">
				<mat-card-actions class="mat-pad-none margin-none">
					<button mat-raised-button mat-button-sm color="primary"
					 (click)="addNewSymbolMarketDialog()">  {{ 'ASSIGN SYMBOL' | translate }} <mat-icon class="mrgn-l-md gene-text-xl">add</mat-icon></button>
				</mat-card-actions>
			</div>
		</div>
		<mat-divider></mat-divider>
		<div class="">

			<kendo-grid 
			      [data]="items" 
				  [resizable]="true"
				  [reorderable]="true"
				  [filterable]="false"   
				  [kendoGridSelectBy]="selectBy"
				  class="kendo-grid-list" 
				  >  <kendo-grid-column   title="{{ 'Id' | translate }}"  headerClass="grid-list-column-header" width="50" > 
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{(rowIndex+1  )}}
					</ng-template>
				  </kendo-grid-column>
                  
				  <kendo-grid-column field="NameEn"
				  width="200" title= "{{ 'Name' | translate }}" headerClass="grid-list-column-header" > 

					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						<span *ngIf="isRtl()">{{dataItem.SymbolNameAr}}</span>
						<span *ngIf="!isRtl()">{{dataItem.SymbolNameEn}}</span>
					</ng-template>
				  </kendo-grid-column>
				 
                
				  <kendo-grid-column field="UpperLValue" title= "{{ 'Upper Value' | translate }}" headerClass="grid-list-column-header"> </kendo-grid-column>
					<kendo-grid-column field="LowerLValue" title="{{ 'Lower Value' | translate }}"  headerClass="grid-list-column-header"> </kendo-grid-column>
					<kendo-grid-column field="UpperLPerc" title="{{ 'Upper%' | translate }}"   headerClass="grid-list-column-header"> </kendo-grid-column>
				   <kendo-grid-column field="LowerLPerc" title= "{{ 'Lower%' | translate }}" headerClass="grid-list-column-header"> </kendo-grid-column>
   
  

				  <kendo-grid-column field="CreatedDate" title= "{{ 'Date Created' | translate }}" headerClass="grid-list-column-header">
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
						{{dataItem.CreatedDate | date: 'MM/dd/yyyy'}}
					</ng-template>
				  </kendo-grid-column>
				   
				  <kendo-grid-column   title=""  width="100" headerClass="grid-list-column-header">
    
					<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
					  <button mat-icon-button [matMenuTriggerFor]="anchorMenu" aria-label="Open anchor menu" >
						<mat-icon>menu</mat-icon>
					  </button>
						 <mat-menu #anchorMenu="matMenu">
							<a mat-menu-item  
							[routerLink]="['/symbols/Edit/'+dataItem.SymbolId]"      >
								 {{ 'Details' | translate }} 
							  </a>

							<a mat-menu-item   (click)="editSymbolMarket(dataItem)"   >
							  	 {{ 'Edit' | translate }} 
							</a>
							<a mat-menu-item   (click)="deleteSymbolMarket(dataItem)"   >
								 {{ 'Delete' | translate }} 
						     </a>
						 
						 </mat-menu>
					 
						</ng-template>
				   
				   </kendo-grid-column>
		 </kendo-grid>
			 

            
		</div>
 
	</mat-card>
</div>