 
import { Routes } from '@angular/router';  
 import { BankListComponent } from './containers/bank-list/bank-list.component';

export const BankManagementRoutes: Routes = [
  {
     path: '',
     redirectTo: 'banks',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
        {
           path: 'banks',
           component:  BankListComponent
        }  
     ]
  }
];