
<form [formGroup]="addNewSymbolForm" class="popup-card-width add-new-user">
	 
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
			   <div fxLayout="column">
				  <h4 class="mrgn-b-none">{{title}}</h4>
			   </div>

			</div>
			<mat-divider></mat-divider>
		 </div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

                    <div class="padding-input-form">
 				 
 
					  
						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Minimum' | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Minimum" 
							  formControlName="Minimum"
							 
							  [(ngModel)]="symbolMarketCommission.Minimum"  >  
						</mat-form-field>
 

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Maximum' | translate }}</mat-label>
						  <input class="form-control" matInput type="number" placeholder="Maximum" 
							  formControlName="Maximum"
						
							  [(ngModel)]="symbolMarketCommission.Maximum"  >  
						</mat-form-field>
			 

					<mat-form-field class="width50 form-field " appearance="outline">
						<mat-label>{{ 'Commission%' | translate }}</mat-label>
					  <input class="form-control" matInput type="number" placeholder="Commission %" 
						  formControlName="CommissionPerc"
					   
						  [(ngModel)]="symbolMarketCommission.CommissionPerc"  >  
					</mat-form-field>

					 </div> 
 
				</div>
		    </div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none"> 
		<button   (click)="onFormSubmit()" type="submit" 
		 [disabled]="!addNewSymbolForm.valid"   mat-raised-button color="warn">{{ 'SUBMIT' | translate }}</button>
		<button type="button" mat-raised-button  (click)="close()" color="primary">{{ 'CLOSE' | translate }} </button>
	
	</mat-dialog-actions>
</form>