<form #form="ngForm" class="client-form add-new-user" [formGroup]="addNewMarketForm"> 
<div fxLayout="column">
   
	<mat-card class="mrgn-b-md">
	      <div class="gene-card-title">
         <div fxLayout="row" fxLayoutAlign="start center">
            <div fxLayout="column">
               <h4>{{'Sub Markets'| translate }} </h4>
            </div>
            <span fxFlex></span>
         </div>
         <mat-divider></mat-divider>
      </div>

      
      <div class="gene-card-content">
		               
                     <div fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{'Market Code' | translate }} </mat-label>
                                 <input class="form-control" matInput type="input"
                                 [readonly]="this.isUpdate" 
                                    placeholder="{{'Code'  | translate }} " formControlName="Code"
                                    [(ngModel)]="market.Code">
                              </mat-form-field>
                           </div>


                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{'Market Name English' | translate }} </mat-label>
                                 <input class="form-control" matInput type="input"
                                    placeholder="{{'Market Name English'  | translate }} " formControlName="NameEn"
                                    [(ngModel)]="market.NameEn">
                              </mat-form-field>
                           </div>



                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{'Market Name Arabic' | translate }} </mat-label>
                                 <input class="form-control" matInput type="input"
                                    placeholder="{{'Market Name Arabic'  | translate }} " formControlName="NameAr"
                                    [(ngModel)]="market.NameAr">
                              </mat-form-field>
                           </div>


                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{'Type' | translate }} </mat-label>
                                 <mat-select [(ngModel)]="market.MarketType" name="MarketType" formControlName="MarketType"
                                 [disabled]="this.isUpdate" >
                                    <mat-option *ngFor="let marketType of  marketTypes" [value]="marketType.id">
                                       {{marketType.name  | translate }}</mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div> 

                           <div fxFlex.gt-sm="19" fxFlex.gt-xs="19" fxFlex="100" fxFlex.gt-md="19">
                              <mat-form-field class="full-wid form-field" appearance="outline">
                                 <mat-label>{{'Status' | translate }} </mat-label>
                                 <mat-select [(ngModel)]="market.IsActive" name="IsActive" formControlName="IsActive"
                                  >
                                    <mat-option *ngFor="let status of  statusList" [value]="status.id">
                                       {{status.name}}</mat-option>
                                 </mat-select>
                              </mat-form-field>
                           </div> 

                        </div>
  
                     </div>
                
				    </div>

	</mat-card>
</div>
<div class="
">
	<div  >
		<div>
			<mat-card class="pad-all-md">
            <div class="gene-card-title">
               <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxLayout="column">
                     <h4>{{'Details'| translate }} </h4>
                  </div>
                  <span fxFlex></span>
               </div>
               <mat-divider></mat-divider>
            </div>
      
				<div class="gene-card-content">
					<div class="trade-crypto crypto-input-prefix">
						<mat-tab-group color="accent" class="mat-tab-body-sub ">

                     <mat-tab label="{{'Sessions' | translate}}" >

								<div fxLayout="column">
									<div fxLayout="row wrap" fxLayoutAlign="space-between center">
										<ms-market-session-list   (sessionEmitter)="getSessions($event)" marketId={{marketId}}></ms-market-session-list>
									</div>

								</div>
							</mat-tab>
                     <mat-tab label="{{'Default Commisions' | translate}}" >

								<div fxLayout="column">
									<div fxLayout="row wrap" fxLayoutAlign="space-between center">
										<ms-market-manage-commission-list  (commissionEmitter)="getCommissions($event)"  _marketId={{marketId}}></ms-market-manage-commission-list>
									</div>

								</div>
							</mat-tab>
							<mat-tab label="{{'Working Days' | translate}}">
                        <br/>
                        <div  fxLayout="row wrap">
                           <div fxFlex.gt-sm="70" fxFlex.gt-xs="70" fxFlex="70" fxFlex.gt-md="70">
                              <mat-form-field class="full-wid form-field " appearance="outline">
                                 <mat-label>{{ 'Working Day' | translate }} </mat-label>
                                 <mat-select [(ngModel)]="marketWorkingDay.WorkingDays" name="WorkingDay" formControlName="WorkingDayName" multiple>
                   
                                   <mat-option *ngFor="let workingDay  of  itemWorkingDays" [value]="workingDay.id">
                   
                                     {{workingDay.name | translate}}
                                   </mat-option>
                                 </mat-select>
                               </mat-form-field>
                           </div>
                        </div>
             
                        <br/>
							</mat-tab>

                     <mat-tab label="{{'Settings' | translate}}">
                        <br/>
								<div  fxLayout="row wrap">
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
                              <mat-checkbox class="example-margin"      [(ngModel)]="market.AllowCheckBalance"      formControlName="AllowBalance"  >{{  'Check Balance' | translate}}</mat-checkbox>
                           </div>
								</div>
                        <div  fxLayout="row wrap">
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
                              <mat-checkbox class="example-margin"     [(ngModel)]="market.AllowCheckQty"      formControlName="AllowQty"  >{{'Check Qty' | translate}}</mat-checkbox>
                           </div>
								</div>

                        <div  fxLayout="row wrap">
                           <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxFlex.gt-md="100">
                              <mat-checkbox class="example-margin"    [(ngModel)]="market.AllowCheckWarehouse"     formControlName="AllowWarehouse"  > {{'Show Warehouse' | translate}}</mat-checkbox>
                            </div>
 
								</div>
                        <br/>
							</mat-tab>
						</mat-tab-group>
                  <div fxLayout="row" fxLayoutAlign="start">
                     <button 
                     (click)="onBasicFormSubmit()" [disabled]="!addNewMarketForm.valid"   type="submit" mat-raised-button color="warn"
                     *ngIf="auth.isGranted(_permissions.EditMarkets)" >{{ 'SUBMIT' | translate }}  </button>
                     <a  [routerLink]="['/markets/sub-markets/'  ]"  mat-raised-button color="primary">{{ 'Go To List' | translate }}</a>

                  </div>
					</div>
				</div>
			</mat-card>
		</div>
	</div>

</div>
</form>