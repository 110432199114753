import { SignalRService } from '../../../service/signalr/signalr.service';
import { SetupApi } from 'app/Shared/api/setup.api';
import { StoreKeeperDto, StatisticsModel, SymbolQtySummaryDto } from './../../../models/warehouse';
import { Component, OnInit, Injector } from '@angular/core';

import { PageTitleService } from '../../../core/page-title/page-title.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { WarehouseApi } from 'app/warehouse-managment/api/warehouse.api';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { State, process } from '@progress/kendo-data-query';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'symbol-quantity',
  templateUrl: './symbol-quantity.component.html',
  styleUrls: ['./symbol-quantity.component.scss']
})
export class SymbolQuantityComponent extends AppComponentBase implements OnInit {

  public view:GridDataResult;
  title: any;
  gridState:State = {skip:0,take:10}
  public actions: any;
  public action: any;
  dialogRef: MatDialogRef<DemoDialog>;
  storeKeeper:StoreKeeperDto = new StoreKeeperDto();
  dataSources = {storeKeepers:[],
  stores:[],
  sections:[]
  ,intervals:[],
  status:[]};

  statistics:StatisticsModel= new StatisticsModel();
  ngOnDestroy() {

  }

  constructor(private pageTitleService: PageTitleService,
    private warehouseService: WarehouseApi,
    private _setupApi:SetupApi,
    injector:Injector) {
      super(injector)

     }


  ngOnInit(): void {

    this.title = this.translate('Warehouse Balances');

    setTimeout(() => {
      this.pageTitleService.setTitle(this.title);
    }, 0);


    
this._setupApi.getStoreKeeperInfo()
.subscribe(res=>{
  this.storeKeeper = res
  this.dataSources.sections = res.StoreInfo.StoreSections;
  this.dataSources.storeKeepers = res.StoreInfo.StoreKeepers
  this.dataSources.stores = [res.StoreInfo];
});

this.getList();
  }



dataItems:SymbolQtySummaryDto[] = [];


  getList() {


      this.warehouseService.getSymbolQtySummary().subscribe(

        data=>{this.dataItems = this.setDataList(data)

          this.view= process(this.dataItems,this.gridState); //process(this.dataItems,this.gridState);
        }
        
        )
  }


  setDataList(res: SymbolQtySummaryDto[]): SymbolQtySummaryDto[] {
    if(res == null || res == undefined)
    {
      return Array<SymbolQtySummaryDto>();
    }
    else
    return res;
  }

}
