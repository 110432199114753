export class BankDto {
    BankID: number;
    BankNameEn: string;
    BankNameAr: string;
    SwiftCode: string;
    FTPIP: string;
    FTPUserName: string;
    FTPPassword: string;
    IsDeleted: boolean;
    CreatedUserId: string;
    CreatedDate: Date;
    LastUpdatedUserId: string;
    LastUpdatedDate: Date;
  }