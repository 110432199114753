<form [formGroup]="addNewForm"  class="popup-card-width add-new-user">
	<!-- class="popup-card-width-big"  -->
	<mat-dialog-content>
		<div class="gene-card-title">
			<div fxLayout="row" fxLayoutAlign="start center">
				<div fxLayout="column">
					<h4 class="mrgn-b-none">{{title| translate }}</h4>
				</div>

			</div>
			<mat-divider></mat-divider>
		</div>
		<div class="gene-card-content">
			<div class="trade-crypto crypto-input-prefix">
				<div class="pad-t-sm">

					<div class="padding-input-form">



						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{'Name Arabic'| translate }}</mat-label>
							<input class="form-control" matInput type="input" placeholder="{{'Name Arabic'| translate }}"
								formControlName="CommissionNameAR" [(ngModel)]="commission.CommissionNameAR">
						</mat-form-field>

						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{'Name English'| translate }}</mat-label>
							<input class="form-control" matInput type="input" placeholder="{{'Name English'| translate }}"
								formControlName="CommissionNameEN" [(ngModel)]="commission.CommissionNameEN">
						</mat-form-field>


						<mat-form-field class="width50 form-field " appearance="outline">
							<mat-label>{{ 'Status' | translate }}</mat-label>
							<mat-select [(ngModel)]="commission.IsActive" name="IsActive" formControlName="IsActive">
								<mat-option *ngFor="let status of  statusLst" [value]="status.id">
									{{status.name}}</mat-option>
							</mat-select>
						</mat-form-field>


 







					</div>

				</div>
			</div>
		</div>
		<!-- (click)="dialogRef.close()" -->
	</mat-dialog-content>
	<mat-dialog-actions class="padding-input-form pad-b-none">
		<button (click)="onFormSubmit()" type="submit" [disabled]="!addNewForm.valid" mat-raised-button
			color="warn">{{'SUBMIT'| translate }}</button>
		<button type="button" mat-raised-button (click)="close()" color="primary">{{'CLOSE'| translate }}</button>

	</mat-dialog-actions>
</form>