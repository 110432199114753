import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Expense } from '../../models/expense';
import { Period } from '../../models/period';
import { User } from '../../models/user';
import { ChangePasswordRequest } from '../../models/changePasswordRequest';
import { AppConsts } from '../../core/config';
import { tap } from 'rxjs/operators';  
import { OrderReuqest } from 'app/models/order';

@Injectable()
export class OperationApi {
  
  private readonly API_URL = `${AppConsts.bussinessApiUrl}` + `${AppConsts.setupUrl}`;

  constructor(private http: HttpClient) {}

 

  getAuctions(): Observable<[]> {

    return this.http.get<[]>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getActiveAuctionView`);
  }
  getAuctionPayments(request:any ): Observable<any> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getAuctionPayments`
    ,request)
    .pipe(tap(data => {}));
  }
  getFilterAuctions(request:any): Observable<any> {

    return this.http.get<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/getFilterAuctions`+
    this.convertObjectToQueryParameters(request));
  }
  insertOrupdateauctionPayment(auctionPaymentDto: any): Observable<boolean> {

    return this.http.put<any>(`${AppConsts.bussinessApiUrl}${AppConsts.orderUrl}/insertOrUpdateAuctionPayment/` , 
         auctionPaymentDto)
  }
 
  convertObjectToQueryParameters(request): string {
    let queryParameters: string = "";
    for (let property in request) {
        if (request[property] != null)
            queryParameters += queryParameters != "" ? "&" + property + "=" + request[property] : "?" + property + "=" + request[property];
    }
    if (queryParameters == "")
        return "";
    return queryParameters
}
 
}