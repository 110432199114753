import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs'; 
import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 

 

import { TranslateModule } from '@ngx-translate/core';
  
import { ClientStatementsComponent } from './containers/client-statements/client-statements.component';
import { SettlementApi } from './api/settlement.api';
import { SettlementService } from './services/settlement.service';
import { settlementManagmentRoutes } from './settlement-managment.routing';
import { SharedModule } from 'app/shared/shared.module';
import{TradeSettlementsComponent} from './containers/trade-settlements/trade-settlements.component';
 import {UploadClientStatementFormDialogComponent} from './components/upload-client-statement-form-dialog/upload-client-settlement-form-dialog.component';
import{ClientStatementDiskFormDialogComponent}from './components/client-statement-disk-form-dialog/client-statement-disk-form-dialog.component';
import{ClientStatementFormDialogComponent}from './components/client-statement-form-dialog/client-statement-form-dialog.component';
 import{ClientBalancesComponent} from './containers/client-balances/client-balances.component';
import{ClientBalanceDetialsComponent}from './components/client-balance-detials/client-balance-detials.component';
import{ClientPurchasePowerFormDialogComponent} from './components/client-purchase-power-form-dialog/client-purchase-power-form-dialog.component';
import {ClientSettlementsComponent} from './containers/client-settlements/client-settlements.component';
import{UnSettlementTradesComponent}from './components/unSettlement-trades/unSettlement-trades.component';
import{BankMasrLogComponent} from './containers/bank-masr-log/bank-masr-log.component';
import { ClientPurchasePowerComponent } from './containers/client-purchase-power/client-purchase-power.component';
import{CancelTradeSettlementsComponent}from './containers/cancel-trade-settlements/cancel-trade-settlements.component';
  @NgModule({
  imports: [   ReactiveFormsModule, 
    CommonModule,
    FlexLayoutModule,
    RouterModule.forChild(settlementManagmentRoutes),
    MatInputModule,
    TranslateModule, 
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatSelectModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      FormsModule,
      MatDialogModule  ,
        ExcelModule,
      TranslateModule, 
      SharedModule , 
      GridModule,TranslocoRootModule,DragDropModule,ResizableModule,
 
  ],
  declarations: [ 
    ClientStatementsComponent, 
    ClientSettlementsComponent,
    ClientStatementFormDialogComponent,
    UploadClientStatementFormDialogComponent,
    ClientStatementDiskFormDialogComponent,
    ClientBalancesComponent,
    ClientBalanceDetialsComponent,
    ClientPurchasePowerFormDialogComponent,
    UnSettlementTradesComponent,
    TradeSettlementsComponent,
    BankMasrLogComponent,
    ClientPurchasePowerComponent,
    CancelTradeSettlementsComponent
  ], 
  providers: [SettlementApi ,SettlementService]
})
export class SettlementManagementModule { }