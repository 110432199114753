import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyManagementService } from '../../services/company-management.service';
import { CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { permissions } from 'app/core/permission';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ClientTypeEnum, CompanyTypeEnum, TradeTypeEnum } from 'app/core/enums';
import { Helpers } from 'app/helper/helpers';
import { AppConsts } from 'app/core/config';
import { BrokerManagementService } from 'app/broker-management/services/broker-management.service';

@Component({
  selector: 'ms-company-form-dialog',
  templateUrl: './company-form-dialog.component.html',
  styleUrls: ['./company-form-dialog.component.scss']
})
export class CompanyFormDialogComponent  extends AppComponentBase   implements OnInit {

  title: string;
  company: CompanyDto = new CompanyDto();
  addNewCompanyForm: FormGroup; 
  isdisable:boolean;
  companyTypes:any;
  warehouses:any;
  errors:any;
  companyCode:any;
  CompanyTypeEnum=CompanyTypeEnum;
  nationalityList: any; 
  identityTypeList: any;
  randamPassword:any;
  symbols:any;
  filteredSymbols:any;
  isUpdate: boolean;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CompanyFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    ,private companyManagementService: CompanyManagementService  ,
     private brokerManagementService :BrokerManagementService ,
     private setupApi :SetupApi,
     private _helpers:Helpers   , 
      injector: Injector
    ) {
      super(injector) 
      
      if (data.company != null) {
        this.company=data.company;
        this.companyManagementService.getCompanySymbolsByCompanyId(this.company.Id).subscribe(res=>{
          this.company.SymbolIds=res;
        });
       
        this.title=this.translate("Update Company")+  " - "+ this.company.Name;
      }else{
     
        this.title=this.translate("Add New Company");   
      }
      
      this.randamPassword=_helpers.generatePassword(12);
    }

  ngOnInit(): void {
    if( !this.auth.isGranted(permissions.EditCompanies)){
      this.isdisable=true;
      this.isUpdate=true; 
     }else{
      if(this.company.Id>0){
        this.isUpdate=true; 
        if(this.company.TypeId==CompanyTypeEnum.B){
          this.brokerManagementService.getCompanyBrokerByCompanyId(this.company.Id).subscribe(
            res=>{
           
              this.company.IdentityTypeID = res.IdentityTypeID; 
              this.company. IdentityText = res.NationalID;
              this.company.NationalityID = res.NationalityID;
              this.company.IpAddress = res.IpAddress;
            }
          )
        }
    
      }else{
        this.isUpdate=false; 
      }
         
     }
    this.addNewCompanyForm = new FormGroup({ 
      
      Name: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      IsActive: new FormControl({ value: '' } ),
      NameAr: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      AddressEn: new FormControl({ value: '' } ),
      AdressAr: new FormControl({ value: '' } ),
      CityID: new FormControl({ value: '' } ),
      Telephone: new FormControl({ value: '' }, Validators.compose([Validators.required, Validators.pattern("[0-9]{8,15}"), Validators.maxLength(20)])),
      Email: new FormControl({ value: '' } , Validators.compose([Validators.required , Validators.maxLength(50), Validators.email, Validators.required])),
      Website: new FormControl({ value: '' } ),
      Subnet: new FormControl({ value: '' } ),
      TypeId: new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IdentityTypeID : new FormControl({ value: '' } ),
      IdentityText: new FormControl({ value: '' } ),
      NationalityID: new FormControl({ value: '' } ),
      Code : new FormControl({ value: '' }, Validators.compose([Validators.required])),
      IpAddress : new FormControl({ value: '' }, Validators.compose([Validators.required])),
      SymbolIds: new FormControl({ value: '' } ),
      SymbolName: new FormControl({ value: '' } ),
    }); 
    this.  getStatusLookup();
    this. getSymbols();
  }
 
  close() {
    this.dialogRef.close(false);
  }
  getSymbols(){
    this.setupApi.getActiveSymbols().subscribe(res => {
      this.symbols = res; 
    });
  }
  onChangeSymbol(event) {
    //this.getMarketCommission(id.value);
   
    if(this.symbols != null)
    { 
      if(typeof event === 'string')
      {
      if( event.match(/^ *$/) !== null)
      this.filteredSymbols = this.symbols;
      else
      this.filteredSymbols = this.symbols.filter((s) => s.NameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
        || this.symbols.filter((s) => s.NameAr.indexOf(event.toLowerCase()) !== -1 );
      }
    }
  }
  getStatusLookup() {
    this.setupApi.getCompanyTypes().subscribe(res=>{
      this.companyTypes=res;
      });
      this.setupApi.getNationalities().subscribe(res=>{
        this.nationalityList=res;
        });
      
      this.setupApi.getIdentityTypes().subscribe(res => {
        this.identityTypeList = res;
        this.identityTypeList=   this.identityTypeList.filter(x=>x.ClientType==ClientTypeEnum.Corporate)
      });
  }

 
    // onFormSubmit method is submit a add new user form.
 onFormSubmit() { 
     
      this.errors=[];
      if (this.company.Id > 0) {
       

        this.companyManagementService.updateCompany(this.company ,this.company.Id).subscribe(() => {
          this.dialogRef.close(this.addNewCompanyForm.value);
         
          this.toastr.success ("Successfully!");
        },
        err =>{   
          
          this.errors.push(err.error[0]);
          this.toastr.error(err.error[0]);
          } );
        
        //this.dialogRef.close(this.addNewOrderForm.value);
      } else {
         if(this.company.TypeId==CompanyTypeEnum.B){
          this.company.Password = this.randamPassword;
          this.company.ConfirmPassword = this.company.Password;
          this.company.ClientCallbackUrl =  AppConsts.frontEndUrl+'/account/confirm';
         }
          this.companyManagementService.insertComapny(this.company) . subscribe(res => { 
            this.dialogRef.close(this.addNewCompanyForm.value);
            this.toastr.success ("Successfully!");
           },
           err =>{   
             
             this.errors.push(err.error[0]);
             this.toastr.error(err.error[0]);
            // console.log(err)
             } 
         );
  
      
  
      }
    }


    
}
