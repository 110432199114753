import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'; 
 
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
 
 
import { MatOptionModule } from '@angular/material/core'; 
import { MatExpansionModule } from '@angular/material/expansion';
 
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu'; 
import { MatProgressBarModule } from '@angular/material/progress-bar'  
import { DirectivesModule } from '../core/directive/directives.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ResizableModule } from 'angular-resizable-element'; 
import { TranslocoRootModule } from '../../app/transloco/transloco-root.module';
import { GridModule } from '@progress/kendo-angular-grid';   
import { MatDialogModule } from '@angular/material/dialog'; 
import { MarketService } from './services/market.service';
import { MarketApi } from './api/market.api';
import { MarketRoutes } from './market-managment.routing';
import {MarketListComponent} from './containers/market-list/market-list.component';
import {MarketFormDialogComponent} from './components/market-form-dialog/market-form-dialog.component';
import {MarketSymbolListComponent} from './components/market-symbol-list/market-symbol-list.component';
import{MarketFormComponent}from './components/market-form/market-form.component';
import{SymbolMarketFormDialogComponent} from './components/symbol-market-form-dialog/symbol-market-form-dialog.component';
import{MarketCommissionListComponent} from'./components/market-commission-list/market-commission-list.component'
import{MarketCommissionDialogComponent} from './components/market-commission-dialog/market-commission-dialog.component'
import { TranslateModule } from '@ngx-translate/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker'; 
import { SharedModule } from 'app/shared/shared.module';
import{MarketSessionListComponent} from './components/market-session-list/market-session-list.component'
import{MarketSessionDialogComponent}from './components/market-session-dialog/market-session-dialog.component'
import{MarketWorkingDayComponent} from  './components/market-working-day/market-working-day.component'
import{MarketSettingsComponent} from './components/market-settings/market-settings.component';
import{MarketCalendarListComponent} from './components/market-calendar-list/market-calendar-list.component'
import{MarketCalendarDialogComponent} from './components/market-calendar-dialog/market-calendar-dialog.component';
import { SubMarketListComponent } from './containers/sub-market-list/sub-market-list.component';
import { MatRadioModule } from '@angular/material/radio';
 
@NgModule({
  imports: [   ReactiveFormsModule, 
    CommonModule,
    TranslateModule, 
    FlexLayoutModule,
    RouterModule.forChild(MarketRoutes),
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDividerModule,
    NgxMatTimepickerModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatChipsModule,
    MatRadioModule,
    MatSelectModule,  
          CommonModule,
      DirectivesModule,
      MatMenuModule,
      MatListModule,
      MatDialogModule  ,
      SharedModule,
      GridModule,TranslocoRootModule,DragDropModule,ResizableModule
  ],
  declarations: [ MarketListComponent ,MarketFormDialogComponent ,MarketSymbolListComponent
    ,SymbolMarketFormDialogComponent,
    MarketCommissionDialogComponent,
    MarketCommissionListComponent,
    MarketFormComponent,
    MarketSessionListComponent,
    MarketSessionDialogComponent,
    MarketWorkingDayComponent,
    MarketSettingsComponent,
    MarketCalendarListComponent,
    MarketCalendarDialogComponent,
    //SymbolMarketListComponent,
    SubMarketListComponent
  
  ], 
  exports :[
    MarketCommissionDialogComponent,
  ],
  
  providers: [MarketApi ,MarketService]
})
export class MarketManagmentModule { }