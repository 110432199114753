import { Component, Inject, Injector, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponentBase } from 'app/app-component-base';
import { ClientService } from 'app/client-managment/services/client.service';
import { StoreClientDto, StoreDto } from 'app/models/store';
import { SetupApi } from 'app/Shared/api/setup.api';
 
import { ToastrService } from 'ngx-toastr';
 

@Component({
  selector: 'ms-client-store-form-dialog',
  templateUrl: './client-store-form-dialog.component.html',
  styleUrls: ['./client-store-form-dialog.component.scss']
})
export class ClientStoreFormDialogComponent   extends AppComponentBase implements OnInit {

  title: string; 
  clientId:number;
  statusLst: any;  
  addNewWarehouseForm: FormGroup;
  isUpdate: boolean;
  errors: any;
  statusList: any;
  sections: any;
  warehouse : StoreClientDto= new StoreClientDto();
  warehouses: StoreDto[];
  filteredWarehouses: any;

  constructor(public formBuilder: FormBuilder,
    private clientService: ClientService,
    private setupApi :SetupApi,
    private dialogRef: MatDialogRef<ClientStoreFormDialogComponent>
    , @Optional() @Inject(MAT_DIALOG_DATA) private data: any
    , injector: Injector
    ) {
      
      super(injector);
      
      if(data!=null){
        this.clientId=data.clientId as number;
        if(data.clientStore!=null){

          this.warehouse=data.clientStore;
          this.onChangeWarehouse( this.warehouse.StoreID);
        }
      }
   
   this.title=this.translate("Assign Client Store");
    this.statusLst = [
      { "id": true, "name": this.translate("Active") },
      { "id": false, "name":  this.translate("InActive") }
    ];

     

  }

  ngOnInit(): void {
    this. addNewWarehouseForm =new FormGroup({

      StoreID: new FormControl({ value: '' },Validators.compose([Validators.required]) ),
      StoreName: new FormControl({ value: 'StoreName' }),
      SectionID: new FormControl({ value: '' },Validators.compose([Validators.required]) ),     
      ContractNo: new FormControl({ value: '' } ),
      ContractStartDate: new FormControl({ value: '' } ),
      ContractEndDate: new FormControl({ value: '' } ),
      Area: new FormControl({ value: '' }),
   //   ContractStatus: new FormControl({ value: '' } ),

    });
 
    if(this.isStoreKeeper()){
 
       this.setupApi.getStoreKeeperInfo().subscribe(res=>{
        if(res!=null){
          this.warehouse.StoreID=res.StoreID;
          this.onChangeWarehouse(res.StoreID);
        }
      });
    }else{
      this.setupApi.getWarehouseLookups()  .  subscribe(res => {
        this.warehouses = res;
      },   );
    }

  }

  onChangeWarehouse(id){
 
    this.setupApi.getWarehouseById(id).subscribe(res=>{
      
      this.sections=res.StoreSections;
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  onWarehouseFormSubmit() {
    this.errors = []; 
    this.warehouse.ClientID=Number(this.clientId);
    this.clientService.addStoreClient(this.warehouse ,this.warehouse.StoreID).subscribe(() => {
      this.dialogRef.close(this.addNewWarehouseForm.value);
      this.toastr.success(  this.translate("Successfully"));
    },
      err => {

        this.errors.push(  this.translate(err.error[0]));
        this.toastr.error(  this.translate(err.error[0]));
    });

  }
  
  onFilterWarehouse(event) {

    if(this.warehouses != null)
      { 
        if(typeof event === 'string')
        {
        if( event.match(/^ *$/) !== null)
        this.filteredWarehouses = this.warehouses;
        else
        this.filteredWarehouses = this.warehouses.filter((s) => s.StoreNameEn.toLowerCase().indexOf(event.toLowerCase()) !== -1 )
          || this.warehouses.filter((s) => s.StoreNameAr.indexOf(event.toLowerCase()) !== -1 );
        }
      }
  }

  
}
