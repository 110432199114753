import { Component, EventEmitter, Inject, Injector, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyDto } from 'app/models/broker';
import { ToastrService } from 'ngx-toastr';
import { AppComponentBase } from 'app/app-component-base';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { permissions } from 'app/core/permission';
import { SetupApi } from 'app/Shared/api/setup.api';
import { ApproveStatusEnum, BalanceSourceEnum, BalanceTypeEnum, ClientTypeEnum, CompanyTypeEnum, StatusEnum, TradeTypeEnum } from 'app/core/enums';
import { Helpers } from 'app/helper/helpers';
import { AppConsts } from 'app/core/config';
import { ClientStatementDto } from 'app/models/ClientStatement';
import { OperationService } from 'app/operation-managment/services/operation.service';
import { EnumHelpers } from 'app/helper/EnumHelpers';
import { ClientReuqest } from 'app/models/client';
import { SettlementService } from 'app/settlement-managment/services/settlement.service';
import { ClientService } from 'app/client-managment/services/client.service';

@Component({
  selector: 'ms-client-statement-form-dialog',
  templateUrl: './client-statement-form-dialog.component.html',
  styleUrls: ['./client-statement-form-dialog.component.scss']
})

export class ClientStatementFormDialogComponent  extends AppComponentBase   implements OnInit {

  title: string;
  ClientStatement: ClientStatementDto = new ClientStatementDto();
  addNewClientStatementForm: FormGroup; 
  clientReuqest: ClientReuqest=new ClientReuqest();
  isdisable:boolean; 
  errors:any;
  balanceTypes:any;  
  balanceSources:any;
  isUpdate: boolean;
  clients:any;
  companies:any;
  Filterclients:any;
  currencies:any;
  CompanyID:any;
  isApprove:any;
  constructor( 
    public formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ClientStatementFormDialogComponent>
    ,@Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private _helpers:EnumHelpers    ,
     private _setupApi :SetupApi,
     private _settlementService:SettlementService   , 
     private _clientService:ClientService   , 
     
      injector: Injector
    ) {
      super(injector) 
      this.isApprove=data.isApprove;
      debugger;
      if (data.ClientStatement != null) {
        this.ClientStatement=data.ClientStatement;
        this.isUpdate=data.isUpdate; 
        this.clientReuqest.ClientId = this.ClientStatement.ClientId;
        // this.CompanyID= this.ClientStatement.CompanyId;

        if(this.ClientStatement.Amount<0){
          this.ClientStatement.BalanceType=BalanceTypeEnum.Withdraw;
          this.ClientStatement.Amount=this.ClientStatement.Amount*-1;
        }else{
          this.ClientStatement.BalanceType=BalanceTypeEnum. Deposit;
        }

        this.title=this.translate("Update Client Statement");
      }else{
        this.ClientStatement.BalanceDate=new Date();
        this.title=this.translate("Add Client Statement");   
      }
      if( this.isApprove){
        this.title=this.translate("Approve Client Statement");
       this.ClientStatement.Amount=null;
      }
      this.balanceTypes =this. _helpers.getNamesAndValues(BalanceTypeEnum); 
      this.balanceSources =this. _helpers.getNamesAndValues(BalanceSourceEnum);
      this._setupApi.getCurrencies().subscribe(res=>{  this.currencies=res;  });
    }

  ngOnInit(): void {
    if( !this.auth.isGranted(permissions.UpdateClientStatement)){
     // this.isdisable=true;
      //this.isUpdate=true; 
     } 

     this._setupApi.getActiveCompanies().subscribe(res => {  this.companies = res;         this.changeClientFilter("");     });

    this. addNewClientStatementForm = new FormGroup({  
      CompanyId: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      ClientId: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      CurrencyId: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      BalanceDate: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      Amount: new FormControl({ value: '' } , Validators.compose([Validators.required, Validators.min(0)])),
      Remark: new FormControl({ value: '' } ), 
      BalanceType: new FormControl({ value: '' } , Validators.compose([Validators.required])),
      itemName: new FormControl({ value: '' } ), 
      itemNameseaRCHb: new FormControl({ value: '' } ),
      companyNameseaRCHbs: new FormControl({ value: '' } ),
    }); 
 

  }
 
  close() {
    this.dialogRef.close(false);
  }


 
  onChangeCompany(event) { 
    debugger;
    this.ClientStatement.ClientId=null;
    this.clients=[];
    this.clientReuqest.CompanyId=event.value;
    this.CompanyID=event.value;  

  }
  changeClientFilter(filter:any)
  {  
    let cfilter:ClientReuqest= new ClientReuqest();
    if(this.CompanyID>0){
     cfilter.CompanyId=this.CompanyID;
      cfilter.ApproveStatusId = ApproveStatusEnum.Approve;
      cfilter.Status = StatusEnum.Active;
      this._clientService.searchClients( cfilter).subscribe(res=>this.clients  =  res.clients);
    }

  }


    // onFormSubmit method is submit a add new user form.
  onFormSubmit() {  
 
      this.errors=[];
      if(this.isApprove){
        this._settlementService. approveClientStatement(this.ClientStatement.Id,this.ClientStatement).subscribe(() => {
          this.dialogRef.close(this. addNewClientStatementForm.value);
           this.toastr.success ("Successfully!");
       },
       err =>{   
         
         this.errors.push(err.error[0]);
         this.toastr.error(err.error[0]);
         } );
      }else{
        if(this.isUpdate==true){
          this._settlementService. updateClientStatement(this.ClientStatement.Id,this.ClientStatement).subscribe(() => {
            this.dialogRef.close(this. addNewClientStatementForm.value);
          
             this.toastr.success ("Successfully!");
         },
         err =>{   
           
           this.errors.push(err.error[0]);
           this.toastr.error(err.error[0]);
           } );
        }else{
          this.ClientStatement.BalanceSource=BalanceSourceEnum.Manual;
          this._settlementService. createClientStatement  (this.ClientStatement).subscribe(() => {
            this.dialogRef.close(this. addNewClientStatementForm.value);
          
             this.toastr.success ("Successfully!");
         },
         err =>{   
           
           this.errors.push(err.error[0]);
           this.toastr.error(err.error[0]);
           } );
        }
      }


    }



}
