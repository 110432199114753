import { Component, Injector, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DemoDialog } from 'app/components/dialog/dialog.component';
import { OrderManagementApi } from 'app/order-management/api/order-management.api';
import { SignalRService } from '../../../service/signalr/signalr.service';
import { OrderDto } from 'app/models/order';
 import { DatePipe } from '@angular/common';
import { GridComponent, GridItem, RowClassArgs } from '@progress/kendo-angular-grid';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
 import { State } from '@progress/kendo-data-query';
import { AppComponentBase } from 'app/app-component-base';
import { SetupApi } from 'app/Shared/api/setup.api';
import { take } from 'rxjs/operators';
import {   OrderSatausEnum, MarketStatusEnum, EntityTypeEnum } from 'app/core/enums';
import { DashboardParamService } from 'app/core/page-title/dashboard.param.service';
import { MarketSessionDto } from 'app/models/market';
import { OrderHistoryComponent } from 'app/order-management/components/order-history/order-history.component';
import { OrderTicketDialogComponent } from '../orderTicket-dialog/orderTicket-dialog.component';
import { OrderAttachmentComponent } from 'app/order-management/components/order-attachment/order-attachment.component';
import { FileAttachmentDto } from 'app/models/client';
 
@Component({
  selector: 'ms-order-watch',
  templateUrl: './order-watch.component.html',
  styleUrls: ['./order-watch.component.scss']
})

export class OrderWatchComponent extends AppComponentBase implements OnInit {

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 50
  };
  loadOrders: boolean = false;
  selectBy: any;
  items: OrderDto[];
  changeOrders: number[];
  form: FormGroup;
  orderUpdates: any[] = [];
  dialogRef: MatDialogRef<DemoDialog>;
  result: string;
  brokerID: any;
  companyID: any;
  isPreOpen: any=false;
 
  marketStatus: any;
  private _orderStatus: number;
  @Input() set orderStatus(value: number) {

    this._orderStatus = value; 
    this.getOrderWatch();
     
  }

  public _marketId: number;
  @Input() set marketId(value: number) {
    this._marketId = value;

    this.getOrderWatch();
    
  }

  constructor(
    private ngZone: NgZone, private readonly signalrService: SignalRService,
    private datePipe: DatePipe,
    private setupApi: SetupApi,
    private dashboardParamService: DashboardParamService,
    private dialog: MatDialog, private orderManagementApi: OrderManagementApi,

    private pageTitleService: PageTitleService
    , injector: Injector
  ) {
    super(injector);

    this.dashboardParamService.loadIsOpen.subscribe(res => {

      if (res != null) {  
      
          if (res==true) {
          this.isPreOpen = true;
        } else {
          this.isPreOpen = false;
        }
      }

    });

    this.dashboardParamService.loadMarketStatus.subscribe(res => {
      if(res!=null){

       this.marketStatus=res;
       this.getOrderWatch();
      }

    });
    signalrService.monitorServiceChanges.subscribe(item => {
      this.getOrderWatch();
    });

    signalrService.orderChanges.subscribe(item => {
      //this.items = [item, ...this.items];
  
      console.log("orderChanges");
      let orders = JSON.parse(item);
 
      orders.forEach((element, index) => {

        if (this.items != null) {
         
          if ((this.items.filter(x => x.OrderID === orders[index].OrderID).length > 0)) {
         
            this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderStatus
              = orders[index].OrderStatus;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderQty
              = orders[index].OrderQty;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].RemainingQty
              = orders[index].RemainingQty;

              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].OrderPrice
              = orders[index].OrderPrice;
              this.items.filter(x => x.OrderID == orders[index].OrderID)[0].MinimumFill
              = orders[index].MinimumFill;
            // this.items[index].isUpdate = true;
          } else {
            // this.items[index].isUpdate = false;
            this.items = [element, ...this.items];
          }
        }


      });
      
      this.onChangeOrderStatus();



    });


  }
  downloadAttachment(order ){ 
    const editingDialog = this.dialog.open(OrderAttachmentComponent, {
      data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:true}
    });
    editingDialog.afterClosed().subscribe((res) => { });
  }
  
  public rowCallback(context: RowClassArgs) {


    if (context.dataItem.BrokerID == this.brokerID) {
      var isBuy = 0;
      if (context.dataItem.OrderType === 1) {
        var isBuy = 1;
      }
      return {
        myOrderColorBuy: isBuy, myOrderColorSell: !isBuy
      };
    } return null;
  }

  onChangeOrderStatus() {
  
    if(this.items!=null){
 
      if (this.marketStatus == MarketStatusEnum.Preopen || this.isPreOpen) {
        this.items = this.items.filter(x => x.AuctionID == -1 && x.OrderStatus 
          == OrderSatausEnum.Private);
      }
      else {

        this.items = this.items.filter(x => x.OrderStatus == this._orderStatus);
      }
      if (this.companyID != null) {
        this.items = this.items.filter(x => x.CompanyID == this.companyID);
      }
      if (this._marketId != null) {
        this.items = this.items.filter(x => x.MarketID ==  this._marketId);
      }
     

    }

  }
  getOrderWatch() {
    if (Number(this._marketId) >0 &&Number(this._orderStatus) >0){
 
    var date = this.datePipe.transform(new Date(), "dd-MM-yyyyy");
  
    if (this.isBroker()) {
      this.  getUserBrokerInfo().subscribe(res => {
 
        if (res  != null  ) {
          this.companyID = res.CompanyId;
          this.brokerID = res.BrokerId; 
          this.orderManagementApi.getMyOrdersbyDate(date, date,
             this._marketId, this.companyID,
            this.gridState.skip, this.gridState.take
          ).subscribe(items => {
            this.items = items;
               this.onChangeOrderStatus();
          });
        }
      })


    } else {

      this.orderManagementApi.getOrdersbyDate(date, date, this._marketId,
         this.gridState.skip, this.gridState.take
        ).subscribe(items => {
          this.items = items; 
   
          this.onChangeOrderStatus();
        });
    }
  }

  }

  //////Update Order
  updateOrder(order) {
    var isAuction=false;
    if(order.AuctionID>0){
      isAuction=true;
    }
    const editingDialog = this.dialog.open( OrderTicketDialogComponent, {
      data: { id: order.OrderID, order: {...order } ,isAuction:isAuction}
    }
    );
    editingDialog.afterClosed().subscribe((res) => { }); 
  }
  viewOrder(order) {
    var isAuction=false;
    if(order.AuctionID>0){
      isAuction=true;
    }
    const editingDialog = this.dialog.open(OrderTicketDialogComponent, {
      data: { id: order.OrderID, order: {...order } ,isViewOnly:true,isAuction:isAuction }
    }
    );
    editingDialog.afterClosed().subscribe((res) => { }); 
  }

  //////  Order History
  getOrderHistory(order) {
    const editingDialog = this.dialog.open(OrderHistoryComponent, {
      data: {  OrderID: order.OrderID ,SymbolCode : order.SymbolCode }
    });
    editingDialog.afterClosed().subscribe((res) => { });

  }

  getAttachments(order){
    
    const editingDialog = this.dialog.open(OrderAttachmentComponent, {
      data: {  OrderID: order.OrderID  ,SymbolCode : order.SymbolCode, OnlyDownload:false}
    });
    editingDialog.afterClosed().subscribe((res) => { });
  }
  dialogHTML: string = `
 <button mat-raised-button type="button" (click)="openDialog()" color="primary">Open Dialog</button>
 <p *ngIf="result">You chose: {{ result }}</p>`;

  /**
   * OpenDialog method is used to open a dialog.
   */
  cancelOrder(order, index) {

    this.dialogRef = this.dialog.open(DemoDialog, {
      data: { message: 'would you like to cancel this order number ' + order.OrderID + '?' },
      disableClose: false
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'Yes!') {

        this.orderManagementApi.cancelOrder(order.OrderID).subscribe((res) => {

          // this.toastr.success("Successfully!");
        }, err => {
          this.toastr.error("Error");
          console.log(err)
        });
      }
      this.dialogRef = null;
    });
  }
  ngOnInit() {
    //this.getOrderWatch();

  }
  @ViewChild(GridComponent)
  public grid: GridComponent;

  public onDataStateChange(): void {
    // this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(2))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }


}
