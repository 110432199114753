import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../core/directive/directives.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ChartsModule } from "@progress/kendo-angular-charts";

import { GoldMarketManagementRoutes } from "./gold-market-management.routing";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { GridModule } from "@progress/kendo-angular-grid";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ResizableModule } from "angular-resizable-element";
import { SharedModule } from "app/shared/shared.module";
import { TranslocoRootModule } from "app/transloco/transloco-root.module";
 

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatOptionModule } from "@angular/material/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SlickCarouselModule } from "ngx-slick-carousel"; 
 

 
import { DatePipe } from "@angular/common";

import { GoldMarketApi } from "./api/gold-market.api";
import { GoldMarketService } from "./services/gold-market.service";

import { MatButtonToggleModule } from '@angular/material/button-toggle';
 
import { LayoutModule } from "@progress/kendo-angular-layout";
import { GoldMarketDashboardComponent } from "./containers/gold-market-dashboard/gold-market-dashboard.component";
import { GoldTradesComponent } from "./components/gold-trades/gold-trades.component";
import { GoldOrderComponent } from "./components/gold-order/gold-order.component";
import { GoldOrderListComponent } from "./components/gold-order-list/gold-order-list.component";
import { GoldWatchListComponent } from "./components/gold-watch-list/gold-watch-list.component";
import{GoldOrderCofirmationDialogComponent }from "./components/gold-order-cofirmation-dialog/gold-order-cofirmation-dialogcomponent";
import { GoldSymbolDetailsComponent } from "./components/gold-symbol-details/gold-symbol-details.component";
import{LazyLoadScriptService} from '../service/core/LazyLoadScriptService';
import { OrderManagementModule } from "app/order-management/order-management.module";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DirectivesModule,
    PerfectScrollbarModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    ChartsModule,
    MatSortModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    MatInputModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    MatExpansionModule,
    NgxDatatableModule,
    FlexLayoutModule,
    MatOptionModule,
    MatSelectModule,
    SlickCarouselModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    MatInputModule, 
    RouterModule.forChild(GoldMarketManagementRoutes),
    GridModule,
    TranslocoRootModule,
    DragDropModule,
    ResizableModule,
    SharedModule,MatButtonToggleModule,
    LayoutModule  ,
    OrderManagementModule  
  ],
  declarations: [
    GoldMarketDashboardComponent ,
    GoldTradesComponent,
    GoldOrderListComponent,
    GoldOrderComponent,GoldSymbolDetailsComponent,
    GoldWatchListComponent,
    GoldOrderCofirmationDialogComponent  
  ],
  

  providers: [
    DatePipe,
    LazyLoadScriptService,
    GoldMarketService,
    GoldMarketApi 
    
  ],
})
export class GoldMarketManagementModule {}
