import { Component, EventEmitter, Injector, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { throwMatDuplicatedDrawerError } from '@angular/material/sidenav';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { AppComponentBase } from 'app/app-component-base';
import { MarketWatchApi } from 'app/dashboard/api/market.watch.api';
import { SignalRService } from '../../../service/signalr/signalr.service'
import { CreateMarketWatchDto, MarketWatchDto } from 'app/models/MarketWatch'; 
import { OrderDto } from 'app/models/order';
import { take } from "rxjs/operators";
import { process } from "@progress/kendo-data-query";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { OrderTicketDialogComponent } from '../orderTicket-dialog/orderTicket-dialog.component';
 @Component({
  selector: 'ms-all-market-watch',
  templateUrl: './all-market-watch.component.html',
  styleUrls: ['./all-market-watch.component.scss']
})
export class AllMarketWatchComponent  extends AppComponentBase  implements OnInit {

 
  @Input()
  marketId: any;
  @Input()
  isfavourite: any;
  
 
  items: any; 
 

  @Input() set _items(value: any) {
 
    
    this.items = value;
    if(this.items!=null && this.items.length>0){
      
      //this.symbolEmitter.emit( this.items[0].SymbolCode); 
    }

  }
 

  selectBy:any;
  @Output() symbolEmitter = new EventEmitter<string>();
  @Output() favouriteEmitter = new EventEmitter<string>();
  
  public clickedRowItem;

  form: FormGroup;
  selectedOrderRow :OrderDto = new OrderDto();
  constructor(
    private ngZone: NgZone,
    private readonly signalrService: SignalRService, 
    private dialog: MatDialog,private marketWatchApi: MarketWatchApi,
      injector: Injector
    ) {
      super(injector);
  
      signalrService.marketChanges.subscribe(item => {

        let marketWatchs = JSON.parse(item);
       
        marketWatchs.forEach((element, index) => {

          if (this.items != null) {

            if ( this.items.filter(x => x.SymbolCode==element.SymbolCode).length > 0) {
        
              this.items.filter(x => x.SymbolCode==element.SymbolCode).isUpdate=true;
              var isfavourite= this.items.filter(x => x.SymbolCode == element.SymbolCode)[0].isfavourite;
              var trades= this.items.filter(x => x.SymbolCode == element.SymbolCode )[0].trades;
              element.trades=trades;
              element.isfavourite=isfavourite;
              this.items =   this.items.filter(x => x.SymbolCode!=element.SymbolCode);

               this.items = [ element,...this.items];
             
            }

          }
  
  
        });
  
         
      }); 
   
   
  }
 
  
  public rowCallback(context: RowClassArgs) {
   
    var isFlash = 0; 
    if(context.dataItem.isUpdate==true ){
      var isFlash = 1; 
      context.dataItem.isUpdate=false;
    }
    return {
        flash_down: isFlash 
    };
  }
 
 
  onCellClick(e) {
 
    this.clickedRowItem = e.dataItem;  
    this.symbolEmitter.emit( this.clickedRowItem.SymbolCode); 
  }
  addFavourite(marketWatch  ){
    
    var createMarketWatchDto=new CreateMarketWatchDto();
    createMarketWatchDto.IsRemove=false;
    createMarketWatchDto.SymbolCode=marketWatch.SymbolCode;
    createMarketWatchDto.UserId=this.currentUserId();
    this.marketWatchApi.updateFavouriteMarketWatches(createMarketWatchDto).subscribe(utems=>{

          
    
    })
    this.favouriteEmitter.emit( marketWatch.SymbolCode); 
   }
 
   removeFavourite(marketWatch  ){
    var createMarketWatchDto=new CreateMarketWatchDto();
    createMarketWatchDto.IsRemove=true;
    createMarketWatchDto.SymbolCode=marketWatch.SymbolCode;
    createMarketWatchDto.UserId=this.currentUserId();
    this.marketWatchApi.updateFavouriteMarketWatches(createMarketWatchDto).subscribe(utems=>{
      // 

  
    })
    this.favouriteEmitter.emit( marketWatch.SymbolCode); 
   }
  
 
  ngOnInit() {
    
  }
  @ViewChild(GridComponent)
  public grid: GridComponent;
  
  public onDataStateChange(): void {
    this.fitColumns();
  }

  private fitColumns(): void {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(10))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.items, {
        
        sort: [{ field: "SymbolCode", dir: "asc" }],
      }).data 
    };

    return result;
  }
  
}
