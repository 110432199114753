import { TransactionsListComponent } from './containers/transactions/transactions-list.component';
  
import { Routes } from '@angular/router';  
import { WarehouseListComponent } from './containers/warehouse-list/warehouse-list.component';
import { ClientPositionComponent } from './containers/client-position/client-position.component';
import { SymbolQuantityComponent } from './containers/symbol-qantity/symbol-quantity.component';
import { WarehouseHomeComponent } from './warehouse-home/warehouse-home.component';
import { WarehouseFormDialogComponent } from './components/warehouse-form-dialog/warehouse-form-dialog.component';
 
export const warehouseManagmentRoutes: Routes = [
  {
     path: '',
     redirectTo: 'warehouses',
     pathMatch: 'full'
  },
  {
     path: '',
     children: [
      
        {
           path: 'dashboard',
           component:  WarehouseHomeComponent
         },
         {
            path: 'warehouses',
            component:  WarehouseListComponent
         },

         {
            path: 'deposit',
            component:  TransactionsListComponent
         }  ,
         {
            path:'withdraw',
            component:  TransactionsListComponent
         },
         {
            path:'deleption',
            component:  TransactionsListComponent
         },
         { 
          path:'transfer',
         component:  TransactionsListComponent
         },
         { 
          path:'accept_reject',
         component:  TransactionsListComponent
         },
         { 
            path:'client_position',
           component:  ClientPositionComponent
           },
           { 
              path:'symbol-qantity',
             component:  SymbolQuantityComponent
             }
              
         ,{
            path: 'create',
            component:  WarehouseFormDialogComponent      
          },{
            path: 'Edit/:id',
            component:  WarehouseFormDialogComponent      
          } 
          
          
     ]
  }
];